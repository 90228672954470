import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { Maybe } from '../types/maybe';

export abstract class Store<Context, Data> {
	private data: Map<string, Subject<Maybe<Data>>> = new Map();

	get(context: Context): Observable<Maybe<Data>> {
		const contextKey = this.contextKey(context);
		if (!this.data.has(contextKey)) {
			this.data.set(contextKey, new BehaviorSubject(null));
			this.retrieveAndSetDataForContext(context, contextKey);
		}
		return this.data.get(contextKey);
	}

	reset(context: Context) {
		const contextKey = this.contextKey(context);
		if (!this.data.has(contextKey)) {
			return;
		}
		this.data.get(contextKey).next(null);
	}

	purge(context: Context) {
		const contextKey = this.contextKey(context);
		if (!this.data.has(contextKey)) {
			return;
		}
		this.data.get(contextKey).next(null);
		this.data.delete(contextKey);
	}

	purgeAll() {
		for (const key of this.data.keys()) {
			this.data.get(key).next(null);
			this.data.delete(key);
		}
	}

	reload(context: Context) {
		const contextKey = this.contextKey(context);
		if (!this.data.has(contextKey)) {
			return;
		}
		this.retrieveAndSetDataForContext(context, contextKey);
	}

	private retrieveAndSetDataForContext(context: Context, contextKey: string) {
		this.retrieve(context)
			.pipe(take(1))
			.subscribe({
				next: (res) => this.data.get(contextKey)?.next(res),
				error: (err) => {
					this.data.get(contextKey)?.error(err);
					this.data.delete(contextKey);
				},
			});
	}

	private contextKey(context: Context): string {
		return JSON.stringify(context); // TODO: use hash function or something in the future
	}

	protected abstract retrieve(context: Context): Observable<Maybe<Data>>;

	isDataFromStoreExist(context: Context): boolean {
		const contextKey = this.contextKey(context);

		return this.data.has(contextKey);
	}
}
