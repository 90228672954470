<div class="share-provider-header">
	{{ 'Provider details' | translate }}
</div>
<div class="share-provider-body">
	<div class="share-provider-field">
		<div class="share-provider-field-title">
			{{ 'Provider Name' | translate }}
		</div>
		<div class="share-provider-field-value">{{ provider?.firstName }} {{ provider?.lastName }}</div>
	</div>
	<div class="share-provider-field" *ngIf="provider?.specialties?.length > 0">
		<div class="share-provider-field-title">
			{{ 'Provider specialties' | translate }}
		</div>
		<div class="share-provider-field-value">
			<ng-container *ngFor="let specialty of provider.specialties; let isLast = last">
				{{ specialty | translate }}<ng-container *ngIf="!isLast">, </ng-container>
			</ng-container>
		</div>
	</div>

	<div class="share-provider-field" *ngIf="provider?.locations?.length > 0">
		<div class="share-provider-field-title">
			{{ 'Address' | translate }}
		</div>
		<div class="share-provider-field-value">
			<a
				[href]="'https://www.google.com/maps/dir/' + encodeURIComponent(provider.locations[0]?.address || '')"
				target="_blank"
			>
				{{ provider.locations[0]?.address }}
			</a>
		</div>
	</div>
	<div
		class="share-provider-field"
		*ngIf="provider?.locations?.length > 0 && provider?.locations[0]?.phoneNumbers[0]"
	>
		<div class="share-provider-field-title">
			{{ 'Phone' | translate }}
		</div>
		<div class="share-provider-field-value">
			<a [href]="'tel:' + provider?.locations[0]?.phoneNumbers[0]">
				{{ provider?.locations[0]?.phoneNumbers[0] }}
			</a>
		</div>
	</div>
</div>
