import { Directive, HostListener, Input } from '@angular/core';
import { TrackingService } from '../../../services/tracking.service';

@Directive({
	selector: '[appClientClickEventTracker]',
})
export class ClientClickEventTrackerDirective {
	@Input('appClientClickEventTracker')
	eventData: { event: string; metaData?: unknown; skip?: boolean };

	@HostListener('click')
	click() {
		if (this.eventData.skip) {
			return;
		}

		// @ts-ignore
		this.trackingService.trackClientEvent(this.eventData.event, this.eventData.metaData);
	}

	constructor(private trackingService: TrackingService) {}
}
