import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppLanguage, LanguageService } from '../services/language.service';

@Injectable()
export class TranslationInterceptor implements HttpInterceptor {
	private appLanguageCode: string = null;

	constructor(private languageService: LanguageService) {
		this.languageService.appLanguage$.subscribe(
			(appLanguage: AppLanguage) => (this.appLanguageCode = appLanguage?.locale)
		);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse && event.body) {
					this.addTranslationsToResponseBody(event.body);
				}

				return event;
			})
		);
	}

	private addTranslationsToResponseBody(body: any) {
		if (body instanceof Array) {
			return this.copyTranslationsToRootArray(body);
		}

		return this.addTranslationsToObject(body);
	}

	private copyTranslationsToRootArray(body: Array<any>) {
		return body.map((obj) => this.addTranslationsToObject(obj));
	}

	private addTranslationsToObject(obj: any) {
		if (!obj) return obj;

		if (this.translationsObjectExists(obj)) {
			const translationPayload = obj.translation[this.appLanguageCode];

			for (const key in translationPayload) {
				if (translationPayload[key]) obj[key] = translationPayload[key];
			}
		}

		// Check for nested objects and arrays and apply translations recursively
		for (const key in obj) {
			if (obj[key] && typeof obj[key] === 'object') {
				obj[key] = Array.isArray(obj[key])
					? this.copyTranslationsToRootArray(obj[key])
					: this.addTranslationsToObject(obj[key]);
			}
		}
		return obj;
	}

	private translationsObjectExists(obj: any) {
		return !!obj.translation;
	}
}
