<div *ngIf="!isLoading; else loadingIndicator" class="edit-info-container">
	<form>
		<input type="text" class="fs-unmask" name="fullName" placeholder="Your full name" [(ngModel)]="fullName" />

		<div *ngIf="oneWordError" class="error">{{ 'Please enter your first AND last name' | translate }}</div>
		<div *ngIf="invalidPatternError" class="error">
			<UT
				str="First and last names must be real,<br>
			each with minimum of 2 characters, and<br>
			not containing special characters"
			></UT>
		</div>

		<div class="btn-wrapper">
			<button type="submit" class="submit-btn" (click)="submitNameChange()">
				<UT str="Save" key="save_button"></UT>
			</button>
		</div>
	</form>
</div>

<ng-template #loadingIndicator>
	<app-loader size="large"></app-loader>
</ng-template>
