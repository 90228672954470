import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesSearchResponse } from './helpers/services-search.response';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ServicesSearchApiService {
	constructor(private httpClient: HttpClient) {}

	servicesSearch(query: string) {
		return this.httpClient.get<ServicesSearchResponse>(environment.servicesSearchURL, {
			params: {
				q: query,
			},
		});
	}
}
