import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TxNativeModule } from '@transifex/angular';

import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppSharedModule } from '../../app-shared/app-shared.module';

import { MainLayoutSharedModule } from '../main-layout-shared/main-layout-shared.module';

import { DISABLED_BACK_NAVIGATION } from '../../../services/ui.service';

import {
	USER_MEMBERSHIP_CARD_LOADING,
	PersonalInfoComponent,
	USER_MEMBERSHIP_CARD_DEFAULT,
} from './personal-info/personal-info.component';
import { ChangePassDialogComponent } from './personal-info/change-pass-dialog/change-pass-dialog.component';
import { AddDependentsDialogComponent } from './personal-info/add-dependents-dialog/add-dependents-dialog.component';
import { HealthProfileMainComponent } from './health-profile-main/health-profile-main.component';
import { TalonWidgetCoverComponent } from './health-profile-main/talon-widget-cover/talon-widget-cover/talon-widget-cover.component';
import { EmptyDependentsComponent } from './empty-dependents/empty-dependents.component';
import { MedicalSpendingModule } from '../../standalone/medical-spending/medical-spending.module';
import { DigitalCardComponent } from '../../digital-cards/digital-card/digital-card.component';
import { EmptyCardComponent } from '../../digital-cards/digital-card/empty-card/empty-card.component';
import { LockedCardComponent } from '../../digital-cards/digital-card/locked-card/locked-card.component';
import { FullCardComponent } from '../../digital-cards/digital-card/full-card/full-card.component';
import { RippleModule } from 'ripple';
import { ConnectDigitalCardFormHandlerModule } from '../../standalone/connect-digital-card-form-handler/connect-digital-card-form-handler.module';
import { UseHttpImageSourceModule } from '../../standalone/use-http-image-source/use-http-image-source.module';
import { CustomDigitalCardFrontComponent } from '../../digital-cards/custom-digital-card/custom-digital-card-front/custom-digital-card-front.component';
import { CharterDigitalCardFrontComponent } from '../../digital-cards/custom-digital-card/charter-digital-card-front/charter-digital-card-front.component';
import { CustomDigitalCardBackComponent } from '../../digital-cards/custom-digital-card/custom-digital-card-back/custom-digital-card-back.component';
import { CustomDigitalCardComponent } from '../../digital-cards/custom-digital-card/custom-digital-card.component';
import { NgOptimizedImage } from '@angular/common';
import { ReferralsInfoModule } from '../../mpi/referrals-info/referrals-info.module';
export const tabs = {
	personalInfo: 'personal-info',
	medicalSpendings: 'medical-spendings',
	healthPlans: 'health-plans',
	referralsInfo: 'referrals-info',
};

const routes: Routes = [
	{
		// route /health-profile
		path: '',
		component: HealthProfileMainComponent,
		children: [
			{
				path: tabs.personalInfo,
				component: HealthProfileMainComponent,
				data: { title: 'health_profile', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
			{
				path: tabs.medicalSpendings,
				component: HealthProfileMainComponent,
				data: { title: 'health_profile', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
			{
				path: tabs.healthPlans,
				component: HealthProfileMainComponent,
				data: { title: 'health_profile', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
			{
				path: tabs.referralsInfo,
				component: HealthProfileMainComponent,
				data: { title: 'health_profile', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
		],
	},
];

@NgModule({
	declarations: [
		HealthProfileMainComponent,
		PersonalInfoComponent,
		AddDependentsDialogComponent,
		ChangePassDialogComponent,
		TalonWidgetCoverComponent,
		EmptyDependentsComponent,
		DigitalCardComponent,
		EmptyCardComponent,
		LockedCardComponent,
		FullCardComponent,
		CustomDigitalCardFrontComponent,
		CharterDigitalCardFrontComponent,
		CustomDigitalCardBackComponent,
		CustomDigitalCardComponent,
	],
	imports: [
		MatExpansionModule,
		CommonModule,
		RouterModule.forChild(routes),
		NgbNavModule,
		NgOptimizedImage,
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatIconModule,
		MatTabsModule,
		MatSelectModule,
		AppSharedModule,

		UseHttpImageSourceModule.forRoot({
			loadingImagePath: USER_MEMBERSHIP_CARD_LOADING,
			errorImagePath: USER_MEMBERSHIP_CARD_DEFAULT,
		}),

		MainLayoutSharedModule,
		TxNativeModule,
		MedicalSpendingModule,
		ReferralsInfoModule,
		RippleModule,
		ConnectDigitalCardFormHandlerModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HealthProfileModule {}
