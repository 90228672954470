import { Component } from '@angular/core';
import { LoaderSize } from 'ripple';
import { ReferraceinfoStoreService } from 'src/app/services/stores/referrance-info-store/referrance-info-store.service';

@Component({
	selector: 'app-referrals-teaser',
	templateUrl: './referrals-teaser.component.html',
	styleUrls: ['./referrals-teaser.component.scss'],
})
export class ReferralsTeaserComponent {
	public referralsInfo$ = this.referraceinfoStoreService.getReferralsData();
	public isLoading$ = this.referraceinfoStoreService.isLoading$;
	public isSuccess$ = this.referraceinfoStoreService.isSuccess$;
	public loaderSize = LoaderSize.medium;

	constructor(private referraceinfoStoreService: ReferraceinfoStoreService) {}
}
