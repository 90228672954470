import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ageFromBirthDate',
})
export class AgeFromBirthDatePipe implements PipeTransform {
	transform(birthDate: Date): number {
		const today = new Date();
		const birthDateObject = new Date(birthDate);
		let age = today.getFullYear() - birthDateObject.getFullYear();
		const month = today.getMonth() - birthDateObject.getMonth();

		if (month < 0 || (month === 0 && today.getDate() < birthDateObject.getDate())) {
			age--;
		}

		return age;
	}
}
