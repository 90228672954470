import { Component, Input, OnInit } from '@angular/core';
import { CommonComponent } from '../../../../../../utils/components/common-component';
import { RibbonDoctor } from '../../../../../../models/ribbon-data.model';
import { Maybe } from '../../../../../../utils/types/maybe';
import { map, Observable, shareReplay } from 'rxjs';
import { InputOf } from '../../../../../../utils/input-reflector/input-of';
import { getPath } from '../../../../../../utils/get-path';
import { isNil } from '../../../../../../utils/is/is-nil';
import { ArrayUtils } from '../../../../../../utils/array';

const MIN_NUM_OF_STARS = 0;

const DEFAULT_MAX_RATING = 10; // the number according to which the rating was calculated (or of how much)
const DEFAULT_MAX_STARS = 5; // default number of stars to dispplay

const COST_START_CALC_POINT = 6;

@Component({
	selector: 'app-rating-and-cost',
	templateUrl: './rating-and-cost.component.html',
	styleUrls: ['./rating-and-cost.component.scss'],
})
export class RatingAndCostComponent extends CommonComponent implements OnInit {
	@Input()
	cardData: Maybe<RibbonDoctor>;

	@Input() rating: number = 1;
	@Input() maxRating: number = DEFAULT_MAX_RATING;
	@Input() totalStarsToDisplay: number = DEFAULT_MAX_STARS;
	@Input() numberOfReviews: number = null;

	public costSigns$: Observable<Array<{ isBold: boolean }>>;

	constructor() {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'cardData'];
	}

	ngOnInit(): void {
		this.validateComponentInputs();

		this.costSigns$ = this.inputs.one('cardData').pipe(
			map((cardData) => {
				const efficiencyIndex = getPath<number>(cardData, 'performance.aggregate.cost.efficiency_index');
				if (isNil(efficiencyIndex)) {
					return [];
				}
				const boldTil = COST_START_CALC_POINT - efficiencyIndex;
				return ArrayUtils.range(1, 5).map((num) => ({ isBold: num <= boldTil }));
			}),
			shareReplay(1)
		);
	}

	public get normalizedRatingStarsToDisplay() {
		const normalizedRating = (this.rating / this.maxRating) * this.totalStarsToDisplay;
		return normalizedRating.toFixed(2);
	}

	private validateComponentInputs() {
		this.rating = this.validateInput(this.rating, MIN_NUM_OF_STARS, this.maxRating);
	}

	private validateInput(value: number, minValue: number, maxValue: number) {
		if (!value) return 0;

		if (value < minValue) return minValue;

		if (value > maxValue) return maxValue;

		return value;
	}
}
