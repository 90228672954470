import { Subscription } from 'rxjs';

export class SubscriptionBag {
	private subscriptions: Subscription[] = [];
	private subsBags: SubscriptionBag[] = [];

	public set add(sub: Subscription | SubscriptionBag) {
		if (sub instanceof SubscriptionBag) {
			this.subsBags.push(sub);
			return;
		}
		this.subscriptions.push(sub);
	}

	dispose() {
		this.disposeSubscriptions();
		this.disposeSubscriptionBags();
	}

	private disposeSubscriptions() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	private disposeSubscriptionBags() {
		this.subsBags.forEach((bag) => bag.dispose());
	}
}
