export enum GipitpotIntent {
	Coverage = "coverage",
}

export enum GipitpotMessageOwner {
	User = "user",
	Bot = "bot",
}

export interface BaseGipitpotMessage {
	id: string;
	text: string;
	owner: GipitpotMessageOwner;
	createdAt?: Date;
	sessionId?: string;
	intent?: string;
	entity?: string;
}

export interface GipitpotCoverageMessage extends BaseGipitpotMessage {
	pos?: string;
}
