import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription, take } from 'rxjs';

import { ZocdocInfo, ZocdocLocation } from '../../../../../../models/zocdoc-data.model';
import { mobileFilterersOptions } from '../../../helpers/providers-search.helper';
import { Provider } from '../../../helpers/providers.helpers';
import { T } from '@transifex/angular';
import { AvailabilityData, SlotData } from 'src/app/models/appointment.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { ScheduleAppointmentEvents } from 'src/app/models/tracking-events.model';

@Component({
	selector: 'app-appointment-availability-form',
	templateUrl: './appointment-availability-form.component.html',
	styleUrls: ['./appointment-availability-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentAvailabilityForm implements OnInit, OnDestroy {
	mobileFilterersOptions = mobileFilterersOptions;

	@Input()
	zocdocData: ZocdocInfo;

	@Input()
	providerData: Provider;

	@Output()
	setAvailabilitySelection = new EventEmitter<AvailabilityData>();

	@Output()
	setSelectedSlot = new EventEmitter<SlotData>();

	@T('Ok')
	successAction: string;

	@T('Oh no, something went wrong, and we can’t show the appointments availables. Please try again later.')
	errorMessage: string;

	public showSlots: boolean = false;
	public showLoader: boolean = false;
	public availabilityForm: FormGroup;
	public availableSlots;

	public existingPatientAnswer = [
		{ value: 'existing', text: 'Yes', isChecked: false },
		{ value: 'new', text: 'No', isChecked: false },
	];

	public filteredLocations: Observable<ZocdocLocation[]>;
	private formSubscription: Subscription;

	constructor(
		private appointmentService: AppointmentService,
		private _snackBar: MatSnackBar,
		private trackingService: TrackingService
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscribeToFormChanges();
	}

	private buildForm() {
		const defaultLocation =
			this.zocdocData.data.locations.length >= 1 ? this.zocdocData.data.locations[0].locationId : '';
		const defaultVisitReason = this.zocdocData.data.defaultVisitReasonId || '';

		this.availabilityForm = new FormGroup({
			providerLocationId: new FormControl<string>(defaultLocation),
			visitReasonId: new FormControl<string>(defaultVisitReason),
			patientType: new FormControl<string>('', Validators.required),
		});
	}

	public subscribeToFormChanges(): void {
		this.formSubscription = this.availabilityForm.valueChanges.subscribe(() => {
			if (this.availabilityForm.valid) {
				this.onSubmitForm();
			}
		});
	}

	public onExistingPatientChange(value: string) {
		this.availabilityForm.controls['patientType'].setValue(value);
	}

	public onSubmitForm() {
		const formValues = this.availabilityForm.value;
		formValues.providerName = this.zocdocData.data.fullName;
		const locationData = this.zocdocData.data.locations.find(
			(location) => location.locationId === formValues.providerLocationId
		);
		const fullLocation = `${locationData.address1} - ${locationData.city}, ${locationData.state} ${locationData.zipCode}`;
		formValues.locationName = `${locationData.address1} - ${locationData.city}`;
		this.showSlots = false;
		this.showLoader = true;

		this.setAvailabilitySelection.emit(formValues);

		this.appointmentService
			.getAvailableSlots(formValues)
			.pipe(take(1))
			.subscribe({
				next: (availableSlots) => {
					this.availableSlots = availableSlots;
					this.trackingService.trackClientEvent(ScheduleAppointmentEvents.SlotsAvailabilityChecked, {
						doctorName: this.zocdocData.data.fullName,
						specialty: this.zocdocData.data.specialties.join(', '),
						address: fullLocation,
						availabilityExists: availableSlots?.timeslots?.length > 0,
					});

					this.showLoader = false;
					this.showSlots = true;
				},
				error: (error) => {
					console.log(error);
					this.showLoader = false;
					this.showErrorPopUp();
				},
			});
	}

	showErrorPopUp() {
		const snackBarRef = this._snackBar.open(this.errorMessage, this.successAction, {
			duration: 5000,
			panelClass: 'snackbar-failure',
			verticalPosition: 'top',
		});
		snackBarRef.onAction();
	}

	ngOnDestroy() {
		this.formSubscription.unsubscribe();
	}
}
