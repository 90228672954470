import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PremiumFeature } from '../models/premium-feature.model';
import { isNil } from '../utils/is/is-nil';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class PremiumFeatureService {
	constructor(private userService: UserService) {}

	isEnabled(feature: PremiumFeature): Observable<boolean> {
		return this.userService.userData$.pipe(
			map((user) => !isNil(user.company.premiumFeatures) && !!user.company.premiumFeatures[feature])
		);
	}
}
