<ion-modal
	[isOpen]="isOpen"
	[breakpoints]="[0, 0.4, 0.7, 1]"
	[initialBreakpoint]="0.35"
	[backdropDismiss]="true"
	(didDismiss)="dismissMenu()"
>
	<ng-template>
		<ion-header translucent>
			<ion-toolbar>
				<ion-title>Developer Menu</ion-title>

				<ion-buttons slot="end">
					<ion-button (click)="dismissMenu()">Close</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content fullscreen>
			<ion-list>
				<ion-item>
					<ion-icon class="item-icon" slot="start" name="cog-outline"></ion-icon>
					<ion-label>
						<h2>App info</h2>
						<h3>Bundle: {{ appInfo.name }}</h3>
						<p>{{ appInfo.id }}</p>
						<p>version: {{ appInfo.version }} build: {{ appInfo.build }}</p>

						<h3>
							Environemnt
							<ion-badge *ngIf="environment.production" color="danger">production</ion-badge>
						</h3>

						<p>name: {{ environment.envName }} ({{ environment.environmentType }})</p>
						<p>host: {{ host }}</p>
					</ion-label>
				</ion-item>

				<ion-item>
					<ion-icon class="item-icon clickable" slot="start" name="close-circle-outline"></ion-icon>
					<ion-label>
						<h3 class="clickable" (click)="clearBioAuthCacheData()">Clear bio auth saved data</h3>
						<p class="item-text">
							Click here to clear bio auth data, as
							<br />if you're logging to the app for the first time. <br />BioAuthData: {{ bioAuthData }}
							<span (click)="updateBioAuthSettings()">update</span>
						</p>
					</ion-label>
				</ion-item>

				<ion-item (click)="dismissMenuAndLockDevMode()">
					<ion-icon class="item-icon clickable" slot="start" name="close-circle-outline"></ion-icon>
					<ion-label>
						<h3 class="clickable">Close developer mode</h3>
						<p class="item-text">
							Click here to deactivate developer mode.<br />Developer mode is activated. When unlocked,
							this menu is accessible by clicking the version label without entering a password.
						</p>
					</ion-label>
				</ion-item>

			</ion-list>
		</ion-content>
	</ng-template>
</ion-modal>
