import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { TxNativeModule } from '@transifex/angular';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFilesizeModule } from 'ngx-filesize';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MainLayoutRoutingModule } from './main-layout-routing.module';
import { MainLayoutSharedModule } from './main-layout-shared/main-layout-shared.module';
import { CareCostsModule } from './care-costs/care-costs.module';
import { HealthProfileModule } from './health-profile/health-profile.module';
import { AppSharedModule } from '../app-shared/app-shared.module';

import { MainLayoutComponent } from './main-layout.component';
import { NewchatComponent } from './ask-zoe/newchat/newchat.component';
import { BenefitWrapperComponent } from './ask-zoe/newchat/coverage-card/benefit-wrapper.component';
import { ChatDialogComponent } from './ask-zoe/chat-dialog/chat-dialog.component';
import { SuggestionsFeedComponent } from './ask-zoe/suggestions-feed/suggestions-feed.component';
import { FaqComponent } from './faq/faq.component';
import { PlanClassTypePipeModule } from '../pipes/plan-class-type-pipe/plan-class-type-pipe.module';
import { HomePageModule } from '../home-page/home-page.module';
import { TooltipWithTouchModule } from '../app-shared/standalone/tooltip-with-touch/tooltip-with-touch.module';
import { PhoneInputModule } from '../app-shared/standalone/phone-input/phone-input.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GipitpotModule } from '../gipitpot/gipitpot.module';
import { NgxGooglePlacesAutocompleteModule } from '../standalone/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module';
import { IntercomMessengerModule } from '../app-shared/intercom-messenger/intercom-messenger.module';

@NgModule({
	declarations: [
		MainLayoutComponent,
		ChatDialogComponent,
		FaqComponent,
		NewchatComponent,
		SuggestionsFeedComponent,

		BenefitWrapperComponent,
	],
	imports: [
		NgbNavModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatExpansionModule,
		MatIconModule,
		MatTooltipModule,
		MatTableModule,
		MatSortModule,
		NgxFilesizeModule,
		MatSidenavModule,
		AppSharedModule,
		GoogleMapsModule,
		MatDividerModule,
		MatStepperModule,
		MatCheckboxModule,
		IntercomMessengerModule,

		NgxGooglePlacesAutocompleteModule,
		TxNativeModule,
		DragDropModule,

		TooltipWithTouchModule,
		PhoneInputModule,

		MainLayoutRoutingModule,
		MainLayoutSharedModule,
		CareCostsModule,
		HealthProfileModule,
		TxNativeModule,
		GipitpotModule,
		// Mobile-only modules
		IonicModule,
		PlanClassTypePipeModule,

		HomePageModule,
	],
	providers: [],
	exports: [],
})
export class MainLayoutModule {}
