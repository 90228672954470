import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacilityCardDetailsComponent } from './facility-card-details/facility-card-details.component';
import { FacilityCardComponent } from './facility-card/facility-card.component';
import { MapAndInfoComponent } from './map-and-info/map-and-info.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProviderAboutComponent } from './provider-card-details/provider-about/provider-about.component';
import { ProviderCardDetailsComponent } from './provider-card-details/provider-card-details.component';
import { ProviderLocationsComponent } from './provider-card-details/provider-locations/provider-locations.component';
import { ProviderReviewComponent } from './provider-card-details/provider-reviews/provider-review/provider-review.component';
import { ProviderReviewsComponent } from './provider-card-details/provider-reviews/provider-reviews.component';
import { ProviderCardLoaderComponent } from './provider-card/provider-card-loader/provider-card-loader.component';
import { ProviderCardComponent } from './provider-card/provider-card.component';
import { ProvidersMapCardComponent } from './providers-map-card/providers-map-card.component';
import { ProviderSearchResultsComponent } from './providers-map/provider-search-results/provider-search-results.component';
import { ProvidersSearchBarComponent } from './providers-search-bar/providers-search-bar.component';
import { ProvidersMapComponent } from './providers-map/providers-map.component';
import { SearchResultsBaseCardComponent } from './search-results-base-card/search-results-base-card.component';

import { TxNativeModule } from '@transifex/angular';
import { AppSharedModule } from 'src/app/modules/app-shared/app-shared.module';
import { RippleModule } from 'ripple';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChipListFormControlModule } from 'src/app/modules/app-shared/standalone/form-controls/chip-list-form-control/chip-list-form-control.module';
import { SelectFormControlModule } from 'src/app/modules/app-shared/standalone/form-controls/select-form-control/select-form-control.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { HighlightStringsModule } from 'src/app/modules/standalone/highlight-strings/highlight-strings.module';
import { ProviderAvatarModule } from 'src/app/modules/app-shared/standalone/provider-avatar/provider-avatar.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DrawerModule } from 'src/app/modules/standalone/drawer/drawer.module';
import { RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { ServicePanelModule } from 'src/app/modules/standalone/service-panel/service-panel.module';
import { NgxGooglePlacesAutocompleteModule } from '../../../standalone/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module';
import { ProvidersSearchResultsPrintComponent } from '..//shared/providers-map/provider-search-results/providers-search-results-print/providers-search-results-print.component';
import { MPIShareProviderComponent } from 'src/app/standalone/mpi-share-provider/mpi-share-provider.component';
import { ProvidersSearchEmptyStateComponent } from './providers-search-empty-state/providers-search-empty-state.component';
import { TopRatedProvidersComponent } from './providers-search-empty-state/top-rated-providers/top-rated-providers.component';
import { PrimaryCareSpecialtiesComponent } from './providers-search-empty-state/primary-care-specialties/primary-care-specialties.component';
import { PrimaryCareSpecialtyComponent } from './providers-search-empty-state/primary-care-specialties/primary-cate-specialty/primary-care-specialty.component';

@NgModule({
	declarations: [
		FacilityCardComponent,
		FacilityCardDetailsComponent,
		MapAndInfoComponent,
		PaginationComponent,
		ProviderCardComponent,
		ProviderCardLoaderComponent,
		ProviderCardDetailsComponent,
		ProviderAboutComponent,
		ProviderLocationsComponent,
		ProviderReviewComponent,
		ProviderReviewsComponent,
		ProvidersMapComponent,
		ProviderSearchResultsComponent,
		ProvidersSearchResultsPrintComponent,
		PrimaryCareSpecialtyComponent,
		ProvidersMapCardComponent,
		ProvidersSearchBarComponent,
		SearchResultsBaseCardComponent,
		ProvidersSearchEmptyStateComponent,
		TopRatedProvidersComponent,
		PrimaryCareSpecialtiesComponent,
	],
	exports: [
		FacilityCardComponent,
		FacilityCardDetailsComponent,
		MapAndInfoComponent,
		PaginationComponent,
		ProviderCardComponent,
		ProviderCardLoaderComponent,
		ProviderCardDetailsComponent,
		ProviderAboutComponent,
		ProviderLocationsComponent,
		ProviderReviewComponent,
		ProviderReviewsComponent,
		ProvidersMapComponent,
		ProviderSearchResultsComponent,
		ProvidersSearchResultsPrintComponent,
		ProvidersMapCardComponent,
		ProvidersSearchBarComponent,
		SearchResultsBaseCardComponent,
		ProvidersSearchEmptyStateComponent,
		TopRatedProvidersComponent,
		PrimaryCareSpecialtiesComponent,
		PrimaryCareSpecialtyComponent,
	],
	imports: [
		TxNativeModule,
		CommonModule,
		AppSharedModule,
		RippleModule,
		ChipListFormControlModule,
		SelectFormControlModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatDividerModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatAutocompleteModule,
		NgxGooglePlacesAutocompleteModule,
		HighlightStringsModule,
		ProviderAvatarModule,
		MatBadgeModule,
		GoogleMapsModule,
		DrawerModule,
		RouterLink,
		MatTabsModule,
		MatMenuModule,
		ServicePanelModule,
		MPIShareProviderComponent,
	],
})
export class MPIProvidersSearchSharedModule {}
