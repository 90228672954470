import { Component } from '@angular/core';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
	selector: 'app-my-care-team-empty-state-teaser',
	templateUrl: './my-care-team-empty-state-teaser.component.html',
	styleUrls: ['./my-care-team-empty-state-teaser.component.scss'],
})
export class MyCareTeamEmptyStateTeaserComponent {
	constructor(private trackingService: TrackingService) {}

	track(event, metaData = {}) {
		this.trackingService.trackClientEvent(event, metaData);
	}
}
