<div class="dialog-container">
	<div class="dialog-header"></div>
	<div class="dialog-body">
		<h3>
			<T str="Are you sure you want to remove {providerName} from your care team?" 
				[vars]="{ providerName: data.provider.name }"></T>		
		</h3>
	</div>
	<div class="dialog-footer">
		<button (click)="closeDialog.emit($event)" class="healthee-btn md tertiary">{{ 'Cancel' | translate }}</button>
		<button (click)="confirmDialog.emit($event)" class="healthee-btn md primary">
			{{ 'Yes, remove this provider' | translate }}
		</button>
	</div>
</div>
