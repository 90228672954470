import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';

@Component({
	selector: 'app-reschedule-disclaimer',
	templateUrl: './reschedule-disclaimer.component.html',
	styleUrls: ['./reschedule-disclaimer.component.scss'],
})
export class RescheduleDisclaimerComponent extends HealtheeDialogContent implements OnInit {
	public showAppointmentDrawer = false;

	constructor() {
		super();
	}

	ngOnInit(): void {}
}
