<app-providers-search-bar
	[searchParams]="queryParams$ | async"
	appearance="partial"
	(searchEvent)="onSearch($event)"
	[startMobileCollapsed]="true"
	[(showMobileMapView)]="showMobileMap"
></app-providers-search-bar>
<div class="main-container">
	<app-provider-search-results
		(outOfNetworkSearch)="onSearch($event)"
		[showMobileMapView]="showMobileMap"
	></app-provider-search-results>
</div>
