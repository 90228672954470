import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderDetails } from '../../../modules/main-layout/care-costs/helpers/providers.helpers';
import { Store } from '../../../utils/store/store';
import { ProvidersApiService } from '../../api/providers-api/providers-api.service';

@Injectable({
	providedIn: 'root',
})
export class ProviderDetailsStoreService extends Store<string, ProviderDetails> {
	constructor(private providersApiService: ProvidersApiService) {
		super();
	}

	protected retrieve(context: string): Observable<ProviderDetails> {
		return this.providersApiService.providerByNpi(context);
	}
}
