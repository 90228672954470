<div class="gipitpot-container">
	<div class="gipitpot-wrapper">

		<div class="gipitpot-header">
			<div class="privacy">
				<span class="lock"></span>
				<span>This chat is confidential, secure and encrypted.<br />
					No one except for you has access to this data.</span>
			</div>
		</div>

		<div class="gipitpot-body">
			<div class="gipitpot-messages" >
				<div class="gipitpot-message-wrapper" *ngFor="let message of messages">
					<app-gipitpot-user-message *ngIf="message.owner === 'user'" [message]="message" ></app-gipitpot-user-message>
					<app-gipitpot-system-message *ngIf="message.owner === 'bot'"  [message]="message" ></app-gipitpot-system-message>
				</div>
			</div>
			<ripple-loader *ngIf="chatBotLoading" [alignTo]="LoaderAlign.Left" text="Zoe is writing..."></ripple-loader>
			<div #scrollerAnchor></div>
		</div>
		<div class="gipitpot-footer">
			<app-gipitpot-input #inputControlRef (handleUserInput)="handleUserInput($event)"></app-gipitpot-input>
		</div>
	</div>
</div>
