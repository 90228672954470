<div *ngIf="!isLoading; else loadingIndicator" class="change-pass-container">
	<span (click)="onCloseModalClicked()" class="close-button">
		<img src="../../../assets/images/icons/x-close-dialog.svg" />
	</span>
	<img class="header-image" src="../../../../assets/images/change_pass_dialog.svg" alt="Change password" />
	<h1>{{ 'Change Password' | translate }}</h1>
	<div class="passwords-wrapper">
		<app-set-password
			[showUsedPasswordErrorSignal$]="usedPasswordIndicator"
			[useConfirmPassword]="true"
			[useValidation]="true"
			[invalidField]="invalidField"
			(passwordChanged)="validateNewPassword($event)"
		>
		</app-set-password>
	</div>
	<p>{{ 'Enter the 6 digit Two Factor Authentication code sent to your email' | translate }}</p>
	<form class="change-pass-form">
		<mat-form-field class="twoFA">
			<mat-label>{{ '2FA code' | translate }}</mat-label>
			<div class="input-wrapper">
				<input
					matInput
					name="password"
					type="password"
					class="twoFA-input"
					autocomplete="off"
					[(ngModel)]="twoFACode"
					(paste)="reset2FAErrors()"
					(keydown)="reset2FAErrors()"
					placeholder="{{ '2FA code from your email' | translate }}"
				/>
			</div>
		</mat-form-field>
		<div *ngIf="twoFACodeInvalidErr" class="error">{{ 'Please enter a valid 6-digit code' | translate }}</div>
		<div *ngIf="twoFACodeNoMatchErr" class="error">
			{{ 'Wrong 2FA code. Please re-enter the 6-digit code' | translate }}
		</div>

		<div class="btn-wrapper">
			<button [disabled]="!isPasswordValid" class="bt-primary" (click)="onChangePasswordClicked()">
				{{ 'Change Password' | translate }}
			</button>
		</div>
	</form>
</div>

<ng-template #loadingIndicator>
	<div class="loader">
		<app-loader size="x-large"></app-loader>
	</div>
</ng-template>
