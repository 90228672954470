import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, firstValueFrom, map, take } from 'rxjs';
import { UserPlanDataStoreService } from '../services/stores/user-plan-data-store/user-plan-data-store.service';
import CFG from 'src/app/config/app-config.json';

@Injectable({
	providedIn: 'root',
})
export class AnthemGuard {
	constructor(private userPlanDataStoreService: UserPlanDataStoreService, private router: Router) {}

	async canActivate(): Promise<Observable<boolean> | boolean | UrlTree> {
		const resultObservable = this.userPlanDataStoreService.hasMedicalPlanByAnthem().pipe(
			map((result) => result),
			take(1)
		);

		const result = await firstValueFrom(resultObservable);

		if (!result) {
			return this.router.createUrlTree([CFG.defaultAppRoute]);
		}

		return resultObservable;
	}
}
