import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap, take, tap, map } from 'rxjs';

import CFG from '../config/app-config.json';

import { User } from '../models/user.model';
import { TalonData } from '../models/talonData.model';

import { UserService } from './user.service';
import { UIService } from './ui.service';

const INITIAL_EMPTY_DATA = null;

@Injectable({ providedIn: 'root' })
export class HealthProfileService {
	private _talonData = new BehaviorSubject<TalonData>(null);

	constructor(private http: HttpClient, private userService: UserService, private uiService: UIService) {
		this.userService.user$.subscribe((user: User) => {
			if (this.uiService.PCTOnlyMode) return;
			if (user) this.attemptRegisteringUserWithTalon();
			else this.resetHealthProfile();
		});
	}

	get talonData$() {
		return this._talonData.asObservable().pipe(
			take(1),
			switchMap((value: TalonData) => {
				if (value) return of(value);
				return this.getUserTalonData();
			}),
			tap((newValue: TalonData) => this._talonData.next(newValue))
		);
	}

	get isUserConnectedToTalon$(): Observable<boolean> {
		return this.talonData$.pipe(
			take(1),
			map((talonData: TalonData) => !!talonData?.userConnectionStatus)
		);
	}

	/** Attempt registering user with Talon.
	 * If user was already connected, isUserConnectedToTalon would still be true.
	 */
	private attemptRegisteringUserWithTalon() {
		// this.http.post(CFG.apiEndpoints.userRegisterWithTalon, {}).subscribe();
	}

	public updateUserMembershipCard(file: File) {
		return this.http
			.post(CFG.apiEndpoints.userMembershipCard, file)
			.pipe(tap(() => this.userService.setMembershipCardUploaded()));
	}

	private getUserTalonData(): Observable<TalonData> {
		return this.http.get<TalonData>(CFG.apiEndpoints.userTalonData);
	}

	private resetHealthProfile() {
		this._talonData.next(INITIAL_EMPTY_DATA);
	}
}
