<div class="header">
	<div class="header-content">
		<div class="left-container">
			<div class="avatar-container">
				<ng-content select="[avatar]"></ng-content>
			</div>
		</div>
		<div class="right-container">
			<div class="text-container">
				<div class="title">
					<ng-content select="[title]"></ng-content>
				</div>
				<div class="sub-title">
					<ng-content select="[subTitle]"></ng-content>
				</div>
			</div>
		</div>
	</div>

	<div
		*ngIf="!uiService.isStandalone && showIsFavorite"
		[hidden]="isFavorite === null"
		class="like-icon"
		[class.active]="isFavorite"
		(click)="$event.stopPropagation(); isFavoriteChange.emit(!isFavorite)"
		matTooltipClass="title-tooltip"
		matTooltipPosition="right"
		[matTooltip]="'Click to add or remove from “My Care Team”' | translate"
	></div>
</div>

<ng-content select="[body]"></ng-content>
<ng-content select="[footer]"></ng-content>
