<app-providers-search-bar
	[showFilters]="false"
	appearance="full"
	[selectedTopSearch]="selectedTopSearch"
	(searchEvent)="onSearch($event)"
	(resetTopServiceSelected)="setService(null)"
></app-providers-search-bar>
<div class="main-container">
	<div class="content">
		<app-top-searched-specialties (setTopServiceSelected)="setService($event)"></app-top-searched-specialties>
	</div>
	<div class="side-panel">
		<app-provider-appointments class="left"></app-provider-appointments>
	</div>
</div>
