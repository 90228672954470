<div *ngIf="!isProcessing" class="appointment-card-container">
	<provider-avatar [gender]="providerData?.gender" size="medium" class=""></provider-avatar>
	<div class="info">
		<div
			*ngIf="appointment?.status === statuses.confirmed || appointment?.status === statuses.rescheduled"
			class="status confirmed"
		>
			{{ 'approved' | translate | uppercase }}
		</div>
		<div
			*ngIf="appointment?.status === statuses.pending || appointment?.status === statuses.reschedulePending"
			class="status pending"
		>
			{{ 'pending' | translate | uppercase }}
		</div>
		<div *ngIf="appointment?.status === statuses.failed || appointment?.status === statuses.rescheduleFailed" class="status failed">
			{{ 'booking failed' | translate | uppercase }}
		</div>
		<div class="date">{{ appointment?.date | date : 'MMMM dd, yyyy h:mm a' }}</div>
		<div class="name" (click)="navigateToProviderProfile()">
			{{ providerData?.firstName }} {{ providerData?.lastName }}
		</div>
	</div>

	<button [matMenuTriggerFor]="menu"><ripple-icon name="dotMenuVert" [hasClickEvent]="true"></ripple-icon></button>
	<mat-menu #menu class="max-width-none">
		<button
			[class.cursor-not-allowed]="option.disabled"
			(click)="option.func()"
			[disabled]="option.disabled"
			*ngFor="let option of actionMenu | filterArrayByPropValue : 'hidden' : undefined"
			mat-menu-item
		>
			<div
				class="option d-flex align-items-center justify-content-space-between"
				[class.cancel]="option?.name === 'cancel'"
			>
				<ripple-icon [name]="option.icon"></ripple-icon>
				<div class="d-flex align-items-center w-100">
					{{ option?.label }}
				</div>
			</div>
		</button>
	</mat-menu>

	<div class="address" (click)="openGoogleMapsDirections()">
		<img src="{{ assetsPath }}/provider-card-location-pin.svg" alt="location pin icon" />
		<div>{{ displayLocation }}</div>
	</div>

	<div *ngIf="appointment?.status === statuses.failed" class="buttons">
		<button (click)="onClickRemove()" class="bt secondary-blue">{{ 'Remove' | translate }}</button>
		<button (click)="onClickBookAgain()" class="bt primary-blue">{{ 'Book again' | translate }}</button>
	</div>
</div>

<app-drawer [isVisible]="showAppointmentDrawer" (isVisibleChange)="showAppointmentDrawer = false">
	<ng-template #content>
		<app-provider-appointment
			[appointment]="appointment"
			(onClose)="onSchedulingModalClose()"
			[providerData]="providerData"
			class="provider-appointment-component"
		></app-provider-appointment>
	</ng-template>
</app-drawer>

<ng-template #disclaimerFooter>
	<div class="disclaimer-footer">
		<button class="bt secondary-blue" (click)="onClickRescheduleClose()">{{ 'I will attend' | translate }}</button>
		<button class="bt primary-blue" (click)="onClickRescheduleContinue()">
			{{ 'Continue to reschedule' | translate }}
		</button>
	</div>
</ng-template>
