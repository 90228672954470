import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumFeatureDirective } from './premium-feature.directive';

@NgModule({
	declarations: [PremiumFeatureDirective],
	imports: [CommonModule],
	exports: [PremiumFeatureDirective],
})
export class PremiumFeatureModule {}
