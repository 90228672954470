<div class="dialog-container">
	<div class="dialog-header"></div>
	<div class="dialog-body">
		<div class="avatar-container">
			<img class="like-icon" src="/assets/images/provider-card/provider-card-favorite.svg" />
			<img class="avatar-icon" [src]="iconPath" />
		</div>
		<div class="text-container">
			<h1>{{ 'Congratulations!' | translate }}</h1>
			<h4>{{ data.provider.name }} {{ 'was added to your care team' | translate }}</h4>
		</div>
	</div>
	<div class="dialog-footer">
		<button (click)="closeDialog.emit($event)" class="healthee-btn md tertiary">{{ 'Done!' | translate }}</button>
	</div>
</div>
