import { Component, Input, OnInit } from '@angular/core';

export interface BannerCard {
	index: number;
	title: string;
	description: string;
	img: string;
	buttonLabel: string;
	action: any;
}

@Component({
	selector: 'app-banner-card',
	templateUrl: './banner-card.component.html',
	styleUrls: ['./banner-card.component.scss'],
})
export class BannerCardComponent implements OnInit {
	@Input() card: BannerCard;

	constructor() {}

	ngOnInit(): void {}
}
