import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';

@Component({
	selector: 'app-remove-from-favorites-confirmation-dialog',
	templateUrl: './remove-from-favorites-confirmation-dialog.component.html',
	styleUrls: ['./remove-from-favorites-confirmation-dialog.component.scss'],
})
export class RemoveFromFavoritesConfirmationDialogComponent extends HealtheeDialogContent implements OnInit {
	constructor() {
		super();
	}

	ngOnInit(): void {}
}
