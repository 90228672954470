<div class="content-wrapper">
	<p class="contact-text" *ngIf="!data?.modalText">
		{{ headline }}
	</p>
	<p class="contact-text" *ngIf="data?.modalText">
		{{ data.modalText }}
	</p>

	<form [formGroup]="contactForm" (ngSubmit)="sendUsEmail()" class="form-container">
		<div class="input-wrapper" *ngIf="unknownUser">
			<mat-form-field>
				<mat-label><UT str="Email" key="email"></UT></mat-label>
				<input matInput formControlName="email" name="email" id="email" />
			</mat-form-field>
		</div>
		<div class="input-wrapper">
			<span class="subject-headline">{{ 'Subject*' | translate }}</span>
			<textarea formControlName="subject" name="subject" id="subject" class="subject-box"></textarea>
		</div>
		<div class="input-wrapper">
			<span class="message-headline">{{ 'Message*' | translate }}</span>
			<textarea
				formControlName="description"
				formControlName="body"
				name="body"
				id="body"
				class="message-box"
			></textarea>
		</div>
		<div [ngSwitch]="isSending" class="btn-wrapper text-center">
			<button *ngSwitchCase="false" type="submit" class="healthee-btn md primary">
				{{ submit }}
			</button>
			<app-loader *ngSwitchCase="true"></app-loader>
		</div>
	</form>
</div>
