<ng-container [formGroup]="form">
	<mat-form-field>
		<mat-label>{{ 'Member ID' | translate }}</mat-label>
		<input #memberID matInput type="text" formControlName="memberId" autocomplete="off" />
	</mat-form-field>

	<div>
		<div class="label">{{ 'Birth date' | translate }}*</div>
		<div class="birth-date-fields">
			<mat-form-field>
				<input formControlName="month" matInput type="number" class="fs-unmask" placeholder="MM" autocomplete="off" />
			</mat-form-field>
			<mat-form-field>
				<input formControlName="day" matInput type="number" class="fs-unmask" placeholder="DD" autocomplete="off" />
			</mat-form-field>
			<mat-form-field>
				<input formControlName="year" matInput type="number" class="fs-unmask" placeholder="YYYY" autocomplete="off" />
			</mat-form-field>
		</div>
	</div>
</ng-container>

<div class="connect-space">
	<app-loader *ngIf="saveLoadingHandler.isLoading$ | async"></app-loader>

	<ng-container *ngIf="(saveLoadingHandler.isLoading$ | async) === false">
		<button [disabled]="form.invalid" (click)="connect()" class="bt primary-blue">
			{{ 'Connect' | translate }}
		</button>
		<div class="content-space-notes">
			{{ 'Having trouble connecting your plan?' | translate }}
			<span (click)="appManagerService.openContactUsModal()">{{ 'Contact us' }}</span>
		</div>
	</ng-container>
</div>
