import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';

import { ProvidersSearchPageComponent } from './providers-search-page.component';
import { CareAndCostsSharedModule } from 'src/app/modules/main-layout/care-costs/shared/care-and-costs-shared.module';
import {
	ProvidersSearchPageRoutingModule
} from 'src/app/modules/main-layout/care-costs/providers-search-page/providers-search-page-routing.module';

@NgModule({
	declarations: [ProvidersSearchPageComponent],
	imports: [
		CommonModule,
		TxNativeModule,
		CareAndCostsSharedModule,
		ProvidersSearchPageRoutingModule
	],
})
export class ProvidersSearchPageModule { }
