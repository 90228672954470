<mat-form-field
	class="select-form-container"
	[floatLabel]="floatLabel"
	[appearance]="appearance"
	[class.isFullWidth]="isFullWidth"
	[class.borderIfValid]="markIfValid"
	[class.no-bottom-space]="!hasBottomSpace"
	[class.hide-label]="!label"
	[class.expand-height]="expandHeightToContent"
>
	<mat-label>{{ label }} </mat-label>
	<mat-select
		(ngModelChange)="changeValue($event)"
		[ngModel]="syncedValue$ | async"
		[required]="hasRequiredValidator"
		[disableOptionCentering]="true"
		[multiple]="multi"
		(opened)="optionsPanelOpened()"
		(closed)="search$.next('')"
		panelClass="select-form-control-panel radius-18 border-input-border shadow-1"
		[placeholder]="placeholderText"
	>
		<mat-select-trigger *ngIf="multi">
			<span class="text-healthee-blue">{{ (syncedValue$ | async)?.length }} Selected</span>
		</mat-select-trigger>
		<mat-optgroup *ngIf="hasSearch">
			<div
				(click)="$event.stopPropagation(); $event.preventDefault()"
				(keydown)="$event.stopPropagation(); $event.stopPropagation()"
			>
				<mat-form-field class="w-100">
					<mat-label>{{ searchLabel }}</mat-label>
					<input
						type="text"
						[ngModel]="search$ | async"
						(ngModelChange)="search$.next($event)"
						#searchInput
						autocomplete="off"
						matInput
						class="border-0"
					/>
				</mat-form-field>
			</div>
		</mat-optgroup>
		<mat-option *ngIf="isNullable" [value]="null">-</mat-option>
		<mat-option *ngFor="let item of filteredItems$ | async" [value]="item | getPath : valueField">
			<ng-container *ngIf="itemTemplate; else standard">
				<ng-container
					[ngTemplateOutlet]="itemTemplate"
					[ngTemplateOutletContext]="{ $implicit: item }"
				></ng-container>
			</ng-container>
			<ng-template #standard>{{ item | getPath : displayField }}</ng-template>
		</mat-option>
	</mat-select>
	<ripple-icon matSuffix name="chevronDown" color="text-secondary"></ripple-icon>
</mat-form-field>
