<div class="feedback-block-container">
	<span class="headline" *ngIf="showHeadlineInWidget">
		{{ headline }}
	</span>
	<p class="feedback-question" [class.question-as-headline]="!showHeadlineInWidget">
		{{ question }}
	</p>
	<app-feedback-emotions
		[rating]="currentRating"
		[disclaimers]="disclaimers"
		(ratingSelected)="openFeedbackDialog($event)"
	>
	</app-feedback-emotions>
</div>
