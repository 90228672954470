<app-digital-card #cardElement [contract]="contract">
	<div content class="card-content">
		<div class="values-row">
			<div class="label-and-value">
				<div class="label">{{ 'Subscriber Name' | translate }}</div>
				<div class="value">{{ fullName }}</div>
			</div>
			<div class="label-and-value right">
				<div class="label">{{ 'Member ID' | translate }}</div>
				<div class="value">{{ memberId$ | async }}</div>
			</div>
		</div>
		<div class="values-row">
			<div class="label-and-value">
				<div class="label">{{ 'Group Number' | translate }}</div>
				<div class="value">{{ contract?.groupNumber || 'N/A' }}</div>
			</div>
			<div class="label-and-value right">
				<div class="label">{{ 'Date of birth' | translate }}</div>
				<div class="value">{{ dob$ | async }}</div>
			</div>
		</div>
		<div class="label-and-value name">
			<div class="label">{{ 'Plan Name' | translate }}</div>
			<div class="name">{{ contract?.name }}</div>
		</div>
		<mat-expansion-panel
			class="member-card-costs-panel"
			[(expanded)]="isExpanded"
			hideToggle="true"
			(opened)="setButtonValues()"
			(closed)="setButtonValues()"
		>
			<mat-expansion-panel-header>
				<div class="non-expansion" (click)="trackShowCosts()">
					<button class="bt secondary-blue" *ngIf="(benefits$ | async)?.length > 0">
						{{ buttonText | translate }}
						<ripple-icon [name]="iconName"></ripple-icon>
					</button>
					<mat-icon
						[class.inaccessible]="shareLoadingHandler.isLoading$ | async"
						(click)="share($event)"
						class="full-card-share"
						>ios_share</mat-icon
					>
				</div>
			</mat-expansion-panel-header>
			<div class="expansion">
				<div class="label-and-value" *ngFor="let benefit of benefits$ | async">
					<div class="label">{{ benefit.title }}</div>
					<div class="value">
						<ng-container *ngIf="benefit.cost[0]"> ${{ benefit.cost[0] }} </ng-container>
						<ng-container *ngIf="!benefit.cost[0] && benefit.cost[1]">
							<ng-container *ngIf="benefit.cost[1] === 100">$0</ng-container>
							<ng-container *ngIf="benefit.cost[1] !== 100">{{ benefit.cost[1] }}%</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</div>
</app-digital-card>
