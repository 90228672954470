<app-healthee-content-slider appearance="standard">
	<div class="benefits-container">
		<ng-container *ngFor="let service of selectedServices">
			<div
				class="benefit-item"
				*ngIf="(languageService.isSpanish$ | async) === false"
				(click)="[selectedServiceId$.next(service._id), onSelectedBenefitClick(service.abbreviate)]"
			>
				<img [src]="service.iconPath | serviceImage" [alt]="service.abbreviate" />
				<div class="image-overlay">
					<div class="benefit-title">
						{{ service.abbreviate || service.name }}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</app-healthee-content-slider>

<app-drawer [isVisible]="!!(selectedServiceId$ | async)" (isVisibleChange)="selectedServiceId$.next(null)">
	<ng-template #content>
		<app-service-panel
			[serviceId]="selectedServiceId$ | async"
			(findProviderClick)="findProvider()"
			(closeClick)="selectedServiceId$.next(null)"
			(relatedBenefitClick)="selectedServiceId$.next($event)"
		></app-service-panel>
	</ng-template>
</app-drawer>
