import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-preventive-care-progress',
	templateUrl: './preventive-care-progress.component.html',
	styleUrls: ['./preventive-care-progress.component.scss'],
})
export class PreventiveCareProgressComponent {
	@Input() progress: number = 0;
	@Input() total: number = 0;
	@Output() progressBarClick: EventEmitter<void> = new EventEmitter<void>();
	public registeredStep: number = 1;

	public onOpenPreventiveCare(): void {
		this.progressBarClick.emit();
	}
}
