import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { sort } from '../../helpers/utils';
import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';
import { ReferraceinfoStoreService } from 'src/app/services/stores/referrance-info-store/referrance-info-store.service';

@Component({
	selector: 'app-referrals-cards-list',
	templateUrl: './referrals-cards-list.component.html',
	styleUrls: ['./referrals-cards-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralsCardsListComponent implements OnInit {
	public referralsInfo$ = this.referraceinfoStoreService.getReferralsData();
	public isLoading$ = this.referraceinfoStoreService.isLoading$;
	public isSuccess$ = this.referraceinfoStoreService.isSuccess$;
	public data: ReferralsInfoResponse[];

	@Input() sortDirection: string;

	public constructor(private referraceinfoStoreService: ReferraceinfoStoreService) {}

	public ngOnInit(): void {
		this.referraceinfoStoreService.getReferralsData().subscribe((result: ReferralsInfoResponse[]) => {
			this.data = result;
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sortDirection) {
			const sortDirection = changes.sortDirection.currentValue;

			this.data = sort(sortDirection, this.data);
		}
	}
}
