import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';

@Component({
	selector: 'app-healthee-autocomplete-select',
	templateUrl: './healthee-autocomplete-select.component.html',
	styleUrls: ['./healthee-autocomplete-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

/**
 * @param  elementArray (Input, string[]) - the strings array to be selected from.
 * @param  imgSrc (Input, string) - The required source for the input image.
 * @param  placeHolder (Input, string) - The required placeholder	for the input.
 * @param  elementSelected (Output, EventEmitter, string) - Emits the selected element string.
 */
export class HealtheeAutocompleteSelectComponent implements OnInit {
	@Input() imgSrc: string = '';
	@Input() elementArray: string[];
	@Input() placeHolder: string = '';
	@Output() elementSelected = new EventEmitter<string | null>();
	@ViewChild('autoTrigger', { read: MatAutocompleteTrigger })
	autoTrigger: MatAutocompleteTrigger;
	filteredOptions$: Observable<string[]>;
	searchCtrl: FormControl = new FormControl();

	ngOnInit() {
		this.listenToValueChanges();
	}

	private listenToValueChanges(): void {
		this.filteredOptions$ = this.searchCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filter(val))
		);
	}

	private filter(val: string): string[] {
		const matchedResults = this.elementArray?.filter((option) =>
			option?.toLowerCase().includes(val?.toLowerCase())
		);
		if (matchedResults.length) return matchedResults;
		return ['No Results Found'];
	}

	public openAutocomplete(): void {
		this.autoTrigger.openPanel();
	}

	public optionSelected(event): void {
		this.elementSelected.emit(event.option.value);
	}

	public clearSelectedElement(): void {
		this.elementSelected.emit(null);
	}
}
