import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, map, take, lastValueFrom, combineLatest } from 'rxjs';
import { DeductiblesApiService } from 'src/app/services/api/deductibles-api/deductibles-api.service';
import { Deductible } from 'src/app/services/api/domain/deductible';
import { DeductiblesStoreService } from 'src/app/services/stores/deductibles-store/deductibles-store.service';
import { Maybe } from 'src/app/utils/types/maybe';
import { isToday } from '../../../utils/utils';
import { ButtonHierarchy, ButtonSize } from 'ripple';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { isNil } from 'src/app/utils/is/is-nil';
@Component({
	selector: 'app-deductible-sync',
	templateUrl: './deductible-sync.component.html',
	styleUrls: ['./deductible-sync.component.scss'],
})
export class DeductibleSyncComponent implements OnInit {
	public readonly REFRESH_ICON = 'refresh';
	public readonly ButtonHierarchy: typeof ButtonHierarchy = ButtonHierarchy;
	public readonly ButtonSize: typeof ButtonSize = ButtonSize;

	connectedDeductiblesData$: Observable<Maybe<Deductible>>;
	isSyncDisabled$: Observable<boolean>;

	userHasDeductible$: Observable<boolean>;
	@Output() isSyncing = new EventEmitter<boolean>();

	constructor(
		private deductiblesApiService: DeductiblesApiService,
		private deductiblesStoreService: DeductiblesStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {}

	ngOnInit(): void {
		this.connectedDeductiblesData$ = this.deductiblesStoreService.get();
		this.userHasDeductible$ = combineLatest([
			this.userPlanDataStoreService.get(),
			this.connectedDeductiblesData$,
		]).pipe(
			map(([userPlans, deductible]) => {
				if (isNil(userPlans) || isNil(deductible)) return false;

				return userPlans?.contract?._id === deductible?.plan;
			})
		);

		this.isSyncDisabled$ = this.connectedDeductiblesData$.pipe(
			map((deductible) => isToday(deductible?.lastUpdatedAt))
		);
	}

	public async onSyncClicked(): Promise<void> {
		const isSyncDisabled = await lastValueFrom(this.isSyncDisabled$.pipe(take(1)));
		if (isSyncDisabled) return;

		this.isSyncing.emit(true);
		this.deductiblesApiService.updateDeductibles().subscribe(() => {
			this.deductiblesStoreService.reload();
			this.isSyncing.emit(false);
		});
	}
}
