import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@NgModule({
	declarations: [PhoneInputComponent],
	imports: [CommonModule, NgxMatIntlTelInputComponent, MatFormFieldModule, MatInputModule, FormsModule],
	exports: [PhoneInputComponent],
})
export class PhoneInputModule {}
