import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectDigitalCardFormHandlerComponent } from './connect-digital-card-form-handler/connect-digital-card-form-handler.component';
import { TxNativeModule } from '@transifex/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RippleModule } from 'ripple';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [ConnectDigitalCardFormHandlerComponent],
	imports: [
		CommonModule,
		TxNativeModule,
		ReactiveFormsModule,
		MatInputModule,
		RippleModule,
		MatCheckboxModule,
		FormsModule,
	],
	exports: [ConnectDigitalCardFormHandlerComponent],
})
export class ConnectDigitalCardFormHandlerModule {}
