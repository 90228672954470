import { T } from '@transifex/angular';

export class PlanSelectionLocalize {
	@T('Myself')
	static filterName_individual: string;
	@T('Myself + Partner')
	static filterName_individualPlusSpouse: string;
	@T('My Family')
	static filterName_family: string;
	@T('Myself + Child')
	static filterName_individualPlusOne: string;

	// tooltips
	@T('Doctors-in-network')
	static networkCoverage_tooltipTitle: string;
	@T('The number of your doctors (PCP/specialists) that are in-network for this plan.')
	static networkCoverage_tooltip: string;

	@T('Who will be enrolling in benefits?')
	static enrollingBenefits_tooltipTitle: string;
	@T("We'll use this info to provide you with relevant plan and coverage options.")
	static enrollingBenefits_tooltip: string;

	@T('Add your providers')
	static provider_tooltipTitle: string;
	@T("We'll use this info to let you know if your providers are in or out-of-network for a particular plan.")
	static provider_tooltip: string;

	@T('Wellness Incentives')
	static wellness_incentives: string;

	@T('Plan preferences')
	static plan_preferences: string;
	@T('Choose your preferences')
	static plan_preferences_tooltipTitle: string;
	@T(
		'Referral requirements to see specialists can impact your healthcare experience by adding extra steps and potential delays. However, it can also ensure that you only see a specialist when necessary, ultimately saving you from incurring unnecessary costs. ﻿We use these preferences to identify the best plan options for your consideration.'
	)
	static plan_preferences_tooltip: string;
	@T('Yes')
	static yes: string;
	@T('No')
	static no: string;
	@T('Show My Plan Options')
	static show_plan_options: string;
	@T('Save & Continue')
	static save_and_continue: string;

	@T('Per pay period')
	static premiumPerPayPeriod_tooltipTitle: string;
	@T(
		' The amount you pay for health insurance each pay period. These costs don’t count toward your plan’s deductible or Out-of-Pocket Maximum.'
	)
	static premiumPerPayPeriod_tooltip: string;

	@T('Deductible')
	static yearlyDeductible_tooltipTitle: string;
	@T(
		'The annual amount you pay for medical expenses before your health insurance kicks in and starts to pay for non-preventive care.'
	)
	static yearlyDeductible_tooltip: string;

	@T('Out-of-Pocket Maximum')
	static outOfPocketMax_tooltipTitle: string;
	@T(
		'The amount you’ll pay out-of-pocket during a plan year for covered medical services. Once you reach your maximum, your health plan will cover 100% of your covered services.'
	)
	static outOfPocketMax_tooltip: string;

	@T('Plan Annual Max coverage')
	static calendarYearMax_tooltipTitle: string;
	@T(
		'The annual maximum is the maximum dollar amount your dental plan will pay toward the cost of dental services and treatment during a plan year.'
	)
	static calendarYearMax_tooltip: string;

	@T('Orthodontics Lifetime Max')
	static orthodonticsLifetimeMax_tooltipTitle: string;
	@T(
		'The orthodontic lifetime maximum caps how much money your dental plan will contribute toward braces or other orthodontic work over the course of your lifetime.'
	)
	static orthodonticsLifetimeMax_tooltip: string;

	@T('Eligible for HSA')
	static HSAEligibility_tooltipTitle: string;
	@T(
		'Take control of your healthcare expenses with a spending account like an FSA or HSA. Contribute pre-tax dollars to pay for eligible medical expenses, saving on taxes and managing your healthcare costs more effectively. Notice - You can’t have a healthcare FSA and an HSA simultaneously.'
	)
	static HSAEligibility_tooltip: string;

	@T(
		'A type of savings account that lets you set aside money on a pre-tax basis to pay for qualified medical expenses.'
	)
	static HSA_tooltip: string;

	@T(
		'This plan type offers both in and out-of-network coverage. While there is generally a large network of providers to choose from, premiums tend to be higher.'
	)
	static PPO_tooltip: string;

	@T('This plan type only covers in-network services. You will need to select a PCP to coordinate your care.')
	static HMO_tooltip: string;

	@T(
		"This plan type will cover services from providers that are both in-network and out-of-network. You're not required to have a Primary Care Provider (PCP), however you will need a PCP to provide a referral if you need to see a specialist."
	)
	static POS_tooltip: string;

	@T(
		'This plan type does not require you to have a Primary Care Provider (PCP), but will only cover services performed by providers in the EPO network (except emergencies).'
	)
	static EPO_tooltip: string;

	@T(
		'You will pay more out of pocket for your medical expenses before the plan begins to share costs. In exchange, you may be eligible to open a Health Savings Account (HSA), where you can save pre-tax dollars to help cover your medical expenses.'
	)
	static HDHP_tooltip: string;

	@T(
		'This plan type (Reference-Based Pricing) sets maximum prices for certain medical services, giving you the flexibility to choose providers. However, you may be responsible for any costs exceeding the reference price, requiring careful cost monitoring.'
	)
	static RBP_tooltip: string;

	@T(
		'ACOs are made up of a group of providers or hospitals that come together to offer coordinated care that’s high quality and cost-effective. You can choose your providers within the network, but out-of-network coverage may be limited, especially for nonemergencies.'
	)
	static ACO_tooltip: string;

	@T(
		'This plan type offers maximum provider flexibility. You can see any doctor or specialist without referrals. You pay a percentage of medical costs (coinsurance), and the plan reimburses the rest, providing extensive choice but potentially higher costs.'
	)
	static Indemnity_tooltip: string;

	@T(
		'This is a prepaid dental insurance plan that aims to minimize out-of-pocket costs. The plan provides in-network dental coverage, meaning you can only choose from dentists who are in the plan’s network.'
	)
	static HMO_dental_tooltip: string;

	@T(
		'This dental plan will typically have a larger network of dental providers, and you’ll be able to switch dentists or see a specialist without a referral from a primary care dentist. In fact, you aren’t required to designate a primary care dentist when enrolling in a PPO dental plan.'
	)
	static PPO_dental_tooltip: string;

	@T('Coverage for')
	static coverage_tooltipTitle: string;

	@T('Coverage details only for yourself.')
	static coverage_tooltip_myself: string;
	@T('Coverage details for yourself and your Spouse or Domestic partner.')
	static coverage_tooltip_partner: string;
	@T('Coverage details for your family, including partner and children.')
	static coverage_tooltip_family: string;
	@T('Coverage details for yourself and your children.')
	static coverage_tooltip_child: string;

	@T('Pin plan')
	static pin_tooltip: string;

	@T('Incorrect information?')
	static incorrectNetworkCoverage_tooltipTitle: string;
	@T('If you think this information may be incorrect call your doctor to see if they are in-network.')
	static incorrectNetworkCoverage_tooltip: string;

	@T('Suitable if you have a complex medical conditions or anticipate large medical expenses.')
	static high_compatibility_medical: string;
	@T(
		"Suitable if you don't have any complex medical conditions or large medical expenses, but want to have coverage in case of unexpected events or costs."
	)
	static medium_compatibility_medical: string;
	@T(
		'Suitable if you expect to receive mostly routine care this year, and can manage some medical expenses out-of-pocket.'
	)
	static low_compatibility_medical: string;

	@T('you expect to <b>receive additional services outside of routine</b> dental screenings and checkups.')
	static high_compatibility_dental: string;
	@T(
		'you expect to <b>receive routine dental screenings and checkups</b> and would like to have <b>more coverage if something unexpected happens</b>.'
	)
	static medium_compatibility_dental: string;
	@T('you expect to <b>receive mostly routine dental screenings and checkups</b> this year.')
	static low_compatibility_dental: string;

	@T('Suitable if you <b>anticipate substantial medical expenses</b> and prefer lower out-of-pocket expenditures.')
	static high_compatibility_medical_v2: string;
	@T('Suitable if you <b>anticipate typical medical expenses</b> and can manage moderate out-of-pocket expenditures.')
	static medium_compatibility_medical_v2: string;
	@T(
		'Suitable if you <b>anticipate minimal medical expenses</b> and can manage larger out-of-pocket expenditures if claims should arise.'
	)
	static low_compatibility_medical_v2: string;

	@T('Suitable if you expect to receive additional services outside of routine dental screenings and checkups.')
	static high_compatibility_dental_v2: string;
	@T(
		'Suitable if you expect to receive routine dental screenings and checkups and would like to have more coverage if something unexpected happens.'
	)
	static medium_compatibility_dental_v2: string;
	@T('Suitable if you expect to receive mostly routine dental screenings and checkups this year.')
	static low_compatibility_dental_v2: string;

	@T('Network Coverage information is unavailable for this plan.')
	static noInfoText: string;

	@T('Network coverage')
	static networkCoverage: string;
	@T('In-Network')
	static doctorsInNetwork: string;
	@T('Out-of-Network')
	static doctorsOutNetwork: string;

	@T('Your <strong>Medical</strong> plan options')
	static planOptionsMedical_title: string;

	@T(
		'Review, compare and save your plans. Medical benefits are offered through four medical plans which are available depending on where you reside. Review and compare your options below.'
	)
	static planOptionsMedical_description: string;

	@T('Your <strong>Dental</strong> plan options')
	static planOptionsDental_title: string;

	@T(
		'Dental benefits are offered through Delta Dental PPO (nationwide) or DeltaCare USA (California residents only) and cover services including check-ups, cleanings, fillings and more.'
	)
	static planOptionsDental_description: string;

	@T('Prescription drug benefits are administered by CVS Caremark.')
	static planOptionsPharmacy_description: string;

	@T('Your <strong>Vision</strong> plan options')
	static planOptionsVision_title: string;

	@T(
		'Vision benefits, administered by VSP (Vision Service Plan) covering certain eye care expenses such as exams, glasses, contacts, and more.'
	)
	static planOptionsVision_description: string;

	@T('<strong>Pharmacy</strong>')
	static planOptionsPharmacy_title: string;

	@T('Gender')
	static gender: string;
	@T('Male')
	static radio_gender_male: string;
	@T('Female')
	static radio_gender_female: string;
	@T('Prefer not to say')
	static radio_gender_preferNotSay: string;

	@T('selected successfully!')
	static provider_selected_snackbar: string;

	@T('Out-of-network <b>coverage is available</b>')
	static out_of_network_coverage: string;

	@T('Out-of-network <b> only in emergencies</b>')
	static no_out_of_network_coverage: string;

	@T('You have the flexibility to <b>visit any provider</b>')
	static out_of_network_flexible: string;

	@T('Referrals are <b>not required</b> to see a specialist')
	static referrals_not_required: string;

	@T('Referrals are <b>required</b> to see a specialist')
	static referrals_usually_required: string;

	@T('Out-of-network services will be covered <b>only in case of emergency</b>')
	static out_of_network_emergency_coverage: string;

	@T('Coverage available <b>out of state</b>')
	static coverage_available_out_of_state: string;

	@T('You can visit any provider with this plan, there is no network restriction')
	static network_coverage_without_restriction: string;

	@T(
		"RBP plans let you choose any provider, but costs may be higher if they charge more than the plan's reference price. Check prices and network options for cost-effective choices."
	)
	static rbp_network_coverage_description: string;

	@T(
		"An Indemnity plan allows you to choose any healthcare provider, and you'll be reimbursed a portion of your medical costs."
	)
	static indemnity_network_coverage_description: string;

	@T('Medical Plan')
	static medical_plan: string;

	@T('Dental Plan')
	static dental_plan: string;

	@T('Vision Plan')
	static vision_plan: string;

	@T(
		'A FSA (Flexible Spending Account) allows you to set aside pre-tax money from your salary to pay for qualified medical expenses.\n		It helps you save on taxes while covering medical bills and other eligible healthcare costs.\n		Typically, FSAs have a "use it or lose it" rule, meaning any unused funds at the end of the plan year may be forfeited.'
	)
	static fsaInfo: string;

	@T('Health Care FSA <strong>Limited</strong> Use')
	static fsaLimitedUse: string;

	@T(
		'In addition to the Health Savings Account (HSA), you are also eligible for the Health Care FSA Limited Use Account'
	)
	static fsaLimitedInfo: string;

	@T(
		'An HSA can be used for a wide range of medical expenses like prescriptions, hospital fees, dental & vision care, mental health services, and medical equipment. It may also cover preventive care, lab tests, and some alternative forms of therapies. HSAs can usually be used on eligible expenses for your dependents.'
	)
	static hsaTooltip: string;

	@T(
		"A Health Care FSA account can be used to cover various eligible medical expenses. Some common uses include paying for doctor's visits, prescription medications, hospital fees, dental and vision care, medical equipment, and certain over-the-counter medications. It can also be used for deductibles, copayments, and coinsurance amounts required by your health insurance plan."
	)
	static fsaTooltip: string;

	@T(
		'A Dependent Care FSA account can be used to pay for eligible child care expenses like daycare and summer camp. It can also cover care for elderly or disabled dependents, including in-home care, adult day care, and certain expenses associated with assisted living facilities.'
	)
	static dependentCareFsaTooltip: string;

	@T(
		'Health Care FSA Limited Use is used for specific healthcare expenses that may not typically be covered. It allows individuals with an HSA-qualified high-deductible  plan to use FSA funds for certain expenses like over-the-counter medications or non-prescription items that may not be reimbursed by the HSA. This provides additional flexibility in accessing pre-tax funds for a broader range of qualified healthcare costs.'
	)
	static fsaLimitedTooltip: string;

	@T(
		'The rules for maximum contributions are subject to regulations set by the Internal Revenue Service (IRS) in the United States. It is typical for spending accounts to follow a calendar year structure, where the contributions and expenses are based on the calendar year cycle.'
	)
	static annualContributionLimitTooltip: string;

	@T(
		'A Health Care FSA Limited Use is offered alongside an HSA to provide additional flexibility for individuals with an HSA-qualified high-deductible health plan. It allows them to use FSA funds for specific expenses not eligible for HSA reimbursement. This combination maximizes tax advantages and provides coverage for a wider range of qualified healthcare expenses.'
	)
	static fsaLimitedExplanationTooltip: string;

	@T('Health Saving Account')
	static healthSavingAccount: string;

	@T('Health Care FSA')
	static healthCareFSA: string;

	@T('Dependent Day Care FSA')
	static dependentDayCareFSA: string;

	@T('Health Care FSA Limited')
	static healthCareFSALimited: string;

	@T('Medical')
	static medical: string;

	@T('Dental')
	static dental: string;

	@T('Vision')
	static vision: string;

	@T('Pharmacy')
	static pharmacy: string;

	@T('How are they related to you?')
	static dependentType: string;

	@T('My Spouse')
	static spouse: string;

	@T('My Domestic Partner')
	static partner: string;

	@T('My Child')
	static child: string;

	@T("My Domestic Partner's Child")
	static domesticPartnerChild: string;

	@T(
		'RBP plan offers flexibility but makes precise cost estimates challenging. Costs may vary based on <b>your choices and healthcare needs.</b>'
	)
	static rbpPlanCostEstimateNotice: string;

	@T(
		'Indemnity plan offers flexibility but makes precise cost estimates challenging. Costs may vary based on <b>your choices and healthcare needs.</b>'
	)
	static indemnityPlanCostEstimateNotice: string;

	@T('Plan Comparison Tool')
	static title_plan_comparison_tool: string;

	@T('Your feedback matters!')
	static feedback_headline: string;
	@T('How helpful was this experience in finding the right plan for you?')
	static feedback_question: string;
}
