import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { BenefitDocTypes, NewBenefit } from '../../../models/benefits.model';
import { Observable, of } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { GalleryApiService } from '../../api/gallery-api/gallery-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NewBenefitStoreService extends Store<{ contractId: string; oldBenefitId: string }, NewBenefit> {
	constructor(private galleryApiService: GalleryApiService) {
		super();
	}

	protected retrieve({ contractId, oldBenefitId }): Observable<Maybe<NewBenefit>> {
		const notFoundBenefit: NewBenefit = {
			oid: "",
			contractId: "",
			title: "",
			slug: "",
			service: "",
			networks: [],
			docType: BenefitDocTypes.EOC
		};
		return this.galleryApiService.newBenefitService(contractId, oldBenefitId).pipe(catchError(() => of(notFoundBenefit)));
	}
}
