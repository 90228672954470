import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-onboarding-layout',
	templateUrl: './onboarding-layout.component.html',
	styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
