import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { T } from '@transifex/angular';
import { Router } from '@angular/router';
import { ImmediateCareObj } from '../immediate-care.localize';

import { UIService } from 'src/app/services/ui.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { TelehealthService } from '../../telehealth/services/telehealth.service';
import { ProvidersSearchService } from 'src/app/services/providers-search/providers-search.service';
import { SearchEntity } from '../../main-layout/care-costs/helpers/providers-search.helper';
import { Observable } from 'rxjs';
import { PremiumFeature } from 'src/app/models/premium-feature.model';
import { PremiumFeatureService } from 'src/app/services/premium-feature.service';

const TWO_LINES_HIGHT = 50;

@Component({
	selector: 'app-immediate-care-card',
	templateUrl: './immediate-care-card.component.html',
	styleUrls: ['./immediate-care-card.component.scss'],
})
export class ImmediateCareCardComponent implements OnInit, AfterViewChecked {
	@Input() immediateCareObj: ImmediateCareObj;
	@ViewChild('benefitDescription') benefitDescription: ElementRef;

	public tooltipText: string;
	public expended: boolean = false;
	public showMoreTextPanel: boolean = false;
	public isMobile: boolean = UIService.isMobileDeviceScreen(window);
	public showTelehealthTeaser$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);


	@T('Not Covered')
	public tooltipTextForNotCovered: string;
	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may  incur additional costs."
	)
	public CopaySubjectToDeductibleIn: string;
	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, regardless of your deductible status. Some services provided during a visit may  incur additional costs."
	)
	public CopayNotSubjectToDeductibleIn: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may  incur additional costs."
	)
	public CoinsuranceSubjectToDeductibleIn: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, regardless of your deductible status/accumulation. Some services provided during a visit may  incur additional costs."
	)
	public CoinsuranceNotSubjectToDeductibleIn: string;

	constructor(
		private router: Router,
		private trackingService: TrackingService,
		private changeDetector: ChangeDetectorRef,
		private telehealthService: TelehealthService,
		private _providersSearchService: ProvidersSearchService,
		private premiumFeatureService: PremiumFeatureService
	) { }

	ngOnInit(): void {
		this.setData();
	}

	ngAfterViewChecked(): void {
		if (this.isMobile) this.setShowMoreTextPanel();
	}

	private setShowMoreTextPanel(): void {
		this.showMoreTextPanel = this.benefitDescription.nativeElement.offsetHeight >= TWO_LINES_HIGHT;
		this.changeDetector.detectChanges();
	}

	public onShowMoreText(): void {
		this.expended = !this.expended;
	}

	public onFindProvidersClicked(): void {
		this.trackingService.trackClientEvent('Immediate Care Choose option', { Type: this.immediateCareObj.title });
		if (this.immediateCareObj.index === 1) return this.telehealthService.onOpenTelehealth();

		const referrer = this.router.url;
		this._providersSearchService.goToProviderSearchWithValue(
			this.immediateCareObj.facilityType,
			SearchEntity.Facility,
			referrer
		);
	}

	private setData(): void {
		switch (this.immediateCareObj?.benefit?.covered === 'covered') {
			case true:
				this.tooltipText = this.getTooltip(
					this.immediateCareObj?.benefit?.inNetwork,
					this.immediateCareObj?.benefit?.subjectToDeductableIn
				);
				break;
			case false:
				this.tooltipText = this.tooltipTextForNotCovered;
				break;
		}
	}

	private getTooltip(networkData: string, subjectToDeductible: boolean): string {
		if (!networkData) return '';

		if (networkData.toLowerCase().includes('copay')) {
			return subjectToDeductible ? this.CopaySubjectToDeductibleIn : this.CopayNotSubjectToDeductibleIn;
		} else if (networkData.toLowerCase().includes('member')) {
			return subjectToDeductible
				? this.CoinsuranceSubjectToDeductibleIn
				: this.CoinsuranceNotSubjectToDeductibleIn;
		} else {
			return networkData;
		}
	}
}
