export const ReferralSortingOptions = [
	{
		name: 'Newest',
		value: 'newest',
	},
	{
		name: 'Oldest',
		value: 'oldest',
	},
];

export const SortingDefaultValue: string = ReferralSortingOptions[0].value;
