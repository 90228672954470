<div
	class="quick-access-item"
	*ngIf="!action"
	[routerLink]="link"
	routerLinkActive="active"
	(click)="onSendAnalytics()"
>
	<ng-container [ngTemplateOutlet]="contents"></ng-container>
</div>

<div class="quick-access-item" *ngIf="action" (click)="onSendAnalytics(); action()">
	<ng-container [ngTemplateOutlet]="contents"></ng-container>
</div>

<ng-template #contents>
	<div class="icon-wrapper">
		<img *ngIf="icon" [src]="'/assets/icons/shared/' + icon" />
		<div class="coming-soon" *ngIf="additionalIcon">
			{{ 'Coming soon' | translate }}
		</div>
	</div>

	<div class="quick-access-item-title">{{ title }}</div>
</ng-template>
