import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { THIS_DOT_ERROR_IMAGE_PATH, THIS_DOT_LOADING_IMAGE_PATH } from './use-http-image-source.injector';
import { UseHttpImageSourcePipe } from './use-http-image-source.pipe';

@NgModule({
	declarations: [UseHttpImageSourcePipe],
	exports: [UseHttpImageSourcePipe],
	imports: [CommonModule],
})
export class UseHttpImageSourceModule {
	static forRoot(
		config: { loadingImagePath?: string; errorImagePath?: string } = {}
	): ModuleWithProviders<UseHttpImageSourceModule> {
		return {
			ngModule: UseHttpImageSourceModule,
			providers: [
				{
					provide: THIS_DOT_LOADING_IMAGE_PATH,
					useValue: config.loadingImagePath || null,
				},
				{
					provide: THIS_DOT_ERROR_IMAGE_PATH,
					useValue: config.errorImagePath || null,
				},
			],
		};
	}
}
