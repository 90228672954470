<div class="category-box-container" (click)="onClick(specialty.value)">
	<div class="category-box-image-container">
		<img
			[src]="'/assets/icons/category/' + specialty.id + '.svg'"
			class="category-box-image"
			appDefaultImage="/assets/images/icons/gallery/cloud_no_image.svg"
		/>
	</div>
	<div class="category-box-title">
		{{ specialty.name }}
	</div>
</div>
