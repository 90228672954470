import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'copayToCoPayment',
	pure: true,
})
// MPI wants to use Co-payment instead of Copay
export class CopayToCoPaymentPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(value: any): SafeHtml {
		if (!value) {
			return '';
		}
		// Extract the raw string from SafeValueImpl if necessary
		let rawValue = value;
		if (typeof value === 'object' && value.changingThisBreaksApplicationSecurity) {
			rawValue = value.changingThisBreaksApplicationSecurity;
		}

		if (typeof rawValue !== 'string') {
			return '';
		}

		const searchText = 'copay';
		const checkOnCurrentCopayText = new RegExp(searchText, 'gi');

		const desiredCopayText = 'Co-Payment';
		if (checkOnCurrentCopayText.test(value)) {
			rawValue = rawValue.replace(checkOnCurrentCopayText, desiredCopayText);
		}
		// Sanitize and return the modified value
		return this.sanitizer.bypassSecurityTrustHtml(rawValue);
	}
}
