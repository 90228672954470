/*
Note: This model file was generated by extrapolating multiple Zoe Chat responses.
It may not not describe all the properties of a Chat Response in all cases
*/
export interface ChatResponse {
	output: Output;
	user_id?: string;
	coverageStatus?: CoverageStatus;
	serviceType?: ServiceTypeEnum;
	flow_type?: string;
	shouldTriggerLiveExpert?: boolean;
}

enum CoverageStatus {
	Covered = 'covered',
	NoCoverage = 'no_coverage',
	None = 'none',
}

interface Output {
	intents: IntentElement[];
	entities: Entity[];
	generic: Generic[];
	user_defined?: UserDefined;
	spelling?: Spelling;
}

interface Entity {
	entity: string;
	location: number[];
	value: string;
	confidence: number;
}

interface Generic {
	response_type: ResponseType;
	text?: string;
	title?: string;
	options?: Option[];
	type?: PurpleType;
	related?: Related[];
	planCareType?: PlanCareType;
	planType?: ServiceTypeEnum;
	name?: string;
	serviceType?: ServiceTypeEnum;
	hasRibbonData?: boolean;
	serviceObjTest?: ServiceObjTest;
	serviceId?: string;
	iconURL?: string;
	inNetwork?: string;
	outNetwork?: string;
	additionalNetworks?: any[];
	inNetworkSubjectToDeductibles?: boolean;
	outNetworkSubjectToDeductibles?: boolean;
	notes?: string[];
	groupName?: string;
	talonConnected?: boolean;
	individual?: GenericFamily;
	family?: GenericFamily;
	individualPlusOne?: IndividualPlusOne;
	contractType?: ServiceTypeEnum;
	outOfPocketMax?: OutOfPocketMax;
}

export enum ServiceTypeEnum {
	Dental = 'dental',
	Medical = 'medical',
	Vision = 'vision',
	Optum = 'optum',
	Pharmacy = 'pharmacy',
}

interface GenericFamily {
	inNetwork: InNetwork;
	outNetwork: InNetwork;
	additionalNetworks: any[];
}

interface InNetwork {
	text: string;
}

interface IndividualPlusOne {
	inNetwork: Data;
	outNetwork: Data;
	additionalNetworks: any[];
}

interface Data {}

interface Option {
	label: string;
	value: Value;
}

interface Value {
	input: InNetwork;
}

interface OutOfPocketMax {
	individual: FamilyElement[];
	family: FamilyElement[];
}

interface FamilyElement {
	maxAmount: number;
}

enum PlanCareType {
	Empty = '',
	Ppo = 'PPO',
}

interface Related {
	title: string;
	name: string;
	iconURL?: string;
}

enum ResponseType {
	InsurightsObject = 'insurightsObject',
	Option = 'option',
	Text = 'text',
}

interface ServiceObjTest {
	translation: Translation;
	_id: string;
	name: string;
	synonyms: string[];
	__v: number;
	tags: Tag[];
	type: ServiceTypeEnum;
	desc?: string;
	paterns: any[];
	relatedServices: RelatedService[];
	translations?: any[];
	abbreviate: string;
	title: string;
	showInList: boolean;
	searchProviders: boolean;
	trainNLP: boolean;
	iconURL?: string;
	ribbon?: string;
	insuranceTitle: any[];
	weight: number;
	preventive?: boolean;
}

interface RelatedService {
	translation: Translation;
	_id: string;
	name: string;
	abbreviate: string;
	title: string;
	type: ServiceTypeEnum;
	tags: Tag[];
	relatedServices: string[];
	paterns: any[];
	synonyms: string[];
	showInList: boolean;
	__v: number;
	searchProviders: boolean;
	trainNLP: boolean;
	iconURL?: string;
	insuranceTitle: any[];
	weight: number;
	ribbon?: string;
	desc?: string;
	translations?: any[];
	serviceCategory?: any[];
	preventive?: boolean;
}

enum Tag {
	The6200E645851A383A9F13Cf60 = '6200e645851a383a9f13cf60',
	The6200E645851A383A9F13Cf61 = '6200e645851a383a9f13cf61',
	The6200E645851A383A9F13Cf63 = '6200e645851a383a9f13cf63',
	The627Aaae330F3A9Ac937A41A8 = '627aaae330f3a9ac937a41a8',
}

interface Translation {
	es: Es;
}

interface Es {
	abbreviate: string;
	desc: string;
}

enum PurpleType {
	Deductibles = 'deductibles',
	DisconnectedImg = 'disconnected_img',
	GetData = 'get_data',
	GetDataMedical = 'get_data_medical',
}

interface IntentElement {
	intent: IntentEnum;
	confidence: number;
}

enum IntentEnum {
	Coverage = 'coverage',
	Providers = 'providers',
}

interface Spelling {
	text: string;
	original_text: string;
}

interface UserDefined {
	data: Data;
}
