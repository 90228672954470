import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DISABLED_BACK_NAVIGATION } from '../../../services/ui.service';
import { TalonWidgetComponent } from '../main-layout-shared/talon-widget/talon-widget.component';
import { CareAndCostsComponent } from './care-and-costs.component';
import { AnthemGuard } from 'src/app/guards/anthem-guard';

const routes: Routes = [
	{
		path: '',
		component: CareAndCostsComponent,
		data: {
			title: 'provider_search',
			ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION,
		},
		children: [
			{
				path: '',
				redirectTo: 'providers',
				pathMatch: 'full',
			},
			{
				path: 'providers',
				loadChildren: () =>
					import('./providers-search-page/providers-search-page.module').then(
						(loadedModule) => loadedModule.ProvidersSearchPageModule
					),
				data: { title: 'provider_search', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
			{
				loadChildren: () =>
					import('../../mpi/providers-search/providers-search-page/providers-search-page.module').then(
						(loadedModule) => loadedModule.MPIProvidersSearchPageModule
					),
				path: 'mp3-providers',
				data: { title: 'provider_search', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
				canActivate: [AnthemGuard],
			},
			{
				path: 'services',
				component: TalonWidgetComponent,
				data: { title: 'provider_search', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
		],
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'prefix',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CareAndCostsRoutingModule {}
