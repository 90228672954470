<div class="preventive-care-progress-container">
	<div class="progress-wrapper">
		<div class="progress-data">
			<div class="progress-number">{{ progress + registeredStep }}</div>
			<div class="total">/{{ total + registeredStep }}</div>
			<div class="text">{{ 'Completed' | translate }}</div>
		</div>
		<div class="progress-bar-container" (click)="onOpenPreventiveCare()">
			<ripple-progress-bar
				[percentageFilled]="((progress + registeredStep) / (total + registeredStep)) * 100 + '%'"
			></ripple-progress-bar>
		</div>
	</div>
	<img class="heart-img" src="/assets/images/wellness-heart.png" alt="heart" />
</div>
