export function getAdressFieldsFromGoogleAdressInput(addressComponents) {
	if (!Array.isArray(addressComponents)) {
		addressComponents = [];
	}

	const streetNumber = addressComponents.find((component) => component.types.includes('street_number'));
	const streetName = addressComponents.find((component) => component.types.includes('route'));
	const city =
		addressComponents.find((component) => component.types.includes('locality')) ||
		addressComponents.find((component) => component.types.includes('sublocality'));
	const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'));
	const zipCode = addressComponents.find((component) => component.types.includes('postal_code'));
	const valuesToPatch = getValuesToPatch(streetNumber, streetName, city, state, zipCode);

	return valuesToPatch;
}

export function getValuesToPatch(streetNumber, streetName, city, state, zipCode) {
	return {
		userAddress: (streetName?.long_name ? `${streetNumber?.long_name || ''} ${streetName?.long_name}` : '').trim(),
		userCity: city?.long_name || '',
		userState: state?.short_name || '',
		zipCode: zipCode?.long_name || '',
	};
}
