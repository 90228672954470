import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

import CFG from '../config/app-config.json';
import benefitsResponse from '../../dev-data/mock-backend/fake-data/benefits-response.json';
import zoeMessagesResponse from '../../dev-data/mock-backend/fake-data/zoe-messages.json';
import zoeChatResponse from '../../dev-data/mock-backend/fake-data/chat-response.json';

const TAG = 'MockBackendInterceptor';

const BENEFITS_ENDPOINT = '/benefitssummary';
const ZOE_MESSAGES_ENDPOINT = '/user/zoe-messages';
const CHAT_ENDPOINT = '/api/message';
@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!CFG.comm.interceptHttpRequestsToCreateAMockServer) {
			return next.handle(request);
		}

		return of(null).pipe(
			mergeMap(() => {
				if (request.url.endsWith(BENEFITS_ENDPOINT)) {
					console.log(TAG, `\n-------- intercepting['${BENEFITS_ENDPOINT}'] --------\n`);
					return this.ok(benefitsResponse);
				} else if (request.url.endsWith(ZOE_MESSAGES_ENDPOINT)) {
					console.log(TAG, `\n-------- intercepting['${ZOE_MESSAGES_ENDPOINT}'] --------\n`);
					return this.ok(zoeMessagesResponse);
				} else if (request.url.endsWith(CHAT_ENDPOINT)) {
					console.log(TAG, `\n-------- intercepting['${CHAT_ENDPOINT}'] --------\n`);
					return this.ok(zoeChatResponse);
				} else {
					console.log(TAG, 'Skipping request.url', request.url);
				}

				return next.handle(request);
			}),
			materialize(),
			delay(1000),
			dematerialize()
		);
	}

	private ok(body) {
		return of(new HttpResponse({ status: 200, body }));
	}

	private unauthorised() {
		return throwError({ status: 401, error: { message: 'Unauthorised' } });
	}

	private error(body) {
		return throwError({ status: 400, body: body });
	}
}
