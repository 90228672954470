<div class="card-container" *ngFor="let item of data">
	<app-referrals-card [item]="item"></app-referrals-card>
</div>

<div class="loader" *ngIf="isLoading$ | async">
	<app-loader [size]="'medium'"></app-loader>
</div>

<app-referrals-no-results
	[marginTop]="true"
	*ngIf="(isLoading$ | async) === false && (referralsInfo$ | async).length === 0"
></app-referrals-no-results>
