import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';

@Component({
	selector: 'app-service-panel-footer',
	templateUrl: './service-panel-footer.component.html',
	styleUrls: ['./service-panel-footer.component.scss'],
})
export class ServicePanelFooterComponent extends CommonComponent {
	@Input()
	displayFindProviders: boolean;

	@Output()
	feedbackClick = new EventEmitter<void>();

	@Output()
	findProviderClick = new EventEmitter<void>();

	constructor() {
		super();
	}
}
