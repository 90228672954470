import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { HealtheeDialogContent } from '../../../app-shared/healthee-dialog/healthee-dialog.component';
@Component({
	selector: 'app-blocked-account-modal',
	templateUrl: './blocked-account-modal.component.html',
	styleUrls: ['./blocked-account-modal.component.scss'],
})
export class BlockedAccountModalComponent extends HealtheeDialogContent implements OnInit {
	@ViewChild('recoverEmail') recoverEmail: ElementRef;

	constructor() {
		super();
	}

	ngOnInit(): void {}

	onCloseClicked(): void {
		this.closeDialog.emit(null);
	}
}
