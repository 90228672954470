<div class="health-card-teaser-title">
	{{ 'My Member ID' | translate }}

	<mat-icon (click)="dismiss()">close</mat-icon>
</div>
<div class="health-card-teaser-text">
	{{ 'View and unlock your digital member cards to easily use when and where you need. ' | translate }}
</div>
<ripple-button
	(onClick)="showCardsClick.emit()"
	[label]="'Show your cards' | translate"
	[hierarchy]="ButtonHierarchy.secondary"
></ripple-button>
