import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthCardsTeaserComponent } from './health-cards-teaser/health-cards-teaser.component';
import { TxNativeModule } from '@transifex/angular';
import { RippleModule } from 'ripple';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [HealthCardsTeaserComponent],
	imports: [CommonModule, TxNativeModule, RippleModule, MatIconModule],
	exports: [HealthCardsTeaserComponent],
})
export class HealthCardsTeaserModule {}
