import { Injectable } from '@angular/core';
import { PreventiveCareApiService } from './preventive-care-api.service';
import { updateCompletedDateStatusIfNeeded } from '../modules/preventive-care/helpers/helpers';
import { Observable, filter, map, switchMap } from 'rxjs';
import { UserService } from './user.service';
import { UserData } from '../models/user-data';

export type PreventiveCare = {
	id: number;
	serviceId: string;
	name: string;
	shortDescription: string;
	longDescription: string;
	frequency: number;
	providerType: string;
	isPinned?: boolean;
	completedDate?: Date;
	passedCompletedDate?: Date;
	userCoverage?: string;
};

export type PreventiveCareParams = {
	id: number;
	name: string;
	frequency: number;
	completedDate?: Date;
};

@Injectable({
	providedIn: 'root',
})
export class PreventiveCareService {
	constructor(private preventiveCareApiService: PreventiveCareApiService, private userService: UserService) { }

	public updatePinnedState(id: number, isPinnedNewState: boolean): Observable<any> {
		return this.preventiveCareApiService.savePinnedState(id, isPinnedNewState);
	}

	public saveCompletedDate(id: number, date: Date): Observable<any> {
		return this.preventiveCareApiService.saveCompletedDate(id, date);
	}

	public clearCompletedDate(id: number): Observable<any> {
		return this.preventiveCareApiService.clearCompletedDate(id);
	}

	public getSortedByPinnedAndCompleted(preventiveCares: PreventiveCare[]): PreventiveCare[] {
		const pinned = [];
		const completed = [];
		const others = [];
		preventiveCares.forEach((item: any) => {
			if (item.isPinned && !item.completedDate) {
				pinned.push(item);
			} else if (item.completedDate) {
				completed.push(item);
			} else {
				others.push(item);
			}
		});

		return [...pinned, ...others, ...completed];
	}

	public normalizeData(preventiveCares: PreventiveCare[], benefitsMap): PreventiveCare[] {
		return preventiveCares.map((item) => {
			if (item.completedDate) {
				item.completedDate = new Date(item.completedDate);
				item = updateCompletedDateStatusIfNeeded(item);
			}

			const userBenefit = benefitsMap.get(item.serviceId);
			item.userCoverage = userBenefit?.inNetwork;

			return item;
		});
	}

	public isMissingUserDataForPreventiveCare(): Observable<boolean> {
		return this.userService.userData$.pipe(
			map((userData) => {
				if (!userData.birthday) return true;
				if (!userData.gender || userData.gender === 'other') return true;

				return false;
			})
		);
	}

	public getIsPreventiveCareInit(): Observable<boolean> {
		return this.userService.userData$.pipe(map((userData: UserData) => userData.isHomePagePreventiveCareInit));
	}

	public setIsPreventiveCareInit(): void {
		this.userService.userData$
			.pipe(
				map((userData: UserData) => userData.isHomePagePreventiveCareInit),
				filter((isHomePagePreventiveCareInit) => !isHomePagePreventiveCareInit),
				switchMap(() => this.userService.setIsPreventiveCareInit())
			)
			.subscribe();
	}
}
