import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	of,
	Subject,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';

@Component({
	selector: 'ripple-autocomplete-input',
	templateUrl: './autocomplete-input.component.html',
	styleUrls: ['./autocomplete-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutocompleteInputComponent),
			multi: true,
		},
	],
})
export class AutocompleteInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
	@Input() label?: string;
	@Input() type = 'text';
	@Input() leadingIcon?: string;
	@Input() placeholder = '';
	@Input() panelWidth = 'auto';
	@Input() displayFn?: (data: unknown) => string;
	@Input() optionsTemplate!: TemplateRef<unknown>;
	@Input() dataProvider!: (v: string) => Observable<unknown[]>;
	@Input() focusOnInit?: boolean = false;
	@Input() unmaskInSessions: boolean = false;

	@Output() onOptionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter();

	@ViewChild(InputComponent) rippleInput: InputComponent;

	showLoading = false;
	control: FormControl<unknown> = new FormControl();
	options$: Observable<unknown[]>;
	autocompleteCssClasses = ['ripple-autocomplete'];

	private unsubscribe$ = new Subject<void>();

	onChange: (value: string | Object) => void = () => {};
	onTouched: () => void = () => {};

	constructor() {}

	ngOnInit(): void {
		// Initializing options observable that does all the boilerplate part of filtering, debouncing and taking care
		// of loader. It also utilizes the dataProvider function that is passed as an input to fetch the data from
		// outside the component.
		this.options$ = this.control.valueChanges.pipe(
			tap(() => this.hideLoading()),
			debounceTime(200),
			distinctUntilChanged(),
			// Filtering out null, undefined and empty values. Also filtering out object values to avoid extra call when
			// selecting an option from autocomplete.
			filter((v) => v != null && v !== '' && typeof v !== 'object'),
			tap(() => {
				this.showLoading = true;
			}),
			switchMap(this.dataProvider),
			// Catching error in case dataProvider does not handle the errors.
			catchError((error) => {
				console.error(error, 'from autocomplete');

				return of([]);
			}),
			tap(() => {
				this.showLoading = false;
			})
		);

		this.control.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
			this.onChange(value);
		});

		if (this.leadingIcon) {
			this.autocompleteCssClasses.push('input-with-icon');
		}
	}

	writeValue(value: string | Object): void {
		this.control.setValue(value);
	}

	registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.control.disable();
		} else {
			this.control.enable();
		}
	}

	selectOption(event: MatAutocompleteSelectedEvent) {
		this.onOptionSelected.emit(event);
	}

	closePanel() {
		this.rippleInput.autocompleteTrigger?.closePanel();
	}

	hideLoading() {
		this.showLoading = false;
	}

	focus() {
		this.rippleInput.focus();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
