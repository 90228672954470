import { StoreUpdateRegistry } from './store-update-registry';
import { tap } from 'rxjs';

export abstract class StoreApi {
	protected constructor(private _storeUpdateRegistry: StoreUpdateRegistry) {}

	// tslint:disable-next-line:ban-types
	protected withStoreUpdate = <T>(token: symbol, context: unknown) =>
		tap<T>(() => this._storeUpdateRegistry.registerUpdate(token, context));

	// tslint:disable-next-line:ban-types
	protected withStoreTotalUpdate = <T>(token: symbol) =>
		tap<T>(() => this._storeUpdateRegistry.registerTotalUpdate(token));
}
