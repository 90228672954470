import { Amplify, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { MFAMethods } from "../../../services/user.service";
import { EnvironmentConfigurationInterface } from "../../../../environments/environment.interface";
import { AppError } from "../../../error-handlers/app-errors";
import { StorageService } from "../../../services/storage.service";
import { UrlService } from '../../../services/url.service';
import { SessionStorageService } from '../../../services/sessionStorage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public cognitoUser: CognitoUser & { challengeParam: { email: string; phoneNumber: string } };
	private static _isFederatedLogin: boolean = false;
	private static _idpName: string;
	private static _idpPrettyName: string;
	private static _isUsingSessionStorage: boolean = false;

	constructor(private storageService: StorageService) {}

	static configureClient(appConfig: EnvironmentConfigurationInterface) {
		let oauthConfig = {};
		let userPoolWebClientId = appConfig.cognito.defaultClient;
		const idpConfig = this.idpConfig(appConfig) || this.previousUrlConfig(appConfig) || this.subDomainConfig(appConfig);
		if (idpConfig) {
			AuthService._isFederatedLogin = true;
			AuthService._idpName = idpConfig.idpName;
			AuthService._idpPrettyName = idpConfig.vendorPrettyName;
			userPoolWebClientId = idpConfig.userPoolWebClientId;
			oauthConfig = {
				domain: appConfig.cognito.cognitoDomain,
				scope: idpConfig.oauth.scope,
				redirectSignIn: idpConfig.oauth.redirectSignIn,
				userPoolId: appConfig.cognito.userPoolId,
				userPoolWebClientId: idpConfig.userPoolWebClientId,
				redirectSignOut: idpConfig.oauth.redirectSignOut,
				responseType: idpConfig.oauth.responseType,
			};
		}

		let storage = undefined;
		if (idpConfig && idpConfig['sessionStorage']) {
			AuthService._isUsingSessionStorage = true;
			storage = window.sessionStorage;
		}

		const amplifyConfig = {
			Auth: {
				region: appConfig.cognito.region,
				userPoolId: appConfig.cognito.userPoolId,
				userPoolWebClientId: userPoolWebClientId,
				oauth: oauthConfig,
				storage: storage
			},
		};

		Amplify.configure(amplifyConfig);
	}

	static get idpName(): string {
		return this._idpName;
	}

	static get idpPrettyName(): string {
		return this._idpPrettyName;
	}
	static get isFederatedLogin(): boolean {
		return this._isFederatedLogin;
	}

	static isUsingSessionStorage(): boolean {
		return this._isUsingSessionStorage;
	}

	async emailFromActivationToken(activationToken: string): Promise<string> {
		return fetch(`${environment.authAdminApi}/user/activation/decode-token?token=${activationToken}`)
			.then(async (response) => {
				const body = await response.json();
				return String(body.email);
			})
			.catch((error) => {
				console.log('error decoding activation token: ', error);
				return undefined;
			});
	}

	async startSmsFlow(email: string, phoneNumber?: string, extraClientMetadata?: { [id: string]: string }) {
		try {
			await this.signIn(email, 'sms');
			await this.smsOptChallenge('DONT_CARE', phoneNumber, extraClientMetadata);
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}
	}

	async startDefaultOtpLoginFlow(email: string) {
		try {
			await this.signIn(email, 'sms');
			this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, 'DONT_CARE', { signInMethod: 'DEFAULT_OTP'});
			await this.isAuthenticated();
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}
	}

	async startEmailFlow(email: string, token?: string) {
		try {
			await this.signIn(email, 'sms');
			await this.emailOptChallenge('DONT_CARE', token);
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}

	}

	async startFederatedFlow() {
		await Auth.federatedSignIn({ customProvider: AuthService._idpName });
	}

	public async signIn(email: string, signInMethod: string) {
		this.cognitoUser = await Auth.signIn(email.toLowerCase(), undefined, {
			signInMethod: signInMethod,
		});
	}

	public async smsOptChallenge(
		answer: string,
		phoneNumber?: string,
		extraClientMetadata?: { [id: string]: string }
	): Promise<boolean> {
		console.log('answerCustomChallenge with answer: ', answer);
		const clientMetadata = { signInMethod: 'SMS_OTP', ...extraClientMetadata };

		if (phoneNumber) {
			clientMetadata['phone_number'] = phoneNumber;
		}

		this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer, clientMetadata);

		console.log('answerCustomChallenge cognitoUser: ', this.cognitoUser);

		return this.isAuthenticated();
	}

	public async emailOptChallenge(answer: string, token?: string): Promise<boolean> {
		console.log('answerCustomChallenge with answer: ', answer);
		const clientMetadata = { signInMethod: 'EMAIL_OTP' };

		if (token) {
			clientMetadata['initToken'] = token;
		}

		this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer, clientMetadata);

		console.log('answerCustomChallenge cognitoUser: ', this.cognitoUser);

		return this.isAuthenticated();
	}

	public async getJwt(): Promise<string> {
		const session = await Auth.currentSession();
		return session.getIdToken().getJwtToken();
	}

	async signInIdp(idpName?: string) {
		await Auth.federatedSignIn(idpName ? { customProvider: idpName } : undefined);
	}
	public async signOut(isSoftLogout: boolean) {
		try {
			if (isSoftLogout) {
				await this.softSignout();
			} else {
				await Auth.signOut();
			}
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	public async isAuthenticated() {
		try {
			await Auth.currentSession();
			return true;
		} catch {
			return false;
		}
	}

	public async getUserDetails() {
		if (!this.cognitoUser) {
			this.cognitoUser = await Auth.currentAuthenticatedUser();
		}
		return await Auth.userAttributes(this.cognitoUser);
	}
	private async getUserAttribute(attributeName: string) {
		const promise = await this.getUserDetails().then((attribute) =>
			attribute.filter((e) => e.Name === attributeName).map((e) => e.getValue())
		);

		return promise.length > 0 ? promise[0] : null;
	}

	async userHasVerifiedPhone() {
		return (await this.getUserAttribute('phone_number_verified')) == 'true';
	}

	async isOtpSmsActivated() {
		return (await this.getUserAttribute('custom:default_auth_method')) == 'sms';
	}

	public async updateUserPhone(phone: string) {
		return await this.updateUserAttribute('phone_number', phone);
	}

	public async updateUserDefaultMfaMethod(mfaMethod: MFAMethods) {
		return await this.updateUserAttribute('custom:default_auth_method', mfaMethod);
	}
	public async changePhoneNumberAnswer(answer: string): Promise<boolean> {
		const result = await Auth.verifyCurrentUserAttributeSubmit(
			'phone_number', // The attribute name
			answer
		);

		return result === 'SUCCESS';
	}

	public async forceRefreshToken() {
		await Auth.currentAuthenticatedUser({ bypassCache: true });
	}

	public async softSignout() {
		const localStorageItems = { ...localStorage };
		for (const key in localStorageItems) {
			if (key.startsWith('CognitoIdentityServiceProvider')){
				localStorage.removeItem(key)
			}
		}

		this.storageService.removeUserData()
	}
	private static subDomainConfig(appConfig: EnvironmentConfigurationInterface) {
		for (const clientsKey in appConfig.cognito.clients) {
			if (document.location.hostname.startsWith(appConfig.cognito.clients[clientsKey].appSubDomain)) {
				return appConfig.cognito.clients[clientsKey];
			}
		}

		return undefined;
	}

	private static previousUrlConfig(appConfig: EnvironmentConfigurationInterface) {
		const urlService = new UrlService(new SessionStorageService());
		const previousUrl = urlService.getPreviousUrl();
		const queryParams = JSON.parse(previousUrl.queryParams);
		if (queryParams && queryParams['idp'] && appConfig.cognito.clients[queryParams['idp']]) {
			return appConfig.cognito.clients[queryParams['idp']];
		}
		return undefined;
	}

	private static idpConfig(appConfig: EnvironmentConfigurationInterface) {
		const searchParams = new URLSearchParams(document.location.search);
		const idp = searchParams.get('idp') || localStorage.getItem('idp');
		if (idp && appConfig.cognito.clients[idp]) {
			return appConfig.cognito.clients[idp];
		}

		return undefined;
	}

	private handleStartOtpFlowError(error: Error) {
		throw new CognitoOtpError(error.message, error);
	}

	private async updateUserAttribute(attributeName: string, attributeValue: string) {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const result = await Auth.updateUserAttributes(user, {
				[attributeName]: attributeValue,
			});
			console.log('updateUserAttribute result: ', result);
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

}
export class CognitoOtpError extends AppError {
	constructor(public message: string, public originalError?: Error) {
		super(message, null, originalError);

		Object.setPrototypeOf(this, CognitoOtpError.prototype);
	}
}
