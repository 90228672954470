import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterOutArrayByPropValue', pure: false })
export class FilterOutArrayByPropertyValue implements PipeTransform {
	/** 'filterOutArrayByPropValue' Pipe - filters elements with property and value out of an of objects.
	 * Usage in html: *ngFor="let message of messages$ | async | filterOutArrayByPropValue:'propertyName':filterBooleanValue".
	 * This filter can be disabled, by setting 'filterIsEnabled' to false, for example: *ngFor="let message of zoeMessages$ | async | filterOutArrayByPropValue:'isRead':false:enableFilter"
	 * @param  {any[]} arrayOfObjects - array of objects to filter
	 * @param  {any} filteringPropertyName - the property of objects in array, to check its value
	 * @param  {any} filteringValue - the value to filter out
	 * @param  {boolean=true} filterIsEnabled - enable/disable filter. Defaults to true.
	 * @returns string
	 */
	transform(
		arrayOfObjects: any[],
		filteringPropertyName: any,
		filteringValue: any,
		filterIsEnabled: boolean = true
	): string[] {
		if (!arrayOfObjects || !filteringPropertyName || !filterIsEnabled) return arrayOfObjects;

		return arrayOfObjects.filter((item) => item[filteringPropertyName] !== filteringValue);
	}
}
