import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, map, startWith, switchMap, of } from 'rxjs';
import { retrieveDeductiblesFromPlan, defaultDeductibles } from '../../helpers/home-page.helpers';
import { DeductibleTeaserLocalize } from './deductible-teaser.localize';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { TrackingService } from '../../../../services/tracking.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { DeductiblesStoreService } from '../../../../services/stores/deductibles-store/deductibles-store.service';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { T } from '@transifex/angular';
import { Deductible } from '../../../../services/api/domain/deductible';
import { UIService } from '../../../../services/ui.service';
import { hasTrulyValue } from 'src/app/utils/utils';

@Component({
	selector: 'app-deductible-teaser',
	templateUrl: './deductible-teaser.component.html',
	styleUrls: ['./deductible-teaser.component.scss'],
})
export class DeductibleTeaserComponent implements OnInit {
	public isMobile$ = this.uiService.isMobile$;
	public isConnected$ = this.deductiblesStoreService.isConnected();
	public hasRibbonInsurancePartner$ = this.userPlanDataStoreService.hasRibbonInsurancePartner();
	public hasMedicalPlanByKayser$ = this.userPlanDataStoreService.hasMedicalPlanByKayser();

	@ViewChild('deductiblesForm')
	deductiblesForm: TemplateRef<any>;

	@T('Connect your plan')
	connectFormModalTitle: string;

	@T('Connect your insurance account to see how much more you’ll pay before the rest of your coverage kicks in.')
	connectFormModalDescription: string;

	HomePageLocalize = DeductibleTeaserLocalize;

	deductibles$: Observable<{ inNetwork: string; outNetwork: string }>;

	isIndividualData$: Observable<boolean>;
	deductibleDetailsCardData$: Observable<unknown>;
	outOfPocketDetail$: Observable<unknown>;

	@Input() isDeductibleSyncing: boolean = false;

	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private deductiblesStoreService: DeductiblesStoreService,
		public healtheeDialogService: HealtheeDialogService,
		private trackingService: TrackingService,
		private uiService: UIService,
	) {}

	ngOnInit(): void {
		this.deductibles$ = this.userPlanDataStoreService
		.get()
		.pipe(RxjsUtils.isNotNil(), map(retrieveDeductiblesFromPlan), startWith(defaultDeductibles));

		const connectedDeductiblesData$ = this.deductiblesStoreService.get();

		this.isIndividualData$ = connectedDeductiblesData$.pipe(
			switchMap((deductible: Deductible) => {
				const famDeductibleValueInNet = deductible?.data?.deductibleDetail?.family.inNetwork;
				const famDeductibleValueOutNet = deductible?.data?.deductibleDetail?.family.outOfNetwork;
				return of(!(hasTrulyValue(famDeductibleValueInNet) || hasTrulyValue(famDeductibleValueOutNet)));
			})
		);

		this.deductibleDetailsCardData$ = connectedDeductiblesData$.pipe(
			switchMap((deductible: Deductible) => {
				const famDeductibleValueInNet = deductible?.data?.deductibleDetail?.family.inNetwork;
				const famDeductibleValueOutNet = deductible?.data?.deductibleDetail?.family.outOfNetwork;

				if (hasTrulyValue(famDeductibleValueInNet) || hasTrulyValue(famDeductibleValueOutNet)) {
					return of(deductible?.data?.deductibleDetail?.family);
				}
				return of(deductible?.data?.deductibleDetail?.individual);
			})
		);

		this.outOfPocketDetail$ = connectedDeductiblesData$.pipe(
			switchMap((deductible: Deductible) => {
				const famDeductibleValueInNet = deductible?.data?.deductibleDetail?.family.inNetwork;
				const famDeductibleValueOutNet = deductible?.data?.deductibleDetail?.family.outOfNetwork;
				if ((hasTrulyValue(famDeductibleValueInNet) || hasTrulyValue(famDeductibleValueOutNet))) {
					return of(deductible?.data?.outOfPocketDetail?.family);
				}
				return of(deductible?.data?.outOfPocketDetail?.individual);
			})
		);
	}

	openConnectionForm() {
		this.healtheeDialogService.open({
			title: this.connectFormModalTitle,
			templateRef: this.deductiblesForm,
		});
		this.trackingService.trackClientEvent('Deductible Connection start', { Source: 'Home Page' });
	}
}
