import { Injectable } from '@angular/core';

import { Observable, combineLatest, filter, of, switchMap } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UIService } from '../services/ui.service';
import {
	PLAN_SELECTION_CLOSED_PATH,
	PLAN_SELECTION_ONLY_PATH,
	PLAN_SELECTION_PATH,
} from '../modules/main-layout/main-layout.constants';
import { PlanSelectionStoreService } from '../services/stores/plan-selection-store/plan-selection-store.service';
import CFG from 'src/app/config/app-config.json';

@Injectable({
	providedIn: 'root',
})
export class PCTOnlyGuard implements CanActivate {
	public isPlanSelectionOpen$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();

	constructor(
		private router: Router,
		private uiService: UIService,
		private planSelectionStoreService: PlanSelectionStoreService
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return combineLatest([this.isPlanSelectionOpen$, this.uiService.PCTOnlyMode$]).pipe(
			filter(([isPlanSelectionOpen, isPctOnlyMode]) => isPlanSelectionOpen !== null && isPctOnlyMode !== null),
			switchMap(([isPlanSelectionOpen, isPctOnlyMode]) => {
				if (isPlanSelectionOpen) {
					if (state.url === `/${PLAN_SELECTION_CLOSED_PATH}`) {
						return of(
							this.uiService.PCTOnlyMode
								? this.router.parseUrl(PLAN_SELECTION_ONLY_PATH)
								: this.router.parseUrl(PLAN_SELECTION_PATH)
						);
					}
					return of(true);
				} else if (isPctOnlyMode) {
					if (state.url !== `/${PLAN_SELECTION_CLOSED_PATH}`) {
						return of(this.router.parseUrl(PLAN_SELECTION_CLOSED_PATH));
					}

					return of(true);
				}

				return of(this.router.createUrlTree([CFG.defaultAppRoute]));
			})
		);
	}
}
