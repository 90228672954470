import { EnvironmentConfigurationInterface } from './environment.interface';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfigurationInterface = {
    production: false,
    environmentType: 'web',
    envName: 'dev',
    baseUrl: 'https://app.dev.healthee.co',
    unleashURL: 'https://ff-dev.healthee.co/proxy',
    language: 'en',
    ionicDeploymentChannelName: 'None',
    intercomAppId: 'bazufyww',
    mixpanelProjectToken: '75ce5ed5247978f652dade78e50d9636',
    mixpanelProjectTokenPCT: 'c5da4b422bf1f14da3ce4f68f20fc050',
    clientLogApiUrl: 'https://logging-hooks.dev.healthee.team/app-logs',
    // clientLogApiUrl: 'https://ke6f11b9t8.execute-api.us-east-1.amazonaws.com/client-log',
    enableSentry: true,

    serverPort: 443,

    /**
     * Development only -
     * @useAltServer - use to run nodejs server on a server other than the angular server
     * @altServer - change to match the hostname and port of the machine running the nodejs server
     */
    useAltServer: true,
    debugAnalytics: false,

    altServer: {
        hostname: 'https://api.dev.healthee.team',
        port: 443,
    },

    mpiServer: {
        hostname: 'https://mpi-api.dev.healthee.team',
        port: 443,
    },

    servicesSearchURL: 'https://search-service.dev.healthee.team/search/services',
    gipitpotURL: 'https://chat-api.dev.healthee.team/demo',

    authAdminApi: 'https://auth-admin-api.dev.healthee.team',
    cognito: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_pFmJbZrsM',
        // healthee client
        defaultClient: '6o37leerbu55nm8dcimc0lcs98',
        cognitoDomain: 'auth.dev.healthee.team',
        clients: {
            trinet: {
                vendorPrettyName: 'Trinet',
                idpName: 'trinet2',
                appSubDomain: 'trinet',
                userPoolWebClientId: '13s02m0kmni17a5epc0uscjml7',
                oauth: {
                    scope: ['openid'],
                    responseType: 'token',
                    redirectSignIn: 'https://trinet.dev.healthee.team/account/login',
                    redirectSignOut: 'https://trinetqen2.hrpassport.com/',
                }
            },
            mpi: {
                vendorPrettyName: 'MPI',
                idpName: 'mpi',
                appSubDomain: 'mpi',
                userPoolWebClientId: '3moitt6tmaui28ahmnh3lra27v',
                sessionStorage: true,
                oauth: {
                    scope: ['openid'],
                    responseType: 'token',
                    redirectSignIn: 'https://mpi.dev.healthee.team/account/login',
                    redirectSignOut: 'https://mpi.dev.healthee.team/account/login/',

                }
            },
        },
    }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
