import { Component, EventEmitter, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MPIProvidersSearchService } from '../../../../../services/providers-search/mpi-providers-search.service';
import { RxjsUtils } from '../../../../../utils/rxjs';
import { SearchEntity } from '../../helpers/providers-search.helper';

@Component({
	selector: 'app-mpi-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
	@Output() goToPage: EventEmitter<number> = new EventEmitter<number>();

	public paginationData$: Observable<any>;
	public isMixSearchLastSearch: boolean = false;

	constructor(private providersSearchService: MPIProvidersSearchService) {
		this.initPaginationData();
	}

	private initPaginationData(): void {
		this.paginationData$ = this.providersSearchService.results$.pipe(
			RxjsUtils.isNotNil(),
			map((results) => {
				const activePage = results.page;
				const pagesNumber = results.totalCount / results.pageSize;
				const pagesNumberWoDecimal = Math.ceil(pagesNumber);
				const pages = Array(pagesNumberWoDecimal)
					.fill(1)
					.map((n, i) => n + i);
				const maxPage = pages[pages.length - 1];

				this.isMixSearchLastSearch = results.entity === SearchEntity.SearchByNeed && results.isLastPage;

				return { activePage, pages, maxPage };
			})
		);
	}

	public onPageClick(activePage: number): void {
		this.goToPage.emit(activePage);
	}
}
