import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-screen-overlay',
	templateUrl: './screen-overlay.component.html',
	styleUrls: ['./screen-overlay.component.scss'],
})
export class ScreenOverlayComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
