import { T } from '@transifex/angular';

export class CharterSchoolLocalize {
	@T('Claims Submission')
	static claimsSubmission: string;
	@T('Utilization')
	static utilization: string;
	@T(
		'Precert Req: All IP & SNF, OP surgeries, Dialysis, Oncology, Home Health/Hospice, MRI/MRA/PET scans, Transplants, IOP or PHP, Genetic Testing, & all DME rentals & DME purchases >$1500'
	)
	static utilizationContentPartOne: string;
	@T('We encourage you to use a Primary Care Provider as a valuable resource and personal health advocate.')
	static utilizationContentPartTwo: string;

	@T('Medical Network')
	static medicalNetwork: string;
	@T(
		'Providers are reimbursed pursuant to the terms of the Plan Document up to the Reasonable and Allowable Amount (subject to reference pricing).  Only Physician and Ancillary services may be subject to a PPO Network.  The Plan will only consider an Assignment of Benefits (AOB) valid under the condition that the Provider accepts the payment received from the Plan as consideration in full for the services, supplies and/or treatment rendered, less any required deductibles/copays/coinsurance'
	)
	static medicalNetworkContent: string;

	@T('Important information')
	static ImportantInformation: string;
	@T(
		`Assignment of Benefits (AOB) is a waiver of the Provider's right to balance bill the patient. Depositing checks received from the Plan represents accord and satisfaction and will take precedence over any previous terms. Please see Plan Document or contact a Care Coordinator at 855-924-5291.`
	)
	static ImportantInformationContent: string;

	@T('This card does not guarantee eligibility or payment.')
	static noGuarantee: string;

	@T('Open Access Plus')
	static openAccessPlus: string;

	@T('No Referral Required')
	static noReferralRequired: string;

	@T('Covered Person')
	static coveredPerson: string;

	@T('Participant ID')
	static participantId: string;

	@T('Effective Date')
	static effective: string;

	@T('Dependent(s)')
	static dependents: string;

	@T('Deductible')
	static deductible: string;

	@T('Call')
	static call: string;

	@T('Plan Opt')
	static planOpt: string;

	@T('In Network/Non Network')
	static inNetworkNonNetwork: string;

	@T('Individual/Family')
	static individualFamily: string;
}
