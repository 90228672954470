import { ContentNodeType, NodeType, TemplateType } from '../shared';
import { BlackhawkLocalize } from './blackhawk.localize';

export enum blackhawkNetworkNames {
	HealthSmart = 'HealthSmart',
	FirstHealth = 'FirstHealth',
	PHCS = 'PHCS',
	RBP = 'RBP',
	FrontPath = 'FrontPath',
}

export const BlackhawkCardTemplates = {
	firsthealth_medwatch: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: BlackhawkLocalize.preCertificationContentMedWatch,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIDDLE_HEADLINE,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.guaranteeText,
						type: NodeType.TEXT,
					},
				],
				middleHeadline: BlackhawkLocalize.contactInformation,
				contentSecondry: [
					{
						left: { value: BlackhawkLocalize.providerLocatorHelp, type: NodeType.TEXT },
						right: { value: '(800) 226-5116', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.providerLocatorSearch, type: NodeType.TEXT },
						right: { value: 'myfirsthealth.com', type: NodeType.LINK },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	healthsmart_medwatch: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: BlackhawkLocalize.preCertificationContentMedWatch,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIDDLE_HEADLINE,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.guaranteeText,
						type: NodeType.TEXT,
					},
				],
				middleHeadline: BlackhawkLocalize.contactInformation,
				contentSecondry: [
					{
						left: { value: BlackhawkLocalize.providerLocatorHelp, type: NodeType.TEXT },
						right: { value: '(800) 687-0500', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.providerLocatorSearch, type: NodeType.TEXT },
						right: { value: 'HealthSmart.com', type: NodeType.LINK },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	firsthealth_medireview: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: `To complete pre authorization or questions contact MediReview at (888) 850-0281`,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIDDLE_HEADLINE,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.guaranteeText,
						type: NodeType.TEXT,
					},
				],
				middleHeadline: BlackhawkLocalize.contactInformation,
				contentSecondry: [
					{
						left: { value: BlackhawkLocalize.providerLocatorHelp, type: NodeType.TEXT },
						right: { value: '(800) 226-5116', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.providerLocatorSearch, type: NodeType.TEXT },
						right: { value: 'myfirsthealth.com', type: NodeType.LINK },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	healthsmart_medireview: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: `To complete pre authorization or questions contact MediReview at (888) 850-0281`,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIDDLE_HEADLINE,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.guaranteeText,
						type: NodeType.TEXT,
					},
				],
				middleHeadline: BlackhawkLocalize.contactInformation,
				contentSecondry: [
					{
						left: { value: BlackhawkLocalize.providerLocatorHelp, type: NodeType.TEXT },
						right: { value: '(800) 687-0500', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.providerLocatorSearch, type: NodeType.TEXT },
						right: { value: 'HealthSmart.com', type: NodeType.LINK },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	rbp_medwatch: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: BlackhawkLocalize.preCertificationContentMedWatch,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.preCertificationText,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	rbp_medireview: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: `To complete pre authorization or questions contact MediReview at (888) 850-0281`,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.preCertificationText,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	phcs_medwatch: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'blackhawk',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'Blackhawk Claims Service GA, Inc.', type: NodeType.TEXT },
					{ value: 'PO BOX 21661', type: NodeType.TEXT },
					{ value: 'Eagan, MN 55121', type: NodeType.TEXT },
				],
				rightContent: [{ value: 'EDI#CB987', type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.preCertification,
				content: [
					{
						value: BlackhawkLocalize.preCertificationContentMedWatch,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'welldyne',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: 'WDRX', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '008878', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: 'RWTFRKHWK', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.memberServices, type: NodeType.TEXT },
						right: { value: '(888) 886-5822', type: NodeType.TEXT },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'aptive',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{
						value: BlackhawkLocalize.providerInquiriesContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIDDLE_HEADLINE,
				logo: '',
				content: [
					{
						value: BlackhawkLocalize.guaranteeText,
						type: NodeType.TEXT,
					},
				],
				middleHeadline: BlackhawkLocalize.contactInformation,
				contentSecondry: [
					{
						left: { value: BlackhawkLocalize.providerLocatorHelp, type: NodeType.TEXT },
						right: { value: '(800) 922-4362', type: NodeType.TEXT },
					},
					{
						left: { value: BlackhawkLocalize.providerLocatorSearch, type: NodeType.TEXT },
						right: { value: 'multiplan.com', type: NodeType.LINK },
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'medwatch',
				content: [
					{
						value: BlackhawkLocalize.forMoreQestions,
						type: NodeType.TEXT,
					},
				],
			},
		],
	},
	frontpath_narus: {
		back: [
			{
				type: TemplateType.REGULAR,
				logo: 'narus',
				headline: BlackhawkLocalize.benefitPlanContact,
				content: [{ value: BlackhawkLocalize.benefitPlanContactText, type: NodeType.TEXT }],
			},
			{
				type: TemplateType.REGULAR,
				logo: '',
				headline: BlackhawkLocalize.preCertificatiion,
				content: [
					{
						value: 'PRECERTIFICATION IS REQUIRED FOR THE FOLLOWING:',
						type: NodeType.TEXT,
					},
					{
						value: '- Inpatient Hospital stays: continuing hospital stays over 48 hours following vaginal delivery or 96 hours following cesarean section.',
						type: NodeType.TEXT,
					},
					{
						value: "- Outpatient stays over 12 hours. Outpatient Surgeries (unless done in a doctor's office).",
						type: NodeType.TEXT,
					},
					{
						value: '- Outpatient Chemotherapy and Radiation Therapy.',
						type: NodeType.TEXT,
					},
					{
						value: '- Pain management procedures after first 3 treatments.',
						type: NodeType.TEXT,
					},
					{
						value: '- Home Health care, Hospice care, Skilled nursing care.',
						type: NodeType.TEXT,
					},
					{
						value: '- Physical, Occupational and Speech therapies after first 6 visits.',
						type: NodeType.TEXT,
					},
					{
						value: '- Diagnostic services: MRI, CAT scan, and PET scan.',
						type: NodeType.TEXT,
					},
					{
						value: '- FOR PRECERTIFICATION CALL: 877-499-1774',
						type: NodeType.TEXT,
					},
					{
						value: 'Certification does NOT guarantee coverage under the plan. EMERGENCY admission should be certified within 48 hours of the first business day after admission.',
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: '',
				headline: BlackhawkLocalize.providerInquiries,
				content: [
					{ value: 'NW OH, SE MI, and NE IN Provider Lookup:', type: NodeType.TEXT },
					{ value: 'frontpathcoalition.com (419) 891-5206', type: NodeType.TEXT },
					{ value: 'Hancock & Seneca Counties: NWOHP', type: NodeType.TEXT },
					{ value: 'All other OH Counties: OHC', type: NodeType.TEXT },
					{ value: 'OHC & NWOHP Provider Lookup:', type: NodeType.TEXT },
					{ value: 'ohiohealthchoice.com/nwohp.php, (800) 554-0027', type: NodeType.TEXT },
				],
			},
			{
				type: TemplateType.MIX,
				logo: '',
				headline: BlackhawkLocalize.medicalClaims,
				content: [
					{ value: 'FrontPath Repricing', type: NodeType.TEXT, contentNodeType: ContentNodeType.REGULAR },
					{ value: 'PO BOX 5810', type: NodeType.TEXT, contentNodeType: ContentNodeType.REGULAR },
					{
						left: { value: 'Troy, MI 48007-5810', type: NodeType.TEXT },
						right: { value: 'EDI# 34171', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						value: 'Include group ID to expedite payment',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
				],
			},
			{
				type: TemplateType.LIST,
				logo: 'bmr',
				headline: BlackhawkLocalize.pharmacyPlan,
				content: [
					{ left: { value: 'RxPCN', type: NodeType.TEXT }, right: { value: '7777', type: NodeType.TEXT } },
					{ left: { value: 'RxBIN', type: NodeType.TEXT }, right: { value: '600471', type: NodeType.TEXT } },
					{
						left: { value: 'RxGRP', type: NodeType.TEXT },
						right: { value: '98BH', type: NodeType.TEXT },
					},
					{
						left: { value: 'BMR Member', type: NodeType.TEXT },
						right: { value: '(866) 718-2375', type: NodeType.TEXT },
					},
				],
			},
		],
	},
};
