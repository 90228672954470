import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedbackLocalize } from 'src/app/modules/standalone/feedback/feedback-localize';
import { rippleIconNames } from 'ripple';

export enum Rating {
	VerySad,
	Sad,
	Nuetral,
	Happy,
	VeryHappy
}

export interface RatingInfo {
	rating: Rating;
	icon: rippleIconNames;
	class: string;
}

export type Disclaimers = Record<Rating, string>;

@Component({
	selector: 'app-feedback-emotions',
	templateUrl: './feedback-emotions.component.html',
	styleUrls: ['./feedback-emotions.component.scss']
})
export class FeedbackEmotionsComponent {
	@Output() ratingSelected = new EventEmitter<Rating>();
	@Input() rating: Rating;

	@Input() showDisclaimer = false;
	@Input() disclaimers?: Disclaimers;

	defaultDisclaimers: Disclaimers = {
		[Rating.VerySad]: FeedbackLocalize.verySadDisclaimer,
		[Rating.Sad]: FeedbackLocalize.sadDisclaimer,
		[Rating.Nuetral]: FeedbackLocalize.averageDisclaimer,
		[Rating.Happy]: FeedbackLocalize.happyDisclaimer,
		[Rating.VeryHappy]: FeedbackLocalize.veryHappyDisclaimer
	};
	ratings: RatingInfo[] = [
		{
			rating: Rating.VerySad,
			icon: 'verySad',
			class: 'very-sad'
		},
		{
			rating: Rating.Sad,
			icon: 'sad',
			class: 'sad'
		},
		{
			rating: Rating.Nuetral,
			icon: 'average',
			class: 'average'
		},
		{
			rating: Rating.Happy,
			icon: 'happy',
			class: 'happy'
		},
		{
			rating: Rating.VeryHappy,
			icon: 'veryHappy',
			class: 'very-happy'
		}

	];

	protected readonly Rating = Rating;

	selectRating(rating: Rating) {
		this.rating = rating;
		this.ratingSelected.emit(rating);
	}
}
