<div class="slider-btns">
	<button class="chevron-btn" [disabled]="!enableScrollLeft" (click)="onScroll('left')">
		<img *ngIf="!enableScrollLeft" src="/assets/images/icons/chevron-left-unactive.svg" />
		<img *ngIf="enableScrollLeft" src="/assets/images/icons/chevron-left.svg" />
	</button>
	<button class="chevron-btn" [disabled]="!enableScrollRight" (click)="onScroll('right')">
		<img *ngIf="!enableScrollRight" src="/assets/images/icons/chevron-right-unactive.svg" />
		<img *ngIf="enableScrollRight" src="/assets/images/icons/chevron-right.svg" />
	</button>
</div>
<app-healthee-content-slider
	appearance="standard"
	#slider
	(enableScrollLeftChange)="enableScrollLeft = $event"
	(enableScrollRightChange)="enableScrollRight = $event"
>
	<div class="items-container">
		<ng-container *ngFor="let card of cards$ | async">
			<app-favorite-provider-card
				(click)="track('PC - Provider card: view full details card ', { Source: 'My care team' })"
				*ngIf="card.type === ProviderCardType.Favorite"
				[provider]="card.provider"
				[routerLink]="['/care-and-costs/providers/specialist/' + card.provider.npi]"
			>
			</app-favorite-provider-card>
			<app-add-to-favorites-card
				(click)="track('MCT - Home Page: intent to add a favorite', { 'Teaser state': 'populated' })"
				[routerLink]="['/care-and-costs/providers']"
				*ngIf="card.type === ProviderCardType.AddToFavorites"
			>
			</app-add-to-favorites-card>
		</ng-container>
	</div>
</app-healthee-content-slider>
