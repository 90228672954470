import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterArrayByPropValue', pure: false })
export class FilterArrayByPropertyValue implements PipeTransform {
	/** 'filterArrayByPropValue' Pipe - filters an array of objects, for objects with properties (filteringProperty) containing a certain value (filteringValue).
	 * Usage in html: *ngFor="let message of messages$ | async | filterArrayByPropValue:'isRead':filterBooleanValue".
	 * This filter can be disabled, by setting 'filterIsEnabled' to false, for example: *ngFor="let message of zoeMessages$ | async | filterArrayByPropValue:'isRead':false:enableFilter"
	 * @param  {any[]} arrayOfObjects - array of objects to filter
	 * @param  {any} filteringPropertyName - the property of objects in array, to check its value
	 * @param  {any} filteringValue - the value to filter
	 * @param  {boolean=true} filterIsEnabled - enable/disable filter. Defaults to true.
	 * @returns string
	 */
	transform(
		arrayOfObjects: any[],
		filteringPropertyName: any,
		filteringValue: any,
		filterIsEnabled: boolean = true
	): string[] {
		if (!arrayOfObjects || !filteringPropertyName || !filterIsEnabled) return arrayOfObjects;

		return arrayOfObjects.filter((item) => item[filteringPropertyName] === filteringValue);
	}
}
