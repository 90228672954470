import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TxNativeModule } from '@transifex/angular';
import { RippleModule } from 'ripple';
import { TooltipWithTouchModule } from '../../app-shared/standalone/tooltip-with-touch/tooltip-with-touch.module';

const NUMBER_OF_COLUMNS = 3;

@Component({
	selector: 'app-eligible-zipcodes-modal',
	templateUrl: './eligible-zipcodes-modal.component.html',
	styleUrls: ['./eligible-zipcodes-modal.component.scss'],
	standalone: true,
	imports: [TxNativeModule, RippleModule, TooltipWithTouchModule, CommonModule],
})
export class EligibleZipcodesModalComponent {
	@Input() set zipCodes(value: string[]) {
		this.zipCodesMatrix = value.reduce((acc, zipCode, index) => {
			const columnIndex = index % NUMBER_OF_COLUMNS;
			if (!acc[columnIndex]) {
				acc[columnIndex] = [];
			}
			acc[columnIndex].push(zipCode);
			return acc;
		}, []);
	}
	@Output() backClick = new EventEmitter<void>();

	zipCodesMatrix: string[][] = [];

	handleBackClick() {
		this.backClick.emit();
	}
}
