import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FacilityDetails } from '../../../modules/main-layout/care-costs/helpers/providers.helpers';
import { Store } from '../../../utils/store/store';
import { FacilitiesApiService } from '../../api/providers-api/facilities-api.service';

@Injectable({
	providedIn: 'root',
})
export class FacilityDetailsStoreService extends Store<string, FacilityDetails> {
	constructor(private facilitiesApiService: FacilitiesApiService) {
		super();
	}

	protected retrieve(context: string): Observable<FacilityDetails> {
		return this.facilitiesApiService.facilityByUuid(context);
	}
}
