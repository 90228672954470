import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SubscriptionBag } from '../subscription-bag';
import { Maybe } from '../types/maybe';
import { isNil } from '../is/is-nil';

const EmptyValue = Symbol('emptyValue');
const cache = new Map<string, BehaviorSubject<unknown>>();

export const timedCache =
	<T>(time: number, namespace: string | symbol, params: unknown = null) =>
	(source: Observable<T>) =>
		new Observable<T>((subscriber) => {
			const subsBag = new SubscriptionBag();
			const key = `${String(namespace)}_${JSON.stringify(params)}`;
			let timeout: Maybe<unknown>;

			function setupCacheCleaner(cacheTime: number, cacheKey: string) {
				if (!isNil(timeout)) {
					return;
				}
				timeout = setTimeout(() => {
					cache.delete(cacheKey);
				}, cacheTime);
			}

			if (!cache.has(key)) {
				cache.set(key, new BehaviorSubject<unknown>(EmptyValue));
				source.pipe(take(1)).subscribe({
					next: (val) => {
						cache.get(key).next(val);
						setupCacheCleaner(time, key);
					},
					error: (err) => {
						cache.get(key).error(err);
						cache.delete(key);
					},
				});
			}

			subsBag.add = cache
				.get(key)
				.pipe(filter((val) => val !== EmptyValue))
				.subscribe({
					error: (err) => subscriber.error(err),
					next: (val: any) => {
						subscriber.next(val);
					},
				});

			return () => subsBag.dispose();
		});
