import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Provider } from '../../../helpers/providers.helpers';
import { AppointmentFetch, AvailabilityData, SlotData } from '../../../../../../models/appointment.model';
import { take } from 'rxjs';
import { AppointmentService } from '../../../../../../services/appointment.service';
import { TrackingService } from '../../../../../../services/tracking.service';
import { RescheduleAppointmentEvents } from 'src/app/models/tracking-events.model';

@Component({
	selector: 'app-appointment-reschedule',
	templateUrl: './appointment-reschedule.component.html',
})
export class AppointmentRescheduleComponent implements OnInit {
	@Input()
	appointment: AppointmentFetch;

	@Output()
	setAvailabilitySelection = new EventEmitter<AvailabilityData>();

	@Output()
	setSelectedSlot = new EventEmitter<SlotData>();

	@Input()
	providerData: Provider;

	public availableSlots;
	public showSlots: boolean = false;

	constructor(private appointmentService: AppointmentService,
				private trackingService: TrackingService) {}

	ngOnInit(): void {
		this.fetchAppointments();
	}

	private fetchAppointments() {
		this.appointmentService
			.getAvailableSlots({
				visitReasonId: this.appointment.reason,
				providerLocationId: this.appointment.locationId,
				patientType: this.appointment.patientType
			})
			.pipe(take(1))
			.subscribe({
				next: (availableSlots) => {
					this.availableSlots = availableSlots;
					this.trackingService.trackClientEvent(RescheduleAppointmentEvents.SlotsAvailabilityChecked, {
						doctorName: this.appointment.providerName,
						address: this.appointment.locationName,
						availabilityExists: availableSlots?.timeslots?.length > 0
					});

					this.showSlots = true;
				},
				error: (error) => {
					console.log(error);
				}
			});
	}
}
