import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { invokePhoneDialer } from '../../../../../../utils/utils';
import { RibbonDoctor } from '../../../../../../models/ribbon-data.model';

import { TrackingService } from 'src/app/services/tracking.service';

import { HealtheeDialogContent } from '../../../../../app-shared/healthee-dialog/healthee-dialog.component';
import { Maybe } from '../../../../../../utils/types/maybe';
import { RibbonServiceCoverage } from '../helpers/ribbon-service-coverage';

@Component({
	selector: 'app-ribbon-health-card-mobile',
	templateUrl: './ribbon-health-card-mobile.component.html',
	styleUrls: ['./ribbon-health-card-mobile.component.scss'],
})
export class RibbonHealthCardMobileComponent extends HealtheeDialogContent implements OnInit, OnChanges {
	@Input() cardData: RibbonDoctor = null;
	@Input() isFullDetailsCard: boolean = null;
	@Output() moreInfoClicked = new EventEmitter<null>();
	@Output() closeClicked = new EventEmitter<null>();

	@Input()
	serviceCoverage: Maybe<RibbonServiceCoverage>;

	RibbonServiceCoverage = RibbonServiceCoverage;

	public _isFullDetailsCard: boolean = false;
	public _cardData: RibbonDoctor = null;
	public isCallBtnEnabled: boolean = true;
	private currentlySelectedPhoneNumber: string = null;

	constructor(private trackingService: TrackingService) {
		super();
	}

	ngOnInit(): void {
		this.updateCardData();
	}

	ngOnChanges(changes: SimpleChanges) {
		this._cardData = changes.cardData.currentValue;
	}

	ionViewWillEnter() {
		this.updateCardData();
	}

	private updateCardData() {
		if (this.isComponentOpenedAsModal()) {
			this._cardData = this.data.cardData;
			this._isFullDetailsCard = this.data.displayFullDetailsCard;
		} else {
			this._cardData = this.cardData;
			this._isFullDetailsCard = this.isFullDetailsCard;
		}

		this.selectNearestProvider();
	}

	private isComponentOpenedAsModal(): boolean {
		return !!this.data;
	}

	private selectNearestProvider() {
		const NEAREST_LOCATION = 0; // Providers are sorted by geographic proximity, closest first
		this.onSelectedProviderChanged(NEAREST_LOCATION);
	}

	public get languages_long() {
		return this._cardData.languages.reduce((prev, current) => prev + ', ' + current);
	}

	public get languages_short() {
		const NUM_OF_LETTERS_IN_SHORT_LANG_NAME = 3;
		return this._cardData.languages
			.map((lang) => lang.slice(0, NUM_OF_LETTERS_IN_SHORT_LANG_NAME))
			.reduce((prev, current) => prev + ', ' + current);
	}

	public onCloseModalClicked() {
		const EMPTY_SIGNAL = null;
		this.closeClicked.emit(EMPTY_SIGNAL);
		this.closeDialog.emit(EMPTY_SIGNAL);
	}

	public onMoreInfoClicked() {
		this.trackingService.trackClientEvent('Provider card more info click', { source: 'Care & Costs' });
		this.moreInfoClicked.emit(null);
	}

	public onSelectedProviderChanged(providerIndex: number) {
		const FIRST_PHONE_NUMBER = 0;

		this.currentlySelectedPhoneNumber = this.getPhoneNumberFromLocation(providerIndex, FIRST_PHONE_NUMBER)?.phone;

		if (!this.currentlySelectedPhoneNumber) {
			this.isCallBtnEnabled = false;
			return;
		}

		this.isCallBtnEnabled = true;
	}

	public onCallClicked(event) {
		const sourceOfCall = event.target.attributes.id.nodeValue;
		if (sourceOfCall === 'callFromCard')
			this.trackingService.trackClientEvent('Provider card call click', { source: 'Care & Costs card' });
		if (sourceOfCall === 'callFromMoreInfo')
			this.trackingService.trackClientEvent('Provider card call click', { source: 'more info' });
		if (!this.currentlySelectedPhoneNumber) return;

		invokePhoneDialer(this.currentlySelectedPhoneNumber);
	}

	private getPhoneNumberFromLocation(locationIndex: number, phoneNumberIndex: number = 0) {
		return this._cardData?.locations[locationIndex]?.phone_numbers[phoneNumberIndex];
	}
}
