<div class="share-provider-header">
	{{ 'Provider details' | translate }}
</div>
<div class="share-provider-body">
	<div class="share-provider-field">
		<div class="share-provider-field-title">
			{{ 'Provider Name' | translate }}
		</div>
		<div class="share-provider-field-value">{{ provider?.firstName }} {{ provider?.lastName }}</div>
	</div>
	<div class="share-provider-field" *ngIf="provider?.specialties?.length > 0">
		<div class="share-provider-field-title">
			{{ 'Provider specialties' | translate }}
		</div>
		<div class="share-provider-field-value">
			<ng-container *ngFor="let specialty of provider.specialties; let isLast = last">
				{{ specialty | translate }}<ng-container *ngIf="!isLast">, </ng-container>
			</ng-container>
		</div>
	</div>

	<div class="share-provider-field" *ngIf="provider.ratingsAvg">
		<div class="share-provider-field-title">
			{{ 'Rating' | translate }}
		</div>
		<div class="share-provider-field-value">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.4249 9.22536C16.9015 8.72772 16.6346 7.87178 15.9674 7.77225L12.7645 7.29452C12.4976 7.25471 12.2688 7.07556 12.1545 6.83669L10.7246 3.81105C10.4196 3.17407 9.56168 3.17407 9.27571 3.81105L7.84587 6.83669C7.73148 7.09546 7.50271 7.25471 7.23581 7.29452L4.03296 7.77225C3.36571 7.87178 3.0988 8.72772 3.57541 9.22536L5.88222 11.5941C6.07287 11.7932 6.16819 12.0719 6.111 12.3505L5.55813 15.6748C5.4628 16.3715 6.16819 16.889 6.75919 16.5705L9.61887 14.998C9.84765 14.8586 10.1336 14.8586 10.3815 14.998L13.2411 16.5705C13.8321 16.889 14.5375 16.3715 14.4231 15.6748L13.8703 12.3505C13.8321 12.0719 13.9084 11.7932 14.099 11.5941L16.4249 9.22536Z"
					fill="#FFB601"
				/>
			</svg>

			<span>{{ provider.ratingsAvg / 2 | number : '1.0-1' }}/5</span>
			<span>&nbsp;({{ provider.ratingsCount }})</span>
		</div>
	</div>

	<div class="share-provider-field" *ngIf="provider?.locations?.length > 0">
		<div class="share-provider-field-title">
			{{ 'Address' | translate }}
		</div>
		<div class="share-provider-field-value">
			<a
				[href]="'https://www.google.com/maps/dir/' + encodeURIComponent(provider.locations[0]?.address || '')"
				target="_blank"
			>
				{{ provider.locations[0]?.address }}
			</a>
		</div>
	</div>
	<div
		class="share-provider-field"
		*ngIf="provider?.locations?.length > 0 && provider?.locations[0]?.phoneNumbers[0]"
	>
		<div class="share-provider-field-title">
			{{ 'Phone' | translate }}
		</div>
		<div class="share-provider-field-value">
			<a [href]="'tel:' + provider?.locations[0]?.phoneNumbers[0]">
				{{ provider?.locations[0]?.phoneNumbers[0] }}
			</a>
		</div>
	</div>
</div>
