import { GeolocationService } from './geolocation-service.base';

import {
	GeolocationPermissionsDeniedOnWebError,
	GeolocationServiceNotAvailableError,
	GeolocationTimeoutError,
	GeolocationUnknownError
} from './geolocation-error';

export class WebGeolocationService extends GeolocationService {
	constructor() {
		super();
	}

	/** Get current position for web through navigator (browser).
	 * @returns Promise with GeolocationPosition or null if position cannot be resolved.
	 * @throws - GeolocationServiceNotAvailableError, GeolocationUnknownError,
	 *   GeolocationTimeoutError GeolocationPermissionsDeniedOnWebError
	 */
	public getCurrentPosition(): Promise<GeolocationPosition> {
		return new Promise((resolve, reject) => {
			if (!('geolocation' in navigator))
				reject(new GeolocationServiceNotAvailableError());

			navigator.geolocation.getCurrentPosition(
				(res: GeolocationPosition) => resolve(res),
				(err: GeolocationPositionError) => {
					if (err && err.code === GeolocationPositionError.PERMISSION_DENIED) {
						reject(new GeolocationPermissionsDeniedOnWebError());

					} else if (err && err.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
						reject(new GeolocationServiceNotAvailableError());

					} else if (err && err.code === GeolocationPositionError.TIMEOUT) {
						reject(new GeolocationTimeoutError());

					} else {
						const errMessage = `Unexpected gelocation position error: ${err.message}, code: ${err.code}`;
						reject(new GeolocationUnknownError(errMessage));
					}
				},
				this.positionOptions
			);
		});
	}

	/** Check for Geolocation availability and location permission.
	 * @returns Promise with boolean
	 * - true - if geolocation can be used (is available and permissions granted)
	 * - false - if geolocation can be used (is available), but permissions are not yet granted (prompt)
	 * @throws - if geolocation cannot be used (not available or perms denied)
	 */
	public async checkAndRequestPermission(): Promise<boolean> {
		if (!('geolocation' in navigator))
			throw new GeolocationServiceNotAvailableError();

		let permissionStatus: PermissionStatus;

		try {
			permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
		} catch (error) {
			throw new GeolocationUnknownError('Error checking geolocation permission.', error);
		}

		if (permissionStatus.state === 'denied')
			throw new GeolocationPermissionsDeniedOnWebError();

		return permissionStatus.state === 'granted';
	}
}
