import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';

import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { AppInfo as IonicAppInfo, App as IonicApp, AppInfo } from '@capacitor/app';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { CheckForUpdateResponse } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';

import CFG from '../config/app-config.json';
import { environment } from '../../environments/environment';
import { environment as ionicLocalEnv } from '../../environments/environment.ionic-local';
import { isIonic } from '../utils/utils';

import { VersionUpdateLocalize } from '../modules/version-manager/version-updater/version-updater.localize';
import {
	minimalSupportedAndroidAppVersion,
	minimalSupportediOSAppVersion,
	MinimalSupportedNativeVersion,
} from '../../../minimal-supported-native-version';

import { UIService } from './ui.service';

const TAG = 'MobileAppService';
const TEST_APP_BUNDLE_ID = 'com.insurights.testapp';
const IONIC_CHANNEL_PRODUCTION = 'Production';
const IONIC_CHANNEL_TESTING = 'Beta';

@Injectable({ providedIn: 'root' })
export class MobileAppService {
	VersionUpdateLocalize = VersionUpdateLocalize;
	private readonly isAndroid = Capacitor.getPlatform() === 'android';
	private minimalSupportedNativeAppInfo: MinimalSupportedNativeVersion = this.isAndroid
		? minimalSupportedAndroidAppVersion
		: minimalSupportediOSAppVersion;

	private _ionicAppInfo$ = new BehaviorSubject<IonicAppInfo>(null);
	private _deviceInfo$ = new BehaviorSubject<DeviceInfo>(null);
	private _developerMode$ = new BehaviorSubject<boolean>(CFG.development.developmentModeIsActivatedAtStartup);

	private _updateIsMobileOnFront$ = new BehaviorSubject<boolean>(null);

	constructor(private deploy: Deploy, private uiService: UIService) {}

	public get appIdOnStore() {
		return this.isAndroid ? CFG.mobileApp.androidAppIsOnGooglePlay : CFG.mobileApp.iOSAppIdOnAppStore;
	}

	public get updateIsAvailable$(): Observable<boolean> {
		if (!this.isUpdatableEnvironment()) {
			return of(false);
		}

		return from(this.getDeploymentChannel()).pipe(
			tap((channel) => console.log(TAG, `Checking app updates on ${channel} channel...`)),
			switchMap((channel) => from(this.deploy.configure({ channel }))),
			switchMap(() => from(this.deploy.checkForUpdate())),
			map((update: CheckForUpdateResponse) => update.available),
			tap((isAvailable: boolean) => console.log(TAG, 'Ionic update available?', isAvailable))
		);
	}

	public isUpdatableEnvironment() {
		if (!isIonic()) {
			return false;
		}

		if (environment.envName === ionicLocalEnv.envName) {
			console.log(TAG, 'Will not update app because app is in local environment.');
			return false;
		}

		return true;
	}

	public get developerMode$() {
		return this._developerMode$.asObservable();
	}
	public set developerModeActivated(activated: boolean) {
		this._developerMode$.next(activated);
	}

	public set updateMobileAppStatus(isFront: boolean) {
		this._updateIsMobileOnFront$.next(isFront);
	}
	public get mobileAppOnFront$() {
		return this._updateIsMobileOnFront$.asObservable();
	}

	public async getDeploymentChannel() {
		const appInfo: AppInfo = await IonicApp.getInfo();

		return appInfo.id === TEST_APP_BUNDLE_ID ? IONIC_CHANNEL_TESTING : IONIC_CHANNEL_PRODUCTION;
	}

	public get ionicAppInfo$() {
		return this._ionicAppInfo$.asObservable().pipe(
			take(1),
			switchMap((value: IonicAppInfo) => {
				if (value) return of(value);
				if (!Capacitor.isNativePlatform()) return of(false);
				return from(IonicApp.getInfo()); // rxjs "from promise"
			}),
			tap((newValue: IonicAppInfo) => this._ionicAppInfo$.next(newValue))
		);
	}

	public get deviceInfo$() {
		return this._deviceInfo$.asObservable().pipe(
			take(1),
			switchMap((value: DeviceInfo) => {
				if (value) return of(value);
				return from(Device.getInfo()); // rxjs "from promise"
			}),
			tap((newValue: DeviceInfo) => this._deviceInfo$.next(newValue))
		);
	}

	public get deviceUuid$(): Observable<string> {
		return from(Device.getId()).pipe(
			map((deviceId: DeviceId) => deviceId.uuid),
			shareReplay()
		);
	}

	public async nativeAppNeedsUpdate() {
		if (!Capacitor.isNativePlatform()) {
			console.log('Not running on a native platform --> skipping native version check.');
			return false;
		}

		const minimalSupportedAppVersion: string = this.minimalSupportedNativeAppInfo.version;
		const minimalSupportedAppBuild: number = this.minimalSupportedNativeAppInfo.build;

		const appInfo = await IonicApp.getInfo();

		const numbersOnlyCurrentAppVersion = appInfo.version.replace(/\./g, '');
		const numbersOnlyMinimalVersion = minimalSupportedAppVersion.replace(/\./g, '');

		return (
			parseInt(numbersOnlyCurrentAppVersion) < parseInt(numbersOnlyMinimalVersion) ||
			parseInt(appInfo.build) < minimalSupportedAppBuild
		);
	}

	public nativeAppUpdateIsMandatory() {
		return this.minimalSupportedNativeAppInfo.updateIsMandatory;
	}

	public async openAppStore() {
		const [appStoreUrl, openingStoreMessage, failedToOpenStoreMessage] = this.isAndroid
			? [
					CFG.mobileApp.androidAppGooglePlayUrl,
					this.VersionUpdateLocalize.txtOpeningGooglePlay,
					this.VersionUpdateLocalize.txtFailedToOpenGooglePlay,
			  ]
			: [
					CFG.mobileApp.iOSAppAppStoreUrl,
					this.VersionUpdateLocalize.txtOpeningAppStore,
					this.VersionUpdateLocalize.txtFailedToOpenAppStore,
			  ];

		await this.uiService.displayAppMessage(openingStoreMessage);

		try {
			await Browser.open({ url: appStoreUrl });
		} catch (e) {
			await this.uiService.displayAppMessage(failedToOpenStoreMessage);
		}
	}
}
