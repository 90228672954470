import { Injectable } from '@angular/core';
import { AppointmentService } from '../../appointment.service';
import { Store } from '../../../utils/store/store';
import { AppointmentFetch } from '../../../models/appointment.model';
import { Observable, take } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { TrackingService } from '../../tracking.service';

@Injectable({
	providedIn: 'root',
})
export class AppointmentsListStoreService extends Store<void, AppointmentFetch[]> {
	constructor(private appointmentService: AppointmentService, private trackingService: TrackingService) {
		super();
	}

	protected retrieve(context: void): Observable<Maybe<AppointmentFetch[]>> {
		return this.appointmentService.getAppointments();
	}

	public cancelFailedBooking(appointment: AppointmentFetch) {
		return this.appointmentService
			.cancelAppointment({ appointmentId: appointment.appointmentId })
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.trackingService.trackClientEvent('Failed booking appointment removed/canceled', {
						success: true,
						...appointment,
					});
				},
				error: () => {
					this.trackingService.trackClientEvent('Failed booking appointment removed/canceled', {
						success: false,
						...appointment,
					});
				},
			});
	}
}
