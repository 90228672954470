<div *ngIf="!isLoading; else loadingIndicator" class="avatars-container">
	<h2 class="title">Which is your favorite avatar?</h2>

	<img
		*ngFor="let avatar of availableAvatars"
		[src]="'../../../../../assets/images/icons-ionic/user-avatars/' + avatar"
		class="avatar"
		(click)="chooseAvatar(avatar)"
	/>
</div>

<ng-template #loadingIndicator>
	<app-loader size="large"></app-loader>
</ng-template>
