<div
	class="pharmacy-service-container table-content plan-details-drawer medical-plan-details-drawer"
	[class.pharmacy-pct-style-view-container]="haPCTStyleView"
>
	<app-plan-details-modal
		[plan]="plan"
		[planType]="planType"
		[showHeader]="false"
		[isTableTopRawFixed]="false"
		[showNetworkCircleIcon]="false"
		[displayAsNonModal]="true"
		[footerText]="footerText"
	>
	</app-plan-details-modal>
</div>
