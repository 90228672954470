<div *ngIf="formattedSlotsLists.length === 0" class="slots-empty-state">
	<p class="title">{{ 'Currently there are no available time slots for the selected criteria.' | translate }}</p>
	<p class="sub-title">{{ 'You can try changing your selections or check back later as availability may vary.' | translate }}</p>
</div>

<div *ngIf="formattedSlotsLists.length > 0" class="slots-available-container">
	<div class="title">{{ 'Available time slots' | translate }}</div>
	<div *ngFor="let slotsList of formattedSlotsLists">
		<div class="date">{{ slotsList.date | date : 'MMMM d' }}</div>
		<app-healthee-radio-group
			id="slotsLists"
			[name]="'slotsLists'"
			[buttons]="slotsList.slots"
			[clickable]="true"
			[showMoreButtonAfterNb]="showMoreButtonAfterNbValue"
			(valueChanged)="slotClicked($event)"
		></app-healthee-radio-group>
	</div>
</div>
