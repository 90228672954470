<mat-drawer-container class="sidebar-container" autosize>
	<mat-drawer
		class="drawer"
		*ngIf="!sidebarService.isHidden"
		[mode]="sidenavMode$ | async"
		[disableClose]="false"
		[opened]="sidebarService.isOpen"
		(openedChange)="notifySidebarService($event)"
	>
		<div
			(click)="sidebarService.toggle()"
			[matTooltip]="sidebarService.isFull ? ('Close Navigation' | translate) : ('Open Navigation' | translate)"
			matTooltipPosition="right"
			matTooltipClass="title-tooltip"
			class="sidebar-toggle-button"
		>
			<mat-icon class="icon-size-24 d-block">
				{{ sidebarService.isFull ? 'chevron_left' : 'chevron_right' }}
			</mat-icon>
		</div>

		<div [class.shrink]="sidebarService.isShrank" class="sidebar d-flex flex-direction-column">
			<div class="flex-grow-1">
				<div *ngIf="sidebarService.isShrank" class="d-flex justify-content-center pt-25 pb-100 cursor-pointer">
					<img routerLink="/" class="width-40" src="/assets/images/logo.svg" alt="Logo" />
				</div>

				<div *ngIf="!sidebarService.isShrank" class="logo-container">
					<!-- <a href="/" class="logo">
						<span class="logo-lg"></span>
					</a> -->
				</div>

				<ul class="metismenu list-unstyled" id="side-menu">
					<ng-container
						*ngFor="
							let item of menuItems | filterArrayByPropValue : 'hidden' : false || undefined;
							let i = index
						"
					>
						<app-sidebar-item
							[shrinked]="sidebarService.isShrank"
							[text]="item.label | translate : { _key: item.labelKey }"
							[icon]="item.icon"
							[selectedIcon]="item.selectedIcon"
							[link]="
								item.id === BenefitsMenuItem
									? (languageService.isSpanish$ | async) === false
										? '/coverage-info'
										: item.link
									: item.link
							"
							fragment="Menu"
							(click)="item.onClick()"
						>
						</app-sidebar-item>
					</ng-container>
				</ul>
				<div *ngIf="isChatAvailable$ | async">
					<div
						*ngIf="!sidebarService.isShrank"
						class="px-28 cursor-pointer mt-30"
						routerLink="/chat"
						routerLinkActive="chat-is-active"
						(click)="onClickChatWithZoe()"
					>
						<div
							class="px-8 py-7 border-border-color-light-blue radius-60 d-flex align-items-center justify-content-space-between"
						>
							<div class="d-flex align-items-center">
								<img
									src="/assets/icons/icon-zoe.svg"
									alt="Zoe Chat"
									class="zoe-icon icon-size-42 mr-10"
								/>
								<img
									src="/assets/icons/icon-zoe-selected.svg"
									alt="Zoe Chat"
									class="zoe-icon active icon-size-42 mr-10"
								/>
								<span class="text-dark-blue font-size-14">{{ 'Chat with Zoe' | translate }}</span>
							</div>
							<mat-icon class="text-azure-radiance">chevron_right</mat-icon>
						</div>
					</div>
					<div
						*ngIf="sidebarService.isShrank"
						routerLink="/chat"
						routerLinkActive="chat-is-active"
						(click)="onClickChatWithZoe()"
						class="d-flex justify-content-center mt-35 cursor-pointer"
					>
						<img src="/assets/icons/icon-zoe.svg" alt="Zoe Chat" class="zoe-icon icon-size-42" />
						<img
							src="/assets/icons/icon-zoe-selected.svg"
							alt="Zoe Chat"
							class="zoe-icon active icon-size-42"
						/>
					</div>
				</div>
				<app-plan-selection-button
					source="menu"
					[shrink]="sidebarService.isShrank"
					(click)="defaultMenuItemOnClick()"
				></app-plan-selection-button>
			</div>

			<div *ngIf="!sidebarService.isShrank">
				<div class="app-version-wrapper text-center">
					<app-version-label></app-version-label>
				</div>

				<app-quantum-support *ngIf="showQuantumSupport"></app-quantum-support>
			</div>
		</div>
	</mat-drawer>

	<mat-drawer-content>
		<ng-content></ng-content>
	</mat-drawer-content>
</mat-drawer-container>
