import { ServiceTypeEnum } from 'src/app/models/chat-response.model';

export const enum MpiPlan {
	Anthem = 'Anthem',
	Kaiser = 'Kaiser',
	Oxford = 'Oxford',
	HealthNet = 'Health Net',
	CVS = 'CVS Caremark - Pharmacy',
}

export enum MpiPlanId {
	Anthem = '656f43a0a6f244f2ee8dfb11',
	Kaiser = '65773f2230bba239b851d0ea',
	Oxford = '64ad77eb2475a65f7a26df35',
	HealthNet = '646cdd4d1625ae7343e1850f',
	CVS = '65b40e13e8197b8f6f7c1e65',
}

const planToServiceTypeMap: { [key in MpiPlanId]: ServiceTypeEnum } = {
	[MpiPlanId.Anthem]: ServiceTypeEnum.Medical,
	[MpiPlanId.Kaiser]: ServiceTypeEnum.Medical,
	[MpiPlanId.Oxford]: ServiceTypeEnum.Medical,
	[MpiPlanId.HealthNet]: ServiceTypeEnum.Medical,
	[MpiPlanId.CVS]: ServiceTypeEnum.Pharmacy,
};

//Make sure number of services stays 4
export const AnthemPopularBenefitIds = [
	'5d9c3af263507c70ef68860f', // Primary Care
	'5dd95d2763d42e623008f791', // Pediatrician
	'6647acc960987a9d74bedc14', // Annual physical Exam a.k.a Wellness Visit
	'5ddfaa5fcace7e30c3c90d6e', // Telehealth
];

export const ServiceNamesForPopularBenefits = {
	'5d9c3af263507c70ef68860f': 'Primary Care',
	'5dd95d2763d42e623008f791': 'Pediatrics',
	'6647acc960987a9d74bedc14': 'Wellness Visit',
	'5ddfaa5fcace7e30c3c90d6e': 'Telehealth',
};

// Other MPI utils

export const getServiceTypeForPlan = (planId: MpiPlanId): ServiceTypeEnum =>
	planToServiceTypeMap[planId] || ServiceTypeEnum.Medical;

export const showMP3Disclaimer = (serviceId: string, network: string): boolean | undefined => {
	return showMP3DisclaimerMap[serviceId]?.[network?.toLowerCase()];
};

const showMP3DisclaimerMap = {
	//Lens Replacement following Cataract Surgery
	'66f1cb5d22742f90dfa9ff4e': {
		'anthem blue cross': false,
	},
	//Diabetic Education (Pre-Diabetes)
	'66fe7ddf984e5b1d8de10df0': {
		'anthem blue cross': false,
	},
	//Diabetic Education (type 1 Diabetes)
	'66fecfd7984e5b1d8deadc7d': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Home Health Services
	'66f35807a0a331cc63f0605d': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Nutritional Support
	'66f1cb233663c433677a3aab': {
		'anthem blue cross': false,
	},
	//Reconstructive/Cosmetic Surgery
	'661e4964e4db2826d63fa83e': {
		'anthem blue cross': false,
	},
	//Transplant Services
	'60e3ef7f54823252fe1b8fa7': {
		'anthem blue cross': false,
	},
	//Travel Expenses Related To Obtaining Covered Services
	'66f1cb8522742f90dfa9ffd5': {
		'anthem blue cross': false,
	},
	//Weight Control
	'661e4a28ef0282e9582faccf': {
		'anthem blue cross': false,
	},
	//Anesthesiologist
	'66f355cf25202a32808b5c80': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Annual Physical Exam
	'6647acc960987a9d74bedc14': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Chiropractor
	'604a72c104b9b313c97044f0': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Colon Cancer Screening
	'661e4077ef0282e9582efe1c': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Diabetic Supplies and Insulin
	'66fed06c1a8e76316db22579': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Diagnostic Imaging and Laboratory
	'667d5ace9afa6575bf06c300': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Facilities (Emergency Room)
	'66ff2d2724153184b24669df': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Facilities (Ambulatory Surgical Center)
	'66fecfc01a8e76316db223d4': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Facilities (Hospital)
	'66f1cc823663c433677a6672': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Genetic Testing
	'61fc6e5cd0a2ab18c9b112cd': {
		'anthem blue cross': false,
	},
	//Hearing Aids
	'5d9c3af263507c70ef68860e': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Home Intravenous Therapy
	'66f3560f18685c904ad17205': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Infertility
	'667dc2f36d72f160ca67e815': {
		'anthem blue cross': false,
		'out of network': false,
		mp3: false,
	},
	//Mammogram
	'66f1cc653663c433677a60b0': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Orthotics, Prosthesis, and Braces
	'66f3583aa0a331cc63f060d2': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Sclerotherapy
	'661e4c8bc402f76976796f88': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Speech Therapy
	'619e36c1e09bec71011d9630': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Vaccines
	'66fe7e141a8e76316da7f15b': {
		'anthem blue cross': false,
		'out of network': false,
	},
	//Work Related Travel Vaccinations
	'664308d10de02639b9b4aced': {
		'out of network': false,
	},
	//Ambulance Services
	'66f1caef25202a328062fd24': {
		'anthem blue cross': false,
	},
	//Facilities (Birthing Center)
	'60e3ba3054823252fe1b8f4d': {
		'anthem blue cross': false,
		'out of network': false,
	},
};

export const showDisclaimerPanelCard = (serviceId: string, network: string): boolean | undefined => {
	return showDisclaimerPanelCardMap[serviceId]?.[network?.toLowerCase()];
};

const showDisclaimerPanelCardMap = {
	//Diabetic Education (Pre-Diabetes)
	'66fe7ddf984e5b1d8de10df0': {
		'anthem blue cross': false,
	},
	//Hearing Aids
	'5d9c3af263507c70ef68860e': {
		mp3: false,
	},
	//Ambulance Services
	'66f1caef25202a328062fd24': {
		'anthem blue cross': false,
	},
	//Chiropractor
	'604a72c104b9b313c97044f0': {
		mp3: false,
	},
	//Colon Cancer Screening
	'661e4077ef0282e9582efe1c': {
		mp3: false,
	},
	//Diabetic Education (type 1 Diabetes)
	'66fecfd7984e5b1d8deadc7d': {
		mp3: false,
	},
	//Home Intravenous Therapy
	'66f3560f18685c904ad17205': {
		mp3: false,
	},
	//Facilities (Birthing Center)
	'60e3ba3054823252fe1b8f4d': {
		mp3: false,
	},
};

export const showProviderSearchButton = (serviceId: string, network: string): boolean | undefined => {
	return showProviderSearchButtonMap[serviceId]?.[network?.toLowerCase()];
};

const showProviderSearchButtonMap = {
	//Ambulance Services
	'66f1caef25202a328062fd24': {
		'anthem blue cross': false,
	},
	//Infertility
	'667dc2f36d72f160ca67e815': {
		'anthem blue cross': false,
		mp3: false,
	},
	//Diabetic Education (Type 1 Diabetes)
	'66fecfd7984e5b1d8deadc7d': {
		mp3: false,
	},
};

export const showAppointmentChecklist = (serviceId: string, network: string): boolean | undefined => {
	return showAppointmentChecklistMap[serviceId]?.[network?.toLowerCase()];
};

const showAppointmentChecklistMap = {
	//Infertility
	'667dc2f36d72f160ca67e815': {
		'anthem blue cross': false,
		mp3: false,
	},
	//Ambulance Services
	'66f1caef25202a328062fd24': {
		'anthem blue cross': false,
	},
	//Diabetic Education (Pre-Diabetes)
	'66fe7ddf984e5b1d8de10df0': {
		'anthem blue cross': false,
	},
	//Chiropractor
	'604a72c104b9b313c97044f0': {
		mp3: false,
	},
	//Colon Cancer Screening
	'661e4077ef0282e9582efe1c': {
		mp3: false,
	},
	//Diabetic Education (Type 1 Diabetes)
	'66fecfd7984e5b1d8deadc7d': {
		mp3: false,
	},
	//Diabetic Education (Type 2 Diabetes)
	'66fecff81a8e76316db22485': {
		'anthem blue cross': false,
	},
	//Diabetic Supplies and Insulin
	'66fed06c1a8e76316db22579': {
		mp3: false,
	},
	//Facilities (Emergency Room)
	'66ff2d2724153184b24669df': {
		'anthem blue cross': false,
		mp3: false,
	},
	//Facilities (Hospital)
	'66f1cc823663c433677a6672': {
		'anthem blue cross': false,
	},
	//Genetic Testing
	'61fc6e5cd0a2ab18c9b112cd': {
		'anthem blue cross': false,
	},
	//Hearing Aids
	'5d9c3af263507c70ef68860e': {
		mp3: false,
	},
	//Facilities (Birthing Center)
	'60e3ba3054823252fe1b8f4d': {
		'anthem blue cross': false,
		mp3: false,
	},
	//Home Hospice Care (Hospice - Routine Home Care)
	'61c0ec369ed1961dd7a38d88': {
		'anthem blue cross': false,
	},
	//Home Intravenous Therapy
	'66f3560f18685c904ad17205': {
		'anthem blue cross': false,
		mp3: false,
	},
	//Lens Replacement following Cataract Surgery
	'66f1cb5d22742f90dfa9ff4e': {
		'anthem blue cross': false,
	},
	//Nutritional Support
	'66f1cb233663c433677a3aab': {
		'anthem blue cross': false,
	},
	//Reconstructive/Cosmetic Surgery
	'661e4964e4db2826d63fa83e': {
		'anthem blue cross': false,
	},
	//Transplant Services
	'60e3ef7f54823252fe1b8fa7': {
		'anthem blue cross': false,
	},
	//Travel Expenses Related To Obtaining Covered Services
	'66f1cb8522742f90dfa9ffd5': {
		'anthem blue cross': false,
	},
	//Weight Control
	'661e4a28ef0282e9582faccf': {
		'anthem blue cross': false,
	},
};
