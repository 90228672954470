import { isNil } from './is/is-nil';
import { Maybe } from './types/maybe';

export const getPath = <T>(obj: unknown, path: Maybe<string>) => {
	if (isNil(path)) {
		return null;
	}
	const pathSplit = path.split('.');

	pathSplit.forEach((part) => {
		if (isNil(obj)) {
			return;
		}
		// @ts-ignore
		obj = obj[part];
	});

	return obj as T;
};
