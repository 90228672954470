import { Component, Input } from '@angular/core';

enum Gender {
	male = 'm',
	female = 'f',
}

export enum Size {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
}

@Component({
	selector: 'provider-avatar',
	templateUrl: './provider-avatar.component.html',
	styleUrls: ['./provider-avatar.component.scss'],
})
export class ProviderAvatarComponent {
	@Input() url: string;
	@Input() gender: 'f' | 'm' | Gender;
	@Input() size: Size;

	getAvatarUrl(): string {
		if (this.url) return this.url;
		if (this.gender === Gender.female) return '../../../assets/images/provider-card/provider-card-female.svg';
		if (this.gender === Gender.male) return '../../../assets/images/provider-card/provider-card-male.svg';
		return '../../../assets/images/provider-card/provider-card-facility.svg';
	}
}
