import { NodeType, TemplateType } from '../shared';
import { CharterSchoolLocalize } from './charter-school.localize';

export enum ContentNodeType {
	REGULAR = 1,
	PAIR = 2,
}

export enum Headlines {
	DEDUCTIBLE = 'Deductible',
	IND_DEDUCTIBLE = 'Ind Deductible',
	OUT_OF_POCKET = 'Out of Pocket',
	IND_OUT_OF_POCKET = 'Ind Out of Pocket',
}

export const CharterSchoolCardTemplates = {
	RBP: {
		back: [
			{
				type: TemplateType.MIX,
				logo: 'away',
				headline: CharterSchoolLocalize.claimsSubmission,
				content: [
					{
						value: 'Submit medical claims to: Allegiance',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{ value: 'PO BOX 3018', type: NodeType.TEXT, contentNodeType: ContentNodeType.REGULAR },
					{ value: 'Missoula, MT 59806', type: NodeType.TEXT, contentNodeType: ContentNodeType.REGULAR },
					{
						left: { value: 'Payer ID', type: NodeType.TEXT },
						right: { value: '81040', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						value: 'Verification of benefits:',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: 'www.ccbqh.com',
						type: NodeType.LINK,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: '855-924-5291',
						type: NodeType.PHONE,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: '270/271 EDI Transactions',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						left: { value: 'Payer ID', type: NodeType.TEXT },
						right: { value: '81040', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				headline: CharterSchoolLocalize.utilization,
				content: [
					{
						value: CharterSchoolLocalize.utilizationContentPartOne,
						type: NodeType.TEXT,
					},
					{
						value: '',
						type: NodeType.TEXT,
					},
					{
						value: CharterSchoolLocalize.utilizationContentPartTwo,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'phcs',
				headline: CharterSchoolLocalize.medicalNetwork,
				content: [
					{
						value: CharterSchoolLocalize.medicalNetworkContent,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIX,
				headline: CharterSchoolLocalize.ImportantInformation,
				content: [
					{
						value: CharterSchoolLocalize.ImportantInformationContent,
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						left: { value: 'For Members', type: NodeType.TEXT },
						right: { value: '833-346-1482', type: NodeType.PHONE },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: '', type: NodeType.TEXT },
						right: { value: 'myQHCC.com', type: NodeType.LINK },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: 'For Providers:', type: NodeType.TEXT },
						right: { value: '855-924-5291', type: NodeType.PHONE },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: 'myQHCC.com', type: NodeType.LINK },
						right: { value: 'Fax: 833-612-2580', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						value: CharterSchoolLocalize.noGuarantee,
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
				],
			},
		],
	},
	OTHER: {
		back: [
			{
				type: TemplateType.MIX,
				logo: 'away',
				headline: CharterSchoolLocalize.claimsSubmission,
				content: [
					{
						value: 'Submit medical claims to: Cigna',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{ value: 'PO BOX 188061', type: NodeType.TEXT, contentNodeType: ContentNodeType.REGULAR },
					{
						value: 'Chattanooga, TN 37422-8061',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						left: { value: 'Payer ID', type: NodeType.TEXT },
						right: { value: '62308', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						value: 'Verification of benefits:',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: 'www.ccbqh.com',
						type: NodeType.LINK,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: '855-924-5291',
						type: NodeType.PHONE,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						value: '270/271 EDI Transactions',
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
					{
						left: { value: 'Payer ID', type: NodeType.TEXT },
						right: { value: '81040', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				headline: CharterSchoolLocalize.utilization,
				content: [
					{
						value: CharterSchoolLocalize.utilizationContentPartOne,
						type: NodeType.TEXT,
					},
					{
						value: '',
						type: NodeType.TEXT,
					},
					{
						value: CharterSchoolLocalize.utilizationContentPartTwo,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.REGULAR,
				logo: 'cigna',
				headline: CharterSchoolLocalize.medicalNetwork,
				content: [
					{
						value: CharterSchoolLocalize.openAccessPlus,
						type: NodeType.TEXT,
					},
					{
						value: CharterSchoolLocalize.noReferralRequired,
						type: NodeType.TEXT,
					},
				],
			},
			{
				type: TemplateType.MIX,
				headline: CharterSchoolLocalize.ImportantInformation,
				content: [
					{
						left: { value: 'For Members', type: NodeType.TEXT },
						right: { value: '833-346-1482', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: '', type: NodeType.TEXT },
						right: { value: 'myQHCC.com', type: NodeType.LINK },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: 'For Providers:', type: NodeType.TEXT },
						right: { value: '855-924-5291', type: NodeType.PHONE },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						left: { value: 'myQHCC.com', type: NodeType.LINK },
						right: { value: 'Fax: 833-612-2580', type: NodeType.TEXT },
						contentNodeType: ContentNodeType.PAIR,
					},
					{
						value: CharterSchoolLocalize.noGuarantee,
						type: NodeType.TEXT,
						contentNodeType: ContentNodeType.REGULAR,
					},
				],
			},
		],
	},
};
