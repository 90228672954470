import {
	AfterViewInit,
	Component,
	ContentChild,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { Maybe } from '../../../../utils/types/maybe';
import { InputOf } from '../../../../utils/input-reflector/input-of';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { isNil } from '../../../../utils/is/is-nil';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
	selector: 'app-drawer',
	templateUrl: './drawer.component.html',
	styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent extends CommonComponent implements AfterViewInit {
	@ContentChild('content')
	drawerTemplate: TemplateRef<any>;

	@Input()
	isVisible: Maybe<boolean>;

	@Input() drawerClass: string = '';

	@Output()
	isVisibleChange = new EventEmitter<boolean>();

	drawerOverlayRef: Maybe<OverlayRef>;

	constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'isVisible'];
	}

	ngAfterViewInit(): void {
		const isVisible$ = this.inputs.one('isVisible');

		this.subsBag.add = isVisible$.subscribe({
			next: (isVisible) => {
				if (isVisible) {
					this.openDrawer();
					return;
				}
				this.closeDrawer();
			},
		});
	}

	private openDrawer() {
		if (!isNil(this.drawerOverlayRef)) {
			return;
		}
		let panelClass = ['drawer-panel'];
		//if we recieved a list of classes
		if (this.drawerClass.includes(' ')) {
			panelClass = [...panelClass, ...this.drawerClass.split(' ')].filter(Boolean);
		} else {
			panelClass = [...panelClass, this.drawerClass].filter(Boolean);
		}

		this.drawerOverlayRef = this.overlay.create({
			hasBackdrop: true,
			panelClass: panelClass,
		});
		this.drawerOverlayRef.attach(new TemplatePortal(this.drawerTemplate, this.viewContainerRef));
		this.subsBag.add = this.drawerOverlayRef.backdropClick().subscribe({
			next: () => this.isVisibleChange.emit(false),
		});
	}

	private closeDrawer() {
		if (isNil(this.drawerOverlayRef)) {
			return;
		}
		this.drawerOverlayRef.detach();
		this.drawerOverlayRef.dispose();
		this.drawerOverlayRef = null;
	}
}
