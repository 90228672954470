import { asyncScheduler, BehaviorSubject, Observable, observeOn } from 'rxjs';
import { LoadingHandler } from './loading-handler';

export class StandardLoadingHandler implements LoadingHandler {
	private is$ = new BehaviorSubject<boolean>(false);

	isLoading$: Observable<boolean> = this.is$.pipe(observeOn(asyncScheduler));

	start() {
		this.is$.next(true);
	}

	finish() {
		this.is$.next(false);
	}
}
