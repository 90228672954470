import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TxNativeModule } from '@transifex/angular';
import { RippleModule } from 'ripple';
import { PlanDetailsModalComponent } from '../plan-details-modal/plan-details-modal.component';
import { PctNotesComponent } from '../pct-notes/pct-notes.component';

@Component({
	selector: 'app-pharmacy-services',
	templateUrl: './pharmacy-services.component.html',
	styleUrls: ['./pharmacy-services.component.scss'],
	standalone: true,
	imports: [TxNativeModule, RippleModule, CommonModule, PlanDetailsModalComponent, PctNotesComponent],
})
export class PharmacyServicesComponent {
	@Input() haPCTStyleView?: boolean = false;
	@Input() public plan: any;

	public planType: string = 'pharmacy';
	public footerText: string = 'The Co-Payment for preventative vaccinations administered at a pharmacy is $5';

	constructor() {}

	ngOnInit(): void {}
}
