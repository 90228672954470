import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipWithTouchDirective } from './tooltip-with-touch.directive';
import { TooltipBubbleComponent } from './tooltip-bubble/tooltip-bubble.component';
import { TooltipBottomSheetComponent } from './tooltip-bottom-sheet/tooltip-bottom-sheet.component';

@NgModule({
	declarations: [TooltipWithTouchDirective, TooltipBubbleComponent, TooltipBottomSheetComponent],
	imports: [CommonModule],
	exports: [TooltipWithTouchDirective],
})
export class TooltipWithTouchModule {}
