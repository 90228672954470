<div class="my-care-team-bg"></div>
<div class="my-care-team-section-body">
	<h1>
		{{ 'Set up your Care Team' | translate }}
	</h1>
	<h3>{{ 'Access your providers anytime, anywhere' | translate }}</h3>
	<button
		(click)="track('MCT - Home Page: intent to add a favorite', { 'Teaser state': 'empty' })"
		[routerLink]="['/provider-search']"
		class="primary healthee-btn sm"
	>
		{{ 'Add your first provider' | translate }}
		<img src="assets/images/icons/arrow-right.svg" />
	</button>
</div>
