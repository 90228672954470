<div
	class="menu-item"
	[routerLink]="link"
	routerLinkActive="active"
	[class.p-0]="shrinked"
	[class.m-0]="shrinked"
	[class.d-flex]="shrinked"
	[class.justify-content-center]="shrinked"
>
	<span
		class="icon-wrapper"
		[matTooltipDisabled]="!shrinked"
		[matTooltip]="text"
		matTooltipPosition="right"
		matTooltipClass="title-tooltip"
	>
		<img *ngIf="icon" class="icon-normal" [src]="icon" />

		<img *ngIf="icon" class="icon-selected" [src]="selectedIcon" />
	</span>

	<span class="text" *ngIf="!shrinked">{{ text }}</span>
</div>
