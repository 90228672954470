import { PreventiveCare } from '../../../services/preventive-care.service';

export const getNextAppointmentDate = (date: Date, frequency: number): Date => {
	const nextDate = new Date(date.getTime());
	const intoMonths = frequency * 12;
	nextDate.setMonth(nextDate.getMonth() + intoMonths);

	return nextDate;
};

export const updateCompletedDateStatusIfNeeded = (preventiveCare: PreventiveCare): PreventiveCare => {
	const completedDate = preventiveCare.completedDate;
	const isPassedCompletedDate = isCompletedDatePassed(completedDate, preventiveCare.frequency);

	if (isPassedCompletedDate) {
		return { ...preventiveCare, completedDate: null, passedCompletedDate: completedDate };
	}

	return preventiveCare;
};

export const isCompletedDatePassed = (completedDate, frequency): boolean => {
	const today = new Date();
	const nextAppointmentDate = getNextAppointmentDate(completedDate, frequency);

	return nextAppointmentDate <= today;
};
