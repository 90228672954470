import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import CFG from 'src/app/config/app-config.json';
import { map, Observable, catchError, of, switchMap } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

/**
 * The guard can be used for routes that are bypassing classical authentication flow, but still require user data to
 * operate. It checks whether user data can be fetched and saves it in the store for future usage.
 */
@Injectable({
	providedIn: 'root'
})
export class HasUserDataGuard implements CanActivate {
	constructor(private router: Router, private userService: UserService) {
	}

	canActivate(): Observable<boolean | UrlTree> {
		const defaultRoute = this.router.createUrlTree([CFG.defaultAppRoute]);

		return this.userService.getUserRequest().pipe(
			// GetUserRequest returns a promise, which then returns an observable, so we need extra switchmap to resolve
			// the inner one
			switchMap(user$ => user$),
			map((user) => {
				if(!user) return defaultRoute;

				return true;
			}),
			catchError((err) => {
				console.error(err);

				return of(defaultRoute);
			})
		);
	}
}
