<div *ngIf="planData$ | async as planData">
	<div *ngIf="planData.contract">
		<app-home-page-teaser-header>
			<div title>&nbsp;</div>
		</app-home-page-teaser-header>
		<app-home-page-teaser-body>
			<div>
				<div *ngIf="planData.contract.insuranceCompany.name === 'Anthem'">
					<p>
						Participants enjoy a comprehensive package of benefits covering medical, hospital, prescription
						drugs, behavioral health, substance use disorder, vision, and dental. Our wellness program
						includes preventive practices and educational programs to support your well-being.
					</p>
					<ul>
						<li>
							Medical and Hospital benefits are through the <strong>Anthem Blue Cross PPO</strong> plan.
							Prescription drug benefits, for Participants and eligible Dependents, are managed by
							<strong>CVS Caremark</strong>.
						</li>
						<li>
							Behavioral Health and substance use disorder, benefits are available through
							<strong>Optum Behavioral Health</strong>.
						</li>
						<li>
							Choose from two dental plans: <strong>Delta Dental PPO</strong> nationwide and
							<strong>DeltaCare USA</strong> which is exclusive to California residents. Our
							<strong>Vision Service Plan</strong> covers regular eye exams and helps with vision care
							expenses.
						</li>
					</ul>
					<p>
						This tool was created to help you navigate the benefits mentioned above. It will also assist you
						in finding providers in the Anthem Blue Cross national network and the Motion Picture Preferred
						Provider (MP3) network. We are here to support you every step of the way.
					</p>
				</div>
				<div *ngIf="planData.contract.insuranceCompany.name === 'Health Net'">
					<p>
						Participants enjoy a comprehensive package of benefits covering medical, hospital, prescription
						drugs, behavioral health, substance abuse, vision, and dental. Our wellness program includes
						preventive practices and educational programs to support your well-being.
					</p>
					<ul>
						<li>
							Exclusively tailored for California residents, the <strong>Health Net HMO</strong> plan
							provides medical, hospital, behavioral health, and substance use disorder, benefits to
							eligible Participants. Meanwhile, the administration of prescription drug benefits,
							applicable to both Participants and eligible Dependents, is overseen by
							<strong>CVS Caremark</strong>.
						</li>
						<li>
							Choose from two dental plans: <strong>Delta Dental PPO</strong> nationwide and
							<strong>DeltaCare USA</strong> which is exclusive to California residents. Our
							<strong>Vision Service Plan</strong> covers regular eye exams and helps with vision care
							expenses.
						</li>
					</ul>
					<p>
						This tool was designed to help you understand the benefits under the Health Net HMO plan. For
						more information about your coverage, or to get a copy of the complete terms of coverage, visit
						<a href="https://www.healthnet.com" target="_blank">www.healthnet.com</a> or call
						<strong>1-800-522-0088</strong>.
					</p>
					<p><a href="https://www.mpiphp.org/home/faq" target="_blank">FAQ</a></p>
				</div>
				<div *ngIf="planData.contract.insuranceCompany.name === 'Kaiser Permanente'">
					<p>
						Participants enjoy a comprehensive package of benefits covering medical, hospital, prescription
						drugs, behavioral health, substance use disorder, vision, and dental. Our wellness program
						includes preventive practices and educational programs to support your well-being.
					</p>
					<ul>
						<li>
							Exclusively tailored for California residents, the
							<strong>Kaiser Permanente HMO</strong> plan provides medical, hospital, behavioral health,
							and substance use disorder, benefits to eligible Participants. Meanwhile, the administration
							of prescription drug benefits, applicable to both Participants and eligible Dependents, is
							overseen by <strong>CVS Caremark</strong>.
						</li>
						<li>
							Choose from two dental plans: <strong>Delta Dental PPO</strong> nationwide and
							<strong>DeltaCare USA</strong> which is exclusive to California residents. Our
							<strong>Vision Service Plan</strong> covers regular eye exams and helps with vision care
							expenses.
						</li>
					</ul>
					<p>
						This tool was designed to help you understand the benefits mentioned above. For more information
						about your coverage, or to get a copy of the complete terms of coverage, visit
						<a href="https://www.kp.org/plandocuments" target="_blank">www.kp.org/plandocuments</a> or call
						<strong>1-800-278-3296</strong>.
					</p>
					<p><a href="https://www.mpiphp.org/home/faq" target="_blank">FAQ</a></p>
				</div>
				<div *ngIf="planData.contract.insuranceCompany.name === 'United Healthcare'">
					<p>
						Participants enjoy a comprehensive package of benefits covering medical, hospital, prescription
						drugs, behavioral health, substance use disorder, vision, and dental. Our wellness program
						includes preventive practices and educational programs to support your well-being.
					</p>
					<ul>
						<li>
							Exclusive to Participants residing in the tri-state area of New York, New Jersey, and
							Connecticut, the <strong>Oxford Health POS</strong> plan provides medical, hospital,
							behavioral health, and substance use disorder, benefits to eligible Participants. Meanwhile,
							the administration of prescription drug benefits, applicable to both Participants and
							eligible Dependents, is overseen by <strong>CVS Caremark</strong>.
						</li>
						<li>
							Choose from two dental plans: <strong>Delta Dental PPO</strong> nationwide and
							<strong>DeltaCare USA</strong> which is exclusive to California residents. Our
							<strong>Vision Service Plan</strong> covers regular eye exams and helps with vision care
							expenses.
						</li>
					</ul>
					<p>
						This tool was designed to help you understand the benefits mentioned above. For more information
						about your coverage, or to get a copy of the complete terms of coverage, visit
						<a href="https://www.myuhc.com" target="_blank">www.myuhc.com</a> or call
						<strong>1-800-444-6222</strong>.
					</p>
					<p><a href="https://www.mpiphp.org/home/faq" target="_blank">FAQ</a></p>
				</div>
			</div>
		</app-home-page-teaser-body>
	</div>
</div>
