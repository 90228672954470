import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-zoe-face',
	templateUrl: './zoe-face.component.html',
	styleUrls: ['./zoe-face.component.scss'],
})
export class ZoeFaceComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
