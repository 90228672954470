import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ProviderLocation } from '../../../helpers/providers.helpers';

@Component({
	selector: 'app-mpi-provider-locations',
	templateUrl: './provider-locations.component.html',
	styleUrls: ['./provider-locations.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderLocationsComponent {
	@Input() locations: ProviderLocation[];
}
