<div class="input-wrapper">
	<mat-form-field appearance="outline">
		<mat-label>{{ 'Address' | translate }}</mat-label>
		<input
			matInput
			ngx-google-places-autocomplete
			type="text"
			#placesRef="ngx-places"
			placeholder="{{ 'Type an address...' | translate }}"
			class="place-search-input fs-unmask"
			[options]="options"
			[disabled]="!enabled"
			[(ngModel)]="searchTerm"
			(keydown)="showResultsPanel()"
			(onAddressChange)="onAddressChanged($event)"
		/>
		<mat-icon matPrefix>location_on</mat-icon>
	</mat-form-field>
</div>
