import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ChipListFormControlComponent } from './chip-list-form-control/chip-list-form-control.component';

@NgModule({
	declarations: [ChipListFormControlComponent],
	imports: [
		CommonModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		TxNativeModule,
	],
	exports: [ChipListFormControlComponent],
})
export class ChipListFormControlModule {}
