import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { RxjsUtils } from '../../../utils/rxjs';
import { AllServicesStoreService } from 'src/app/services/stores/all-services-store/all-services-store.service';
import { UserService } from 'src/app/services/user.service';
import { UserBenefitsStoreService } from 'src/app/services/stores/user-benefits-store/user-benefits-store.service';
import { AdditionalBenefitsStoreService } from 'src/app/services/stores/additional-benefits-store/additional-benefits-store.service';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { MpiPlan, AnthemPopularBenefitIds, ServiceNamesForPopularBenefits } from 'src/app/utils/mpi/mpi.constant';

const DefaultTeasersOrder = {
	quickAccess: 1,
	telehealth: 2,
	deductible: 3,
	healthCards: 4,
	selectedBenefits: 5,
	preventiveCare: 6,
	myCareTeam: 7,
	planSelection: 8,
	quantumSupport: 9,
	poweredByHealthee: 10,
};

@Injectable({
	providedIn: 'root',
})
export class HomePageService {
	teasersOrder$: Observable<Record<keyof typeof DefaultTeasersOrder, number>> = of(DefaultTeasersOrder);
	userBenefitsMap$: Observable<Map<string, any>> = this.userService.userData$.pipe(
		switchMap((user) => this.userBenefitsStoreService.benefitsMap(user.uid)),
		shareReplay(1)
	);

	constructor(
		private allServicesStoreService: AllServicesStoreService,
		private userService: UserService,
		private userBenefitsStoreService: UserBenefitsStoreService,
		private additionalBenefitsStoreService: AdditionalBenefitsStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {}

	public getMostPopularCoveredServicesAndAdditionalBenefits(amount: number): Observable<any[]> {
		return combineLatest([
			this.getMostPopularCoveredServices(amount),
			this.additionalBenefitsStoreService.get().pipe(RxjsUtils.isNotNil()),
		]).pipe(
			map(([mostPopularCoveredServices, additionalBenefits]) => {
				return additionalBenefits.length ? additionalBenefits : mostPopularCoveredServices;
			}),
			shareReplay(1)
		);
	}
	public getMostPopularCoveredServices(amount: number): Observable<any[]> {
		return combineLatest([
			this.allServicesStoreService.get().pipe(
				RxjsUtils.isNotNil(),
				map((services) => services.sort((a, b) => b.weight - a.weight))
			),
			this.userBenefitsMap$,
			this.userPlanDataStoreService.getUserMedicalPlan().pipe(RxjsUtils.isNotNil()),
		]).pipe(
			map(([services, userBenefitsMap, userPlan]) => {
				// Sorts the services based on the order in PopularBenefits Id's consts defined(e.x. AnthemPopularBenefitIds)
				const sortByMpiPopularServiceOrder = (service1: any, service2: any, planIdsConst: string[]) => {
					const index1 = planIdsConst.indexOf(service1._id);
					const index2 = planIdsConst.indexOf(service2._id);
					// If either service is not found in AnthemPopularBenefitIds, put it at the end
					if (index1 === -1) return 1;
					if (index2 === -1) return -1;
					// Sort based on the index in AnthemPopularBenefitIds (lower index comes first)
					return index1 - index2;
				};

				if (userPlan === MpiPlan.Anthem) {
					const popularServices = services
						.filter(
							(service) =>
								userBenefitsMap.has(service._id) && AnthemPopularBenefitIds.includes(service._id)
						)
						.map((service) => {
							return {
								...service,
								abbreviate: ServiceNamesForPopularBenefits[service._id] || service.abbreviate,
							};
						})
						.sort((a, b) => sortByMpiPopularServiceOrder(a, b, AnthemPopularBenefitIds))
						.slice(0, amount);
					return popularServices;
				}
				return services.filter((service) => userBenefitsMap.has(service._id)).slice(0, amount);
			}),
			shareReplay(1)
		);
	}
}
