<span class="rating-and-cost">
	<span class="rating" *ngIf="rating > 0">
		<img src="/assets/images/icons/rating-star.svg" alt="rating" />

		<span class="bold">{{ normalizedRatingStarsToDisplay }}</span
		>/{{ totalStarsToDisplay }}
		<span>({{ numberOfReviews }})</span>
	</span>
	<span class="unavailable-rating" *ngIf="rating === 0">
		<img src="/assets/images/icons/rating-star-unavailable.svg" alt="rating" />

		<span>({{ numberOfReviews }})</span>
	</span>
	<br />
	<span class="cost">
		<span *ngFor="let item of costSigns$ | async" [class.font-weight-700]="item.isBold">$</span>
	</span>
</span>
