import { Injectable } from '@angular/core';
import { BehaviorSubject, from, firstValueFrom, Observable, zip, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { Capacitor } from '@capacitor/core';
import { FingerprintAIO, FingerprintOptions, FingerprintSecretOptions } from '@ionic-native/fingerprint-aio/ngx';

import { isIonic } from '../utils/utils';
import { User } from '../models/user.model';

import { StorageService } from './storage.service';
import { UserService } from './user.service';

export type BiometricsType = 'face' | 'finger';
export type BiometricData = Record<string, string>;

const TAG = 'BiometryService';

const BIO_AUTH_ERROR_PERMISSIONS_DENIED = -101;
const BIO_AUTH_ERROR_MATCH_NOT_FOUND = -108;
const BIO_AUTH_ERROR_BIOMETRY_IS_LOCKED = -111; // should run this.faio.show with disableBackup: false
const BIO_AUTH_ERROR_REMOTE_ALERT_DEACTIVATED = -100; // should run this.faio.show with disableBackup: false

@Injectable({ providedIn: 'root' })
export class BiometryService {
	private _isBiometryUsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public isBiometryUsed$: Observable<boolean> = this._isBiometryUsed$.asObservable();

	private biometricsType: BiometricsType = null;

	constructor(
		private faio: FingerprintAIO,
		private storageService: StorageService,
		private userService: UserService
	) {
		this.loadStoredBiometricSettings();
	}

	private loadStoredBiometricSettings() {
		const isBiometryUsed = this.storageService.getBiometrySettings();
		this.setBiometryUsageStatus(isBiometryUsed);
	}

	private setBiometryUsageStatus(isBiometryUsed: boolean) {
		this._isBiometryUsed$.next(isBiometryUsed);
		this.storageService.saveBiometrySettings(isBiometryUsed);
	}

	public async canUseBiometricToken(): Promise<boolean> {
		if (!(await this.isBiometricsAvailable())) {
			return false;
		}

		if (!(await firstValueFrom(this.isBiometryUsed$))) {
			console.log(TAG, 'User has turned off biometric auth or no available biometry data.');
			return false;
		}

		console.log(TAG, 'Biometry can be used.');
		return true;
	}

	public getBiometricData(): Observable<BiometricData> {
		return from(this.loadSavedSecretFromBiometryAPI()).pipe(
			switchMap(async (secretString: string) => {
				if (!secretString) throw new Error('Invalid saved biotoken');

				const bioData: BiometricData = JSON.parse(secretString);
				let idToken = null;
				for (const key in bioData) {
					localStorage.setItem(key, bioData[key]);
					if (key.endsWith('idToken')) {
						idToken = bioData[key];
					}
				}

				if (idToken) {
					this.userService.updateToken(idToken);
				}

				return bioData;
			}),

			catchError((error: Error) => {
				this.clearBiometryUsage();
				throw new Error(error.message);
			})
		);
	}

	private async loadSavedSecretFromBiometryAPI() {
		const biometricMethodName: string = this.getBiometricMethodName(this.biometricsType);
		const description = `Authenticate with ${biometricMethodName} to unlock the app`;
		const biometryConfig: FingerprintOptions = { description };

		return this.faio.loadBiometricSecret(biometryConfig);
	}

	/** Attempts to save biometric login settings by saving JWT as the biometric secret.
	 * Should be called after a successful manual login.
	 * If user has not explicitly disabled biometry, the JWT is saved in the biometry API
	 * for it to be restored in the next login when 'restoreJWTFromBiometryRegistry() is called
	 */
	public async attemptSettingBiometricLogin() {
		if (!(await this.isBiometricsAvailable())) {
			console.log(TAG, 'Biometric auth is not supported. Exiting.');
			return;
		}

		if ((await firstValueFrom(this.isBiometryUsed$)) === false) {
			// user has explicitly set biometry use to 'false'
			console.log(TAG, 'User has explicitly turned off biometric auth. Exiting.');
			return;
		}

		this.gatherBiometryDataAndSaveToBiometryRegistry('Authenticate to use biometry next time for fast login');
	}

	public async toggleBiometryUsage() {
		const currentBioAuthState = await firstValueFrom(this.isBiometryUsed$);

		console.log(TAG, 'Changing bio state from', currentBioAuthState, '->', !currentBioAuthState);

		// if biometry is true -> change it to false, without authenticating or writing to the biometry API
		if (currentBioAuthState) {
			this.setBiometryUsageStatus(false);
			return;
		}

		if (Capacitor.getPlatform() === 'ios') {
			// bio-authenticate user only in iOS (In Android authentication is done automatically when writing to biometry API)
			if (!(await this.verifyUserOniOS())) {
				alert('You must authenticate to change biometric authentication settings');
				return;
			}
		}

		this.gatherBiometryDataAndSaveToBiometryRegistry('Authenticate to use biometry');
	}

	private gatherBiometryDataAndSaveToBiometryRegistry(messageToUser: string) {
		const userInfo$ = this.userService.user$.pipe(map((user: User) => {
			return { uid: user?.data.uid, email: user?.data.email};
		}));

		const tokenData: BiometricData = {};
		const localStorageItems = { ...localStorage };
		//TODO: should use auth service to store the token
		for (const key in localStorageItems) {
			if (key.startsWith('CognitoIdentityServiceProvider')){
				tokenData[key] = localStorageItems[key];
			}
		}
		zip([of(tokenData), userInfo$])
			.pipe(
				switchMap(async ([tokenData, userInfo]) => {
					tokenData = {...tokenData, ...userInfo}
					const bioDataStr = JSON.stringify(tokenData);
					const description = messageToUser;
					const biometryConfig: FingerprintSecretOptions = { description, secret: bioDataStr };
					return [await this.faio.show(biometryConfig), biometryConfig];
				}),
				switchMap(([isAuth, biometryConfig]) => {
					if (!isAuth) throw new Error('Failed to authenticate using biometry');
					return from(this.faio.registerBiometricSecret(biometryConfig));
				})
			)
			.subscribe({
				next: () => {
					console.log(TAG, 'Successfully registered biometry token');
					this.setBiometryUsageStatus(true);
				},
				error: (error) => {
					console.log(TAG, 'Could not register biometry token using the biometry API:', error);
				},
			});
	}

	private async verifyUserOniOS(disablePasscodeBackup: boolean = true) {
		// In Android - this function is not needed as the user is verified with every
		// read/write from/to the biometry API
		if (Capacitor.getPlatform() !== 'ios') return;

		const description = disablePasscodeBackup
			? 'Please confirm your biometrics for fast login'
			: 'Please enter your passcode to unlock biometric auth';

		const biometryConfig: FingerprintOptions = { description, disableBackup: disablePasscodeBackup };

		try {
			const result = await this.faio.show(biometryConfig);
			console.log(TAG, 'verifyUserOniOS: User successfully authenticated using biometric auth', result);
		} catch (error) {
			console.log(TAG, 'Match not found! Error:', error);
			return await this.handleiOSBiometryError(error);
		}

		return true;
	}

	private handleiOSBiometryError(error: any) {
		// native iOS biometry error
		switch (error.code) {
			case BIO_AUTH_ERROR_BIOMETRY_IS_LOCKED:
				return this.verifyUserOniOS(false); // false = tryUnlockingDeviceWithPasscode

			case BIO_AUTH_ERROR_PERMISSIONS_DENIED:
				this.stopUsingBiometry();
				return false;

			case BIO_AUTH_ERROR_MATCH_NOT_FOUND:
				console.log(TAG, 'Match not found!');
				return false;

			case BIO_AUTH_ERROR_REMOTE_ALERT_DEACTIVATED:
				console.log(TAG, 'Remote alert deactivated. Error:', error);
				return false;

			default:
				console.log(TAG, 'Unknown biometry error code. Error:', error);
				return false;
		}
	}

	private stopUsingBiometry() {
		alert(
			'You denied permissions to use biometric auth. To re-enable biometric authentication, you need to turn on Face ID/Touch ID under iPhone Settings -> Healthee'
		);
		console.log(TAG, 'User denied permissions to use biometric auth.');
		this.setBiometryUsageStatus(false);
	}

	private async isBiometricsAvailable() {
		if (!isIonic() || Capacitor.getPlatform() === 'web') {
			return false;
		}

		try {
			this.biometricsType = await this.faio.isAvailable();
		} catch (error) {
			console.log(TAG, 'Biometry is not supported on this device. Error:', error);
			return false;
		}

		return true;
	}

	private getBiometricMethodName(
		bioAuthSupportedMethodCode: BiometricsType
	): 'Touch ID' | 'Face ID' | 'biometric auth' {
		switch (bioAuthSupportedMethodCode) {
			case 'finger':
				return 'Touch ID';
			case 'face':
				return 'Face ID';

			default: // null or unknown
				return 'biometric auth';
		}
	}

	public clearBiometryUsage() {
		this.setBiometryUsageStatus(null);
	}
}
