import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { Observable } from 'rxjs';

import { AppLanguage, LanguageService } from '../../../services/language.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
	selector: 'app-change-language',
	templateUrl: './change-language.component.html',
	styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent extends HealtheeDialogContent implements OnInit {
	public supportedLanguages$: Observable<AppLanguage[]>;
	public selectedLanguage$: Observable<AppLanguage>;

	constructor(
		private languageService: LanguageService,
		private trackingService: TrackingService,
		) {
		super();
	}

	ngOnInit(): void {
		this.supportedLanguages$ = this.languageService.supportedLanguages$;
		this.selectedLanguage$ = this.languageService.appLanguage$;
	}

	public setLanguage(locale: string): void {
		this.data.isActivationFlow
		? this.languageService.setLocale(locale)
		: this.languageService.updateUserLocale(locale);

		this.trackingService.trackClientEvent('Language switch', { 'selected language': locale });

		this.closeDialog.emit(null);
	}
}
