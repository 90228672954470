import { AddressDetails, PanelDemographics, RibbonDoctor, RibbonFax } from 'src/app/models/ribbon-data.model';
import { LngLat } from '../../../../services/providers-search/helpers/providers-search.helpers';
import { Maybe } from '../../../../utils/types/maybe';
import { SearchEntity } from './providers-search.helper';

export interface RibbonFacilityAddress {
	zip: string;
	city: string;
	state: string;
	street: string;
	address_line_1: string;
	address_line_2: string;
}

export interface RibbonFacility {
	uuid: string;
	version_id: string;
	name: string;
	address: string;
	address_details: RibbonFacilityAddress;
	mpi_address: string;
	latitude: number;
	longitude: number;
	google_maps_link: string;
	phone_numbers: RibbonPhoneNumber[];
	faxes: RibbonFax[];
	confidence: number;
	location_types: string[];
	aha_id: string;
	parent_aha_id: string;
	distance: number;
	insurances?: { uuid: string }[];
	insurance_group: string;
	type?: string;
}

export interface FacilityAddress {
	zip: string;
	city: string;
	state: string;
	street: string;
	addressLine1: string;
	addressLine2: string;
}

export interface Facility {
	uuid: string;
	versionId: string;
	name: string;
	address: string;
	addressDetails: FacilityAddress;
	mpiAddress: string;
	latitude: number;
	longitude: number;
	googleMapsLink: string;
	phoneNumbers: string[];
	faxes: string[];
	confidence: number;
	locationTypes: string[];
	ahaId: string;
	parentAhaId: string;
	distance: number;
	insuranceGroup: string;
	type?: string;
}

export enum ProviderGender {
	Male = 'm',
	Female = 'f',
}

export interface RibbonProvider {
	npi: string;
	first_name: string;
	last_name: string;
	gender: ProviderGender;
	ratings_count: number;
	ratings_avg: number;
	degrees: string[];
	is_pcp: boolean;
	specialties: RibbonSpecialty[];
	languages: string[];
	mpi_languages: string[];
	locations: RibbonLocation[];
	board_certifications: [];
	distance: number;
	is_z: boolean; // Indicates whether the provider is associated with Zocdoc (stored as "is_z" for ribbon)
	clinical_areas?: [];
	panel_demographics: PanelDemographics;
	educations: [];
	type?: string;
}

interface RibbonLocation {
	name: string;
	uuid: string;
	faxes: RibbonFax[];
	address: string;
	latitude: number;
	longitude: number;
	confidence: number;
	phone_numbers: RibbonPhoneNumber[];
	address_details: RibbonAddressDetails;
	google_maps_link: string;
	distance: number;
	accepting_new_patients: boolean;
	insurance_group?: string;
	mpi_address?: string;
}

interface RibbonPhoneNumber {
	phone: string;
	details: 'primary' | 'secondary';
}

interface RibbonAddressDetails {
	zip: string;
	city: string;
	state: string;
	street: string;
	address_line_1: string;
	address_line_2: string;
}

interface RibbonSpecialty {
	uuid: string;
	taxonomy_code: string;
	board_specialty: string;
	board_sub_specialty: string;
	non_md_specialty: string;
	non_md_sub_specialty: string;
	colloquial: string;
	display: string;
	taxonomy_1: string;
	taxonomy_2: string;
	taxonomy_3: string;
	provider_type: string;
	is_primary: boolean;
	provider_name: string;
}

export interface Provider {
	npi: string;
	firstName: string;
	lastName: string;
	gender: ProviderGender;
	ratingsCount: number;
	ratingsAvg: number;
	degrees: string[];
	mpiDegrees: string[];
	isPcp: boolean;
	providerTypes: string[];
	languages: string[];
	mpiLanguages: string[];
	locations: ProviderLocation[];
	distance: number;
	specialties: string[];
	primarySpecialty: string;
	isZocdoc: boolean;
	clinical_areas?: [];
	panelDemographics: PanelDemographics;
	educations: [];
	type?: string;
}

export interface ProviderLocation extends LngLat {
	name: string;
	uuid: string;
	address: string;
	phoneNumbers: string[];
	faxes: string[];
	distance: number;
	acceptingNewPatients: boolean;
	insuranceGroup?: string;
	mpiAddress?: string;
}

export interface Coverage {
	isInNetwork: Maybe<boolean>;
	networkContract: Maybe<string>;
}

export interface Review {
	id?: number;
	source_name?: string;
	rating?: number;
	url?: string;
	first_name?: string;
	last_name?: string;
	image?: string;
	text?: string;
	reviewed_at?: string;
	likes?: number;
	replies?: any[];
}

export interface Reviews {
	reviews?: Review[];
}

export type ProviderDetails = Provider & Coverage & Reviews;
export type FacilityDetails = Facility & Coverage;

export const findPrimaryLocation = (provider: RibbonProvider | RibbonDoctor): string => {
	return (provider.specialties || []).find((specialty) => specialty.is_primary)?.provider_name || '';
};

export const normalizeProvider = (ribbonProvider: RibbonProvider): Provider => {
	const primarySpecialty = findPrimaryLocation(ribbonProvider);
	const mpiAllowedDegrees = ['MD', 'DPM', 'DO', 'DDS', 'RD', 'DMD'];

	return {
		npi: ribbonProvider.npi,
		firstName: ribbonProvider.first_name,
		lastName: ribbonProvider.last_name,
		gender: ribbonProvider.gender as ProviderGender,
		ratingsCount: ribbonProvider.ratings_count,
		ratingsAvg: ribbonProvider.ratings_avg,
		degrees: ribbonProvider.degrees,
		mpiDegrees: ribbonProvider.degrees.filter((degree) => mpiAllowedDegrees.includes(degree)),
		isPcp: ribbonProvider.is_pcp,
		providerTypes: [],
		languages: ribbonProvider.languages || [],
		mpiLanguages: ribbonProvider.mpi_languages || [],
		locations: (ribbonProvider.locations || []).map(normalizeLocation),
		distance: ribbonProvider.distance,
		specialties: (ribbonProvider.specialties || []).map((specialty) => specialty.display),
		primarySpecialty,
		isZocdoc: !!ribbonProvider.is_z,
		clinical_areas: ribbonProvider.clinical_areas || [],
		panelDemographics: ribbonProvider.panel_demographics,
		educations: ribbonProvider.educations,
		type: ribbonProvider.type,
	};
};

export const normalizeLocation = (location: RibbonLocation): ProviderLocation => ({
	name: location.name,
	uuid: location.uuid,
	address: location.address,
	latitude: location.latitude,
	longitude: location.longitude,
	phoneNumbers: location.phone_numbers?.map((obj) => obj.phone) ?? [],
	faxes: location.faxes?.map((obj) => obj.fax) ?? [],
	distance: location.distance,
	acceptingNewPatients: location.accepting_new_patients,
	insuranceGroup: location.insurance_group,
	mpiAddress: location.mpi_address,
});

export const retriveIsInNetwork = (searchParameters): Maybe<boolean> => {
	if (searchParameters.insurance_ids?.length) {
		return true;
	}
	if (searchParameters._excl_insurance_ids?.length) {
		return false;
	}

	return null;
};

export const normalizeFacility = (ribbonFacility: RibbonFacility): Facility => {
	return {
		uuid: ribbonFacility.uuid,
		versionId: ribbonFacility.version_id,
		name: ribbonFacility.name,
		address: ribbonFacility.address,
		mpiAddress: ribbonFacility.mpi_address,
		addressDetails: {
			zip: ribbonFacility.address_details.zip,
			city: ribbonFacility.address_details.city,
			state: ribbonFacility.address_details.state,
			street: ribbonFacility.address_details.street,
			addressLine1: ribbonFacility.address_details.address_line_1,
			addressLine2: ribbonFacility.address_details.address_line_2,
		},
		latitude: ribbonFacility.latitude,
		longitude: ribbonFacility.longitude,
		googleMapsLink: ribbonFacility.google_maps_link,
		phoneNumbers: ribbonFacility.phone_numbers?.map((obj) => obj.phone) || [],
		faxes: ribbonFacility.faxes?.map((obj) => obj.fax) || [],
		confidence: ribbonFacility.confidence,
		locationTypes: ribbonFacility.location_types,
		ahaId: ribbonFacility.aha_id,
		parentAhaId: ribbonFacility.parent_aha_id,
		distance: ribbonFacility.distance,
		insuranceGroup: ribbonFacility.insurance_group,
		type: ribbonFacility.type,
	};
};

export const normalizeAddress = (addressDetails: AddressDetails | RibbonAddressDetails): string => {
	return [addressDetails?.street, addressDetails?.city, addressDetails?.state].filter((piece) => !!piece).join(', ');
};

export const normalizeProviderAndFacility = (ribbonData) => {
	if (ribbonData.type === SearchEntity.Provider) {
		return normalizeProvider(ribbonData);
	}

	if (ribbonData.type === SearchEntity.Facility) {
		return normalizeFacility(ribbonData);
	}
};
