import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';
import { isUUID } from 'src/app/utils/is/is-uuid';

@Component({
	selector: 'app-referral-referred-content',
	templateUrl: './referral-referred-content.component.html',
	styleUrls: ['./referral-referred-content.component.scss'],
})
export class ReferralsReferredContentComponent {
	@Input() item: ReferralsInfoResponse;
	@Input() referredTo: boolean = false;

	constructor(private router: Router) {}

	public get specialistName(): string {
		if (this.referredTo) {
			return `${this.item.ProviderToFirstName} ${this.item.ProviderToMiddleInitial} ${this.item.ProviderToLastName}`;
		}

		return `${this.item.ProviderFromFirstName} ${this.item.ProviderFromMiddleInitial} ${this.item.ProviderFromLastName}`;
	}

	public get specialityName(): string {
		return this.item.SpecialtyDescription;
	}

	public contactDetailsHandler() {
		const code = this.referredTo ? this.item.ProviderToCode : this.item.ProviderFromCode;

		if (isUUID(code)) {
			this.navigateToFacility(code);
		} else {
			this.navigateToSpecialist(code);
		}
	}

	private navigateToSpecialist(code: string) {
		this.router.navigate([`/care-and-costs/mp3-providers/specialist/${code}`]);
	}

	private navigateToFacility(code: string) {
		this.router.navigate([`/care-and-costs/mp3-providers/facility/${code}`]);
	}
}
