import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import CFG from '../../../../config/app-config.json';

@Component({
	selector: 'app-talon-widget-dialog',
	templateUrl: './talon-widget-dialog.component.html',
	styleUrls: ['./talon-widget-dialog.component.scss'],
})
export class TalonWidgetDialogComponent implements OnInit, OnDestroy {
	@ViewChild('talonWidgetDialog') talonWidgetDialog: ElementRef;

	constructor(
		private http: HttpClient,
		private dialogRef: MatDialogRef<TalonWidgetDialogComponent>,

		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.findProcedure();
	}

	findProcedure() {
		this.http.post(CFG.apiEndpoints.talonIFrameSrcUrl, { type: 'procedureSearch' }).subscribe({
			next: (resp: any) => {
				this.talonWidgetDialog.nativeElement.src = resp + '&hideSidebar=true';
			},
			error: (error: Error) => this.displayErrorLoadingTalonWidget(error),
		});
	}

	private displayErrorLoadingTalonWidget(error: Error) {
		// placeholder to trigger display of talon connection error as part of HA-213
		// meanwhile, intentionally hiding (catching and not rethrowing) Talon error messages from the user
		console.log('Error opening Talon widget:', error.message);
	}

	ngOnDestroy(): void {
		this.closeWithMsg();
	}

	closeWithMsg(): void {
		this.dialogRef.close({ resumeWatson: true });
	}
}
