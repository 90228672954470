import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseGipitpotMessage } from "../../models/gipitpot/gipitpot-messages";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class GipitpotCommunicationService {
	constructor(private http: HttpClient) {
	}

	public sendUserMessage(message: BaseGipitpotMessage): Observable<any> {

		const url = `${environment.gipitpotURL}/chat`;
		return this.http.post(url, {
			session_id: message.sessionId || "XX" + Math.random(),
			input: message.text,
			bot_type: "zoe_demo"
		});
	}
}

export const GipitpotCommunicationServiceStub: Partial<GipitpotCommunicationService> = {
	sendUserMessage: () => {
		return new Observable();
	}
};
