import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExternalApiService } from 'src/app/services/external-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackingService } from 'src/app/services/tracking.service';
import { T } from '@transifex/angular';
import { Rating } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.component';
import { FeedbackLocalize } from 'src/app/modules/standalone/feedback/feedback-localize';

export interface Feedback {
	rating?: number;
	feature: string;
	data: object;
	description?: string;
}
@Component({
	selector: 'app-feedback-dialog',
	templateUrl: './feedback-dialog.component.html',
	styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent extends HealtheeDialogContent implements OnInit {
	public selectedRating: Rating = null;
	public feedbackForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private externalApiService: ExternalApiService,
		private trackingService: TrackingService,
		private matSnackBar: MatSnackBar
	) {
		super();
	}

	ngOnInit(): void {
		this.buildFeedbackForm();
		this.selectedRating = this.data?.rating || 0;
	}

	private buildFeedbackForm() {
		this.feedbackForm = this.formBuilder.group({
			rating: [this.data?.rating, [Validators.required]],
			description: [''],
		});
	}

	onRatingIconSelected(rating: Rating) {
		this.feedbackForm.controls.rating.setValue(rating);
		this.selectedRating = rating;
	}

	public onSubmitFeedbackForm() {
		this.externalApiService.sendFeedbackToMonday({
			rating: this.feedbackForm.controls.rating.value,
			feature: this.data.feature,
			data: this.data.data,
			description: this.feedbackForm.controls.description.value,
		});
		this.confirmDialog.emit(true);
		this.matSnackBar.open(FeedbackLocalize.feedbackSentMessage, 'X', {
			duration: 5000,
			panelClass: 'snackbar-success',
			verticalPosition: 'top',
		});

		this.trackingService.trackClientEvent('Feedback Dialog Add Comment', {
			feature: this.data.feature,
			comment: this.feedbackForm.controls.description.value,
			extraProperties: this.data.logData
		});
	}
}
