<div class="appointment-submitted-component-wrapper">
	<div class="main">
		<div class="title">{{ 'Your appointment request has been submitted.' | translate }}</div>
		<div class="warning-container">
			<div class="pending">{{ 'PENDING' | translate }}</div>
			<div *ngIf="!isReschedule">
				{{
					'The provider must confirm the booking before it is finalized, which usually takes up to 24 hours.'
						| translate
				}}
			</div>
			<div *ngIf="isReschedule">
				{{
					'Your new appointment booking request will be pending confirmation and replace your existing appointment.'
						| translate
				}}
			</div>
		</div>
	</div>
	<div *ngIf="!userData.subscribeToAppointmentNotifications" class="approval">
		<mat-checkbox
			*ngIf="!userData.subscribeToAppointmentNotifications"
			[checked]="true"
			(change)="onChoiceChange($event)"
			color="primary"
		>
			{{
				'I agree to receive medical appointments scheduling information through emails and texts.' | translate
			}}
		</mat-checkbox>
	</div>
</div>
