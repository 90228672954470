import {
	AfterViewInit,
	Component,
	ElementRef,
	forwardRef,
	HostListener,
	Input,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
	selector: 'ripple-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
	@Input() label?: string;
	@Input() type: string = 'text';
	@Input() pattern: string = '.*';
	@Input() leadingIcon: string | null = null;
	@Input() placeholder: string = '';
	@Input() hideNumberArrows: boolean = false;
	@Input() matAutocomplete?: MatAutocomplete;
	@Input() showResetButton = false;
	@Input() showLoading = false;
	@Input() focusOnInit?: boolean = false;
	@Input() unmaskInSessions: boolean = false;

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;
	@ViewChild('inputRef', { static: false }) inputElementRef: ElementRef;
	@ViewChild('autocompleteRef', { static: false }) autocompleteRef: ElementRef;

	public value: string = '';
	public isDisabled: boolean = false;
	public onChange: (value: string) => void = () => {};
	public onTouched: () => void = () => {};

	@HostListener('wheel', ['$event'])
	public handleWheelEvent(event: WheelEvent): void {
		// prevent scroll behavior on number inputs
		if (this.type === 'number') {
			event.preventDefault();
		}
	}

	constructor(private renderer: Renderer2) {}

	ngAfterViewInit(): void {
		if (this.focusOnInit) {
			setTimeout(() => {
				this.focus();
			}, 100);
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	public registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public handleChange(value: string): void {
		this.value = value;
		this.onChange(value);
	}

	public resetValue() {
		this.value = '';
		this.onChange('');
	}

	public focus(): void {
		if (!!this.matAutocomplete) {
			this.autocompleteRef.nativeElement.focus();
		} else {
			this.inputElementRef.nativeElement.focus();
		}
	}
}
