import { isNotNil } from './is-not-nil';
import { arrayLength } from './array-length';
import { timedCache } from './timed-cache';
import { arrayFirst } from './array-first';

export const RxjsUtils = {
	isNotNil,
	arrayLength,
	timedCache,
	arrayFirst,
};
