import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Store } from '../../../utils/store/store';
import { toByKey } from '../../../utils/utils';
import { FavoriteProvider } from '../../../modules/my-care-team/helpers/providers.helpers';
import { FavoriteProvidersApiService } from '../../api/favorite-providers-api/favorite-providers-api.service';

@Injectable({
	providedIn: 'root',
})
export class FavoriteProvidersStoreService extends Store<void, FavoriteProvider[]> {
	constructor(private favoriteProvidersApiService: FavoriteProvidersApiService) {
		super();
	}

	protected retrieve(context: void): Observable<FavoriteProvider[]> {
		return this.favoriteProvidersApiService.getFavoriteProviders();
	}

	getFavoriteProvidersByNpi(): Observable<{ [npi: string]: FavoriteProvider }> {
		return this.get().pipe(
			map((providers) => toByKey<FavoriteProvider>(providers || [], 'npi')),
			shareReplay(1)
		);
	}

	isFavorite(npi: string): Observable<boolean> {
		return this.getFavoriteProvidersByNpi().pipe(
			map((providers) => !!providers[npi]?.isFavorite),
			shareReplay(1)
		);
	}
}
