import { Component, Input, ViewEncapsulation } from '@angular/core';

const ASSETS_PATH = '/assets/images/provider-card';

@Component({
	selector: 'app-mpi-map-and-info',
	templateUrl: './map-and-info.component.html',
	styleUrls: ['./map-and-info.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MapAndInfoComponent {
	private _locations: any[] = [];

	@Input()
	set locations(value: any[]) {
		this._locations = value.filter((location) => location !== null && location !== undefined);
	}

	get locations(): any[] {
		return this._locations;
	}

	public activeLocationIndex = 0;
	public assetsPath: string = ASSETS_PATH;

	public displayPrevLocation(): void {
		this.activeLocationIndex--;
	}

	public displayNextLocation(): void {
		this.activeLocationIndex++;
	}

	public openGoogleMapsDirections(): void {
		const address = this.locations[this.activeLocationIndex].address;
		window.open(`https://www.google.com/maps/dir//${encodeURIComponent(address)}`, '_blank');
	}

	public isLastLocation(selectedProviderLocationsData): boolean {
		return this.activeLocationIndex >= selectedProviderLocationsData.length - 1;
	}
}
