import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import { useIonicFile, isIonic } from './utils/utils';
import CFG from './config/app-config.json';
import { environment } from '../environments/environment';

import { UIService } from './services/ui.service';
import { LanguageService } from './services/language.service';
import { MobileAppService } from './services/mobile-app.service';
import { StoreUpdateHandlerService } from './services/stores/store-update-handler.service';
import { PreviousRouteService } from './services/PreviousRouteService';
import { TrackingService } from './services/tracking.service';
import { AuthService } from './modules/account/login/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: useIonicFile('./app.component.html', './app.component.ionic.html'),
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public showScreenOverlay$: Observable<boolean>;
	public loaded: boolean = false;
	public routingSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private languageService: LanguageService,
		private router: Router,
		private uiService: UIService,
		private ngZone: NgZone,
		private mobileAppService: MobileAppService,
		private storeUpdateHandlerService: StoreUpdateHandlerService,
		private previousRouteService: PreviousRouteService,
		private trackingService: TrackingService,
		private authService: AuthService,
		@Inject(DOCUMENT) private document: any
	) {}

	ngOnInit() {
		this.logAngularAppLaunchTimeAfterUpdateThroughNativeLayer();

		if (window) window.name = 'healthee';
		if (isIonic()) {
			this.router.navigate(['/app-update']);
		}

		if (AuthService.isFederatedLogin) {
			this.uiService.setCognitoLoginMode()
		}

		this.startAppInit();
	}

	private logAngularAppLaunchTimeAfterUpdateThroughNativeLayer() {
		const eventName = 'Angular app launch';
		const iOSLabelInTrackingService = 'iOS';
		const androidLabelInTrackingService = 'Android';

		const platform = Capacitor.getPlatform();
		let platformLabel: string;

		switch (platform) {
			case 'ios':
				platformLabel = iOSLabelInTrackingService;
				break;

			case 'android':
				platformLabel = androidLabelInTrackingService;
				break;

			default:
				return; // Not iOS nor Android, so no need to log app launch times
		}

		const eventProperties = {
			platform: platformLabel,
			type: 'Angular',
			environment: environment.envName,
		};

		this.trackingService.trackClientEvent(eventName, eventProperties);
	}

	private startAppInit() {
		this.waitForLocaleParam();
		this.registerObservables();
		this.setupAppUrlListener();
		this.registerMobileAppStatus();
		this.setScreenSize();
		this.storeUpdateHandlerService.init();
		this.subscribeToRouting();
	}

	private subscribeToRouting() {
		this.routingSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.previousRouteService.setPreviousUrl(event.url);
			}
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: UIEvent) {
		const targetedWindow = event.target as Window;
		this.uiService.updateAppViewMode(targetedWindow);
	}

	private waitForLocaleParam() {
		const subscription = this.route.queryParams.subscribe(async (params: Params) => {
			if (params.locale) {
				await this.languageService.setLocale(params.locale);
				this.loaded = true;
			}
		});
		setTimeout(() => {
			this.loaded = true;
			subscription.unsubscribe();
		}, 250);
	}

	private registerObservables() {
		this.showScreenOverlay$ = this.uiService.showScreenOverlay$;
	}

	private setupAppUrlListener() {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.ngZone.run(async () => {
				const slug = event.url.includes('.com') ? event.url.split('.com').pop() : event.url.split('.co').pop();
				try {
					await this.router.navigateByUrl(slug);
				} catch (_) {
					this.router.navigateByUrl('/');
				}
			});
		});
	}

	private registerMobileAppStatus() {
		App.addListener('appStateChange', ({ isActive }) => {
			this.mobileAppService.updateMobileAppStatus = isActive;
		});
	}

	private setScreenSize() {
		this.uiService.updateAppViewMode(window);
	}

	ngOnDestroy(): void {
		this.routingSubscription.unsubscribe();
	}
}
