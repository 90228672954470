<div class="gipitpot-intent coverage" *ngIf="message && message.entity" data-test-id="gipitpot-coverage-intent">
	<div *ngIf="coverageLoading; else coverageDone">
		<ripple-loader [alignTo]="LoaderAlign.Left" text="finding you service for: {{message.entity}}"></ripple-loader>
	</div>

	<ng-template #coverageDone>
		<div *ngIf="services && services.length > 0; else noServices">
			<p class="msg">I found the following services for you:</p>

			<div class="coverage-services-container">
				<a *ngFor="let service of services">
					<ripple-label [text]="service.name" [colorTheme]="LabelColorTheme.darkTextGreenBg"></ripple-label>
				</a>
			</div>
		</div>

		<ng-template #noServices>
			<p class="msg">{{message.text}}</p>
		</ng-template>
	</ng-template>
</div>
