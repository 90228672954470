import { Benefit } from '../../../models/benefits.model';

//interfaces
export interface CardFrontData {
	name: string;
	deductible: Deductible;
	dependents: [{ firstName: string; lastName: string; dateOfBirth: string }];
	memberId: string;
	outOfPocketMax: OutOfPocketMax;
	effectiveDate: Date;
	benefits: BenefitWithCost[];
	planName: string;
	upperLeftLogo: string;
	upperRightLogo: string;
}

export interface Deductible {
	individual: {
		inNetwork: { text: string };
		outNetwork: { text: string };
	};
	family: {
		inNetwork: { text: string };
		outNetwork: { text: string };
	};
}

export interface OutOfPocketMax {
	individual: {
		inNetwork: string;
		outNetwork: string;
	};
	family: {
		inNetwork: string;
		outNetwork: string;
	};
}

export interface CardBackData {
	type: number | string;
	logo: string;
	content: content[] | contentSecondry[];
	headline?: string;
	rightContent?: content;
	middleHeadline?: string;
	contentSecondry?: contentSecondry[];
}

interface contentSecondry {
	left: content;
	right: content;
}
interface content {
	value: string;
	type: NodeType;
}

export interface BenefitWithCost extends Benefit {
	cost: number[];
}

//enums
export enum TemplateType {
	REGULAR = 1,
	LIST = 2,
	MIDDLE_HEADLINE = 3,
	MIX = 4,
}

export enum NodeType {
	TEXT = 1,
	LINK = 2,
	PHONE = 3,
}

export enum ContentNodeType {
	REGULAR = 1,
	PAIR = 2,
}

export enum networkNames {
	RBP = 'RBP',
	OTHER = 'OTHER',
	FRONT_PATH = 'frontpath',
}

export const ServicesIds = {
	PCP: '5d9c3af263507c70ef68860f',
	SPU: '5d9c3af763507c70ef688644',
	URG: '60e3bd9e54823252fe1b8f55',
	EMERG: '5d9c3af263507c70ef68861b',
};

export const RxServicesIds = {
	GENRx: '657eb658a2b052f8340343a3',
	PREFRx: '657eb871cabc23ff69d8249f',
	NONPRx: '657eb8b4cabc23ff69d824db',
	SPECRx: '657eb8ebcabc23ff69d82504',
};
