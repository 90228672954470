import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import { TranslationService } from '@transifex/angular';

import CFG from '../config/app-config.json';

import { StorageService } from './storage.service';

export const LANGUAGE_NAME_MAP = {
	en: 'English',
	es: 'Español',
};

export interface AppLanguage {
	locale: string;
	displayName: string;
}

const DEFAULT_LOCALE = 'en';
const DEFAULT_APP_LANG: AppLanguage = {
	locale: DEFAULT_LOCALE,
	displayName: LANGUAGE_NAME_MAP[DEFAULT_LOCALE],
};
const DEFAULT_SUPPORTED_LANGS: AppLanguage[] = [DEFAULT_APP_LANG];

@Injectable({ providedIn: 'root' })
export class LanguageService {
	private _showLoginLanguagePicker$ = new BehaviorSubject<boolean>(false);
	private _appLanguage$ = new BehaviorSubject<AppLanguage>(DEFAULT_APP_LANG);
	private _supportedLanguages$ = new BehaviorSubject<AppLanguage[]>(DEFAULT_SUPPORTED_LANGS);

	public get showLoginLanguagePicker$() {
		return this._showLoginLanguagePicker$.asObservable();
	}
	public get appLanguage$() {
		return this._appLanguage$.asObservable();
	}
	public get supportedLanguages$() {
		return this._supportedLanguages$.asObservable();
	}
	public currentLanguage: string = DEFAULT_LOCALE;

	public isSpanish$ = this.appLanguage$.pipe(map((language) => language.locale === 'es'));

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private translationService: TranslationService
	) {
		this.translationService.init({
			token: CFG.transifex.token,
		});
		const localeFromLocalStorage = this.storageService.getLocaleSettings() || DEFAULT_LOCALE;
		this.setLocale(localeFromLocalStorage);
	}

	public translate(str: string, params: Record<string, unknown>, instanceAlias?: string) {
		return this.translationService.translate(str, params, instanceAlias);
	}

	public async setLocale(locale: string) {
		locale = locale || 'en';
		if (LANGUAGE_NAME_MAP[locale]) {
			const language: AppLanguage = LanguageService.getAppLanguageFromLocale(locale);
			await this.translationService.setCurrentLocale(locale);
			this.storageService.saveLocaleSettings(locale);
			this.currentLanguage = locale;
			this._showLoginLanguagePicker$.next(true);
			this._appLanguage$.next(language);
		}
	}

	public updateUserLocale(locale: string) {
		const newLanguageData = { preferredLanguage: locale };
		this.http.post(CFG.apiEndpoints.userProfileData, newLanguageData).subscribe({
			next: () => {
				this.storageService.saveLocaleSettings(locale);
				window.location.reload();
			},
		});
	}

	public setSupportedLanguages(localesArray: string[]) {
		const languagesArray: AppLanguage[] = localesArray.map((locale: string) =>
			LanguageService.getAppLanguageFromLocale(locale)
		);
		this._supportedLanguages$.next(languagesArray);
	}

	public static getAppLanguageFromLocale(locale: string): AppLanguage {
		return { locale, displayName: LANGUAGE_NAME_MAP[locale] };
	}
}
