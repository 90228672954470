<img [matMenuTriggerFor]="menu" class="icon-size-24 cursor-pointer" src="/assets/icons/icon-settings.svg" alt="" />

<mat-menu #menu class="max-width-none">
	<button
		[class.cursor-not-allowed]="option.disabled"
		(click)="option.func()"
		[disabled]="option.disabled"
		*ngFor="let option of userDropDownMenu | filterArrayByPropValue : 'hidden' : false || undefined"
		mat-menu-item
	>
		<div class="d-flex align-items-center justify-content-space-between">
			<div class="d-flex align-items-center w-100">
				<img
					[class.icon-size-40]="option?.name !== 'logout'"
					[class.icon-size-24]="option?.name === 'logout'"
					[class.ml-16]="option?.name === 'logout'"
					class="mr-16"
					[src]="option.icon"
					alt="option icon"
				/>
				<p class="text-dark-blue flex-grow-1 mr-16 mb-0">{{ option.label }}</p>
				<mat-icon *ngIf="option?.name === 'verifyPhone' && !!phoneNumber" class="text-success icon-size-24 mr-0"
					>check</mat-icon
				>
				<div *ngIf="option?.name === 'lang'" class="text-azure-radiance">
					{{ (languageService.appLanguage$ | async)?.locale | uppercase }}
				</div>
			</div>
		</div>
	</button>
</mat-menu>
