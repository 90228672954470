import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleModule } from 'ripple';

import { FeedbackEmotionsComponent } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.component';

@NgModule({
	declarations: [FeedbackEmotionsComponent],
	imports: [CommonModule, RippleModule],
	exports: [FeedbackEmotionsComponent],
})
export class FeedbackEmotionsModule {}
