import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-add-to-favorites-card',
	templateUrl: './add-to-favorites-card.component.html',
	styleUrls: ['./add-to-favorites-card.component.scss'],
})
export class AddToFavoritesCardComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
