<div class="talon-cover-container" *ngIf="showCover">
	<h1><UT str="Medical Spending" key="medical_spending"></UT></h1>
	<img
		src="../../../../../../../assets//images/health-profile/talon-widget-cover-img.svg"
		alt="talon cover img"
		class="talon-cover-img"
	/>
	<p class="talon-cover-txt-third">
		{{
			'Connect your insurance account to see how much more you’ll pay before the rest of your coverage kicks in.'
				| translate
		}}
	</p>
	<button class="bt-regular" (click)="showCover = false">{{ 'Add Your Account' | translate }}</button>
</div>
