import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { Observable, of } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { catchError, switchMap } from 'rxjs/operators';
import { ICompany } from '../../../models/company.model';
import { CompanyApiService } from '../../api/company-api/company-api.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyStoreService extends Store<void, ICompany> {
	readonly CHARTER_SCHOOL_SLUG = 'charter school'

	constructor(private companyApiService: CompanyApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<ICompany>> {
		return this.companyApiService.getCompany().pipe(catchError(() => of(null)));
	}

	public isCharterSchool(): Observable<boolean> {
		return this.get().pipe(
			switchMap((company: ICompany) => {
				if (company) {
					const isCharterSchool =
						company._id === '65085c54dca4726c35446353' ||
						company.name.toLowerCase() === this.CHARTER_SCHOOL_SLUG;
					return of(isCharterSchool);
				}
				return of(false);
		})
	);
	}
}
