<ul *ngIf="(service?.pctNotes?.[networkType] || {}) as pctNote" class="service-bullet-points variant-{{ variant }}">
	<li *ngIf="service?.frequency">
		<span class="bullet"></span>
		{{
			'Available every {frequency} {period}' | translate: {
				frequency: service.frequency.frequency,
				period: service.frequency.period | translate,
			}
		}}
	</li>

	<li *ngIf="isSubjectToDeductible()">
		<span class="bullet"></span>
		{{ 'Applies only after deductible is met.' | translate }}
	</li>

	<li *ngIf="!isNil(pctNote.ageLimitation?.max)">
		<span class="bullet"></span>
		{{
			'Your plan will cover up to age {age}' | translate: {
				age: pctNote.ageLimitation.max
			}
		}}
	</li>

	<li *ngIf="!isNil(pctNote.ageLimitation?.min)">
		<span class="bullet"></span>
		{{
			'Your plan will cover up to age {age} and older' | translate: {
				age: pctNote.ageLimitation.min
			}
		}}
	</li>

	<li *ngIf="pctNote.reimbursement">
		<span class="bullet"></span>
		{{
			'Your plan will reimburse you up to {reimbursement}' | translate: {
				reimbursement: pctNote.reimbursement
			}
		}}
	</li>

	<li *ngIf="hasValidCountLimitation(pctNote)">
		<span class="bullet"></span>
		{{
			'This is limited to {serviceCount} per {timeUnitCount} {timeUnit}' | translate: {
				serviceCount: pctNote.countLimitation.serviceCount,
				timeUnitCount: pctNote.countLimitation.timeUnitCount,
				timeUnit: pctNote.countLimitation.timeUnit | translate,
			}
		}}
	</li>

	<ng-container *ngFor="let allowance of pctNote.allowance">
		<li>
			<span class="bullet"></span>
			{{ allowance }}
		</li>
	</ng-container>
</ul>
