import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalSpendingComponent } from './medical-spending/medical-spending.component';
import { ConnectDeductiblesFormHandlerModule } from '../connect-deductibles-form-handler/connect-deductibles-form-handler.module';
import { TxNativeModule } from '@transifex/angular';
import { AppSharedModule } from '../../app-shared/app-shared.module';
import { DeductiblesCardModule } from '../deductibles-card/deductibles-card.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MedicalSpendingConnectedDeductibleCardComponent } from './medical-spending-connected-deductible-card/medical-spending-connected-deductible-card.component';

@NgModule({
	declarations: [MedicalSpendingComponent, MedicalSpendingConnectedDeductibleCardComponent],
	imports: [
		CommonModule,
		ConnectDeductiblesFormHandlerModule,
		TxNativeModule,
		AppSharedModule,
		DeductiblesCardModule,
		MatIconModule,
		MatTabsModule,
	],
	exports: [MedicalSpendingComponent, MedicalSpendingConnectedDeductibleCardComponent],
})
export class MedicalSpendingModule {}
