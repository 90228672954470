<app-digital-card [contract]="contract" disabled>
	<div content class="card-content">
		<div class="skeleton-row mb-s">
			<div class="skeleton tall w-9"></div>
			<div class="skeleton tall w-4"></div>
		</div>
		<div class="skeleton-row mb-l">
			<div class="skeleton tall w-6"></div>
			<div class="skeleton tall w-5"></div>
		</div>
		<div class="skeleton short full-width mb-s"></div>
		<div class="skeleton short full-width mb-l"></div>
		<div class="skeleton line full-width mb-m"></div>
		<button class="bt secondary-blue" (click)="unlockClick.emit(contract.planType)">
			<img src="{{ assetsPath }}/lock-open.svg" alt="open lock icon" />
			{{ buttonText | translate }}
		</button>
	</div>
</app-digital-card>
