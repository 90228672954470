<div class="plan-selection-container" *ngIf="!shrink">
	<a
		[routerLink]="[planSelectionPath]"
		class="mx-28 background-mpi-orange font-size-15 border-border-color-light-blue px-8 py-7 border-60 plan-selection text-white"
		(click)="onClick()"
	>
		<div class="d-flex align-items-center mat-typography font-size-15">
			<img class="icon-size-42 mr-10" src="/assets/icons/icon-plan-selection-button-alt.svg" />
			{{ 'Plan selection tool' | translate }}
		</div>
		<mat-icon class="text-white">chevron_right</mat-icon>
	</a>
</div>

<a
	*ngIf="shrink"
	class="d-flex justify-content-center pt-13 pb-40"
	(click)="onClick()"
	[routerLink]="[planSelectionPath]"
>
	<img class="icon-size-42" src="/assets/icons/icon-plan-selection-button-alt.svg" />
</a>
