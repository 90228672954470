import { Component, Input } from "@angular/core";
import { BaseGipitpotMessage } from "../../../models/gipitpot/gipitpot-messages";

@Component({
	selector: "app-gipitpot-user-message",
	templateUrl: "./gipitpot-user-message.component.html",
	styleUrls: ["./gipitpot-user-message.component.scss"]
})
export class GipitpotUserMessageComponent {

	@Input() message: BaseGipitpotMessage;
}
