import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { Observable, combineLatest, throwError, switchMap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { RxjsUtils } from '../utils/rxjs';
import { UserData } from '../models/user-data';
import { PlanData } from '../models/plan-data.model';
import { UserService } from './user.service';
import { UserPlanDataStoreService } from './stores/user-plan-data-store/user-plan-data-store.service';
import { isNil } from '../utils/is/is-nil';
import CFG from '../config/app-config.json';
import { Feedback } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.component';
import { isProduction } from '../utils/utils';
import { captureExceptionSentry } from '../utils/sentry.utils';
import { Rating } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.component';

const TAG = 'ExternalApiService';

const MONDAY_HOOK_API_PREFIX = CFG.externalApi.MONDAY_HOOK_API_PREFIX;
const MONDAY_API_VERSION = CFG.externalApi.MONDAY_API_VERSION;
const CX_MONDAY_BOARD_ID_DEV = CFG.externalApi.CX_MONDAY_BOARD_ID_DEV;
const CX_MONDAY_BOARD_ID_PROD = CFG.externalApi.CX_MONDAY_BOARD_ID_PROD;
const FEEDBACK_MONDAY_BOARD_ID_DEV = CFG.externalApi.FEEDBACK_MONDAY_BOARD_ID_DEV;
const FEEDBACK_MONDAY_BOARD_ID_PROD = CFG.externalApi.FEEDBACK_MONDAY_BOARD_ID_PROD;

const FEEDBACK_MONDAY_BOARD_ID = isProduction() ? FEEDBACK_MONDAY_BOARD_ID_PROD : FEEDBACK_MONDAY_BOARD_ID_DEV;
const MONDAY_NOT_FOUND_SEARCH_BOARD_ID = isProduction() ? CX_MONDAY_BOARD_ID_PROD : CX_MONDAY_BOARD_ID_DEV;
const PCT_MISSING_PROVIDERS_MONDAY_BOARD_ID = isProduction() ? CFG.externalApi.PCT_MISSING_PROVIDERS_MONDAY_BOARD_ID_PROD : CFG.externalApi.PCT_MISSING_PROVIDERS_MONDAY_BOARD_ID_DEV;
const HEALTHEE_COMPANY_NAME = CFG.externalApi.HEALTHEE_COMPANY_NAME;
const EXCLUDED_DOMAINS = [CFG.externalApi.HEALTHEE_DOMAIN, CFG.externalApi.INSURIGHTS_DOMAIN];

export enum MondayTaskType {
	ProvidersSearch = 'Providers Search',
	PCTProvidersSearch = 'PCT Providers Search',
	CoverageInfoSearch = 'Coverage Info Search',
	Feedback = 'Feedback',
}

const BOARD_ID_BY_TASK_TYPE = {
	[MondayTaskType.ProvidersSearch]: MONDAY_NOT_FOUND_SEARCH_BOARD_ID,
	[MondayTaskType.PCTProvidersSearch]: PCT_MISSING_PROVIDERS_MONDAY_BOARD_ID,
	[MondayTaskType.CoverageInfoSearch]: MONDAY_NOT_FOUND_SEARCH_BOARD_ID,
	[MondayTaskType.Feedback]: FEEDBACK_MONDAY_BOARD_ID,
};

@Injectable({
	providedIn: 'root',
})
export class ExternalApiService {
	constructor(
		private http: HttpClient,
		private userService: UserService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {}

	private extractContractNames(planData: PlanData): any[] {
		const userContractsNames = [];
		if (!isNil(planData.contract)) {
			userContractsNames.push(`Medical - ${planData.contract.name}`);
		}
		if (!isNil(planData.dental)) {
			userContractsNames.push(`Dental - ${planData.dental.name}`);
		}
		if (!isNil(planData.vision)) {
			userContractsNames.push(`Vision - ${planData.vision.name}`);
		}
		return userContractsNames;
	}

	private isInternalUser(user) {
		return EXCLUDED_DOMAINS.some((domain) => user.email.includes(domain)) && user.company !== HEALTHEE_COMPANY_NAME;
	}

	private getUserType(user) {
		if (this.isInternalUser(user)) {
			return 'internal';
		}
		return 'external';
	}

	private getMondayReqObjForNotFoundSearch(
		dataObj: { userData: UserData; planData?: PlanData; searchText: string },
		fType: string,
		customTitle: string | null = null
	): { query: string; columnObj: object } {
		const { userData, planData, searchText } = dataObj;
		const userContractsNames = planData ? this.extractContractNames(planData) : {};
		const splitDateTime = new Date().toISOString().split('T');
		const date = splitDateTime[0];
		const time = splitDateTime[1].split('.')[0];
		const boardId = BOARD_ID_BY_TASK_TYPE[fType];

		return {
			columnObj: {
				myItemName: `${customTitle ?? fType} | ${searchText}`,
				boardId,
				column_values: JSON.stringify({
					date4: { date, time }, // Full Date with time
					text9: userData?.uid, // UID
					text6: userData?.email, // User Email
					text5: searchText, // Question/Input-text
					text2: userData?.company?.name, // company
					text4: JSON.stringify(userContractsNames), // Plans
					feature_name: fType, // Feature name
					env0: environment.envName, // Env
					user_type9: this.getUserType({ email: userData?.email, company: userData?.company?.name }), // User type
				}),
			},
			query: `mutation ($myItemName: String!, $boardId: Int!, $column_values: JSON!) { create_item (board_id: $boardId, item_name: $myItemName, column_values: $column_values) { id } }`,
		};
	}

	private sendToMondayAPI(mondayObj: { query: string; columnObj: object }) {
		try {
			const url = MONDAY_HOOK_API_PREFIX + MONDAY_API_VERSION;
			const body = JSON.stringify({
				query: mondayObj.query,
				variables: JSON.stringify(mondayObj.columnObj),
			});
			const headers = new HttpHeaders()
				.set('Content-Type', 'application/json')
				.set('Authorization', CFG.mondayToken);
			this.http.post(url, body, { headers }).subscribe({
				error: (error) => {
					Sentry.withScope((scope) => {
						scope.setLevel('info');
						captureExceptionSentry(error, TAG);
					});
				},
			});
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('info');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	private sendToMondayAPI$(mondayObj: { query: string; columnObj: object }): Observable<unknown> {
		const url = MONDAY_HOOK_API_PREFIX + MONDAY_API_VERSION;
		const body = JSON.stringify({
			query: mondayObj.query,
			variables: JSON.stringify(mondayObj.columnObj),
		});
		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', CFG.mondayToken);

		return this.http.post(url, body, { headers }).pipe(catchError((error) => {
			Sentry.withScope((scope) => {
				scope.setLevel('info');
				captureExceptionSentry(error, TAG);
			});
			return throwError(error);
		}));
	}

	public handleExternalApiForNotFoundSearch(
		searchText: string,
		type: MondayTaskType,
		customTitle: string | null = null
	) {
		combineLatest([
			this.userService.userData$.pipe(take(1)),
			this.userPlanDataStoreService.get().pipe(RxjsUtils.isNotNil()),
		])
			.pipe(take(1))
			.subscribe({
				next: ([userData, planData]: [UserData, PlanData]) => {
					if (type === MondayTaskType.CoverageInfoSearch) {
						this.sendToMondayAPI(
							this.getMondayReqObjForNotFoundSearch(
								{ userData, planData, searchText },
								MondayTaskType.CoverageInfoSearch
							)
						);
					}
					if (type === MondayTaskType.ProvidersSearch) {
						this.sendToMondayAPI(
							this.getMondayReqObjForNotFoundSearch({ userData, planData, searchText }, type, customTitle)
						);
					}
				},
			});
	}

	public handleExternalApiForNotFoundSearch$(searchText: string, type: MondayTaskType, customTitle?: string) {
		return combineLatest([
			this.userService.userData$.pipe(take(1)),
			this.userPlanDataStoreService.get().pipe(RxjsUtils.isNotNil()),
		]).pipe(
			take(1),
			switchMap( ([userData, planData]) => {
				const data = { userData, planData, searchText };
				const mondayRequest = this.getMondayReqObjForNotFoundSearch(data, type, customTitle);

				return this.sendToMondayAPI$(mondayRequest);
			}));
	}

	public reportMissingPCTProvider(searchText: string) {
		return this.userService.userData$.pipe(take(1)).pipe(
			switchMap((userData) => {
				const data = { userData, planData: null, searchText };
				const mondayRequest = this.getMondayReqObjForNotFoundSearch(data, MondayTaskType.PCTProvidersSearch);

				return this.sendToMondayAPI$(mondayRequest);
			})
		);
	}

	private getFeedbackMondayReqObject(userData: UserData, feedback: Feedback): { query: string; columnObj: object } {
		const ratingsToMondayTag = {
			[Rating.VerySad]: 'Very Bad',
			[Rating.Sad]: 'Bad',
			[Rating.Nuetral]: 'Average',
			[Rating.Happy]: 'Good',
			[Rating.VeryHappy]: 'Very Good',
		};

		const textRating = ratingsToMondayTag[feedback.rating];
		const splitDateTime = new Date().toISOString().split('T');
		const date = splitDateTime[0];
		return {
			columnObj: {
				myItemName: `${userData.email}`,
				boardId: FEEDBACK_MONDAY_BOARD_ID,
				column_values: JSON.stringify({
					rating3: textRating, // Feature rating
					text43: feedback.feature, // Feature name
					text42: JSON.stringify(feedback.data), // Meta data
					text8: feedback.description, // User email
					text9: userData?.company?.name, // User company
					date_1: date, //  date
				}),
			},
			query: `mutation ($myItemName: String!, $boardId: Int!, $column_values: JSON!) { create_item (board_id: $boardId, item_name: $myItemName, column_values: $column_values) { id } }`,
		};
	}

	public sendFeedbackToMonday(feedback: Feedback) {
		this.userService.userData$.pipe(take(1)).subscribe((userData) => {
			this.sendToMondayAPI(this.getFeedbackMondayReqObject(userData, feedback));
		});
	}

	public isUserSupportedByTalon(): Observable<boolean> {
		return this.userPlanDataStoreService.get().pipe(
			take(1),
			map((planData) => {
				return planData?.contract?.isTalonSupported;
			})
		);
	}
}
