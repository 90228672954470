<div class="referrals-teaser-container">
	<div class="referrals-teaser-wrapper" *ngIf="(isLoading$ | async) === false && (referralsInfo$ | async).length > 0">
		<app-referrals-card
			[item]="item"
			*ngFor="let item of referralsInfo$ | async"
			[isTeaserCard]="true"
		></app-referrals-card>
	</div>

	<div class="loader" *ngIf="isLoading$ | async">
		<app-loader [size]="'medium'"></app-loader>
	</div>

	<app-referrals-no-results
		*ngIf="(isLoading$ | async) === false && (referralsInfo$ | async).length === 0"
	></app-referrals-no-results>
</div>
