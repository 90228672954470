import { Pipe, PipeTransform } from '@angular/core';
import { isObjectEmpty } from '../utils/utils';

@Pipe({
	name: 'isShowImportantToKnow',
	pure: true,
})
export class IsShowImportantToKnow implements PipeTransform {
	transform(newBenefit: any, activeNetworkTabIndex: number): boolean {
		return true;
		//MPI added disclaimer to be always shown; Leaving below code here, if they change their mind
		// return (
		// 	!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].reimbursement) ||
		// 	newBenefit.gender ||
		// 	!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].ageRestriction) ||
		// 	!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].countLimitations) ||
		// 	newBenefit.networks[activeNetworkTabIndex].preExistConditions?.length ||
		// 	newBenefit.networks[activeNetworkTabIndex].notes?.length
		// );
	}
}
