import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs';
import { PremiumFeature } from '../../models/premium-feature.model';
import { PremiumFeatureService } from '../../services/premium-feature.service';

@Directive({
	selector: '[premiumFeature]',
})
export class PremiumFeatureDirective implements OnInit {
	@Input() premiumFeature: PremiumFeature;

	constructor(
		private tpl: TemplateRef<any>,
		private vcr: ViewContainerRef,
		private premiumFeatureService: PremiumFeatureService
	) {}

	ngOnInit() {
		this.featureFlagSubscription();
	}

	private featureFlagSubscription(): void {
		this.premiumFeatureService
			.isEnabled(this.premiumFeature)
			.pipe(take(1))
			.subscribe((isEnabled) => {
				isEnabled ? this.vcr.createEmbeddedView(this.tpl) : this.vcr.clear();
			});
	}
}
