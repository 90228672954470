import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanData } from 'src/app/models/plan-data.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
	public planData$: Observable<PlanData>;

	constructor(private userPlanDataStoreService: UserPlanDataStoreService) {}

	ngOnInit(): void {
		this.planData$ = this.userPlanDataStoreService.get();
	}
}
