import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

const ANIMATION_DURATION_MS = 200;
@Component({
	selector: 'app-tooltip-bottom-sheet',
	templateUrl: './tooltip-bottom-sheet.component.html',
	styleUrls: ['./tooltip-bottom-sheet.component.scss'],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateY(100%)' }),
				animate(ANIMATION_DURATION_MS, style({ transform: 'translateY(0%)' })),
			]),
			transition(':leave', [animate(ANIMATION_DURATION_MS, style({ transform: 'translateY(100%)' }))]),
		]),
	],
})
export class TooltipBottomSheetComponent implements OnInit {
	@Input() tooltip: TemplateRef<any>;
	@Input() title: string;
	@Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	public content = {
		title: '',
	};

	constructor() {}

	ngOnInit(): void {
		this.content.title = this.title;
	}

	public onDismiss() {
		this.closeClicked.emit();
	}
}
