import { Injectable } from '@angular/core';

import { map, Observable, shareReplay, combineLatest } from 'rxjs';

import { Maybe } from '../../../utils/types/maybe';
import { Store } from '../../../utils/store/store';
import { ContractServicesApiService } from '../../api/contract-services-api/contract-services-api.service';
import { isNil } from '../../../utils/is/is-nil';

@Injectable({
	providedIn: 'root',
})
export class ContractServicesStoreService extends Store<void, { mainCategories: any[]; subCategories: any[] }> {
	constructor(private contractServicesApi: ContractServicesApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<{ mainCategories: any[]; subCategories: any[] }>> {
		return this.contractServicesApi.servicesCategories();
	}

	subCategoriesById(): Observable<Map<string, any>> {
		return this.get().pipe(
			map((data) => {
				const res = new Map<string, any>();
				if (isNil(data)) {
					return res;
				}
				data.subCategories.forEach((d) => res.set(d._id, d));
				return res;
			}),
			shareReplay(1)
		);
	}

	mainCategoryServices(): Observable<Map<string, Set<string>>> {
		return combineLatest([this.get(), this.subCategoriesById()]).pipe(
			map(([data, subCategoriesById]) => {
				const res = new Map<string, Set<string>>();
				if (isNil(data)) {
					return res;
				}
				data.mainCategories.forEach((mc) => {
					res.set(mc._id, new Set<string>());
					mc.subCategories.forEach((subCategoryId) =>
						subCategoriesById
							.get(subCategoryId)
							?.services.forEach((serviceId) => res.get(mc._id).add(serviceId))
					);
				});
				return res;
			}),
			shareReplay(1)
		);
	}
}
