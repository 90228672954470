<div class="immediate-care-container">
	<button (click)="onClickBack()" class="back-btn" type="button" mat-icon-button>
		<mat-icon class="search">keyboard_backspace</mat-icon>
	</button>
	<div class="box-wrapper">
		<div class="immediate-care-wrapper">
			<app-immediate-care-card
				*ngFor="let benefit of benefitArr"
				[immediateCareObj]="benefit"
			></app-immediate-care-card>
		</div>
	</div>
</div>
