import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { T } from '@transifex/angular';
import { CardFrontData, RxServicesIds, ServicesIds } from '../../../../services/digital-card/templates/shared';
import { getBenefitCostValue } from '../../../../utils/utils';
import { blackhawkNetworkNames } from 'src/app/services/digital-card/templates/blackhawk/blackhawk';

export interface CardFrontDataCustom extends CardFrontData {
	networkName: blackhawkNetworkNames;
}

interface listData {
	property: string;
	displayName: string;
	value: any;
}
@Component({
	selector: 'app-custom-digital-card-front',
	templateUrl: './custom-digital-card-front.component.html',
	styleUrls: ['./custom-digital-card-front.component.scss'],
})
export class CustomDigitalCardFrontComponent implements OnChanges {
	@Input() content: CardFrontDataCustom;
	@Input() companyName: string;
	@Input() groupNumber: string;

	userInfo: listData[];
	benefits: listData[];
	insuranceInfo: listData[];
	employer: string;
	groupNo: string;

	@T('Name')
	name: string;

	@T('Member ID')
	MemberID: string;

	@T('Effective')
	effective: string;

	@T('Dependent(s)')
	dependents: string;

	@T('Deductible')
	deductible: string;

	@T('Call')
	call: string;

	BenefitTypeMap = new Map<string, string>([
		[ServicesIds.PCP, 'PCP'],
		[ServicesIds.SPU, 'SPU'],
		[ServicesIds.URG, 'URG'],
		[ServicesIds.EMERG, 'EMERG'],
		[RxServicesIds.GENRx, 'GENRx'],
		[RxServicesIds.PREFRx, 'PREFRx'],
		[RxServicesIds.NONPRx, 'NON-PRx'],
		[RxServicesIds.SPECRx, 'SPECRx'],
	]);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.content?.currentValue) {
			this.createTemplateData(this.content);
		}
		if (changes?.companyName?.currentValue) {
			this.employer = this.capitalizeFirstLetter(this.companyName);
		}
		if ('groupNumber' in changes) {
			this.groupNo = this.groupNumber ? this.groupNumber : 'N/A';
		}
	}

	createTemplateData(data: CardFrontDataCustom) {
		this.userInfo = [
			{ property: 'name', displayName: this.name, value: data.name },
			{ property: 'memberId', displayName: this.MemberID, value: data.memberId },
			{ property: 'effectiveDate', displayName: this.effective, value: data.effectiveDate },
			{
				property: 'dependants',
				displayName: this.dependents,
				value: data.dependents?.map((d) => `${d.firstName} ${d.lastName}`),
			},
		];

		this.insuranceInfo = [
			{
				property: 'deductible',
				displayName: 'Deductible',
				value: data.deductible?.individual?.inNetwork?.text,
			},
			{
				property: 'outOfPocketMax',
				displayName: 'OOP Max',
				value: data.outOfPocketMax?.individual?.inNetwork,
			},
		];

		this.benefits = data.benefits
			.map((b) => {
				return {
					property: b.title,
					displayName: this.BenefitTypeMap.get(b.serviceId),
					value: getBenefitCostValue(b),
				};
			})
			.filter((b) => b.value);
	}

	isArray(item: any) {
		return Array.isArray(item);
	}

	capitalizeFirstLetter(inputString: string): string {
		if (inputString.length === 0) {
			return inputString;
		}
		return inputString.charAt(0).toUpperCase() + inputString.slice(1);
	}
}
