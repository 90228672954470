import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { UserData } from '../models/user-data';

import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class OauthTokenGuard  {
	constructor(private router: Router, private userService: UserService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.storeUserDataFromToken();
	}

	private storeUserDataFromToken(): Observable<boolean> {
		const params = this.router.getCurrentNavigation().extractedUrl.queryParams;

		if (!params.access_token || !params.vendor) return of(true);
		return this.userService.storeUserDataFromExternalToken(params.access_token, params.vendor).pipe(
			take(1),
			switchMap((user: UserData) => {
				delete this.router.getCurrentNavigation().extractedUrl.queryParams.access_token;
				delete this.router.getCurrentNavigation().extractedUrl.queryParams.vendor;
				return of(true);
			}),
			catchError((e) => {
				return of(null);
			})
		);
	}
}
