import { Component, OnInit } from '@angular/core';
import { T } from '@transifex/angular';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConversationService } from 'src/app/services/conversation.service';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';

const LENGTH_OF_A_LETTER = 1;

@Component({
	selector: 'app-chat-dialog',
	templateUrl: './chat-dialog.component.html',
	styleUrls: ['./chat-dialog.component.scss'],
})
export class ChatDialogComponent extends HealtheeDialogContent implements OnInit {
	userFilterText: string = null;
	autoCompleteState: boolean = true;

	allEntitiesNames$: Observable<string[]>;

	@T('Start typing a medical condition...')
	placeHolder: string;

	constructor(private conversationService: ConversationService) {
		super();
	}

	ngOnInit(): void {
		// TODO: Implement rxjs retry strategy
		this.allEntitiesNames$ = this.conversationService.allEntitiesNames$.pipe(
			filter((data) => !!data), // wait until not null
			map((names) => this.sortByNameAscending(names)),
			map((sortedNames) => this.addLetterHeaders(sortedNames))
		);
	}

	private sortByNameAscending(arrayOfStrings: string[]): string[] {
		return arrayOfStrings.sort(this.ascendingTwoElementsSort);
	}

	private ascendingTwoElementsSort(a, b) {
		return a.toLowerCase().localeCompare(b.toLowerCase());
	}

	private addLetterHeaders(entityArr: Array<string>): string[] {
		let lastLetterHead: string = '';

		for (let i = 0; i < entityArr.length; i++) {
			if (entityArr[i]) {
				const entity = entityArr[i];
				const firstLetter: string = entityArr[i][0];
				if (firstLetter.toLowerCase() === entity) return entityArr;
				if (firstLetter.toLowerCase() !== lastLetterHead.toLowerCase()) {
					lastLetterHead = firstLetter;
					entityArr.splice(i, 0, firstLetter);
				}
			}
		}
		return entityArr;
	}

	isLetter(phrase: string): boolean {
		if (!phrase) return true;
		return phrase.length === LENGTH_OF_A_LETTER;
	}

	closeDialogAndSendData(entityName: string): void {
		if (this.isLetter(entityName)) return;

		this.confirmDialog.emit({ entity: entityName });
	}
}
