import { Component } from '@angular/core';

@Component({
	selector: 'app-home-page-teaser-header',
	templateUrl: './home-page-teaser-header.component.html',
	styleUrls: ['./home-page-teaser-header.component.scss'],
})
export class HomePageTeaserHeaderComponent {
	constructor() {}
}
