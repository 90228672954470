<div class="twofa">
	<form [formGroup]="twofaForm" (change)="on2FACodeChanged()" class="twofa-form" (ngSubmit)="on2FACodeSubmit()">
		<input formControlName="code" maxlength="6" min="0" max="9" type="tel" onfocus="this.select()" #codeRef />
		<div>
			<div>
				<div class="resend-code-container">
					<p>{{ 'Did not receive it?' | translate }}</p>
					<a
						[class.resend-link-disabled]="disableCodeResend$ | async"
						class="resend"
						(click)="request2FACodeAgain()"
						>{{ 'Resend' | translate }}</a
					>
					<p *ngIf="counter$ | async">
						<T str="in {count} seconds" [vars]="{ count: counter$ | async }"></T>
					</p>
				</div>				
				<div *ngIf="isSendCodeByEmailVisible()" class="resend-email-code-container">
					<a 
						class="resend"
						(click)="request2FACodeWithEmail()"
						>{{ 'Click here' | translate }}</a
					>
					<p> to receive the code via email</p>
				</div>
			</div>
			<div *ngIf="showCodeResentMessage" class="code-sent">
				<img src="../../../../../assets/images/icons/green_V.svg" />
				<p>{{ 'The code was sent successfully.' | translate }}</p>
			</div>
		</div>
		<button type="submit">{{ 'Submit' | translate }}</button>
	</form>
</div>
