import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { LabelColorTheme } from 'ripple';
import { getServiceWithPlanTypeOrEmployerSponsored } from 'src/app/utils/mpi/mpi-service-naming.helper';
import { PlanType } from 'src/app/models/plan-type.model';

@Component({
	selector: 'app-service-panel-header',
	templateUrl: './service-panel-header.component.html',
	styleUrls: ['./service-panel-header.component.scss'],
})
export class ServicePanelHeaderComponent extends CommonComponent {
	@Input()
	shrinked: boolean;

	@Input()
	bgImage: string;

	@Input()
	title: string;

	@Input()
	covered: boolean;

	@Input()
	carrier: string;

	@Input()
	planType: string;

	@Input()
	isESP: boolean = false;

	@Output()
	closeClick = new EventEmitter<void>();

	constructor() {
		super();
	}

	protected readonly LabelColorTheme = LabelColorTheme;

	public getCarrierTitle(name: string, planType: string): string {
		const type = planType.toLowerCase() === 'employer sponsored' ? 'Employer Sponsored' : (planType as PlanType);
		return getServiceWithPlanTypeOrEmployerSponsored(name, type);
	}
}
