import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PreviousRouteService {
	private previousUrl: string;
	private current: string;

	setPreviousUrl(url: string) {
		this.previousUrl = this.current;
		this.current = url;
	}

	getPreviousUrl(): string {
		return this.previousUrl;
	}
}
