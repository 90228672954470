export enum ScheduleAppointmentEvents {
	SchedulingFormOpened = "Schedule - appointment scheduling form opened",
	SlotsAvailabilityChecked = "Schedule - checked provider's availability",
	BookingRequested = "Schedule - appointment booking requested",
	SchedulingAfterFailedBooking = "Schedule - appointment scheduling after failed booking",
}

export enum CancelAppointmentEvents {
	CancellationRequested = "Cancel - appointment cancellation requested",
	CancelFlowStopped = "Cancel - flow stopped from disclaimer",
}

export enum RescheduleAppointmentEvents {
	ReschedulingFlowStopped = "Reschedule - appointment re-scheduling closed from disclaimer",
	ReschedulingFormOpened = "Reschedule - appointment re-scheduling form opened",
	SlotsAvailabilityChecked = "Reschedule - checked provider's availability",
	ReschedulingRequested = "Reschedule - appointment rescheduling requested"
}

export enum RemoveAppointmentEvents {
	RemovingFailedBooking = "Remove - failed appointment removed"
}
