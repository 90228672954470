import { Component, Input, OnInit } from '@angular/core';
import { TrackingService } from '../../../services/tracking.service';
import { Maybe } from '../../../utils/types/maybe';
import { PlanSelectionService } from 'src/app/services/plan-selection.service';
import CFG from '../../../config/app-config.json';

@Component({
	selector: 'app-plan-selection-button',
	templateUrl: './plan-selection-button.component.html',
	styleUrls: ['./plan-selection-button.component.scss'],
})
export class PlanSelectionButtonComponent implements OnInit {
	@Input() shrink: Maybe<boolean>;
	@Input() source: string;

	planSelectionPath: string;

	constructor(private trackingService: TrackingService, private planSelectionService: PlanSelectionService) {}

	ngOnInit(): void {
		this.planSelectionPath = this.getPlanSelectionPath();
	}
	public onClick(): void {
		this.track();
	}

	track() {
		this.trackingService.trackClientEventPCT('Start', { source: this.source });
	}

	getPlanSelectionPath() {
		return '/' + CFG.openEnrollmentRoute;
	}
}
