<div *ngIf="userHasDeductible$ | async" class="deductible-sync-wrapper">
	<T str="Last Synced on {date}" [vars]="{ date: (connectedDeductiblesData$ | async)?.lastUpdatedAt | date }"></T>
	<ripple-button
		[isDisabled]="isSyncDisabled$ | async"
		(click)="onSyncClicked()"
		[hierarchy]="ButtonHierarchy.link"
		[size]="ButtonSize.xsmall"
		[leadingIcon]="REFRESH_ICON"
	></ripple-button>
</div>
