<div class="clinical-area-container" [ngClass]="{ expanded: isExpanded }">
	<div class="main" [ngClass]="{ expanded: isExpanded }">
		<div class="name">{{ data.display | translate }}</div>
		<div class="conditions">
			{{ 'Conditions' | translate }} ({{ data.conditions.length }})

			<li *ngFor="let condition of data.conditions" class="condition" [ngClass]="{ expanded: isExpanded }">
				<span>{{ condition.display | translate }}</span>
			</li>
		</div>
		<div class="treatments">
			{{ 'Treatments' | translate }} ({{ data.treatments.length }})

			<li *ngFor="let treatment of data.treatments" class="treatment" [ngClass]="{ expanded: isExpanded }">
				<span>{{ treatment.display | translate }}</span>
			</li>
		</div>

		<div class="action">
			<button *ngIf="!isExpanded" class="bt-link" (click)="isExpanded = true">{{ 'Show' | translate }}</button>
			<button *ngIf="isExpanded" class="bt-link" (click)="isExpanded = false">{{ 'Hide' | translate }}</button>
		</div>
	</div>

	<div *ngIf="isExpanded" class="details">
		<div class="name"></div>

		<div class="conditions">
			<li *ngFor="let condition of data.conditions">
				<span>{{ condition.display | translate }}</span>
			</li>
		</div>

		<div class="treatments">
			<li *ngFor="let treatment of data.treatments">
				<span>{{ treatment.display | translate }}</span>
			</li>
		</div>
	</div>
</div>
