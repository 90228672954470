import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { TxNativeModule } from '@transifex/angular';
import { RippleModule } from 'ripple';

import { AppSharedModule } from '../../app-shared/app-shared.module';

import { TopbarComponent } from './topbar/topbar.component';
import { ScreenOverlayComponent } from './screen-overlay/screen-overlay.component';
import { TabComponent } from '../../app-shared/tabs-container/tab/tab.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { ContactUsModalComponent } from './contact-us-modal/contact-us-modal.component';
import { BlockedAccountModalComponent } from './blocked-account-modal/blocked-account-modal.component';

import { EditInfoModalComponent } from './edit-info-modal/edit-info-modal.component';
import { SidebarItemComponent } from './sidebar/sidebar-item/sidebar-item.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TalonWidgetComponent } from './talon-widget/talon-widget.component';
import { TalonWidgetDialogComponent } from './talon-widget-dialog/talon-widget-dialog.component';
import { MobileActionBarComponent } from './mobile-action-bar/mobile-action-bar.component';
import { ToggleMenuItemComponent } from './toggle-menu-item/toggle-menu-item.component';
import { CounterComponent } from '../../app-shared/counter/counter.component';
import { BenefitsTabComponent } from './benefits-tab/benefits-tab.component';
import { AppVersionLabelComponent } from './app-version-label/app-version-label.component';
import { MobileDeveloperMenuComponent } from './mobile-developer-menu/mobile-developer-menu.component';

@NgModule({
	declarations: [
		TopbarComponent,
		ScreenOverlayComponent,
		TabComponent,
		UserAvatarComponent,
		ContactUsModalComponent,
		BlockedAccountModalComponent,
		EditInfoModalComponent,
		SidebarComponent,
		SidebarItemComponent,
		TalonWidgetComponent,
		TalonWidgetDialogComponent,

		MobileActionBarComponent,
		ToggleMenuItemComponent,
		CounterComponent,
		BenefitsTabComponent,
		AppVersionLabelComponent,
		MobileDeveloperMenuComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		MatSelectModule,
		MatProgressBarModule,
		MatIconModule,
		MatMenuModule,
		MatSidenavModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatTabsModule,
		TxNativeModule,
		RippleModule,

		// App modules
		IonicModule,
		AppSharedModule,
	],
	exports: [
		TopbarComponent,
		ScreenOverlayComponent,
		TabComponent,
		UserAvatarComponent,
		ContactUsModalComponent,
		EditInfoModalComponent,
		SidebarComponent,
		TalonWidgetComponent,
		TalonWidgetDialogComponent,
		MobileActionBarComponent,
		ToggleMenuItemComponent,
		CounterComponent,
		BenefitsTabComponent,
		AppVersionLabelComponent,
		MobileDeveloperMenuComponent,
	],
	providers: [Title],
})
export class MainLayoutSharedModule {}
