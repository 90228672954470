<span class="network-label" [ngSwitch]="isInNetwork">
	<span *ngSwitchCase="true">
		{{ 'In-network' | translate }}
	</span>

	<span *ngSwitchCase="false" class="out-of-network">
		{{ 'Out-of-network' | translate }}
	</span>

	<img
		*ngSwitchDefault
		[matTooltip]="noNetworkInformationTooltip"
		matTooltipClass="no-network-information"
		aria-label="Button that shows a red tooltip"
		matTooltipPosition="above"
		src="../../../../../../../assets/images/icons/circled-exclamation.svg"
		alt="exclamation"
		class="network-status-icon"
	/>
</span>
