import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'app-counter',
	templateUrl: './counter.component.html',
	styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
	@Input() value: number = 0;
	@Output() valueEmitter: EventEmitter<number> = new EventEmitter<number>();

	constructor() {}

	ngOnInit(): void {}

	public toggleLess(): void {
		this.value -= 1;
		this.valueEmitter.emit(this.value);
	}

	public togglePlus(): void {
		this.value += 1;
		this.valueEmitter.emit(this.value);
	}
}
