import { isNil } from '../../../../../utils/is/is-nil';
import dayjs from 'dayjs';
import { DateBadge } from './date-badge';

export function addDateBadges(today, yesterday, todayText, yesterdayText) {
	return (res, cur) => {
		const last = res.length > 0 ? res[res.length - 1] : null;
		if (!isNil(last) && !isNil(last.created) && !isNil(cur.created)) {
			const lastMessageTime = dayjs(last.created);
			const curMessageTime = dayjs(cur.created);
			if (lastMessageTime.endOf('day').isBefore(curMessageTime)) {
				res.push({
					type: DateBadge,
					date: curMessageTime.isAfter(today.startOf('day'))
						? todayText
						: curMessageTime.isAfter(yesterday.startOf('day'))
						? yesterdayText
						: curMessageTime.format('D MMMM'),
				});
			}
		}
		res.push(cur);
		return res;
	};
}
