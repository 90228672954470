<div class="emotions">
	<div
		*ngFor="let r of ratings"
		(click)="selectRating(r.rating)"
		[class.selected]="r.rating === rating"
		[ngClass]="r.class"
		class="emotion"
	>
		<ripple-icon [name]="r.icon" size="size40"></ripple-icon>
	</div>
</div>
<div *ngIf="showDisclaimer && rating >= 0" class="disclaimer" [ngClass]="ratings[rating]?.class">
	{{ disclaimers ? disclaimers[rating] : defaultDisclaimers[rating] }}
</div>
