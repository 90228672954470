import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { CommonComponent } from '../../../../utils/components/common-component';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { DeductiblesData } from '../../../../services/stores/user-plan-data-store/helpers/deductibles-data';
import { Maybe } from '../../../../utils/types/maybe';
import { ConnectDeductiblesFormHandlerComponent } from '../../connect-deductibles-form-handler/connect-deductibles-form-handler/connect-deductibles-form-handler.component';
import { isNil } from '../../../../utils/is/is-nil';
import { TrackingService } from '../../../../services/tracking.service';
import { DeductiblesStoreService } from '../../../../services/stores/deductibles-store/deductibles-store.service';
import { DeductiblesApiService } from '../../../../services/api/deductibles-api/deductibles-api.service';
import { Deductible } from '../../../../services/api/domain/deductible';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { T } from '@transifex/angular';
import { filter } from 'rxjs/operators';
import { ExternalApiService } from 'src/app/services/external-api.service';

@Component({
	selector: 'app-medical-spending',
	templateUrl: './medical-spending.component.html',
	styleUrls: ['./medical-spending.component.scss'],
})
export class MedicalSpendingComponent extends CommonComponent implements OnInit, AfterViewInit {
	@ViewChild('individualWrapperRef', { static: false }) individualWrapperRef: ElementRef;
	@ViewChild('individualRef', { static: false }) individualRef: ElementRef;
	@ViewChild('familyWrapperRef', { static: false }) familyWrapperRef: ElementRef;
	@ViewChild('familyRef', { static: false }) familyRef: ElementRef;

	public isIndividualOverflowing = false;
	public isFamilyOverflowing = false;

	@T('Disconnect your plan')
	disconnectTitle: string;

	@T('Are you sure that you want disconnect your plan?')
	disconnectMessage: string;

	@T('No')
	disconnectCancel: string;

	@T('Yes')
	disconnectConfirm: string;

	selectedTab$ = new BehaviorSubject<number>(0);

	disconnectedDeductiblesData$: Observable<DeductiblesData>;

	hasRibbonInsurancePartner$: Observable<boolean>;

	isConnected$: Observable<boolean>;

	connectedDeductiblesData$: Observable<Maybe<Deductible>>;

	isDeductibleSyncing: boolean = false;

	hasMedicalPlanByKayser$ = this.userPlanDataStoreService.hasMedicalPlanByKayser();

	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private deductiblesStoreService: DeductiblesStoreService,
		private deductiblesApiService: DeductiblesApiService,
		private trackingService: TrackingService,
		private healtheeDialogService: HealtheeDialogService,
		private externalApiService: ExternalApiService
	) {
		super();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.checkOverflow();
		}, 200);
	}

	public checkOverflow() {
		if (this.individualRef && this.individualWrapperRef) {
			this.isIndividualOverflowing =
				this.individualRef.nativeElement.offsetWidth > this.individualWrapperRef.nativeElement.offsetWidth;
		}
		if (this.familyRef && this.familyWrapperRef) {
			this.isFamilyOverflowing =
				this.familyRef.nativeElement.offsetWidth > this.familyWrapperRef.nativeElement.offsetWidth;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.checkOverflow();
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.isConnected$ = this.deductiblesStoreService.isConnected();

		this.hasRibbonInsurancePartner$ = this.userPlanDataStoreService.hasRibbonInsurancePartner();

		this.disconnectedDeductiblesData$ = this.userPlanDataStoreService.medicalDeductibleIndividualData();

		this.connectedDeductiblesData$ = this.deductiblesStoreService.get();
	}

	focusOnMemberID(deductiblesFormHandler: Maybe<ConnectDeductiblesFormHandlerComponent>) {
		if (isNil(deductiblesFormHandler)) {
			return;
		}
		this.checkOverflow();
		deductiblesFormHandler.memberIDFormControl.nativeElement.focus();
	}

	disconnect() {
		this.healtheeDialogService
			.open({
				hasCloseButton: true,
				title: this.disconnectTitle,
				message: this.disconnectMessage,
				cancelText: this.disconnectCancel,
				confirmText: this.disconnectConfirm,
			})
			.pipe(
				filter((confirmed) => confirmed),
				switchMap(() => this.deductiblesApiService.disconnect())
			)
			.subscribe({
				next: () => this.track('Deductible Disconnect success'),
			});
	}

	public track(event, metaData = {}): void {
		this.trackingService.trackClientEvent(event, metaData);
	}

	public onDeductibleSyncStatusChanged($event: any): void {
		this.isDeductibleSyncing = $event;
	}
}
