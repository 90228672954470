<div class="content">
	<p class="title">{{ 'Are you sure that you want to cancel this appointment?' | translate }}</p>
	<div class="disclaimer">
		<p class="title">{{ 'Please note!' | translate }}</p>
		<p class="text">
			{{
				'Canceling within 24 hours of your appointment more than three times or missing an appointment more than two times may affect your ability to book appointments in the future.'
					| translate
			}}
		</p>
	</div>
</div>
<div class="footer">
	<button class="healthee-btn md secondary" (click)="onClose()">{{ 'I will attend' | translate }}</button>
	<button class="healthee-btn md primary" (click)="onCancel()">{{ 'Cancel Appointment' | translate }}</button>
</div>
