import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-bubble-dialog',
	templateUrl: './bubble-dialog.component.html',
	styleUrls: ['./bubble-dialog.component.scss'],
})
export class BubbleDialogComponent implements OnInit {
	config = {
		text: '',
		name: '',
	};

	constructor(private dialogRef: MatDialogRef<BubbleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.config.text = this.data.text;
		this.config.name = this.data.name;
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
