import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contract } from '../../../../models/contract.model';
import { PlanType } from '../../../../models/plan-type.model';

const ASSETS_PATH = '/assets/images/health-profile';

@Component({
	selector: 'app-locked-card',
	templateUrl: './locked-card.component.html',
	styleUrls: ['./locked-card.component.scss'],
})
export class LockedCardComponent implements OnInit {
	@Input()
	contract: Contract;

	@Output()
	unlockClick = new EventEmitter<PlanType>();

	public buttonText: string;

	public assetsPath = ASSETS_PATH;

	constructor() {}

	ngOnInit(): void {
		console.log("Erm - I'm a locked card component with name : " + this.contract.name);
		console.log(JSON.parse(JSON.stringify(this.contract)));
		// overriding for MPI - Optum
		if (this.contract.name.toLowerCase().includes('optum')) {
			this.buttonText = 'Unlock your optum card';
			return;
		}
		this.buttonText = 'Unlock your ' + this.contract.planType + ' card';
	}
}
