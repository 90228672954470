<mat-chip-listbox #chipList [multiple]="options.multiple" [selectable]="options.selectable">
	<mat-chip-option
		#chipItem
		*ngFor="let item of items"
		(click)="toggle(item)"
		[selected]="selectedChips.has(item[keyField])"
		[ngStyle]="{
			'background-color': options.chipColor,
			color: options.chipTextColor,
			height: options.chipHeight,
			width: options.chipWidth,
			border: options.chipBorder,
			fontSize: options.fontSize,
			borderRadius: options.borderRadius
		}"
	>
		<ng-container *ngIf="itemTemplate; else simple">
			<img *ngIf="item.img" matChipAvatar [src]="item.img" alt="chip photo" />
			<ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-container>
		</ng-container>

		<ng-template #simple>
			{{ item[displayField] | translate }}
		</ng-template>
	</mat-chip-option>
</mat-chip-listbox>
