import { Injectable } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { SearchOptionsParams, SearchEntity } from '../../modules/main-layout/care-costs/helpers/providers-search.helper';
import { getDataMixed, EMERGENCY_MEDECINE_SERVICE_ID, PAGE_SIZE_PER_ENTITY } from './helpers/mix-data.helpers';
import { ProvidersApiService } from '../api/providers-api/providers-api.service';
import { FacilitiesApiService } from '../api/providers-api/facilities-api.service';

@Injectable({
  providedIn: 'root'
})
export class MixSearchService {

  constructor(private providersApiService: ProvidersApiService, private facilitiesApiService: FacilitiesApiService) { }

  getMixProvidersAndFacilities(params: SearchOptionsParams) {
    params.pageSize = PAGE_SIZE_PER_ENTITY;
    const paramsForProvidersSearch = { ...params };
    paramsForProvidersSearch.service = EMERGENCY_MEDECINE_SERVICE_ID;
    delete paramsForProvidersSearch.searchByNeed;

    const paramsForFacilitiesSearch = { ...params };
    paramsForFacilitiesSearch.facilities = params.searchByNeed;
    delete paramsForFacilitiesSearch.searchByNeed;

    return forkJoin({
      provider: this.providersApiService.providersPaginated(paramsForProvidersSearch),
      facility: this.facilitiesApiService.facilitiesPaginated(paramsForFacilitiesSearch)
    }).pipe(
      map(data => {
        data.provider.data.map(obj => obj.type = SearchEntity.Provider);
        data.facility.data.map(obj => obj.type = SearchEntity.Facility);

        return getDataMixed(data);
      })
    );
  }
}
