import { Component } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { defaultIfEmpty } from 'rxjs';

@Component({
	selector: 'app-service-panel-eligible-zipcodes',
	templateUrl: './service-panel-eligible-zipcodes.component.html',
	styleUrls: ['./service-panel-eligible-zipcodes.component.scss'],
})
export class ServicePanelEligibleZipCodesComponent extends CommonComponent {
	zipCodes$ = this.userPlanDataStoreService.getEligibleZipCodes().pipe(defaultIfEmpty([]));

	constructor(private userPlanDataStoreService: UserPlanDataStoreService) {
		super();
	}

	isVisibleModal: boolean = false;

	hideZipCodesModal() {
		this.isVisibleModal = false;
	}

	showZipCodesModal() {
		this.isVisibleModal = true;
	}
}
