import { Component, Input, OnInit } from '@angular/core';
import { T } from '@transifex/angular';

@Component({
	selector: 'app-in-out-network-status',
	templateUrl: './in-out-network-status.component.html',
	styleUrls: ['./in-out-network-status.component.scss'],
})
export class InOutNetworkStatusComponent implements OnInit {
	@Input() isInNetwork: boolean = null;

	@T('No Network Information')
	noNetworkInformationTooltip: string;

	constructor() {}
	ngOnInit(): void {}
}
