<div class="service-header" [class.service-header-shrink]="shrinked">
	<div class="service-header-image">
		<div [ngStyle]="{ 'background-image': 'url(/assets/images/default-service-image-large.svg)' }"></div>
		<div
			[ngStyle]="{
				'background-image': 'url(' + bgImage + ')'
			}"
		></div>
	</div>
	<div class="service-header-content">
		<div class="service-header-close-and-label">
			<button (click)="closeClick.emit()" class="service-header-content-back-button close-button" mat-icon-button>
				<mat-icon>close</mat-icon>
			</button>
			<button (click)="closeClick.emit()" class="service-header-content-back-button arrow-back" mat-icon-button>
				<mat-icon>arrow_back</mat-icon>
			</button>
			<div class="coverage-badge">
				<span *ngIf="covered; else displayRedBadge">
					<ripple-label
						text="{{ getCarrierTitle(carrier, planType) }}"
						[colorTheme]="isESP ? LabelColorTheme.allDarkMagenta : LabelColorTheme.allGreen"
					></ripple-label>
				</span>
				<ng-template #displayRedBadge>
					<span>
						<ripple-label
							text="{{ 'Not Covered' | notCoveredInServicePanel }}"
							[colorTheme]="LabelColorTheme.allOrange"
						></ripple-label>
					</span>
				</ng-template>
			</div>
		</div>

		<div class="service-header-content-title">{{ title | mpiServiceTitle }}</div>
	</div>
</div>
