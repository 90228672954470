import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { MainLayoutSharedModule } from 'src/app/modules/main-layout/main-layout-shared/main-layout-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import { ProviderDetailsPageComponent } from './provider-details-page/provider-details-page.component';
import { FacilityDetailsPageComponent } from './facility-details-page/facility-details-page.component';
import { ProvidersSearchResultsPageComponent } from './providers-search-results-page/providers-search-results-page.component';
import { MPIProvidersSearchSharedModule } from './shared/mpi-providers-search-shared.module';

@NgModule({
	declarations: [ProviderDetailsPageComponent, FacilityDetailsPageComponent, ProvidersSearchResultsPageComponent],
	exports: [],
	imports: [
		CommonModule,
		TxNativeModule,
		MainLayoutSharedModule,
		MatTabsModule,
		RouterLink,
		MPIProvidersSearchSharedModule,
	],
})
export class MPIProvidersSearchModule {}
