<div class="copay">
	<span class="icon-container">
		<img src="/assets/icons/icon-wallet-coverage.svg" alt="wallet-icon" />
	</span>
	<span class="content-container" *ngIf="getCopayText() as copayInfo">
		<div class="headline-container" *ngIf="copayInfo.headline">
			{{ copayInfo.headline }}
		</div>
		<div class="text-container">
			<span
				class="green"
				[innerHTML]="getFullCopayText(copayInfo) | underlineAllowableAmount | copayToCoPayment"
			></span>
			<!-- <span class="dark-blue"></span> -->
		</div>
	</span>
</div>

<div class="mr" *ngIf="getMRText() as mrInfo">
	<span class="icon-container">
		<img src="/assets/icons/icon-wallet-coverage.svg" alt="wallet-icon" />
	</span>
	<span class="content-container">
		<div class="headline-container">
			{{ mrInfo.headline }}
		</div>
		<div class="text-container">
			<span
				class="green"
				[innerHTML]="getMRFullText(mrInfo) | underlineAllowableAmount | copayToCoPayment"
			></span>
			<!-- <span class="dark-blue"></span> -->
		</div>
	</span>
</div>
<div class="deductible-container">
	<p>{{ (subjectToDeductible ? 'subject to deductible' : 'not subject to deductible') | translate }}</p>
</div>
