import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs';

import CFG from '../config/app-config.json';
import {
	RibbonResponse,
	RibbonDoctor,
	RibbonProvidersInputs,
	RibbonDoctorDetails,
	RibbonProvidersSearchRequest,
	RibbonPCTData,
	RibbonFacilityDetails,
} from '../models/ribbon-data.model';
import { HealtheeService } from '../models/service.model';
import { isNil } from '../utils/is/is-nil';
import { ProviderGender } from '../modules/mpi/providers-search/helpers/providers.helpers';

@Injectable({ providedIn: 'root' })
export abstract class BaseRibbonHealthService {
	constructor(protected http: HttpClient) {}

	public getGoogleMapsAPIKey() {
		return this.http.get<string>(CFG.apiEndpoints.googleMapsApiKey);
	}

	public getRibbonProvidersList(inputs: RibbonProvidersInputs) {
		const { minEfficiencyIndex, minRating, language, pageSize, page, distance, gender, name, onlineBookingOnly } =
			inputs;
		let params = new HttpParams();
		params = params.append('address', inputs.address);
		params = params.append('serviceId', inputs.serviceId);
		params = params.append('serviceType', inputs.serviceType);

		if (!isNil(distance)) {
			params = params.append('distance', distance);
		}

		if (!isNil(minEfficiencyIndex)) {
			params = params.append('minEfficiencyIndex', minEfficiencyIndex);
		}

		if (!isNil(minRating)) {
			params = params.append('minRating', minRating);
		}

		if (!isNil(onlineBookingOnly)) {
			params = params.append('onlineBookingOnly', onlineBookingOnly);
		}

		if (!isNil(language)) {
			params = params.append('language', language);
		}

		if (!isNil(gender)) {
			params = params.append('gender', gender);
		}

		if (!isNil(page)) {
			params = params.append('page', page);
		}

		if (!isNil(pageSize)) {
			params = params.append('pageSize', pageSize);
		}

		if (!isNil(name)) {
			params = params.append('name', name);
		}

		return this.http
			.get<RibbonResponse>(CFG.apiEndpoints.ribbonProviders, { params })
			.pipe(map((searchResponse) => this.addNetworkStatusForEachCardInResponse(searchResponse)));
	}

	public getHealtheeServicesNamesThatMappedByRibbon() {
		return this.http.get<HealtheeService[]>(CFG.apiEndpoints.healtheeServicesMappedByRibbon);
	}

	public abstract getEntities();

	protected getWoDuplicatedRibbonKey(arr) {
		const keyOccurrences: { [key: string]: any } = {};
		arr.forEach((item) => {
			const key = item.ribbon?.key;
			if (key) {
				keyOccurrences[key] ? keyOccurrences[key]++ : (keyOccurrences[key] = 1);
			}
		});

		const withoutDuplicates = arr.filter((item) => {
			if (keyOccurrences[item.ribbon.key] === 1) {
				return item;
			}

			if (keyOccurrences[item.ribbon.key] > 1 && item.ribbonPrimary) {
				return item;
			}
		});

		return withoutDuplicates;
	}

	protected addNetworkStatusForEachCardInResponse(searchResponse: RibbonResponse): RibbonResponse {
		const networkStatus = this.getNetworkStatus(searchResponse.networkStatus);

		searchResponse.providers.data.forEach((cardData: RibbonDoctor) => {
			cardData.isInNetwork = networkStatus;
		});
		return searchResponse;
	}

	protected getNetworkStatus(networkStatusInStringFormat: string): boolean {
		// API network statuses: IN_NETWORK, OUT_OF_NETWORK, UNKNOWN
		return networkStatusInStringFormat === 'UNKNOWN'
			? null
			: networkStatusInStringFormat === 'IN_NETWORK'
			? true
			: false;
	}

	public static getProvidersLengthDisplay(length: number, maxNumber: number) {
		return length < maxNumber - 1 ? length : maxNumber + '+';
	}

	public getRibbonProvidersForPlanSelection(searchParams: RibbonProvidersSearchRequest) {
		const params = new HttpParams({
			fromObject: {
				...searchParams,
			},
		});

		return this.http.get<RibbonPCTData>(CFG.apiEndpoints.ribbonProvidersForPlanSelection, { params });
	}

	public getRibbonProvidersByNPIs(npis: string[]) {
		const params = new HttpParams({
			fromObject: {
				npis,
			},
		});
		return this.http.get<RibbonDoctorDetails>(CFG.apiEndpoints.ribbonProvidersForPlanSelectionNPIs, {
			params,
		});
	}

	public getRibbonFacilitiesByUUIDs(uuids: string[]) {
		const params = new HttpParams({
			fromObject: {
				uuids,
			},
		});
		return this.http.get<RibbonFacilityDetails>(CFG.apiEndpoints.ribbonFacilitiesForPlanSelectionUUIDs, {
			params,
		});
	}

	public languages() {
		return [
			{ id: '26bd8812-ec4d-4d1f-990a-49633091c76d', title: 'English' },
			{ id: '355c6a23-fbf5-4a0c-9bf3-587bef2da653', title: 'Spanish' },
			{ id: '2f6b4e64-6156-4df6-a44b-ce715bf63a00', title: 'Chinese' },
			{ id: 'dae85722-d0ee-4403-9b19-adee4d6ad61b', title: 'Tagalog' },
			{ id: 'b6b8ef85-c0e6-4d83-be34-fe17011f3cf4', title: 'Vietnamese' },
			{ id: 'de8c5ccc-54cb-4e49-bb13-4011e3d505d5', title: 'French' },
			{ id: '9b79819b-a1e3-489d-8010-b0b9f591c201', title: 'Arabic' },
			{ id: '790a1276-d42d-4012-a107-e613202b64c4', title: 'Korean' },
			{ id: 'b58561b1-5570-48d4-9a00-794cec2bc5ff', title: 'Russian' },
			{ id: 'a50fab01-32bd-4f7e-9ee2-9b5eb0b03634', title: 'German' },
			{ id: 'dfac0771-0053-4021-b323-ab0d33f5becc', title: 'Italian' },
			{ id: '691b9f07-d51b-4d9d-b831-ace8b3edb966', title: 'Portugese' },
			{ id: 'd9641c76-e67d-49c0-87b2-ec762cb37c7a', title: 'Hindi' },
			{ id: '1cedce28-482d-43bf-8650-8f7ad596806d', title: 'Polish' },
			{ id: 'b9a9cac2-17fa-4fc6-ae43-b7123de25e7e', title: 'Greek' },
			{ id: '3cba92ea-3a29-4d1c-9358-0b33b17117e0', title: 'Hebrew' },
			{ id: '20035368-1ce7-484f-a033-f7e9b0eb48e2', title: 'Panjabi' },
		];
	}
	public costs() {
		return [
			{ id: 1, display: '$$$$$-' },
			{ id: 2, display: '$$$$-' },
			{ id: 3, display: '$$$-' },
			{ id: 4, display: '$$-' },
			{ id: 5, display: '$' },
		];
	}
	public scores() {
		return [
			{ id: 2, value: 1 },
			{ id: 4, value: 2 },
			{ id: 6, value: 3 },
			{ id: 8, value: 4 },
			{ id: 10, value: 5 },
		];
	}
	public distances() {
		return [
			{ id: '1', display: '1 Miles' },
			{ id: '5', display: '5 Miles' },
			{ id: '10', display: '10 Miles' },
			{ id: '25', display: '25 Miles' },
			{ id: '50', display: '50 Miles' },
			{ id: '100', display: '100 Miles' },
		];
	}
	public genders() {
		return [
			{ id: ProviderGender.Male, display: 'Male' },
			{ id: ProviderGender.Female, display: 'Female' },
		];
	}
	public networks() {
		return [
			{ id: false, display: 'In-Network' },
			{ id: true, display: 'Out-of-Network' },
		];
	}

	public get specificInsurancesConnectedByRibbon() {
		return [
			{ initial: '8b51820d-43f8-4234-a822-d140a32ace25', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: 'eecb0179-615f-42cd-9fc0-8f7570f4de63', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: '64a3adc9-eee6-4c26-b00b-72b01cc25431', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: '4776cda9-a060-48e8-9d94-144ba6d8fbe5', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: '387a26fc-335c-462f-bee1-29b9f998e4cb', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: '877e5a3e-93d6-4279-a4c9-e04a326bd540', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: 'c628b7bc-b4be-41d7-ae6a-6199d51fc0ad', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
			{ initial: '7753b892-e0a4-430a-9a60-dc0e3d4e5273', mapped: 'b8581687-2b4e-4f2f-91a9-9128f5bec540' },
		];
	}

	get searchByNeedsList() {
		return [
			{
				text: 'Urgent Care',
				value: 'Urgent Care,Emergency Room,Hospital,Retail Health Clinic',
			},
		];
	}
}
