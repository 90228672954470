import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Maybe } from '../../../utils/types/maybe';

@Directive({
	selector: '[appDefaultImage]',
})
export class DefaultImageDirective {
	@Input('appDefaultImage')
	defaultImage: Maybe<string>;

	@Input('src')
	@HostBinding('src')
	image: Maybe<string>;

	@HostListener('error')
	private error() {
		this.image = this.defaultImage;
	}

	constructor() {}
}
