<a
	cdkDragBoundary=".hydrated"
	(cdkDragStarted)="onDragStart()"
	cdkDrag
	class="custom-intercom-element"
	[class.hidden]="!userData?.company?.showIntercom"
	(click)="openMessenger()"
	[ngStyle]="{
		bottom:
			'calc(env(safe-area-inset-bottom) + 1rem + var(--capacitor-nav-height) + ' +
			(intercomHigher$ | async) +
			'px)'
	}"
></a>
