import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureGuard } from '../../guards/feature-guard.guard';
import { RedirectGuard } from '../../guards/redirect-guard.guard';

import { DISABLED_BACK_NAVIGATION } from '../../services/ui.service';

import { NewchatComponent } from './ask-zoe/newchat/newchat.component';

import { ContractGuard } from 'src/app/guards/contract-guard.guard';
import { ImmediateCareComponent } from '../immediate-care/immediate-care.component';
import { GipitpotControllerComponent } from '../gipitpot/gipitpot-controller.component';
import { EligibleUserGuard } from 'src/app/guards/eligibleUser.guard';
import { PLAN_SELECTION_PATH } from './main-layout.constants';
import { PlanSelectionGuard } from 'src/app/guards/plan-selection.guard';
import { ContractAndAdditionalBenefitsGuard } from 'src/app/guards/contract-and-additional-benefits-guard.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		children: [],
		canActivate: [RedirectGuard],
	},
	{
		path: 'chat',
		component: NewchatComponent,
		data: { animation: 'Chat', title: 'ask_zoe', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
		canActivate: [ContractGuard, EligibleUserGuard],
	},
	{
		path: 'gipitpot',
		component: GipitpotControllerComponent,
		data: { title: 'ask_zoe', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
		canActivate: [ContractGuard],
	},
	{
		path: 'home',
		loadChildren: () =>
			import('./../home-page/home-page.module').then((loadedModule) => loadedModule.HomePageModule),
		data: {
			title: 'home',
			ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION,
		},
		canActivate: [FeatureGuard],
	},
	{
		path: 'health-profile',
		loadChildren: () =>
			import('./health-profile/health-profile.module').then((loadedModule) => loadedModule.HealthProfileModule),
		data: { title: 'health_profile' },
		canActivate: [ContractGuard, EligibleUserGuard],
	},
	{
		path: 'procedures-search', // leave here for backward compat with links sent to users
		redirectTo: 'care-and-costs',
		pathMatch: 'full',
	},
	{
		path: 'care-and-costs',
		loadChildren: () =>
			import('./care-costs/care-costs.module').then((loadedModule) => loadedModule.CareCostsModule),
		data: { title: 'provider_search' },
		canActivate: [ContractGuard, EligibleUserGuard],
	},
	{
		path: 'gallery',
		loadChildren: () => import('./gallery/gallery.module').then((loadedModule) => loadedModule.GalleryModule),
		data: { title: 'my_benefits' },
		canActivate: [ContractGuard, EligibleUserGuard],
	},
	{
		path: PLAN_SELECTION_PATH,
		loadChildren: () =>
			import('../plan-selection/plan-selection.module').then((loadedModule) => loadedModule.PlanSelectionModule),
		data: { title: 'pct' },
	},
	{
		path: 'user', // important! do not remove. 'u' is for a shorter url representation.
		redirectTo: 'me',
		pathMatch: 'full',
	},
	{
		path: 'me',
		loadChildren: () =>
			import('./my-profile/my-profile.module').then((loadedModule) => loadedModule.MyProfileModule),
		data: { title: 'my_profile' },
		canActivate: [EligibleUserGuard],
	},
	{
		path: 'mobile-app-settings',
		loadChildren: () =>
			import('../../routes/mobile-app-settings-route/mobile-app-settings-route.module').then(
				(m) => m.MobileAppSettingsRouteModule
			),
	},
	{
		path: 'coverage-info',
		loadChildren: () =>
			import('../../routes/coverage-info-route/coverage-info-route.module').then(
				(m) => m.CoverageInfoRouteModule
			),
		data: { title: 'my_benefits' },
		canActivate: [EligibleUserGuard, ContractAndAdditionalBenefitsGuard],
	},
	{
		path: 'immediate-care',
		component: ImmediateCareComponent,
		loadChildren: () =>
			import('../immediate-care/immediate-care.module').then((loadedModule) => loadedModule.ImmediateCareModule),
		data: { title: 'immediateCare' },
		canActivate: [EligibleUserGuard, ContractGuard],
	},
	{
		path: 'preventive-care',
		loadChildren: () =>
			import('../preventive-care/preventive-care.module').then(
				(loadedModule) => loadedModule.PreventiveCareModule
			),
		canActivate: [EligibleUserGuard],
	},
	{
		path: '**',
		pathMatch: 'full',
		children: [],
		canActivate: [RedirectGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [],
})
export class MainLayoutRoutingModule {}
