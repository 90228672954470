import { Directive, Injector } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Maybe } from '../types/maybe';
import { isNil } from '../is/is-nil';
import { RxjsUtils } from '../rxjs';

import { CommonComponent } from './common-component';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FormControlComponent<FormValue = unknown>
	extends CommonComponent
	implements ControlValueAccessor
{
	private latestChangeValue: Maybe<FormValue>;
	private readonly onChangeFn$ = new BehaviorSubject<Maybe<(v: Maybe<FormValue>) => void>>(null);
	private readonly onTouchedFn$ = new BehaviorSubject<any>(null);
	public readonly isDisabled$ = new BehaviorSubject<boolean>(false);
	public readonly value$ = new BehaviorSubject<Maybe<FormValue>>(null);
	public readonly syncedValue$ = new BehaviorSubject<Maybe<FormValue>>(null);

	private ngControl!: NgControl;

	// ==========
	// Since we use material inputs, it is necessary to transfer validators to material specific controls
	// flags below should help with that
	get formControl(): FormControl {
		// @ts-ignore
		return !isNil(this.ngControl) ? this.ngControl.control : null;
	}

	get hasRequiredValidator(): boolean {
		return !isNil(this.formControl) && this.formControl.hasValidator(Validators.required);
	}

	get hasEmailValidator(): boolean {
		return !isNil(this.formControl) && this.formControl.hasValidator(Validators.email);
	}

	// ==========

	protected constructor(private _injector: Injector) {
		super();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected valueReceived(val: FormValue) {}

	registerOnChange(fn: any): void {
		this.onChangeFn$.next(fn);
	}

	registerOnTouched(fn: any): void {
		this.onTouchedFn$.next(fn);
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled$.next(isDisabled);
	}

	writeValue(obj: any): void {
		this.value$.next(obj);
		this.syncedValue$.next(obj);
		this.valueReceived(obj);
	}

	markAsTouched() {
		this.onTouchedFn$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe((touchedFn) => touchedFn());
	}

	changeValue(val: FormValue): void {
		this.latestChangeValue = val;

		this.onChangeFn$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe((changeFn) => {
			changeFn(this.latestChangeValue);
			this.syncedValue$.next(this.latestChangeValue);
		});
	}

	// tslint:disable-next-line:use-lifecycle-interface
	override ngOnInit() {
		super.ngOnInit();
		try {
			this.ngControl = this._injector.get(NgControl);
		} catch (_) {}
	}
}
