import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonHierarchy } from 'ripple';
import { UserApiService } from '../../../../services/api/user-api/user-api.service';

@Component({
	selector: 'app-health-cards-teaser',
	templateUrl: './health-cards-teaser.component.html',
	styleUrls: ['./health-cards-teaser.component.scss'],
})
export class HealthCardsTeaserComponent {
	@Output()
	showCardsClick = new EventEmitter<void>();

	ButtonHierarchy = ButtonHierarchy;

	constructor(private userApiService: UserApiService) {}

	dismiss() {
		this.userApiService.dismissHomePageHealthCardTeaser().subscribe();
	}
}
