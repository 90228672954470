import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ProviderDetails } from '../../../helpers/providers.helpers';
import { TrackingService } from '../../../../../../services/tracking.service';

const ASSETS_PATH = '/assets/images/provider-card';
const REVIEW_TO_FETCH = 5;

@Component({
	selector: 'app-provider-reviews',
	templateUrl: './provider-reviews.component.html',
	styleUrls: ['./provider-reviews.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderReviewsComponent {
	@Input() providerData: ProviderDetails;

	public assetsPath: string = ASSETS_PATH;

	public reviewCount: number = REVIEW_TO_FETCH;

	constructor(
		private trackingService: TrackingService
	) {}

	public showMoreReviews() {
		this.reviewCount += REVIEW_TO_FETCH;
		this.trackingService.trackClientEvent('PS - show more reviews', {reviewCount: this.reviewCount});
	}
	  
	public hasMoreReviews(): boolean {
		return this.providerData && this.providerData.reviews.length > this.reviewCount;
	}
}
