import { Injectable } from '@angular/core';
import { BaseRibbonEntitiesStoreService } from './base-ribbon-entities-store.service';
import { MPIRibbonHealthService } from '../../mpi-ribbon.service';

@Injectable({
	providedIn: 'root',
})
export class MPIRibbonEntitiesStoreService extends BaseRibbonEntitiesStoreService {
	constructor(ribbonHealthService: MPIRibbonHealthService) {
		super(ribbonHealthService);
	}
}
