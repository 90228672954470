import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
	selector: 'app-locale-select',
	templateUrl: './locale-select.component.html',
	styleUrls: ['./locale-select.component.scss'],
})
export class LocaleSelectComponent implements OnInit {
	@Input() fullSize: boolean;
	public showPicker$: Observable<boolean> = of(false);

	constructor(private languageService: LanguageService) {}

	ngOnInit(): void {
		this.showPicker$ = this.languageService.showLoginLanguagePicker$;
	}

	async setLocal(lang) {
		await this.languageService.setLocale(lang);
	}
}
