import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicePanelComponent } from './service-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppSharedModule } from '../../app-shared/app-shared.module';
import { TxNativeModule } from '@transifex/angular';
import { DefaultImageModule } from '../default-image/default-image.module';
import { ClientEventTrackerModule } from '../client-event-tracker/client-event-tracker.module';
import { FeedbackBlockModule } from 'src/app/modules/standalone/feedback/feedback-block/feedback-block.module';
import { FeedbackDialogModule } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RippleModule } from 'ripple';
import { ServicePanelCopayComponent } from './service-panel-copay/service-panel-copay.component';
import { ServicePanelAccordionComponent } from './service-panel-accordion/service-panel-accordion.component';
import { ServicePanelCardComponent } from './service-panel-card/service-panel-card.component';
import { ServicePanelHeaderComponent } from './service-panel-header/service-panel-header.component';
import { ServicePanelFooterComponent } from './service-panel-footer/service-panel-footer.component';
import { ServicePanelRelatedServicesComponent } from './service-panel-related-services/service-panel-related-services.component';
import { EligibleZipcodesModalComponent } from '../eligible-zipcodes-modal/eligible-zipcodes-modal.component';
import { DrawerModule } from '../drawer/drawer.module';
import { ServicePanelEligibleZipCodesComponent } from './service-panel-eligible-zipcode/service-panel-eligible-zipcodes.component';
import { PharmacyServicesComponent } from '../../plan-selection/comparison-table-page/pharmacy-services/pharmacy-services.component';

@NgModule({
	declarations: [
		ServicePanelComponent,
		ServicePanelCopayComponent,
		ServicePanelAccordionComponent,
		ServicePanelCardComponent,
		ServicePanelHeaderComponent,
		ServicePanelFooterComponent,
		ServicePanelRelatedServicesComponent,
		ServicePanelEligibleZipCodesComponent,
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		AppSharedModule,
		TxNativeModule,
		DefaultImageModule,
		ClientEventTrackerModule,
		FeedbackBlockModule,
		FeedbackDialogModule,
		MatTabsModule,
		RippleModule,
		DrawerModule,
		EligibleZipcodesModalComponent,
		PharmacyServicesComponent,
	],
	exports: [ServicePanelComponent],
})
export class ServicePanelModule {}
