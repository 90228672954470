import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ripple-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	@Input() size: LoaderSize = LoaderSize.small;
	@Input() text: string = '';
	@Input() color: LoaderColor = LoaderColor.default;
	@Input() alignTo: LoaderAlign = LoaderAlign.Default;

	constructor() {}

	ngOnInit(): void {}
}

export enum LoaderSize {
	'xsmall' = 'xs',
	'small' = 'sm',
	'medium' = 'md',
	'large' = 'lg',
}

export enum LoaderColor {
	'default' = 'default',
	'white' = 'white',
	'gray' = 'gray',
	'yellow' = 'yellow'
}

export enum LoaderAlign {
	Default = '',
	Left = 'left',
	Right = 'right',
}
