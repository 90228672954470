<app-search-results-base-card [appearance]="appearance">
	<div avatar></div>
	<ng-container title>
		{{ facility.name }}
	</ng-container>
	<ng-container subTitle>
		<ng-container *ngFor="let type of facility.locationTypes; last as isLast">
			{{ type }}<span *ngIf="!isLast">,&nbsp;</span>
		</ng-container>
	</ng-container>

	<div class="body" body>
		<div class="d-flex justify-content-center" *ngIf="facility.distance">
			<img src="assets/images/provider-card/provider-card-location-pin.svg" alt="Distance" />
			<span>{{ facility.distance }} {{ 'miles' | translate }}</span>
		</div>

		<ng-container *ngIf="appearance === 'standard'">
			<!-- temporarily removed, we need to fix underlaying issue. ToDo -->

			<!-- <div *ngIf="facility.isInNetwork === true" class="network-wrapper">
				<div class="radius-circle icon-size-8 background-success ml-4 mr-4"></div>
				<div>{{ 'In-network' | translate }} ({{ facility.networkContract | titlecase }} Plan)</div>
			</div>
			<div *ngIf="facility.isInNetwork === false" class="network-wrapper">
				<div class="radius-circle icon-size-8 background-error ml-4 mr-4"></div>
				<div>{{ 'Out-of-Network' | translate }}</div>
			</div>
			<div *ngIf="facility.isInNetwork === null" class="network-wrapper">
				<div class="radius-circle icon-size-8 background-color-light-blue ml-4 mr-4"></div>
				<div>{{ 'Coverage status is unavailable' | translate }}</div>
			</div> -->
		</ng-container>
	</div>

	<div footer class="footer">
		<ng-container *ngIf="facility.phoneNumbers?.[0]">
			<button
				class="healthee-btn sm primary"
				(click)="onCallClick($event)"
				[matTooltip]="facility.phoneNumbers[0]"
				[matTooltipDisabled]="true"
				#tooltip="matTooltip"
				(mouseover)="showTooltip(tooltip)"
			>
				<ripple-icon name="phone"></ripple-icon>
				{{ 'Call' | translate }}
			</button>
		</ng-container>
	</div>
</app-search-results-base-card>
