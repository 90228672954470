import { Component, Input } from '@angular/core';
import { isNil } from '../../../../utils/is/is-nil';
import { TxNativeModule } from '@transifex/angular';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-pct-notes',
	templateUrl: './pct-notes.component.html',
	styleUrls: ['./pct-notes.component.scss'],
	standalone: true,
	imports: [CommonModule, TxNativeModule],
})
export class PctNotesComponent {
	@Input() service: any;
	@Input() networkType: 'inNetwork' | 'outNetwork';
	@Input() variant: 'medium' | 'small' | 'xs' = 'small';

	isNil = isNil;

	public isSubjectToDeductible() {
		return (
			(this.service?.subjectToDeductableIn && this.networkType === 'inNetwork') ||
			(this.service?.subjectToDeductableOut && this.networkType === 'outNetwork')
		);
	}

	public hasValidCountLimitation(pctNote) {
		return (
			pctNote?.countLimitation?.serviceCount &&
			pctNote?.countLimitation?.timeUnitCount &&
			pctNote?.countLimitation?.timeUnit
		);
	}
}
