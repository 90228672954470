<div
	*ngIf="checklistLoaded; else showLoader"
	class="preventive-care-teaser-container"
	[class.is-new-user]="showInitTeaser"
>
	<div class="content" [class.is-new-user]="showInitTeaser">
		<div class="top">
			<div class="titles-wrapper">
				<a class="title" (click)="onOpenPreventiveCare()">{{ 'My Care Tracker' | translate }}</a>

				<div *ngIf="checklistLoaded" class="subtitle" [ngSwitch]="showInitTeaser">
					<div *ngSwitchCase="false">
						{{ 'Complete your recommended preventive care visits' | translate }}
					</div>
					<div *ngSwitchCase="true">
						{{
							'Stay on top of your family’s health with personalized preventive care recommendations'
								| translate
						}}
					</div>
				</div>
			</div>

			<a *ngIf="!showInitTeaser" (click)="onOpenPreventiveCare()" link>{{ 'Show' | translate }}</a>
		</div>

		<ripple-button
			*ngIf="showInitTeaser"
			[label]="'Take Charge of your Health' | translate"
			size="sm"
			trailingIcon="arrowRight"
			(click)="onOpenPreventiveCare()"
		>
		</ripple-button>

		<div *ngIf="!showInitTeaser" class="preventive-care-progress-wrapper">
			<app-preventive-care-progress
				[progress]="checklist | completedCount"
				[total]="checklist.length"
				(progressBarClick)="onOpenPreventiveCare()"
			>
			</app-preventive-care-progress>
		</div>
	</div>

	<img *ngIf="showInitTeaser" class="welcome-image" src="/assets/images/preventive-care-family.png" alt="" />
</div>

<ng-template #showLoader>
	<div class="loader-wrapper">
		<div class="title">{{ 'My Care Tracker' | translate }}</div>
		<app-loader [size]="'medium'"></app-loader>
	</div>
</ng-template>
