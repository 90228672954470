import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs';
import { UserService } from '../../../../../../services/user.service';

@Component({
	selector: 'app-appointment-submitted',
	templateUrl: './appointment-submitted.component.html',
	styleUrls: ['./appointment-submitted.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentSubmittedComponent implements OnInit {
	@Input()
	isReschedule = false;

	@Output()
	onSubscriptionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	public userData;
	private subscriptionChoiceDefault: boolean = true;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.userService.userData$.pipe(take(1)).subscribe((userData) => {
			this.userData = userData;
			if (!userData.subscribeToAppointmentNotifications) {
				this.onSubscriptionChanged.emit(this.subscriptionChoiceDefault);
			}
		});
	}

	public onChoiceChange({ checked }): void {
		this.onSubscriptionChanged.emit(checked);
	}
}
