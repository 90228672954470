import { Params } from '@angular/router';
import { isNil } from '../../../../utils/is/is-nil';
import { filterOutNils } from '../../../../utils/object/filter-out-nils';
import { Maybe } from '../../../../utils/types/maybe';
import { ChipsOptions } from '../../../app-shared/standalone/form-controls/chip-list-form-control/chip-list-form-control/chip-list-form-control.component';
import { Facility, Provider, ProviderDetails, ProviderGender } from './providers.helpers';
import { NetworkStructure } from 'src/app/models/chat.model';

export enum SearchEntity {
	Facility = 'Facility',
	Provider = 'Provider',
	Procedure = 'Procedure',
	Treatment = 'Treatment',
	Condition = 'Condition',
	SearchByNeed = 'SearchByNeed',
	SearchByGroupName = 'SearchByGroupName',
}

export enum SearchType {
	Options = 'Options',
	Free = 'Free',
}

export const mobileFilterersOptions: ChipsOptions = {
	multiple: false,
	chipColor: 'white',
	selectable: true,
	chipTextColor: '#405C79',
	chipBorder: '1px solid #DDE0E2',
	chipHeight: '2.5rem',
	borderRadius: '2rem',
};

export enum ProvidersSearchFormFields {
	Entity = 'entity',
	SearchType = 'searchType',
	Gender = 'gender',
	Address = 'address',
	Value = 'value',
	Page = 'page',
	OutOfNetwork = 'outOfNetwork',
	Distances = 'distance',
	Language = 'language',
	MinRating = 'minRating',
	OnlineBookingOnly = 'onlineBookingOnly',
	InsuranceGroup = 'insuranceGroup',
	MPILanguage = 'mpiLanguage',
	RbpApproved = 'rbpApproved',
	MnFavoriteSearch = 'mnFavoriteSearch',
}

export interface StaticFields {
	entity: SearchEntity;
	searchType: SearchType;
	page: number;
}

export type DynamicFields = {
	[key in ProvidersSearchFormFields]?: any;
};

export type SearchFields = StaticFields & DynamicFields;

export interface AttributeGroup {
	name: string;
	options: string[];
	entity: SearchEntity;
	id: string;
}

export interface SearchPage<T> {
	records: any[];
	page: number;
	pageSize: number;
	totalCount: number;
	isInNetwork: Maybe<boolean>;
	entity: SearchEntity;
	isLastPage?: boolean;
	networkName?: string;
	networkStructure?: NetworkStructure;
}

export type ProvidersSearchResults = SearchPage<Facility> | SearchPage<Provider>;

export interface SearchOptionsParams {
	page: number;
	address: string;
	distance: string;
	name?: string;
	facilities?: string;
	service?: string;
	procedure?: string;
	outOfNetwork?: boolean;
	language?: string;
	gender?: ProviderGender;
	minRating?: number;
	onlineBookingOnly?: boolean;
	rbpApproved?: boolean;
	mnFavoriteSearch?: boolean;
	searchByNeed?: string;
	pageSize?: number;
	networkStructure?: NetworkStructure;
}

export interface SearchOptions {
	entity: SearchEntity;
	searchType: SearchType;
	params: SearchOptionsParams;
}

export const googlePlaceOptions = {
	componentRestrictions: { country: 'us' },
	fields: ['formatted_address', 'address_components'],
};

export const DEFAULT_SEARCH_OPTIONS: SearchOptions = {
	entity: SearchEntity.Provider,
	searchType: SearchType.Free,
	params: {
		page: 0,
		address: '',
		distance: '25',
		networkStructure: NetworkStructure.SINGLE,
	},
};

export const retrieveValueByEntity = (value: string, entity: SearchEntity, ribbonEntities): string => {
	switch (entity) {
		case SearchEntity.Provider:
			return ribbonEntities.ribbonServices.find((service) => service.ribbon.key === value)?._id;
		case SearchEntity.Procedure:
			return ribbonEntities.ribbonProcedures.find((procedure) => procedure.displayName === value)?.uuid;
		case SearchEntity.Facility:
			return ribbonEntities.locationTypes?.find((location) => location.text === value)?.value;
		case SearchEntity.Treatment:
			return ribbonEntities.ribbonTreatments.find((treatment) => treatment.label === value)?.label;
		case SearchEntity.Condition:
			return ribbonEntities.ribbonConditions.find((condition) => condition.label === value)?.label;
		case SearchEntity.SearchByNeed:
			return ribbonEntities.searchByNeeds.find((location) => location.text === value)?.value;
	}
};

export const DEFAULT_FORM_VALUES: SearchFields = {
	address: '',
	searchType: null,
	value: '',
	entity: null,
	page: 1,
	distance: DEFAULT_SEARCH_OPTIONS.params.distance,
	gender: null,
	language: null,
	minRating: null,
	outOfNetwork: false,
	onlineBookingOnly: false,
	rbpApproved: null,
	mnFavoriteSearch: true,
};

export const ATTRIBUTES_LABELS = {
	TOP_SPECIALTIES: 'top specialties',
	MORE_SPECIALTIES: 'More Specialties (A-Z)',
	SPECIALTIES: 'specialties',
	FACILITIES: 'facilities',
	AUTOCOMPLETE: 'providers by name',
	TREATMENTS: 'treatments',
	CONDITIONS: 'conditions',
	MENTAL_HEALTH: 'mental health',
	SEARCH_BY_NEED: 'search by need',
	SEARCH_BY_GROUP_NAME: 'search by group name',
};

export const DEFAULT_MPI_LOCATION: string = '11365 Ventura Blvd Studio City, CA 91604';

export const normalizeQueryParams = (params: Params) => ({
	...DEFAULT_FORM_VALUES,
	...filterOutNils({
		entity: SearchEntity[params.entity],
		searchType: SearchType[params.searchType],
		page: Number(params.page),
		gender: params.gender,
		address: params.address,
		value: params.value,
		outOfNetwork: !(isNil(params.outOfNetwork) || params.outOfNetwork === 'false'),
		distance: params.distance,
		language: params.language || params.mpiLanguage,
		minRating: Number(params.minRating),
		onlineBookingOnly: params.onlineBookingOnly,
		insuranceGroup: params.insuranceGroup,
		rbpApproved: params.rbpApproved,
		mnFavoriteSearch: params.mnFavoriteSearch,
	}),
});

export const isValidSearchParams = (params: Partial<SearchFields>): boolean => {
	return (
		!isNil(SearchEntity[params.entity]) &&
		!isNil(SearchType[params.searchType]) &&
		!isNil(params.address) &&
		!isNil(params.value)
	);
};

export const mapProviderLocations = (provider: ProviderDetails, locationUuid: string): ProviderDetails => {
	if (!locationUuid) return provider;
	const selectedLocation = provider.locations.find((location) => location?.uuid === locationUuid);
	provider.locations.splice(provider.locations.indexOf(selectedLocation), 1);
	provider.locations.unshift(selectedLocation);
	return provider;
};

export const isSubStrStartWith = (value: string, searchText: string): boolean => {
	if (value?.toLocaleLowerCase().startsWith(searchText?.toLocaleLowerCase())) return true;

	let isWordContainingSearchText: boolean = false;
	const allWords = value.split(' ');

	allWords.forEach((word) => {
		if (word?.toLocaleLowerCase().startsWith(searchText?.toLocaleLowerCase())) {
			isWordContainingSearchText = true;
		}
	});

	return isWordContainingSearchText;
};

export const DEFAULT_RESULTS: ProvidersSearchResults = {
	entity: null,
	page: 0,
	records: [],
	pageSize: 20,
	totalCount: 0,
	isInNetwork: null,
	networkStructure: NetworkStructure.SINGLE,
};
