import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { capitalizeFirstLetter, getDomainNameFromUrl } from '../../../../../utils/utils';
import { RibbonDoctor } from '../../../../../models/ribbon-data.model';

import { UIService } from '../../../../../services/ui.service';
import { HealtheeDialogService } from '../../../../../services/healthee-dialog.service';
import { TrackingService } from '../../../../../services/tracking.service';

import { HealtheeDialogData } from '../../../../app-shared/healthee-dialog/healthee-dialog.component';
import { RibbonHealthCardMobileComponent } from './ribbon-health-card-mobile/ribbon-health-card-mobile.component';
import { Maybe } from '../../../../../utils/types/maybe';
import { CommonComponent } from '../../../../../utils/components/common-component';
import { InputOf } from '../../../../../utils/input-reflector/input-of';
import { RibbonServiceCoverage } from './helpers/ribbon-service-coverage';

@Component({
	selector: 'app-ribbon-health-card-list',
	templateUrl: './ribbon-health-card-list.component.html',
	styleUrls: ['./ribbon-health-card-list.component.scss'],
})
export class RibbonHealthCardListComponent extends CommonComponent implements OnInit {
	@Input()
	isLoading: Maybe<boolean> = false;

	@Input()
	hasLoadMore: Maybe<boolean> = false;

	@Input()
	serviceCoverage: Maybe<RibbonServiceCoverage>;

	@Output()
	loadMoreClick = new EventEmitter<void>();

	@Input() openAsDialog: boolean;
	@Input() rawRibbonCardData: RibbonDoctor[];
	@Output() currentCardData = new EventEmitter<RibbonDoctor>();

	public ribbonCardData: RibbonDoctor[] = [];

	constructor(private dialogService: HealtheeDialogService, private trackingService: TrackingService) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'rawRibbonCardData'];
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.subsBag.add = this.inputs.one('rawRibbonCardData').subscribe({
			next: (rawRibbonCardData) => {
				// TODO: Deligate this data-manipulation method to the service that retrieves the Ribbon data, in a reactive way
				this.ribbonCardData = rawRibbonCardData
					.map((cardData: RibbonDoctor) => this.generalCorrections(cardData))
					.map((cardData: RibbonDoctor) => this.capitalizeFirstLetterInLanguages(cardData))
					.map((cardData: RibbonDoctor) => this.correctGender(cardData));
				// .map((cardData: RibbonCardData) => this.addAverageCost(cardData));
			},
		});
	}

	private generalCorrections(cardData: RibbonDoctor): RibbonDoctor {
		const fullName = cardData.first_name + ' ' + cardData.middle_name + '. ' + cardData.last_name;
		const specialtyDisplayNames = cardData.specialties
			.filter((specialty) => specialty.is_primary)
			.map((specialty) => specialty.display);

		const onlineProfiles = cardData.online_profiles.map((item) => ({
			name: getDomainNameFromUrl(item.url).toUpperCase(),
			url: item.url,
		}));

		return {
			...cardData,
			fullName,
			specialtyDisplayNames,
			online_profiles: onlineProfiles,
		};
	}

	private capitalizeFirstLetterInLanguages(cardData: RibbonDoctor) {
		const capitalizedLanguages = cardData.languages.map((lang) => capitalizeFirstLetter(lang));
		return {
			...cardData,
			languages: capitalizedLanguages,
		};
	}

	// TODO: Deligate this data-manipulation method to the service that retrieves the Ribbon data
	private correctGender(cardData: RibbonDoctor) {
		const originalGender = cardData.gender;

		cardData.gender = originalGender === 'f' ? 'Female' : originalGender === 'm' ? 'Male' : null;

		return cardData;
	}

	private addAverageCost(cardData: RibbonDoctor) {
		let totalCost = 0;
		let totalProcedures = 0;

		cardData.procedures.forEach((procedure) => {
			if (procedure.cost?.cost_index) {
				totalCost = +procedure.cost.cost_index;
				totalProcedures++;
			}
		});
		const averageCost = totalCost / totalProcedures;

		return {
			...cardData,
			averageCost,
		};
	}

	public onMoreInfoClicked(cardData: RibbonDoctor) {
		this.trackingService.trackClientEvent('Provider card more info click', { source: 'Care & Costs' });
		if (!this.openAsDialog) {
			this.currentCardData.emit(cardData);
		} else {
			const shouldOpenFullHeight = UIService.isTabletDeviceScreen(window);

			const options: HealtheeDialogData = {
				hasCloseButton: false,
				title: '',
				component: RibbonHealthCardMobileComponent,
				data: {
					cardData,
					displayFullDetailsCard: true,
				},
				fullHeight: shouldOpenFullHeight,
				componentOnly: true,
				noPadding: true,
			};
		}
	}
}
