import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { MedicalConditionsResponse } from '../../api/employee-api/helpers/medical-conditions.response';
import { EmployeeApiService } from '../../api/employee-api/employee-api.service';
import { Maybe } from '../../../utils/types/maybe';
import { map, Observable, shareReplay } from 'rxjs';
import { isNil } from '../../../utils/is/is-nil';
import { medicalConditionServiceCount } from './helpers/medical-condition-service-count';
import { medicalConditionServices } from './helpers/medical-condition-services';
import { serviceConditions } from './helpers/service-conditions';

@Injectable({
	providedIn: 'root',
})
export class MedicalConditionsStoreService extends Store<void, MedicalConditionsResponse[]> {
	constructor(private employeeApiService: EmployeeApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<MedicalConditionsResponse[]>> {
		return this.employeeApiService.medicalConditions();
	}

	orderedByServicesCount() {
		return this.get().pipe(
			map((conditions) => {
				if (isNil(conditions)) {
					return [];
				}
				const res = [...conditions];
				res.sort((a, b) => medicalConditionServiceCount(a) - medicalConditionServiceCount(b));
				return res;
			}),
			shareReplay(1)
		);
	}

	conditionServices(): Observable<Map<string, Set<string>>> {
		return this.get().pipe(map(medicalConditionServices), shareReplay(1));
	}

	serviceConditions(): Observable<Map<string, Map<string, MedicalConditionsResponse>>> {
		return this.get().pipe(map(serviceConditions), shareReplay(1));
	}

	serviceConditionsNamesStr(): Observable<Map<string, string>> {
		return this.serviceConditions().pipe(
			map((conditions) => {
				const res = new Map<string, string>();
				for (const serviceId of conditions.keys()) {
					let concatedCondition = '';
					for (const condition of conditions.get(serviceId).values()) {
						concatedCondition += condition.name.toLowerCase() + ' ';
						for (const synonym of condition.synonyms) {
							concatedCondition += synonym + ' ';
						}
					}
					res.set(serviceId, concatedCondition);
				}
				return res;
			}),
			shareReplay(1)
		);
	}

	byId(id: string): Observable<Maybe<MedicalConditionsResponse>> {
		return this.get().pipe(
			map((conditions) => (isNil(conditions) ? null : conditions.find((condition) => condition._id === id))),
			shareReplay(1)
		);
	}
}
