<div class="personalize-tracker-container">
	<div class="title">{{'Personalize your Care Tracker' | translate}}</div>
	<div class="subtitle">{{'We will personalize your recommended preventive care visits based on your age and sex' | translate}}</div>

	<form [formGroup]="editInfoForm" (ngSubmit)="onInfoSubmit()">
		<fieldset>
				<div class="label">{{'Date of birth *' | translate}}</div>
				<mat-form-field class="date-picker">
					<input
						[readonly]="isIonic"
						matInput
						(click)="picker.open()"
						class="date-picker-input fs-unmask"
						autocomplete="off"
						[matDatepicker]="picker"
						formControlName="dateOfBirth"
						[min]="minDate"
						[max]="maxDate"
					/>
					<mat-datepicker-toggle matSuffix [for]="picker" class="healthee-blue"></mat-datepicker-toggle>
					<mat-datepicker #picker startView="multi-year"></mat-datepicker>
				</mat-form-field>

				<div class="label gender-label">{{'Sex (at birth) *'| translate}}</div>
				<app-healthee-radio-group
					id="gender"
					[name]="'gender'"
					[buttons]="chooseGenderRadios"
					(valueChanged)="onGenderButtonChange($event)"
				></app-healthee-radio-group>
		</fieldset>
		<fieldset>
		<div class="btns-wrapper">
			<ripple-button
				[label]="'Access your Care Tracker' | translate"
				size="sm"
				hierarchy="primary"
				isFullWidth="true"
				type="submit"
				[isDisabled]="!editInfoForm.valid"
			></ripple-button>

			<ripple-button
				[label]="'Continue without providing these details' | translate"
				size="sm"
				hierarchy="link"
				isFullWidth="true"
				type="button"
				(click)="onContinueWithoutProvidingDetails()"
			></ripple-button>

		</div>
		</fieldset>
	</form>

</div>
