import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ICON_SIZE } from "ripple";

@Component({
	selector: "app-gipitpot-input",
	templateUrl: "./gipitpot-input.component.html",
	styleUrls: ["./gipitpot-input.component.scss"],
})
export class GipitpotInputComponent {

	@ViewChild('chatInput') chatInput: ElementRef;
	@Output() public handleUserInput = new EventEmitter<string>();

	public userInput: string;

	public onUserInput(): void {
		if (this.userInput) {
			this.handleUserInput.emit(this.userInput);
		}
	}

	public resetInput(): void {
		this.userInput = '';
	}

	public focus(): void {
		this.chatInput.nativeElement.focus();
	}

	protected readonly ICON_SIZE = ICON_SIZE;
}
