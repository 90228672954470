import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealtheeContentSliderComponent } from './healthee-content-slider.component';

@NgModule({
	declarations: [HealtheeContentSliderComponent],
	imports: [CommonModule],
	exports: [HealtheeContentSliderComponent],
})
export class HealtheeContentSliderModule {}
