import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { isNil } from '../is/is-nil';
import { InputOf } from './input-of';

export function reflectChanges<Component, Input extends InputOf<Component> = InputOf<Component>>(
	subject: BehaviorSubject<Component[Input]>,
	prop: Input,
	changes: SimpleChanges
) {
	// @ts-ignore
	const val = changes[prop];
	if (!isNil(val)) {
		subject.next(val.currentValue);
	}
}
