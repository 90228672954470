import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZocdocInfo } from '../../../models/zocdoc-data.model';
import { Store } from '../../../utils/store/store';
import { AppointmentService } from '../../appointment.service';

@Injectable({
	providedIn: 'root',
})
export class ZocdocInfosStoreService extends Store<string, ZocdocInfo> {
	constructor(private appointmentService: AppointmentService) {
		super();
	}

	protected retrieve(providerNpi: string): Observable<ZocdocInfo> {
		return this.appointmentService.getProviderInfosForScheduling(providerNpi);
	}
}
