import { Component, OnInit } from '@angular/core';

import { TrackingService } from '../../services/tracking.service';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { ButtonHierarchy, ButtonSize, RippleModule } from 'ripple';
import { TxNativeModule } from '@transifex/angular';
import CFG from 'src/app/config/app-config.json';

export interface InactivityDialogAction {
	shouldLogout: boolean;
}

const ONE_SECOND = 1000;

@Component({
	selector: 'app-inactivity-dialog',
	templateUrl: './inactivity-dialog.component.html',
	styleUrls: ['./inactivity-dialog.component.scss'],
	standalone: true,
	imports: [RippleModule, TxNativeModule],
})
export class InactivityDialogComponent extends HealtheeDialogContent implements OnInit {
	autoLogoutCounter: number = CFG.behavior.sessionInactivityNotificationCountdownInSeconds;
	counterInterval;

	protected readonly ButtonHierarchy = ButtonHierarchy;
	protected readonly ButtonSize = ButtonSize;

	constructor(private trackingService: TrackingService) {
		super();
	}

	ngOnInit() {
		this.trackingService.trackClientEvent('Inactivity Dialog - Open');
		this.startLogoutCountdown();
	}

	private startLogoutCountdown() {
		this.counterInterval = setInterval(() => {
			this.autoLogoutCounter--;

			if (this.autoLogoutCounter <= 0) {
				this.trackingService.trackClientEvent('Inactivity Dialog - Logout by timeout');
				clearInterval(this.counterInterval);
				const doLogout: InactivityDialogAction = { shouldLogout: true };
				this.confirmDialog.emit(doLogout);
			}
		}, ONE_SECOND);
	}

	closeDialogAndLogout() {
		clearInterval(this.counterInterval);
		this.trackingService.trackClientEvent('Inactivity Dialog - Logout by user');
		const doLogout: InactivityDialogAction = { shouldLogout: true };
		this.confirmDialog.emit(doLogout);
	}

	closeDialogAndStayConnected() {
		clearInterval(this.counterInterval);
		this.trackingService.trackClientEvent('Inactivity Dialog - Stay Connected');
		const stayConnected: InactivityDialogAction = { shouldLogout: false };
		this.confirmDialog.emit(stayConnected);
	}
}
