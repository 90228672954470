import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { RibbonDoctor, RibbonProvidersInputs, RibbonResponse, ServiceType } from '../../../models/ribbon-data.model';

import { HealtheeDialogContent } from '../healthee-dialog/healthee-dialog.component';

import { RibbonHealthService } from '../../../services/ribbon.service';
import { TrackingService } from '../../../services/tracking.service';

const MAX_NUMBER_OF_PROVIDERS_TO_DISPLAY = 50; // This must be correlated to the max number on the server

@Component({
	selector: 'app-address-bar-dialog',
	templateUrl: './address-bar-dialog.component.html',
	styleUrls: ['./address-bar-dialog.component.scss'],
})
export class AddressBarDialogComponent extends HealtheeDialogContent implements OnInit, OnDestroy {
	private serviceId: string;
	private lookupAddress: string = '';

	public dataIsAvailable: boolean = null;
	public ribbonProviders: RibbonDoctor[] = null;

	public isLoading$ = new BehaviorSubject<boolean>(null);
	private ribbonRequestSub: Subscription;

	constructor(private ribbonService: RibbonHealthService, private trackingService: TrackingService) {
		super();
	}

	ngOnInit() {
		this.serviceId = this.data?.serviceId;
	}

	public onCloseModalClicked() {
		this.resetView();
		this.confirmDialog.emit(false);
	}

	public onAddressChanged(address: string) {
		this.resetView();

		this.lookupAddress = address;

		const options: RibbonProvidersInputs = {
			address: this.lookupAddress,
			serviceId: this.serviceId,
			serviceType: ServiceType.Healthee,
		};

		this.isLoading$.next(true);

		this.ribbonRequestSub = this.ribbonService
			.getRibbonProvidersList(options)
			.subscribe((ribbonResponse: RibbonResponse) => {
				this.isLoading$.next(false);
				this.dataIsAvailable = true;
				this.setRibbonProviders(ribbonResponse?.providers?.data);
			});
	}

	private setRibbonProviders(ribbonCardData: RibbonDoctor[]) {
		this.ribbonProviders = ribbonCardData && ribbonCardData.length ? ribbonCardData : null;
	}

	public onSearchCleared() {
		this.resetView();
	}

	public getProvidersLengthDisplay() {
		return RibbonHealthService.getProvidersLengthDisplay(
			this.ribbonProviders.length,
			MAX_NUMBER_OF_PROVIDERS_TO_DISPLAY
		);
	}

	public onShowResultsClicked(): void {
		this.confirmDialog.emit({ ribbonProviders: this.ribbonProviders, query: this.lookupAddress });
	}

	private resetView() {
		this.cancelPreviousRequest();
		this.clearData();
	}

	private cancelPreviousRequest() {
		this.isLoading$.next(false);
		this.ribbonRequestSub?.unsubscribe();
	}

	private clearData() {
		this.lookupAddress = '';
		this.dataIsAvailable = null;
		this.ribbonProviders = null;
	}

	ngOnDestroy(): void {
		this.cancelPreviousRequest();
	}
}
