<div class="tabs-container">
	<ul class="tabs-header">
		<li
			*ngFor="let tab of tabs; let i = index"
			(click)="selectTabFromTemplate($event, tab)"
			[class.active]="!!tab.isActive"
			class="tab-header"
			[routerLink]="tab.link"
		>
			<a href="#">{{ tab.title }}</a>
		</li>
	</ul>

	<!-- tab content goes here -->
	<div class="tabs-content">
		<ng-content></ng-content>
	</div>
</div>
