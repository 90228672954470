import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetPathPipe } from './get-path.pipe';

@NgModule({
	declarations: [GetPathPipe],
	imports: [CommonModule],
	exports: [GetPathPipe],
})
export class GetPathPipeModule {}
