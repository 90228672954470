import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { Gender, PROVIDER_ICON_PATH } from '../helpers/providers.helpers';

@Component({
	selector: 'app-add-to-favorites-success-dialog',
	templateUrl: './add-to-favorites-success-dialog.component.html',
	styleUrls: ['./add-to-favorites-success-dialog.component.scss'],
})
export class AddToFavoritesSuccessDialogComponent extends HealtheeDialogContent implements OnInit {
	get iconPath() {
		return PROVIDER_ICON_PATH[this.data.provider.gender || Gender.Male];
	}

	constructor() {
		super();
	}

	ngOnInit(): void {}
}
