import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'displayTypeAndAmount',
	pure: true,
})
export class DisplayTypeAndAmount implements PipeTransform {
	transform(value: any): string {
		if (value.type === '$') {
			return '$' + value.amount;
		} else if (value.type === '%') {
			return value.amount + '%';
		}
	}
}
