import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { FeedbackBlockComponent } from 'src/app/modules/standalone/feedback/feedback-block/feedback-block.component';
import { FeedbackEmotionsModule } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.module';

@NgModule({
	declarations: [FeedbackBlockComponent],
	imports: [CommonModule, TxNativeModule, FeedbackEmotionsModule],
	exports: [FeedbackBlockComponent],
})
export class FeedbackBlockModule {}
