import { Component, Input } from '@angular/core';
import { ReferralInfoStatus } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-status';

@Component({
	selector: 'app-referral-status',
	templateUrl: './referral-status.component.html',
	styleUrls: ['./referral-status.component.scss'],
})
export class ReferralsStatusComponent {
	public statuses = ReferralInfoStatus;

	@Input() value: ReferralInfoStatus;
	@Input() alignRight: boolean = false;
}
