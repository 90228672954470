import { DEFAULT_RESULTS } from "./providers-search.helpers";

export type MixDataType = {
	data: any[],
	parameters: { total_count: number },
	isInNetwork?: boolean | null
};

export const isEntityInNetwork = (entity?: MixDataType): boolean => entity?.isInNetwork === true;
export const isEntityOutOfNetwork = (entity?: MixDataType): boolean => entity?.isInNetwork === false;

export const updateMixData = (mix: MixDataType, data: any[], totalCount: number): void => {
	mix.data.push(...data);
	mix.parameters.total_count += totalCount;
};

export const handleInNetwork = (mix: MixDataType, entities: MixDataType[]): void => {
	entities.forEach(entity => {
		if (isEntityInNetwork(entity)) {
			updateMixData(mix, entity.data, entity.parameters.total_count);
		}
	});
};

export const handleOutOfNetwork = (mix: MixDataType, entities: MixDataType[]): void => {
	mix.isInNetwork = false;
	entities.forEach(entity => {
		if (isEntityOutOfNetwork(entity)) {
			updateMixData(mix, entity.data, entity.parameters.total_count);
		}
	});
};

export const handleUncertainNetwork = (mix: MixDataType, entities: MixDataType[]): void => {
	mix.isInNetwork = null;
	entities.forEach(entity => {
		updateMixData(mix, entity.data, entity.parameters.total_count);
	});
};

export const getDataMixed = (allData) => {
	const mixData = {
		data: [],
		isInNetwork: false,
		parameters: { ...allData.facility.parameters }
	};

	mixData.parameters.total_count = 0;
	mixData.parameters.isLastPage = isLastPage(allData.facility.parameters) && isLastPage(allData.provider.parameters);

	mixData.isInNetwork = allData.provider?.isInNetwork || allData.facility?.isInNetwork;

	if (mixData.isInNetwork) {
		handleInNetwork(mixData, [allData.facility, allData.provider]);
	} else if (isEntityOutOfNetwork(allData.provider) || isEntityOutOfNetwork(allData.facility)) {
		handleOutOfNetwork(mixData, [allData.facility, allData.provider]);
	} else {
		handleUncertainNetwork(mixData, [allData.facility, allData.provider]);
	}

	mixData.data.sort((a, b) => a.distance - b.distance);
	mixData.data = mixData.data.slice(0, DEFAULT_RESULTS.pageSize);

	return mixData;
}

export const isLastPage = (parameters): boolean => {
	return parameters.page * parameters.page_size >= parameters.total_count;
}

export const PAGE_SIZE_PER_ENTITY = 10;
export const EMERGENCY_MEDECINE_SERVICE_ID = '60e3286c54823252fe1b8f05';
