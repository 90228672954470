import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { isHostMPI } from 'src/app/utils/utils';
import CFG from 'src/app/config/app-config.json';

@Injectable({
	providedIn: 'root',
})
export class MPIGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(): boolean | UrlTree {
		if(!isHostMPI()) {
			return this.router.createUrlTree([CFG.defaultAppRoute]);
		}

		return true;
	}
}
