import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { Benefit } from '../../../models/benefits.model';
import { Observable, of } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { GalleryApiService } from '../../api/gallery-api/gallery-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BenefitStoreService extends Store<string, Benefit> {
	constructor(private galleryApiService: GalleryApiService) {
		super();
	}

	protected retrieve(benefitId: string): Observable<Maybe<Benefit>> {
		return this.galleryApiService.service(benefitId).pipe(catchError(() => of(null)));
	}
}
