import { Component, Input, OnInit } from '@angular/core';
import { Maybe } from '../../../../utils/types/maybe';
import { CommonComponent } from '../../../../utils/components/common-component';
import { map, Observable, shareReplay } from 'rxjs';
import { combineLatest } from 'rxjs';
import { isNil } from '../../../../utils/is/is-nil';
import { InputOf } from '../../../../utils/input-reflector/input-of';

@Component({
	selector: 'app-medical-spending-connected-deductible-card',
	templateUrl: './medical-spending-connected-deductible-card.component.html',
	styleUrls: ['./medical-spending-connected-deductible-card.component.scss'],
})
export class MedicalSpendingConnectedDeductibleCardComponent extends CommonComponent implements OnInit {
	@Input()
	isRed: Maybe<boolean>;

	@Input()
	hasMyselfBadge: Maybe<boolean>;

	@Input()
	hasFamilyBadge: Maybe<boolean>;

	@Input()
	total: Maybe<number>;

	@Input()
	spend: Maybe<number>;

	@Input()
	remaining: Maybe<number>;

	@Input()
	outOfPocketSpend: Maybe<number>;

	@Input()
	outOfPocketTotal: Maybe<number>;

	spendPercentage$: Observable<number>;

	constructor() {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		this.total = this.total || 0;
		this.spend = this.spend || 0;
		this.remaining = this.remaining || 0;
		this.outOfPocketSpend = this.outOfPocketSpend || 0;
		this.outOfPocketTotal = this.outOfPocketTotal || 0;
		return [...super.reflectInputs(), 'total', 'spend', 'remaining'];
	}

	override ngOnInit(): void {
		super.ngOnInit();

		const total$ = this.inputs.one('total');
		const spend$ = this.inputs.one('spend');

		this.spendPercentage$ = combineLatest([total$, spend$]).pipe(
			map(([total, spend]) => (isNil(total) || isNil(spend) ? 0 : Math.ceil((100 * spend) / total))),
			shareReplay(1)
		);
	}
}
