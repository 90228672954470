import { Maybe } from '../../../../../../utils/types/maybe';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from '../../../../../../utils/components/form-control-component';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	forwardRef,
	Injector,
	Input,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { isArray } from '../../../../../../utils/is/is-array';
import { ArrayUtils } from '../../../../../../utils/array';

/**
 * Component will use 100% of the width of the parent element
 * @param  items (Input, unknown[]) - an array of elements to display as chips.
 * @param  options (Input, chipsOptions) - configuration object in order to adjust the chips as needed.
 * @param  keyField (Input, Maybe<string>) - Defined the keyField of the chip element that will signals the selected chips and that will be the chipsController value.
 * @param  displayField (Input, Maybe<string>) - the field of the chip element which the component will display as a chip representation.
 */

export interface ChipsOptions {
	multiple: boolean;
	selectable: boolean;
	chipColor?: string;
	chipWidth?: string;
	chipHeight?: string;
	chipTextColor?: string;
	chipBorder?: string;
	fontSize?: string;
	borderRadius?: string;
}

@Component({
	selector: 'app-chip-list-form-control',
	templateUrl: './chip-list-form-control.component.html',
	styleUrls: ['./chip-list-form-control.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChipListFormControlComponent),
			multi: true,
		},
	],
})
export class ChipListFormControlComponent<T extends string | number | symbol> extends FormControlComponent<
	Array<T> | T
> {
	@ContentChild('item')
	itemTemplate: TemplateRef<any>;

	@ViewChild('chipsInput')
	chipsInput: ElementRef<HTMLInputElement>;

	@Input()
	items: unknown[];

	@Input()
	options: ChipsOptions;

	@Input()
	keyField?: Maybe<string> = 'key';

	@Input()
	displayField?: Maybe<string> = 'label';

	selectedChips = new Set<T>();

	constructor(private injector: Injector, private changeDetectorRef: ChangeDetectorRef) {
		super(injector);
	}

	toggle(item: unknown) {
		const value = item[this.keyField];
		const isMultiple = this.options.multiple;

		if (this.selectedChips.has(value)) {
			this.selectedChips.delete(value);
			this.notifyOutside();
			this.changeDetectorRef.markForCheck();
			return;
		}
		if (!isMultiple) {
			this.selectedChips.clear();
		}
		this.selectedChips.add(value);
		this.notifyOutside();
		this.changeDetectorRef.markForCheck();
	}

	private notifyOutside() {
		const isMultiple = this.options.multiple;
		const values = ArrayUtils.fromSet(this.selectedChips);
		if (isMultiple) {
			this.changeValue(values);
			return;
		}
		this.changeValue(values.length === 0 ? null : values[0]);
	}

	protected valueReceived(val: Array<T> | T) {
		this.selectedChips.clear();
		if (isArray(val)) {
			val.forEach((v) => this.selectedChips.add(v));
			this.changeDetectorRef.markForCheck();
			return;
		}
		this.selectedChips.add(val);
		this.changeDetectorRef.markForCheck();
	}
}
