import { Component, ViewEncapsulation, AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { IconsComponent } from '../icons/icons.component';

@Component({
	selector: 'ripple-skeleton-loader',
	templateUrl: './skeleton-loader.component.html',
	styleUrls: ['./skeleton-loader.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SkeletonLoaderComponent implements AfterContentInit {
	@ContentChildren(ButtonComponent, { descendants: true })
	buttonComponents: QueryList<ButtonComponent>;

	@ContentChildren(IconsComponent, { descendants: true })
	iconComponents: QueryList<IconsComponent>;

	ngAfterContentInit() {
		this.buttonComponents.forEach((buttonComponent) => {
			buttonComponent.isSkeletonLoader = true;
		});

		this.iconComponents.forEach((iconComponent) => {
			iconComponent.isSkeletonLoader = true;
		});
	}
}
