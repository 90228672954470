import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';
import CFG from '../../../config/app-config.json';

import { RxjsUtils } from '../../../utils/rxjs';
import { TelehealthApiService } from './telehealth-api.service';

import { HealtheeDialogData } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { QuickAccessLocalize } from '../../home-page/teasers/quick-access/quick-access.localize';
import { TelehealthWidgetComponent } from '../telehealth-widget/telehealth-widget.component';
import { HealtheeDialogService } from 'src/app/services/healthee-dialog.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Injectable({
	providedIn: 'root',
})
export class TelehealthService {
	constructor(
		private telehealthApiService: TelehealthApiService,
		private _sanitizer: DomSanitizer,
		private http: HttpClient,
		private dialogService: HealtheeDialogService,
		private trackingService: TrackingService,

	) {}

	getTelehealthSsoUrl(): Observable<SafeUrl> {
		return this.telehealthApiService.fetchTelehealthSsoUrl().pipe(
			shareReplay(1),
			RxjsUtils.isNotNil(),
			map((response) =>
				this._sanitizer.bypassSecurityTrustResourceUrl(
					this._sanitizer.sanitize(SecurityContext.URL, response.data.toString())
				)
			),
			catchError((response) => throwError(() => response.error.message))
		);
	}

	createTelehealthUser(uid: string): Observable<boolean> {
		return this.http.post<boolean>(CFG.apiEndpoints.telehealthCreateUser, { uid });
	}

	onOpenTelehealth(): void {
		const options: HealtheeDialogData = {
			title: QuickAccessLocalize.telehealthDialogTitle,
			hasCloseButton: true,
			fullHeight: true,
			fullWidth: true,
			noPadding: true,
			disableClose: true,
			component: TelehealthWidgetComponent,
		};
		this.dialogService
			.open(options)
			.subscribe(() =>
				this.trackingService.trackClientEvent('Teleheatlh modal closed', { source: 'Quick Access' })
			);
	}
}
