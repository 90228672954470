import { Pipe, PipeTransform } from '@angular/core';

import { BenefitSummaryModel } from '../models/benefit-summary.model';

@Pipe({ name: 'filterBenefitsByProperty', pure: false })
export class FilterBenefitsByProperty implements PipeTransform {
	// TODO: Update this description below
	/** 'filterBenefitsByProperty' Pipe - filters an array of objects, for objects with properties (filteringProperty) containing a certain value (filteringValue).
	 * Usage in html: *ngFor="let message of messages$ | async | filterBenefitsByProperty:'isRead':filterBooleanValue".
	 * This filter can be disabled, by setting 'filterIsEnabled' to false, for example: *ngFor="let message of zoeMessages$ | async | filterBenefitsByProperty:'isRead':false:enableFilter"
	 * @param  {any[]} benefitsArray - array of objects to filter
	 * @param  {any} filteringProperty - the property of objects in array, to check its value
	 * @param  {any} filteringValue - the value to filter
	 * @param  {boolean=true} filterIsEnabled - enable/disable filter. Defaults to true.
	 * @returns string
	 */
	transform(
		benefitsArray: BenefitSummaryModel[],
		filteringProperty: any,
		filteringValue: any,
		inactiveFilterOnValue: any = '',
		filterIsEnabled: boolean = true
	): BenefitSummaryModel[] {
		if (!benefitsArray || !filteringProperty || !filterIsEnabled || filteringValue === inactiveFilterOnValue)
			return benefitsArray;

		return benefitsArray.filter((item) =>
			item[filteringProperty].toLowerCase().includes(filteringValue.toLowerCase())
		);
	}
}
