import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInfo } from '@capacitor/app';
import { AlertController } from '@ionic/angular';

import CFG from '../../../../config/app-config.json';
import packageJson from '../../../../../../package.json';
import { environment } from '../../../../../environments/environment';
import { resolveHost } from '../../../../utils/utils';

import { UIService } from '../../../../services/ui.service';
import { MobileAppService } from '../../../../services/mobile-app.service';
import { AppManagerService } from '../../../../services/app-manager.service';

@Component({
	selector: 'app-version-label',
	templateUrl: './app-version-label.component.html',
	styleUrls: ['./app-version-label.component.scss'],
})
export class AppVersionLabelComponent implements OnInit {
	@Input() showBundleId: boolean = false;
	@Input() forceProductionEnvInfo: boolean = CFG.development.forceDisplayingEnvironmentInfoInProduction;
	@Input() showPopup: boolean = false;
	@Input() allowChangingHost: boolean = false;

	public webAppVersion: string = packageJson.version;
	public apiVersion: string = null;
	public ionicAppInfo$: Observable<AppInfo>;
	public hostUrl = resolveHost();
	public showDeveloperMenu = false;
	public env = environment;
	public isDevelopmentOrLocalEnv = !environment.production;

	private clicksCounter = 0;

	constructor(
		private mobileAppService: MobileAppService,
		private uiService: UIService,
		public alertController: AlertController,
		private appManager: AppManagerService
	) {}

	ngOnInit(): void {
		this.ionicAppInfo$ = this.mobileAppService.ionicAppInfo$;
	}

	public countClicksOnVersionCaption() {
		if (this.isDevelopmentOrLocalEnv) {
			this.unlockDeveloperMode();
			return;
		}

		this.mobileAppService.developerMode$.pipe(take(1)).subscribe((activated) => {
			if (activated) {
				this.showDeveloperActionSheet();
				this.resetClicksCounter();
			}
		});

		if (this.clicksCounter === CFG.behavior.clicksToShowAPIVersion) {
			this.showAPIVersion();
		}

		if (this.clicksCounter === CFG.behavior.clicksToOpenDeveloperMenu) {
			this.resetClicksCounter();
			this.askForPasswordToUnlockDeveloperMode();
			return;
		}

		if (this.clicksCounter > CFG.behavior.clicksToOpenDeveloperMenu) {
			this.resetClicksCounter();
			return;
		}

		if (this.clicksCounter >= CFG.behavior.clicksToDisplayNotificationBeforeOpeningDeveloperMenu) {
			const clicksRemaining = CFG.behavior.clicksToOpenDeveloperMenu - this.clicksCounter;
			const youreAlmostThereMessage = `You're ${clicksRemaining} clicks from from dev menu`;
			const MESSAGE_DISPLAY_DURAION = 500;

			this.uiService.displayAppMessage(youreAlmostThereMessage, '', null, 'top', MESSAGE_DISPLAY_DURAION);
		}

		this.clicksCounter++;
	}

	private async askForPasswordToUnlockDeveloperMode() {
		const UNLOCK_BUTTON_ROLE = 'unlock';

		const passwordPrompt = await this.alertController.create({
			backdropDismiss: false,
			header: 'Developer Mode',
			message: 'Enter password to unlock developer mode:',
			inputs: [
				{
					name: 'password',
					type: 'password',
					placeholder: 'Password',
					cssClass: 'developer-mode-input',
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
				},
				{
					text: 'Unlock',
					role: UNLOCK_BUTTON_ROLE,
				},
			],
		});

		await passwordPrompt.present();
		const { data, role } = await passwordPrompt.onDidDismiss();

		if (role !== UNLOCK_BUTTON_ROLE) return;

		if (data?.values?.password === CFG.development.developerModePassword) this.unlockDeveloperMode();
	}

	private unlockDeveloperMode() {
		this.mobileAppService.developerModeActivated = true;
		this.showDeveloperActionSheet();
	}

	private showDeveloperActionSheet() {
		this.mobileAppService.developerMode$.pipe(take(1)).subscribe((activated) => {
			if (activated) {
				this.showDeveloperMenu = true;
				this.showAPIVersion();
			}
		});
	}

	private resetClicksCounter() {
		this.clicksCounter = 0;
	}

	private showAPIVersion() {
		this.appManager.getAPIVersion().subscribe((version) => (this.apiVersion = version));
	}

	public dismissDeveloperMenu() {
		this.showDeveloperMenu = false;
	}

	public lockDeveloperMode() {
		this.dismissDeveloperMenu();
		this.mobileAppService.developerModeActivated = false;
	}
}
