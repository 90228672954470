import { Component, Input, OnInit } from '@angular/core';
import { Contract } from '../../../../models/contract.model';
import { PlanType } from '../../../../models/plan-type.model';
import { isNil } from '../../../../utils/is/is-nil';
import { Maybe } from '../../../../utils/types/maybe';

@Component({
	selector: 'app-empty-card',
	templateUrl: './empty-card.component.html',
	styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent implements OnInit {
	@Input()
	planType: Maybe<PlanType>;

	public planTypeText: string;

	constructor() {}

	ngOnInit(): void {
		this.planTypeText = isNil(this.planType)
			? 'Plan is not available'
			: this.planType[0].toUpperCase() + this.planType.slice(1) + ' plan is not available';
	}
}
