import { Component, HostBinding, Input } from '@angular/core';

/**
 * standard - on mobile non-outlined on desktop outlined
 * outlined - always outlined
 */
type HomePageTeaserAppearance = 'standard' | 'outlined';

/**
 * A general component for the home page, change it's style on diferant screen sizes.
 * Related Components: This component is a wrapper and it works with content projection. There are two other related components:
 *   HomePageTeaserHeaderComponent and HomePageTeaserBodyComponent. If you choose to work with all of the home page section components, you'll get all the basic styles you need.
 * Custom content: If you need custom content, it is possible to project whatever type of content instead of the header or the body or both.
 * Custom styling: To style this component it is possible to add a class directly to component's element.
 *
 * @param  {HomePageTeaserAppearance} appearance - is the style of the component, the value is applyed as a class to the host element of the component.
 */
@Component({
	selector: 'app-home-page-teaser',
	templateUrl: './home-page-teaser.component.html',
	styleUrls: ['./home-page-teaser.component.scss'],
})
export class HomePageTeaserComponent {
	@Input() appearance: HomePageTeaserAppearance = 'standard';

	@HostBinding('class') get classes() {
		return this.appearance;
	}

	constructor() {}
}
