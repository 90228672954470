import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const TELEHEALTH_SSO_URI = '/telehealth/sso';

interface TelehealthResponse {
	data?: string;
	message?: any;
}

@Injectable({
	providedIn: 'root',
})
export class TelehealthApiService {
	constructor(private http: HttpClient) {}

	fetchTelehealthSsoUrl(): Observable<TelehealthResponse> {
		return this.http.post<TelehealthResponse>(TELEHEALTH_SSO_URI, {});
	}
}
