import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
	selector: '[rippleSkeletonLoader]',
})
export class SkeletonLoaderDirective implements OnInit {
	@Input() rippleSkeletonLoader: 'avatar' | 'title' | 'textShort' | 'textFull';

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnInit() {
		this.removeContent(this.el.nativeElement);
		this.renderer.addClass(this.el.nativeElement, 'skeleton');
		this.renderer.addClass(this.el.nativeElement, this.rippleSkeletonLoader + '-loading');
	}

	private removeContent(element: any) {
		while (element.firstChild) {
			this.renderer.removeChild(element, element.firstChild);
		}
	}
}
