import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
	@Input() title: string = 'Tab Title';
	@Input() isActive: boolean = false;
	@Input() link: string;

	constructor() {}

	ngOnInit(): void {}
}
