<div *ngIf="providerData.reviews?.length" class="reviews">
	<div class="title" [ngPlural]="providerData.reviews.length">
		<ng-template ngPluralCase="one">
			{{ 'Review' | translate }}
		</ng-template>
		<ng-template ngPluralCase="other">
			{{ 'Reviews' | translate }} ({{ providerData.reviews.length }})
		</ng-template>
	</div>

	<div class="reviews-disclaimer">
		{{
			'The ratings and reviews are aggregated from established review platforms such as Google, Healthgrades, WebMD and more.'
				| translate
		}}
	</div>

	<div class="reviews-list">
		<app-mpi-provider-review
			*ngFor="let review of providerData.reviews.slice(0, reviewCount)"
			[review]="review"
		></app-mpi-provider-review>
		<ripple-button
			*ngIf="hasMoreReviews()"
			(click)="showMoreReviews()"
			label="{{ 'Show more reviews' | translate }}"
			hierarchy="tertiary"
		>
		</ripple-button>
	</div>
</div>
