import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import CFG from '../config/app-config.json';
import { ZoeMessage } from '../models/zoe-message.model';

@Injectable({
	providedIn: 'root',
})
export class ZoeMessagesService {
	private _zoeMessages$: BehaviorSubject<ZoeMessage[]> = new BehaviorSubject<ZoeMessage[]>([]);

	constructor(private http: HttpClient) {}

	get zoeMessages$() {
		return this._zoeMessages$.asObservable().pipe(
			map((zoeMessages: ZoeMessage[]) => this.filteroutDeleted(zoeMessages)),
			map((zoeMessages: ZoeMessage[]) => this.sortByDateSentDescending(zoeMessages))
		);
	}

	get totalMessagesCount$() {
		return this.zoeMessages$.pipe(map((msgArray) => msgArray.length));
	}

	get unreadMessagesCount$() {
		const filterDeleted = (msg: ZoeMessage) => !msg.isDeleted; // not really needed because deleted messages are filtered at the source observable!
		const filterUnread = (msg: ZoeMessage) => !msg.isRead;

		return this.zoeMessages$.pipe(map((msgArray) => msgArray.filter(filterDeleted).filter(filterUnread).length));
	}

	get readMessagesCount$() {
		const filterRead = (msg: ZoeMessage) => msg.isRead;
		return this.zoeMessages$.pipe(map((msgArray) => msgArray.filter(filterRead).length));
	}

	private filteroutDeleted(zoeMessages: ZoeMessage[]): ZoeMessage[] {
		return zoeMessages.filter((zoeMessage: ZoeMessage) => !zoeMessage.isDeleted);
	}

	private sortByDateSentDescending(zoeMessages: ZoeMessage[]): ZoeMessage[] {
		return zoeMessages.sort((msg1, msg2) => {
			const sentDate1: Date = new Date(msg1.dateSent);
			const sentDate2: Date = new Date(msg2.dateSent);

			return sentDate2.getTime() - sentDate1.getTime();
		});
	}

	private handleUpdateMessageStatusFailure(id: string, error: Error) {
		this.markStoreMessageAsUnread(id); // get it back to unread in case it failed to update status on server.
		throw error; // continue handling the error by the global app-error-handler
	}

	private handleDeleteMessageStatusFailure(id: string, error: Error) {
		this.markStoreMessageAsUndeleted(id);
		throw error;
	}

	private markStoreMessageAsRead(id: string) {
		this.changeStoreMessageReadStatus(id, true);
	}

	private markStoreMessageAsUnread(id: string) {
		this.changeStoreMessageReadStatus(id, false);
	}

	private markStoreMessageAsUndeleted(id: string) {
		this.changeStoreMessageDeletionStatus(id, false);
	}

	private markStoreMessageAsDeleted(id: string) {
		this.changeStoreMessageDeletionStatus(id, true);
	}

	private changeStoreMessageReadStatus(id: string, isRead: boolean) {
		this.zoeMessages$.pipe(take(1)).subscribe((messages) => {
			const index = messages.findIndex((msg) => msg._id === id);
			messages[index].isRead = isRead;
			this._zoeMessages$.next(messages);
		});
	}

	private changeStoreMessageDeletionStatus(id: string, isDeleted: boolean) {
		this.zoeMessages$.pipe(take(1)).subscribe((messages) => {
			const index = messages.findIndex((msg) => msg._id === id);
			messages[index].isDeleted = isDeleted;
			this._zoeMessages$.next(messages);
		});
	}
}
