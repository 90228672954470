import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, shareReplay } from 'rxjs';
import { Store } from '../../../utils/store/store';
import { ReferraceInfoApiService } from '../../api/referrals-info-api/referrance-info-api.service';
import { ReferralsInfoResponse } from '../../api/referrals-info-api/helpers/referrals-info-api.response';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { HttpRequestProgressStatus } from 'src/app/utils/http-response/http-response';

@Injectable({
	providedIn: 'root',
})
export class ReferraceinfoStoreService extends Store<void, ReferralsInfoResponse[]> {
	private _requestStatus = new BehaviorSubject<HttpRequestProgressStatus>(HttpRequestProgressStatus.Pending);

	public constructor(private referraceinfoApiService: ReferraceInfoApiService) {
		super();
	}

	public retrieve(): Observable<ReferralsInfoResponse[]> {
		this._requestStatus.next(HttpRequestProgressStatus.Loading);

		return this.referraceinfoApiService.getReferrals().pipe(
			RxjsUtils.isNotNil(),
			map((months) => {
				this._requestStatus.next(HttpRequestProgressStatus.Success);

				return months.map((month) => {
					return {
						...month,
						CreatedDate: new Date(month.CreatedDate),
						DateReferred: new Date(month.DateReferred),
						DateApproved: new Date(month.DateApproved),
						StartDate: new Date(month.StartDate),
						ExprDate: new Date(month.ExprDate),
						LastModifiedDate: new Date(month.LastModifiedDate),
					};
				});
			}),
			map((result) => result?.slice().sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime())),
			catchError(() => {
				this._requestStatus.next(HttpRequestProgressStatus.Error);
				return of([]);
			})
		);
	}

	public getReferralsData(): Observable<ReferralsInfoResponse[]> {
		return this.get().pipe(shareReplay(1));
	}

	public downloadReferral(referralCode: string): Observable<any> {
		return this.referraceinfoApiService.downloadReferral(referralCode);
	}

	get isLoading$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Loading));
	}

	get isSuccess$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Success));
	}

	get isFailed$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Error));
	}

	get isPending$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Pending));
	}
}
