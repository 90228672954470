import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { IntercomService } from '../../../../../../services/intercom.service';
import { ProvidersSearchService } from '../../../../../../services/providers-search/providers-search.service';
import { FavoriteProvidersStoreService } from '../../../../../../services/stores/favorite-providers-store/favorite-providers-store.service';
import { TrackingService } from '../../../../../../services/tracking.service';
import { Maybe } from '../../../../../../utils/types/maybe';
import { providerCardToFavoriteProvider } from '../../../../../my-care-team/helpers/providers.helpers';
import { MyCareTeamService } from '../../../../../my-care-team/services/my-care-team.service';
import { Provider, ProviderGender } from '../../../helpers/providers.helpers';
import { ProvidersSearchResults, SearchEntity, SearchOptionsParams } from '../../../helpers/providers-search.helper';
import { UIService } from 'src/app/services/ui.service';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ButtonHierarchy, ICON_SIZE } from 'ripple';
import { NetworkStructure } from 'src/app/models/chat.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { SearchBarService } from 'src/app/services/providers-search/search-bar.service';

@Component({
	selector: 'app-provider-search-results',
	templateUrl: './provider-search-results.component.html',
	styleUrls: ['./provider-search-results.component.scss'],
})
export class ProviderSearchResultsComponent implements OnDestroy {
	@Input() showMobileMapView: boolean;
	@Output() outOfNetworkSearch = new EventEmitter<object>();
	ProviderGender = ProviderGender;
	SearchEntity = SearchEntity;
	private _unsubscribe = new Subject<void>();

	public resultsInNetwork$: Observable<Maybe<boolean>> = this._providersSearchService.isResultsInNetwork$;
	public activeLocationIndex$: Observable<number> = this._providersSearchService.activeLocationIndex$;
	public disablePanTo$: Observable<boolean> = this._providersSearchService.disabledPanTo$;
	public showActiveLocationCard$: Observable<boolean> = this._providersSearchService.showActiveLocationCard$;
	public favoriteProvidersByNpi$ = this.favoriteProvidersStore.getFavoriteProvidersByNpi();
	public providersLocations$ = this._providersSearchService.providersLocations$;
	public results$ = this._providersSearchService.results$;
	public entityType$ = this._providersSearchService.entityType$;
	public searchParams$: Observable<SearchOptionsParams> = this._providersSearchService.searchParams$;
	public isSuccess$ = this._providersSearchService.isSuccess$;
	public isPending$ = this._providersSearchService.isPending$;
	public isLoading$: Observable<boolean> = this._providersSearchService.showLoader$;
	private isDataFromStore$: Observable<boolean> = this._providersSearchService.isDataFromStore$;
	public showAppointmentDrawer: boolean = false;
	public selectedProvider?: Provider;
	public showOonLoader: boolean;
	public showNoResultsMessage: boolean;
	public isPrintingInProgress = false;
	public readonly ButtonHierarchy = ButtonHierarchy;
	public readonly NetworkStructure = NetworkStructure;
	public readonly ICON_SIZE = ICON_SIZE;
	public networkStructure$: Observable<NetworkStructure> = this.userPlanDataStoreService.getPlansNetworkStructure();
	public isRbpApproved$: Observable<boolean> = this.searchBarService._rbpNetworkSearch$;
	public rbpNetworkSearch$: BehaviorSubject<boolean> = this.searchBarService._rbpNetworkSearch$;

	constructor(
		private _providersSearchService: ProvidersSearchService,
		private myCareTeamService: MyCareTeamService,
		private favoriteProvidersStore: FavoriteProvidersStoreService,
		private intercomService: IntercomService,
		private trackingService: TrackingService,
		private uiService: UIService,
		private router: Router,
		private route: ActivatedRoute,
		public providersSearchUrlsService: ProvidersSearchUrlsService,
		private elementRef: ElementRef,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private searchBarService: SearchBarService
	) {}

	public onProviderCardClick(): void {
		this.trackingService.trackClientEvent('PS - Card: More info page', {
			Source: 'Care & Costs',
			cardType: 'Provider',
		});
	}

	public async setActiveLocationIndex(provCardindex: number): Promise<void> {
		this.providersLocations$.pipe(take(1)).subscribe((locations) => {
			const locationIndex = locations.findIndex((loc) => loc.providerIndex === provCardindex);
			this._providersSearchService.setActiveLocationIndex(locationIndex, { disablePanTo: true });
		});
	}

	public goToPage(pageNumber: number): void {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { page: pageNumber },
			queryParamsHandling: 'merge',
		});

		this.scrollTo(0);
	}

	private scrollTo(index): void {
		document.getElementsByClassName('card-to-scroll-to')[index]?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'nearest',
		});
	}

	onIsFavoriteChange(isFavorite: boolean, provider: Provider): void {
		const favoriteProviderInput = providerCardToFavoriteProvider(provider);

		if (!isFavorite) {
			this.myCareTeamService.removeFromFavorites(favoriteProviderInput).subscribe({
				next: () => {
					this.trackFavorites(isFavorite, provider);
				},
			});
			return;
		}

		this.myCareTeamService.addToFavorites(favoriteProviderInput);
		this.trackFavorites(isFavorite, provider);
	}

	public trackFavorites(isFavorite, provider) {
		const specialty = provider?.specialties[0];
		const inNetwork = provider?.coverage?.isInNetwork || false;
		const npi = provider?.npi;

		const event = isFavorite ? 'MCT - Provider card: add favorites' : 'MCT - Provider card: remove favorites';
		this.trackingService.trackClientEvent(event, { specialty, inNetwork, npi });
	}

	public openCreateAppointment(providerData: Provider) {
		this.showAppointmentDrawer = true;
		this.selectedProvider = providerData;
	}

	contactExpert() {
		this.intercomService.openMessenger();
		this.trackingService.trackClientEvent('Provider search contact an expert');
	}

	onLocationChanged(index: number) {
		this.uiService.isMobile$.subscribe((isMobile) =>
			this.trackingService.trackClientEvent('PS - Map: click pins', { isMobile: isMobile })
		);
		this._providersSearchService.setActiveLocationIndex(index);
	}

	async printResults(event: MouseEvent, results: ProvidersSearchResults) {
		event.preventDefault();

		this.isPrintingInProgress = true;

		const elementToPrint = this.elementRef.nativeElement
			.querySelector('app-providers-search-results-print')
			.cloneNode(true);
		elementToPrint.style.display = 'flex';
		document.body.appendChild(elementToPrint);

		this.trackingService.trackClientEvent('PS - Print results list', {
			amount_of_cards: results.records?.length,
			entity: results.entity,
		});

		try {
			const canvas = await html2canvas(elementToPrint, { scale: 3, useCORS: true });
			document.body.removeChild(elementToPrint);

			const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
			const pageWidth = 210; // A4 page width in mm
			const pageHeight = 297; // A4 page height in mm
			const padding = 6; // Padding in mm

			const generatedImageHeight = (canvas.height * pageWidth) / canvas.width;
			const pagesNeeded = Math.ceil(generatedImageHeight / pageHeight);

			const PDF = new jsPDF('p', 'mm', 'a4');

			for (let i = 0; i < pagesNeeded; i++) {
				// The first page already exists, so we don't need to add it
				if (i > 0) PDF.addPage();

				PDF.addImage(
					imageGeneratedFromTemplate,
					'JPEG',
					padding,
					-pageHeight * i + padding + 4 * i,
					pageWidth,
					generatedImageHeight
				);
			}

			// Generate the blob containing the PDF data
			const pdfBlob = PDF.output('blob');
			const pdfUrl = URL.createObjectURL(pdfBlob);
			window.open(pdfUrl, '_blank');

			this.isPrintingInProgress = false;
		} catch (e) {
			this.isPrintingInProgress = false;
		}
	}

	ngOnDestroy() {
		this._unsubscribe.next();
		this._unsubscribe.complete();
		this._providersSearchService.reset();
	}
}
