import { Component, Input } from '@angular/core';
import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';

@Component({
	selector: 'app-referrals-card',
	templateUrl: './referral-card.component.html',
	styleUrls: ['./referral-card.component.scss'],
})
export class ReferralsCardComponent {
	@Input() isTeaserCard: boolean = false;
	@Input() item: ReferralsInfoResponse;
}
