<div *ngIf="(activeAppointments$ | async)?.length > 0" class="appointments-container">
	<h2 class="title">{{ 'Upcoming Appointments' | translate }}</h2>
	<div class="appointments-list-scroll">
		<div class="appointments-list">
			<app-appointment-card
				*ngFor="let appointment of activeAppointments$ | async"
				[appointment]="appointment"
				(appointmentUpdated)="appointmentUpdated()"
			></app-appointment-card>
		</div>
	</div>
</div>
