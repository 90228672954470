import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Subscription } from "rxjs/internal/Subscription";
import { GipitpotCommunicationService } from "../../services/gipitpot/gipitpot-communication.service";
import { BaseGipitpotMessage, GipitpotMessageOwner } from "../../models/gipitpot/gipitpot-messages";
import { GipitpotInputComponent } from "./gipitpot-input/gipitpot-input.component";
import { LoaderAlign } from "ripple";
import { delay } from "../../utils/utils";
import { UserService } from "../../services/user.service";

@Component({
	selector: "app-gipitpot-controller",
	templateUrl: "./gipitpot-controller.component.html",
	styleUrls: ["./gipitpot-controller.component.scss"]
})
export class GipitpotControllerComponent implements OnInit, OnDestroy {

	@ViewChild("inputControlRef") inputControlRef: GipitpotInputComponent;
	@ViewChild("scrollerAnchor") scrollerAnchor: ElementRef;

	public chatDemoMode = true;
	public chatDemoResponses = [
		"I'm sorry to hear that. Let's find a pediatrician in your area who can help. Are you located in zip code 10002?",
		"Great! There are 32 pediatricians in your area. Would you like me to suggest the ones who are available and have good reviews?",
		"Dr. Louis and Dr. John, both in your network, are available today, both have a rating of 4.7. Based on your plan and your deductibles, your cost will be $25 for the visit. Which one would you prefer to see?",
		"Dr. John is available at 2pm, 3:30pm and 5pm, when would you like to go?",
		"Great, I scheduled the appointment - keep in mind that if you need an Xray or an MRI, there are 59 locations around you that are in-network. Prices range between $32 out-of-pocket, to $498, let me know if you need help finding the lowest cost and highest quality location.",
		"You're welcome! If you have any other questions, feel free to ask."
	];
	public chatDemoResponsesIndex = 0;

	public chatBotLoading = false;
	public messages: BaseGipitpotMessage[] = [];

	private sessionId: string;

	// behaviours
	public userInput = new BehaviorSubject<string>(null);
	public userInput$ = this.userInput.asObservable();
	private userInputSubscription: Subscription;

	// protected
	protected readonly LoaderAlign = LoaderAlign;

	constructor(
		private chatCommunicationService: GipitpotCommunicationService,
		private userService: UserService
	) {
	}

	// lifecycle hooks

	ngOnInit(): void {
		this.sessionId = "" + Math.random();

		this.userService.user$.subscribe({
			next: (user) => {
				const name = this.chatDemoMode ? "Guy" : user.firstName;

				const welcomeMessage = {
					id: "" + Math.random(),
					text: `Welcome back ${name}. I hope you are doing well. What can I do for you today?`,
					createdAt: new Date(),
					owner: GipitpotMessageOwner.Bot
				};
				this.messages.push(welcomeMessage);
			}
		});
		//Welcome back,Uzi. I hope you are doing well. What can I do for you today?


		this.userInputSubscription = this.userInput$.subscribe({
			next: async (input: string) => {
				if (this.chatDemoMode) {
					await this.addDemoUserMessage(input);
					return;
				}

				await this.addUserMessage(input);
			}
		});

	}

	ngOnDestroy() {
		this.userInputSubscription.unsubscribe();
	}

	// Public methods

	public handleUserInput(input: string): void {
		this.userInput.next(input);
	}

	public async addDemoUserMessage(input: string): Promise<void> {
		if (input) {

			this.chatBotLoading = true;
			try {
				const message = {
					id: "" + Math.random(),
					text: input,
					createdAt: new Date(),
					owner: GipitpotMessageOwner.User,
					sessionId: this.sessionId
				};
				this.messages.push(message);
				this.scrollChatToBottom().then();

				const rnd = Math.random() * (4100 - 1200) + 1200;
				setTimeout(() => {
					const message = {
						id: "" + Math.random(),
						text: this.chatDemoResponses[this.chatDemoResponsesIndex],
						createdAt: new Date(),
						owner: GipitpotMessageOwner.Bot
					};
					this.messages.push(message);
					this.chatDemoResponsesIndex++;
					this.chatBotLoading = false;
					this.scrollChatToBottom();
				}, rnd);

				this.inputControlRef.resetInput();
				this.inputControlRef.focus();
			} catch (e) {
				console.error(e);
				this.chatBotLoading = false;
			}
		}
	}

	public async addUserMessage(input: string): Promise<void> {
		if (input) {

			this.chatBotLoading = true;
			try {
				const message = {
					id: "" + Math.random(),
					text: input,
					createdAt: new Date(),
					owner: GipitpotMessageOwner.User,
					sessionId: this.sessionId
				};
				this.messages.push(message);
				this.scrollChatToBottom().then();

				await this.chatCommunicationService.sendUserMessage(message).subscribe({
					next: (response: any) => {
						console.log(response);
						const message = {
							...response,
							id: response.id || "" + Math.random(),
							text: response.answer,
							createdAt: new Date(),
							owner: GipitpotMessageOwner.Bot
						};
						this.messages.push(message);
						this.chatBotLoading = false;
						this.scrollChatToBottom();
					}
				});
				this.inputControlRef.resetInput();
			} catch (e) {
				console.error(e);
				this.chatBotLoading = false;
			}
		}
	}

	private async scrollChatToBottom() {
		await delay(100);
		const element = this.scrollerAnchor.nativeElement;
		element.scrollIntoView(true);
	}
}
