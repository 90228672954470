import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { randomString } from '../../../utils/utils';

export interface HealtheeRadioButton<T> {
	value: T;
	text: string;
	isChecked: boolean;
}

@Component({
	selector: 'app-healthee-radio-group',
	templateUrl: './healthee-radio-group.component.html',
	styleUrls: ['./healthee-radio-group.component.scss'],
})
export class HealtheeRadioGroupComponent implements OnInit {
	@Input() name: string;
	@Input() label: string;
	@Input() buttons: HealtheeRadioButton<string>[];
	@Input() isInvalid: boolean = false;
	@Input() emitInitialChecked: boolean = false;
	@Input() clickable: boolean = true;
	@Input() showMoreButtonAfterNb: number;
	@Input() bold: boolean = false;
	@Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

	private uniqueRadioGroupId = randomString(10, { skipLowerCase: true });
	public showMoreButton: boolean = false;
	public moreBtnValue: string = 'more-btn';

	constructor() {}

	ngOnInit(): void {
		this.name = this.name || 'radio';

		if (this.emitInitialChecked) this.emitValueOfInitiallyCheckedRadioButton();

		if (this.showMoreButtonAfterNb) {
			this.addShowMoreButton();
		}
	}

	private emitValueOfInitiallyCheckedRadioButton() {
		const reversedButtonsArray = [...this.buttons].reverse();
		const lastCheckedButton = reversedButtonsArray.find((btn) => btn.isChecked);

		if (!lastCheckedButton) return;

		this.valueChanged.emit(lastCheckedButton.value);
	}

	private addShowMoreButton(): void {
		const showMoreButton: any = {
			text: 'More',
			value: this.moreBtnValue,
			isChecked: false,
		};

		const buttonsWithMoreBtn = [];

		this.buttons.forEach((button, index) => {
			buttonsWithMoreBtn.push(button);
			if (index + 1 === this.showMoreButtonAfterNb) {
				this.showMoreButton = true;
				buttonsWithMoreBtn.push(showMoreButton);
			}
		});
		this.buttons = buttonsWithMoreBtn;
	}

	public getRadioButtonId(index: number) {
		return this.uniqueRadioGroupId + index;
	}

	public onItemChange(value: string) {
		if (value === this.moreBtnValue) {
			this.showMoreButton = false;
		} else {
			this.valueChanged.emit(value);
		}
	}
}
