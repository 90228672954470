<div class="card" [ngClass]="{ 'full-details-card': _isFullDetailsCard }">
	<!-- visible on both card-preview and card-details -->
	<div class="ribbon-card-header horizontal-section">
		<app-in-out-network-status [isInNetwork]="_cardData.isInNetwork"></app-in-out-network-status>

		<span class="distance">
			<img src="../../../../../../../assets/images/icons/location-pin-black.svg" alt="location" />
			{{ _cardData.distance }} {{ 'miles' | translate }}
		</span>

		<span *ngIf="_isFullDetailsCard" class="close-btn" (click)="onCloseModalClicked()">
			<img src="../../../../../../../assets/images/icons/close-btn-round-black.svg" alt="close" />
		</span>
	</div>

	<!-- visible on both card-preview and card-details -->
	<div class="card-summary">
		<div class="title-and-subsitle">
			<div class="name">
				{{ _cardData.first_name + ' ' + _cardData.last_name }}
				<span *ngIf="_cardData.degrees[0]">, {{ _cardData.degrees[0] }}</span>
				<span *ngIf="_cardData.degrees[1]">, {{ _cardData.degrees[1] }}</span>
			</div>

			<div class="gender-and-age">
				<span *ngIf="_cardData.gender" class="gender">{{ _cardData.gender }}</span>
				<span *ngIf="_cardData.gender && _cardData.age">, </span>
				<span *ngIf="_cardData.age" class="age">{{ _cardData.age }}</span>
			</div>
		</div>

		<div class="info horizontal-section aligned-to-top">
			<div class="rating">
				<app-rating-and-cost
					[cardData]="_cardData"
					[rating]="_cardData.ratings_avg"
					[numberOfReviews]="_cardData.ratings_count"
				></app-rating-and-cost>
			</div>

			<div class="gender-and-language">
				<span *ngIf="_cardData.gender" class="gender">{{ _cardData.gender }}</span>
				<span *ngIf="_cardData.gender && _cardData.age" class="separator">, </span>
				<span *ngIf="_cardData.age" class="age">{{ _cardData.age }}</span>

				<span class="languages-label">{{ 'Languages' | translate }}</span>
				<span class="languages-short">{{ languages_short }}</span>
				<span class="languages_long">{{ languages_long }}</span>
			</div>
		</div>
	</div>

	<!-- visible only on card-preview -->
	<div class="horizontal-section action-buttons">
		<button class="bt-primary" (click)="onMoreInfoClicked()">{{ 'More Info' | translate }}</button>

		<button class="bt-secondary call-btn" (click)="onCallClicked($event)" id="callFromCard">
			{{ 'Call' | translate }}
		</button>
	</div>

	<!-- visible only on card-details -->
	<div class="card-detailed-info">
		<div class="provider-info">
			<span class="heading">{{ 'Specialties' | translate }}</span>
			<p>{{ _cardData.specialtyDisplayNames?.length ? _cardData.specialtyDisplayNames : ('N/A' | translate) }}</p>

			<span class="heading">{{ 'Education' | translate }}</span>
			<ul *ngIf="_cardData.educations?.length">
				<li *ngFor="let education of _cardData.educations">
					<span *ngIf="education.type">{{ education.type }} - </span>
					{{ education.education.name }}
				</li>
			</ul>
			<p *ngIf="!_cardData.educations?.length">{{ 'N/A' | translate }}</p>

			<span class="heading">{{ 'Online Profiles' | translate }}</span>
			<ul *ngIf="_cardData.online_profiles?.length">
				<li *ngFor="let profile of _cardData.online_profiles">
					<a target="_blank" [href]="profile.url">{{ profile.name }}</a>
				</li>
			</ul>
			<p *ngIf="!_cardData.online_profiles?.length">{{ 'N/A' | translate }}</p>

			<span class="heading">{{ 'Panel Demographics' | translate }}</span>
			<ul *ngIf="_cardData.online_profiles">
				<li *ngIf="_cardData.panel_demographics?.ages?.length">
					<span class="sub-heading">{{ 'Age' | translate }} - </span>
					<span *ngFor="let age of _cardData.panel_demographics.ages">{{ age }} </span>
				</li>
				<!-- <li *ngIf="_cardData.panel_demographics.sexes">
					<span class="sub-heading">{{ 'Sex' | translate }} - </span>
					<span>{{ _cardData.panel_demographics.sexes }}</span>
				</li> -->
			</ul>
			<p *ngIf="!_cardData.online_profiles">{{ 'N/A' | translate }}</p>
		</div>

		<div class="provider-browser-wrapper" *ngIf="_isFullDetailsCard">
			<app-ribbon-health-providers-browser
				*ngIf="_isFullDetailsCard"
				[providersArr]="_cardData.locations"
				(selectedProvider)="onSelectedProviderChanged($event)"
			></app-ribbon-health-providers-browser>
		</div>
	</div>

	<!-- visible only on card-details -->
	<div class="card-footer">
		<button
			class="bt-regular call-btn"
			[disabled]="!isCallBtnEnabled"
			(click)="onCallClicked($event)"
			id="callFromMoreInfo"
		>
			{{ 'Call' | translate }}
		</button>
	</div>
</div>
