import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-referrals-no-results',
	templateUrl: './referrals-no-results.component.html',
	styleUrls: ['./referrals-no-results.component.scss'],
})
export class ReferralsNoResultComponent {
	@Input() marginTop: boolean = false;

	public constructor() {}
}
