import { Component, OnInit, Input } from '@angular/core';
import { TrackingService } from '../../../../../services/tracking.service';
import { isNil } from '../../../../../utils/is/is-nil';
@Component({
	selector: 'app-quick-access-item',
	templateUrl: './quick-access-item.component.html',
	styleUrls: ['./quick-access-item.component.scss'],
})
export class QuickAccessItemComponent implements OnInit {
	@Input() title: string = '';
	@Input() icon: string = '';
	@Input() additionalIcon: string = '';
	@Input() link: string = '/';
	@Input() analyticEvent: string = '';
	@Input() action?: () => void;

	constructor(private trackingService: TrackingService) {}

	ngOnInit(): void {}

	onSendAnalytics() {
		if (isNil(this.analyticEvent) || this.analyticEvent === '') return;
		this.trackingService.trackClientEvent(this.analyticEvent, { source: 'Quick access' });
	}
}
