import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Observable, take } from 'rxjs';
import { providerCardToFavoriteProvider } from 'src/app/modules/my-care-team/helpers/providers.helpers';
import { MyCareTeamService } from 'src/app/modules/my-care-team/services/my-care-team.service';
import { IntercomService } from 'src/app/services/intercom.service';
import { ProvidersSearchService } from 'src/app/services/providers-search/providers-search.service';
import { FavoriteProvidersStoreService } from 'src/app/services/stores/favorite-providers-store/favorite-providers-store.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { UIService } from 'src/app/services/ui.service';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { SearchEntity } from '../../helpers/providers-search.helper';
import { FacilityDetails, Provider, ProviderDetails } from '../../helpers/providers.helpers';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';

@Component({
	selector: 'app-providers-map-card',
	templateUrl: './providers-map-card.component.html',
	styleUrls: ['./providers-map-card.component.scss'],
})
export class ProvidersMapCardComponent implements OnInit, OnChanges {
	SearchEntity = SearchEntity;

	@Input() providerLocations = [];
	@Input() activeLocationIndex: number;
	@Input() type: SearchEntity;
	@Output() openCreateAppointment = new EventEmitter<ProviderDetails>();

	@ViewChild('cardElement', { static: false }) cardElementRef: ElementRef;
	private cardResizeObserver: ResizeObserver;

	public results: FacilityDetails[] | ProviderDetails[];

	public selectedProvider: ProviderDetails;
	public selectedFacility: FacilityDetails;

	public isFavorite$: Observable<boolean>;
	public sameLocationIndexes: number[];
	public isMobileView: boolean;

	constructor(
		private providersSearchService: ProvidersSearchService,
		private trackingService: TrackingService,
		private favoriteProvidersStoreService: FavoriteProvidersStoreService,
		private myCareTeamService: MyCareTeamService,
		private _uiService: UIService,
		private _intercomService: IntercomService,
		public providersSearchUrlsService: ProvidersSearchUrlsService,
	) {}

	ngOnInit(): void {
		this.initData();
	}

	public initData(): void {
		this.providersSearchService.results$.pipe(RxjsUtils.isNotNil()).subscribe((results) => {
			this.results = results.records;
		});

		this._uiService.isMobile$.subscribe((isMobile) => {
			this.isMobileView = isMobile;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.activeLocationIndex && this.providerLocations.length > 0 && this.results) {
			const selectedIndex = this.providerLocations[this.activeLocationIndex]?.providerIndex;
			const selectedResult = selectedIndex !== null ? this.results[selectedIndex] : null;

			if (!selectedResult) {
				this.selectedProvider = null;
				this.selectedFacility = null;
				this._intercomService.resetIntercomPosition();
				return;
			}

			if (
				this.type === SearchEntity.Provider ||
				this.type === SearchEntity.Treatment ||
				this.type === SearchEntity.Condition
			) {
				this.selectedProvider = selectedResult as ProviderDetails;
				this.selectedFacility = null;
				this.isFavorite$ = this.favoriteProvidersStoreService.isFavorite(this.selectedProvider.npi);
				this.setSameLocationIndexes();
			}

			if (this.type === SearchEntity.Facility) {
				this.selectedFacility = selectedResult as FacilityDetails;
				this.selectedProvider = null;
				this.setSameLocationIndexes();
			}
		}
	}

	ngAfterViewInit() {
		this.observeCardHeight();
	}

	private observeCardHeight() {
		const cardElement = this.cardElementRef.nativeElement;

		this.cardResizeObserver = new ResizeObserver((entries) => {
			if (this.isMobileView) {
				for (const entry of entries) {
					const height = entry.contentRect.height;
					this._intercomService.moveIntercomFromPositionPX(height + 20);
				}
			}
		});

		this.cardResizeObserver.observe(cardElement);
	}

	private setSameLocationIndexes(): void {
		const indexes = [];
		const thisLocation = this.providerLocations[this.activeLocationIndex];

		if (thisLocation) {
			this.providerLocations.map((location, index) => {
				if (location.latitude === thisLocation.latitude && location.longitude === thisLocation.longitude)
					indexes.push(index);
			});
		}

		this.sameLocationIndexes = indexes.reverse();
	}

	public onIsFavoriteChange(isFavorite: boolean, provider: Provider): void {
		const favoriteProviderInput = providerCardToFavoriteProvider(provider);

		if (!isFavorite) {
			this.myCareTeamService
				.removeFromFavorites(favoriteProviderInput)
				.pipe(RxjsUtils.isNotNil(), take(1))
				.subscribe({
					next: () => {
						this.trackFavorites(isFavorite, provider);
					},
				});
			return;
		}

		this.myCareTeamService.addToFavorites(favoriteProviderInput);
		this.trackFavorites(isFavorite, provider);
	}

	public trackFavorites(isFavorite, provider) {
		const specialty = provider?.specialties[0];
		const inNetwork = !!provider?.coverage?.isInNetwork;
		const npi = provider?.npi;

		const eventAction = isFavorite ? 'add' : 'remove';
		const event = `MCT - Provider card:${eventAction} favorites`;
		this.track(event, { specialty, inNetwork, npi });
	}

	private track(event, metaData = {}) {
		this.trackingService.trackClientEvent(event, metaData);
	}

	public onLocationChanged(index: number) {
		this.providersSearchService.setActiveLocationIndex(this.sameLocationIndexes[index], { showCard: true });
	}

	public getSliderButtonsActiveIndex(): number {
		return this.sameLocationIndexes?.findIndex((index) => index === this.activeLocationIndex);
	}

	ngOnDestroy(): void {
		this._intercomService.resetIntercomPosition();
		this.cardResizeObserver?.disconnect();
	}
}
