import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { UIService } from './ui.service';

import {
	HealtheeDialogComponent,
	HealtheeDialogData,
} from '../modules/app-shared/healthee-dialog/healthee-dialog.component';
import { isNil } from '../utils/is/is-nil';

@Injectable({ providedIn: 'root' })
export class HealtheeDialogService {
	private dialogRef: MatDialogRef<HealtheeDialogComponent>;
	private bottomSheetRef: MatBottomSheetRef<HealtheeDialogComponent>;

	constructor(private _bottomSheet: MatBottomSheet, private _dialog: MatDialog) {}

	public close() {
		if (this.dialogRef) {
			this.dialogRef.close();
			return this.dialogRef.afterClosed();
		}

		if (this.bottomSheetRef) {
			this.bottomSheetRef.dismiss();
			return this.bottomSheetRef.afterDismissed();
		}
	}

	public open(options: HealtheeDialogData) {
		const isMobile = UIService.isMobileDeviceScreen(window);

		const mobileStyles: string[] = ['healthee-bottom-sheet'];
		const desktopStyles: string[] = ['healthee-dialog-wrapper'];

		if (options.noPadding) {
			mobileStyles.push('healthee-bottom-sheet-no-padding');
			desktopStyles.push('healthee-dialog-wrapper-no-padding');
		}

		if (isMobile) {
			this.bottomSheetRef = this._bottomSheet.open(HealtheeDialogComponent, {
				data: options,
				panelClass: mobileStyles,
				disableClose: isNil(options.disableClose) ? false : options.disableClose
			});
			return this.bottomSheetRef.afterDismissed().pipe(take(1));
		}

		const config = {
			data: options,
			autoFocus: false,
			panelClass: desktopStyles,
			maxWidth: options.maxWidth
		};

		this.dialogRef = this._dialog.open(HealtheeDialogComponent, config);

		return this.dialogRef.afterClosed().pipe(take(1));
	}
}

