import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-talon-widget-cover',
	templateUrl: './talon-widget-cover.component.html',
	styleUrls: ['./talon-widget-cover.component.scss'],
})
export class TalonWidgetCoverComponent implements OnInit {
	showCover: boolean = true;

	constructor() {}

	ngOnInit(): void {}
}
