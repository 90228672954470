import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ripple-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
	@Input() hint: string = '';
	@Input() tooltip: string = '';
	@Input() isChecked: boolean = false;
	@Input() isUnderlined: boolean = false;
	@Output() toggle = new EventEmitter<boolean>();

	public toggleCheck() {
		this.toggle.emit(!this.isChecked);
	}

	protected readonly toolbar = toolbar;
}
