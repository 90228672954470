<ripple-skeleton-loader>
	<div class="provider-card-loader">
		<div class="header">
			<div rippleSkeletonLoader="avatar"></div>
			<div class="content">
				<div rippleSkeletonLoader="title"></div>
				<div rippleSkeletonLoader="textShort"></div>
			</div>
			<ripple-button leadingIcon="heart" size="xs"></ripple-button>
		</div>

		<div class="body">
			<div class="sub-body">
				<div class="line">
					<ripple-icon name="locationFull" size="size-20"></ripple-icon>
					<div rippleSkeletonLoader="textShort"></div>
				</div>
				<div class="line">
					<ripple-icon name="starFull" size="size-20"></ripple-icon>
					<div rippleSkeletonLoader="textShort"></div>
				</div>
			</div>
		</div>

		<div class="footer">
			<ripple-button leadingIcon="call" label="call"></ripple-button>
		</div>
	</div>
</ripple-skeleton-loader>
