import { SimpleChanges } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { InputOf } from './input-of';
import { reflectChanges } from './reflect-changes';
import { isNil } from '../is/is-nil';
import { ChangesContainer } from './changes-container';

export class InputReflector<Component = unknown> {
	private changes: ChangesContainer<Component> = {};

	constructor(private inputs: Array<InputOf<Component>>) {
		inputs.forEach((inp) => (this.changes[inp] = new BehaviorSubject<any>(null)));
	}

	reflectChanges(changes: SimpleChanges) {
		this.inputs.forEach((field) => {
			const changeSubject = this.changes[field];
			if (!isNil(changeSubject)) {
				reflectChanges(changeSubject, field, changes);
			}
		});
	}

	one<Input extends InputOf<Component>>(input: Input): Observable<Component[Input]> {
		const changes = this.changes[input];
		if (isNil(changes)) {
			return new BehaviorSubject<any>(null);
		}
		return changes;
	}
}
