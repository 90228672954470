import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable, take, combineLatest } from 'rxjs';
import { UserPlanDataStoreService } from '../services/stores/user-plan-data-store/user-plan-data-store.service';
import CFG from '../config/app-config.json';

import { RxjsUtils } from '../utils/rxjs';
import { PlanSelectionStoreService } from '../services/stores/plan-selection-store/plan-selection-store.service';
import { UIService } from '../services/ui.service';

@Injectable({
	providedIn: 'root',
})
export class RedirectGuard  {
	constructor(
		private uiService: UIService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private router: Router
	) {}

	canActivate(): Observable<boolean | UrlTree> {
		return combineLatest([
			this.userPlanDataStoreService.hasContract().pipe(RxjsUtils.isNotNil()),
			this.planSelectionStoreService.isPlanSelectionEnabled(),
		]).pipe(
			take(1),
			map(([hasContract, hasOEAccess]) => {
				if (!hasContract && hasOEAccess) {
					return this.router.createUrlTree([CFG.openEnrollmentRoute]);
				}
				return this.router.createUrlTree([CFG.defaultAppRoute]);
			})
		);
	}
}
