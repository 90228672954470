import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIService } from '../../../services/ui.service';
import { TrackingService } from '../../../services/tracking.service';
import { SubscriptionBag } from '../../../utils/subscription-bag';
import { Observable, Subject, map, takeUntil, tap } from 'rxjs';
import { ExternalApiService } from 'src/app/services/external-api.service';
import { getUrlWoParams } from 'src/app/utils/utils';
import { CompanyStoreService } from '../../../services/stores/company-store/company-store.service';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { MpiPlan } from 'src/app/utils/mpi/mpi.constant';
import { getProviderSearchTitleByPlanName } from 'src/app/utils/mpi/mpi-service-naming.helper';

enum Tab {
	Providers = 'Providers',
	MP3Providers = 'MP3 Providers',
	Services = 'Services',
}

@Component({
	selector: 'app-care-and-costs',
	templateUrl: './care-and-costs.component.html',
	styleUrls: ['./care-and-costs.component.scss'],
})
export class CareAndCostsComponent implements OnInit, OnDestroy {
	Tab = Tab;

	get tabsAlignment(): string {
		return this.isMobileView ? 'center' : 'start';
	}

	public links = {
		[Tab.Providers]: {
			id: Tab.Providers,
			trackingId: 'Provider-Click-Doctors-Tab',
			url: '/care-and-costs/providers',
		},
		[Tab.MP3Providers]: {
			id: Tab.MP3Providers,
			trackingId: 'Provider-Click-Services-Tab',
			url: '/care-and-costs/mp3-providers',
		},
		[Tab.Services]: {
			id: Tab.Services,
			trackingId: 'Provider-Click-Services-Tab',
			url: '/care-and-costs/services',
		},
	};

	public activeTabId: Tab = this.links[Tab.Providers].id;
	private isMobileView = false;
	private subscriptionBag = new SubscriptionBag();
	hasTalonSupport$: Observable<boolean>;
	hasMP3providerSearchSupport$: Observable<boolean>;

	private urlsWithTabs = [
		'/care-and-costs',
		this.links[Tab.Providers].url,
		this.links[Tab.MP3Providers].url,
		this.links[Tab.Services].url,
	];
	shouldShowTabs: boolean = true;

	public hasPricecomparisonToolEnabled: boolean = false;
	private unsubscribe = new Subject<void>();
	userPlan$: Observable<MpiPlan> = this.userPlanDataStoreService.getUserMedicalPlan();

	constructor(
		public userPlanDataStoreService: UserPlanDataStoreService,
		private trackingService: TrackingService,
		private uiService: UIService,
		private externalApiService: ExternalApiService,
		private companyStoreService: CompanyStoreService
	) {
		this.hasTalonSupport$ = this.externalApiService.isUserSupportedByTalon();
		this.hasMP3providerSearchSupport$ = this.userPlanDataStoreService.hasMedicalPlanByAnthem();
	}

	ngOnInit(): void {
		this.isMobileView = UIService.isMobileDeviceScreen(window);
		this.subscriptionBag.add = this.uiService.route$.subscribe({
			next: (url) => {
				const urlWoParams = getUrlWoParams(url);
				this.activeTabId =
					Object.values(this.links).find((link) => link.url === urlWoParams)?.id || Tab.Providers;
				this.shouldShowTabs = this.urlsWithTabs.includes(urlWoParams);
			},
		});

		this.companyStoreService
			.get()
			.pipe(
				tap(
					(company) =>
						(this.hasPricecomparisonToolEnabled =
							company?.showPriceComparisonTool === undefined || company?.showPriceComparisonTool)
				)
			)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe();
	}

	onSelectedTabChange(tabId: Tab) {
		const trackingId = this.links[tabId].trackingId;
		if (trackingId === 'Provider-Click-Services-Tab') {
			this.trackingService.trackClientEvent('Care & Costs Service search start');
		}
	}

	public getTabTitle(): Observable<string> {
		return this.userPlan$.pipe(
			takeUntil(this.unsubscribe),
			map((userPlan) => getProviderSearchTitleByPlanName(userPlan))
		);
	}

	ngOnDestroy(): void {
		this.subscriptionBag.dispose();
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
