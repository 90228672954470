<div class="card">
	<div class="header">
		<div class="icon-with-title">
			<img [src]="iconURL" alt="icon" *ngIf="iconURL" />
			<span class="title">{{ title }}</span>
		</div>
		<ripple-label
			*ngIf="labelText"
			text="{{ labelText | translate }}"
			[colorTheme]="badgeSuccess ? LabelColorTheme.darkTextGreenBg : LabelColorTheme.darkTextRedBg"
		></ripple-label>
	</div>
	<div class="description" *ngIf="description && !preformatted">{{ description | translate }}</div>
	<div class="description" *ngIf="description && preformatted"><pre>{{ description | translate }}</pre></div>
	<div class="children">
		<ng-content></ng-content>
	</div>
</div>
