import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '../../../../services/user.service';
import { UIService } from '../../../../services/ui.service';
import { TrackingService } from '../../../../services/tracking.service';

import { HealtheeDialogContent } from '../../../app-shared/healthee-dialog/healthee-dialog.component';

@Component({
	selector: 'app-change-name-dialog',
	templateUrl: './change-name-dialog.component.html',
	styleUrls: ['./change-name-dialog.component.scss'],
})
export class ChangeNameDialogComponent extends HealtheeDialogContent implements OnInit, OnDestroy {
	private fullnameSub: Subscription;
	public fullName: string;

	public oneWordError: boolean = false;
	public invalidPatternError: boolean = false;
	public isLoading = false;

	constructor(
		private userService: UserService,
		private uiService: UIService,
		private trackingService: TrackingService
	) {
		super();
	}

	ngOnInit(): void {
		this.fullnameSub = this.userService.userFullname$.subscribe((name) => (this.fullName = name)); // TODO: Change to reactive ngModel Directive
	}

	public submitNameChange() {
		const fullname = this.validateInput();

		if (!fullname) return;

		this.isLoading = true;
		this.userService
			.updateUserFullnameOrAvatar(fullname)
			.subscribe(() => this.fullnameUpdatedSuccessfully())
			.add(() => (this.isLoading = false));
	}

	private validateInput() {
		this.oneWordError = this.invalidPatternError = false;

		const WHITESPACE = ' ';
		const MIN_NUMBER_OF_WORDS_IN_FULLNAME = 2;
		// firstanme can't contain spaces, lastname can.
		// John A. Smith'son --> First: John, Last: A. Smith'son
		const regexFirstName = /^[A-Za-z'.-]{2,}$/;
		const regexLastName = /^[A-Za-z'-.\s]{2,}$/;

		const trimmed = this.fullName.trim().replace(/\s+/g, WHITESPACE); // trim whitspaces from the sides and from inside the string
		const names = trimmed.split(WHITESPACE);

		if (names.length < MIN_NUMBER_OF_WORDS_IN_FULLNAME) {
			this.oneWordError = true;
			return false;
		}

		const firstName = names.splice(0, 1)[0]; // return and remove 1st word
		const lastName = names.join(WHITESPACE);

		if (!regexFirstName.test(firstName) || !regexLastName.test(lastName)) {
			this.invalidPatternError = true;
			return false;
		}

		return { firstName, lastName };
	}

	private fullnameUpdatedSuccessfully() {
		this.uiService.displayAppMessage('Your name was updated', 'Ok', 'checkmark-outline');
		this.closeDialog.emit();
	}

	ngOnDestroy() {
		this.fullnameSub?.unsubscribe();
	}
}
