import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RippleComponent } from './ripple.component';

import { ButtonComponent } from './button/button.component';
import { IconsComponent } from './icons/icons.component';
import { LoaderComponent } from './loader/loader.component';
import { SliderButtonsComponent } from './slider-buttons/slider-buttons.component';
import { BadgeComponent } from './badge/badge.component';
import { LabelComponent } from './label/label.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { InputSuccessErrorMessageComponent } from './input-success-error-message/input-success-error-message.component';
import { TabsComponent } from './tabs/tabs.component';
import { InputComponent } from './input/input.component';
import { SkeletonLoaderDirective } from './skeleton-loader/skeleton-loader.directive';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { TxNativeModule } from '@transifex/angular';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TooltipWithTouchModule } from './tooltip-with-touch/tooltip-with-touch.module';

@NgModule({
	declarations: [
		RippleComponent,
		ButtonComponent,
		IconsComponent,
		LoaderComponent,
		SliderButtonsComponent,
		BadgeComponent,
		LabelComponent,
		ProgressBarComponent,
		EmptyStateComponent,
		InputSuccessErrorMessageComponent,
		TabsComponent,
		InputComponent,
		SkeletonLoaderComponent,
		SkeletonLoaderDirective,
  		AutocompleteInputComponent,
    	CheckboxComponent,
	],
  imports: [CommonModule, MatAutocompleteModule, ReactiveFormsModule, FormsModule, TxNativeModule, TooltipWithTouchModule],
	exports: [
		RippleComponent,
		ButtonComponent,
		IconsComponent,
		LoaderComponent,
		SliderButtonsComponent,
		BadgeComponent,
		LabelComponent,
		ProgressBarComponent,
		EmptyStateComponent,
		InputSuccessErrorMessageComponent,
		TabsComponent,
		InputComponent,
		SkeletonLoaderComponent,
		SkeletonLoaderDirective,
		AutocompleteInputComponent,
		CheckboxComponent,
	]
})
export class RippleModule {}
