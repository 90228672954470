<div class="bubble-container">
	<hr />
	<button class="close-btn" (click)="closeDialog()" mat-dialog-close><i class="ri-close-line"></i></button>

	<h3 class="name">{{ config.name }}</h3>
	<h3 class="desc">{{ config.text }}</h3>
	<footer>
		<div class="circle"></div>
		<div class="kav">
			<svg width="375" height="91" viewBox="0 0 375 91" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M375.5 66.5707C355.243 88.4643 291.7 114.449 201.581 41.2412C111.463 -31.9669 29.1443 11.2378 -0.499939 41.7412"
					stroke="#E0E3F7"
				/>
			</svg>
		</div>
	</footer>
</div>
