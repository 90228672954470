<div class="preventive-care-details-card-container">
	<ng-container *ngIf="preventiveCareData$ | async as preventiveCareData; else showLoader">
		<div class="body">
			<div *ngIf="!isFromModal" class="back-btn">
				<ripple-button hierarchy="link" size="sm" leadingIcon="arrowLeft" (click)="goBack()"> </ripple-button>
			</div>

			<div class="card-image-wrapper">
				<img
					*ngIf="this.allServicesStoreService.byId(preventiveCareData.serviceId) | async as service"
					class="card-image"
					[class.is-pinned]="preventiveCareData.isPinned"
					[class.is-completed]="preventiveCareData.completedDate"
					[src]="service?.iconPath | serviceImage"
				/>

				<div *ngIf="preventiveCareData.isPinned" class="is-pinned-icon">
					<ripple-icon name="pin" size="size-32"></ripple-icon>
				</div>

				<div *ngIf="preventiveCareData.completedDate" class="completed-icon">
					<ripple-icon name="check" size="size-32"></ripple-icon>
				</div>
			</div>

			<div class="name">{{ preventiveCareData.name }}</div>

			<div class="frequency">
				{{ 'Recommend for every ' | translate }} {{ preventiveCareData.frequency * 12 }}
				{{ ' months or per doctor recommendation' | translate }}
			</div>

			<div class="action-btns">
				<ng-container *ngIf="!preventiveCareData.completedDate; else showCompletedBadge">
					<ripple-button
						[label]="'Mark as Completed' | translate"
						[isLoading]="buttonsLoadingStates.markAsCompleted"
						hierarchy="tertiary"
						size="sm"
						leadingIcon="tickCircle"
						isFullWidth="true"
						(click)="onMarkAsCompleted(preventiveCareData)"
					>
					</ripple-button>
					<ripple-button
						[label]="preventiveCareData.isPinned ? 'Remove Pin' : ('Put a pin on it!' | translate)"
						[isLoading]="buttonsLoadingStates.pinned"
						hierarchy="tertiary"
						size="sm"
						leadingIcon="pin"
						isFullWidth="true"
						(click)="onPinClick(preventiveCareData.id, preventiveCareData.isPinned)"
					>
					</ripple-button>
				</ng-container>

				<ng-template #showCompletedBadge>
					<app-completed-badge [data]="preventiveCareData"></app-completed-badge>
				</ng-template>
			</div>

			<div class="coverage-wrapper">
				<img src="/assets/icons/icon-wallet-coverage.svg" alt="coverage" />
				<div *ngIf="preventiveCareData.userCoverage" class="coverage-text">
					{{ preventiveCareData.userCoverage }}{{ ' (in-network providers)' | translate }}
				</div>
				<div *ngIf="!preventiveCareData.userCoverage" class="coverage-text">
					{{ 'Coverage information is unavailable' | translate }}
				</div>
			</div>

			<app-next-appointment
				*ngIf="preventiveCareData.completedDate || preventiveCareData.passedCompletedDate"
				[completedDate]="preventiveCareData.completedDate"
				[passedCompletedDate]="preventiveCareData.passedCompletedDate"
				[frequency]="preventiveCareData.frequency"
			>
			</app-next-appointment>

			<div class="short-description">
				<div class="title">{{ 'What is it ?' | translate }}</div>
				<div [innerHTML]="preventiveCareData.shortDescription"></div>
			</div>

			<div class="long-description">
				<div class="title">{{ 'What happens during the visit ?' | translate }}</div>
				<div [innerHTML]="preventiveCareData.longDescription"></div>
			</div>
		</div>

		<div class="find-provider-btn">
			<ripple-button
				[label]="'Find a provider' | translate"
				size="md"
				(click)="onFindAProviderClick(preventiveCareData.serviceId)"
			>
			</ripple-button>
		</div>
	</ng-container>

	<ng-template #showLoader>
		<div class="loader-wrapper">
			<app-loader></app-loader>
		</div>
	</ng-template>
</div>
