<div class="gipitpot-message system" attr.data-test-id="system-message-{{message.id}}" *ngIf="message">
	<img class="system-icon" src="assets/icons/gipitpot/gipitpot-zoe-chat-icon.png" />
	<div class="message-wrapper">
		<p class="msg" *ngIf="!suggestedIntent">{{message.text}}</p>

		<div *ngIf="suggestedIntent" class="gipitpot-intent-wrapper">
			<app-gipitpot-coverage-intent *ngIf="suggestedIntent === GipitpotIntent.Coverage" [message]="message"></app-gipitpot-coverage-intent>
		</div>

	</div>
</div>
