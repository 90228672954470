import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, take, tap } from 'rxjs';
import { AppointmentFetch, AvailabilityData } from '../models/appointment.model';
import { normalizeAvailability } from '../models/availability.model';
import { normalizeZocdocProvider, ZocdocInfoResponse } from '../models/zocdoc-data.model';
import { StoreApi } from '../utils/store/store-api';
import { StoreUpdateRegistryService } from './stores/store-update-registry.service';
import { AppointmentsListStoreToken } from './stores/appointments-list-store/appointments-list-store.token';

const ZOCDOC_URLS: any = {
	getProviderInfos: '/zocdoc/providers',
	getAvailableSlots: '/zocdoc/availability',
	appointments: '/appointment',
};

@Injectable({
	providedIn: 'root',
})
export class AppointmentService extends StoreApi {
	constructor(private http: HttpClient, private storeUpdateRegistryService: StoreUpdateRegistryService) {
		super(storeUpdateRegistryService);
	}

	public getProviderInfosForScheduling(providerNpi: string) {
		const params = new HttpParams({
			fromObject: {
				npis: providerNpi,
			},
		});

		return this.http
			.get<ZocdocInfoResponse>(ZOCDOC_URLS.getProviderInfos, { params })
			.pipe(map((response) => ({ data: normalizeZocdocProvider(response.data), reasons: response.reasons })));
	}

	public getAvailableSlots(data: AvailabilityData) {
		const params = new HttpParams({
			fromObject: {
				providerLocationIds: [data.providerLocationId],
				reason: data.visitReasonId,
				patientType: data.patientType,
			},
		});

		return this.http
			.get(ZOCDOC_URLS.getAvailableSlots, { params })
			.pipe(map((response) => ({ ...normalizeAvailability(response[0]) })));
	}

	public createAppointment(data) {
		const params = { ...data };
		params.reason = params.visitReasonId;
		params.locationId = params.providerLocationId;
		delete params.visitReasonId;
		delete params.providerLocationId;

		return this.http
			.post(ZOCDOC_URLS.appointments, { ...params })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}

	public getAppointments() {
		return this.http.get<AppointmentFetch[]>(ZOCDOC_URLS.appointments);
	}

	public cancelAppointment(params) {
		return this.http
			.put(ZOCDOC_URLS.appointments, { ...params, action: 'cancel' })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}

	public rescheduleAppointment(params) {
		return this.http
			.put(ZOCDOC_URLS.appointments, { ...params, action: 'reschedule' })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}
}
