import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeductiblesCardComponent } from './deductibles-card/deductibles-card.component';

@NgModule({
	declarations: [DeductiblesCardComponent],
	imports: [CommonModule],
	exports: [DeductiblesCardComponent],
})
export class DeductiblesCardModule {}
