<div class="wrapper">
	<div class="grid">
		<div class="header-1">
			<div class="header-1-content">
				<div class="image-container">
					<img
						[ngSrc]="'https://assets.healthee.co/charterschool_logos/' + content.upperLeftLogo + '.png'"
						alt=""
						fill
					/>
				</div>
				<div class="image-container">
					<img
						[ngSrc]="'https://assets.healthee.co/charterschool_logos/' + content.upperRightLogo + '.png'"
						alt=""
						fill
					/>
				</div>
			</div>
		</div>
		<div class="content content-1">
			<ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{ $implicit: userInfo }"></ng-container>
		</div>
		<div class="content content-2">
			<ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{ $implicit: planInfo }"></ng-container>
		</div>
		<div class="content content-3">
			<ng-container
				[ngTemplateOutlet]="list"
				[ngTemplateOutletContext]="{ $implicit: pharmacyInfo }"
			></ng-container>
		</div>
	</div>
</div>

<ng-template #list let-data>
	<div class="member-info">
		<div class="header">
			{{ data.headline }}
		</div>
		<div *ngFor="let item of data.list">
			<div class="list-item" ngClass="type-{{ item.type }}">
				<span *ngIf="item.type === 1" class="property" [ngClass]="{ 'not-bold': item.propertyNotBold }">{{
					item.displayName
				}}</span>
				<a *ngIf="item.type === 2" class="property" [href]="'https://' + item.displayName" target="_blank">{{
					item.displayName
				}}</a>
				<div *ngIf="item.type === 3" (click)="callPhone(item.displayName)" class="property">
					{{ item.displayName }}
				</div>
				<span class="value" [ngClass]="{ smaller: item.valueSmaller }">
					<span *ngIf="item.property === 'effectiveDate'; else default">
						{{ item.value | date : 'MM/dd/yyyy' }}
					</span>

					<ng-template #default>
						<span>{{ item.value }}</span>
					</ng-template></span
				>
				<ng-template #arr>
					<div class="sub-list">
						<span class="value" *ngFor="let val of item.value">{{ val }}</span>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
	<div *ngIf="data.hasContactInfo" class="contact-info">
		<div class="questions-header">Questions?</div>
		<div class="number" (click)="callPhone('1-833-346-1482')">1-833-346-1482</div>
		<a class="web" href="https://www.myQHCC.com" target="_blank">myQHCC.com</a>
	</div>
	<div class="logo" *ngIf="data.logo">
		<img [ngSrc]="'https://assets.healthee.co/charterschool_logos/' + data.logo + '_small.png'" alt="" fill />
	</div>
</ng-template>
