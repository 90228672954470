<div class="stepper-wrapper">
	<div class="stepper-bars">
		<div class="bar colored"></div>
		<div class="bar grey" [style.width.%]="calculateGreyBarWidth()"></div>
		<div class="circle-wrapper">
			<div
				*ngFor="let step of steps; let i; as: index"
				[ngClass]="{ 'active-circle': activeStepCircles(i) }"
				id="{{ i + 1 }}"
				class="circle left"
			></div>
		</div>
	</div>
</div>
