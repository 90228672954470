export interface ZocdocAvailabilityResponse {
	first_availability: ZocdocTimeslotResponse;
	provider_location_id: string;
	timeslots: ZocdocTimeslotResponse[];
}

export interface ZocdocAvailability {
	providerLocationId: string;
	timeslots: ZocdocTimeslot[];
}

export interface ZocdocTimeslotResponse {
	start_time: Date;
	visit_reason_id: string;
}

export interface ZocdocTimeslot {
	startTime: Date;
	visitReasonId: string;
}

export const normalizeAvailability = (availability: ZocdocAvailabilityResponse): ZocdocAvailability => ({
	providerLocationId: availability.provider_location_id,
	timeslots: (availability.timeslots || []).map(normalizeTimeslots),
});

export const normalizeTimeslots = (slot: ZocdocTimeslotResponse): ZocdocTimeslot => ({
	startTime: slot.start_time,
	visitReasonId: slot.visit_reason_id,
});
