import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FeedConfig, DataFromServerForFeed } from '../models/suggestion-feed.model';

import CFG from '../config/app-config.json';
import { getRandomElementFromArray } from '../utils/utils';

const AVATARS_ICONS_PATHS = [
	'../../../../../assets/images/icons/feed-avatar1.svg',
	'../../../../../assets/images/icons/feed-avatar2.svg',
	'../../../../../assets/images/icons/feed-avatar3.svg',
	'../../../../../assets/images/icons/feed-avatar4.svg',
];
const FEED_SWAP_INTERVAL_DEFAULT: number = 5000;
const NUMBER_ELEMENTS_TO_SWITCH_DEFAULT: number = 1;

@Injectable({
	providedIn: 'root',
})
export class FeedService {
	constructor(private http: HttpClient) {}

	public fetchData(): Observable<any> {
		const url: string = CFG.apiEndpoints.suggestionsFeed;

		return this.http.get<DataFromServerForFeed>(url).pipe(
			filter((data) => !!data),
			map((data) => this.initDataForUI(data))
		);
	}

	public getDefaultConfig(): FeedConfig {
		const config: FeedConfig = {
			feedSwapInterval: FEED_SWAP_INTERVAL_DEFAULT,
			numberElementsToSwitch: NUMBER_ELEMENTS_TO_SWITCH_DEFAULT,
		};
		return config;
	}

	private initDataForUI(data: DataFromServerForFeed): DataFromServerForFeed {
		data.suggestions.map((obj) => {
			obj.isSent = false;
			obj.avatarIconPath = this.getRandomAvatarIconPath();
		});
		return data;
	}

	private getRandomAvatarIconPath(): string {
		return getRandomElementFromArray(AVATARS_ICONS_PATHS);
	}
}
