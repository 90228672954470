import { Component, EventEmitter, Output } from '@angular/core';
import { SortingDefaultValue, ReferralSortingOptions } from './referrals-sorting-options';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-referrals-sorting-options',
	templateUrl: './referrals-sorting-options.component.html',
	styleUrls: ['./referrals-sorting-options.component.scss'],
})
export class ReferralsSortingOptionsComponent {
	public options = ReferralSortingOptions;

	public formControllValue = new FormControl<string>(SortingDefaultValue);

	@Output() sorChangedEvent: EventEmitter<string> = new EventEmitter<string>();

	constructor() {}

	onChangeHandler() {
		this.sorChangedEvent.emit(this.formControllValue.value);
	}
}
