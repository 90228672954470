import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchFields } from '../../helpers/providers-search.helper';

@Component({
	selector: 'app-providers-search-empty-state',
	templateUrl: './providers-search-empty-state.component.html',
	styleUrls: ['./providers-search-empty-state.component.scss'],
})
export class ProvidersSearchEmptyStateComponent {
	@Input() defaultLocation: string;
	@Input() isLocationEnabled: boolean;
	@Output() searchEvent: EventEmitter<SearchFields> = new EventEmitter();

	constructor() {}
}
