import { Component, Input } from '@angular/core';
import { HealtheeDialogData } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { FeedbackDialogComponent } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.component';
import { HealtheeDialogService } from 'src/app/services/healthee-dialog.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { Disclaimers, Rating } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.component';
import { FeedbackLocalize } from 'src/app/modules/standalone/feedback/feedback-localize';

@Component({
	selector: 'app-feedback-block',
	templateUrl: './feedback-block.component.html',
	styleUrls: ['./feedback-block.component.scss'],
})
export class FeedbackBlockComponent {
	@Input() feature: string;
	@Input() data: object;
	@Input() logData?: object;
	@Input() headline = FeedbackLocalize.headline;
	@Input() showHeadlineInWidget = true;
	@Input() question = FeedbackLocalize.question;
	@Input() disclaimers?: Disclaimers;

	currentRating: Rating = null;

	constructor(private dialogService: HealtheeDialogService, private trackingService: TrackingService) {}

	public openFeedbackDialog(rating: Rating) {
		this.trackingService.trackClientEvent('Feedback Block Set Rating', { rating, feature: this.feature });
		this.currentRating = rating;

		const options: HealtheeDialogData = {
			hasCloseButton: true,
			component: FeedbackDialogComponent,
			data: {
				rating,
				feature: this.feature,
				question: this.question,
				headline: this.headline,
				data: this.data,
				logData: this.logData,
				disclaimers: this.disclaimers
			},

		};

		this.dialogService.open(options).subscribe((isFeedbackSent) => {
			if(!isFeedbackSent) {
				this.trackingService.trackClientEvent('Feedback Dialog closed without submission', { rating, feature: this.feature });
				this.currentRating = null;
			}
		});
	}
}
