import { Component, Input } from '@angular/core';

@Component({
	selector: 'ripple-input-success-error-message',
	templateUrl: './input-success-error-message.component.html',
	styleUrls: ['./input-success-error-message.component.scss']
})
export class InputSuccessErrorMessageComponent {

	@Input() isSuccess: boolean;
	@Input() messageText: string;

	constructor() { }

}
