import { removeAt } from './remove-at';
import { range } from './range';
import { fromSet } from './from-set';
import { take } from './take';

export const ArrayUtils = {
	removeAt,
	range,
	fromSet,
	take,
};
