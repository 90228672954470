<ripple-input
	class="doctor-selection_search-input"
	[formControl]="control"
	[type]="type"
	[label]="label"
	[leadingIcon]="leadingIcon"
	[placeholder]="placeholder"
	[showResetButton]="true"
	[matAutocomplete]="autocomplete"
	[showLoading]="showLoading"
	[focusOnInit]="focusOnInit"
	[unmaskInSessions]="unmaskInSessions"
	(keydown.enter)="closePanel(); hideLoading()"
>
</ripple-input>

<mat-autocomplete
	#autocomplete="matAutocomplete"
	[class]="autocompleteCssClasses"
	[panelWidth]="panelWidth"
	[displayWith]="displayFn"
	(optionSelected)="selectOption($event)"
>
	<mat-option *ngFor="let option of options$ | async" [value]="option">
		<ng-container *ngTemplateOutlet="optionsTemplate; context: { $implicit: option }"></ng-container>
	</mat-option>
</mat-autocomplete>
