import { Injectable } from '@angular/core';
import { AutocompleteApiService } from '../../api/providers-api/autocomplete-api.service';
import { NonNullStore } from '../non-null-store/non-null-store';

@Injectable({
	providedIn: 'root',
})
export class SearchAutocompleteResultsStoreService extends NonNullStore<any, any> {
	constructor(private autocompleteApiService: AutocompleteApiService) {
		super();
	}

	protected retrieve(fields) {
		return this.autocompleteApiService.search(fields.name, fields.pageSize, fields.address, fields.services);
	}
}
