<mat-radio-group class="field-wrapper">
	<label *ngIf="label" class="field-wrapper-label" [class.variant-bold]="bold">{{ label }}</label>
	<div class="items-wrapper">
		<div
			*ngFor="let button of buttons; let index = index"
			class="radio-item-wrapper"
			[ngClass]="{
				'no-show':
					(index > showMoreButtonAfterNb && showMoreButton) ||
					(!showMoreButton && button.value === moreBtnValue)
			}"
		>
			<input
				type="radio"
				[name]="name"
				class="radio-input"
				[class.variant-invalid]="isInvalid"
				[id]="getRadioButtonId(index)"
				[value]="button.value"
				[checked]="button.isChecked"
				(change)="onItemChange($event.target.value)"
				[disabled]="!clickable"
			/>
			<label [for]="getRadioButtonId(index)" class="radio-label" [ngClass]="{ 'is-active': clickable }">{{
				button.text
			}}</label>
		</div>
	</div>
</mat-radio-group>
