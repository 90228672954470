import { Component, OnInit } from '@angular/core';

import { TrackingService } from '../../../services/tracking.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	constructor(private trackingService: TrackingService) {}

	public panelOpened(num: number) {
		this.trackingService.trackClientEvent('FAQ - Opened', { 'FAQ number': num });
	}

	ngOnInit(): void {}
}
