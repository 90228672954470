import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { PreventiveCareService } from '../../../services/preventive-care.service';
import { isCompletedDatePassed } from '../helpers/helpers';
import { TrackingService } from '../../../services/tracking.service';

@Component({
	selector: 'app-preventive-care-mark-as-completed',
	templateUrl: './preventive-care-mark-as-completed.component.html',
	styleUrls: ['./preventive-care-mark-as-completed.component.scss'],
})
export class PreventiveCareMarkAsCompletedComponent extends HealtheeDialogContent implements OnInit {
	public selectedDate: Date;
	public today: Date = new Date();
	public isSubmitLoading: boolean = false;
	public isClearLoading: boolean = false;
	public showFormText = true;
	public showNextAppointmentText: boolean = false;
	public showPassedNextAppointmentText: boolean = false;

	constructor(private preventiveCareService: PreventiveCareService, private trackingService: TrackingService) {
		super();
	}

	ngOnInit(): void {
		if (this.data.completedDate) {
			this.selectedDate = this.data.completedDate;
		}
	}

	public onMonthSelect(event: string, picker: MatDatepicker<Date>): void {
		this.selectedDate = new Date(event);
		picker.close();
	}

	public onSubmit(): void {
		this.isSubmitLoading = true;
		const isPassedCompletedDate: boolean = isCompletedDatePassed(this.selectedDate, this.data.frequency);

		this.preventiveCareService.saveCompletedDate(this.data.id, this.selectedDate).subscribe(() => {
			this.isSubmitLoading = false;
			this.showFormText = false;
			if (isPassedCompletedDate) {
				this.showPassedNextAppointmentText = true;
			} else {
				this.showNextAppointmentText = true;
			}

			this.trackingService.trackClientEvent('Preventive care - mark as complete', {
				itemName: this.data.name,
				dateSubmitted: formatDate(this.selectedDate, 'MMMM yyyy', 'en-US'),
			});
		});
	}

	public onClear(): void {
		this.isClearLoading = true;

		this.preventiveCareService.clearCompletedDate(this.data.id).subscribe(() => {
			this.isClearLoading = false;
			this.onFinished();
		});
	}

	public onFinished(): void {
		this.confirmDialog.emit(true);
	}
}
