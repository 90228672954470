<div class="banner-card-container">
	<img class="img" [src]="card.img" alt="card image">
	<div class="banner-card-content">
		<div class="title">{{card.title}}</div>
		<div class="description">{{card.description}}</div>
		<ripple-button
			[label]="card.buttonLabel"
			[isFullWidth]="true"
			(click)="card.action()"
			>
		</ripple-button>
	</div>
</div>
