<div class="menu-item-container" (click)="onItemClicked()">
	<div class="header">
		<span class="title">{{ title }}</span>

		<span (click)="onToggleClicked()">
			<ion-toggle
				class="override-disabled-toggle-opacity"
				[disabled]="disabled"
				[(ngModel)]="state"
				(ngModelChange)="onStateChange($event)"
				mode="ios"
			></ion-toggle>
		</span>
	</div>

	<div class="description">{{ description }}</div>
</div>
