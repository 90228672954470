import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { ProviderFavoriteCardComponent } from './favorite-provider-card/favorite-provider-card.component';
import { AddToFavoritesCardComponent } from './add-to-favorites-card/add-to-favorites-card.component';
import { HealtheeContentSliderModule } from '../app-shared/healthee-content-slider/healthee-content-slider.module';
import { RemoveFromFavoritesConfirmationDialogComponent } from './remove-from-favorites-confirmation-dialog/remove-from-favorites-confirmation-dialog.component';
import { AddToFavoritesSuccessDialogComponent } from './add-to-favorites-success-dialog/add-to-favorites-success-dialog.component';

@NgModule({
	declarations: [
		ProviderFavoriteCardComponent,
		AddToFavoritesCardComponent,
		RemoveFromFavoritesConfirmationDialogComponent,
		AddToFavoritesSuccessDialogComponent,
	],
	imports: [CommonModule, HealtheeContentSliderModule, TxNativeModule],
	exports: [ProviderFavoriteCardComponent, AddToFavoritesCardComponent],
})
export class MyCareTeamModule {}
