import { isNil } from '../is/is-nil';

export const filterOutNils: <T>(obj: T) => Partial<T> = <T>(obj: T) => {
	return Object.keys(obj)
		.filter((key) => !isNil(obj[key]))
		.reduce((res, key) => {
			res[key] = obj[key];
			return res;
		}, {});
};
