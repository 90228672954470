import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { AppointmentService } from 'src/app/services/appointment.service';
import { HealtheeDialogService } from 'src/app/services/healthee-dialog.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { T } from '@transifex/angular';
import { CancelAppointmentEvents } from 'src/app/models/tracking-events.model';

@Component({
	selector: 'app-cancel-appointment-modal',
	templateUrl: './cancel-appointment-modal.component.html',
	styleUrls: ['./cancel-appointment-modal.component.scss'],
})
export class CancelAppointmentModalComponent extends HealtheeDialogContent implements OnInit {
	@T('Confirmation: Your appointment has been canceled.')
	successMessage: string;

	@T('Ok')
	successAction: string;

	@T('Oh no, something went wrong, and we can’t cancel the appointment. Please try again later.')
	errorMessage: string;

	@T('Retry')
	errorAction: string;

	constructor(
		private appointmentService: AppointmentService,
		private trackingService: TrackingService,
		private healtheeDialogService: HealtheeDialogService,
		private _snackBar: MatSnackBar
	) {
		super();
	}

	ngOnInit(): void {}

	public onCancel() {
		this.appointmentService
			.cancelAppointment(this.data)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.trackingService.trackClientEvent(CancelAppointmentEvents.CancellationRequested, { success: true });
					this.healtheeDialogService.close();
					this._snackBar.open(this.successMessage, this.successAction, {
						duration: 5000,
						panelClass: 'snackbar-success',
						verticalPosition: 'top',
					});
				},
				error: () => {
					this.trackingService.trackClientEvent(CancelAppointmentEvents.CancellationRequested, { success: false });
					this.healtheeDialogService.close();
					this.showErrorPopUp();
				},
			});
	}

	private showErrorPopUp(): void {
		const snackBarRef = this._snackBar.open(this.errorMessage, this.successAction, {
			duration: 5000,
			panelClass: 'snackbar-failure',
			verticalPosition: 'top',
		});
		snackBarRef.onAction().subscribe(() => {
			this.onCancel();
		});
	}

	public onClose() {
		this.trackingService.trackClientEvent(CancelAppointmentEvents.CancelFlowStopped);
		this.healtheeDialogService.close();
	}
}
