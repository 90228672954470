import { Directive, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

import { SubscriptionBag } from '../subscription-bag';
import { InputReflector } from '../input-reflector/input-reflector';
import { InputOf } from '../input-reflector/input-of';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class CommonComponent implements OnInit, OnDestroy, OnChanges {
	protected readonly subsBag = new SubscriptionBag();
	protected readonly inputs = new InputReflector<this>(this.reflectInputs());

	protected reflectInputs(): Array<InputOf<this>> {
		return [];
	}

	ngOnInit(): void {
		// This is needed to pick default values
		const changes: SimpleChanges = this.reflectInputs().reduce((res, cur) => {
			// @ts-ignore
			res[cur] = new SimpleChange(null, this[cur], false);
			return res;
		}, {} as SimpleChanges);
		this.inputs.reflectChanges(changes);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.inputs.reflectChanges(changes);
	}

	ngOnDestroy(): void {
		this.subsBag.dispose();
	}
}
