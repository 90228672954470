import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';

@Component({
	selector: 'app-service-panel-accordion',
	templateUrl: './service-panel-accordion.component.html',
	styleUrls: ['./service-panel-accordion.component.scss'],
})
export class ServicePanelAccordionComponent extends CommonComponent {
	@Input()
	title: string;

	@Input()
	description: string;

	@Input()
	count: number;

	@Input()
	isExpanded: boolean;

	@Output()
	isExpandedChange = new EventEmitter<boolean>();

	constructor() {
		super();
	}
}
