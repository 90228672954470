import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import CFG from '../../../config/app-config.json';
import { ICompany } from '../../../models/company.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyApiService {
	constructor(private httpClient: HttpClient) {}

	getCompany(): Observable<ICompany> {
		return this.httpClient.get<ICompany>(CFG.apiEndpoints.userCompany);
	}
}
