import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppAuthGuard } from './guards/app-auth.guard';

import { MainLayoutComponent } from './modules/main-layout/main-layout.component';
import { MPIGuard } from 'src/app/guards/mpi.guard';
import { HasUserDataGuard } from 'src/app/guards/has-user-data.guard';
import { PCTOnlyGuard } from './guards/pct-only.guard';
import { LogoutComponent } from './modules/account/logout/logout.component';

const routes: Routes = [
	{
		path: 'activation',
		loadChildren: () =>
			import('./routes/activation-route/activation-route.module').then((m) => m.ActivationRouteModule),
	},
	{
		path: 'account',
		loadChildren: () =>
			import('./modules/account/account.module').then((loadedModule) => loadedModule.AccountModule),
	},
	{
		path: 'logout',
		component: LogoutComponent,
	},
	{
		path: 'providers-search',
		canActivate: [MPIGuard, HasUserDataGuard],
		loadChildren: () =>
			import('./modules/mpi/providers-search/providers-search-page/providers-search-page.module').then(
				(loadedModule) => loadedModule.MPIProvidersSearchPageModule
			),
	},
	{
		path: 'register',
		redirectTo: 'activation',
	},
	{
		path: 'app-update',
		loadChildren: () =>
			import('./modules/version-manager/version-manager.module').then(
				(loadedModule) => loadedModule.VersionManagerModule
			),
	},
	{
		path: 'pct',
		canActivate: [AppAuthGuard, PCTOnlyGuard],
		loadChildren: () =>
			import('./modules/plan-selection/plan-selection.module').then(
				(loadedModule) => loadedModule.PlanSelectionModule
			),
	},
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AppAuthGuard],
		loadChildren: () =>
			import('./modules/main-layout/main-layout.module').then((loadedModule) => loadedModule.MainLayoutModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
