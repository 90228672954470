import { Component, EventEmitter, Output, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-phone-input-component',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit {
	@Input() phone: string;
	@Output() phoneChanged = new EventEmitter<string>();
	@Output() phoneSubmited = new EventEmitter();
	@ViewChild('phoneRef') phoneRef: any;

	public phoneForm: FormGroup;

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.buildPhoneForm();
		this.focusOnInput();
	}

	private buildPhoneForm() {
		this.phoneForm = this.formBuilder.group({
			phone: [this.phone, [Validators.required]],
		});
	}

	private focusOnInput() {
		setTimeout(() => this.phoneRef?.elRef?.nativeElement?.querySelector('input')?.focus());
	}

	public onPhoneSubmit(): void {
		this.phoneSubmited.emit();
	}

	public async pastePhoneFromClipboard() {
		let pastedCode: string = '';

		try {
			pastedCode = await navigator.clipboard.readText();
		} catch (e) {
			console.log('Error pasting code from clipboard:', e.message);
			return;
		}

		this.phoneForm.controls['phone'].setValue(pastedCode);
		this.phoneChanged.emit(pastedCode);
	}
}
