import { Component } from '@angular/core';

@Component({
	selector: 'app-home-page-teaser-body',
	templateUrl: './home-page-teaser-body.component.html',
	styleUrls: ['./home-page-teaser-body.component.scss'],
})
export class HomePageTeaserBodyComponent {
	constructor() {}
}
