<div class="primary-care-specialities-container">
	<h2 class="title">Search Primary Care Providers</h2>
	<div class="primary-care-specialities-wrapper">
		<app-primary-care-specialty
			*ngFor="let specialty of specialties"
			[specialty]="specialty"
			(categoryClick)="onCategoryClick($event)"
			class="primary-care-specialty"
		></app-primary-care-specialty>
	</div>
</div>
