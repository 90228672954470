<div (paste)="pastePhoneFromClipboard()" class="phone-input">
	<form #phoneForm="ngForm" class="phone-form">
		<ngx-mat-intl-tel-input
			[preferredCountries]="['us', 'il']"
			[enablePlaceholder]="true"
			[enableSearch]="true"
			[(ngModel)]="phone"
			(ngModelChange)="phoneChanged.emit($event)"
			[ngModelOptions]="{ standalone: true }"
			#phoneRef
		>
		</ngx-mat-intl-tel-input>
	</form>
</div>
