<app-select-form-control
	class="mr-8"
	[items]="options"
	floatLabel="never"
	valueField="value"
	displayField="name"
	[hasBottomSpace]="false"
	[multi]="false"
	[label]="'Order by'"
	(ngModelChange)="onChangeHandler()"
	[formControl]="formControllValue"
></app-select-form-control>
