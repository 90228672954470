import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({ providedIn: 'root' })
export class LastActivityTrackerService {

  public static readonly LAST_ACTIVE_KEY = "LastActivityTrackerService.lastPause";
  constructor(private platform: Platform) {
    this.platform.pause.subscribe(async () => {
      let value = String(new Date().getTime());
      localStorage.setItem(LastActivityTrackerService.LAST_ACTIVE_KEY, value);
    });
  }

  public onResume(fn: (lastActiveMilli: number) => void) {
    this.platform.resume.subscribe(async () => {
      const lastPause = localStorage.getItem(LastActivityTrackerService.LAST_ACTIVE_KEY);
      const lastActiveMilli = lastPause && new Date().getTime() - parseInt(lastPause);
      fn(lastActiveMilli);
    });
  }

}