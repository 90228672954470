import { Component, Input } from '@angular/core';

import { TrackingService } from '../../../../services/tracking.service';
import { SelectedBenefitsLocalize } from './helpers/selected-benefits.localize';
import { BehaviorSubject } from 'rxjs';
import { Maybe } from '../../../../utils/types/maybe';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../services/language.service';
import { ServicesResponse } from 'src/app/services/api/employee-api/helpers/services.response';
import { ProvidersSearchService } from 'src/app/services/providers-search/providers-search.service';

@Component({
	selector: 'app-selected-benefits-teaser',
	templateUrl: './selected-benefits-teaser.component.html',
	styleUrls: ['./selected-benefits-teaser.component.scss'],
})
export class SelectedBenefitsTeaserComponent {
	SelectedBenefitsLocalize = SelectedBenefitsLocalize;
	@Input() selectedServices: ServicesResponse[] = [];

	selectedServiceId$ = new BehaviorSubject<Maybe<string>>(null);

	constructor(
		private trackingService: TrackingService,
		public router: Router,
		public languageService: LanguageService,
		private _providersSearchService: ProvidersSearchService,
	) { }

	findProvider() {
		const serviceId = this.selectedServiceId$.value;
		this.selectedServiceId$.next(null);
		const referrer = this.router.url;
		setTimeout(() => this._providersSearchService.goToProviderSearchWithServiceId(serviceId, referrer));
	}

	onSelectedBenefitClick(benefitTitle) {
		this.trackingService.trackClientEvent('Home Page Single Benefit Click', { service_name: `${benefitTitle}` });
	}
}
