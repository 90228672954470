import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppInfo } from '@capacitor/app';

import { EnvironmentConfigurationInterface } from '../../../../../environments/environment.interface';

import { BiometryService } from '../../../../services/biometry.service';
import { StorageService } from '../../../../services/storage.service';
import { UIService } from '../../../../services/ui.service';

@Component({
	selector: 'app-mobile-developer-menu',
	templateUrl: './mobile-developer-menu.component.html',
	styleUrls: ['./mobile-developer-menu.component.scss'],
})
export class MobileDeveloperMenuComponent implements OnInit {
	@Input() appInfo: AppInfo;
	@Input() environment: EnvironmentConfigurationInterface;
	@Input() host: string;
	@Input() isOpen: boolean = false;

	@Output() dismissed = new EventEmitter();
	@Output() dismissedAndDeactivated = new EventEmitter();

	public bioAuthData: boolean;
	public fcmToken: string = '';

	constructor(
		private bioService: BiometryService,
		private storageService: StorageService,
		private uiService: UIService,
	) {}

	ngOnInit() {
		this.updateBioAuthSettings();
	}

	public updateBioAuthSettings() {
		// deliberate use of storage service directly and not the bioAuthService observable.
		this.bioAuthData = this.storageService.getBiometrySettings();
	}

	public dismissMenu() {
		this.dismissed.emit();
	}

	public clearBioAuthCacheData() {
		this.bioService.clearBiometryUsage();
		this.storageService.removeBiometrySettings();
		this.uiService.displayAppMessage('Bio auth data is cleared');
	}

	public dismissMenuAndLockDevMode() {
		this.dismissedAndDeactivated.emit();
	}
}
