import { HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

export interface ErrorObjectExtention {
	technicalMessage: string;
	requestVerb: string;
	requestHeaders: HttpHeaders;
	requestUrl: string;
	requestBody: HttpRequest<any>;
	requestParams: HttpParams;
}

export class AppError extends Error {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error,
		public status?: number
	) {
		super(message);

		Object.setPrototypeOf(this, AppError.prototype);
	}
}

// Expected http errors
export class NoConnectionError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, NoConnectionError.prototype);
	}
}
export class ConnectionTimeoutError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, ConnectionTimeoutError.prototype);
	}
}
export class UnknownError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, UnknownError.prototype);
	}
}
export class NotFoundError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, NotFoundError.prototype);
	}
}
export class UnauthorizedError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, UnauthorizedError.prototype);
	}
}
export class BadRequestError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, BadRequestError.prototype);
	}
}
export class InternalServerError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, InternalServerError.prototype);
	}
}

// App specific errors
export class SettingsNotFoundError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, SettingsNotFoundError.prototype);
	}
}

export class RegistrationTokenNotFoundError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, RegistrationTokenNotFoundError.prototype);
	}
}

export class InvalidRegistrationTokenError extends AppError {
	constructor(
		public message: string,
		public errorObjectExtention?: ErrorObjectExtention,
		public originalError?: Error
	) {
		super(message, errorObjectExtention, originalError);

		Object.setPrototypeOf(this, InvalidRegistrationTokenError.prototype);
	}
}

export const CustomErrorCodes: any = {
	CREDENTIALS_NOT_CORRECT: 4011,
	LOCKED_ACCOUNT: 4012,
	ATTEMPTS_REMAINING: 4013,
};
