import { Component, Input, OnInit } from '@angular/core';

const FULL_PROGRESS_BAR_WIDTH = 100;
const FIRST_STEP = 0;
const MINIMAL_NUMBER_OF_STEPS = 2;
/**
 * @param  currentStep - @Input() - the current step
 * @param  totalSteps - @Input() - total steps to complete
 */
@Component({
	selector: 'app-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
	@Input() currentStep: number = 1;
	@Input() totalSteps: number = 2;

	public steps = [];

	constructor() {}

	ngOnInit(): void {
		this.validateInputs();
		this.createSteps();
	}

	ngAfterViewInit() {
		this.calculateGreyBarWidth();
	}

	ngOnChanges() {
		this.currentStep = this.currentStep - 1; //steps starts at 0
		this.calculateGreyBarWidth();
	}

	private validateInputs() {
		this.currentStep < FIRST_STEP ? (this.currentStep = FIRST_STEP) : this.currentStep;
		this.totalSteps < MINIMAL_NUMBER_OF_STEPS ? (this.totalSteps = MINIMAL_NUMBER_OF_STEPS) : this.totalSteps;
		this.currentStep > this.totalSteps ? (this.currentStep = this.totalSteps) : this.currentStep;
	}

	private createSteps() {
		for (let i = 0; i < this.totalSteps + 1; i++) {
			//increments totalSteps by one 1 to present two steps as minimum
			this.steps.push(i);
		}
	}

	public calculateGreyBarWidth() {
		return FULL_PROGRESS_BAR_WIDTH - FULL_PROGRESS_BAR_WIDTH * (this.currentStep / this.totalSteps);
	}

	public activeStepCircles(i: number) {
		return i <= this.currentStep;
	}
}
