import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_RESULTS } from "../services/providers-search/helpers/providers-search.helpers";

@Pipe({
	name: 'approximateProvidersCount'
})
export class ApproximateProvidersCountPipe implements PipeTransform {

	transform(totalCount: number): string {
		if (totalCount <= DEFAULT_RESULTS.pageSize) {
			return totalCount.toString();
		}

		if (totalCount < 100) {
			return totalCount.toString().slice(0, -1) + '0+';
		}

		if (totalCount < 500) {
			return totalCount.toString().slice(0, -2) + '00+';
		}

		return totalCount < 1000 ? '500+' : '1000+';
	}

}
