import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { SlotData } from 'src/app/models/appointment.model';
@Component({
	selector: 'app-appointment-slots-available',
	templateUrl: './appointment-slots-available.component.html',
	styleUrls: ['./appointment-slots-available.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSlotsAvailableComponent implements OnInit {
	@Input()
	availableSlots;

	@Output()
	setSelectedSlot = new EventEmitter<SlotData>();

	public formattedSlotsLists;
	public showMoreButtonAfterNbValue = 5;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.formattedSlotsLists = formatTimeSlots(this.availableSlots?.timeslots);
		this.cdr.detectChanges();
	}

	public slotClicked(slot) {
		this.setSelectedSlot.emit({ date: slot });
	}
}

function formatTimeSlots(timeslots) {
	const slotsByDate = {};

	// group time slots by date
	timeslots?.forEach((slot) => {
		const date = slot.startTime.slice(0, 10);
		if (!slotsByDate[date]) {
			slotsByDate[date] = [];
		}
		slotsByDate[date].push(slot);
	});

	// format each group of time slots
	const formattedSlots = [];
	for (const date in slotsByDate) {
		if (slotsByDate.hasOwnProperty(date)) {
			const slots = slotsByDate[date].map((slot) => {
				return {
					text: new Date(slot.startTime).toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
					}),
					value: slot.startTime,
					isChecked: false,
				};
			});

			formattedSlots.push({ date, slots });
		}
	}

	return formattedSlots;
}
