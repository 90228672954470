import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UIService } from '../../../../services/ui.service';
import { SearchEntity, SearchType } from '../helpers/providers-search.helper';
import { HealtheeService } from 'src/app/models/service.model';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { ProvidersSearchService } from 'src/app/services/providers-search/providers-search.service';

@Component({
	selector: 'app-providers-search-page',
	templateUrl: './providers-search-page.component.html',
	styleUrls: ['./providers-search-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProvidersSearchPageComponent {
	public selectedTopSearch: HealtheeService;

	constructor(
		private uiService: UIService,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private providersSearchService: ProvidersSearchService
	) {}

	public setService(service) {
		this.selectedTopSearch = service;
	}

	public onSearch(queryParams: { entity: SearchEntity; searchType: SearchType }) {
		this.uiService.navigate([this.providersSearchUrlsService.resultsUrl], { queryParams });
	}
}
