import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ripple-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
	@Input() digits: number;
	constructor() {}

	ngOnInit(): void {}
}
