<div class="gipitpot-input-wrapper">
	<div class="gipitpot-input">
		<input
			#chatInput
			[(ngModel)]="userInput"
			(keyup.enter)="onUserInput()"
			data-test-id="gipitpot-input"

			type="text"
			placeholder="whats on your mind..."
			class="fs-unmask"
		/>
		<a class="send-button" data-test-id="gipitpot-submit" (click)="onUserInput()" title="send chat message to zoe"></a>
	</div>
</div>
