<div class="map-container">
	<div *ngIf="hasSearchThisAreaButton && showSearchButton" class="button-container">
		<ripple-button
			(click)="onAreaSearchClicked()"
			label="{{ 'Search this area' | translate }}"
			hierarchy="secondary"
			size="sm"
			leadingIcon="refresh"
		></ripple-button>
	</div>
	<div class="providers-map-card-container">
		<app-providers-map-card
			[type]="type"
			[providerLocations]="providerLocations"
			[activeLocationIndex]="showActiveLocationCard && activeLocationIndex"
			(openCreateAppointment)="handleOpenCreateAppointment($event)"
		></app-providers-map-card>
	</div>

	<div class="custom-controls">
		<button id="zoom-in-btn" class="floating-button" (click)="setZoomInLevel()">
			<ripple-icon name="plus" color="text-secondary"></ripple-icon>
		</button>
		<button id="zoom-out-btn" class="floating-button" (click)="setZoomOutLevel()">
			<ripple-icon name="minus" color="text-secondary"></ripple-icon>
		</button>
		<button id="current-location-btn" class="floating-button" (click)="goToCurrentLocation()">
			<ripple-icon name="currentLocation" color="text-secondary"></ripple-icon>
		</button>
	</div>

	<google-map
		width="100%"
		height="100%"
		[options]="mapOptions"
		[center]="mapOptions.center"
		(zoomChanged)="onMapChangeCenter()"
		(mapClick)="resetMarkerClicked()"
		(mapDragstart)="onMapChangeCenter()"
	>
		<map-marker
			*ngFor="let marker of markers; let index = index"
			[position]="marker.position"
			[options]="marker.options"
			(mapClick)="onMarkerClicked(index)"
		>
		</map-marker>
	</google-map>
</div>
