import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import CFG from '../../../config/app-config.json';
import { UserOpenEnrollmentData } from '../../../models/open-enrollment.model';
import { OpenEnrollmentData } from '../../plan-selection.service';
import { UserData } from 'src/app/models/user-data';

export interface EnrollmentResponse {
	userData: UserOpenEnrollmentData;
	companyData: OpenEnrollmentData;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionAPIService {
	constructor(private http: HttpClient) {}

	public fetchEnrollment(): Observable<EnrollmentResponse> {
		const eventType = localStorage.getItem('eventType');
		const url: string = this.addParamsToUrl(CFG.apiEndpoints.enrollmentData, eventType);

		return this.http.get<EnrollmentResponse>(url);
	}

	public updateServer(data: Partial<UserOpenEnrollmentData>): Observable<any> {
		const url: string = this.addParamsToUrl(CFG.apiEndpoints.userOpenEnrollmentData, 'OE');
		return this.http.post<UserData>(url, data);
	}

	private addParamsToUrl(url: string, eventType?: string) {
		if (eventType) url += '?';
		if (eventType) url += 'eventType=' + eventType;
		return url;
	}
}
