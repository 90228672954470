import { Injectable } from '@angular/core';
import { SearchAutocompleteResultsStoreService } from '../stores/search-autocomplete-results-store/search-autocomplete-results-store.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AutocompleteData } from '../api/providers-api/autocomplete-api.service';
import { Router } from '@angular/router';
import {
	findPrimaryLocation,
	normalizeAddress,
	RibbonFacility,
} from '../../modules/main-layout/care-costs/helpers/providers.helpers';
import { tap } from 'rxjs/operators';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import {
	NormalizedRibbonDoctor,
	NormalizedRibbonFacility,
	ProvidersSearchUrlKey,
	RibbonDoctor,
} from '../../models/ribbon-data.model';

@Injectable({
	providedIn: 'root',
})
export class SearchAutocompleteService {
	private _results = new BehaviorSubject<any>(null);

	constructor(
		private _searchAutocompleteResultsStoreService: SearchAutocompleteResultsStoreService,
		private _router: Router,
		private providersSearchUrlsService: ProvidersSearchUrlsService
	) {}

	get results$(): Observable<AutocompleteData> {
		return this._results.asObservable();
	}

	public search(name: string, address?: string, services: string[] = []): Observable<void> {
		if (!name) return of(undefined);

		const fields = {
			name,
			pageSize: this.getPageSize(name),
			address,
			services,
		};

		return this._searchAutocompleteResultsStoreService
			.get(fields)
			.pipe(tap((result) => this._results.next(result)));
	}

	private getPageSize(name: string): number {
		return name.length <= 1 ? 3 : 10;
	}

	public getFormattedData(data): any {
		data.providers = this.getDoctorsData(data);
		data.facilities = this.getFacilitiesData(data);

		return [...data.providers, ...data.facilities];
	}

	public getDoctorsData(data: { providers: RibbonDoctor[] }): NormalizedRibbonDoctor[] {
		return data.providers
			.filter((doctor) => doctor.isInNetwork) // MPI only in network providers
			.map((doctor) => {
				const addressDetails = doctor.locations?.[0]?.address_details;

				return {
					...doctor,
					urlKey: ProvidersSearchUrlKey.PROVIDER,
					urlId: doctor.npi,
					name: `${doctor.first_name} ${doctor.last_name}`,
					goToDetailsPage: () =>
						this._router.navigate([this.providersSearchUrlsService.specialistUrl, doctor.npi]),
					address: normalizeAddress(addressDetails),
					state: addressDetails?.state,
					city: addressDetails?.city,
					specialty: findPrimaryLocation(doctor),
					gender: doctor.gender,
				};
			});
	}

	public getFacilitiesData(data: { facilities: RibbonFacility[] }): NormalizedRibbonFacility[] {
		return data.facilities.map((facility) => {
			return {
				...facility,
				urlKey: ProvidersSearchUrlKey.FACILITY,
				urlId: facility.uuid,
				goToDetailsPage: () =>
					this._router.navigate([this.providersSearchUrlsService.facilityUrl, facility.uuid]),
				address: normalizeAddress(facility.address_details),
				state: facility.address_details.state,
				city: facility.address_details.city,
				specialty: null,
				specialties: [],
			};
		});
	}
}
