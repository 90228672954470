<div class="preventive-care-mark-as-completed-container">
	<div *ngIf="showFormText" class="body">
		<div class="title">{{ 'When was your last {name}?' | translate: data }}</div>

		<mat-form-field class="input-wrapper" [hideRequiredMarker]="true">
			<input
				matInput
				type="text"
				class="fs-unmask"
				[value]="selectedDate | date: 'MM/yyyy'"
				placeholder='Select a Month and a Year'
				(click)="picker.open()">
			<input
				class="hidden-input"
				matInput
				[matDatepicker]="picker"
				[max]="today"
				(click)="picker.open()">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker date startView="multi-year" (monthSelected)="onMonthSelect($event, picker)"></mat-datepicker>
		</mat-form-field>
	</div>

	<div *ngIf="!showFormText" class="body" [class.post-form]="!showFormText">
		<div class="heart-img">
			<img src="/assets/images/wellness-heart.png" alt="hearth">
			<div *ngIf="showNextAppointmentText" class="completed-icon">
				<ripple-icon name="check" size="size-32"></ripple-icon>
			</div>
		</div>
		<div class="title">
			<span *ngIf="showNextAppointmentText">{{'Great Job!' | translate}}</span>
			<span *ngIf="showPassedNextAppointmentText">{{'Next Appointment Due' | translate}}</span>
		</div>
		<div class="description">
			<span *ngIf="showNextAppointmentText">
				<div>{{'Mark your calendar for your next appointment' | translate}}</div>
				<div>{{'in '| translate}}{{selectedDate | nextAppointmentDate: data.frequency | date: 'MMMM yyyy'}}</div>
			</span>
			<span *ngIf="showPassedNextAppointmentText">
				<div>{{'Based on the entered date, your last appointment ' | translate}} <b>{{'was due in ' | translate}}{{selectedDate | nextAppointmentDate: data.frequency | date: 'MMMM yyyy'}}.</b></div>
				<div>{{" It's recommended to schedule it soon."}}</div>
			</span>
		</div>
	</div>


	<div class="action-btn" [class.multiple-btns]="data.completedDate">
		<ripple-button
			*ngIf="data.completedDate"
			[label]="'Clear selection' | translate"
			[isLoading]="isClearLoading"
			hierarchy="secondary"
			size="sm"
			(click)="onClear()">
		</ripple-button>

		<ripple-button
			[label]="(showFormText ? 'Submit' : 'Got It!') | translate"
			hierarchy="primary"
			size="sm"
			[isLoading]="isSubmitLoading"
			[isDisabled]="!selectedDate"
			(click)="showFormText ? onSubmit() : onFinished()">
		</ripple-button>
	</div>
</div>
