import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'notCoveredInServicePanel',
	pure: true,
})
export class NotCoveredInServicePanelPipe implements PipeTransform {
	transform(value: any): string {
		if (value === 'Not Covered') return 'Non Covered Service';
		return value;
	}
}
