import { PlanData } from '../../../models/plan-data.model';
import { isNil } from '../../../utils/is/is-nil';

export const retrieveDeductiblesFromPlan = (plan: PlanData): { inNetwork: string; outNetwork: string } => {
	if (isNil(plan?.contract?.deductible?.individual)) {
		return defaultDeductibles;
	}

	const { inNetwork, outNetwork } = plan.contract.deductible.individual;
	return {
		inNetwork: inNetwork ? transformDeductible(inNetwork.text) : '',
		outNetwork: outNetwork ? transformDeductible(outNetwork.text) : '',
	};
};

export const defaultDeductibles = {
	inNetwork: '',
	outNetwork: '',
};

/**
 * Deductible string should have:
 * 1. number (mandatory)
 * 2. dollar sign prefix (optional)
 * 3. comma (optional)
 * 4. dots (optional)
 */
export const isValidDeductible = (deductible: string): boolean =>
	/^\$?\d{1,3}(,?\d{3})*(\.\d+)?$/.test(deductible.trim());

export const transformDeductible = (value: string): string => (isValidDeductible(value) ? value.trim() : '');
