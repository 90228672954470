import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CardBackData, ContentNodeType, TemplateType } from '../../../../services/digital-card/templates/shared';
import { CUSTOM_CARD_TYPES } from '../../../../services/digital-card/digital-card.service';
import { invokePhoneDialer } from '../../../../utils/utils';

@Component({
	selector: 'app-custom-digital-card-back',
	templateUrl: './custom-digital-card-back.component.html',
	styleUrls: ['./custom-digital-card-back.component.scss'],
})
export class CustomDigitalCardBackComponent implements OnChanges {
	@ViewChild('regular') REGULAR: TemplateRef<any>;
	@ViewChild('middleHeadline') MIDDLE_HEADLINE: TemplateRef<any>;
	@ViewChild('list') LIST: TemplateRef<any>;
	@ViewChild('mix') MIX: TemplateRef<any>;

	@Input() data: CardBackData[];
	@Input() customCardType: CUSTOM_CARD_TYPES;

	ContentNodeType: ContentNodeType;
	contents: CardBackData[] = [];

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data.currentValue) {
			this.contents = [];

			this.data.map((item) => {
				this.contents.push({ ...item, type: TemplateType[item.type] });
			});
			this.cdr.detectChanges();
		}
	}

	callPhone(phone: string) {
		invokePhoneDialer(phone);
	}
}
