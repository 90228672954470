import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

import { HomePageService } from '../home-page/services/home-page.service';
import { ImmediateCareObj, ImmediateCareServices } from './immediate-care.localize';


@Component({
	selector: 'app-immediate-care',
	templateUrl: './immediate-care.component.html',
	styleUrls: ['./immediate-care.component.scss'],
})
export class ImmediateCareComponent implements OnInit {
	public benefitArr: ImmediateCareObj[] = [];
	public userBenefitsMap$: Observable<Map<string, any>> = this.homePageService.userBenefitsMap$;

	constructor(private navCtrl: NavController, private homePageService: HomePageService) {}

	ngOnInit(): void {
		this.userBenefitsMap$.pipe().subscribe((userBenefitsMap) => {
			if (!userBenefitsMap.size) return;
			for (const benefitData of ImmediateCareServices) {
				this.benefitArr.push({
					index: benefitData.index,
					benefit: userBenefitsMap.get(benefitData.benefit),
					title: benefitData.title,
					description: benefitData.description,
					icon: benefitData.icon,
					facilityType: benefitData.facilityType,
				});
			}
		});
	}

	onClickBack() {
		this.navCtrl.back();
	}
}
