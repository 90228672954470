import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { T } from '@transifex/angular';
import { AppError } from './../../../../../error-handlers/app-errors';

import { UIService } from '../../../../../services/ui.service';
import { AppManagerService } from '../../../../../services/app-manager.service';
import { ChangePassword, UserService } from './../../../../../services/user.service';
import { StorageService } from '../../../../../services/storage.service';

import { PasswordObj } from 'src/app/modules/app-shared/set-password/set-password.component';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';

const EMPTY_SIGNAL = null;
const IS_MFA_REGEX = /^\d{6}$/;

@Component({
	selector: 'app-change-pass-dialog',
	templateUrl: './change-pass-dialog.component.html',
	styleUrls: ['./change-pass-dialog.component.scss'],
})
export class ChangePassDialogComponent extends HealtheeDialogContent implements OnInit {
	@Output() closeClicked = new EventEmitter<null>();

	private hash: string;
	public isLoading = true;
	public twoFACode: string;
	public invalidField: boolean = false;
	public passwordPayload: ChangePassword;
	public usedPasswordErr: boolean = false;
	public isPasswordValid: boolean = false;
	public twoFACodeNoMatchErr: boolean = false;
	public twoFACodeInvalidErr: boolean = false;
	public usedPasswordIndicator = new Subject<boolean>();

	@T(
		'Oops! This action cannot be completed at the moment! Help us improve your experience by sending an error report.'
	)
	private userErrors_actionCannotBeCompleted: string;

	constructor(
		private userService: UserService,
		private uiService: UIService,
		private appManager: AppManagerService,
		private storageService: StorageService
	) {
		super();
	}

	ngOnInit(): void {
		this.requestPasswordChangeWhileConnected();
	}

	private requestPasswordChangeWhileConnected() {
		const changePasswordHash$ = this.userService.requestPasswordChangeHash();
		const changePassword2FA$ = this.userService.requestPasswordChange2FACode();

		zip(changePasswordHash$, changePassword2FA$)
			.pipe(map(([hash, resFrom2FA]) => hash))
			.subscribe((hash: any) => this.passwordResetHashReceived(hash));
	}

	private passwordResetHashReceived(res: { hash: string }) {
		if (!res.hash) {
			this.onCloseModalClicked();
			throw new AppError(this.userErrors_actionCannotBeCompleted);
		}

		this.hash = res.hash;
		this.isLoading = false;
	}

	public reset2FAErrors() {
		this.twoFACodeInvalidErr = false;
		this.twoFACodeNoMatchErr = false;
	}

	onChangePasswordClicked() {
		if (!IS_MFA_REGEX.test(this.twoFACode)) {
			this.twoFACodeInvalidErr = true;
			return;
		} else this.twoFACodeInvalidErr = false;

		this.passwordPayload.twoFACode = this.twoFACode;

		this.userService.sendChangePasswordRequest(this.passwordPayload).subscribe({
			next: () => this.passwordChangedSuccessfully(),
			error: (err) => this.handlePasswordChangeError(err),
		});
	}

	private passwordChangedSuccessfully() {
		this.invalidField = false;
		this.appManager.openPasswordChangeSuccess();
		this.onCloseModalClicked();
	}

	private handlePasswordChangeError(error: any) {
		const originalError = error.originalError as any;
		const errorMessage = originalError?.error?.error; // TODO: Change this to error-codes insteaad of strings.

		if (errorMessage === 'invalid_token' || errorMessage === 'token_expired') {
			this.uiService.displayAppMessage('Something went wrong. Please refresh the page and try again later');
			this.onCloseModalClicked();
			return;
		}

		if (errorMessage === 'password_used') {
			this.invalidField = true;
			this.isLoading = false;
			this.usedPasswordIndicator.next(true);
			return;
		}

		if (errorMessage === 'invalid_twoFACode') {
			this.invalidField = true;
			this.isLoading = false;
			this.twoFACodeNoMatchErr = true;
			// TODO: Add the ability to request another 2FA code
			return;
		}

		// some other error - throw to the global error handler
		this.onCloseModalClicked();
		throw error;
	}

	public onCloseModalClicked() {
		this.closeDialog.emit(EMPTY_SIGNAL);
	}

	public validateNewPassword(passwordObj: PasswordObj): boolean {
		this.usedPasswordIndicator.next(false);
		if (!passwordObj.isMatch || !passwordObj.isValid || !passwordObj.password)
			return (this.isPasswordValid = false);

		this.passwordPayload = {
			password: passwordObj.password,
			hash: this.hash,
			twoFACode: '',
			mfaToken: this.storageService.getMfaToken(),
		};
		this.isPasswordValid = true;
	}
}
