import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { AdditionalBenefit } from '../../api/domain/additional-benefit';
import { map, Observable, share } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { UserApiService } from '../../api/user-api/user-api.service';
import { isNil } from '../../../utils/is/is-nil';

@Injectable({
	providedIn: 'root',
})
export class AdditionalBenefitsStoreService extends Store<void, AdditionalBenefit[]> {
	constructor(private userApiService: UserApiService) {
		super();
	}

	protected retrieve(context: void): Observable<Maybe<AdditionalBenefit[]>> {
		return this.userApiService.additionalBenefits();
	}

	byId(id: string): Observable<Maybe<AdditionalBenefit>> {
		return this.get().pipe(
			map((benefits) => (isNil(benefits) ? null : benefits.find((benefit) => benefit._id === id))),
			share()
		);
	}

	isExist(id: string): Observable<boolean> {
		return this.byId(id).pipe(map((benefit) => !isNil(benefit)));
	}

	namesStr(): Observable<Maybe<Map<string, string>>> {
		return this.get().pipe(
			map((benefits) => {
				if (isNil(benefits)) {
					return null;
				}
				const res = new Map<string, string>();

				for (const benefit of benefits) {
					res.set(benefit._id, benefit.name.toLowerCase());
				}

				return res;
			}),
			share()
		);
	}
}
