import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-healthee-mark-tooltip',
	templateUrl: './healthee-mark-tooltip.component.html',
	styleUrls: ['./healthee-mark-tooltip.component.scss'],
})
export class HealtheeMarkTooltipComponent {
	@Input() mobileTitle: string;
	@Input() text: string;
	@Output() opened = new EventEmitter<void>();

	constructor() {}

}
