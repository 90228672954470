import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'floor',
	pure: true,
})
export class FloorPipe implements PipeTransform {
	transform(value: number, precision: number = 0): number {
		const multiplier = Math.pow(10, precision);

		return Math.floor(value * multiplier) / multiplier;
	}
}
