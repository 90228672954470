import { Pipe, PipeTransform } from '@angular/core';
import { Maybe } from '../../../../../utils/types/maybe';
import { getPath } from '../../../../../utils/get-path';

@Pipe({
	name: 'getPath',
})
export class GetPathPipe implements PipeTransform {
	transform(value: unknown, path: Maybe<string>): unknown {
		return getPath(value, path);
	}
}
