import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DataFromServerForFeed, FeedConfig, SuggestionFeed } from '../../../../models/suggestion-feed.model';

import { shuffleArray } from '../../../../utils/utils';

import { FeedService } from '../../../../services/feed.service';
import { UIService } from '../../../../services/ui.service';

const ICONS_PATH = {
	PLAY: '../../../../../assets/images/icons/play-feed.svg',
	STOP: '../../../../../assets/images/icons/stop-feed.svg',
};

@Component({
	selector: 'app-suggestions-feed',
	templateUrl: './suggestions-feed.component.html',
	styleUrls: ['./suggestions-feed.component.scss'],
})
export class SuggestionsFeedComponent implements OnInit {
	private ELEMENTS_LENGTH_PER_FEED: number = 20;
	private feedConfig: FeedConfig;
	private allSuggestions: SuggestionFeed[];
	public suggestionsToShow: SuggestionFeed[];
	private initialized: boolean;
	private rollingProcess;
	public isRolling: boolean;
	public rollingIconPath: string;
	public isTabletView: boolean = false;
	public isMobileView: boolean = false;

	@Input() isRollingPausedByChatFooter: boolean;
	@Output() sendToChatFromFeed: EventEmitter<string> = new EventEmitter();
	@Output() closeFeed: EventEmitter<any> = new EventEmitter();

	constructor(private feedService: FeedService) {}

	ngOnInit() {
		this.initView();
		this.initRolling();
		this.initialized = true;
	}

	ngOnChanges(): void {
		if (!this.initialized) return;

		this.isRollingPausedByChatFooter ? this.stopRolling() : this.startRolling();
	}

	private initView(): void {
		this.isTabletView = UIService.isTabletDeviceScreen(window);
		this.isMobileView = UIService.isMobileDeviceScreen(window);
	}

	private initRolling() {
		this.isRolling = false;
		this.feedService.fetchData().subscribe((data) => {
			this.initProcess(data);
		});
	}

	private initProcess(data: DataFromServerForFeed): void {
		this.initData(data);
		this.shuffleSuggestions();
		this.startRolling();
	}

	private initData(data: DataFromServerForFeed): void {
		this.allSuggestions = data.suggestions;
		this.feedConfig = this.feedService.getDefaultConfig();
	}

	private shuffleSuggestions(): void {
		this.allSuggestions = shuffleArray(this.allSuggestions);
		this.suggestionsToShow = this.allSuggestions.slice(0, this.ELEMENTS_LENGTH_PER_FEED);
		this.allSuggestions = this.allSuggestions.slice(this.ELEMENTS_LENGTH_PER_FEED);
	}

	private startRolling(): void {
		if (this.isRolling) return;

		this.rollingProcess = setInterval(() => this.rollingSuggestions(), this.feedConfig.feedSwapInterval);
		this.isRolling = true;
		this.rollingIconPath = ICONS_PATH.STOP;
	}

	private stopRolling(): void {
		clearInterval(this.rollingProcess);
		this.isRolling = false;
		this.rollingIconPath = ICONS_PATH.PLAY;
	}

	public toggleRolling(): void {
		this.isRolling ? this.stopRolling() : this.startRolling();
	}

	private rollingSuggestions() {
		for (let i = 0; i < this.feedConfig.numberElementsToSwitch; i++) {
			this.suggestionsToShow.shift();
			const newSuggestion = this.allSuggestions[0];

			if (!newSuggestion) {
				this.feedService.fetchData().subscribe((data) => {
					this.initData(data);
				});
				return;
			}

			this.suggestionsToShow.push(newSuggestion);
			this.allSuggestions.shift();
		}
	}

	public sendSuggestion(event, index: number, text: string): void {
		event.stopPropagation();
		this.sendToChatFromFeed.emit(text);
		this.suggestionsToShow[index].isSent = true;

		this.isMobileView ? this.close() : this.startRolling();
	}

	public close(): void {
		this.stopRolling();
		this.closeFeed.emit();
	}
}
