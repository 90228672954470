import { Pipe, PipeTransform } from '@angular/core';
import { PreventiveCare } from '../../../services/preventive-care.service';

@Pipe({
	name: 'completedCount',
})
export class CompletedCountPipe implements PipeTransform {
	transform(items: PreventiveCare[]): number {
		return items.filter((item) => item.completedDate).length;
	}
}
