<div class="text-and-toggle">
	<p class="text">
		{{ 'Receive medical appointments scheduling information through emails and texts.' | translate }}
	</p>
	<mat-slide-toggle
		[checked]="isSubscribedToNotifications$ | async"
		(change)="onChoiceChange($event)"
	></mat-slide-toggle>
</div>
<mat-divider></mat-divider>
<div class="button-wrapper">
	<button class="bt primary-blue" (click)="closeDialog.emit()">{{ 'Close' | translate }}</button>
</div>
