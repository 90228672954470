import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'underlineAllowableAmount',
	pure: true,
})
// MPI wants to have some words and names in AllUpper-Cases etc.
export class UnderlineAllowableAmountPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: any): SafeHtml {
		if (!value) {
			return '';
		}

		// Extract the raw string from SafeValueImpl if necessary
		let rawValue = value;
		if (typeof value === 'object' && value.changingThisBreaksApplicationSecurity) {
			rawValue = value.changingThisBreaksApplicationSecurity;
		}

		if (typeof rawValue !== 'string') {
			return '';
		}

		const searchText = 'Allowable Amount';
		const checkOnAllowableAmountText = new RegExp(searchText, 'gi');

		// Check if search text exists using test method before replace
		if (checkOnAllowableAmountText.test(value)) {
			rawValue = rawValue.replace(checkOnAllowableAmountText, `<u>${searchText}</u>`);
		}

		return this.sanitizer.bypassSecurityTrustHtml(rawValue);
	}
}
