<form [formGroup]="editInfoForm" (ngSubmit)="onSubmit()">
	<div class="input-wrapper" *ngIf="!hideNicknameField">
		<mat-form-field>
			<mat-label class="">{{ 'Preferred Name' | translate }}</mat-label>
			<input matInput formControlName="nickname" name="nickname" id="nickname" pattern="^[a-zA-Z\-_—\s]{1,16}$" />
		</mat-form-field>
	</div>
	<fieldset>
		<div class="input-wrapper">
			<mat-form-field class="date-picker">
				<mat-label>{{ 'Date of Birth' | translate }}</mat-label>
				<input
					[readonly]="isIonic"
					matInput
					(click)="picker.open()"
					class="date-picker-input fs-unmask"
					autocomplete="off"
					[matDatepicker]="picker"
					formControlName="dateOfBirth"
					[min]="minDate"
					[max]="maxDate"
				/>
				<mat-datepicker-toggle matSuffix [for]="picker" class="healthee-blue"> </mat-datepicker-toggle>
				<mat-datepicker #picker startView="multi-year"></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="input-wrapper">
			<app-healthee-radio-group
				id="gender"
				[label]="this.gender"
				[name]="'gender'"
				[buttons]="chooseGenderRadios"
				(valueChanged)="onGenderButtonChange($event)"
			></app-healthee-radio-group>
		</div>
	</fieldset>
	<fieldset class="physical-wrapper" *ngIf="!hideHeightWeightField">
		<div class="input-wrapper">
			<mat-form-field>
				<mat-label><UT str="Height" key="height"></UT></mat-label>
				<input
					matInput
					formControlName="heightFt"
					name="heightFt"
					id="heightFt"
					type="number"
					class="fs-unmask"
					min="4"
					max="7"
				/>
			</mat-form-field>
			<span>ft</span>
		</div>
		<div class="input-wrapper">
			<mat-form-field>
				<mat-label><UT str="Height" key="height"></UT></mat-label>
				<input
					matInput
					formControlName="heightInch"
					name="heightInc"
					id="heightInc"
					type="number"
					class="fs-unmask"
					min="0"
					max="11"
				/>
			</mat-form-field>
			<span>inch</span>
		</div>
		<div class="input-wrapper">
			<mat-form-field>
				<mat-label><UT str="Weight" key="weight"></UT></mat-label>
				<input
					matInput
					formControlName="weight"
					name="weight"
					id="weight"
					type="number"
					class="fs-unmask"
					min="90"
					max="440"
				/>
			</mat-form-field>
			<span>lbs</span>
		</div>
		<div class="input-wrapper" *ngIf="!hideSmokingField">
			<app-healthee-radio-group
				id="smoking"
				[label]="this.smoker"
				[name]="'smoking'"
				[buttons]="chooseSmokingRadios"
				(valueChanged)="onSmokingButtonChange($event)"
			></app-healthee-radio-group>
		</div>
	</fieldset>
	<div class="btn-wrapper text-center">
		<button type="submit" class="bt-primary" [disabled]="!this.editInfoForm.valid">
			<UT str="Save" key="save_button"></UT>
		</button>
	</div>
</form>
