import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlaceDirective } from './google-place.directive';

@NgModule({
	declarations: [GooglePlaceDirective],
	imports: [CommonModule],
	exports: [GooglePlaceDirective],
})
export class NgxGooglePlacesAutocompleteModule {}
