<div class="input-success-error-message">
  <span class="input-success-error-message-icon">
    <ripple-icon
		*ngIf="isSuccess; else errorIcon" name="check" color="green" alt="Success Icon">
	</ripple-icon>
    <ng-template #errorIcon>
		<ripple-icon name="close" color="red" alt="Error Icon"></ripple-icon>
    </ng-template>
  </span>
	<span class="input-success-error-message-text">{{ messageText }}</span>
</div>
