<div class="feed-container">
	<div *ngIf="isMobileView || isTabletView" class="feed-close-header">
		<img src="../../../../../assets/images/icons/close.svg" (click)="close()" alt="" />
	</div>
	<div class="title-bar">{{ 'See what other people are asking Zoe' | translate }}</div>
	<div *ngIf="!isTabletView || isMobileView" class="play-btn-container">
		<img src="{{ rollingIconPath }}" alt="" (click)="toggleRolling()" />
		<div>{{ isRolling ? 'Stop' : 'Play' }} {{ 'suggested questions' | translate }}</div>
	</div>

	<div class="suggestions-container">
		<div *ngFor="let suggestion of suggestionsToShow; let i = index" class="suggestion-container">
			<div class="suggestion" [ngClass]="{ 'suggestion-sent': suggestion.isSent }">
				<div class="suggestion-info">
					<img src="{{ suggestion.avatarIconPath }}" alt="" />
					{{ 'Someone just asked' | translate }}
				</div>
				<div class="suggestion-main">
					<div class="suggestion-text">{{ suggestion.text }}</div>
					<div class="ask-question-btn" (click)="sendSuggestion($event, i, suggestion.text)">
						<span>{{ 'Chat With Zoe' | translate }}</span>
						<img src="../../../../../assets/images/icons/suggestion-submit.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="input-container">
		<input id="show-question-input" type="checkbox" checked disabled />
		<div>{{ "Don't show my question to other people" | translate }}</div>
	</div>
</div>
