import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, map, take } from 'rxjs';

import { WidgetType } from '../../main-layout-shared/talon-widget/talon-widget.component';

import { UserService } from '../../../../services/user.service';
import { UIService } from '../../../../services/ui.service';
import { HealthProfileService } from '../../../../services/health-profile.service';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { tabs } from '../health-profile.module';

@Component({
	selector: 'app-health-profile-main',
	templateUrl: './health-profile-main.component.html',
	styleUrls: ['./health-profile-main.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HealthProfileMainComponent implements OnInit {
	hasMedicalContract$: Observable<boolean>;
	isAnthemContract$: Observable<boolean>;

	public talonWidgetType: WidgetType = WidgetType.HEALTH_PLANS;
	public userConnectionToTalon$: Observable<boolean>;
	public userInitials$: Observable<string>;

	public activeTabLink;
	public tabs = tabs;

	public navLinks = [];

	constructor(
		private healthProfileService: HealthProfileService,
		private userService: UserService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	public ngOnInit(): void {
		this.userConnectionToTalon$ = this.healthProfileService.isUserConnectedToTalon$;
		this.userInitials$ = this.userService.userInitials$;
		this.hasMedicalContract$ = this.userPlanDataStoreService.hasContractByType('contract');
		this.isAnthemContract$ = this.userPlanDataStoreService.hasMedicalPlanByAnthem();

		this.navLinks = this.setNavLinks();
		this.loadActiveTabIndex();
	}

	private setNavLinks = () => {
		const links = [{ link: `${tabs.personalInfo}`, label: 'Personal Info' }];

		this.isAnthemContract$
			.pipe(take(1))
			.pipe(
				map((isAnthemContract) => {
					if (isAnthemContract) {
						// links.push({ link: `${tabs.referralsInfo}`, label: 'My MP3 Referrals' });
					}
				})
			)
			.subscribe();
		return links;
	};

	private loadActiveTabIndex() {
		this.route.queryParams.subscribe(() => {
			const url = this.router.url.split('?')[0];

			if (url === `/health-profile/${tabs.medicalSpendings}`) {
				this.activeTabLink = `${tabs.medicalSpendings}`;
				return;
			}
			if (url === `/health-profile/${tabs.healthPlans}`) {
				this.activeTabLink = `${tabs.healthPlans}`;
				return;
			}

			if (url === `/health-profile/${tabs.referralsInfo}`) {
				this.activeTabLink = `${tabs.referralsInfo}`;
				return;
			}

			this.activeTabLink = `${tabs.personalInfo}`;
		});
	}

	public setActiveTabLink(tabLink: string) {
		this.activeTabLink = tabLink;
	}

	public isMobileView(): boolean {
		return UIService.isTabletDeviceScreen(window);
	}
}
