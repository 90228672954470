<div class="search-bar-container" [ngClass]="{ 'after-search': shorterContent }">
	<img
		class="sun"
		[ngClass]="{ 'no-img': shorterContent }"
		src="/assets/images/providers-search/sun-desktop.svg"
		alt="img"
	/>

	<div class="search-bar-container__header" [class.no-title]="shorterContent">
		<ripple-button
			*ngIf="shouldDisplayBackButton"
			hierarchy="link"
			leadingIcon="arrowLeft"
			[isCurrentColor]="true"
			(onClick)="goBack()"
		></ripple-button>

		<div class="title">
			{{ 'Find an MP3 Provider' | translate }}
		</div>
		<div class="need-help">
			<button *ngIf="showNeedhelpButton" (click)="onNeedHelp()" class="bt-link">
				{{ 'Need Help?' | translate }}
			</button>
		</div>
	</div>

	<div class="search-wrapper">
		<div class="btn-container" [class.with-form]="!showMobileMapView">
			<div>
				<button *ngIf="shorterContent && !showMobileMapView" (click)="onClickBack()" class="back-button">
					<ripple-icon name="arrowLeft"></ripple-icon>
				</button>
			</div>

			<div *ngIf="(isMobile$ | async) && shorterContent" (click)="toggleMobileMapView()" class="map-buttons">
				<button *ngIf="!showMobileMapView" class="map-button">
					{{ 'Map' | translate }}
					<div class="floating-btn-icon"><ripple-icon name="map"></ripple-icon></div>
				</button>
				<button *ngIf="showMobileMapView" class="map-button">
					{{ 'List' | translate }}
					<div class="floating-btn-icon"><ripple-icon name="list"></ripple-icon></div>
				</button>
			</div>
		</div>
		<!-- desktop form -->
		<form
			*ngIf="(isMobile$ | async) === false && !showMobileMapView"
			[formGroup]="searchForm"
			class="desktop-view form-wrapper"
		>
			<div class="search-content">
				<ng-container *ngTemplateOutlet="providerInput"></ng-container>
			</div>
			<div class="search-content">
				<ng-container *ngTemplateOutlet="addressInput"></ng-container>
			</div>

			<div class="d-flex align-items-center search-button-wrapper">
				<button
					class="healthee-btn sm primary"
					[disabled]="showAutocompleteLoader || showLocationLoader"
					(click)="onFormSubmit()"
				>
					{{ 'Find Providers' | translate }}
				</button>
				<button *ngIf="showFilters" mat-icon-button class="d-sm-none mr-15">
					<mat-icon
						class="text-healthee-blue badge-color-healthee-blue cursor-pointer"
						(click)="
							healtheeDialogService.open({
								templateRef: mobileFilters,
								actionsTemplateRef: mobileFiltersActions,
								title: modalTitle,
								hasCloseButton: true
							})
						"
						[matBadge]="(filtersCount$ | async) === 0 ? null : (filtersCount$ | async)"
						>tune
					</mat-icon>
				</button>
			</div>
		</form>
		<!-- mobile collapsed one input view -->
		<div *ngIf="isMobileCollapsed && (isMobile$ | async) && !showMobileMapView" class="collapsed-input-wrapper">
			<div class="collapsed-input" (click)="setMobileInputsCollapsed(false)">
				<ripple-icon name="search"></ripple-icon>
				<div>
					<p class="highlight">{{ searchForm?.get('value').value }}</p>
					<p>{{ searchForm?.get('address').value }}</p>
				</div>
			</div>
			<button *ngIf="showFilters" mat-icon-button class="d-sm-none">
				<mat-icon
					class="text-healthee-blue badge-color-healthee-blue cursor-pointer"
					(click)="
						healtheeDialogService.open({
							templateRef: mobileFilters,
							actionsTemplateRef: mobileFiltersActions,
							title: modalTitle,
							hasCloseButton: true
						})
					"
					[matBadge]="(filtersCount$ | async) === 0 ? null : (filtersCount$ | async)"
					>tune
				</mat-icon>
			</button>
		</div>
		<!-- mobile two input view -->
		<div *ngIf="!isMobileCollapsed && (isMobile$ | async) && !showMobileMapView" class="form-wrapper">
			<div class="search-input" (click)="setMobileSearchAndFocus(SearchFieldEnum.provider)">
				<div class="icon">
					<ripple-icon name="search"></ripple-icon>
				</div>
				<p>
					{{ searchForm?.get('value').value }}
					<span *ngIf="!searchForm?.get('value').value" class="placeholder">
						{{ 'Specialty, Facility, Doctor name' | translate }}
					</span>
				</p>
			</div>
			<div class="search-input" (click)="setMobileSearchAndFocus(SearchFieldEnum.location)">
				<div class="icon">
					<ripple-icon name="location"></ripple-icon>
				</div>
				<p>
					{{ searchForm?.get('address').value }}
					<span *ngIf="!searchForm?.get('address').value" class="placeholder">
						{{ 'City, State or Zip code' | translate }}
					</span>
				</p>
			</div>
		</div>
		<!-- mobile full screen -->
		<form *ngIf="(isMobile$ | async) && !showMobileMapView" [formGroup]="searchForm" class="form-wrapper">
			<div class="search-content mobile" [hidden]="mobileSearchOpen !== SearchFieldEnum.provider">
				<div class="mobile-search-header">
					<span class="header-close close-btn" (click)="setMobileSearchAndFocus(null)">
						<ripple-icon name="arrowLeft"></ripple-icon>
					</span>
					<ng-container *ngTemplateOutlet="providerInput"></ng-container>
				</div>
			</div>

			<div class="search-content mobile" [hidden]="mobileSearchOpen !== SearchFieldEnum.location">
				<div class="mobile-search-header">
					<span class="header-close close-btn" (click)="setMobileSearchAndFocus(null)">
						<ripple-icon name="arrowLeft"></ripple-icon>
					</span>
					<ng-container *ngTemplateOutlet="addressInput"></ng-container>
				</div>
			</div>
			<div *ngIf="!isMobileCollapsed" class="d-flex align-items-center search-button-wrapper">
				<button
					class="healthee-btn sm primary"
					[disabled]="showAutocompleteLoader || showLocationLoader"
					(click)="onFormSubmit()"
				>
					{{ 'Find Providers' | translate }}
				</button>
				<button mat-icon-button class="mobile-fullscreen-filter">
					<mat-icon
						class="text-healthee-blue badge-color-healthee-blue cursor-pointer"
						(click)="
							healtheeDialogService.open({
								templateRef: mobileFilters,
								actionsTemplateRef: mobileFiltersActions,
								title: modalTitle,
								hasCloseButton: true
							})
						"
						[matBadge]="(filtersCount$ | async) === 0 ? null : (filtersCount$ | async)"
						>tune
					</mat-icon>
				</button>
			</div>
		</form>
	</div>

	<div
		*ngIf="
			!isResultPage &&
			!showFilters &&
			(isMobile$ | async) === false &&
			!showMobileMapView &&
			(isEmptyStateEnabled$ | async)
		"
		class="toggle-search-preferences-wrapper"
	>
		<button (click)="onToggleSearchPreferences()" class="bt-link">{{ 'Search Preferences' | translate }}</button>
	</div>

	<div
		*ngIf="(!isResultPage && (isMobile$ | async) === false && showFilters) || isResultPage"
		class="filters-form d-none sm-d-flex align-items-center pt-20"
		#filterWrapperRef
	>
		<ng-container *ngIf="!isOverflowing; else filterWrapperWithSlider">
			<ng-container *ngTemplateOutlet="filterContent"></ng-container>
		</ng-container>
	</div>

	<div
		*ngIf="
			!isResultPage &&
			showFilters &&
			(isMobile$ | async) === false &&
			!showMobileMapView &&
			(isEmptyStateEnabled$ | async)
		"
		class="toggle-search-preferences-wrapper"
	>
		<button (click)="onToggleSearchPreferences()" class="bt-link">
			{{ 'Hide Search Preferences' | translate }}
		</button>
	</div>
</div>

<ng-template #providerInput>
	<div class="search-input desktop-view" [formGroup]="searchForm">
		<div class="colored-icon">
			<ripple-icon name="search"></ripple-icon>
		</div>
		<input
			type="text"
			class="fs-unmask"
			matInput
			(click)="onInputClick()"
			#inputNative
			placeholder="{{ 'Specialty, Facility, Doctor name' | translate }}"
			formControlName="value"
			[matAutocomplete]="autoGroup"
			(keyup.enter)="onFormSubmit()"
		/>
		<ripple-loader *ngIf="showAutocompleteLoader" [size]="'xs'" [color]="loaderColor"></ripple-loader>
		<mat-autocomplete
			#autoGroup="matAutocomplete"
			class="autocomplete-container providers-autocomplete-container"
			(optionSelected)="onOptionSelected($event)"
			[autoActiveFirstOption]="isFirstOptionHighlighted"
		>
			<ng-container>
				<ng-container *ngIf="attributeGroups[0].options | async as specialties">
					<mat-optgroup [label]="attributeGroups[0].name | titlecase" *ngIf="specialties.length > 0">
						<mat-option
							[id]="attributeGroups[0].id + '_' + i"
							*ngFor="let specialtie of specialties; let i = index"
							[value]="specialtie"
						>
							<span [innerHTML]="specialtie | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="attributeGroups[3].options | async as facilities">
					<mat-optgroup [label]="attributeGroups[3].name | titlecase" *ngIf="facilities.length > 0">
						<mat-option
							[id]="attributeGroups[3].id + '_' + i"
							*ngFor="let facility of facilities; let i = index"
							[value]="facility.text"
						>
							<span [innerHTML]="facility.text | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="attributeGroups[8].options | async as insuranceGroups">
					<mat-optgroup [label]="attributeGroups[8].name | titlecase" *ngIf="insuranceGroups.length > 0">
						<mat-option
							[id]="attributeGroups[8].id + '_' + i"
							*ngFor="let insuranceGroup of insuranceGroups; let i = index"
							[value]="insuranceGroup"
						>
							<span [innerHTML]="insuranceGroup | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
			</ng-container>
		</mat-autocomplete>
		<ripple-icon
			*ngIf="inputNative.value"
			name="close"
			[hasClickEvent]="true"
			(click)="deleteInputText($event)"
			[class.visible]="searchForm.controls.value.value"
			class="close"
		>
		</ripple-icon>
	</div>
</ng-template>

<ng-template #addressInput>
	<div class="search-input desktop-view google-places-autocomplete-input" [formGroup]="searchForm">
		<div class="colored-icon">
			<ripple-icon name="location"></ripple-icon>
		</div>
		<input
			ngx-google-places-autocomplete
			type="text"
			class="fs-unmask"
			#placesRef="ngx-places"
			#locationInput
			formControlName="address"
			[options]="googlePlaceOptions"
			(input)="onGoogleLocationChange()"
			(onAddressChange)="onAddressChange($event.formatted_address, false)"
			placeholder="{{ 'City, State or Zip code' | translate }}"
			[matAutocomplete]="locationGroup"
			[matAutocompleteDisabled]="locationInput.value !== ''"
			[class.autocomplete-container]="true"
		/>
		<ripple-loader *ngIf="showLocationLoader" [size]="'xs'" [color]="loaderColor"></ripple-loader>
		<mat-autocomplete #locationGroup="matAutocomplete" class="autocomplete-container">
			<mat-option (click)="onClickGetCurrentLocation()">
				<div class="search-bar-option">
					<ripple-icon name="currentLocation" color="blue"></ripple-icon>
					{{ 'Current location' | translate }}
				</div>
			</mat-option>
			<mat-optgroup [label]="'Recent searches' | titlecase" *ngIf="(recentSearches$ | async)?.length > 0">
				<mat-option
					*ngFor="let recentSearch of recentSearches$ | async"
					(click)="onClickSetRecentLocation(recentSearch)"
					class="recent-search-option"
				>
					<div class="search-bar-option">
						<div class="recent-searches-location-pin"></div>
						{{ recentSearch }}
					</div>
				</mat-option>
			</mat-optgroup>
		</mat-autocomplete>
		<div class="icon">
			<ripple-icon
				*ngIf="locationInput.value"
				name="close"
				[hasClickEvent]="true"
				(click)="deleteLocationText($event)"
				[class.visible]="searchForm.controls.address.value"
				class="icon close"
			>
			</ripple-icon>
		</div>
	</div>
</ng-template>

<ng-template #mobileFiltersActions let-close="close">
	<button class="healthee-btn md secondary mr-8" (click)="this.onResetFilter(); close(); onFormSubmit()">
		{{ 'Reset' | translate }}
	</button>
	<button class="healthee-btn md primary" (click)="close(); onFormSubmit()">
		{{ 'Show Providers' }}
	</button>
</ng-template>

<ng-template #mobileFilters>
	<div [formGroup]="filtersForm" class="mobile-block" *ngIf="networkStructure$ | async as networkStructure">
		<div class="first-label mobile-label">{{ 'Distance' | translate }}</div>
		<app-chip-list-form-control
			keyField="id"
			displayField="display"
			[items]="distances"
			formControlName="distance"
			[options]="mobileFilterersOptions"
		>
		</app-chip-list-form-control>

		<mat-divider class="divider"></mat-divider>
		<div class="mobile-label">{{ 'Gender' | translate }}</div>
		<app-chip-list-form-control
			keyField="id"
			displayField="display"
			[items]="genders"
			formControlName="gender"
			[options]="mobileFilterersOptions"
		>
		</app-chip-list-form-control>

		<mat-divider class="divider"></mat-divider>
		<div class="mobile-label">{{ 'Language' | translate }}</div>
		<app-select-form-control
			[placeholderText]="'Language' | translate"
			floatLabel="always"
			valueField="title"
			displayField="title"
			[items]="languages"
			[multi]="false"
			formControlName="language"
		></app-select-form-control>

		<ng-container
			*ngIf="
				this.searchForm.value.entity !== SearchEntity.SearchByGroupName && insuranceGroups$
					| async as insuranceGroups
			"
		>
			<ng-container *ngIf="insuranceGroups.length">
				<mat-divider class="divider"></mat-divider>
				<div class="mobile-label">{{ 'Group' | translate }}</div>
				<app-select-form-control
					class="mr-8 group-filter-control"
					valueField="value"
					displayField="value"
					[label]="'Group' | translate"
					[searchLabel]="'Search group' | translate"
					[items]="insuranceGroups"
					[hasBottomSpace]="false"
					(ngModelChange)="onFormSubmit()"
					[hasSearch]="true"
					[multi]="false"
					[isNullable]="true"
					formControlName="insuranceGroup"
				>
					<ng-template #item let-data>
						<div class="group-filter-option">{{ data.value }}</div>
					</ng-template>
				</app-select-form-control>
			</ng-container>
		</ng-container>
	</div>
</ng-template>

<ng-template #filterWrapperWithSlider>
	<app-healthee-content-slider [enableClickScroll]="false" class="with-slider">
		<ng-container *ngTemplateOutlet="filterContent"></ng-container>
	</app-healthee-content-slider>
</ng-template>

<ng-template #filterContent [formGroup]="filtersForm">
	<div class="d-flex align-items-center pb-10 pt-10" #filterRef>
		<app-select-form-control
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Distance' | translate"
			[items]="distances"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="distance"
		></app-select-form-control>

		<app-select-form-control
			class="mr-8"
			valueField="title"
			displayField="title"
			[label]="'Language' | translate"
			[items]="languages"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="language"
		></app-select-form-control>

		<app-select-form-control
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Gender' | translate"
			[items]="genders"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="gender"
		></app-select-form-control>

		<ng-container
			*ngIf="
				this.searchForm.value.entity !== SearchEntity.SearchByGroupName && insuranceGroups$
					| async as insuranceGroups
			"
		>
			<app-select-form-control
				*ngIf="insuranceGroups.length"
				class="mr-8 group-filter-control"
				valueField="value"
				displayField="value"
				[label]="'Group' | translate"
				[searchLabel]="'Search group' | translate"
				[items]="insuranceGroups"
				[hasBottomSpace]="false"
				(ngModelChange)="onFormSubmit()"
				[hasSearch]="true"
				[multi]="false"
				[isNullable]="true"
				formControlName="insuranceGroup"
			>
				<ng-template #item let-data>
					<div class="group-filter-option">{{ data.value }}</div>
				</ng-template>
			</app-select-form-control>
		</ng-container>

		<div class="text-healthee-blue cursor-pointer ml-45" (click)="onResetFilter()">
			{{ 'Reset Filters' | translate }}
		</div>
	</div>
</ng-template>
