import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isMP3LoadedInsideHA, resolveHost } from '../utils/utils';
import { environment } from 'src/environments/environment';

// Add suffixes or prefixes for routes to be passed "as-is", without changing the hostname.
// For example, relative urls (such as '/assets/images/') that are fetched through http - should be ignored.
export const ignoredRouteSuffixes = [];
export const forcedRouteUrlPreffixes = [
	'/api/search-providers',
	'/api/ribbon',
	'/api/providers',
	'/ribbon-mpi-entities',
	'/v2/mp3-user',
];

export const ignoredRoutePrefixes = [
	'http://', // ignore absolute (not relative) urls that explicitly point to an http host
	'https://', // ignore absolute (not relative) urls that explicitly point to an https host
	'/assets/', // angular's asset directory should be retrieved from the angular host
];

@Injectable()
export class HostnameInterceptor implements HttpInterceptor {
	private resolvedHostname = resolveHost();

	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let hostName: string = null;

		if (this.urlIsAnIgnoredRoute(req.url)) {
			return next.handle(req);
		}

		if (isMP3LoadedInsideHA() && this.urlIsAnForceedRoute(req.url)) {
			hostName = `${environment.mpiServer.hostname}:${environment.mpiServer.port}`;
		}

		const requestWithAlteredHost = this.changeRequestHostname(req, hostName);
		return next.handle(requestWithAlteredHost);
	}

	private urlIsAnForceedRoute(url: string) {
		let found = false;

		forcedRouteUrlPreffixes.forEach((routeSuffix) => {
			if (url.startsWith(routeSuffix)) found = true;
		});

		return found;
	}

	private urlIsAnIgnoredRoute(url: string) {
		let found = false;

		ignoredRouteSuffixes.forEach((routeSuffix) => {
			if (url.endsWith(routeSuffix)) found = true;
		});

		ignoredRoutePrefixes.forEach((routePrefix) => {
			if (url.startsWith(routePrefix)) found = true;
		});
		return found;
	}

	private changeRequestHostname(req: HttpRequest<any>, hostName?: string) {
		return req.clone({
			url: (hostName || this.resolvedHostname) + req.url,
		});
	}
}
