import { isE2eEnv } from './utils/is-e2e-env';
import { isLocalEnv } from './utils/is-local-env';
import { MeUserStoreService } from './services/stores/me-user-store/me-user-store.service';
import { isAppEmbeddedInIframe } from './utils/utils';
import { LastActivityTrackerService } from './services/last-activity-tracker.service';
import { UserService } from './services/user.service';

export function initializeGtm(documentObject: Document, meUserStore: MeUserStoreService) {
	const windowObject = documentObject.defaultView;
	if (isAppEmbeddedInIframe()) {
		windowObject['_fs_is_outer_script'] = true;
	}

	if (!isE2eEnv(windowObject) && !isLocalEnv(windowObject)) {
		const gtmScript = documentObject.createElement('script');
		gtmScript.type = 'text/javascript';
		gtmScript.src = '../assets/js/gtm.js';

		gtmScript.onload = () => {
			meUserStore.get().subscribe((meUser) => {
				if (meUser) {
					windowObject['FullStory'].identify(meUser.uid, {
						displayName: meUser.uid,
						companyName: meUser.companyName,
						emailDomain: meUser.email?.split('@')[1],
					});
				}
			});
		};

		const gtmNoScript = documentObject.createElement('noscript');
		const gtmIframe = documentObject.createElement('iframe');
		gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-MLSWLJK';
		gtmIframe.height = '0';
		gtmIframe.width = '0';
		gtmIframe.style.display = 'none';
		gtmIframe.style.visibility = 'hidden';

		gtmNoScript.appendChild(gtmIframe);
		documentObject.head.appendChild(gtmScript);
		documentObject.body.prepend(gtmNoScript);
	}
}

export function initializeMobileAutoLogout(lastActivityTrackerService: LastActivityTrackerService, userService: UserService, autoLogoutInMilli?: number) {
	if (autoLogoutInMilli) {
		console.log('initializeMobileAutoLogout');
		lastActivityTrackerService.onResume(async (lastActiveMilli) => {
			if (lastActiveMilli > autoLogoutInMilli) {
				console.log('AutoLogout', 'User was inactive for more than', autoLogoutInMilli, 'milli. Soft logging out.');
				await userService.logout();
			}
		})
	}
}
