<div class="provider-about">
	<div class="information">
		<div class="title">{{ "Provider's information" | translate }}</div>
		<table>
			<tr *ngIf="providerData.specialties.length">
				<td class="sub-title">{{ 'Specialties' | translate }}</td>
				<td class="content">
					<div *ngFor="let specialty of providerData.specialties" class="item">
						{{ specialty }}
					</div>
				</td>
			</tr>
			<tr *ngIf="providerData.mpiLanguages?.length">
				<td class="sub-title languages">{{ 'Languages' | translate }}</td>
				<td class="content labels">
					<ng-container>
						<ripple-label
							*ngFor="let language of providerData.mpiLanguages"
							[text]="language | titlecase"
						></ripple-label>
					</ng-container>
				</td>
			</tr>
			<tr *ngIf="providerData.gender">
				<td class="sub-title">{{ 'Gender' | translate }}</td>
				<td class="content">
					<div class="item">
						{{
							providerData.gender === ProviderGender.Male ? ('Male' | translate) : ('Female' | translate)
						}}
					</div>
				</td>
			</tr>
			<tr *ngIf="groups.length">
				<td class="sub-title" [ngPlural]="groups.length">
					<ng-template ngPluralCase="one">
						{{ 'Group' | translate }}
					</ng-template>
					<ng-template ngPluralCase="other">
						{{ 'Groups' | translate }}
					</ng-template>
				</td>
				<td class="content">
					<div *ngFor="let group of groups" class="item">
						{{ group }}
					</div>
				</td>
			</tr>
		</table>
	</div>
</div>
