<div class="content" [class.content-mobile]="isMobileView">
	<div class="header">
		<img src="https://assets.healthee.co/quantum_logos/logo.png" alt="" />
		<div class="head">
			<div class="title">Quantum Support</div>
			<div class="availability">Available 8:00 AM - 10 PM EST</div>
		</div>
	</div>
	<div class="info">
		<div (click)="call()">
			<div class="icon call">
				<ripple-icon name="callFilled" size="size-16"></ripple-icon>
			</div>
			<div class="value">(833) 346-1482</div>
		</div>
		<div (click)="goToWebsite()">
			<div class="icon web">
				<ripple-icon name="global" size="size-16"></ripple-icon>
			</div>
			<div class="value">myqhcc.com</div>
		</div>
	</div>
</div>
