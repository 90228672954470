<!-- ionic-only action-bar. Not rendered on web version -->
<app-mobile-action-bar></app-mobile-action-bar>

<div class="faq">
	<mat-expansion-panel hideToggle (opened)="panelOpened(1)">
		<mat-expansion-panel-header>
			<mat-panel-title>{{ "So, let's start with the basics. What is Healthee?" | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Healthee is an independent third-party platform. This means we are NOT associated with any healthcare provider and always have your best interests in mind.
            Our goal is to make sure you get the most out of your health plan by keeping you informed about your medical coverage and benefits.
            We know healthcare plans are complicated and we are here to simplify them so you and your family can stay healthy while saving time and money."
			></UT>
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(2)">
		<mat-expansion-panel-header>
			<mat-panel-title> {{ 'How does Healthee work?' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Your employer provides Zoe with all the information regarding health plan. Using the most advanced artificial
            intelligence technology and natural language processing (NLP) algorithms, Zoe can analyze it and quickly answer any questions you have about your rights and benefits, 24/7 (Zoe does not take any coffee breaks nor vacations)."
			></UT>
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(3)">
		<mat-expansion-panel-header>
			<mat-panel-title>{{
				'You probably wonder what kind of questions Zoe can answer?' | translate
			}}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Your employer provides Zoe with all the information regarding your company's health plans. Using the most advanced artificial intelligence technology and natural language processing (NLP) algorithms, Zoe can understand your medical benefits and rights and swiftly answer any questions you have about it, 24/7. (Zoe does not take a coffee break or a vacation).
            <h3><strong>{{'Here are some sample questions Zoe can answer:'</strong></h3>"
			></UT>
			<UT
				str="<ul>
                <li>Am I covered for an annual check-up?</li>
                <li>How much will a doctor's appointment cost me? (coming soon!)</li>
                <li>Do I have a gym membership discount? (coming soon!)</li>
                <li>I am pregnant; what are my relevant benefits?</li>
            </ul>"
			></UT>
			{{
				'And remember! Zoe is learning and adjusting herself to your needs with every interaction, so the more questions you ask her, the more she’ll be able to help you.'
					| translate
			}}
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(4)">
		<mat-expansion-panel-header>
			<mat-panel-title>{{
				'What kind of questions Zoe can NOT answer (although she would love to)?' | translate
			}}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="<h3>Zoe is NOT:</h3>
            <ul>
                <li>A health insurance provider – you can't purchase insurance through her.</li>
                <li>A doctor – she <strong>cannot</strong> provide any medical advice but she will always be sympathetic and help you to find the right solution for you.</li>
            </ul>
            In addition, she <strong>only knows</strong> what is stated in your medical plan (and your state's medical benefits if relevant), so she <strong>won't be able</strong> to answer any questions not related to your medical coverage or benefits.
            With that said, you will be surprised by how much she knows! So don’t hesitate to ask her."
			></UT>
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(5)">
		<mat-expansion-panel-header>
			<mat-panel-title>{{
				'What else can Zoe do, aside from answering my questions?' | translate
			}}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Zoe’s goal is not only to help you get healthier, but to help you <strong>stay</strong> healthy as well. This means she will let you know of any other preventive care and wellness benefits you are entitled to and will recommend the right ones for you <strong>(coming soon!)</strong>. For example, you may get a notification from Zoe regarding a yearly physical check-up, flu shots and other procedures you are covered for and should take advantage of."
			></UT>
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(6)">
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'What if Zoe does not have an answer to my question?' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Zoe is super smart but sometimes, she may not have an immediate answer for you. But don't worry, we’ve got your back! When that happens, our experts will receive your question and reply within 24 hours via email and teach Zoe to know the answer for next time!
            Zoe uses machine learning technologies to constantly improve and learn more about your health plan and your health concerns, so she will answer more and more questions as time goes by."
			></UT>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel hideToggle (opened)="panelOpened(7)">
		<mat-expansion-panel-header>
			<mat-panel-title> {{ 'What happens with my information and data?' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="Healthee does not share any personal or health-related data with your employer or medical provider.
            We pride ourselves on being <strong>independent</strong> and <strong>confidential</strong>, which allows us to always keep your best interest at heart."
			></UT>
		</div>
	</mat-expansion-panel>
	<mat-expansion-panel hideToggle (opened)="panelOpened(8)">
		<mat-expansion-panel-header>
			<mat-panel-title> {{ 'How can I contact Healthee?' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div>
			<UT
				str="You can always reach us via <strong><a href='mailto:info@healthee.co'>EMAIL</a></strong> Our coverage experts are happy to help get feedback and learn how to get better.
            Zoe and our entire team are looking forward to showing you what your medical plan covers, and how to take full advantage of it, while keeping you well informed and healthier!"
			></UT>
		</div>
	</mat-expansion-panel>
</div>
