import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { T } from '@transifex/angular';
import { BehaviorSubject, Observable, Subscription, shareReplay } from 'rxjs';
import { Benefit } from '../../../../../models/benefits.model';
import { ProvidersSearchService } from '../../../../../services/providers-search/providers-search.service';
import { BenefitStoreService } from '../../../../../services/stores/benefit-store/benefit-store.service';
import { TrackingService } from '../../../../../services/tracking.service';
import { Maybe } from '../../../../../utils/types/maybe';
import { ICON_SIZE } from 'ripple';
import { NetworkStructure } from 'src/app/models/chat.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { ProvidersSearchResults } from '../../helpers/providers-search.helper';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { CompanyStoreService } from 'src/app/services/stores/company-store/company-store.service';

interface Service {
	id: string;
	name: string;
}

@Component({
	selector: 'app-coverage-info',
	templateUrl: './coverage-info.component.html',
	styleUrls: ['./coverage-info.component.scss'],
})
export class CoverageInfoComponent implements OnInit, OnDestroy {
	public inNetwork: boolean;

	@Input() hasBorder: boolean = false;

	@Input() set inNetworkChange(inNetwork: boolean) {
		this.inNetwork = inNetwork;
	}

	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, regardless of your deductible status. Some services provided during a visit may incur additional costs."
	)
	public tooltipTextForCopayInNetwork: string;
	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may incur additional costs."
	)
	public tooltipTextForCopayOutNetwork: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, regardless of your deductible status/accumulation. Some services provided during a visit may incur additional costs."
	)
	public tooltipTextForCoinsuranceInNetwork: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may incur additional costs."
	)
	public tooltipTextForCoinsuranceOutNetwork: string;

	public readonly ICON_SIZE = ICON_SIZE;
	public readonly NetworkStructure = NetworkStructure;
	public services$: Observable<Service[]> = this.providersSearchService.services$;
	private benefitData: { [serviceId: string]: Benefit } = {};
	public networkData: { [serviceId: string]: string } = {};
	public isSubjectToDeductible: { [serviceId: string]: boolean } = {};
	public showBenefitDrawer = false;
	public serviceIdForDrawer$ = new BehaviorSubject<Maybe<string>>(null);
	public tooltipText: { [serviceId: string]: string } = {};
	private tooltipType: { [serviceId: string]: string } = {};
	public allServices: Service[] = [];
	public servicesWithNetworkData: Service[] = [];
	public networkStructure$: Observable<NetworkStructure> = this.providersSearchService.searchNetworkStructure$;
	private providersResponse$: Observable<ProvidersSearchResults> = this.providersSearchService.results$;
	private multiNetworkName: string = null;
	private readonly OUT_OF_NETWORK_NAME = 'Out of network';
	public providersResponseSubscription$: Subscription = new Subscription();
	public isCharterSchool$: Observable<boolean> = this.companyStoreService.isCharterSchool();

	constructor(
		public userPlanDataStoreService: UserPlanDataStoreService,
		private providersSearchService: ProvidersSearchService,
		private companyStoreService: CompanyStoreService,
		private benefitStoreService: BenefitStoreService,
		private trackingService: TrackingService,
		public router: Router,
	) { }

	ngOnInit(): void {
		this.services$.subscribe((services) => {
			this.allServices = services ?? [];
			services?.map((service) => {
				this.fetchBenefitData(service.id);
			});
		});

		this.providersResponseSubscription$ = this.providersResponse$.subscribe((providersResponse) => {
			this.multiNetworkName = providersResponse.networkName;
		});
	}

	private fetchBenefitData(serviceId: string): void {
		if (!serviceId) return;
		this.benefitStoreService
			.get(serviceId)
			.pipe(shareReplay(1), RxjsUtils.isNotNil())
			.subscribe((benefitData: Benefit) => {
				this.benefitData[serviceId] = benefitData;
				this.setData(serviceId);
			});
	}

	private setData(serviceId: string): void {
		if (!serviceId || !this.benefitData[serviceId]) {
			this.setServicesWithNetworkData(serviceId);
			return;
		}
		switch (this.inNetwork) {
			case true:
					this.getInNetworkBenefitData(serviceId);
				break;
				case false:
					this.getOonBenefitData(serviceId);
				break;
			}

			this.setServicesWithNetworkData(serviceId);
		}

	private getInNetworkBenefitData(serviceId): void {
		this.networkData[serviceId] = this.benefitData[serviceId]?.inNetwork;
		this.isSubjectToDeductible[serviceId] = this.benefitData[serviceId]?.subjectToDeductibleIn

		if (this.benefitData[serviceId].networkStructure === this.NetworkStructure.MN) {
			const benefit = this.benefitData[serviceId]?.additionalNetworks.find((network) => network.name === this.multiNetworkName);

			if (benefit) {
				this.networkData[serviceId] = benefit.value;
				this.isSubjectToDeductible[serviceId] = benefit.subjectToDeductible;
			}
		}
	}

	private getOonBenefitData(serviceId): void {
		this.networkData[serviceId] = this.benefitData[serviceId]?.outNetwork;
		this.isSubjectToDeductible[serviceId] = this.benefitData[serviceId]?.subjectToDeductibleOut;

		if (this.benefitData[serviceId].networkStructure === this.NetworkStructure.MN) {
			const benefit = this.benefitData[serviceId]?.additionalNetworks.find((network) => network.name === this.OUT_OF_NETWORK_NAME);

			if (benefit) {
				this.networkData[serviceId] = benefit.value;
				this.isSubjectToDeductible[serviceId] = benefit.subjectToDeductible;
			}
		}

		this.tooltipText[serviceId] = this.getTooltip(serviceId, this.networkData[serviceId], false, this.isSubjectToDeductible[serviceId]);
	}

	private setServicesWithNetworkData(serviceId): void {

		if (
			this.benefitData[serviceId].networkStructure === this.NetworkStructure.INDEMNITY ||
			this.benefitData[serviceId].networkStructure === this.NetworkStructure.RBP
		) {
			this.servicesWithNetworkData = this.allServices.filter((service) => this.benefitData[service.id]);
			this.networkData[serviceId] = this.benefitData[serviceId]?.inNetwork;
			this.isSubjectToDeductible[serviceId] = this.benefitData[serviceId]?.subjectToDeductibleIn;
		} else {
			this.servicesWithNetworkData = this.allServices.filter((service) => this.networkData[service.id]);
		}
	}

	private getTooltip(serviceId: string, networkData: string, isInNetwork: boolean, isSubjectToDeductible: boolean): string {
		if (!networkData) return '';

		if (networkData.toLowerCase().includes('copay')) {
			this.tooltipType[serviceId] = 'copay';
			const tooltip = isInNetwork && !isSubjectToDeductible ? this.tooltipTextForCopayInNetwork : this.tooltipTextForCopayOutNetwork;
			return tooltip;
		} else if (networkData.toLowerCase().includes('member')) {
			this.tooltipType[serviceId] = 'coinsurance';
			const tooltip = isInNetwork && !isSubjectToDeductible
				? this.tooltipTextForCoinsuranceInNetwork
				: this.tooltipTextForCoinsuranceOutNetwork;
			return tooltip;
		} else {
			return '';
		}
	}

	public setServiceIdForDrawer(serviceId): void {
		this.serviceIdForDrawer$.next(serviceId);
	}

	public closeServicePanel(): void {
		this.showBenefitDrawer = false;
		this.setServiceIdForDrawer(null);
	}

	public findProvider(serviceId: string): void {
		const referrer = this.router.url;
		this.providersSearchService.goToProviderSearchWithServiceId(serviceId, referrer);
	}

	public onMoreInfo(service): void {
		this.setServiceIdForDrawer(service.id);
		this.showBenefitDrawer = true;
		this.trackingService.trackClientEvent('PS - coverage - more info', { serviceName: service.name });
	}

	public sendTooltipEvent(): void {
		this.trackingService.trackClientEvent('PS - coverage - tooltip review', { tooltipType: this.tooltipType });
	}

	ngOnDestroy(): void {
		this.providersSearchService.setServices(null);
		this.providersResponseSubscription$.unsubscribe();
	}
}
