import { T } from '@transifex/angular';

export class FeedbackLocalize {
	@T('Help us improve')
	static headline: string;

	@T('How helpful did you find the information on this page?')
	static question: string;

	@T('Thanks for your feedback!')
	static feedbackSentMessage: string;

	@T('Not Helpful at All')
	static verySadDisclaimer: string;

	@T('Slightly Helpful')
	static sadDisclaimer: string;

	@T('Moderately Helpful')
	static averageDisclaimer: string;

	@T('Very Helpful')
	static happyDisclaimer: string;

	@T('Extremely Helpful')
	static veryHappyDisclaimer: string;

}
