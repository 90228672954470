import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UIService } from '../services/ui.service';
import CFG from '../config/app-config.json';
import { NGXLogger } from 'ngx-logger';

@Injectable({
	providedIn: 'root',
})
export class FeatureGuard  {
	constructor(private uiService: UIService, private logger: NGXLogger) {}

	canActivate(
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.isFeatureRouteEnabled(route)) {
			const componentOrRouteName = route.component?.name || route.url;

			this.logger.error(`Feature/route ${componentOrRouteName} is not enabled.`);
			alert('This route and feature are disabled');

			this.uiService.navigate([CFG.defaultAppRoute]);
			return false;
		}

		return true;
	}

	private isFeatureRouteEnabled(route: ActivatedRouteSnapshot) {
		// Don't conert to !route... A feature is disabled only if
		// isEnabled is set to false (null doesn't mean a disabled feature!)
		return route?.data?.isEnabled !== false;
	}
}
