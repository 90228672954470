<div class="feedback-dialog-container">
	<form [formGroup]="feedbackForm" (ngSubmit)="onSubmitFeedbackForm()" class="feedback-dialog-wrapper">
		<span class="main-headline">{{ data?.headline }}</span>
		<span class="secondary-headline">{{ data?.question }}</span>
		<app-feedback-emotions
			[rating]="selectedRating"
			(ratingSelected)="onRatingIconSelected($event)"
			[showDisclaimer]="true"
			[disclaimers]="data?.disclaimers"
		>
		</app-feedback-emotions>
		<div class="description-wrapper">
			<span class="description-headline">{{ 'Tell us more (optional)' | translate }}</span>
			<textarea formControlName="description" class="description-box"></textarea>
		</div>
		<div class="divider"></div>
		<div class="send-btn">
			<ripple-button
				(click)="onSubmitFeedbackForm()"
				[label]="'Send' | translate"
				[isDisabled]="feedbackForm.invalid"
			></ripple-button>
		</div>
	</form>
</div>
