import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { TxNativeModule } from '@transifex/angular';

import { HomePageTeaserHeaderComponent } from './home-page-teaser/home-page-teaser-header/home-page-teaser-header.component';
import { HomePageTeaserBodyComponent } from './home-page-teaser/home-page-teaser-body/home-page-teaser-body.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { PlanSelectionTeaserComponent } from './teasers/plan-selection-teaser/plan-selection-teaser.component';
import { DeductibleTeaserComponent } from './teasers/deductible-teaser/deductible-teaser.component';
import { QuickAccessComponent } from './teasers/quick-access/quick-access.component';
import { QuickAccessItemComponent } from './teasers/quick-access/quick-access-item/quick-access-item.component';
import { HomePageTeaserComponent } from './home-page-teaser/home-page-teaser.component';
import { MainLayoutSharedModule } from '../main-layout/main-layout-shared/main-layout-shared.module';
import { SelectedBenefitsTeaserComponent } from './teasers/selected-benefits-teaser/selected-benefits-teaser.component';
import { ContractServicesStoreService } from '../../services/stores/services-store/services-store.service';
import { MatIconModule } from '@angular/material/icon';
import { ConnectDeductiblesFormHandlerModule } from '../standalone/connect-deductibles-form-handler/connect-deductibles-form-handler.module';
import { TelehealthModule } from '../telehealth/telehealth.module';
import { MyCareTeamEmptyStateTeaserComponent } from './teasers/my-care-team-empty-state-teaser/my-care-team-empty-state-teaser.component';
import { MyCareTeamTeaserComponent } from './teasers/my-care-team-teaser/my-care-team-teaser.component';
import { MedicalSpendingModule } from '../standalone/medical-spending/medical-spending.module';
import { MyCareTeamModule } from '../my-care-team/my-care-team.module';
import { ServicePanelModule } from '../standalone/service-panel/service-panel.module';
import { DrawerModule } from '../standalone/drawer/drawer.module';

import { NonEligibleLayoutComponent } from './non-eligible-layout/non-eligible-layout.component';
import { BannerCardModule } from '../standalone/banner-card/banner-card.module';
import { HealthCardsTeaserModule } from '../standalone/health-cards-teaser/health-cards-teaser.module';
import { TelehealthTeaserComponent } from './teasers/telehealth-teaser/telehealth-teaser.component';
import { RippleModule } from 'ripple';
import { ReferralsTeaserComponent } from './teasers/referrals-teaser/referrals-teaser.component';
import { ReferralsInfoModule } from '../mpi/referrals-info/referrals-info.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { PoweredByFooterComponent } from '../standalone/powered-by-footer/powered-by-footer.component';

const routes: Routes = [
	{
		path: '',
		component: HomePageComponent,
	},
];

@NgModule({
	declarations: [
		HomePageTeaserComponent,
		HomePageTeaserBodyComponent,
		HomePageTeaserHeaderComponent,
		HomePageComponent,
		PlanSelectionTeaserComponent,
		DeductibleTeaserComponent,
		QuickAccessComponent,
		QuickAccessItemComponent,
		SelectedBenefitsTeaserComponent,
		MyCareTeamEmptyStateTeaserComponent,
		MyCareTeamTeaserComponent,
		NonEligibleLayoutComponent,
		TelehealthTeaserComponent,
		ReferralsTeaserComponent,
		WelcomeComponent,
	],
	providers: [ContractServicesStoreService],
	imports: [
		CommonModule,
		AppSharedModule,
		TxNativeModule,
		RouterModule.forChild(routes),
		MainLayoutSharedModule,
		MatIconModule,
		ConnectDeductiblesFormHandlerModule,
		TelehealthModule,
		MedicalSpendingModule,
		MyCareTeamModule,
		ServicePanelModule,
		DrawerModule,
		BannerCardModule,
		HealthCardsTeaserModule,
		ReferralsInfoModule,
		RippleModule,
		PoweredByFooterComponent,
	],
	exports: [HomePageComponent],
})
export class HomePageModule {}
