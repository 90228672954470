import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterStringsArray', pure: false })
export class FilterStringsArrayPipe implements PipeTransform {
	/** filterStringsArray Pipe - filters an array of strings,
	 * for strings containing only filteringStringLiteral.
	 * Usage in html: *ngFor="let name of namesList | filterStringsArray: userFilterText"
	 * @param  {string[]} arrayOfStrings
	 * @param  {string} filteringStringLiteral
	 * @returns filtered array of strings
	 */
	transform(arrayOfStrings: string[], filteringStringLiteral: string): string[] {
		if (!arrayOfStrings || !filteringStringLiteral) return arrayOfStrings;

		return arrayOfStrings.filter((item) => item.toLowerCase().includes(filteringStringLiteral.toLowerCase()));
	}
}
