import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export class CountdownTimer {
	private count: number;
	private subscription: Subscription;
	private _countdown = new BehaviorSubject<number>(null);

	get countdown$(): Observable<number> {
		return this._countdown.asObservable();
	}

	get countdown(): number {
		return this._countdown.value;
	}

	constructor() {
		this.count = 0;
		this.subscription = null;
	}

	startCountdown(seconds: number): void {
		this.count = seconds;
		const observable = interval(1000).pipe(take(seconds + 1));
		this.subscription = observable.subscribe({
			next: (val) => {
				this._countdown.next(this.count - val);
			},
		});
	}

	resetCountdown(seconds: number): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.startCountdown(seconds);
	}

	clearCountdown(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this._countdown.next(null);
	}

	completeCountdown(): void {
		this.clearCountdown();
		this._countdown.complete();
	}
}
