<div class="header-provider">
	<provider-avatar [gender]="providerData.gender" size="medium"></provider-avatar>
	<div class="info">
		<div class="title">
			{{ providerData.firstName }} {{ providerData.lastName
			}}<span *ngFor="let degree of providerData.degrees">, {{ degree }}</span>
		</div>
		<div class="sub-title">
			<span *ngFor="let speciality of providerData?.specialties">{{ speciality }}, </span>
		</div>
	</div>
</div>
<app-appointment-slots-available
	[availableSlots]="availableSlots"
	(setSelectedSlot)="setSelectedSlot.emit($event)"
	*ngIf="showSlots"
></app-appointment-slots-available>
