import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { isNil } from '../../../../utils/is/is-nil';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip/tooltip.component';

@Directive({
	selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
	@Input('appTooltip')
	template: TemplateRef<any>;

	private overlayRef: OverlayRef;

	@HostListener('mouseenter')
	show() {
		if (isNil(this.overlayRef) || this.overlayRef.hasAttached()) {
			return;
		}

		const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.changeDetectorRef.markForCheck();
	}

	@HostListener('mouseleave')
	hide() {
		if (isNil(this.overlayRef)) {
			return;
		}
		this.overlayRef.detach();
	}

	constructor(private elementRef: ElementRef, private overlay: Overlay) {}

	ngOnInit(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.elementRef)
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: 'center',
						overlayY: 'top',
						offsetY: 5,
					},
				]),
		});
	}

	ngOnDestroy(): void {
		this.overlayRef.detach();
	}
}
