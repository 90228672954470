<app-healthee-content-slider [enableClickScroll]="false">
	<app-ribbon-health-card-mobile
		[serviceCoverage]="serviceCoverage"
		[isFullDetailsCard]="false"
		*ngFor="let cardData of ribbonCardData"
		class="ribbon-mobile-card"
		[cardData]="cardData"
		(moreInfoClicked)="onMoreInfoClicked(cardData)"
	></app-ribbon-health-card-mobile>
	<div *ngIf="isLoading" class="d-flex align-items-center mx-15">
		<div>
			<app-loader class="mr-15 d-inline-block"></app-loader>
			<span class="d-inline-block">{{ 'Loading' | translate }}</span>
		</div>
	</div>
	<div *ngIf="hasLoadMore && !isLoading" class="d-flex align-items-center">
		<button (click)="loadMoreClick.emit()" class="bt bt-secondary border-border-color-light-blue">
			{{ 'Show More' | translate }}
		</button>
	</div>
</app-healthee-content-slider>
