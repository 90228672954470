import { Component } from '@angular/core';
import { SortingDefaultValue } from '../../referrals-sorting-options/referrals-sorting-options';

@Component({
	selector: 'app-referrals-mobile-layout',
	templateUrl: './referrals-mobile-layout.component.html',
	styleUrls: ['./referrals-mobile-layout.component.scss'],
})
export class ReferralsMobileLayoutComponent {
	public sortDirection: string = SortingDefaultValue;

	sortChangedEventHandler(sortDirection: string) {
		this.sortDirection = sortDirection;
	}
}
