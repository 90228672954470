import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectDeductiblesFormHandlerComponent } from './connect-deductibles-form-handler/connect-deductibles-form-handler.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TxNativeModule } from '@transifex/angular';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from '../../app-shared/app-shared.module';

@NgModule({
	declarations: [ConnectDeductiblesFormHandlerComponent],
	imports: [CommonModule, MatFormFieldModule, TxNativeModule, MatInputModule, ReactiveFormsModule, AppSharedModule],
	exports: [ConnectDeductiblesFormHandlerComponent],
})
export class ConnectDeductiblesFormHandlerModule {}
