import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { Maybe } from '../../utils/types/maybe';
import { SearchResultsStoreService } from '../stores/search-results-store/search-results-store.service';
import { HttpRequestProgressStatus } from '../../utils/http-response/http-response';
import {
	DEFAULT_RESULTS,
	entityHelper,
	LngLat,
	ProviderLocationMarker,
	retrieveSearchTypeAttr,
} from './helpers/providers-search.helpers';
import { ObjectUtils } from '../../utils/object';
import {
	SearchPage,
	SearchOptions,
	DEFAULT_SEARCH_OPTIONS,
	SearchFields,
	SearchType,
	DEFAULT_FORM_VALUES,
	SearchEntity,
	ProvidersSearchResults,
} from '../../modules/main-layout/care-costs/helpers/providers-search.helper';
import { TrackingService } from '../tracking.service';
import { ExternalApiService, MondayTaskType } from '../external-api.service';
import { SearchBarService } from './search-bar.service';
import { getAdressFieldsFromGoogleAdressInput } from '../../modules/main-layout/care-costs/shared/provider-appointment/helpers/confirm-appointment-infos';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentLocationService } from '../current-location.service';
import { UIService } from '../ui.service';
import { RibbonEntitiesStoreService } from '../stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { Router } from '@angular/router';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { getKeyByValue } from 'src/app/utils/utils';

@Injectable({
	providedIn: 'root',
})
export class ProvidersSearchService {
	private _activeLocationIndex$: BehaviorSubject<Maybe<number>> = new BehaviorSubject<Maybe<number>>(null);
	private _showActiveLocationCard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _requestStatus = new BehaviorSubject<HttpRequestProgressStatus>(HttpRequestProgressStatus.Pending);
	private _results = new BehaviorSubject<ProvidersSearchResults>(DEFAULT_RESULTS);
	private _searchOptions = new BehaviorSubject<Maybe<SearchOptions>>(DEFAULT_SEARCH_OPTIONS);
	private _services = new BehaviorSubject<{ id: string; name: string }[]>(null);
	private _isDataFromStore = new BehaviorSubject<boolean>(null);
	private _allHealtheeServices$ = this._ribbonEntitiesStoreService.getAllHealthServices();
	private _disablePanTo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private searchResultsStoreService: SearchResultsStoreService,
		private externalApiService: ExternalApiService,
		private trackingService: TrackingService,
		private searchBarService: SearchBarService,
		private _snackBar: MatSnackBar,
		private _currentLocationService: CurrentLocationService,
		private _uiService: UIService,
		private _ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private _router: Router,
		private providersSearchUrlsService: ProvidersSearchUrlsService
	) {}

	get requestStatus$(): Observable<HttpRequestProgressStatus> {
		return this._requestStatus.asObservable();
	}

	get searchOptions$() {
		return this._searchOptions.asObservable();
	}

	get searchParams$() {
		return this._searchOptions.asObservable().pipe(map((options) => options.params));
	}

	get providersLocations$(): Observable<ProviderLocationMarker[]> {
		return this.results$.pipe(
			map((results) =>
				results.records
					.map((record, index) =>
						entityHelper[results.entity]
							.retrieveLocations(record)
							.map((location: LngLat) => ({ ...location, providerIndex: index }))
					)
					.flat()
			),
			startWith([]),
			shareReplay(1)
		);
	}

	get activeLocationIndex$(): Observable<number> {
		return this._activeLocationIndex$.asObservable();
	}

	get showActiveLocationCard$(): Observable<boolean> {
		return this._showActiveLocationCard$.asObservable();
	}

	get isSuccess$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Success));
	}

	get isPending$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Pending));
	}

	get showLoader$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Loading));
	}

	get isResultsInNetwork$(): Observable<Maybe<boolean>> {
		return this.results$.pipe(map((results) => results.isInNetwork));
	}

	get results$() {
		return this._results.asObservable();
	}

	get entityType$() {
		return this._results.asObservable().pipe(map((results) => results.entity));
	}

	get services$() {
		return this._services.asObservable();
	}

	get isDataFromStore$() {
		return this._isDataFromStore.asObservable();
	}

	get resultsNetworkStatus$() {
		return this._results.asObservable().pipe(map((results) => results.isInNetwork));
	}

	get disabledPanTo$(): Observable<boolean> {
		return this._disablePanTo$.asObservable();
	}

	get searchNetworkStructure$(): Observable<NetworkStructure> {
		return this._results.asObservable().pipe(
			switchMap((results) => {
				const networkStructure = getKeyByValue(results.networkStructure, NetworkStructure);
				if (networkStructure) return of(NetworkStructure[networkStructure]);
				return of(NetworkStructure.SINGLE);
			}),
			shareReplay(1)
		);
	}

	private setIsDataFromStoreState(state: boolean): void {
		this._isDataFromStore.next(state);
	}

	public setActiveLocationIndex(
		locationIndex: number,
		options: { showCard?: boolean; disablePanTo?: boolean } = { showCard: false, disablePanTo: true }
	): void {
		this._activeLocationIndex$.next(locationIndex);
		this._disablePanTo$.next(options.disablePanTo);
		this._showActiveLocationCard$.next(options.showCard);
	}

	public async findServiceById(id: string): Promise<string | undefined> {
		const items = await firstValueFrom(this._allHealtheeServices$);

		return items.find((item) => item._id === id)?.ribbon.key;
	}

	public async goToProviderSearchWithServiceId(id: string, referrer: string) {
		const serviceName = await this.findServiceById(id);
		this.trackingService.trackClientEvent('Find Provider Button Click', { 'Service Name': serviceName });
		if (!serviceName) {
			return this._router.navigate(['/care-and-costs/providers'], {
				queryParams: { referrer },
			});
		}
		return this.goToProviderSearchWithValue(serviceName, SearchEntity.Provider, referrer);
	}

	public async goToProviderSearchWithValue(value: string, entity: SearchEntity, referrer: string) {
		const queryParams: SearchFields = {
			distance: DEFAULT_FORM_VALUES.distance,
			searchType: SearchType.Options,
			page: DEFAULT_FORM_VALUES.page,
			entity: entity,
			value: value,
			address: null,
		};

		this.trackingService.trackClientEvent('Preventive care - find a provider', {});

		this._uiService.navigate([this.providersSearchUrlsService.baseUrl], {
			queryParams: { ...queryParams, referrer },
		});
	}

	public setServices(services: { id: string; name: string }[]): void {
		this._services.next(services);
	}

	public search(formData: SearchOptions, logParams: any = null): void {
		this._requestStatus.next(HttpRequestProgressStatus.Loading);
		this._searchOptions.next(formData);

		const logData = {
			entity: formData.entity,
			isFreeSearch: formData.searchType === SearchType.Free,
			network: formData.params.outOfNetwork ? 'out-network' : 'in-network',
			...formData.params,
			isCurrentLocation: this.searchBarService.isCurrentLocation$.getValue(),
			specificProvider: formData.entity === SearchEntity.Provider && formData.searchType === SearchType.Free,
			specificFacility: formData.entity === SearchEntity.Facility && formData.searchType === SearchType.Free,
		};

		if (logParams.value) {
			logData['q'] = logParams.value;
		}

		if (logData.name) {
			logData['q'] = logData.name;
			delete logData['name'];
		}

		const isDataFromStoreExist = this.searchResultsStoreService.isDataFromStoreExist(formData);
		this.setIsDataFromStoreState(isDataFromStoreExist);

		if (!(formData.entity in entityHelper)) {
			return;
		}

		this.searchResultsStoreService.getNormalized(formData, entityHelper[formData.entity].normalize).subscribe({
			next: async (result: SearchPage<any>) => {
				this._results.next(result);
				this.setServices([{ id: formData.params.service, name: logParams.value }]);
				this._requestStatus.next(HttpRequestProgressStatus.Success);
				logData['totalResults'] = result.totalCount;
				logData['page'] = result.page;
				logData['success'] = true;
				const addressData = await this._currentLocationService
					.getPlaceByAddress(formData.params.address)
					.catch(console.log);
				const addressFormatted = getAdressFieldsFromGoogleAdressInput(addressData);
				logData['addressState'] = addressFormatted?.userState;
				logData['addressCity'] = addressFormatted?.userCity;
				logData['addressZipCode'] = addressFormatted?.zipCode;
				this.trackingService.trackClientEvent('PS - Search', logData);

				if (result.totalCount === 0) {
					const input = `(${logData.network}) - ${logData['q']} | ${logData.address}`;
					this.externalApiService.handleExternalApiForNotFoundSearch(input, MondayTaskType.ProvidersSearch);
				}
			},
			error: () => {
				this._requestStatus.next(HttpRequestProgressStatus.Error);
				logData['totalResults'] = 0;
				logData['page'] = 0;
				logData['success'] = false;
				this.trackingService.trackClientEvent('PS - Search', logData);
			},
		});
	}

	reset() {
		this._requestStatus.next(HttpRequestProgressStatus.Pending);
		this._results.next(DEFAULT_RESULTS);
		this._searchOptions.next(DEFAULT_SEARCH_OPTIONS);
		this._showActiveLocationCard$.next(null);
		this._activeLocationIndex$.next(null);
	}
}

export const searchBarParamsToServerParams = (
	searchFields: SearchFields,
	customFieldValue: string,
	networkStructure: NetworkStructure
): SearchOptions => {
	const searchOptions: SearchOptions = {
		searchType: searchFields.searchType,
		entity: searchFields.entity,
		params: {
			...ObjectUtils.filterOutNils({
				language: searchFields.language,
				gender: searchFields.gender,
				minRating: searchFields.minRating,
				onlineBookingOnly: searchFields.onlineBookingOnly,
				insurance_group_name: searchFields.insuranceGroup,
				mpi_languages: searchFields.mpiLanguage,
				networkStructure: networkStructure,
				mnFavoriteSearch: searchFields.mnFavoriteSearch,
				[retrieveSearchTypeAttr(searchFields.entity, searchFields.searchType)]: customFieldValue,
			}),
			distance: searchFields.distance || DEFAULT_FORM_VALUES.distance,
			address: searchFields.address,
			page: searchFields.page || DEFAULT_FORM_VALUES.page,
		},
	};

	if (searchFields.outOfNetwork === 'true' || searchFields.outOfNetwork === true) {
		searchOptions.params.outOfNetwork = true;
	}

	if (searchOptions.params['name']) {
		searchOptions.params['name'] = searchOptions.params['name'].replace(/-/g, ' ');
	}

	if (!searchOptions.entity && searchOptions.searchType === SearchType.Free) {
		searchOptions.entity = SearchEntity.Provider;
	}
	return searchOptions;
};
