import { Component, Input, EventEmitter, Output, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, Observable, Subscription } from 'rxjs';
import { isNil } from '../../../utils/is/is-nil';
import { CountdownTimer } from './countdown-timer';
import { TrackingService } from '../../../services/tracking.service';
import { CommonComponent } from '../../../utils/components/common-component';
import { MFAMethodCaptions, MFAMethods } from 'src/app/services/user.service';
import { UnleashService } from '../../../services/unleash.service';

const BACKSPACE_KEYCODE = 8;
const ESCAPE_KEYCODE = 27;
const LEFT_ARROW_KEYCODE = 37;
const RIGHT_ARROW_KEYCODE = 39;
const DEFAULT_DELAY_SEC = 30;
/**
 * @param  showInputErrorSignal$ (Input, Observable) - Signals to temporarily display an input error.
 * This should be of type Observable.
 * @param  showCodeResentMessage (Input, boolean) - Signals to display the 'Code Resent' message
 * @Output twoFACodeChanged (EventEmitter, string) - Emits the submitted code, as a string
 * @Output twoFACodeRequested  (EventEmitter, null) - Signals to parent to resend the 2FA code
 */
@Component({
	selector: 'app-twofa-input-component',
	templateUrl: './twofa-input.component.html',
	styleUrls: ['./twofa-input.component.scss'],
})
export class TwoFAInputComponent extends CommonComponent implements OnInit, OnDestroy {
	isNaN = isNaN;
	private _timer = new CountdownTimer();
	private currentSessionMFAMethod: MFAMethods
	public counter$ = this._timer.countdown$;
	public disableCodeResend$ = this.counter$.pipe(map((counter) => counter > 0 || isNil(counter)));

	@Input() resendDelay: number = DEFAULT_DELAY_SEC; // in sec
	@Input() showCodeResentMessage: boolean = false;
	@Input() showInputErrorSignal$: Observable<null>;
	@Input() activeMFAMethod: MFAMethods;
	@Output() twoFACodeChanged = new EventEmitter<string>();
	@Output() twoFACodeSubmited = new EventEmitter();
	@Output() twoFACodeRequested = new EventEmitter<MFAMethods>();

	@Output()
	validTwoFACodeChange = new EventEmitter<string>();

	@Output()
	validTwoFACodeSubmit = new EventEmitter<string>();

	@ViewChild('codeRef') codeRef: ElementRef;

	public readonly MFAMethods = MFAMethods;	
	public twofaForm: FormGroup;
	private errorSignalSub: Subscription;

	constructor(
		private formBuilder: FormBuilder,
		private trackingService: TrackingService,
		private unleashService: UnleashService
	) {
		super();
	}

	ngOnInit() {
		this.build2FAForm();
		this.focusOnInput();
		this.subscribeToErrorSignals();

		this.currentSessionMFAMethod = this.activeMFAMethod ? this.activeMFAMethod : MFAMethods.sms;
		this.subsBag.add = this.twofaForm.get('code').valueChanges.subscribe({
			next: (twoFACode) => this.validTwoFACodeChange.emit(this.isCodeValid(twoFACode) ? twoFACode : null),
		});
	}

	private build2FAForm() {
		this._timer.startCountdown(this.resendDelay);
		this.twofaForm = this.formBuilder.group({
			code: ['', [Validators.required, Validators.maxLength(6)]],
		});
	}

	private focusOnInput() {
		setTimeout(() => this.resetForm());
	}

	private subscribeToErrorSignals() {
		if (isNil(this.showInputErrorSignal$)) {
			return;
		}
		this.errorSignalSub = this.showInputErrorSignal$.subscribe(() => this.displayInputError());
	}

	private resetForm() {
		this.twofaForm.reset();
		this.codeRef.nativeElement.focus();
	}

	private displayInputError() {
		this.codeRef.nativeElement.classList.add('wrong-otp');
		setTimeout(() => {
			this.codeRef.nativeElement.classList.remove('wrong-otp');
		}, 650);
	}

	public on2FACodeChanged() {
		this.twoFACodeChanged.emit(this.twofaForm.value.code);
	}

	public on2FACodeSubmit(): void {
		this.twoFACodeSubmited.emit();

		if (this.isCodeValid(this.twofaForm.get('code').value)) {
			this.validTwoFACodeSubmit.emit();
		}
	}

	public request2FACodeWithEmail() {
		this.currentSessionMFAMethod = MFAMethods.email;
		this.trackingService.trackClientEvent(`Login Email MFA`);
		this.request2FACodeAgain();
	}

	public request2FACodeAgain() {
		if (this._timer.countdown > 0 && isNil(this._timer.countdown)) return;
		this.trackingService.trackClientEvent(`${MFAMethodCaptions[this.currentSessionMFAMethod]} verification 2FA resend`);
		this.twoFACodeRequested.emit(this.currentSessionMFAMethod);
		this.resetForm();
		this._timer.resetCountdown(this.resendDelay);
	}

	private isCodeValid(code: string) {
		return code?.length === 6;
	}

	public isSendCodeByEmailVisible() {	
		return this.activeMFAMethod === MFAMethods.sms 
			&& this.currentSessionMFAMethod === MFAMethods.sms;
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.errorSignalSub?.unsubscribe();
		this._timer.completeCountdown();
	}
}
