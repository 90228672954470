import { Store } from '../../../utils/store/store';
import { RxjsUtils } from '../../../utils/rxjs';

export abstract class NonNullStore<Context, Data> extends Store<Context, Data> {
	get(context: Context) {
		return super.get(context).pipe(
			RxjsUtils.isNotNil(),
		);
	}
}
