import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs';
import * as Sentry from '@sentry/angular';

import { returnEnvironmentField } from '../utils/utils';

import { UserData } from '../models/user-data';

import { UserService } from './user.service';
import { UserPlanDataStoreService } from './stores/user-plan-data-store/user-plan-data-store.service';
import { AppInfo } from '@capacitor/app';
import { MobileAppService } from './mobile-app.service';
import { DeviceInfo } from '@capacitor/device';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { isLocalEnv } from '../utils/is-local-env';
import { isE2eEnv } from '../utils/is-e2e-env';
import { captureExceptionSentry } from '../utils/sentry.utils';
import { PlanSelectionService } from './plan-selection.service';

const TAG = 'TrackingService';

export enum MixpanelProject {
	Healthee = 'MPI',
	PCT = 'PCT',
}

@Injectable({
	providedIn: 'root',
})
export class TrackingService {
	private appInfo: AppInfo;
	private device: DeviceInfo;

	private debugMode: boolean;
	private isDisabled: boolean = false;

	constructor(
		private userService: UserService,
		private mobileAppService: MobileAppService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private http: HttpClient,
		private planSelectionService: PlanSelectionService,
		@Inject(DOCUMENT) private document: Document
	) {
		try {
			const windowObject = this.document.defaultView;
			this.isDisabled = isE2eEnv(windowObject) || isLocalEnv(windowObject);

			this.mobileAppService.ionicAppInfo$.subscribe({
				next: (data) => {
					if (data) {
						this.appInfo = data;
					}
				},
			});

			this.mobileAppService.deviceInfo$.subscribe({
				next: (data) => {
					if (data) {
						this.device = data;
					}
				},
			});

			this.debugMode = returnEnvironmentField('debugAnalytics') === true || false;
			if (this.debugMode) {
				console.log('[TrackingService] starting in debug mode');
			}

			if (!this.isDisabled) {
				this.mixpanelInit();
			}
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('info');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	// ---- mixpanel region -----
	private mixpanelInit() {
		mixpanel.init(environment.mixpanelProjectToken, {
			debug: this.debugMode,
			ignore_dnt: true,
		});

		mixpanel.init(
			environment.mixpanelProjectTokenPCT,
			{
				debug: this.debugMode,
				ignore_dnt: true,
			},
			MixpanelProject.PCT
		);
		mixpanel.register({
			build: this.appInfo?.build,
			version: this.appInfo?.version,
			user_agent: navigator.userAgent,
			platform: this.device?.platform,
			device_model: this.device?.model,
			device_name: this.device?.name,
			device_OS_version: this.device?.osVersion,
		});
		mixpanel.PCT.register({
			build: this.appInfo?.build,
			version: this.appInfo?.version,
			user_agent: navigator.userAgent,
			platform: this.device?.platform,
			device_model: this.device?.model,
			device_name: this.device?.name,
			device_OS_version: this.device?.osVersion,
		});
	}

	private mixpanelUserIdentity(userData: UserData, medical: string, dental: string, vision: string) {
		mixpanel.identify(userData.uid);
		mixpanel.people.set({
			medical_plan: medical || '',
			dental_plan: dental || '',
			vision_plan: vision || '',
			user_id: userData.uid || '',
			externalId: userData.externalId || '',
			company_name: userData.companyName || '',
			birth_date: `${userData.birthday || ''}`,
			gender: userData.gender || '',
			preferredLanguage: userData.preferredLanguage || '',
		});

		mixpanel.PCT.identify(userData.uid);
		mixpanel.PCT.people.set({
			medical_plan: medical || '',
			dental_plan: dental || '',
			vision_plan: vision || '',
			user_id: userData.uid || '',
			externalId: userData.externalId || '',
			company_name: userData.companyName || '',
			birth_date: `${userData.birthday || ''}`,
			gender: userData.gender || '',
			preferredLanguage: userData.preferredLanguage || '',
		});
	}

	//$email and $name in userProperties are special properties used by mixpanel to identify users
	private mixpanelSendEvent(event: string, eventProperties: object = {}, project: MixpanelProject) {
		try {
			/*
                NOTE: adblockers may block browser events to mixpanel - consider using mixpanel proxy
                https://developer.mixpanel.com/docs/collection-via-a-proxy
            */
			if (project === MixpanelProject.PCT) {
				mixpanel.PCT.track(event, eventProperties);
			} else {
				mixpanel.track(event, eventProperties);
			}
		} catch (e) {
			console.warn(`[sendToMixpanel] Error sending data to mixpanel: ${e}`);
		}
	}

	// ---- end mixpanel region -----

	public identifyUser(userData: UserData = null) {
		try {
			return new Promise((resolve) => {
				if (this.isDisabled) return resolve(null);

				this.userPlanDataStoreService
					.get()
					.pipe(take(1))
					.subscribe((planData) => {
						const medical = planData?.contract?.name || '';
						const dental = planData?.dental?.name || '';
						const vision = planData?.vision?.name || '';

						if (userData) {
							if (this.debugMode) {
								console.log('[TrackingService.identifyUser]', {
									userData,
									medical,
									dental,
									vision,
								});
							} else {
								this.mixpanelUserIdentity(userData, medical, dental, vision);
							}
							resolve(null);
						} else {
							this.userService.userData$.pipe(take(1)).subscribe((data: UserData) => {
								if (this.debugMode) {
									console.log('[TrackingService.identifyUser]', data, medical, dental, vision);
								} else {
									this.mixpanelUserIdentity(data, medical, dental, vision);
								}
								resolve(null);
							});
						}
					});
			});
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	private trackClientEventForProject(event: string, eventProperties: object = {}, project: MixpanelProject) {
		if (this.isDisabled) {
			console.info('[TrackingService.trackClientEvent.disabled]', project, event, eventProperties);
			return;
		}

		if (this.debugMode) {
			console.log('[TrackingService.trackClientEvent]', project, { event, ...eventProperties });
		} else {
			// this.mixpanelSendEvent(event, eventProperties, project);
			this.sendToHealthee(event, eventProperties, project);
		}
	}

	public trackClientEvent(event: string, eventProperties: object = {}) {
		try {
			eventProperties = this.addAdditionalPropertiesToEventProperties(eventProperties);
			this.trackClientEventForProject(event, eventProperties, MixpanelProject.Healthee);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	public trackClientEventPCT(event: string, eventProperties: object = {}) {
		try {
			eventProperties = this.addAdditionalPropertiesToEventPropertiesPCT(eventProperties);
			this.trackClientEventForProject(event, eventProperties, MixpanelProject.PCT);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	private sendToHealthee(event: string, metaData: object, project: MixpanelProject) {
		try {
			const body = JSON.stringify({ ...metaData, event, project });
			const headers = new HttpHeaders().set('Content-Type', 'application/json');
			if (environment.clientLogApiUrl)
				this.http.post(environment.clientLogApiUrl, body, { headers: headers }).subscribe({
					error: (error) => {
						console.error(`[sendToHealthee] error with sending client log events: ${error}`, error);
					},
				});
		} catch (e) {
			console.log(`[sendToHealthee] Error sending data to healthee server: ${e}`);
		}
	}

	private addAdditionalPropertiesToEventProperties(eventProperties: object = {}) {
		const additionalProperties = {
			device: this.device?.model,
			platform: this.device?.platform,
			...eventProperties,
		};
		return additionalProperties;
	}
	private addAdditionalPropertiesToEventPropertiesPCT(eventProperties: object = {}) {
		const additionalProperties = {
			device: this.device?.model,
			platform: this.device?.platform,
			...eventProperties,
		};
		return additionalProperties;
	}
}
