<div class="eligible-sizpcodes-wrapper">
	If your address on file with MPI has a <span (click)="showZipCodesModal()">ZIP code</span> within the MP3 Service
	Area and you choose not to utilize the MP3 Provider Network, you may be required to pay a $30 Co-Payment plus any
	applicable Coinsurance.
</div>

<app-drawer drawerClass="low-copay-eligible-zipcodes-drawer" [isVisible]="isVisibleModal">
	<ng-template #content>
		<app-eligible-zipcodes-modal
			[zipCodes]="zipCodes$ | async"
			(backClick)="hideZipCodesModal()"
		></app-eligible-zipcodes-modal>
	</ng-template>
</app-drawer>
