import { MedicalConditionsResponse } from '../../../api/employee-api/helpers/medical-conditions.response';
import { isNil } from '../../../../utils/is/is-nil';

export const medicalConditionServices = (medicalConditions: MedicalConditionsResponse[]) => {
	const res = new Map<string, Set<string>>();
	if (isNil(medicalConditions)) {
		return res;
	}
	medicalConditions.forEach((condition) => {
		res.set(condition._id, new Set<string>());
		condition.services.stage1.forEach((serviceId) => res.get(condition._id).add(serviceId));
		condition.services.unDiagnosed.forEach((serviceId) => res.get(condition._id).add(serviceId));
		condition.services.preventive.forEach((serviceId) => res.get(condition._id).add(serviceId));
	});
	return res;
};
