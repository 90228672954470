import { Component, OnDestroy, OnInit } from '@angular/core';
import { T } from '@transifex/angular';

import { AppManagerService } from '../../../../services/app-manager.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subject, take, takeUntil } from 'rxjs';
import { UIService } from '../../../../services/ui.service';
import { UserService } from '../../../../services/user.service';
import { ProvidersSearchService } from '../../../../services/providers-search/providers-search.service';
import { UnleashService } from '../../../../services/unleash.service';
import { AuthService } from '../../../account/login/auth.service';
import { PlanSelectionStoreService } from '../../../../services/stores/plan-selection-store/plan-selection-store.service';

export interface DropDownMenu {
	name: string;
	label: string;
	icon: string;
	func: Function;
	disabled?: boolean;
	hidden?: boolean;
}
@Component({
	selector: 'app-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnDestroy {
	private _unsubscribe = new Subject<void>();
	private itemNameToHide;
	public phoneNumber: string = undefined;

	@T('Contact Us')
	contactUs: string;

	@T('Language')
	lang: string;

	@T('Change Password')
	changePass: string;

	@T('Logout')
	logout: string;

	@T('Privacy policy')
	privacy: string;

	@T('Activate verification via sms')
	private verifyPhone: string;

	@T('Change phone number')
	private changePhone: string;

	@T('Email / Text notifications')
	private toggleNotifications: string;

	public userDropDownMenu: DropDownMenu[] = [
		{
			name: 'lang',
			label: '',
			icon: '/assets/icons/icon-language.svg',
			func: () => this.appManager.openChangeLanguageDialog(),
		},
		{
			name: 'password',
			label: '',
			icon: '/assets/icons/icon-security.svg',
			func: () => this.appManager.openChangePasswordModal(),
		},
		{
			name: 'verifyPhone',
			label: '',
			icon: '/assets/icons/icon-verify-phone.svg',
			func: () => this.appManager.openVerifyPhoneNumber(),
		},
		{
			name: 'changePhone',
			label: '',
			icon: '/assets/icons/icon-verify-phone.svg',
			func: () => this.appManager.openChangePhoneNumber(),
		},
		{
			name: 'toggleNotifications',
			label: '',
			icon: '/assets/icons/icon-notifications.svg',
			func: () => this.appManager.openToggleNotifications(),
		},
		{
			name: 'contact',
			label: '',
			icon: '/assets/icons/icon-contact-us.svg',
			func: () => this.appManager.openContactUsModal(),
		},
		{
			name: 'privacy',
			label: '',
			icon: '/assets/icons/icon-privacy-policy.svg',
			func: () => this.uiService.navigate(['/me', 'privacy-policy']),
		},
		{
			name: 'logout',
			label: '',
			icon: '/assets/icons/icon-log-out.svg',
			func: () => this.appManager.openLogoutConfirmationModal(),
		},
	];

	constructor(
		private appManager: AppManagerService,
		public languageService: LanguageService,
		private uiService: UIService,
		private userService: UserService,
		private authService: AuthService,
		private unleashService: UnleashService,
		private providersSearchService: ProvidersSearchService,
		private planSelectionStoreService: PlanSelectionStoreService
	) {}

	ngOnInit(): void {
		this.languageService.appLanguage$.subscribe(() => {
			this.populateTranslatedItems();
		});
		this.languageService.supportedLanguages$.pipe(take(1)).subscribe((supportedLanguages) => {
			if (supportedLanguages?.length <= 1) {
				for (const item of this.userDropDownMenu) {
					if (item.name === 'lang') item.hidden = true;
				}
			}
		});

		this.userService.userData$.pipe(takeUntil(this._unsubscribe)).subscribe({
			next: (userData) => {
				this.phoneNumber = userData.phoneNumber;

				this.itemNameToHide = this.phoneNumber ? 'verifyPhone' : 'changePhone';
				this.userDropDownMenu.find((item) => item.name === this.itemNameToHide).hidden = true;
				if (this.phoneNumber) {
					this.userDropDownMenu.find((item) => item.name === 'password').hidden = true;
				}
			},
		});

		if (this.uiService.PCTOnlyMode) {
			this.userDropDownMenu.map((item) => {
				if (
					item.name === 'lang' ||
					item.name === 'verifyPhone' ||
					item.name === 'changePhone' ||
					item.name === 'toggleNotifications'
				)
					item.hidden = true;
			});
		}
	}

	private populateTranslatedItems() {
		let c = 0;
		this.userDropDownMenu[++c].label = this.lang;
		this.userDropDownMenu[++c].label = this.verifyPhone;
		this.userDropDownMenu[++c].label = this.changePhone;
		this.userDropDownMenu[++c].label = this.toggleNotifications;
		this.userDropDownMenu[++c].label = this.contactUs;
		this.userDropDownMenu[++c].label = this.privacy;
		this.userDropDownMenu[++c].label = this.logout;
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}
}
