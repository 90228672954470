<div
	*ngIf="showHeader"
	class="px-16 py-20 sm-d-flex sm-justify-content-space-between border-border-color-light-blue background-azure-radiance-5"
>
	<div class="mb-16 sm-mb-0">
		<div
			class="font-weight-700 font-size-20 text-align-left my-10 d-flex justify-content-space-between align-items-center"
		>
			{{ plan?.contract?.name }}
			<div class="close-btn mobile material-icons-outlined" (click)="modalClose.emit()">
				<ripple-icon name="close"></ripple-icon>
			</div>
		</div>
		<a
			*ngIf="plan.contract.sbcUrl"
			href="https://assets.healthee.co/contracts_sbc/{{ plan.contract.sbcUrl }}"
			type="application/pdf"
			target="_blank"
		>
			{{ 'Summery of benefits' | translate }}
		</a>
		<div class="text-align-center sm-text-align-left">
			<span
				*ngIf="plan?.contract?.planCareType"
				class="info-chip background-white py-8 px-16 radius-60 d-inline-block border-border-color-light-gray mr-8"
			>
				{{ plan?.contract?.planCareType }}
			</span>
			<span
				*ngIf="plan.hsaEligibility"
				class="info-chip background-white py-8 px-16 radius-60 d-inline-block hsa-color"
			>
				{{ 'HSA Eligible' | translate }}
			</span>
		</div>
	</div>
	<div class="d-flex flex-direction-column align-items-center sm-align-items-flex-end justify-content-space-between">
		<div class="close-btn desktop material-icons-outlined mb-12" (click)="modalClose.emit()">
			<ripple-icon name="close"></ripple-icon>
		</div>
	</div>
</div>

<div
	class="d-flex flex-column flex-1 content px-16 py-16 overflow-y-auto"
	[class.px-16]="!displayAsNonModal"
	[class.inline-plan-details]="displayAsNonModal"
>
	<UT
		*ngIf="plan.serviceData?.length > 0 && showHeader; else customTitle"
		class="font-weight-700 text-dark-blue font-size-18 d-block"
		str="Main services and costs"
	></UT>
	<ng-template #customTitle>
		<UT
			*ngIf="plan.serviceData?.length > 0"
			class="font-weight-700 text-dark-blue font-size-18 d-block text-left customTitle"
			[str]="customTitleStr"
		></UT>
	</ng-template>

	<ng-container *ngIf="plan.meta?.lowCopayEligibleZipCodes">
		<div class="mt-8">
			{{ 'You can control costs by staying in-network.' | translate }}
			<br />
			<span class="drawer-link" (click)="showLowCopayEligibleZipCodes(plan.meta.lowCopayEligibleZipCodes)">
				{{ 'Click here for the MP3 Service Area.' | translate }}
			</span>
		</div>
	</ng-container>

	<div
		*ngIf="plan.serviceData?.length > 0"
		class="mt-8 flex-1 sm-border-border-color-light-blue sm-radius-8 bg-white overflow-auto"
	>
		<div
			class="d-none sm-py-16 sm-px-24 sm-d-inline-flex border-bottom-border-color-light-blue font-weight-700"
			[class.fixed-top-row]="isTableTopRawFixed"
		>
			<div class="service-title sm-flex-1">{{ 'Services' | translate }}</div>
			<div class="sm-flex-2 sm-d-flex">
				<ng-container *ngIf="!plan.meta?.hideDefaultInNetwork">
					<div class="service-item sm-flex-1 sm-d-flex align-items-center">
						<div
							*ngIf="showNetworkCircleIcon"
							class="radius-circle icon-size-8 background-success mr-8"
						></div>
						<div>{{ 'In-network' | translate }}</div>
					</div>
				</ng-container>
				<ng-container *ngFor="let network of additionalNetworks">
					<div class="service-item ml-16 sm-flex-1 sm-d-flex align-items-center">
						<div
							*ngIf="showNetworkCircleIcon"
							class="radius-circle icon-size-8 background-success mr-8"
						></div>
						<div>{{ network }}</div>
					</div>
				</ng-container>
				<div class="service-item ml-16 sm-flex-1 sm-d-flex align-items-center" *ngIf="hasOONCosts">
					<div *ngIf="showNetworkCircleIcon" class="radius-circle icon-size-8 background-error mr-8"></div>
					<div>{{ 'Out-of-network' | translate }}</div>
				</div>
			</div>
		</div>
		<div
			class="py-16 sm-px-24 sm-d-inline-flex sm-justify-content-space-between sm-align-items-center service-items"
			[class.border-bottom-border-color-light-blue]="!isLast"
			[class.service-items]="isTableTopRawFixed"
			*ngFor="let service of sortedServiceData; let isLast = last"
		>
			<div class="service-title font-size-14 text-dark-blue mb-16 sm-mb-0 sm-flex-1">
				{{ service.title | mpiServiceTitle }}
			</div>
			<div class="d-flex sm-flex-2">
				<ng-container *ngIf="!plan.meta?.hideDefaultInNetwork">
					<div class="service-item flex-1 d-flex flex-direction-column">
						<div class="d-flex align-items-center mb-8 sm-d-none">
							<div class="radius-circle icon-size-8 background-success mr-8"></div>
							<div class="font-weight-700 text-dark-blue-shade-2">{{ 'In-network' | translate }}</div>
						</div>
						{{ normalizeServiceValue(service.inNetwork) | mpiServiceTitle }}

						<app-pct-notes [service]="service" [networkType]="'inNetwork'" [variant]="'xs'"></app-pct-notes>
					</div>
				</ng-container>

				<ng-container *ngFor="let network of additionalNetworks">
					<div class="service-item ml-16 flex-1 d-flex flex-direction-column">
						<div class="d-flex align-items-center mb-8 sm-d-none">
							<div class="radius-circle icon-size-8 background-success mr-8"></div>
							<div class="font-weight-700 text-dark-blue-shade-2">{{ network }}</div>
						</div>
						{{ normalizeServiceValue(service.additionalNetworkMap?.[network]?.value) | mpiServiceTitle}}

						<app-pct-notes [service]="service" [networkType]="network" [variant]="'xs'"></app-pct-notes>
					</div>
				</ng-container>
				<div class="service-item ml-16 flex-1 d-flex flex-direction-column" *ngIf="hasOONCosts">
					<div class="d-flex align-items-center mb-8 sm-d-none">
						<div class="radius-circle icon-size-8 background-error mr-8"></div>
						<div class="font-weight-700 text-dark-blue-shade-2">{{ 'Out-of-network' | translate }}</div>
					</div>
					{{ normalizeServiceValue(service.outNetwork) }}

					<app-pct-notes [service]="service" [networkType]="'outNetwork'" [variant]="'xs'"></app-pct-notes>
				</div>
			</div>
		</div>
	</div>
	<p *ngIf="footerText" class="footer-note-text">{{ footerText }}</p>
</div>
