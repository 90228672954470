import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProviderDetails, ProviderGender, ProviderLocation } from '../../../helpers/providers.helpers';

@Component({
	selector: 'app-provider-about',
	templateUrl: './provider-about.component.html',
	styleUrls: ['./provider-about.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderAboutComponent implements OnInit {
	@Input() providerData: ProviderDetails;
	ProviderGender = ProviderGender;
	groups: string[] = [];

	ngOnInit(): void {
		const groups = this.providerData?.locations?.map((location) => location.insuranceGroup).filter((group) => !!group) ?? [];
		this.groups = [...new Set(groups)];
	}
}
