import { Injectable } from '@angular/core';

const TAG = 'SessionService';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
	constructor() {}

	public getItem(itemTag: string): string {
		try {
			return sessionStorage.getItem(itemTag);
		} catch (err) {
			this.handleLocalStorageError(itemTag, err);
		}
	}

	public setItem(itemTag: string, itemValue: string): void {
		try {
			sessionStorage.setItem(itemTag, itemValue);
		} catch (err) {
			this.handleLocalStorageError(itemTag, err);
		}
	}

	public clearItem(itemTag: string): void {
		try {
			sessionStorage.removeItem(itemTag);
		} catch (err) {
			this.handleLocalStorageError(itemTag, err);
		}
	}

	public clearStorage(): void {
		try {
			sessionStorage.clear();
		} catch (err) {
			this.handleLocalStorageError('Clear Storage', err);
		}
	}

	private handleLocalStorageError(errorItemTag: string, error: Error) {
		console.log(TAG, 'Error reading/writing session storage. Clearing error item. Error:', error.message);
		sessionStorage.removeItem(errorItemTag);
	}
}
