import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ripple-slider-buttons',
	templateUrl: './slider-buttons.component.html',
	styleUrls: ['./slider-buttons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderButtonsComponent {
	@Input() activeIndex: number; // if multiple indexes shown, give smallest index
	@Input() minIndex: number = 0;
	@Input() maxIndex: number;
	@Input() set extraIndexesActive(extraIndexesActive: number) { // if multiple indexes shown, amount
		this.navigateBy = Math.max(extraIndexesActive, 1);
	}
	@Input() text: string;
	@Input() isSpaceBetween: boolean = false;
	@Output() setNewIndex = new EventEmitter<number>();

	public navigateBy: number = 1;

	public handlePreviousClick() {
		const newIndex = Math.max(this.activeIndex - this.navigateBy, this.minIndex);
		this.setNewIndex.emit(newIndex);
	}

	public handleNextClick() {
		const maxIndex = this.maxIndex - this.navigateBy;
		const newIndex = Math.min(this.activeIndex + this.navigateBy, maxIndex);
		this.setNewIndex.emit(newIndex);
	}

	public get isPreviousEnabled(): boolean {
		return this.activeIndex > this.minIndex;
	}

	public get isNextEnabled(): boolean {
		return this.activeIndex + this.navigateBy < this.maxIndex;
	}
}
