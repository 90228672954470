<img class="plan-selection-bg" src="assets/images/plan_selection_ilustration.png" alt="" />

<div class="plan-selection-section-body">
	<h1>
		{{ 'Easily compare and select your medical, vision, and dental coverage.' | translate }}
	</h1>
	<button (click)="track()" [routerLink]="[planSelectionPath]" class="primary healthee-btn sm">
		{{ 'Compare your plans' | translate }}
		<img src="assets/images/icons/arrow-right.svg" alt="" />
	</button>
</div>
