import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderAvatarComponent } from './provider-avatar.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ProviderAvatarComponent],
	exports: [ProviderAvatarComponent],
})
export class ProviderAvatarModule {}
