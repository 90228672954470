<div class="logout-container">
	<div class="divider"></div>
	<h3>Your session will expire in {{ autoLogoutCounter }} seconds due to inactivity</h3>
	<div class="divider"></div>
	<div class="action-btns">
		<ripple-button
			[hierarchy]="ButtonHierarchy.tertiary"
			[label]="'Logout' | translate"
			[size]="ButtonSize.medium"
			(click)="closeDialogAndLogout()"
		>
		</ripple-button>
		<ripple-button
			[hierarchy]="ButtonHierarchy.primary"
			[label]="'Stay connected' | translate"
			(click)="closeDialogAndStayConnected()"
			[size]="ButtonSize.medium"
		>
		</ripple-button>
	</div>
</div>
