import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { OpenEnrollmentData } from '../../../../services/plan-selection.service';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { LanguageService } from '../../../../services/language.service';
import { PlanSelectionLocalize } from '../../plan-selection.localize';
import { TrackingService } from '../../../../services/tracking.service';
import { capitalizeFirstLetter, formatDecimalNumber } from 'src/app/utils/utils';
import { isNil } from 'src/app/utils/is/is-nil';
import { ComparisonTableHelper } from '../comparison-table.helper';
import { TxNativeModule } from '@transifex/angular';
import { PctNotesComponent } from '../pct-notes/pct-notes.component';
import { RippleModule } from 'ripple';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from 'src/app/modules/app-shared/app-shared.module';

@Component({
	selector: 'app-plan-details-modal',
	templateUrl: './plan-details-modal.component.html',
	styleUrls: ['./plan-details-modal.component.scss'],
	standalone: true,
	imports: [CommonModule, TxNativeModule, PctNotesComponent, RippleModule, AppSharedModule],
})
export class PlanDetailsModalComponent implements OnInit {
	@Input() showHeader?: boolean = true;
	@Input() showNetworkCircleIcon?: boolean = true;
	@Input() displayAsNonModal?: boolean = false; // default is PCT modal display type
	@Input() footerText?: string;

	get plan() {
		return this.planObject;
	}
	@Input() set plan(value: any) {
		this.planObject = value;
		this.onPlanChange();
	}

	additionalNetworks: string[] = [];
	@Input() planType?: string = 'medical';
	@Input() isTableTopRawFixed?: boolean = true;
	@Output() lowCopayEligibleZipCodesClick? = new EventEmitter<string[]>();
	@Output() modalClose? = new EventEmitter();

	public planObject: any;
	public sortedServiceData: any[] = [];
	public hasOONCosts: boolean;
	public tooltipText: any;
	public isNil = isNil;

	constructor(
		public healtheeDialogService: HealtheeDialogService,
		private languageService: LanguageService,
		private trackingService: TrackingService,
		private helper: ComparisonTableHelper
	) {}

	ngOnInit(): void {
		this.subscribeToLocale();
	}

	public format(num: number, notApplicableResult?: string): string {
		return formatDecimalNumber(num, notApplicableResult);
	}

	private subscribeToLocale() {
		this.languageService.appLanguage$.subscribe(() => {
			this.populateTranslatedTooltip();
		});
	}

	public populateTranslatedTooltip(): void {
		this.tooltipText = {
			premiumPerPayPeriod: PlanSelectionLocalize.premiumPerPayPeriod_tooltip,
			yearlyDeductible: PlanSelectionLocalize.yearlyDeductible_tooltip,
			outOfPocketMax: PlanSelectionLocalize.outOfPocketMax_tooltip,
		};
	}

	public normalizeServiceValue(text: string): string {
		return this.helper.normalizeServiceValue(text);
	}

	public showLowCopayEligibleZipCodes(zipCodes: string[]) {
		this.lowCopayEligibleZipCodesClick.emit(zipCodes);
	}

	private onPlanChange() {
		this.additionalNetworks = [
			...new Set<string>(
				this.planObject.serviceData?.flatMap(
					(service) => service.additionalNetworks?.flatMap((network) => network.name) ?? []
				)
			),
		];

		this.setAdditionalNetworksInAnthemForOptumServices();

		const distinctServices: any[] = [
			...new Map(
				this.planObject.serviceData.map((service) => [
					service.title,
					{
						...service,
						additionalNetworkMap: service.additionalNetworks?.reduce((acc, network) => {
							acc[network.name] = network;
							return acc;
						}, {}),
					},
				])
			).values(),
		];

		this.sortedServiceData = distinctServices.sort((a, b) => a.title.localeCompare(b.title));

		this.hasOONCosts =
			this.planObject?.contract?.planCareType !== 'RBP' &&
			this.planObject.serviceData.some((obj) => !!obj.outNetwork);
	}

	get customTitleStr(): string {
		return capitalizeFirstLetter(this.planType) + ' Services';
	}

	// Manually set additional networks for Optum Plan Services inside Anthem Plan
	private setAdditionalNetworksInAnthemForOptumServices() {
		//improvment: have this as a global constant
		if (this.planObject?.contract?._id !== '656f43a0a6f244f2ee8dfb11') return;

		if (!this.planObject.meta?.hideDefaultInNetwork || !this.planObject.meta?.isRelatedToOptum) return;

		this.planObject.serviceData
			.filter((service) => service.additionalNetworks.length == 0)
			.map((service) => {
				service.additionalNetworks = [
					{
						name: 'Anthem Blue Cross',
						subjectToDeductible: false,
						value: service.inNetwork,
					},
					{
						name: 'MP3',
						subjectToDeductible: false,
						value: service.inNetwork,
					},
				];
			});
	}
}
