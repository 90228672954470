<ion-header *ngIf="isIonic" class="ion-no-border">
	<div
		class="action-bar"
		[ngClass]="{
			'bottom-border': showBottomBorder,
			'android-action-bar': isAndroid
		}"
	>
		<!-- left-side 'back' navigation -->
		<span>
			<img
				*ngIf="ionicBackOverrideLink !== DISABLED_BACK_NAVIGATION"
				src="/assets/images/icons-ionic/arrow-narrow-left.svg"
				(click)="navigateBack()"
			/>
			<span *ngIf="ionicBackOverrideLink === DISABLED_BACK_NAVIGATION">
				<img class="d-inline-block sm-d-none width-40" src="/assets/images/logo.svg" alt="Logo" />
			</span>
		</span>

		<!-- middle 'title' -->
		<span class="title"></span>

		<!-- right side 'notification-bell' icon -->
		<span
			class="bell-container"
		>

			<img
				(click)="uiService.navigate(['/mobile-app-settings'])"
				class="icon-size-24 cursor-pointer cypress-testing-selector-settings"
				src="/assets/icons/icon-settings.svg"
				alt=""
			/>
		</span>
	</div>
</ion-header>
