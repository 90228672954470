import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipBottomSheetComponent } from './tooltip-bottom-sheet/tooltip-bottom-sheet.component';
import { TooltipBubbleComponent } from './tooltip-bubble/tooltip-bubble.component';
import { isNil } from '../utils';

@Directive({
	selector: '[rippleTooltipWithTouch]',
})
export class TooltipWithTouchDirective implements OnInit, OnDestroy {
	@Input('rippleTooltipWithTouch') template: TemplateRef<any>;
	@Input('rippleTooltipWithTouchDisabled') disabled = false;
	@Input() title: string;
	@Input() rippleTooltipWithTouchOffsetY: number = 5;
	@Input() rippleTooltipWithTouchDynamicWidth = false;

	@Output() tooltipClosed = new EventEmitter<void>();
	@Output() tooltipOpened = new EventEmitter<void>();

	private overlayRef: OverlayRef;
	private overlayBottomRef: OverlayRef;
	private isTouchDevice: boolean = false;

	@HostListener('touchstart')
	handleTouchStart() {
		this.isTouchDevice = true;
	}

	@HostListener('click')
	showMobile() {
		if (this.disabled) {
			return;
		}

		if (!this.isTouchDevice) {
			this.hide();
			return;
		}

		if (isNil(this.overlayBottomRef) || this.overlayBottomRef.hasAttached()) {
			return;
		}

		// Open sheet
		const tooltipRef = this.overlayBottomRef.attach(new ComponentPortal(TooltipBottomSheetComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.instance.title = this.title;
		this.tooltipOpened.emit();

		// Close sheet
		tooltipRef.instance.closeClicked.pipe().subscribe(() => this.onTooltipCloseTriggered());
		this.overlayBottomRef.backdropClick().subscribe(() => this.onTooltipCloseTriggered());
	}

	onTooltipCloseTriggered() {
		this.isTouchDevice = false;
		this.tooltipClosed.emit();
		this.overlayBottomRef.detach();
	}

	@HostListener('mouseenter')
	show() {
		if (this.disabled || this.isTouchDevice) {
			return;
		}

		if (isNil(this.overlayRef) || this.overlayRef.hasAttached()) {
			return;
		}

		const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipBubbleComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.instance.isDynamicWidth = this.rippleTooltipWithTouchDynamicWidth;

		tooltipRef.changeDetectorRef.markForCheck();
		this.tooltipOpened.emit();
	}

	@HostListener('mouseleave')
	hide() {
		if (this.isTouchDevice) {
			return;
		}

		if (!isNil(this.overlayRef)) {
			this.overlayRef.detach();
			this.tooltipClosed.emit();
		}
	}

	constructor(private elementRef: ElementRef, private overlay: Overlay, private overlayBottom: Overlay) {}

	ngOnInit(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.elementRef)
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: 'center',
						overlayY: 'top',
						offsetY: this.rippleTooltipWithTouchOffsetY,
					},
					{
						originX: 'center',
						originY: 'top',
						overlayX: 'center',
						overlayY: 'bottom',
						offsetY: -this.rippleTooltipWithTouchOffsetY,
					},
				]),
		});

		this.overlayBottomRef = this.overlayBottom.create({
			hasBackdrop: true,
			scrollStrategy: this.overlayBottom.scrollStrategies.block(),
			positionStrategy: this.overlayBottom.position().global().centerHorizontally().bottom(),
		});
	}

	ngOnDestroy(): void {
		this.overlayRef.detach();
		this.overlayBottomRef.detach();
	}
}
