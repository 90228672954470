<div
	#sliderStrip
	[class.without-arrows]="hideArrows"
	class="slider-container"
	[class]="customCssClassNameList + ' ' + appearance"
>
	<div *ngIf="!hideArrows" class="control">
		<button [ngSwitch]="sliderContentScroll <= SCROLL_START" class="to-start" (click)="scrollContent('left')">
			<img *ngSwitchCase="true" src="../../../../assets/images/icons/chevron-left-unactive.svg" alt="" />
			<img *ngSwitchCase="false" src="../../../../assets/images/icons/chevron-left.svg" alt="" />
		</button>
	</div>

	<div #sliderContentWrapper (scroll)="sliderContentScrolled($event)" class="slider-content-wrapper">
		<ng-content></ng-content>
	</div>

	<div *ngIf="!hideArrows" class="control">
		<button [ngSwitch]="!enableScrollLeft" class="to-end" (click)="scrollContent('right')">
			<img *ngSwitchCase="true" src="../../../../assets/images/icons/chevron-right-unactive.svg" alt="" />
			<img *ngSwitchCase="false" src="../../../../assets/images/icons/chevron-right.svg" alt="" />
		</button>
	</div>
</div>
