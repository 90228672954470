import { Component, OnInit } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import CFG from '../../../config/app-config.json';

import { UserService } from '../../../services/user.service';
import { PlanSelectionService } from '../../../services/plan-selection.service';
import { TrackingService } from '../../../services/tracking.service';

import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';

import { HomePageService } from '../services/home-page.service';
import { MY_CARE_TEAM_HOME_PAGE_TEASER, PREVENTIVE_CARE } from '../../feature-flags/flag-keys';
import { RxjsUtils } from '../../../utils/rxjs';
import { FavoriteProvidersStoreService } from '../../../services/stores/favorite-providers-store/favorite-providers-store.service';
import { FeatureFlag } from '../../../models/feature-flag.model';
import { UnleashService } from '../../../services/unleash.service';
import { LanguageService } from '../../../services/language.service';
import { ServicesResponse } from 'src/app/services/api/employee-api/helpers/services.response';

import { FfDigitalCard } from '../../../config/feature-flags/ff-digital-card';
import { MeUserStoreService } from '../../../services/stores/me-user-store/me-user-store.service';
import { isNil } from '../../../utils/is/is-nil';
import { Router } from '@angular/router';
import { PremiumFeatureService } from 'src/app/services/premium-feature.service';
import { PremiumFeature } from 'src/app/models/premium-feature.model';
import { UIService } from 'src/app/services/ui.service';
import { PlanSelectionStoreService } from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { UserData } from '../../../models/user-data';
import { CompanyStoreService } from '../../../services/stores/company-store/company-store.service';
import { isIonic } from '../../../utils/utils';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	myCareTeamHomePageTeaserEnabled: boolean = false; //this.unleashService.isEnabled(MY_CARE_TEAM_HOME_PAGE_TEASER); - MPI requested to disable this feature
	SERVICES_LENGTH_ON_TEASER = 4; // Before increasing this to >4, add also services in the constant -> AnthemPopularBenefitIds

	FeatureFlag = FeatureFlag;

	userBenefitsMap$: Observable<Map<string, any>> = this.homePageService.userBenefitsMap$;

	showDeductibleTeaser$: Observable<boolean>;

	showTelehealthTeaser$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);

	public showPreventiveCareTeaser: boolean = true; // this.unleashService.isEnabled(PREVENTIVE_CARE); - MPI requested to disable this feature

	isDigitalCardEnabled$ = this.unleashService.isEnabled$(FfDigitalCard);

	teasersOrder$ = this.homePageService.teasersOrder$;

	selectedBenefits$: Observable<ServicesResponse[]>;

	hasFavoriteProviders$: Observable<boolean> = this.favoriteProvidersStore.get().pipe(
		RxjsUtils.isNotNil(),
		map((providers) => providers.length > 0)
	);

	private userHasGroupNumber$ = this.userPlanDataStoreService.hasGroupNumber();

	isHomePageHealthCardTeaserAvailable$: Observable<boolean>;

	planSelectionPath: string;

	public isMobile$: Observable<boolean> = this.uiService.isMobile$;
	public isIonic: boolean = isIonic();
	public isPlanSelectionEnabled$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();
	isDeductibleSyncing: boolean = false;

	public isPreventiveCareInit$: Observable<boolean> = this.userService.userData$.pipe(
		map((userData: UserData) => userData.isHomePagePreventiveCareInit)
	);

	public hasWellnessTrackerFeature: boolean = false;
	public hasParticipantAccDataFeature: boolean = false;

	private unsubscribe = new Subject<void>();

	public showQuantumSupport: boolean = false;

	constructor(
		private userService: UserService,
		private planSelectionService: PlanSelectionService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private trackingService: TrackingService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private homePageService: HomePageService,
		private favoriteProvidersStore: FavoriteProvidersStoreService,
		public languageService: LanguageService,
		private meUserStoreService: MeUserStoreService,
		private unleashService: UnleashService,
		public router: Router,
		private premiumFeatureService: PremiumFeatureService,
		private uiService: UIService,
		private companyStoreService: CompanyStoreService
	) {
		this.showDeductibleTeaser$ = this.userPlanDataStoreService.hasContractByType('contract');
	}

	get hasContract$(): Observable<boolean> {
		return this.userPlanDataStoreService.hasContract();
	}

	get firstName$(): Observable<string> {
		return this.userService.userData$.pipe(map((userData) => userData.firstName));
	}

	get showDefaultLayout$(): Observable<boolean> {
		return this.userService.userData$.pipe(map((userData) => userData.esiEligible));
	}

	ngOnInit(): void {
		this.planSelectionPath = this.getPlanSelectionPath();
		this.selectedBenefits$ = this.homePageService.getMostPopularCoveredServices(this.SERVICES_LENGTH_ON_TEASER);
		this.isHomePageHealthCardTeaserAvailable$ = combineLatest([
			this.isDigitalCardEnabled$,
			this.meUserStoreService
				.get()
				.pipe(map((me) => (isNil(me) ? false : me.isHomePageHealthCardTeaserAvailable))),
			this.userHasGroupNumber$,
		]).pipe(
			map(([isDigitalCardEnabled, meUserStoreService, userHasGroupNumber]) => {
				return isDigitalCardEnabled && meUserStoreService && userHasGroupNumber;
			})
		);

		this.companyStoreService
			.get()
			.pipe(
				tap((company) => {
					this.hasWellnessTrackerFeature =
						company?.showWellnessTracker === undefined || company?.showWellnessTracker;
					this.hasParticipantAccDataFeature =
						company?.showParticipantAccumulatorData === undefined ||
						company?.showParticipantAccumulatorData;
				})
			)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe();

		this.companyStoreService
			.get()
			.pipe(filter((value) => !!value))
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((company) => {
				this.showQuantumSupport = !!(company.name === 'Charter Schools');
			});
	}

	onSeeAllBenefitsClick(): void {
		this.trackingService.trackClientEvent('Home Page See All Benefits Click');
		this.trackingService.trackClientEvent('Benefits Gallery Start', { source: 'Benefits teaser' });
	}

	onAllProvidersClick(): void {}

	getPlanSelectionPath() {
		return '/' + CFG.openEnrollmentRoute;
	}

	onDeductibleSyncStatusChanged($event: any) {
		this.isDeductibleSyncing = $event;
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
