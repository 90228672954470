import { Pipe, PipeTransform } from '@angular/core';
import { getNextAppointmentDate } from '../helpers/helpers';

@Pipe({
	name: 'nextAppointmentDate',
})
export class NextAppointmentDatePipe implements PipeTransform {
	transform(date: Date, frequency: number): Date {
		return getNextAppointmentDate(date, frequency);
	}
}
