<div class="wrapper" [ngClass]="'cards-' + contents.length">
	<div class="grid">
		<ng-container *ngFor="let content of contents; index as i">
			<div class="content" [ngClass]="'content-' + (i + 1)">
				<ng-container
					[ngTemplateOutlet]="this[content.type]"
					[ngTemplateOutletContext]="{ $implicit: content }"
				></ng-container>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #regular let-content>
	<div class="regular">
		<div class="header" *ngIf="content.headline">{{ content.headline }}</div>
		<div class="body">
			<div class="text">
				<div class="text-item" *ngFor="let item of content.content">
					{{ item.value }}
				</div>
			</div>
			<div class="text justify-end" *ngIf="content.rightContent">
				<div class="text-item" *ngFor="let item of content.rightContent">
					{{ item.value }}
				</div>
			</div>
		</div>
		<div class="footer" *ngIf="content.logo">
			<img
				[ngSrc]="'https://assets.healthee.co/' + customCardType + '_logos/' + content.logo + '_small.png'"
				alt=""
				fill
			/>
		</div>
	</div>
</ng-template>

<ng-template #middleHeadline let-content>
	<div class="middleHeadline">
		<div class="text" *ngFor="let item of content.content">{{ item.value }}</div>
		<div class="headline">{{ content.middleHeadline }}</div>
		<div class="info">
			<div class="item" *ngFor="let secondary of content.contentSecondry">
				<div class="title">{{ secondary.left.value }}</div>

				<div *ngIf="secondary.right.type === 1; else web">{{ secondary.right.value }}</div>
				<ng-template #web>
					<a class="web" [href]="'https://' + secondary.right.value" target="_blank">{{
						secondary.right.value
					}}</a>
				</ng-template>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #list let-content>
	<div class="list">
		<div class="header">
			{{ content.headline }}
		</div>
		<div class="info">
			<div class="item" *ngFor="let item of content.content">
				<div class="title">{{ item.left.value }}</div>
				<div class="value">{{ item.right.value }}</div>
			</div>
		</div>
		<div class="footer" *ngIf="content.logo">
			<img
				[ngSrc]="'https://assets.healthee.co/' + customCardType + '_logos/' + content.logo + '_small.png'"
				alt=""
				fill
			/>
		</div>
	</div>
</ng-template>

<ng-template #mix let-content>
	<div class="mix">
		<div class="header">
			{{ content.headline }}
		</div>
		<div class="body">
			<div class="item" *ngFor="let item of content.content">
				<div class="regular" *ngIf="item.contentNodeType === 1">
					<div *ngIf="item.type === 1" class="text-item">
						{{ item.value }}
					</div>
					<a
						*ngIf="item.type === 2"
						ngClass="type-{{ item.type }}"
						class="text-item"
						[href]="'https://' + item.value"
						target="_blank"
						>{{ item.value }}</a
					>
					<div
						*ngIf="item.type === 3"
						ngClass="type-{{ item.type }}"
						class="text-item"
						(click)="callPhone(item.value)"
					>
						<div class="value">{{ item.value }}</div>
					</div>
				</div>
				<div class="pair" *ngIf="item.contentNodeType === 2">
					<div *ngIf="item.left.type === 1" class="title">{{ item.left.value }}</div>
					<a
						*ngIf="item.left.type === 2"
						ngClass="type-{{ item.left.type }}"
						class="title"
						[href]="'https://' + item.left.value"
						target="_blank"
						>{{ item.left.value }}</a
					>
					<div
						*ngIf="item.left.type === 3"
						ngClass="type-{{ item.left.type }}"
						class="text-item"
						(click)="callPhone(item.left.value)"
					>
						<div class="value">{{ item.left.value }}</div>
					</div>
					<div *ngIf="item.right.type === 1" class="value">{{ item.right.value }}</div>
					<a
						*ngIf="item.right.type === 2"
						ngClass="type-{{ item.right.type }}"
						class="title"
						[href]="'https://' + item.right.value"
						target="_blank"
						>{{ item.right.value }}</a
					>
					<div
						*ngIf="item.right.type === 3"
						ngClass="type-{{ item.right.type }}"
						class="text-item"
						(click)="callPhone(item.right.value)"
					>
						<div class="value">{{ item.right.value }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer" *ngIf="content.logo">
			<img
				[ngSrc]="'https://assets.healthee.co/' + customCardType + '_logos/' + content.logo + '_small.png'"
				alt=""
				fill
			/>
		</div>
	</div>
</ng-template>
