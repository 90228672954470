import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { MainLayoutSharedModule } from 'src/app/modules/main-layout/main-layout-shared/main-layout-shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import { CareAndCostsSharedModule } from 'src/app/modules/main-layout/care-costs/shared/care-and-costs-shared.module';
import { CareAndCostsRoutingModule } from 'src/app/modules/main-layout/care-costs/care-costs-routing.module';

import { CareAndCostsComponent } from './care-and-costs.component';
import { ProviderDetailsPageComponent } from './provider-details-page/provider-details-page.component';
import { FacilityDetailsPageComponent } from './facility-details-page/facility-details-page.component';
import { ProvidersSearchResultsPageComponent } from './providers-search-results-page/providers-search-results-page.component';
import { ProvidersSearchPageModule } from 'src/app/modules/main-layout/care-costs/providers-search-page/providers-search-page.module';

@NgModule({
	declarations: [
		CareAndCostsComponent,
		ProviderDetailsPageComponent,
		FacilityDetailsPageComponent,
		ProvidersSearchResultsPageComponent,
	],
	exports: [],
	imports: [
		CommonModule,
		TxNativeModule,
		CareAndCostsRoutingModule,
		MainLayoutSharedModule,
		MatTabsModule,
		RouterLink,
		CareAndCostsSharedModule,
		ProvidersSearchPageModule,
	],
})
export class CareCostsModule {}
