import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-primary-care-specialty',
	templateUrl: './primary-care-specialty.component.html',
	styleUrls: ['./primary-care-specialty.component.scss'],
})
export class PrimaryCareSpecialtyComponent {
	@Input() specialty: any = {};
	@Output() categoryClick: EventEmitter<string> = new EventEmitter();

	constructor() {}

	onClick(value: string): void {
		this.categoryClick.emit(value);
	}
}
