import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-clinical-area',
	templateUrl: './clinical-area.component.html',
	styleUrls: ['./clinical-area.component.scss'],
})
export class ClinicalAreaComponent {
	@Input() data;

	public isExpanded: boolean = false;
}
