import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TxNativeModule } from '@transifex/angular';
import { RippleModule } from 'ripple';
import { TooltipWithTouchModule } from '../../app-shared/standalone/tooltip-with-touch/tooltip-with-touch.module';

const NUMBER_OF_COLUMNS = 3;

@Component({
	selector: 'app-powered-by-footer',
	templateUrl: './powered-by-footer.component.html',
	styleUrls: ['./powered-by-footer.component.scss'],
	standalone: true,
	imports: [TxNativeModule, CommonModule],
})
export class PoweredByFooterComponent {}
