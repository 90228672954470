import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderSize } from 'ripple';
import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';
import { ReferraceinfoStoreService } from 'src/app/services/stores/referrance-info-store/referrance-info-store.service';

@Component({
	selector: 'app-referrals-table',
	templateUrl: './referral-table.component.html',
	styleUrls: ['./referral-table.component.scss'],
})
export class ReferralsTableComponent implements OnInit {
	public referralsInfo$ = this.referraceinfoStoreService.getReferralsData();
	public isLoading$ = this.referraceinfoStoreService.isLoading$;
	public isSuccess$ = this.referraceinfoStoreService.isSuccess$;

	public dataSource: MatTableDataSource<ReferralsInfoResponse>;
	public loaderSize = LoaderSize.medium;
	public displayedColumns: string[] = [
		'ProviderFromFirstName',
		'ProviderToFirstName',
		'CreatedDate',
		'ExprDate',
		'Status',
		'actions',
	];

	@ViewChild(MatSort) sort: MatSort;

	public constructor(
		private _liveAnnouncer: LiveAnnouncer,
		private referraceinfoStoreService: ReferraceinfoStoreService
	) {}

	public ngOnInit(): void {
		this.referralsInfo$.subscribe((result: ReferralsInfoResponse[]) => {
			this.dataSource = new MatTableDataSource(result);
			this.dataSource.sort = this.sort;
		});
	}

	ngAfterViewInit() {
		if (this.dataSource.data) {
			this.dataSource.sort = this.sort;
		}
	}

	public announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}
}
