<div>
	<table
		mat-table
		[dataSource]="dataSource"
		class="referrals-table"
		matSortActive="CreatedDate"
		matSortDirection="desc"
		matSortStart="desc"
		matSort
		matSortDisableClear="true"
		(matSortChange)="announceSortChange($event)"
	>
		<!-- Position Column -->
		<ng-container matColumnDef="ProviderFromFirstName" class="header-title">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by referred by">
				Referred by
			</th>
			<td mat-cell *matCellDef="let element">
				<app-referral-referred-content [item]="element"></app-referral-referred-content>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="ProviderToFirstName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by referred to">
				Referred to
			</th>
			<td mat-cell *matCellDef="let element">
				<app-referral-referred-content [referredTo]="true" [item]="element"></app-referral-referred-content>
			</td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="CreatedDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by issue date">
				Issue date
			</th>
			<td mat-cell *matCellDef="let element">
				<div class="content">{{ element.CreatedDate | date : 'MMM d, y' }}</div>
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="ExprDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by expiration date">
				Expiration date
			</th>
			<td mat-cell *matCellDef="let element">
				<div class="content">{{ element.ExprDate | date : 'MMM d, y' }}</div>
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="Status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
			<td mat-cell *matCellDef="let element">
				<app-referral-status [value]="element.Status"></app-referral-status>
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef>Actions</th>
			<td mat-cell *matCellDef="let element">
				<app-referral-download [item]="element"></app-referral-download>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="loader" *ngIf="isLoading$ | async">
		<app-loader [size]="'medium'"></app-loader>
	</div>

	<app-referrals-no-results
		[marginTop]="true"
		*ngIf="(isLoading$ | async) === false && (referralsInfo$ | async).length === 0"
	></app-referrals-no-results>
</div>
