import { Capacitor } from '@capacitor/core';

const LOCAL_ADDRESSES = [
	/localhost/,
	/^::1$/, // IPv6 localhost address
	/^127\.\d+\.\d+\.\d+$/, // loopback IP addresses ranging from 127.0.0.1 to 127.255.255.255
	/^10\.\d+\.\d+\.\d+$/, // IP addresses ranging from 10.0.0.0 to 10.255.255.255
	/^172\.(1[6-9]|2\d|3[01])\.\d+\.\d+$/, // IP addresses ranging from 172.16.0.0 to 172.31.255.255
	/^192\.168\.\d+\.\d+$/, // IP addresses ranging from 192.168.0.0 to 192.168.255.255
];

export function isLocalEnv(windowObject?: Window): boolean {
	if (!windowObject) {
		windowObject = window;
	}

	return Capacitor.getPlatform() === 'web' && LOCAL_ADDRESSES.some(address => address.test(windowObject.location.hostname));
}
