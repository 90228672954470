<app-deductibles-card [isRed]="isRed">
	<div
		*ngIf="total === null || total === undefined"
		class="medical-spending-connected-deductible-card-not-applicable"
	>
		{{ 'N/A' | translate }}
	</div>

	<div *ngIf="!(total === null || total === undefined)" class="medical-spending-connected-deductible-card">
		<div class="medical-spending-connected-deductible-card-header">
			<div class="medical-spending-connected-deductible-card-header-network-name">
				<div class="medical-spending-deductibles-dot" [class.red]="isRed"></div>
				{{ isRed ? ('Out-of-Network' | translate) : ('In-Network' | translate) }}
			</div>
			<div
				*ngIf="!hasMyselfBadge && !hasFamilyBadge"
				class="medical-spending-connected-deductible-card-header-network-total"
			>
				{{ 'Total' | translate }}
				{{ total | currency }}
			</div>
			<div *ngIf="hasMyselfBadge" class="medical-spending-connected-deductible-card-header-network-myself-badge">
				{{ 'Myself' | translate }}
			</div>
			<div *ngIf="hasFamilyBadge" class="medical-spending-connected-deductible-card-header-network-myself-badge">
				{{ 'Family' | translate }}
			</div>
		</div>
		<div class="medical-spending-connected-deductible-card-progress-bar">
			<div [ngStyle]="{ width: (spendPercentage$ | async) + '%' }"></div>
		</div>
		<div class="medical-spending-connected-deductible-card-amounts">
			<div>
				<span class="medical-spending-connected-deductible-card-amounts-number">{{ spend | currency }}</span>
				<span class="medical-spending-connected-deductible-card-amounts-subtitle">
					{{ 'Consumed' | translate }}
				</span>
			</div>
			<div>
				<span class="medical-spending-connected-deductible-card-amounts-number">{{
					remaining | currency
				}}</span>
				<span class="medical-spending-connected-deductible-card-amounts-subtitle remaining">
					{{ 'Remaining' | translate }}
					<app-healthee-mark-tooltip
						text="You have {{
							remaining | currency
						}} left to pay before your plan begins to pay for covered services."
						[mobileTitle]="'Remaining' | translate"
					>
					</app-healthee-mark-tooltip>
				</span>
			</div>
		</div>
		<div class="medical-spending-connected-deductible-card-out-of-pocket">
			<div class="medical-spending-connected-deductible-card-out-of-pocket-title">
				{{ 'Out of Pocket maximums' | translate }}
				<app-healthee-mark-tooltip
					text="Full coverage starts after you spend {{
						outOfPocketTotal | currency
					}} on services that count toward your out-of-pocket max"
					[mobileTitle]="'Out of Pocket maximums' | translate"
				>
				</app-healthee-mark-tooltip>
			</div>

			<div class="medical-spending-connected-deductible-card-out-of-pocket-numbers">
				<span>{{ outOfPocketSpend | currency }}</span>
				<span>/{{ outOfPocketTotal | currency }}</span>
			</div>
		</div>
	</div>
</app-deductibles-card>
