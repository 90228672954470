<div
	class="ripple-checkbox"
	[class.variant-checked]="isChecked"
	[class.has-hint]="!!hint"
	(click)="toggleCheck()"
>
	<div class="ripple-checkbox__checkbox-container">
		<ripple-icon
			[name]="isChecked ? 'checkboxChecked' : 'checkboxUnchecked'"
		></ripple-icon>
	</div>

	<div class="ripple-checkbox__text">
		<div
			class="ripple-checkbox__label"
			[class.variant-underlined]="tooltip || isUnderlined"
			[rippleTooltipWithTouch]="tooltipTemplate"
			[rippleTooltipWithTouchDisabled]="!tooltip"
		>
			<ng-content></ng-content>
		</div>

		<ng-template #tooltipTemplate>
			{{ tooltip }}
		</ng-template>

		<div *ngIf="hint" class="ripple-checkbox__hint">
			{{ hint }}
		</div>
	</div>
</div>
