<!-- ionic-only action-bar. Not rendered on web version -->
<app-mobile-action-bar></app-mobile-action-bar>
<nav
	*ngIf="shouldShowTabs"
	mat-tab-nav-bar
	class="tabs-header"
	[tabPanel]="tabPanel"
	[attr.mat-align-tabs]="tabsAlignment"
	mat-stretch-tabs="false"
>
	<a
		mat-tab-link
		[routerLink]="['/care-and-costs/providers']"
		[active]="activeTabId === Tab.Providers"
		(selectedTabChange)="onSelectedTabChange(Tab.Providers)"
	>
		{{ getTabTitle() | async }}
	</a>
	<a
		*ngIf="hasMP3providerSearchSupport$ | async"
		mat-tab-link
		[routerLink]="['/care-and-costs/mp3-providers']"
		[active]="activeTabId === Tab.MP3Providers"
		(selectedTabChange)="onSelectedTabChange(Tab.MP3Providers)"
	>
		{{ 'MP3 Providers ' | translate }}
	</a>
</nav>
<mat-tab-nav-panel #tabPanel>
	<router-outlet></router-outlet>
</mat-tab-nav-panel>
