import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { PlanData } from '../../../models/plan-data.model';
import { PlanType } from '../../../models/plan-type.model';
import { map, Observable, of, shareReplay } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { UserApiService } from '../../api/user-api/user-api.service';
import { isNil } from '../../../utils/is/is-nil';
import { PlanClassType } from '../../../models/domain/plan-class-type';
import { DeductiblesData } from './helpers/deductibles-data';
import { getPath } from '../../../utils/get-path';
import { isArray } from '../../../utils/is/is-array';
import { UIService } from '../../ui.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { Contract } from 'src/app/models/contract.model';
import { MpiPlan, MpiPlanId } from 'src/app/utils/mpi/mpi.constant';

const INVALID_COMPANY_ID_FOR_DEDUCTIBLE_TEASER_AND_TALON = '60181af06df9235a655c24de'; // Kayser

@Injectable({
	providedIn: 'root',
})
export class UserPlanDataStoreService extends Store<void, PlanData> {
	constructor(private userApiService: UserApiService, private uiService: UIService) {
		super();
	}

	protected retrieve(): Observable<Maybe<PlanData>> {
		if (this.uiService.PCTOnlyMode) return of(null);
		return this.userApiService.userPlans();
	}

	medicalDeductibleIndividualData(): Observable<Maybe<DeductiblesData>> {
		return this.get().pipe(
			map((planData) => getPath<DeductiblesData>(planData, 'contract.deductible.individual')),
			shareReplay(1)
		);
	}

	hasContract(): Observable<Maybe<boolean>> {
		return this.get().pipe(
			map((planData) =>
				!isNil(planData) ? !!planData.contract || !!planData.dental || !!planData.vision : null
			),
			shareReplay(1)
		);
	}

	hasRibbonInsurancePartner(): Observable<Maybe<boolean>> {
		return this.get().pipe(
			map((planData) => !isNil(planData) && !!planData.contract?.metadata?.ribbonInsurancePartner),
			shareReplay(1)
		);
	}

	hasContractByType(contractType: 'contract' | 'dental' | 'vision'): Observable<boolean> {
		return this.get().pipe(
			map((planData) => !isNil(planData) && !isNil(planData[contractType])),
			shareReplay(1)
		);
	}

	getContractsNames(): Observable<string[]> {
		return this.get().pipe(
			map((planData) => [
				planData.contract?.name,
				planData.dental?.name,
				planData.vision?.name,
				//...planData.employerSponsoredPlans.map((plan) => plan.name),
			]),
			shareReplay(1)
		);
	}

	getContracts(): Observable<Contract[]> {
		return this.get().pipe(
			map((planData) => [
				planData.contract,
				planData.dental,
				planData.vision,
				...(Array.isArray(planData.employerSponsoredPlans)
					? planData.employerSponsoredPlans
					: [planData.employerSponsoredPlans]),
			]),
			shareReplay(1)
		);
	}

	hasMedicalContractByInsuranceCompanyId(insuranceCompanyId: string): Observable<boolean> {
		return this.get().pipe(
			map(
				(planData) =>
					!isNil(planData) &&
					!isNil(planData.contract) &&
					planData?.contract?.insuranceCompany?._id === insuranceCompanyId
			),
			shareReplay(1)
		);
	}

	planClassType(planType: string): Observable<Maybe<PlanClassType>> {
		return this.get().pipe(
			map((planData) => {
				if (isNil(planData)) {
					return null;
				}
				return planData[planType === 'medical' ? 'contract' : planType]?.planClass;
			})
		);
	}

	benefitsMap(): Observable<Map<string, any>> {
		// TODO: add exact type
		return this.get().pipe(
			map((planData) => {
				const benefitsMap = new Map<string, any>();

				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'contract.contractBenefits'));
				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'dental.contractBenefits'));
				this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'vision.contractBenefits'));

				return benefitsMap;
			}),
			shareReplay(1)
		);
	}

	private indexBenefits(benefitMap: Map<string, any>, benefitsList: any[]) {
		if (isNil(benefitsList) || !isArray(benefitsList)) {
			return;
		}
		benefitsList.forEach((benefit: any) => benefitMap.set(benefit.benefit, benefit));
	}

	planTypeByContractId(id: string): Observable<Maybe<PlanType>> {
		return this.get().pipe(
			map((planData) => {
				const planKey = ['contract', 'dental', 'vision'].find(
					(planKey) => planData[planKey].insuranceCompany._id === id
				);
				return planData[planKey]?.planType || null;
			}),
			shareReplay(1)
		);
	}

	planTypeByContractIds(ids: string[]): Observable<{ _id: string; planType: Maybe<PlanType> }[]> {
		return this.get().pipe(
			map((planData) =>
				ids.map((id) => {
					const planKey = ['contract', 'dental', 'vision'].find(
						(planKey) => planData[planKey]?.ribbon === id
					);
					return { _id: id, planType: planData[planKey]?.planType || null };
				})
			),
			shareReplay(1)
		);
	}

	hasGroupNumber(): Observable<boolean> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return false;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (contract?.['groupNumber']) return true;
				}
				return false;
			})
		);
	}

	hasMedicalPlanByKayser(): Observable<boolean> {
		return this.hasMedicalContractByInsuranceCompanyId(INVALID_COMPANY_ID_FOR_DEDUCTIBLE_TEASER_AND_TALON).pipe(
			shareReplay(1)
		);
	}

	getPlansNetworkStructure(): Observable<NetworkStructure> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return null;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (
						contract?.['networkStructure'] !== NetworkStructure.SINGLE &&
						contract?.['networkStructure'] !== undefined
					)
						return contract?.['networkStructure'];
				}
				return NetworkStructure.SINGLE;
			}),
			shareReplay(1)
		);
	}

	getMnNetworkContract(): Observable<NetworkStructure> {
		return this.get().pipe(
			map((planData) => {
				if (!planData) return null;
				const contracts = Object.values(planData);
				for (const contract of contracts) {
					if (
						contract?.['networkStructure'] !== NetworkStructure.SINGLE &&
						contract?.['networkStructure'] !== undefined
					)
						return contract;
				}
				return null;
			}),
			shareReplay(1)
		);
	}

	getMnNetworkSortArray(type: string = 'contract'): Observable<string[]> {
		return this.get().pipe(
			map((planData) => {
				if (!planData[type]?.additionalNetworks) return [];
				return planData[type]?.additionalNetworks;
			})
		);
	}

	hasMedicalPlanByAnthem(): Observable<boolean> {
		return this.getUserMedicalPlan().pipe(map((plan) => plan === MpiPlan.Anthem));
	}

	getUserMedicalPlan(): Observable<MpiPlan> {
		return this.get().pipe(
			map((planData) => {
				switch (planData?.contract?._id) {
					case MpiPlanId.Anthem:
						return MpiPlan.Anthem;
					case MpiPlanId.HealthNet:
						return MpiPlan.HealthNet;
					case MpiPlanId.Kaiser:
						return MpiPlan.Kaiser;
					case MpiPlanId.Oxford:
						return MpiPlan.Oxford;
					default:
						return undefined;
				}
			})
		);
	}

	getEligibleZipCodes(): Observable<string[]> {
		return this.get().pipe(
			map((planData) => {
				return planData?.contract?.metadata?.lowCopayEligibleZipCodes ?? [];
			})
		);
	}
}
