import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'makeCDCVaccineUrlHref',
	pure: true,
})
// MPI wants to have some words and names in AllUpper-Cases etc. - this is work in progress - ToDo
export class MakeCDCVaccineUrlHrefPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: any): SafeHtml {
		if (!value) {
			return '';
		}

		const urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;;
		value = value.replace(urlPattern, function(url) {
			let href = url;
			if (!href.match(/^(https?:\/\/|www\.)/)) {
				href = 'https://' + href;
			}
			if (href.startsWith('www.')) {
				href = 'https://' + href;
			}
			return '<a href="' + href + '" target="_blank">' + url + '</a>';
		});

		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
}

//https://www.cdc.gov/vaccines/vpd/vaccines-diseases.html
