<div class="quick-access-container">
	<ng-container *ngFor="let item of quickAccessItems">
		<app-quick-access-item
			*ngIf="item.isActive"
			[title]="item.label"
			[icon]="item.icon"
			[additionalIcon]="item?.additionalIcon"
			[link]="item?.link"
			[action]="item?.action"
			[analyticEvent]="item?.analyticEvent"
		>
		</app-quick-access-item>
	</ng-container>
</div>
