import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreventiveCare, PreventiveCareService } from '../../../../services/preventive-care.service';
import { PreventiveCareStoreService } from '../../../../services/stores/preventive-care-store/preventive-care-store.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { AppManagerService } from '../../../../services/app-manager.service';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
	selector: 'app-preventive-care-teaser',
	templateUrl: './preventive-care-teaser.component.html',
	styleUrls: ['./preventive-care-teaser.component.scss'],
})
export class PreventiveCareTeaserComponent implements OnInit {
	@Input() showInitTeaser: boolean = true;
	@Input() source: string;
	public checklist: any;
	public checklistLoaded: boolean = false;

	constructor(
		private router: Router,
		private preventiveCareStore: PreventiveCareStoreService,
		private preventiveCareService: PreventiveCareService,
		private appManagerService: AppManagerService,
		private trackingService: TrackingService,
	) { }

	ngOnInit(): void {
		this.preventiveCareStore
			.get()
			.pipe(RxjsUtils.isNotNil())
			.subscribe((preventiveCares: PreventiveCare[]) => {
				this.checklistLoaded = true;
				this.checklist = preventiveCares;
			});
	}

	private goToPreventiveCare(): void {
		this.trackingService.trackClientEvent('Preventive care - start', { source: this.source });
		this.router.navigate(['preventive-care']);
	}

	public onOpenPreventiveCare(): void {
		this.preventiveCareService
			.isMissingUserDataForPreventiveCare()
			.subscribe((isMissing) => {
				isMissing ? this.openTracker() : this.goToPreventiveCare();
			})
			.unsubscribe();
	}

	public openTracker(): void {
		this.appManagerService.openPreventiveCarePersonalizeTracker('entry screen').subscribe((success) => {
			if (success) {
				this.goToPreventiveCare();
			}
		});
	}
}
