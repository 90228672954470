import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleModule } from 'ripple';
import { TxNativeModule } from '@transifex/angular';
import { ReactiveFormsModule } from '@angular/forms';

import { FeedbackDialogComponent } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackEmotionsModule } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.module';

@NgModule({
	declarations: [FeedbackDialogComponent],
	imports: [CommonModule, TxNativeModule, ReactiveFormsModule, RippleModule, FeedbackEmotionsModule],
	exports: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
