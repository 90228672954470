<div class="eligible-zipcodes-modal">
	<div class="eligible-zipcodes-modal__header">
		<button class="eligible-zipcodes-modal__back-button" (click)="handleBackClick()">
			<ripple-icon name="arrowLeft"></ripple-icon>
		</button>
	</div>

	<div class="eligible-zipcodes-modal__content">
		<div class="eligible-zipcodes-modal__title">
			<span [appTooltipWithTouch]="mp3tooltipText"> MP3 </span>
			Service Area Zip Code
		</div>
		<div class="eligible-zipcodes-modal__description">
			If your address on file with MPI has a ZIP code within the MP3 Service Area and you choose not to utilize
			the MP3 Provider Network, you may be required to pay a $30 Co-Payment plus any applicable Coinsurance.
		</div>

		<div class="eligible-zipcodes-modal__data">
			<ng-container *ngFor="let zipCodes of zipCodesMatrix">
				<div class="eligible-zipcodes-modal__data-column">
					<ng-container *ngFor="let zipCode of zipCodes">
						<div class="eligible-zipcodes-modal__data-item">{{ zipCode }}</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #mp3tooltipText> <p>Los Angeles and Ventura counties</p></ng-template>
