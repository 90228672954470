<div *ngIf="{ passwordAlreadyUsed: (showUsedPasswordErrorSignal$ | async) } as asyncErrors" class="passwords-container">
	<mat-form-field MatFormFieldControl="a" [ngClass]="{ 'mat-form-field-invalid': invalidField }">
		<mat-label>{{ 'Password' | translate }}</mat-label>
		<div class="input-wrapper">
			<input
				matInput
				class="password"
				name="password"
				[type]="hidePassword ? 'password' : 'text'"
				(input)="onPasswordInputChange()"
				(blur)="onPasswordBlur()"
				[(ngModel)]="password"
			/>
			<mat-icon
				*ngIf="password"
				(click)="hidePassword = !hidePassword"
				[ngClass]="{ 'visibility-off': hidePassword, visibility: !hidePassword }"
			>
				{{ hidePassword ? 'visibility_off' : 'visibility' }}
			</mat-icon>
		</div>
	</mat-form-field>

	<div *ngIf="!passwordIsValid && password?.length > 0 && useValidation" class="password-validation-container">
		<ripple-input-success-error-message
			[isSuccess]="validationObj.length"
			messageText="{{ 'At least 8 characters' | translate }}"
		></ripple-input-success-error-message>
		<ripple-input-success-error-message
			[isSuccess]="validationObj.uppercase"
			messageText="{{ 'At least one upper case' | translate }}"
		></ripple-input-success-error-message>
		<ripple-input-success-error-message
			[isSuccess]="validationObj.lowercase"
			messageText="{{ 'At least one lower case' | translate }}"
		></ripple-input-success-error-message>
		<ripple-input-success-error-message
			[isSuccess]="validationObj.numbers"
			messageText="{{ 'At least one number' | translate }}"
		></ripple-input-success-error-message>
		<ripple-input-success-error-message
			[isSuccess]="validationObj.specialChar"
			messageText="{{ 'At least one special character' | translate }}"
		></ripple-input-success-error-message>
	</div>

	<div
		#passwordIsStrong
		*ngIf="!passwordFromLogin && passwordIsValid && password?.length > 0 && !asyncErrors.passwordAlreadyUsed"
		class="password-indicator"
	>
		<span class="password-valid-indicator-icon"></span>
		<p class="indicator-text">{{ 'Password strength: Strong' | translate }}</p>
	</div>
	<div *ngIf="asyncErrors.passwordAlreadyUsed" class="password-indicator">
		<span class="password-invalid-indicator-icon"></span>
		<p class="indicator-text">{{ 'Password already used in the past' | translate }}</p>
	</div>

	<mat-form-field class="confirm-password-container" MatFormFieldControl="b" *ngIf="useConfirmPassword">
		<mat-label>{{ 'Confirm Password' | translate }}</mat-label>
		<div class="input-wrapper">
			<input
				matInput
				name="confirm-password"
				class="confirm-password"
				[type]="hideConfirmPassword ? 'password' : 'text'"
				(input)="checkPasswordMatch()"
				(blur)="onPasswordBlur()"
				(focus)="displayPasswordMatchValidation = false"
				[(ngModel)]="confirmPassword"
			/>
			<mat-icon
				*ngIf="confirmPassword"
				(click)="hideConfirmPassword = !hideConfirmPassword"
				[ngClass]="{ 'visibility-off': hideConfirmPassword, visibility: !hideConfirmPassword }"
			>
				{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}
			</mat-icon>
		</div>
	</mat-form-field>
	<ripple-input-success-error-message
		*ngIf="!passwordsMatch && displayPasswordMatchValidation"
		[isSuccess]="false"
		messageText="{{ 'Passwords must be identical' | translate }}"
		class="identical-confirm"
	></ripple-input-success-error-message>
	<ripple-input-success-error-message
		*ngIf="passwordsMatch"
		[isSuccess]="true"
		messageText="{{ 'Passwords match' | translate }}"
		class="identical-confirm"
	></ripple-input-success-error-message>
</div>
