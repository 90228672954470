import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import CFG from '../../../config/app-config.json';

@Injectable({
	providedIn: 'root',
})
export class ContractServicesApiService {
	constructor(private httpClient: HttpClient) {}

	servicesCategories(): Observable<{ mainCategories: any[]; subCategories: any[] }> {
		return this.httpClient.get<{ mainCategories: any[]; subCategories: any[] }>(CFG.apiEndpoints.serviceCategories);
	}
}
