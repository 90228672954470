import { Component, Input } from '@angular/core';
import { Review } from '../../../../helpers/providers.helpers';

const ASSETS_PATH = '/assets/images/provider-card';

@Component({
	selector: 'app-mpi-provider-review',
	templateUrl: './provider-review.component.html',
	styleUrls: ['./provider-review.component.scss'],
})
export class ProviderReviewComponent {
	@Input() review: Review;

	public assetsPath: string = ASSETS_PATH;
}
