import { Injectable } from '@angular/core';
import { StoreUpdateRegistry } from '../../utils/store/store-update-registry';
import { filter, map, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StoreUpdateRegistryService implements StoreUpdateRegistry {
	// eslint-disable-next-line @typescript-eslint/ban-types
	private update$ = new Subject<{ token: Symbol; context: unknown }>();

	// eslint-disable-next-line @typescript-eslint/ban-types
	private totalUpdate$ = new Subject<Symbol>();

	// eslint-disable-next-line @typescript-eslint/ban-types
	updatesFor<Context>(token: Symbol): Observable<Context> {
		return this.update$.pipe(
			filter((update) => update.token === token),
			map((update) => update.context as Context)
		);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	registerTotalUpdate(token: Symbol): void {
		this.totalUpdate$.next(token);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	registerUpdate<Context>(token: Symbol, context: Context): void {
		this.update$.next({ token, context });
	}
}
