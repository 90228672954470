<div class="main-container" [ngClass]="{ auto: autoCompleteState }">
	<div class="autocomplete-container" [hidden]="!autoCompleteState">
		<h1 class="choose-from-list">{{ 'Choose from list:' | translate }}</h1>
		<div class="autocomplete-content">
			<div class="input-wrapper">
				<input
					type="text"
					class="fs-unmask"
					[(ngModel)]="userFilterText"
					placeholder="{{ 'Start typing a medical condition...' | translate }}"
				/>
				<button mat-button matPrefix mat-icon-button>
					<img
						src="../../../assets/images/icons/choose_list_search.svg"
						class="search-icon"
						alt="search icon"
					/>
				</button>
			</div>

			<div class="entities-wrapper">
				<ul *ngIf="allEntitiesNames$ | async as namesList; else loadingIndicator">
					<li class="row" *ngFor="let name of namesList | filterStringsArray : userFilterText">
						<span
							(click)="closeDialogAndSendData(name)"
							class="entity"
							[ngClass]="{ letter: isLetter(name) }"
							>{{ name }}</span
						>
					</li>
				</ul>

				<ng-template #loadingIndicator>
					<div class="loading-indicator-wrapper">
						<app-loader size="medium"></app-loader>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
