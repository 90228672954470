import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event'])
	onInputChange(event: any) {
		const phoneNumber = event.target.value.replace(/\D/g, '');
		if (phoneNumber.length === 0) {
			event.target.value = '';
		} else if (phoneNumber.length < 4) {
			event.target.value = '(' + phoneNumber;
		} else if (phoneNumber.length < 7) {
			event.target.value = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3);
		} else {
			event.target.value =
				'(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6);
		}
	}
}
