<div *ngIf="locations?.length" class="locations">
	<div class="title" [ngPlural]="locations.length">
		<ng-template ngPluralCase="one">
			{{ 'Location' | translate }}
		</ng-template>
		<ng-template ngPluralCase="other">
			{{ 'Locations' | translate }} ({{ locations.length }})
		</ng-template>
	</div>
	<app-map-and-info [locations]="locations"></app-map-and-info>
</div>
