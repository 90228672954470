import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
	BenefitWithCost,
	CardFrontData,
	NodeType,
	RxServicesIds,
	ServicesIds,
	Deductible,
	OutOfPocketMax,
	networkNames,
} from '../../../../services/digital-card/templates/shared';
import { getBenefitCostValue, invokePhoneDialer } from '../../../../utils/utils';
import { CharterSchoolLocalize } from '../../../../services/digital-card/templates/charter-school/charter-school.localize';
import { Headlines } from 'src/app/services/digital-card/templates/charter-school/charter-school';

export interface CardFrontDataCharterSchool extends CardFrontData {
	networkName: networkNames;
}

interface listData {
	property: string;
	displayName: string;
	value: any;
	propertyNotBold?: boolean;
	valueSmaller?: boolean;
	type?: NodeType;
}

interface listDataBox {
	headline: string;
	list: listData[];
	hasContactInfo?: boolean;
	logo?: string;
}

@Component({
	selector: 'app-charter-digital-card-front',
	templateUrl: './charter-digital-card-front.component.html',
	styleUrls: ['./charter-digital-card-front.component.scss'],
})
export class CharterDigitalCardFrontComponent implements OnChanges {
	@Input() content: CardFrontDataCharterSchool;
	@Input() companyName: string;
	@Input() groupNumber: string;

	userInfo: listDataBox = { headline: '', list: [] };
	planInfo: listDataBox = { headline: '', list: [] };
	pharmacyInfo: listDataBox = { headline: '', list: [] };
	groupNo: string;

	BenefitTypeMap = new Map<string, string>([
		[ServicesIds.PCP, 'PCP'],
		[ServicesIds.SPU, 'Spec'],
		[ServicesIds.URG, 'Urgent'],
	]);

	ngOnChanges(changes: SimpleChanges): void {
		if ('groupNumber' in changes) {
			this.groupNo = this.groupNumber ? this.groupNumber : 'N/A';
		}
		if (changes?.content?.currentValue) {
			this.createTemplateData(this.content);
		}
	}

	createTemplateData(data: CardFrontDataCharterSchool) {
		this.userInfo = {
			headline: 'Member',
			list: [
				{ property: 'groupId', displayName: 'Group ID', value: this.groupNo, type: NodeType.TEXT },
				{
					property: 'coveredPerson',
					displayName: CharterSchoolLocalize.coveredPerson,
					value: data.name,
					type: NodeType.TEXT,
				},
				{
					property: 'participantId',
					displayName: CharterSchoolLocalize.participantId,
					value: data.memberId,
					type: NodeType.TEXT,
				},
				{
					property: 'effectiveDate',
					displayName: CharterSchoolLocalize.effective,
					value: data.effectiveDate,
					type: NodeType.TEXT,
				},
			],
			hasContactInfo: true,
		};

		this.planInfo = {
			headline: 'Plan Information',
			list: [
				{ property: 'planName', displayName: CharterSchoolLocalize.planOpt, value: data.planName },
				{
					property: 'medicalBenefits',
					displayName: 'Medical Benefits',
					value:
						data.networkName === networkNames.RBP
							? CharterSchoolLocalize.individualFamily
							: CharterSchoolLocalize.inNetworkNonNetwork,
					propertyNotBold: true,
					valueSmaller: true,
					type: NodeType.TEXT,
				},
				{
					property: 'deductible',
					displayName:
						data.networkName === networkNames.RBP ? Headlines.DEDUCTIBLE : Headlines.IND_DEDUCTIBLE,
					value: this.getDeductibleValue(data.deductible, data.networkName),
					propertyNotBold: true,
					valueSmaller: true,
					type: NodeType.TEXT,
				},
				{
					property: 'outOfPocketMax',
					displayName:
						data.networkName === networkNames.RBP ? Headlines.OUT_OF_POCKET : Headlines.IND_OUT_OF_POCKET,
					value: this.getOutOfPucketValue(data.outOfPocketMax, data.networkName),
					propertyNotBold: true,
					valueSmaller: true,
					type: NodeType.TEXT,
				},
				{
					property: 'copay',
					displayName: 'Copay',
					value: this.getCopayValue(data.benefits),
					valueSmaller: true,
					type: NodeType.TEXT,
				},
				{
					property: 'rx',
					displayName: 'RX',
					value: this.getRxValue(data.benefits),
					valueSmaller: true,
					type: NodeType.TEXT,
				},
			],
		};

		this.pharmacyInfo = {
			headline: 'Pharmacy Plan',
			list: [
				{ property: 'RxPCN', displayName: 'RxPCN', value: 'ADV', type: NodeType.TEXT },
				{ property: 'RxBIN', displayName: 'RxBIN', value: '004336', type: NodeType.TEXT },
				{ property: 'RxGRP', displayName: 'RxGRP', value: 'RX21FJ', type: NodeType.TEXT },
				{
					property: 'helpdesk',
					displayName: 'Pharmacy Help Desk:',
					value: undefined,
					propertyNotBold: true,
					type: NodeType.TEXT,
				},
				{
					property: 'helpdesk',
					displayName: '1-800-364-6331',
					value: undefined,
					propertyNotBold: true,
					type: NodeType.PHONE,
				},
				{
					property: 'caremark',
					displayName: 'www.caremark.com',
					value: undefined,
					propertyNotBold: true,
					type: NodeType.LINK,
				},
			],
			logo: 'caremark',
		};
	}

	private getCopayValue(benefits: BenefitWithCost[]): string {
		return benefits.reduce((accumulator, currentBenefit) => {
			const benefitType = this.BenefitTypeMap.get(currentBenefit.serviceId);
			if (!benefitType || !currentBenefit?.cost) {
				return accumulator;
			}
			const formattedString = `${benefitType} ${getBenefitCostValue(currentBenefit)}`;
			return accumulator.length === 0 ? formattedString : `${accumulator} / ${formattedString}`;
		}, '');
	}

	private getDeductibleValue(deductible: Deductible, networkName: networkNames): string {
		let deductibleValue;
		if (networkName === networkNames.RBP) {
			const deductibleFamilyArr = deductible?.family?.inNetwork?.text?.match(/\$[\d,]+/);
			const deductibleFamily = deductibleFamilyArr ? deductibleFamilyArr[0] : '---';
			deductibleValue = `${deductible?.individual?.inNetwork?.text}/${deductibleFamily}`;
		} else {
			deductibleValue = deductible?.individual?.outNetwork?.text
				? `${deductible?.individual?.inNetwork?.text}/${deductible?.individual?.outNetwork?.text}`
				: `${deductible?.individual.inNetwork?.text}`;
		}
		return deductibleValue;
	}

	private getOutOfPucketValue(outOfPocketMax: OutOfPocketMax, networkName: networkNames): string {
		let outOfPocketMaxValue;
		if (networkName === networkNames.RBP) {
			outOfPocketMaxValue = `${outOfPocketMax?.individual?.inNetwork}/${outOfPocketMax?.family?.inNetwork}`;
		} else {
			outOfPocketMaxValue = outOfPocketMax?.individual.outNetwork
				? `${outOfPocketMax?.individual?.inNetwork}/${outOfPocketMax?.individual?.outNetwork}`
				: `${outOfPocketMax?.individual?.inNetwork}`;
		}
		return outOfPocketMaxValue;
	}

	private getRxValue(benefits: BenefitWithCost[]): string {
		const rxServicesIdsValues = Object.values(RxServicesIds);
		return rxServicesIdsValues.reduce((accumulator, rxServiceId) => {
			const benefit = benefits.find((benefit) => benefit.serviceId === rxServiceId);
			if (!benefit?.cost) {
				return accumulator;
			}
			const formattedString = getBenefitCostValue(benefit);
			return accumulator.length === 0 ? formattedString : `${accumulator} / ${formattedString}`;
		}, '');
	}

	isArray(item: any) {
		return Array.isArray(item);
	}

	capitalizeFirstLetter(inputString: string): string {
		if (inputString.length === 0) {
			return inputString;
		}
		return inputString.charAt(0).toUpperCase() + inputString.slice(1);
	}

	callPhone(phone: string) {
		invokePhoneDialer(phone);
	}
}
