import { FavoriteProvider } from '../../my-care-team/helpers/providers.helpers';

export enum ProviderCardType {
	AddToFavorites = 'AddToFavorites',
	Favorite = 'Favorite',
}

export interface ProviderCard<ProviderCardType> {
	type: ProviderCardType;
	provider: Partial<FavoriteProvider>;
}

export const createCards = ([favoriteProviders, minCardsCount]: [
	FavoriteProvider[],
	number
]): ProviderCard<ProviderCardType>[] => {
	const leftOversCount = Math.max(1, minCardsCount - favoriteProviders.length);

	return [
		...sortCardsNewestFirst(favoriteProviders).map(providerToCard),
		...createAddToFavoriteCards(leftOversCount),
	];
};

const providerToCard = (provider: FavoriteProvider): ProviderCard<ProviderCardType.Favorite> => ({
	type: ProviderCardType.Favorite,
	provider,
});

const createAddToFavoriteCards = (count: number): ProviderCard<ProviderCardType.AddToFavorites>[] =>
	new Array(count).fill({ type: ProviderCardType.AddToFavorites });

const sortCardsNewestFirst = (favoriteProviders: FavoriteProvider[]): FavoriteProvider[] =>
	favoriteProviders.sort(
		(a: FavoriteProvider, b: FavoriteProvider) => -a.addedToFavoriteAt.localeCompare(b.addedToFavoriteAt)
	);

const MIN_CARDS_COUNT = {
	MOBILE: 2,
	DESKTOP: 5,
};

export const deviceToMinCardsCount = (isMobile: boolean): number =>
	isMobile ? MIN_CARDS_COUNT.MOBILE : MIN_CARDS_COUNT.DESKTOP;
