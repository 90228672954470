import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { T } from '@transifex/angular';

import { getUTCDateWithoutTime, isIonic } from '../../../../utils/utils';

import { UserData } from '../../../../models/user-data';

import { UserService } from '../../../../services/user.service';
import { TrackingService } from '../../../../services/tracking.service';

import { HealtheeDialogContent } from '../../../../modules/app-shared/healthee-dialog/healthee-dialog.component';
import { HealtheeRadioButton } from '../../../../modules/app-shared/healthee-radio-group/healthee-radio-group.component';
import { LanguageService } from 'src/app/services/language.service';

const MAX_PROFILE_WEIGHT = 440;
const MIN_PROFILE_WEIGHT = 90;

const MAX_PROFILE_HEIGHT_FT = 7;
const MIN_PROFILE_HEIGHT_FT = 4;

const MAX_PROFILE_HEIGHT_IN = 11;
const MIN_PROFILE_HEIGHT_IN = 0;

@Component({
	selector: 'app-edit-info-modal',
	templateUrl: './edit-info-modal.component.html',
	styleUrls: ['./edit-info-modal.component.scss'],
})
export class EditInfoModalComponent extends HealtheeDialogContent implements OnInit {
	public editInfoForm: FormGroup;
	public showSuccessMessage: boolean = false;
	public isSending: boolean = false;
	public minDate = new Date(1900, 0, 1);
	public maxDate = new Date();
	public isIonic = isIonic();

	public hideSmokingField: boolean;
	public hideHeightWeightField: boolean;
	public hideNicknameField: boolean;

	@T('Male')
	radio_gender_male: string;

	@T('Female')
	radio_gender_female: string;

	@T('Non-binary')
	radio_gender_non_binary: string;

	@T('Gender')
	gender: string;

	@T('Prefer not to say')
	radio_gender_preferNotSay: string;

	@T('Smoker')
	smoker: string;

	@T('Yes')
	radio_smoker_yes: string;

	@T('No')
	radio_smoker_no: string;

	public chooseGenderRadios: HealtheeRadioButton<string>[];

	public chooseSmokingRadios: HealtheeRadioButton<boolean>[];

	constructor(
		private languageService: LanguageService,
		private formBuilder: FormBuilder,
		private trackingService: TrackingService,
		private userService: UserService
	) {
		super();
	}

	ngOnInit(): void {
		this.hideSmokingField = this.data.hideSmokingField;
		this.hideHeightWeightField = this.data.hideHeightWeightField;
		this.hideNicknameField = this.data.hideNicknameField;

		this.languageService.appLanguage$.subscribe(() => {
			this.populateTranslatedRadios();
		});
		this.userService.userData$.subscribe((data: UserData) => {
			this.chooseGenderRadios.forEach((button) => (button.isChecked = button.value === data.gender));
			this.chooseSmokingRadios.forEach((button) => (button.isChecked = button.value === data.smoking));
			let heightArray = [null, null];
			if (data.height) heightArray = data.height.split('"');

			const date = new Date(data.birthday + ' 12:00:00'); //prevent timezone issues with angular's forms date conversions

			this.editInfoForm = this.formBuilder.group({
				nickname: [data.nickname || data.firstName, [Validators.required]],
				dateOfBirth: [date, [Validators.required]],
				gender: [data.gender, [Validators.required]],
				smoking: [data.smoking],
				heightFt: [
					parseInt(heightArray[0]),
					[
						Validators.min(MIN_PROFILE_HEIGHT_FT),
						Validators.max(MAX_PROFILE_HEIGHT_FT),
						Validators.maxLength(1),
					],
				],
				heightInch: [
					parseInt(heightArray[1]),
					[
						Validators.min(MIN_PROFILE_HEIGHT_IN),
						Validators.max(MAX_PROFILE_HEIGHT_IN),
						Validators.maxLength(2),
					],
				],
				weight: [
					data.weight,
					[Validators.min(MIN_PROFILE_WEIGHT), Validators.max(MAX_PROFILE_WEIGHT), Validators.maxLength(3)],
				],
			});
		});
	}

	private populateTranslatedRadios() {
		this.chooseGenderRadios = [
			{ value: 'male', text: this.radio_gender_male, isChecked: false },
			{ value: 'female', text: this.radio_gender_female, isChecked: false },
			{ value: 'non-binary', text: this.radio_gender_non_binary, isChecked: false },
			{ value: 'other', text: this.radio_gender_preferNotSay, isChecked: false },
		];

		this.chooseSmokingRadios = [
			{ value: true, text: this.radio_smoker_yes, isChecked: false },
			{ value: false, text: this.radio_smoker_no, isChecked: false },
		];
	}

	public onGenderButtonChange(value: string) {
		this.editInfoForm.controls['gender'].setValue(value);
	}

	public onSmokingButtonChange(value: string) {
		this.editInfoForm.controls['smoking'].setValue(value);
	}

	public onSubmit() {
		if (this.editInfoForm.valid) {
			const formData = this.editInfoForm.value;

			const utcDateOnlyObject = getUTCDateWithoutTime(new Date(formData.dateOfBirth));
			formData.dateOfBirth = new Date(utcDateOnlyObject);

			this.userService.updateUserProfileFromFormData(formData).subscribe({
				next: () => {
					this.trackingService.trackClientEvent('Health Profile Edit my personal info');
					this.closeDialog.emit(null);
				},
			});
		}
	}
}
