<div *ngIf="paginationData$ | async as paginationData" class="pagination-container">
	<div class="slider-btns">
		<button
			[ngSwitch]="paginationData.activePage > 1"
			class="chevron-btn"
			[ngClass]="{ active: paginationData.activePage > 1 }"
		>
			<img *ngSwitchCase="false" src="/assets/images/icons/chevron-left-unactive.svg" />
			<img
				*ngSwitchCase="true"
				src="/assets/images/icons/chevron-left.svg"
				(click)="onPageClick(paginationData.activePage - 1)"
			/>
		</button>
		<button
			[ngSwitch]="!isMixSearchLastSearch && paginationData.activePage < paginationData.maxPage"
			class="chevron-btn"
			[ngClass]="{ active: !isMixSearchLastSearch && paginationData.activePage < paginationData.maxPage }"
		>
			<img *ngSwitchCase="false" src="/assets/images/icons/chevron-right-unactive.svg" />
			<img
				*ngSwitchCase="true"
				src="/assets/images/icons/chevron-right.svg"
				(click)="onPageClick(paginationData.activePage + 1)"
			/>
		</button>
	</div>
</div>
