import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../../services/user.service';
import { UIService } from '../../../../services/ui.service';
import { TrackingService } from '../../../../services/tracking.service';

import { HealtheeDialogContent } from '../../../app-shared/healthee-dialog/healthee-dialog.component';

const AVAILABLE_AVATARS = ['user-avatar1.svg', 'user-avatar2.svg', 'user-avatar3.svg', 'user-avatar4.svg'];

@Component({
	selector: 'app-change-avatar-dialog',
	templateUrl: './change-avatar-dialog.component.html',
	styleUrls: ['./change-avatar-dialog.component.scss'],
})
export class ChangeAvatarDialogComponent extends HealtheeDialogContent implements OnInit {
	public availableAvatars = AVAILABLE_AVATARS;
	public isLoading = false;

	constructor(
		private userService: UserService,
		private uiService: UIService,
		private trackingService: TrackingService
	) {
		super();
	}

	ngOnInit(): void {}

	public chooseAvatar(avatarFilename: string) {
		this.isLoading = true;

		this.userService
			.updateUserFullnameOrAvatar({ avatar: avatarFilename })
			.subscribe(() => this.avatarUpdatedSuccessfully())
			.add(() => (this.isLoading = false));
	}

	private avatarUpdatedSuccessfully() {
		this.uiService.displayAppMessage('Your avatar was updated', 'Ok', 'checkmark-outline');
		this.closeDialog.emit();
	}
}
