import { Component, Input } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { Copay, CopayCadence, MemberResponsibility } from 'src/app/models/benefits.model';

@Component({
	selector: 'app-service-panel-copay',
	templateUrl: './service-panel-copay.component.html',
	styleUrls: ['./service-panel-copay.component.scss'],
})
export class ServicePanelCopayComponent extends CommonComponent {
	@Input()
	copay: Copay;

	@Input()
	mr: MemberResponsibility;

	@Input()
	subjectToDeductible: boolean;

	@Input()
	dependsOnLocation: boolean;

	@Input()
	networkTitle: string;

	constructor() {
		super();
	}

	private createCopayObj(
		headline: string,
		textGreen: string,
		textDBlue
	): { headline: string; textGreen: string; textDBlue: string } {
		return {
			headline,
			textGreen,
			textDBlue,
		};
	}
	public getFullCopayText(copayText): string {
		return copayText.textGreen + ' ' + copayText.textDBlue;
	}

	public getMRFullText(mrText): string {
		return mrText.textGreen + ' ' + mrText.textDBlue;
	}

	public getCopayText(): { headline: string; textGreen: string; textDBlue: string } {
		const hideTitle = this.hideCoPayTitle();
		const title = 'Co-Payment';
		if (this.dependsOnLocation) return this.createCopayObj('', 'Coverage depends on location', '');
		if (this.copay.label) return this.createCopayObj(hideTitle ? '' : title, this.copay.label, '');
		if (!this.copay.amount && this.mr.percent === 100) return this.createCopayObj('', 'Not covered', '');

		const textGreen = `$${this.copay.amount}${this.copay.upToLimit ? ' - $' + this.copay.upToLimit : ''}`;
		let textDBlue = '';
		if (this.copay.unit) {
			switch (this.copay.cadence) {
				case CopayCadence.Admission:
					textDBlue += ` per ${this.copay.unit} per ${this.copay.cadenceCount} ${this.copay.cadence}${
						this.copay.cadenceCount > 1 ? 's' : ''
					}`;
					break;
				case CopayCadence.FirstNDays:
					textDBlue += ` per ${this.copay.unit} for the first ${this.copay.cadenceCount} days`;
					break;
				default:
					textDBlue += ` per ${this.copay.unit}`;
					break;
			}
		}
		return this.createCopayObj(title, textGreen, textDBlue);
	}

	public getMRText(): { headline: string; textGreen: string; textDBlue: string } {
		const title = 'Coinsurance';
		if (this.mr.label) return this.createCopayObj(title, this.mr.label, '');
		if (this.dependsOnLocation || this.copay.label || (!this.copay.amount && this.mr.percent === 100))
			return undefined;

		let textGreen = '';
		if (this.mr.dollar || this.mr.percent) {
			const textPartForOON = 'of the Allowable Amount for Covered Services; Balance Billing may apply';
			let textPart = 'of Contracted Amount for Covered Services';

			if (this.networkTitle.toLowerCase() === 'out of network') {
				textPart = textPartForOON;
			}
			textGreen = this.mr.dollar === null ? `${this.mr.percent}% ${textPart}` : `$${this.mr.dollar}`;
		}

		// XOR operator. only true if only one - this.mr.dollar or this.mr.percent - equals to 0
		if ((this.mr.dollar === 0) !== (this.mr.percent === 0)) {
			if (this.mr.dollar === 0) textGreen = `$0 for Covered Services`;
			if (this.mr.percent === 0) textGreen = `0% for Covered Services`;
		}

		if (!textGreen) return undefined;
		let textDBlue = '';
		if (this.mr.amount !== null) {
			textGreen += ` up to $${this.mr.amount}`;
			if (this.mr.additionalPercent !== null) textDBlue += `, then ${this.mr.additionalPercent}%`;
		}
		if (this.mr.cadence !== null) textDBlue += ` per ${this.mr.cadence}`;
		return this.createCopayObj(title, textGreen, textDBlue);
	}

	private hideCoPayTitle(): boolean {
		const mrHasNoText = this.getMRText() === undefined;
		return mrHasNoText === true && !!this.copay.label;
	}
}
