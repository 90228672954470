import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { UIService } from 'src/app/services/ui.service';
import { Facility } from '../../helpers/providers.helpers';

@Component({
	selector: 'app-facility-card-details',
	templateUrl: './facility-card-details.component.html',
	styleUrls: ['./facility-card-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FacilityCardDetailsComponent {
	@Input() facilityData: Facility;
	@Output() backClick = new EventEmitter();
	public locations: any[];
	public activeLocationIndex: number = 0;

	public tabs = ['About', 'Location'];
	public activeTab = this.tabs[0];
	public isMobile$: Observable<boolean> = this._uiService.isMobile$;

	constructor(private _uiService: UIService) {}

	public manuallySetActiveTab() {
		const topOffset = document.querySelector('nav[mat-tab-nav-bar]').getBoundingClientRect().height;
		const contentElements = document.querySelectorAll('.tab-panel');

		contentElements.forEach((element) => {
			const top = element.getBoundingClientRect().top;
			const bottom = element.getBoundingClientRect().bottom;
			if (top - topOffset < window.innerHeight / 2 && bottom - topOffset > window.innerHeight / 2) {
				this.setActiveTab(element.id);
			}
		});
	}

	public scrollTo(tab: string) {
		const element = document.getElementById(tab);
		element?.scrollIntoView({ behavior: 'smooth' });
	}

	public setActiveTab(tab: string) {
		this.activeTab = tab;
	}

	public openGoogleMapsDirections(selectedFacilityData: Facility): void {
		const address = selectedFacilityData.address;
		window.open(`https://www.google.com/maps/dir//${encodeURIComponent(address)}`, '_blank');
	}

	public goBack() {
		this.backClick.emit();
	}
}
