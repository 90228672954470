import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonHierarchy } from '../button/button.component';

@Component({
	selector: 'ripple-empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
	@Input() icon: string;
	@Input() title: string;
	@Input() text: string;
	@Input() button: string;

	@Output() public onClick = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	protected readonly ButtonHierarchy = ButtonHierarchy;
}
