import { BannerCard } from "../../standalone/banner-card/banner-card.component";
import { NonEligibleLayoutComponentLocalize } from "./non-eligible-layout.localize";


	export const telehealthBannerCard: BannerCard = {
		index: 1,
		title: NonEligibleLayoutComponentLocalize.telehealth_title,
		description: NonEligibleLayoutComponentLocalize.telehealth_description,
		img: 'assets/images/non-eligible-layout/telehealth_cards_layout.png',
		buttonLabel: 'Speak to a doctor',
		action: '',
	};
	export const talonBannerCard: BannerCard = {
		index: 2,
		title: NonEligibleLayoutComponentLocalize.talon_title,
		description: NonEligibleLayoutComponentLocalize.talon_description,
		img: 'assets/images/non-eligible-layout/talon_cards_layout.png',
		buttonLabel: 'Compare',
		action: '',
	};
