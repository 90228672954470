import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, catchError, filter, take, map, tap, switchMap } from 'rxjs';

import CFG from '../config/app-config.json';
import { isArray } from '../utils/is/is-array';
import { ChatData, ChatMessage } from '../models/chat.model';
import { ChatResponse } from '../models/chat-response.model';

import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { IntercomService } from './intercom.service';
import { ConversationsApiService } from './api/conversations-api/conversations-api.service';
import { DomSanitizer } from '@angular/platform-browser';

export interface ChatSession {
	messages: Array<ChatMessage>;
	sessionId: string;
	chatStart: Date;
}
export interface SessionStart {
	session: any;
	welcomeMessage: any;
}

export enum ConversationBot {
	Staging = 'staging',
	Prod = 'production',
	Dev = 'dev',
}

const INITIAL_CHAT_MSG_OBJ = () => {
	return { messages: [], sessionId: null, chatStart: new Date() };
};
@Injectable({
	providedIn: 'root',
})
export class ConversationService {
	private _entitiesNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

	private _conversation$: BehaviorSubject<ChatSession> = new BehaviorSubject<ChatSession>(INITIAL_CHAT_MSG_OBJ());
	private _intercom_conversation$: BehaviorSubject<ChatSession> = new BehaviorSubject<ChatSession>(
		INITIAL_CHAT_MSG_OBJ()
	);
	private currentSessionId: string = null;
	public responseFromZoe$: BehaviorSubject<any> = new BehaviorSubject<any>({});

	public isHistoryLoaded = false;

	public isSessionInitialized = false;

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private userService: UserService,
		private storageService: StorageService,
		private intercomService: IntercomService,
		private conversationsApiService: ConversationsApiService
	) {
		this.userService.user$.pipe(filter((user) => !user)).subscribe(() => this.resetConversation());
	}

	get conversation$() {
		return this._conversation$.asObservable();
	}

	get intercom_conversation$() {
		return this._intercom_conversation$.asObservable();
	}

	get allEntitiesNames$() {
		return this._entitiesNames$.asObservable();
	}

	startSession(botRBValue: ConversationBot): Observable<SessionStart> {
		if (this.currentSessionId) return;

		return this.http.post(CFG.apiEndpoints.initiateNewSession, { botRBValue }).pipe(
			tap((res: any) => {
				const now = new Date();
				this.currentSessionId = res.session.session_id;

				this._conversation$.next({
					messages: this._conversation$.value.messages || [],
					sessionId: res.session.session_id,
					chatStart: new Date(),
				});
				return res;
			})
		);
	}

	pushChatMessageToConversation(message: ChatMessage): void {
		this.conversation$.pipe(take(1)).subscribe((conversation) => {
			conversation.messages.push(message);
			this._conversation$.next(conversation);
		});
		this.intercom_conversation$.pipe(take(1)).subscribe((conversation) => {
			conversation.messages.push(message);
			this._intercom_conversation$.next(conversation);
		});
	}

	clearIntercomConversation() {
		this._intercom_conversation$.next(INITIAL_CHAT_MSG_OBJ());
	}

	sendMessageData(data: ChatData, botRBValue: ConversationBot): Observable<ChatResponse> {
		const msg = {
			session_id: this.currentSessionId,
			return_context: true,
			data: data,
			message_type: 'text',
			botRBValue,
		};
		return this.http.post<ChatResponse>(CFG.apiEndpoints.sendNewMessage, msg);
	}

	history() {
		return this.conversationsApiService.conversations({ limit: 1000 }).pipe(take(1));
	}

	public resetConversation() {
		this.currentSessionId = null;
		this.isSessionInitialized = false;
		this._conversation$.next(INITIAL_CHAT_MSG_OBJ());
	}

	public openChooseFromList(): void {
		const entitiesNames = this.storageService.getChooseFromListEntities();
		entitiesNames ? this._entitiesNames$.next(entitiesNames) : this.fetchAndPublishEntityNames();
	}

	public fetchAndPublishEntityNames(): void {
		this.fetchEntitiesNames().subscribe((entityNamesArray: string[]) => {
			this._entitiesNames$.next(entityNamesArray),
				this.storageService.setChooseFromListEntities(entityNamesArray);
		});
	}

	private fetchEntitiesNames(): Observable<string[]> {
		const url = CFG.apiEndpoints.allEntitiesNames;

		return this.http.get<string[]>(url).pipe(
			catchError((errorRes) => {
				console.log('Error loading entity names from server', errorRes);
				return of(null);
			})
		);
	}

	public setResponseFromZoe(response): void {
		this.responseFromZoe$.next(response);
	}

	public escalateChatToLiveExpert(conversationId) {
		return this.intercom_conversation$.pipe(
			take(1),
			map((conversation: ChatSession) => conversation.messages),
			map((messages: Array<ChatMessage>) => this.summarizeConversation(messages)),
			switchMap((minimizedMessages: Array<string>) => {
				let minimizedMessagesInOneMessage = minimizedMessages.join('<br>');
				minimizedMessagesInOneMessage = new DOMParser().parseFromString(
					minimizedMessagesInOneMessage,
					'text/html'
				).documentElement.textContent;
				return conversationId
					? this.intercomService.updateIntercomConversation([minimizedMessagesInOneMessage], conversationId)
					: this.intercomService.initiateIntercomConversation([minimizedMessagesInOneMessage]);
			})
		);
	}

	public summarizeConversation(messagesArray: Array<ChatMessage>) {
		if (!isArray(messagesArray)) return;

		const minimizedMessages: string[] = [];

		messagesArray.forEach((msg: ChatMessage) => {
			if (!msg) return;

			if (msg.owner === 'user') {
				this.summarizeMessagesFromUser(msg, minimizedMessages);
			} else {
				this.summarizeMessagesFromBot(msg, minimizedMessages);
			}
		});

		return minimizedMessages;
	}

	private summarizeMessagesFromUser(msg: ChatMessage, minimizedMessages: string[]) {
		minimizedMessages.push('User:');
		minimizedMessages.push(msg.text.changingThisBreaksApplicationSecurity);
	}

	private summarizeMessagesFromBot(msg: ChatMessage, minimizedMessages: string[]) {
		switch (msg.type) {
			case 'avatar':
				minimizedMessages.push('ZOE:');
				break;

			case 'text':
				const line = msg.text.changingThisBreaksApplicationSecurity;
				minimizedMessages.push(line);
				break;

			case 'option':
				minimizedMessages.push('Options: ' + this.summarizeOptions(msg.options.map((option) => option.label)));
				break;

			case 'get_data_medical':
			case 'get_data':
				if (msg.related[0])
					minimizedMessages.push(
						'Related: ' + this.summarizeRelated(msg.related.map((option) => option.title))
					);

				if (msg.inNetwork)
					minimizedMessages.push(
						this.summarizeCoverage('inNetwork', msg.inNetwork, msg.inNetworkSubjectToDeductibles)
					);

				if (msg.outNetwork)
					minimizedMessages.push(
						this.summarizeCoverage('outNetwork', msg.outNetwork, msg.outNetworkSubjectToDeductibles)
					);

				if (msg.additionalNetworks) {
					for (const [index, additionalNetwork] of Object.entries(msg.additionalNetworks)) {
						if (!additionalNetwork.value) return; // prevent logging of additionalNetwork without a value (for some reason they also appear under 'get_data' without a value)
						const additionalNetworksText = this.summarizeAdditionalNetwork(additionalNetwork);
						minimizedMessages.push(additionalNetworksText);
					}
				}
				break;
		}

		if (msg.isCovered) {
			minimizedMessages.push('You have coverage!');
		}
	}

	private summarizeOptions(strings: Array<string>) {
		if (!strings) return '';

		return strings.map((str) => `(${str})`).join(', ');
	}

	private summarizeRelated(strings: Array<string>) {
		if (!strings) return '';

		return strings.map((str) => `[${str}]`).join(', ');
	}

	private summarizeCoverage(title: string, coverageText: string, subjectToDeductibles: boolean) {
		return `{ ${title}: Coverage: ${coverageText}, Deductibles? ${subjectToDeductibles ? 'Yes' : 'No'} }`;
	}

	private summarizeAdditionalNetwork(additionalNetwork: {
		name: string;
		subjectToDeductible: boolean;
		value: string;
	}) {
		return `{ ${additionalNetwork.name}: Value: ${additionalNetwork.value}, Deductibles? ${
			additionalNetwork.subjectToDeductible ? 'Yes' : 'No'
		} }`;
	}
}
