import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { Deductible } from '../../api/domain/deductible';
import { DeductiblesApiService } from '../../api/deductibles-api/deductibles-api.service';
import { Maybe } from '../../../utils/types/maybe';
import { catchError, map, Observable, of } from 'rxjs';
import { isNil } from '../../../utils/is/is-nil';

@Injectable({
	providedIn: 'root',
})
export class DeductiblesStoreService extends Store<void, Deductible> {
	constructor(private deductiblesApiService: DeductiblesApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<Deductible>> {
		return this.deductiblesApiService.getDeductibles().pipe(catchError(() => of(null)));
	}

	isConnected(): Observable<boolean> {
		return this.get().pipe(map((deductible) => !isNil(deductible)));
	}
}
