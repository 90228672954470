import { ComponentType } from '@angular/cdk/portal';
import {
	Component,
	ComponentFactoryResolver,
	HostListener,
	Optional,
	Inject,
	OnInit,
	ViewChild,
	EventEmitter,
	TemplateRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { take } from 'rxjs';

import { DynamicCmpDirective } from '../../../directives/dynamic-cmp.directive';

export type HealtheeDialogData = {
	componentOnly?: boolean;
	hasCloseButton?: boolean;
	title?: string;
	message?: string;
	confirmText?: string;
	cancelText?: string;
	component?: ComponentType<any>;
	templateRef?: TemplateRef<any>;
	actionsTemplateRef?: TemplateRef<any>;
	data?: object;
	fullHeight?: boolean;
	fullWidth?: boolean;
	noPadding?: boolean;
	icon?: string;
	disableClose?: boolean;
	maxWidth?: string;
};

export class HealtheeDialogContent {
	data: any;
	closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
	confirmDialog: EventEmitter<any> = new EventEmitter<any>();
}

@Component({
	selector: 'app-healthee-dialog',
	templateUrl: './healthee-dialog.component.html',
	styleUrls: ['./healthee-dialog.component.scss'],
})
export class HealtheeDialogComponent implements OnInit {
	public data: HealtheeDialogData;

	@ViewChild(DynamicCmpDirective, { static: true }) dynamicCmp!: DynamicCmpDirective;

	constructor(
		private resolver: ComponentFactoryResolver,
		@Optional() private mdDialogRef: MatDialogRef<HealtheeDialogComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogData?: HealtheeDialogData,
		@Optional() private mdBottomSheetRef?: MatBottomSheetRef<HealtheeDialogComponent>,
		@Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public sheetData?: HealtheeDialogData
	) {}

	ngOnInit(): void {
		this.data = this.dialogData || this.sheetData;
		if (this.mdDialogRef) this.mdDialogRef.disableClose = !!this.data.disableClose;
		if (this.mdBottomSheetRef) this.mdBottomSheetRef.disableClose = !!this.data.disableClose;

		if (this.data.component) this.setupContent();
	}

	private setupContent() {
		const viewContainerRef = this.dynamicCmp.viewContainerRef;
		viewContainerRef.clear();

		const factory = this.resolver.resolveComponentFactory(this.data.component);
		const componentRef = viewContainerRef.createComponent<HealtheeDialogContent>(factory);
		componentRef.instance.data = this.data.data;
		componentRef.instance.closeDialog?.pipe(take(1)).subscribe({
			next: () => this.close(),
		});
		componentRef.instance.confirmDialog?.pipe(take(1)).subscribe({
			next: (data) => this.close(data),
		});
	}

	public onCancel() {
		this.close(false);
	}

	public readonly close = (value: any = false) => {
		if (this.dialogData) return this.mdDialogRef.close(value);

		this.mdBottomSheetRef.dismiss(value);
	};

	public confirm() {
		this.close(true);
	}

	@HostListener('keydown.esc')
	public onEsc() {
		this.close(false);
	}
}
