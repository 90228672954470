import { T } from '@transifex/angular';

export class BlackhawkLocalize {
	@T('Medical Claims')
	static medicalClaims: string;
	@T('Pre Certification')
	static preCertification: string;
	@T('Pharmacy Plan')
	static pharmacyPlan: string;
	@T('Provider Inquiries')
	static providerInquiries: string;
	@T('Benefit Plan Contact')
	static benefitPlanContact: string;
	@T('Pre Certificatiion')
	static preCertificatiion: string;

	@T(
		'Call Narus Health with inquiries regarding eligibilty, plan benefits, claims, or any healthcare related question. (888) 585-3309'
	)
	static benefitPlanContactText: string;
	@T(
		'To complete pre authorization or questions contact MedWatch at (888) 900-8215 To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215To complete pre authorization or questions contact MedWatch at (888) 900-8215'
	)
	static preCertificatiionTextFrontpathNarus: string;
	@T('To complete pre authorization or questions contact MedWatch at (888) 900-8215')
	static preCertificationContentMedWatch: string;
	@T('To complete pre authorization or questions contact MediReview at (888) 850-0281')
	static preCertificationContentMediReview: string;
	@T('Member Services')
	static memberServices: string;
	@T('For providers inquiries please call (888) 205-2688 or visit aptivehc.com/providers')
	static providerInquiriesContent: string;

	@T('This card does not guarantee coverage. this policy provides automatic assignment of benefits to the provider.')
	static guaranteeText: string;
	@T('Contact information')
	static contactInformation: string;
	@T('Provider locator help')
	static providerLocatorHelp: string;
	@T('Provider locator search')
	static providerLocatorSearch: string;

	@T('For more questions about your plan and member benefits information call (888) 900-8215')
	static forMoreQestions: string;

	@T(
		"Pre-certification is required for (1) all inpatient adminssions; (2) all surgeries (outside of a physician's office); (3) high-cost radiology; (4) any drug or infusion above $1,500 a dose; (5) chemotherapy; and (6) anything else specified in your Summary Plan Description. To avoid reduction in benefits, call (888) 900-8215 at least 7-10 days before treatment or within 72 hours of an emergency admission. Pre-certification does not guarantee payment."
	)
	static preCertificationText: string;
}
