import { T } from '@transifex/angular';

export class NonEligibleLayoutComponentLocalize {
	@T('Cost Calculator')
	static talon_title: string;
	@T('Compare prices for medical procedures and services')
	static talon_description: string;
	@T('Telehealth')
	static telehealth_title: string;
	@T('Virtually connect with a doctor or therapist 24/7 at no cost')
	static telehealth_description: string;
}
