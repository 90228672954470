<div class="wrapper">
	<div class="controls">
		<button (click)="switchSide()" class="switch-button">
			<ripple-icon name="rotateRight"></ripple-icon>
			{{ 'Show' | translate }} {{ cardSide }}
		</button>
		<button (click)="share($event)" class="share-button">
			<ripple-icon name="export"></ripple-icon>
			{{ 'Share' | translate }}
		</button>
	</div>

	<div #cardElement class="cards">
		<app-custom-digital-card-front
			*ngIf="customCardType === getCustomCardTypes().BLACKHAWK"
			[class.hidden]="!showFront"
			[content]="cardFrontData"
			[companyName]="companyName"
			[groupNumber]="groupNumber"
		></app-custom-digital-card-front>
		<app-charter-digital-card-front
			*ngIf="customCardType === getCustomCardTypes().CHARTERSCHOOL"
			[class.hidden]="!showFront"
			[content]="cardFrontData"
			[companyName]="companyName"
			[groupNumber]="groupNumber"
		></app-charter-digital-card-front>
		<app-custom-digital-card-back
			[class.hidden]="showFront"
			[data]="cardBackData"
			[customCardType]="customCardType"
		></app-custom-digital-card-back>
	</div>
</div>
