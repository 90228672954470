import { Injectable } from '@angular/core';
import { UserOpenEnrollmentData } from 'src/app/models/open-enrollment.model';
import { Store } from 'src/app/utils/store/store';
import { OpenEnrollmentData } from '../../plan-selection.service';
import { MeUserStoreService } from '../me-user-store/me-user-store.service';
import { Observable, catchError, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { RxjsUtils } from '../../../utils/rxjs';
import { PlanSelectionAPIService } from '../../api/plan-selection-api/plan-selection-api.service';
import { isNil } from '../../../utils/is/is-nil';
export interface PlanSelectionData {
	userData: UserOpenEnrollmentData;
	companyData: OpenEnrollmentData;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionStoreService extends Store<void, PlanSelectionData> {
	constructor(
		private meUserStoreService: MeUserStoreService,
		private planSelectionAPIService: PlanSelectionAPIService
	) {
		super();
	}

	protected retrieve(): Observable<PlanSelectionData> {
		return this.meUserStoreService.get().pipe(
			RxjsUtils.isNotNil(),
			switchMap(() => this.planSelectionAPIService.fetchEnrollment()),
			catchError(() => of(null))
		);
	}

	public isPlanSelectionEnabled(): Observable<boolean> {
		return combineLatest([this.get(), this.meUserStoreService.get()]).pipe(
			filter(([planSelectionData, meUserData]) => planSelectionData != null && meUserData != null),
			map(([planSelectionData, meUserData]) => {
				if (!planSelectionData?.companyData) return false;
				if (!meUserData?.openEnrollmentData.isActive) return false;
				return this.validateData(planSelectionData.companyData);
			})
		);
	}

	public validateData(openEnrollmentData) {
		const today = new Date();
		const endDate = new Date(openEnrollmentData.endDate);
		endDate.setDate(endDate.getDate() + 1);
		return (
			openEnrollmentData.isActive &&
			(isNil(openEnrollmentData.goLiveDate) || today >= new Date(openEnrollmentData.goLiveDate)) &&
			(isNil(openEnrollmentData.endDate) || today <= endDate)
		);
	}
}
