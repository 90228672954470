import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import CFG from '../config/app-config.json';
import { Capacitor } from '@capacitor/core';
import { Intercom as IntercomMobile } from '@capacitor-community/intercom';

declare global {
	interface Window {
		Intercom: any;
	}
}

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	public isEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _intercomHigher$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	get intercomHigher$() {
		return this._intercomHigher$.asObservable();
	}

	constructor(private http: HttpClient) {}

	public onShow(): void {
		this.isEnabled$.next(true);
	}

	public onHide(): void {
		this.isEnabled$.next(false);
	}

	public moveIntercomFromPositionPX(state: number): void {
		this._intercomHigher$.next(state);
	}
	public resetIntercomPosition(): void {
		this._intercomHigher$.next(0);
	}

	public initiateIntercomConversation(messages: string[]) {
		const url = CFG.apiEndpoints.startIntercomConversation;
		return this.http.post(url, { messages });
	}

	public updateIntercomConversation(messages: string[], conversationId: string) {
		const url = CFG.apiEndpoints.updateIntercomConversation;
		return this.http.post(url, { messages, conversationId });
	}

	openMessenger() {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayMessenger();
			return;
		}
		if (window.Intercom) {
			window.Intercom("show");
		}
	}

	openConversation(conversationId) {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayMessenger();
			return;
		}
		if (window.Intercom) {
			window.Intercom("showConversation", conversationId);
		}
	}

	openArticle(articleId: string): void {
		if (Capacitor.isNativePlatform()) {
			IntercomMobile.displayArticle({ articleId: articleId });
			return;
		}
		if (window.Intercom) {
			window["Intercom"]("showArticle", articleId);
		}
	}
}
