import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Provider, ProviderDetails, ProviderLocation } from '../../helpers/providers.helpers';
import { TrackingService } from '../../../../../services/tracking.service';
import { ProvidersSearchService } from 'src/app/services/providers-search/providers-search.service';
import { RibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { retrieveValueByEntity, SearchEntity } from '../../helpers/providers-search.helper';
import { Observable, take } from 'rxjs';
import { UIService } from 'src/app/services/ui.service';

const ABOUT_TAB_NAME = 'About';
const LOCATIONS_TAB_NAME = 'Locations';
const REVIEWS_TAB_NAME = 'Reviews';

@Component({
	selector: 'app-provider-card-details',
	templateUrl: './provider-card-details.component.html',
	styleUrls: ['./provider-card-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderCardDetailsComponent implements OnChanges {
	@Input() providerData: ProviderDetails;
	@Input() isFavorite: boolean = false;
	@Output() isFavoriteChange = new EventEmitter<boolean>();
	@Output() backClick = new EventEmitter();

	public aboutTabName = ABOUT_TAB_NAME;
	public locationsTabName = LOCATIONS_TAB_NAME;
	public reviewsTabName = REVIEWS_TAB_NAME;
	public tabs = [ABOUT_TAB_NAME, LOCATIONS_TAB_NAME];
	public activeTab = this.tabs[0];
	public showAppointmentDrawer: boolean = false;
	public selectedProvider?: Provider;
	public ribbonEntities$ = this._ribbonEntitiesStoreService.get().pipe(RxjsUtils.isNotNil(), take(1));
	public isMobile$: Observable<boolean> = this._uiService.isMobile$;
	public locations: ProviderLocation[] = [];

	constructor(
		private trackingService: TrackingService,
		private _providersSearchService: ProvidersSearchService,
		private _ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private _uiService: UIService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.providerData && this.providerData?.locations?.length > 0) {
			this.locations = this.providerData.locations;
		}
		if (changes.providerData && this.providerData?.specialties.length > 0) {
			const services = [];

			this.ribbonEntities$.subscribe((ribbonEntities) => {
				services.push(
					...this.providerData.specialties.map((specialty) => {
						const id = retrieveValueByEntity(specialty, SearchEntity.Provider, ribbonEntities);
						return { id, name: specialty };
					})
				);
				this._providersSearchService.setServices(services);
			});
		}
		//Reviews Removed by MPI request
		// if (this.providerData?.reviews?.length && !this.tabs.includes(REVIEWS_TAB_NAME)) {
		// 	this.tabs.push(REVIEWS_TAB_NAME);
		// }
	}

	public manuallySetActiveTab() {
		const topOffset = document.querySelector('nav[mat-tab-nav-bar]').getBoundingClientRect().height;
		const contentElements = document.querySelectorAll('.tab-panel');

		contentElements.forEach((element) => {
			const top = element.getBoundingClientRect().top;
			const bottom = element.getBoundingClientRect().bottom;
			if (top - topOffset < window.innerHeight / 2 && bottom - topOffset > window.innerHeight / 2) {
				this.setActiveTab(element.id);
			}
		});
	}

	public scrollTo(tab: string) {
		this.trackingService.trackClientEvent('PS - tab click', { tab: tab });
		const element = document.getElementById(tab);
		element?.scrollIntoView({ behavior: 'smooth' });
	}

	public setActiveTab(tab: string) {
		this.activeTab = tab;
	}

	public openCreateAppointment(providerData: Provider) {
		this.showAppointmentDrawer = true;
		this.selectedProvider = providerData;
	}

	public goBack() {
		this.backClick.emit();
	}
}
