import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Maybe } from '../../utils/types/maybe';
import {
	ATTRIBUTES_LABELS,
	DEFAULT_FORM_VALUES,
	SearchEntity,
	SearchType,
} from '../../modules/main-layout/care-costs/helpers/providers-search.helper';
import { ProviderGender } from '../../modules/main-layout/care-costs/helpers/providers.helpers';
import { SearchAutocompleteService } from './search-autocomplete.service';
import { RxjsUtils } from '../../utils/rxjs';
import { BehaviorSubject, take } from 'rxjs';

const RECENT_SEARCHES = 'recentSearches';
const MAX_RECENT_SEARCHES = 3;

@Injectable({
	providedIn: 'root',
})
export class SearchBarService {
	public isCurrentLocation$ = new BehaviorSubject<boolean>(false);

	private recentSearches: string[] = [];
	public recentSearches$ = new BehaviorSubject<string[]>([]);
	public _rbpNetworkSearch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(private _searchAutocompleteService: SearchAutocompleteService) {
		this.loadSearchHistory();
	}

	public getSearchFormGroup(): FormGroup {
		return new FormGroup({
			searchType: new FormControl<Maybe<SearchType>>(null, Validators.required),
			entity: new FormControl<Maybe<SearchEntity>>(null, Validators.required),
			value: new FormControl<Maybe<string>>(''),
			address: new FormControl<Maybe<string>>(null),
			page: new FormControl<number>(1),
		});
	}

	public getFiltersFormGroup(): FormGroup {
		return new FormGroup({
			language: new FormControl<Maybe<string>>(null),
			minRating: new FormControl<Maybe<number>>(null),
			distance: new FormControl<Maybe<string>>(DEFAULT_FORM_VALUES.distance),
			gender: new FormControl<Maybe<ProviderGender>>(null),
			outOfNetwork: new FormControl<Maybe<boolean>>(false),
			onlineBookingOnly: new FormControl<Maybe<boolean>>(false),
			insuranceGroup: new FormControl<Maybe<string>>(null),
			rbpApproved: new FormControl<Maybe<boolean>>(true),
			mnFavoriteSearch: new FormControl<Maybe<string>>('true'),
		});
	}

	public getSearchTypeAndEntityIfNotSelected(autocomplete, value: string): [SearchType, SearchEntity, string] {
		const firstOptionsGroup = autocomplete.optionGroups.first?.label.toLowerCase();
		const SEARCH_TYPE_DICT = {
			[ATTRIBUTES_LABELS.SPECIALTIES]: SearchType.Options,
			[ATTRIBUTES_LABELS.FACILITIES]: SearchType.Options,
			[ATTRIBUTES_LABELS.TREATMENTS]: SearchType.Options,
			[ATTRIBUTES_LABELS.CONDITIONS]: SearchType.Options,
			[ATTRIBUTES_LABELS.SEARCH_BY_NEED]: SearchType.Options,
			[ATTRIBUTES_LABELS.AUTOCOMPLETE]: SearchType.Free,
		};

		const searchType = SEARCH_TYPE_DICT[firstOptionsGroup] || SearchType.Free; // Assigning search type according to first options group in autocomplete
		let entity = undefined;

		if (searchType === SearchType.Options) {
			const ENTITY_DICT = {
				[ATTRIBUTES_LABELS.SPECIALTIES]: SearchEntity.Provider,
				[ATTRIBUTES_LABELS.FACILITIES]: SearchEntity.Facility,
				[ATTRIBUTES_LABELS.CONDITIONS]: SearchEntity.Condition,
				[ATTRIBUTES_LABELS.TREATMENTS]: SearchEntity.Treatment,
				[ATTRIBUTES_LABELS.AUTOCOMPLETE]: SearchEntity.Provider,
				[ATTRIBUTES_LABELS.SEARCH_BY_NEED]: SearchEntity.SearchByNeed,
			};

			entity = ENTITY_DICT[firstOptionsGroup];
			value = autocomplete.options.first?.value; // Assigning value according to first option from first option group in autocomplete
		} else if (searchType === SearchType.Free) {
			this._searchAutocompleteService.results$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe((results) => {
				if (results.providers.length > 0) {
					// Priority to providers if both providers and facilities are found
					entity = SearchEntity.Provider;
				} else if (results.facilities.length > 0) {
					entity = SearchEntity.Facility;
				} else {
					// Defaults to Provider
					entity = SearchEntity.Provider;
				}
			});
		}

		return [searchType, entity, value];
	}

	private loadSearchHistory(): void {
		const storedHistory = localStorage.getItem(RECENT_SEARCHES);
		if (storedHistory) {
			this.recentSearches = JSON.parse(storedHistory);
			this.recentSearches$.next(this.recentSearches);
		}
	}

	private saveSearchHistory(): void {
		localStorage.setItem(RECENT_SEARCHES, JSON.stringify(this.recentSearches));
	}

	private isValueExists(searchValue: string): boolean {
		return this.recentSearches.includes(searchValue);
	}

	public addToRecentSearches(searchValue: string): void {
		if (this.isValueExists(searchValue)) return;

		this.recentSearches.unshift(searchValue);

		if (this.recentSearches.length > MAX_RECENT_SEARCHES) {
			this.recentSearches.pop();
		}

		this.saveSearchHistory();
	}
}
