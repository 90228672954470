export const mapStyles = [
	{
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#405C79',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#FFFFFF',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#FFFFFF',
			},
			{
				visibility: 'on',
			},
			{
				weight: 4,
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#C8D4DF',
			},
		],
	},
	{
		featureType: 'landscape.natural',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#F2F9FF',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [
			{
				color: '#F2F9FF',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'poi.school',
		stylers: [
			{
				color: '#F2F9FF',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#C8D4DF',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#F0F7FF',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#DBEDFF',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#BFD1E3',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#F0F7FF',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#75B8FF',
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'transit.station.airport',
		stylers: [
			{
				color: '#F2F9FF',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#A3CEFF',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
];

export const mapDefault: google.maps.MapOptions = {
	disableDefaultUI: true,
	zoom: 21,
	styles: mapStyles,
	maxZoom: 19,
	minZoom: 8,
};

export const mapFallback: google.maps.MapOptions = {
	zoom: 14,
};
