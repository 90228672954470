import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { RxjsUtils } from '../../../utils/rxjs';
import { Store } from '../../../utils/store/store';
import { toByKey } from '../../../utils/utils';
import { BaseRibbonHealthService } from '../../base-ribbon.service';

@Injectable({
	providedIn: 'root',
})
export abstract class BaseRibbonEntitiesStoreService extends Store<
	void,
	{
		ribbonServices: any[];
		allRibbonServices: any[];
		locationTypes: any[];
		ribbonProcedures: any[];
		ribbonTreatments: any[];
		ribbonConditions: any[];
		searchByNeeds: any[];
		contractRibbon: any[];
	}
> {
	constructor(private ribbonHealthService: BaseRibbonHealthService) {
		super();
	}

	protected retrieve(): Observable<{
		ribbonServices: any[];
		allRibbonServices: any[];
		locationTypes: any[];
		ribbonProcedures: any[];
		ribbonTreatments: any[];
		ribbonConditions: any[];
		searchByNeeds: any[];
		contractRibbon: any[];
	}> {
		return this.ribbonHealthService.getEntities();
	}

	getHealthServices() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.ribbonServices),
			shareReplay(1)
		);
	}

	getAllHealthServices() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.allRibbonServices),
			shareReplay(1)
		);
	}

	getHealthServicesDict(key: string) {
		return this.getHealthServices().pipe(
			RxjsUtils.isNotNil(),
			map((services) => toByKey(services, key)),
			shareReplay(1)
		);
	}

	getLocationTypes() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.locationTypes),
			shareReplay(1)
		);
	}

	getProcedures() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.ribbonProcedures),
			shareReplay(1)
		);
	}

	getTreatments() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.ribbonTreatments),
			shareReplay(1)
		);
	}

	getConditions() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.ribbonConditions),
			shareReplay(1)
		);
	}

	getSearchByNeeds() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.searchByNeeds),
			shareReplay(1)
		);
	}

	getContractRibbon() {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((result) => result.contractRibbon),
			shareReplay(1)
		);
	}
}
