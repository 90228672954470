import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { UIService } from 'src/app/services/ui.service';
import { invokePhoneDialer } from '../../../../../utils/utils';
import { FacilityDetails } from '../../helpers/providers.helpers';

@Component({
	selector: 'app-facility-card',
	templateUrl: './facility-card.component.html',
	styleUrls: ['./facility-card.component.scss'],
})
export class FacilityCardComponent {
	@Input() appearance: 'standard' | 'outlined' = 'outlined';
	@Input() facility: FacilityDetails;
	@Input() isFavorite: boolean = null;
	@Output() isFavoriteChange = new EventEmitter<boolean>();

	isMobileScreen: boolean = UIService.isMobileDeviceScreen(window) || UIService.isTabletDeviceScreen(window);

	onCallClick(event) {
		event.stopPropagation();
		invokePhoneDialer(this.facility.phoneNumbers[0]);
	}

	public showTooltip(tooltip: MatTooltip): void {
		if (UIService.isMobileDeviceScreen(window)) {
			tooltip.disabled = true;
			return;
		}
		tooltip.disabled = false;
		tooltip.show();
	}
}
