import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mpiServiceTitle',
	pure: true,
})
// MPI wants to have some words and names in AllUpper-Cases etc.
export class MpiServiceTitlePipe implements PipeTransform {
	transform(value: string): string {
		value = value?.trim() ?? '';
		if (!value) {
			return '';
		}

		value = value[0].toUpperCase() + value.slice(1);

		//case insensitive - includes
		if (/cvs/i.test(value)) {
			//cvs to UpperCase
			value = value.replace(/\b(cvs)\b/gi, 'CVS');
		}

		//make first char of next word capital.
		value = value.replace(/(\s\w)/g, function (match) {
			return match.toUpperCase();
		});

		return value;
	}
}
