import { T } from '@transifex/angular';

export class QuickAccessLocalize {
	@T('I have a coverage question')
	static chatItem: string;

	@T('Provider Search')
	static careCostItem: string;

	@T('MP3 Providers')
	static careCostItemMP3: string;

	@T('Find urgent care')
	static urgentCareItem: string;

	@T('Telehealth')
	static telehealthDialogTitle: string;

	@T('Urgent Care')
	static immediateCareTitle: string;
}
