<div class="review-card">
    <div class="review-top">
      <div class="reviewer-info">
        <span class="reviewer-name">{{review.first_name}} {{review.last_name}}</span>
        <div class="review-rating">
            <img src="{{ assetsPath }}/provider-card-rating.svg" alt="Rating"/>
            <div class="review-rating-integer">{{ review.rating | number : '1.0-1' }}</div>
        </div>
      </div>
      <span class="review-date">{{ review.reviewed_at | date:'shortDate' }}</span>
    </div>
    <div class="review-text">
      {{review.text}}
    </div>
</div>
  