import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RxjsUtils } from '../../../../utils/rxjs';
import { AdditionalBenefitsCall } from '../../../../utils/symbols/additional-benefits-call';

import { UserApiService } from '../../../../services/api/user-api/user-api.service';
import { TrackingService } from '../../../../services/tracking.service';
import { AdditionalBenefit } from '../../../../services/api/domain/additional-benefit';

@Component({
	selector: 'app-benefits-tab',
	templateUrl: './benefits-tab.component.html',
	styleUrls: ['./benefits-tab.component.scss'],
})
export class BenefitsTabComponent implements OnInit {
	@Input()
	isMedicalBenefitsActive = true;

	hasBenefits$: Observable<boolean>;

	constructor(private userApiService: UserApiService, private trackingService: TrackingService) {}

	ngOnInit(): void {
		this.hasBenefits$ = this.userApiService.additionalBenefits().pipe(
			catchError(() => of<AdditionalBenefit[]>([])),
			RxjsUtils.timedCache(10000, AdditionalBenefitsCall),
			map((resp) => resp.length !== 0)
		);
	}

	onTrack() {
		this.trackingService.trackClientEvent('Benefits Gallery Employer Benefits tab click');
	}
}
