import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFormControlComponent } from './select-form-control/select-form-control.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { GetPathPipeModule } from '../../pipes/get-path-pipe/get-path-pipe.module';
import { A11yModule } from '@angular/cdk/a11y';
import { MatInputModule } from '@angular/material/input';
import { RippleModule } from 'ripple';

@NgModule({
	declarations: [SelectFormControlComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		GetPathPipeModule,
		A11yModule,
		MatInputModule,
		RippleModule
	],
	exports: [SelectFormControlComponent],
})
export class SelectFormControlModule {}
