import { Injectable } from '@angular/core';
import { SidebarState } from './helpers/sidebar-state';
import { SidebarVisibility } from './helpers/sidebar-visibility';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	//TODO: We might want to change these to observables so that it can be used alongside with other observables and
	// work without change detection
	private _isHidden = true;
	private _sidebarState = SidebarState.Full;
	// Sidebar can be opened/closed on mobile (different from hiding/showing)
	private _isOpen = true;
	private _overriddenSidebarVisibility$: BehaviorSubject<SidebarVisibility> = new BehaviorSubject(SidebarVisibility.Hidden);

	get isOpen() {
		return this._isOpen;
	}

	get isHidden() {
		return this._isHidden;
	}

	get isShrank() {
		return this._sidebarState === SidebarState.Shrink;
	}

	get isFull() {
		return this._sidebarState === SidebarState.Full;
	}

	get overriddenSidebarVisibility$() {
		return this._overriddenSidebarVisibility$.asObservable();
	}

	constructor() {}

	shrink() {
		this._sidebarState = SidebarState.Shrink;
	}

	full() {
		this._sidebarState = SidebarState.Full;
	}

	hide() {
		this._isHidden = true;
	}

	show() {
		this._isHidden = false;
	}

	open() {
		this._isOpen = true;
	}

	close() {
		this._isOpen = false;
	}

	toggle() {
		switch (this._sidebarState) {
			case SidebarState.Full:
				this._sidebarState = SidebarState.Shrink;
				break;
			case SidebarState.Shrink:
				this._sidebarState = SidebarState.Full;
				break;
		}
	}

	overrideSidebarVisibility(visibility: SidebarVisibility) {
		this._overriddenSidebarVisibility$.next(visibility);
	}
}
