import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { ReferralsInfoPageComponent } from './referrals-info-page/referrals-info-page.component';
import { ReferralsDesktopLayoutComponent } from './shared/layouts/desktop/referrals-desktop-layout.component';
import { ReferralsTableComponent } from './shared/referrals-table/referral-table.component';
import { ReferralsMobileLayoutComponent } from './shared/layouts/mobile/referrals-mobile-layout.component';
import { ReferralsReferredContentComponent } from './shared/referral-referred-content/referral-referred-content.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ReferralsStatusComponent } from './shared/referral-status/referral-status.component';
import { ReferralsDownloadComponent } from './shared/referral-download/referral-download.component';
import { ReferralsCardsListComponent } from './shared/referrals-cards-list/referrals-cards-list.component';
import { ReferralsCardComponent } from './shared/referral-card/referral-card.component';
import { ReferralsSortingOptionsComponent } from './shared/referrals-sorting-options/referrals-sorting-options.component';
import { ReferralsNoResultComponent } from './shared/referrals-no-results/referrals-no-results.component';
import { SelectFormControlModule } from '../../app-shared/standalone/form-controls/select-form-control/select-form-control.module';
import { AppSharedModule } from '../../app-shared/app-shared.module';

@NgModule({
	declarations: [
		ReferralsInfoPageComponent,
		ReferralsDesktopLayoutComponent,
		ReferralsMobileLayoutComponent,
		ReferralsTableComponent,
		ReferralsDownloadComponent,
		ReferralsCardsListComponent,
		ReferralsStatusComponent,
		ReferralsNoResultComponent,
		ReferralsCardComponent,
		ReferralsReferredContentComponent,
		ReferralsSortingOptionsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SelectFormControlModule,
		MatIconModule,
		AppSharedModule,
		MatInputModule,
		MatFormFieldModule,
		TextFieldModule,
		AppSharedModule,
		IonicModule,
		MatTableModule,
		MatSortModule,
	],
	exports: [
		ReferralsInfoPageComponent,
		ReferralsDesktopLayoutComponent,
		ReferralsTableComponent,
		ReferralsCardComponent,
		ReferralsDownloadComponent,
		ReferralsMobileLayoutComponent,
		ReferralsStatusComponent,
		ReferralsCardsListComponent,
		ReferralsNoResultComponent,
		ReferralsReferredContentComponent,
		ReferralsSortingOptionsComponent,
	],
})
export class ReferralsInfoModule {}
