import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { T } from '@transifex/angular';

import { AppError } from '../../../../error-handlers/app-errors';

import { UserService } from '../../../../services/user.service';

import { HealtheeDialogContent } from '../../../app-shared/healthee-dialog/healthee-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactUsLocalize } from './contact-us-localize';
@Component({
	selector: 'app-contact-us-modal',
	templateUrl: './contact-us-modal.component.html',
	styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent extends HealtheeDialogContent implements OnInit {
	@ViewChild('recoverEmail') recoverEmail: ElementRef;

	public subject: string;
	public body: string;
	public email: string;
	public contactForm: FormGroup;
	public unknownUser: boolean;
	public isSending: boolean = false;
	public headline: string = ContactUsLocalize.headline;
	public submit: string = ContactUsLocalize.submit;

	constructor(private fb: FormBuilder, private userService: UserService, private matSnackBar: MatSnackBar) {
		super();
		this.userService.user$.subscribe((user) => {
			console.log({ user });
			if (!user) this.unknownUser = true;
		});
	}

	ngOnInit(): void {
		this.subject = this.data?.subject;
		const form = {
			email: null,
			subject: [this.subject, [Validators.required]],
			body: [this.body, [Validators.required]],
		};

		if (this.unknownUser) form.email = [this.email, [Validators.required, Validators.email]];
		this.contactForm = this.fb.group(form);
	}

	public sendUsEmail() {
		if (!this.contactForm.valid) return;

		this.isSending = true;

		this.userService
			.sendContactUsEmail({
				email: this.contactForm.value.email,
				subject: this.contactForm.value.subject,
				text: this.contactForm.value.body,
			})
			.subscribe({
				next: () => this.handleSuccess(),
				error: (error) => this.handleFailure(error),
			})
			.add(() => {
				this.isSending = false;
			});
	}

	private handleSuccess() {
		this.matSnackBar.open(ContactUsLocalize.successMessage, 'X', {
			duration: 5000,
			panelClass: 'snackbar-success',
			verticalPosition: 'top',
		});

		this.closeDialog.emit(null);
	}

	private handleFailure(error: Error) {
		if (error instanceof AppError) throw error; // if this is a known error (an app created error), it has it's own purpose and messge. Throw it to the global error-handler

		// else - throw an new AppError for the global error handler to show the message we want (otherwise it is only logged)
		throw new AppError(ContactUsLocalize.userErrorCannotSendContactUsMessage);
	}
}
