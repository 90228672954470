import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppointmentFetch, AppointmentStatuses } from 'src/app/models/appointment.model';
import { AppointmentsListStoreService } from '../../../../../services/stores/appointments-list-store/appointments-list-store.service';

@Component({
	selector: 'app-provider-appointments',
	templateUrl: './provider-appointments.component.html',
	styleUrls: ['./provider-appointments.component.scss'],
})
export class ProviderAppointmentsComponent implements OnInit {
	public activeAppointments$: Observable<AppointmentFetch[]>;
	refreshAppointments$ = new BehaviorSubject<boolean>(null);

	constructor(private appointmentsListStoreService: AppointmentsListStoreService) {}

	ngOnInit(): void {
		this.getAppointments();
	}

	private getAppointments() {
		this.activeAppointments$ = this.appointmentsListStoreService.get().pipe(
			map((appointments) => {
				return appointments?.filter((appointment) =>
					appointment.status !== AppointmentStatuses.cancelled &&
					appointment.status !== AppointmentStatuses.noShow &&
					appointment.status !== AppointmentStatuses.cancelFailed &&
					appointment.status !== AppointmentStatuses.cancelPending
				)
			}),
			shareReplay(1)
		);
	}

	appointmentUpdated() {
		this.refreshAppointments$.next(null);
	}
}
