import { Benefit } from 'src/app/models/benefits.model';
import { T } from '@transifex/angular';

export interface ImmediateCareObj {
	index: number;
	benefit: Benefit;
	title: string;
	description: string;
	icon: string;
	facilityType: string;
}

export class ImmediateCareLocalize {
	@T('Urgent Care')
	static urgent_care_title: string;
	@T(
		'Urgent care centers provide timely medical attention for non-life-threatening conditions and offer a range of services, including X-rays, lab tests, and stitches.'
	)
	static urgent_care_description: string;
	@T('Emergency Room')
	static emergency_room_title: string;
	@T(
		'Emergency rooms are meant for life-threatening conditions or severe injuries that require immediate medical attention.'
	)
	static emergency_room_description: string;
	@T('Walk-in Retail Clinic')
	static walk_in_retail_clinic_title: string;
	@T('Walk-in clinics offer non-emergency medical care without prior appointments.')
	static walk_in_retail_clinic_description: string;
	@T('Telehealth')
	static careAndCost: string;
	@T('Telehealth')
	static telehealth_title: string;
	@T('Telehealth offers patients the opportunity to have remote  medical appointments using their computer or phone.')
	static telehealth_description: string;
}

export const ImmediateCareServices = [
	{
		index: 2,
		benefit: '60e3bd9e54823252fe1b8f55',
		title: ImmediateCareLocalize.urgent_care_title,
		description: ImmediateCareLocalize.urgent_care_description,
		icon: 'assets/icons/icon-urgent-care.svg',
		facilityType: 'Urgent Care',
	},
	{
		index: 4,
		benefit: '5d9c3af263507c70ef68861b',
		title: ImmediateCareLocalize.emergency_room_title,
		description: ImmediateCareLocalize.emergency_room_description,
		icon: 'assets/icons/icon-emergency-room.svg',
		facilityType: 'Emergency Room',
	},
];
