import { Component, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderDetails } from '../../modules/main-layout/care-costs/helpers/providers.helpers';
import { Maybe } from '../../utils/types/maybe';
import { TxNativeModule } from '@transifex/angular';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isIonic } from '../../utils/utils';
import { blobToBase64 } from '../../utils/blob-to-base64';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { TrackingService } from '../../services/tracking.service';

@Component({
	selector: 'app-share-provider',
	standalone: true,
	imports: [CommonModule, TxNativeModule],
	templateUrl: './share-provider.component.html',
	styleUrls: ['./share-provider.component.scss'],
})
export class ShareProviderComponent {
	@Input()
	provider: Maybe<ProviderDetails>;

	encodeURIComponent = encodeURIComponent;

	constructor(
		private elementRef: ElementRef,
		private socialSharing: SocialSharing,
		private trackingService: TrackingService
	) {}

	async share(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		const element: HTMLElement = this.elementRef.nativeElement.cloneNode(true);
		element.style.width = '326px';
		element.style.display = 'flex';
		document.body.appendChild(element);

		try {
			const canvas = await html2canvas(element, { scale: 3, useCORS: true });
			document.body.removeChild(element);

			const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
			const fileWidth = 200;
			const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
			const PDF = new jsPDF('p', 'mm', 'a4');
			PDF.addImage(imageGeneratedFromTemplate, 'PNG', 5, 5, fileWidth, generatedImageHeight);
			if (isIonic()) {
				await this.socialSharing.share(null, null, await blobToBase64(PDF.output('blob')));
				return;
			}
			let providerNetwork: string;
			if (this.provider.isInNetwork) {
				providerNetwork = 'In Network';
			} else if (this.provider.isInNetwork === false) {
				providerNetwork = 'Out of Network';
			} else {
				providerNetwork = 'Unknown';
			}
			PDF.save(`${this.provider.firstName} ${this.provider.lastName}.pdf`);
			this.trackingService.trackClientEvent('PS - Share Provider', {
				firstName: this.provider.firstName,
				lastName: this.provider.lastName,
				npi: this.provider.npi,
				network: providerNetwork,
				specialty: this.provider.primarySpecialty,
			});
		} catch (e) {}
	}
}
