import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { UIService } from '../../../services/ui.service';
import { TrackingService } from '../../../services/tracking.service';
import { StandardLoadingHandler } from '../../../utils/loading-handler/standard-loading-handler';
import { delay } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isIonic } from '../../../utils/utils';
import * as Sentry from '@sentry/angular';
import { T } from '@transifex/angular';
import { blobToBase64 } from '../../../utils/blob-to-base64';
import { PlanType } from '../../../models/plan-type.model';
import { UserService } from '../../../services/user.service';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { CUSTOM_CARD_TYPES } from 'src/app/services/digital-card/digital-card.service';
import { CardBackData, CardFrontData } from '../../../services/digital-card/templates/shared';

@Component({
	selector: 'app-custom-digital-card',
	templateUrl: './custom-digital-card.component.html',
	styleUrls: ['./custom-digital-card.component.scss'],
})
export class CustomDigitalCardComponent implements OnInit, OnChanges {
	@Input() digitalCardTemplates: { front: CardFrontData; back: CardBackData[]; customCardType: CUSTOM_CARD_TYPES };

	@ViewChild('cardElement', {
		read: ElementRef,
	})
	cardElement: ElementRef;
	shareLoadingHandler = new StandardLoadingHandler();

	@T('Something went wrong', {
		token: 'sharingErrorMessage',
	})
	sharingErrorMessage: string;

	@T('Front')
	front: string;

	@T('Back')
	back: string;

	cardSide: string;

	public showFront: boolean = true;

	public cardFrontData: CardFrontData;
	public cardBackData: CardBackData[];
	public companyName: string;
	public groupNumber: string;
	public customCardType: CUSTOM_CARD_TYPES;

	constructor(
		private socialSharing: SocialSharing,
		private uiService: UIService,
		private trackingService: TrackingService,
		private userService: UserService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {}

	ngOnInit(): void {
		this.cardSide = this.back;
		this.userService.userData$.subscribe((userData) => (this.companyName = userData.company.name));
		this.userPlanDataStoreService.get().subscribe((plan) => (this.groupNumber = plan?.contract?.groupNumber));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.digitalCardTemplates?.currentValue) {
			this.cardFrontData = this.digitalCardTemplates.front;
			this.cardBackData = this.digitalCardTemplates.back;
			this.customCardType = this.digitalCardTemplates.customCardType;
		}
	}

	public getCustomCardTypes() {
		return CUSTOM_CARD_TYPES;
	}

	public switchSide() {
		this.showFront = !this.showFront;
		this.cardSide = this.showFront ? this.back : this.front;
	}

	async share(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		this.trackingService.trackClientEvent('DMC - Share Card', { planType: PlanType.medical });

		this.shareLoadingHandler.start();

		await delay(1000);

		const element: HTMLElement = this.cardElement.nativeElement.cloneNode(true);

		element.style.width = window.innerWidth <= 500 ? `900px` : '1200px';
		element.style.display = 'flex';
		element.style.justifyContent = 'space-evenly';
		element.style.gap = '30px';

		const minChildWidth = window.innerWidth <= 500 ? '20rem' : '35rem';
		Array.from(element.children).map((el: HTMLElement) => (el.style.minWidth = minChildWidth));

		document.body.appendChild(element);
		try {
			html2canvas(element, { scale: 3, useCORS: true, allowTaint: true }).then(async (canvas) => {
				const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
				const fileWidth = 200;
				const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
				const PDF = new jsPDF('p', 'mm', 'a4');
				PDF.addImage(imageGeneratedFromTemplate, 'PNG', 5, 5, fileWidth, generatedImageHeight);
				if (isIonic()) {
					await this.socialSharing.share(null, null, await blobToBase64(PDF.output('blob')));
					this.shareLoadingHandler.finish();
					return;
				}
				PDF.save(`my ${PlanType.medical} card.pdf`);
				this.shareLoadingHandler.finish();
			});
		} catch (e) {
			this.shareLoadingHandler.finish();
			Sentry.captureException("error generating card's image");
			Sentry.captureException(e);
			Sentry.captureException(e.message);
			this.uiService.displayAppMessage(this.sharingErrorMessage);
		}
		document.body.removeChild(element);
	}
}
