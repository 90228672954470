<label *ngIf="!!label" [for]="label" class="ripple-label">{{ label }}</label>
<div class="ripple-input" [class.variant-disabled]="isDisabled" [class.hide-number-arrows]="hideNumberArrows">
	<ng-container *ngIf="leadingIcon">
		<div class="ripple-input_icon">
			<ripple-icon [name]="leadingIcon"></ripple-icon>
		</div>
	</ng-container>

	<input
		#inputRef
		*ngIf="!matAutocomplete; else withAutocomplete"
		[id]="label"
		[type]="type"
		[placeholder]="placeholder"
		[disabled]="isDisabled"
		[ngModel]="value"
		[pattern]="pattern"
		(ngModelChange)="handleChange($event)"
		(blur)="onTouched()"
		class="ripple-input_field"
		[class.fs-unmask]="unmaskInSessions"
	/>
	<ng-template #withAutocomplete>
		<input
			#autocompleteRef
			[id]="label"
			[type]="type"
			[matAutocomplete]="matAutocomplete"
			[placeholder]="placeholder"
			[disabled]="isDisabled"
			[ngModel]="value"
			(ngModelChange)="handleChange($event)"
			(blur)="onTouched()"
			class="ripple-input_field"
			[class.fs-unmask]="unmaskInSessions"
		/>
	</ng-template>
	<ripple-loader *ngIf="showLoading" size="xs" color="gray"></ripple-loader>
	<button *ngIf="showResetButton && value" (click)="resetValue()" class="close" title="Clear input">
		<ripple-icon name="close"></ripple-icon>
	</button>
</div>
