import { AfterViewInit, Component, Input } from "@angular/core";
import { GipitpotCoverageMessage } from "../../../../models/gipitpot/gipitpot-messages";
import { ServicesSearchApiService } from "../../../../services/api/services-search-api/services-search-api.service";
import { ServicesSearchResponse } from "../../../../services/api/services-search-api/helpers/services-search.response";
import { LabelColorTheme, LoaderAlign } from "ripple";

@Component({
	selector: "app-gipitpot-coverage-intent",
	templateUrl: "./gipitpot-coverage-intent.component.html",
	styleUrls: ["./gipitpot-coverage-intent.component.scss"]
})
export class GipitpotCoverageIntentComponent implements AfterViewInit {
	@Input() message: GipitpotCoverageMessage;

	public coverageLoading: boolean = false;
	public services: { oid: string, name: string }[];

	// protected
	protected readonly LoaderAlign = LoaderAlign;
	protected readonly LabelColorTheme = LabelColorTheme;

	constructor(private servicesSearchService: ServicesSearchApiService) {
	}

	// lifecycle hooks

	ngAfterViewInit(): void {
		if (this.message?.entity) {
			this.coverageSearch();
		}
	}

	// Public methods

	public coverageSearch(): void {
		this.coverageLoading = true;
		this.servicesSearchService.servicesSearch(this.message.entity).subscribe({
			next: (response) => {
				this.handleCoverageSearchResponse(response);
				this.coverageLoading = false;
			},
			error: (error) => {
				console.error(error);
				this.coverageLoading = false;
			}
		});
	}

	// Private methods
	private handleCoverageSearchResponse(response: ServicesSearchResponse): void {
		if (response.total > 0) {
			this.services = response.services;
			// response.services.forEach((service) => {
			// 	this.services.push(service);
			// });
		}
	}

}
