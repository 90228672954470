<div class="content-wrapper">
	<!-- <p class="contact-text">We welcome your feedback, and happy to answer any questions you might have about Zoe. So, what’s on your mind?</p> -->
	<img src="../../../../assets/images/onboarding/Step1.png" />
	<UT
		str="<h1>Hi {{ data.name }},</h1>
    <p>Thanks for stopping by.<br>
        To provide instant answers to all your benefits and care-related questions, we’ll first need to receive and analyze your employer-sponsored health plan.
        I’ll reach out as soon as it’s ready.
    </p>
    <p>Thanks for your understanding,<br>Zoe.
    </p>"
	></UT>

	<button (click)="onCloseClicked()" class="bt-primary"><UT str="Close" key="close_button"></UT></button>
</div>
