import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TxNativeModule } from "@transifex/angular";
import { RippleModule } from "ripple";
import { GipitpotInputComponent } from "./gipitpot-input/gipitpot-input.component";
import { AppSharedModule } from "../app-shared/app-shared.module";
import { GipitpotUserMessageComponent } from "./gipitpot-user-message/gipitpot-user-message.component";
import { GipitpotSystemMessageComponent } from "./gipitpot-system-message/gipitpot-system-message.component";
import { GipitpotControllerComponent } from "./gipitpot-controller.component";
import { CommonModule } from "@angular/common";
import { GipitpotCoverageIntentComponent } from "./gpitpot-intents/coverage/gipitpot-coverage-intent.component";

@NgModule({
	declarations: [
		// components
		GipitpotInputComponent,
		GipitpotUserMessageComponent,
		GipitpotSystemMessageComponent,
		GipitpotControllerComponent,

		// intents
		GipitpotCoverageIntentComponent
	],
	imports: [
		CommonModule,
		TxNativeModule,
		FormsModule,
		RippleModule,
		AppSharedModule
	]
})

export class GipitpotModule {
}
