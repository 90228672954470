<div class="accordion" [class.expanded]="isExpanded" (click)="isExpanded = !isExpanded; isExpandedChange.emit(isExpanded);">
	<div class="header">
		<div>
			<h1 class="title">{{ title | translate }}</h1>
			<div class="description">{{ description | translate }}</div>
		</div>
		<div class="counter-with-arrow">
			<span class="counter" *ngIf="count > 0">{{ count }}</span>
			<span class="arrow">
				<mat-icon *ngIf="isExpanded">expand_less</mat-icon>
				<mat-icon *ngIf="!isExpanded">expand_more</mat-icon>
			</span>
		</div>
	</div>
	<div class="children" *ngIf="isExpanded">
		<ng-content></ng-content>
	</div>
</div>
