import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { UserData } from '../../../models/user-data';
import { UserService } from '../../../services/user.service';
import { TrackingService } from '../../../services/tracking.service';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-toggle-notifications-modal',
	templateUrl: './toggle-notifications-modal.component.html',
	styleUrls: ['./toggle-notifications-modal.component.scss'],
})
export class ToggleNotificationsModalComponent extends HealtheeDialogContent implements OnInit {
	userData$: Observable<UserData>;
	isSubscribedToNotifications$: Observable<boolean>;

	constructor(private userService: UserService, private trackingService: TrackingService) {
		super();
	}

	ngOnInit(): void {
		this.userData$ = this.userService.userData$.pipe(take(1));
		this.isSubscribedToNotifications$ = this.userData$.pipe(
			map((userData) => userData.subscribeToAppointmentNotifications)
		);
	}

	public onChoiceChange({ checked }): void {
		this.userService.updateAppointmentNotificationChoice(checked);
		this.userData$.pipe(take(1)).subscribe((userData: UserData) => {
			this.trackingService.trackClientEvent('Schedule - change communication preferences', userData);
		});
	}
}
