import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { T } from '@transifex/angular';
import { take } from 'rxjs';
import { HealtheeService } from 'src/app/models/service.model';
import { RibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { RxjsUtils } from 'src/app/utils/rxjs';

@Component({
	selector: 'app-top-searched-specialties',
	templateUrl: './top-searched-specialties.component.html',
	styleUrls: ['./top-searched-specialties.component.scss'],
})
export class TopSearchedSpecialtiesComponent implements OnInit {
	@Output() public setTopServiceSelected = new EventEmitter<HealtheeService>(null);

	@T('Primary care')
	private primary_care: string;

	@T('Dentist')
	private dentist: string;

	@T('Optometrist')
	private optometrist: string;

	@T('OB-GYN')
	private ob_gyn: string;

	@T('Dermatology')
	private dermatology: string;

	@T('Psychologist')
	private psychologist: string;

	public topSearchedServices = [
		{
			ribbonId: '',
			ribbonName: 'Primary Care Provider - PCP',
			text: '',
		},
		{
			ribbonId: 'f9281d01-128a-4ee9-89ac-612680114304',
			ribbonName: 'Dentist',
			text: '',
		},
		{
			ribbonId: '967aa811-b696-4b29-9180-4f43e3d77626',
			ribbonName: 'Optometrist',
			text: '',
		},
		{
			ribbonId: 'd8f7fff0-9c5b-42e2-b011-376315d4be3d',
			ribbonName: 'Obstetrics and Gynecology - Gynecology',
			text: '',
		},
		{
			ribbonId: 'b9dc44e1-6add-41f8-8df4-a8ef9cea706a',
			ribbonName: 'Dermatology',
			text: '',
		},
		{
			ribbonId: 'adfb5cae-37f0-4472-a92f-5448c0ff3d66',
			ribbonName: 'Psychologist',
			text: '',
		},
	];

	private healthServices;

	constructor(
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private trackingService: TrackingService
	) {}

	ngOnInit(): void {
		this.ribbonEntitiesStoreService
			.getHealthServices()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe((services) => (this.healthServices = services));

		this.initiateTranslations();
	}

	private initiateTranslations() {
		this.topSearchedServices[0].text = this.primary_care;
		this.topSearchedServices[1].text = this.dentist;
		this.topSearchedServices[2].text = this.optometrist;
		this.topSearchedServices[3].text = this.ob_gyn;
		this.topSearchedServices[4].text = this.dermatology;
		this.topSearchedServices[5].text = this.psychologist;
	}

	public onClick(service) {
		this.trackingService.trackClientEvent('PS - Frequently Searched', {
			speciality_type: `${service.text}`,
		});
		const selected = this.healthServices.find((healthService) => healthService.ribbon.id === service.ribbonId);
		this.setTopServiceSelected.emit(selected);
	}
}
