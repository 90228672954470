import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'ripple-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
	@Input() tabs: string[] = [];
	@Input() selectedIndex = -1;
	@Output() selectedIndexChange = new EventEmitter<number>();

	handleTabClick(tabIndex: number): void {
		this.selectedIndex = tabIndex;
		this.selectedIndexChange.emit(this.selectedIndex);
	}
}
