import { Component, OnInit, Input } from '@angular/core';
import { Maybe } from '../../../../../utils/types/maybe';

@Component({
	selector: 'app-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent implements OnInit {
	@Input()
	text = '';

	@Input()
	icon = '';

	@Input()
	selectedIcon = '';

	@Input()
	link = '/';

	@Input()
	shrinked: Maybe<boolean>;

	@Input()
	fragment: Maybe<string>;

	constructor() {}

	ngOnInit(): void {}
}
