<div class="referral-card-container" [ngClass]="{ 'teaser-card': isTeaserCard }">
	<div class="card-row align-top" *ngIf="!isTeaserCard">
		<div class="card-column">
			<div class="title">Referred by</div>
		</div>
		<div class="card-column rigth">
			<app-referral-referred-content [item]="item"></app-referral-referred-content>
		</div>
	</div>
	<div class="card-row align-top">
		<div class="card-column">
			<div class="title">Referred to</div>
		</div>
		<div class="card-column">
			<app-referral-referred-content [referredTo]="true" [item]="item"></app-referral-referred-content>
		</div>
	</div>
	<div class="card-row">
		<div class="card-column">
			<div class="title">Issue date</div>
		</div>
		<div class="card-column">{{ item.CreatedDate | date : 'MMM d, y' }}</div>
	</div>
	<div class="card-row" *ngIf="!isTeaserCard">
		<div class="card-column">
			<div class="title">Expiration date</div>
		</div>
		<div class="column">{{ item.ExprDate | date : 'MMM d, y' }}</div>
	</div>
	<div class="card-row without-border">
		<div class="card-column">
			<div class="title">Status</div>
		</div>
		<div class="card-column">
			<app-referral-status [value]="item.Status" [alignRight]="true"></app-referral-status>
		</div>
	</div>
	<div class="card-row without-border">
		<div class="card-column download-referral">
			<app-referral-download [item]="item" [withBorder]="true"></app-referral-download>
		</div>
	</div>
</div>
