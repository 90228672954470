import { Injectable } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';
import { FavoriteProvidersApiService } from '../../../services/api/favorite-providers-api/favorite-providers-api.service';
import { HealtheeDialogService } from '../../../services/healthee-dialog.service';
import { AddToFavoritesSuccessDialogComponent } from '../add-to-favorites-success-dialog/add-to-favorites-success-dialog.component';
import { FavoriteProvider } from '../helpers/providers.helpers';
import { RemoveFromFavoritesConfirmationDialogComponent } from '../remove-from-favorites-confirmation-dialog/remove-from-favorites-confirmation-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class MyCareTeamService {
	constructor(
		private favoriteProviderApiService: FavoriteProvidersApiService,
		private healtheeDialogService: HealtheeDialogService
	) {}

	addToFavorites(provider: Partial<FavoriteProvider>) {
		this.favoriteProviderApiService.addToFavorites(provider).subscribe({
			next: () => {
				this.healtheeDialogService.open({
					component: AddToFavoritesSuccessDialogComponent,
					data: { provider },
					hasCloseButton: true,
					noPadding: true,
					componentOnly: true,
				});
			},
		});
	}

	removeFromFavorites(provider: FavoriteProvider): Observable<void> {
		return this.healtheeDialogService
			.open({
				hasCloseButton: true,
				componentOnly: true,
				component: RemoveFromFavoritesConfirmationDialogComponent,
				noPadding: true,
				data: { provider },
			})
			.pipe(
				filter((isConfirmed) => isConfirmed),
				switchMap(() => this.favoriteProviderApiService.removeFromFavorites(provider.npi))
			);
	}
}
