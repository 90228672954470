import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';
import { ReferraceinfoStoreService } from 'src/app/services/stores/referrance-info-store/referrance-info-store.service';

@Component({
	selector: 'app-referral-download',
	templateUrl: './referral-download.component.html',
	styleUrls: ['./referral-download.component.scss'],
})
export class ReferralsDownloadComponent {
	public downloading: boolean = false;

	@Input() item: ReferralsInfoResponse;
	@Input() withBorder: boolean = false;

	@Output() referralDownloadEvent: EventEmitter<string> = new EventEmitter<string>();

	constructor(private referraceinfoStoreService: ReferraceinfoStoreService) {}

	public onReferralDownloadClicked(): void {
		if (this.downloading) {
			return;
		}

		this.downloading = true;
		this.referraceinfoStoreService.downloadReferral(this.item.ReferralCode).subscribe((f: any) => {
			const blob = new Blob([f], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${this.item.ReferralCode}.pdf`;
			link.click();
			this.downloading = false;
		});
	}
}
