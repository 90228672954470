<div class="top-searched-container">
	<h2 class="title">{{ 'Frequently searched' | translate }}</h2>
	<div class="top-searched-list-wrapper">
		<div class="top-searched-list">
			<ripple-button
				*ngFor="let service of topSearchedServices"
				[label]="service.text"
				hierarchy="tertiary"
				(onClick)="onClick(service)"
			></ripple-button>
		</div>
	</div>
</div>
