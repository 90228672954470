import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import CFG from '../config/app-config.json';
import { BaseRibbonHealthService } from './base-ribbon.service';

@Injectable({ providedIn: 'root' })
export class RibbonHealthService extends BaseRibbonHealthService {
	constructor(http: HttpClient) {
		super(http);
	}

	public getEntities() {
		return this.http.get<any>(CFG.apiEndpoints.ribbonEntities).pipe(
			map((result) => {
				result.allRibbonServices = [...result.ribbonServices];
				result.ribbonServices = this.getWoDuplicatedRibbonKey(result.ribbonServices);
				result.ribbonServices = result.ribbonServices.sort((a, b) => a.ribbon.key.localeCompare(b.ribbon.key));
				result.searchByNeeds = this.searchByNeedsList;
				return result;
			})
		);
	}
}
