import { T } from '@transifex/angular';

export class ContactUsLocalize {
	@T('We welcome your feedback, and happy to answer any questions you might have about Zoe. So, what’s on your mind?')
	static headline: string;

	@T('Thank you for getting in touch!')
	static successMessage: string;

	@T('Oops! We cannot send your message at the moment! Please try again later.')
	static userErrorCannotSendContactUsMessage: string;

	@T('Submit')
	static submit: string;
}
