<div class="service-panel" *ngIf="!isAdditionalService; else displayOldLayout">
	<ng-container *ngIf="activeBenefit; else displayLoadingSpinner">
		<app-service-panel-header
			[shrinked]="isHeaderShrinked$ | async"
			[bgImage]="(service$ | async)?.iconPath | serviceImage"
			[title]="(service$ | async)?.abbreviate"
			[covered]="(userBenefitsMap$ | async)?.get(serviceId)?.covered === 'covered'"
			[carrier]="(userBenefitsMap$ | async)?.get(serviceId)?.carrier?.name"
			[planType]="
				(userBenefitsMap$ | async)?.get(serviceId)?.esp ? 'Employer Sponsored' : (service$ | async)?.type
			"
			(closeClick)="closeClick.emit()"
			[isESP]="(userBenefitsMap$ | async)?.get(serviceId)?.esp"
		></app-service-panel-header>

		<div
			class="service-content without-top-padding"
			[class.with-padding]="isHeaderShrinked$ | async"
			(scroll)="contentScrollPos$.next($any($event?.srcElement)?.scrollTop)"
		>
			<div class="service-content-tabs">
				<ng-container
					*ngIf="
						isEnabledCoverageInfoRestructure && activeBenefit === BenefitType.NEW;
						else displayOldBenefit
					"
				>
					<nav mat-tab-nav-bar disableRipple [tabPanel]="tabPanel">
						<ng-container *ngFor="let network of (newBenefit$ | async)?.networks; let i = index">
							<a
								mat-tab-link
								*ngIf="!((isOneTabAnthemService() | async) && i > 0)"
								(click)="setActiveTab(i, network.title.toLowerCase())"
								[active]="activeNetworkTabIndex === i"
							>
								<div
									class="service-content-tab"
									[ngClass]="
										network.title.toLowerCase() === OON_MN_NAME ||
										network.title.toLowerCase() === OON_SIMPLE_NAME
											? 'red'
											: 'green'
									"
								>
									<span></span>

									<ng-container *ngIf="network.title.toLowerCase() !== 'mp3'; else mp3TabTitle">
										{{ transformNetworkName(network.title) }}
									</ng-container>

									<ng-template #mp3TabTitle>
										<span [appTooltipWithTouch]="mp3tooltipText">{{ network.title }}</span>
									</ng-template>
								</div>
							</a>
						</ng-container>
					</nav>

					<mat-tab-nav-panel #tabPanel>
						<div class="service-content-copay">
							<app-service-panel-copay
								[copay]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].copay"
								[mr]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].memberResponsibility"
								[subjectToDeductible]="
									(newBenefit$ | async)?.networks[activeNetworkTabIndex].subjectToDeductible
								"
								[dependsOnLocation]="
									(newBenefit$ | async)?.networks[activeNetworkTabIndex].dependsOnLocation
								"
								[networkTitle]="(newBenefit$ | async)?.networks[activeNetworkTabIndex].title"
							></app-service-panel-copay>
						</div>
						<div>
							<app-service-panel-accordion
								*ngIf="newBenefit$ | async | isShowImportantToKnow : activeNetworkTabIndex"
								[title]="'Important to know'"
								[description]="
									'Here are a few more items your policy mentions about coverage related to this topic'
								"
								[count]="
									getImportantToKnowCount(
										(newBenefit$ | async)?.networks[activeNetworkTabIndex],
										(newBenefit$ | async)?.gender
									)
								"
								[isExpanded]="true"
							>
								<app-service-panel-card
									*ngIf="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex]
											.reimbursement as reimbursement
									"
									[title]="'Reimbursements'"
									[description]="'Your plan will reimburse you up to'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{ reimbursement | displayTypeAndAmount }}"
										[colorTheme]="LabelColorTheme.darkTextGreenBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="(newBenefit$ | async)?.gender"
									[title]="'Gender Limitation'"
									[description]="'Your policy only covers this service for individuals'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{
											!(newBenefit$ | async)?.gender || (newBenefit$ | async)?.gender === 'null'
												? 'NA'
												: (newBenefit$ | async)?.gender
										}}
										"
										[colorTheme]="LabelColorTheme.darkTextRedBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction"
									[title]="'Age Limitation'"
									[description]="'Your policy only covers this service for individuals'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction.min ||
												'NA'
										}}
														{{
											(newBenefit$ | async)?.networks[activeNetworkTabIndex].ageRestriction.max ||
											'NA'
												? ' - ' +
														(newBenefit$ | async)?.networks[activeNetworkTabIndex]
															.ageRestriction.max || 'NA'
												: '+'
										}}"
										[colorTheme]="LabelColorTheme.darkTextRedBg"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].countLimitations"
									[title]="'Limitation'"
								>
									<div class="separator"></div>
									<ripple-label
										text="{{
												getCountLimitationsText(
													(newBenefit$ | async)?.networks[activeNetworkTabIndex]
														.countLimitations
												)
										}}"
										[colorTheme]="LabelColorTheme.darkTextRedBgWrapWhiteSpaceBorderRadius1"
										preformatted="true"
									></ripple-label>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].preExistConditions
											?.length
									"
									[title]="'Pre-existing Conditions'"
									[description]="'Only if you have medical precondition'"
								>
									<div class="separator"></div>
									<ng-container
										*ngFor="
											let condition of (newBenefit$ | async)?.networks[activeNetworkTabIndex]
												.preExistConditions
										"
									>
										<ripple-label
											*ngFor="let innerCondition of condition.condition"
											text="{{ (getMedicalConditionById(innerCondition) | async)?.name }}"
											[colorTheme]="LabelColorTheme.darkTextRedBg"
										></ripple-label>
									</ng-container>
								</app-service-panel-card>
								<app-service-panel-card
									*ngIf="(newBenefit$ | async)?.networks[activeNetworkTabIndex].notes?.length"
									[title]="'Notes'"
								>
									<div class="separator"></div>
									<pre
										class="note"
										*ngFor="
											let note of (newBenefit$ | async)?.networks[activeNetworkTabIndex].notes
										"
										[innerHTML]="note | makeCDCVaccineUrlHref"
									></pre>
								</app-service-panel-card>

								<app-service-panel-card *ngIf="showDisclaimerPanel() | async" [title]="'Disclaimer'">
									<div class="separator"></div>
									<div class="note">
										<span
											>Coverage is based on medical necessity and subject to review. Calculation
											of your cost share (co-pay and/or co-insurance) is only an estimate and may
											not reflect the provider network status, valid referrals, dollar and visit
											limits, and/or certain other claims processing procedures.</span
										>
										<br />
									</div>
								</app-service-panel-card>
							</app-service-panel-accordion>
							<app-service-panel-accordion
								*ngIf="showAppointmentChecklist() | async"
								[title]="'Appointment Checklist'"
								[description]="'Here are a few items you might need prior to your appointment'"
								[count]="
									getAppointmentChecklistCount((newBenefit$ | async)?.networks[activeNetworkTabIndex])
								"
								[isExpanded]="false"
							>
								<app-service-panel-card
									*ngIf="
										!!(newBenefit$ | async)?.networks[activeNetworkTabIndex]?.appointmentChecklist
									"
									[title]="'Checklist'"
									[description]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex]?.appointmentChecklist
									"
									preformatted="true"
								></app-service-panel-card>

								<app-service-panel-card
									[iconURL]="'/assets/icons/category/61d2998942f33e4082a17019.svg'"
									[title]="'Referral'"
									[description]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
											? 'You\'ll need a referral from your Primary Care Physician for this service'
											: ''
									"
									[labelText]="
										(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
											? 'Required'
											: 'Not Required'
									"
									[badgeSuccess]="
										!(newBenefit$ | async)?.networks[activeNetworkTabIndex].referralRequired
									"
								></app-service-panel-card>
							</app-service-panel-accordion>
							<br /><br />
						</div>
					</mat-tab-nav-panel>
				</ng-container>
				<ng-template #displayOldBenefit>
					<ng-container *ngIf="!isAdditionalService && benefit$ | async as benefit">
						<!-- header-tabs -->
						<nav
							*ngIf="benefit.networkStructure === NetworkStructure.SINGLE"
							mat-tab-nav-bar
							disableRipple
							[tabPanel]="tabPanel"
						>
							<a
								mat-tab-link
								(click)="setActiveTab(0, 'in-network')"
								[active]="activeNetworkTabIndex === 0"
							>
								<div class="service-content-tab" [class.green]="activeNetworkTabIndex === 0">
									<span></span>
									{{ 'In-Network' | translate }}
								</div>
							</a>
							<a
								mat-tab-link
								(click)="setActiveTab(1, 'out-of-network')"
								[active]="activeNetworkTabIndex === 1"
							>
								<div class="service-content-tab" [class.red]="activeNetworkTabIndex === 1">
									<span></span>
									{{ 'Out-of-Network' | translate }}
								</div>
							</a>
						</nav>
						<nav
							*ngIf="benefit.networkStructure === NetworkStructure.MN"
							mat-tab-nav-bar
							disableRipple
							[tabPanel]="tabPanel"
						>
							<a
								*ngFor="let network of benefit.additionalNetworks; let i = index"
								[active]="activeNetworkTabIndex === i"
								mat-tab-link
								(click)="setActiveTab(i, network.name.toLowerCase())"
							>
								<div class="service-content-tab" [class.green]="activeNetworkTabIndex === i">
									<span class="as-bulletin"></span>

									<ng-container *ngIf="network.name.toLowerCase() !== 'mp3'; else mp3TabTitle">
										{{ network.name }}
									</ng-container>

									<ng-template #mp3TabTitle>
										<span [appTooltipWithTouch]="mp3tooltipText">{{ network.name }}</span>
									</ng-template>
								</div>
							</a>
						</nav>
						<!-- body-tabs -->
						<mat-tab-nav-panel #tabPanel>
							<br />
							<div *ngIf="benefit.networkStructure === NetworkStructure.SINGLE" class="single-network">
								<div class="service-content-coverage green" *ngIf="activeNetworkTabIndex === 0">
									<div class="service-content-coverage-header">
										<span></span>
										<div>{{ 'In-Network' | translate }}</div>
									</div>
									<div class="service-content-coverage-body">
										<div
											*ngIf="!benefit.subjectToDeductibleIn"
											class="service-content-coverage-body-title"
										>
											{{ 'Not subject to deductible' | translate }}
										</div>
										<div
											*ngIf="benefit.subjectToDeductibleIn"
											class="service-content-coverage-body-title"
										>
											{{ 'Subject to deductible' | translate }}
										</div>
										<div class="service-content-coverage-body-subtitle">
											{{ benefit.inNetwork }}
										</div>
									</div>
								</div>
								<div class="service-content-coverage red" *ngIf="activeNetworkTabIndex === 1">
									<div class="service-content-coverage-header">
										<span></span>
										<div>{{ 'Out-of-Network' | translate }}</div>
									</div>
									<div class="service-content-coverage-body">
										<div
											*ngIf="!benefit.subjectToDeductibleOut"
											class="service-content-coverage-body-title"
										>
											{{ 'Not subject to deductible' | translate }}
										</div>
										<div
											*ngIf="benefit.subjectToDeductibleOut"
											class="service-content-coverage-body-title"
										>
											{{ 'Subject to deductible' | translate }}
										</div>
										<div class="service-content-coverage-body-subtitle">
											{{ benefit.outNetwork }}
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="benefit.networkStructure === NetworkStructure.MN" class="multi-network">
								<div *ngFor="let network of benefit.additionalNetworks; let i = index">
									<div
										*ngIf="activeNetworkTabIndex === i"
										class="service-content-coverage"
										[ngClass]="network.name.toLowerCase() === OON_MN_NAME ? 'red' : 'green'"
									>
										<div class="service-content-coverage-header">
											<span></span>
											<div *ngIf="network.name.toLowerCase() !== OON_MN_NAME">
												{{ 'In-Network' | translate }}
											</div>
											<div *ngIf="network.name.toLowerCase() === OON_MN_NAME">
												{{ 'Out-of-Network' | translate }}
											</div>
										</div>
										<div class="service-content-coverage-body">
											<div
												*ngIf="!network.subjectToDeductible"
												class="service-content-coverage-body-title"
											>
												{{ 'Not subject to deductible' | translate }}
											</div>
											<div
												*ngIf="network.subjectToDeductible"
												class="service-content-coverage-body-title"
											>
												{{ 'Subject to deductible' | translate }}
											</div>
											<div class="service-content-coverage-body-subtitle">
												{{ network.value }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="service-content-coverage green rbp-indemnity"
								*ngIf="
									benefit.networkStructure === NetworkStructure.RBP ||
									benefit.networkStructure === NetworkStructure.INDEMNITY
								"
							>
								<div class="service-content-coverage-header">
									<div *ngIf="!benefit.subjectToDeductibleIn">
										{{ 'Not subject to deductible' | translate }}
									</div>
									<div *ngIf="benefit.subjectToDeductibleIn">
										{{ 'Subject to deductible' | translate }}
									</div>
									<div class="service-content-coverage-body-subtitle">
										{{ benefit.inNetwork }}
									</div>
								</div>
								<div class="service-content-coverage-body">
									<div class="service-content-coverage-body-subtitle">
										<div *ngIf="benefit.networkStructure === NetworkStructure.RBP">
											<div>
												{{
													'Note: Not all healthcare providers may participate in an RBP
												arrangement, which could lead to balance-billing.' | translate
												}}
											</div>
										</div>
										<div *ngIf="benefit.networkStructure === NetworkStructure.INDEMNITY">
											<div>
												{{
													'Note: The plan pays for costs up to the UCR price and will refund you
												based on this amount once you file a claim.' | translate
												}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</mat-tab-nav-panel>
					</ng-container>
					<div *ngIf="(benefit$ | async)?.desc" class="service-content-info">
						<div class="service-content-info-title">
							{{ 'Important to know' | translate }}
						</div>
						<div class="service-content-info-body" [innerHTML]="(benefit$ | async)?.desc"></div>
					</div>
					<div class="service-content-info">
						<ng-container *ngIf="(benefit$ | async)?.notes?.length > 0">
							<div class="service-content-info-title for-note">
								{{ 'Notes' | translate }}
							</div>
							<div
								class="service-content-info-body for-note"
								*ngFor="let note of (benefit$ | async)?.notes"
								[innerHTML]="note | makeCDCVaccineUrlHref"
							></div>
							<div
								(click)="trackBrochureEvent()"
								class="pdf-link"
								*ngIf="(benefit$ | async)?.brochureFilePath"
							>
								<UT
									str="Full details and claims info <a href={link} target='_blank'>here</a>"
									[vars]="{ link: (benefit$ | async)?.brochureFilePath }"
								></UT>
							</div>
						</ng-container>
						<ng-container *ngIf="showDisclaimerPanel() | async">
							<div class="service-content-info-title for-note">
								{{ 'disclaimer' | translate | titlecase }}
							</div>
							<div
								class="service-content-info-body for-note"
								[innerHTML]="
									'Coverage is based on medical necessity and subject to review. Calculation of your cost
								share (co-pay and/or co-insurance) is only an estimate and may not reflect the provider
								network status, valid referrals, dollar and visit limits, and/or certain other claims
								processing procedures.'
								"
							></div>
						</ng-container>
					</div>
				</ng-template>
			</div>

			<app-service-panel-related-services
				*ngIf="userBenefitsMap$ | async"
				[services]="(benefit$ | async)?.relatedServices"
				[benefitId]="(benefit$ | async)?._id"
				[userBenefitsMap]="userBenefitsMap$ | async"
				[serviceName]="(service$ | async)?.abbreviate"
				[relatedBenefitClick]="relatedBenefitClick"
			></app-service-panel-related-services>
		</div>

		<app-service-panel-footer
			[displayFindProviders]="shouldShowProviderSearchButton() | async"
			(feedbackClick)="onFeedbackClick(0)"
			(findProviderClick)="onFindProvidersClick()"
		></app-service-panel-footer>

		<app-service-panel-eligible-zipcodes *ngIf="showMP3Disclaimer() | async"></app-service-panel-eligible-zipcodes>
	</ng-container>

	<ng-template #displayLoadingSpinner>
		<div class="service-panel-loading">
			<app-loader size="medium"></app-loader>
		</div>
	</ng-template>
</div>

<ng-template #displayOldLayout>
	<div class="service-panel">
		<div class="service-header" [class.service-header-shrink]="isHeaderShrinked$ | async">
			<div class="service-header-image">
				<div [ngStyle]="{ 'background-image': 'url(/assets/images/default-service-image-large.svg)' }"></div>
				<div
					*ngIf="!isAdditionalService; else additionalService"
					[ngStyle]="{
						'background-image': 'url(' + ((service$ | async)?.iconPath | serviceImage) + ')'
					}"
				></div>
				<ng-template #additionalService>
					<div
						[ngStyle]="{
							'background-image': 'url(' + ((service$ | async)?.iconURL | additionalBenefitImage) + ')'
						}"
					></div>
				</ng-template>
			</div>
			<div class="service-header-content">
				<div class="service-header-close-and-label">
					<button
						(click)="closeClick.emit()"
						class="service-header-content-back-button close-button"
						mat-icon-button
					>
						<mat-icon>close</mat-icon>
					</button>

					<button
						(click)="closeClick.emit()"
						class="service-header-content-back-button arrow-back"
						mat-icon-button
					>
						<mat-icon>arrow_back</mat-icon>
					</button>
				</div>

				<div class="service-header-content-subtitle">Primary Care | Preventive screening</div>
				<div class="service-header-content-title">
					{{ (service$ | async)?.abbreviate }}
				</div>
			</div>
		</div>
		<div
			class="service-content"
			[class.with-padding]="isHeaderShrinked$ | async"
			(scroll)="contentScrollPos$.next($any($event?.srcElement)?.scrollTop)"
		>
			<ng-container *ngIf="!isAdditionalService">
				<br />
				<div class="service-content-title">
					<div class="service-content-title-text">{{ 'Coverage' | translate }}</div>
				</div>
				<div class="service-content-coverage green">
					<div class="service-content-coverage-header">
						<span></span>
						<div>{{ 'In-Network' | translate }}</div>
					</div>
					<div class="service-content-coverage-body">
						<div
							*ngIf="!(benefit$ | async)?.subjectToDeductibleIn"
							class="service-content-coverage-body-title"
						>
							{{ 'Not subject to deductible' | translate }}
						</div>
						<div
							*ngIf="(benefit$ | async)?.subjectToDeductibleIn"
							class="service-content-coverage-body-title"
						>
							{{ 'Subject to deductible' | translate }}
						</div>
						<div class="service-content-coverage-body-subtitle">
							{{ (benefit$ | async)?.inNetwork }}
						</div>
					</div>
				</div>

				<div class="service-content-coverage red">
					<div class="service-content-coverage-header">
						<span></span>
						<div>{{ 'Out-of-Network' | translate }}</div>
					</div>
					<div class="service-content-coverage-body">
						<div
							*ngIf="!(benefit$ | async)?.subjectToDeductibleOut"
							class="service-content-coverage-body-title"
						>
							{{ 'Not subject to deductible' | translate }}
						</div>
						<div
							*ngIf="(benefit$ | async)?.subjectToDeductibleOut"
							class="service-content-coverage-body-title"
						>
							{{ 'Subject to deductible' | translate }}
						</div>
						<div class="service-content-coverage-body-subtitle">
							{{ (benefit$ | async)?.outNetwork }}
						</div>
					</div>
				</div>
			</ng-container>

			<div *ngIf="(benefit$ | async)?.desc" class="service-content-info">
				<div class="service-content-info-title">
					{{ 'Important to know' | translate }}
				</div>
				<div class="service-content-info-body" [innerHTML]="(benefit$ | async)?.desc"></div>
			</div>
			<div class="service-content-info">
				<ng-container *ngIf="(benefit$ | async)?.notes?.length > 0">
					<div class="service-content-info-title for-note">
						{{ 'Notes' | translate }}
					</div>
					<div
						class="service-content-info-body for-note"
						*ngFor="let note of (benefit$ | async)?.notes"
						[innerHTML]="note | makeCDCVaccineUrlHref"
					></div>
					<div (click)="trackBrochureEvent()" class="pdf-link" *ngIf="(benefit$ | async)?.brochureFilePath">
						<UT
							str="Full details and claims info <a href={link} target='_blank'>here</a>"
							[vars]="{ link: (benefit$ | async)?.brochureFilePath }"
						></UT>
					</div>
				</ng-container>
				<ng-container *ngIf="showDisclaimerPanel() | async">
					<div class="service-content-info-title for-note">
						{{ 'disclaimer' | translate | titlecase }}
					</div>
					<div
						class="service-content-info-body for-note"
						[innerHTML]="
							'Coverage is based on medical necessity and subject to review. Calculation of your cost
						share (co-pay and/or co-insurance) is only an estimate and may not reflect the provider
						network status, valid referrals, dollar and visit limits, and/or certain other claims
						processing procedures.'
						"
					></div>
				</ng-container>
			</div>

			<div class="find-providers-button-wrapper" *ngIf="shouldShowProviderSearchButton() | async">
				<button (click)="onFindProvidersClick()" class="find-providers-button">
					{{ 'Find Providers' | translate }}
					<mat-icon>arrow_right_alt</mat-icon>
				</button>
			</div>

			<div *ngIf="(benefit$ | async)?.relatedServices?.length > 0" class="service-content-benefits">
				<div class="service-content-benefits-title">{{ 'Related Benefits' | translate }}</div>

				<div class="service-content-benefits-wrapper">
					<div
						class="related-benefit"
						(click)="relatedBenefitClick.emit(service._id)"
						[appClientClickEventTracker]="{
							event: 'Coverage Info Related Benefits click',
							metaData: {
								'service name': (service$ | async)?.abbreviate,
								relatedServiceName: service.abbreviate
							}
						}"
						*ngFor="let service of (benefit$ | async)?.relatedServices; let i = index"
					>
						<img class="related-benefit-image" [src]="service.iconPath | serviceImage : i" />
						<div class="related-benefit-content">
							<span class="related-benefit-title">{{ service.abbreviate }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #mp3tooltipText>
	<p>Los Angeles and Ventura counties</p>
</ng-template>
