import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CFG from '../../../config/app-config.json';
import { PlanData } from '../../../models/plan-data.model';
import { AllBenefitsResponse } from './helpers/all-benefits.response';
import { RegisterWithTokenResponse } from './helpers/register-with-token.response';
import { MeUser } from '../domain/me-user';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { UpdateContractDataParams } from './helpers/update-contract-data.params';
import { MeUserStoreToken } from '../../stores/me-user-store/me-user-store.token';
import { Observable } from 'rxjs';
import { AdditionalBenefit } from '../domain/additional-benefit';
import { UserData } from '../../../models/user-data';
import { PreventiveCareStoreToken } from '../../stores/preventive-care-store/preventive-care-store.token';
import { DeductiblesStoreToken } from '../../stores/deductibles-store/deductibles-store.token';

@Injectable({
	providedIn: 'root',
})
export class UserApiService extends StoreApi {
	constructor(private httpClient: HttpClient, private storeUpdateRegistryService: StoreUpdateRegistryService) {
		super(storeUpdateRegistryService);
	}

	additionalBenefits() {
		return this.httpClient.get<AdditionalBenefit[]>(CFG.apiEndpoints.userAPIs.additionalBenefits);
	}

	userPlans() {
		return this.httpClient.get<PlanData>(CFG.apiEndpoints.mpiUserPlans + '?includeEsp=true');
	}

	mp3userPlans() {
		return this.httpClient.get<PlanData>(CFG.apiEndpoints.mp3userPlans);
	}

	allBenefits() {
		return this.httpClient.get<AllBenefitsResponse>(`/user/allBenefitsWithCarrier`);
	}

	registerWithToken(token: string): Observable<RegisterWithTokenResponse> {
		return this.httpClient.get<RegisterWithTokenResponse>(`/user/${token}/register-with-token`);
	}

	me() {
		return this.httpClient.get<MeUser>(`/v2/user/me`);
	}

	updateContractData(params: UpdateContractDataParams) {
		return this.httpClient
			.post(`/v2/mpi/user/contract-data`, params)
			.pipe(this.withStoreUpdate(MeUserStoreToken, undefined))
			.pipe(this.withStoreUpdate(DeductiblesStoreToken, undefined));
	}

	dismissHomePageHealthCardTeaser() {
		return this.httpClient
			.post(`/v2/user/dismiss-health-card-home-page-teaser`, null, {
				responseType: 'text',
			})
			.pipe(this.withStoreUpdate(MeUserStoreToken, undefined));
	}

	updateProfile(formData) {
		return this.httpClient
			.post<UserData>(CFG.apiEndpoints.userProfileData, formData)
			.pipe(this.withStoreUpdate(PreventiveCareStoreToken, undefined));
	}
}
