import { Component, Input, ElementRef, OnChanges, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import * as rippleIcons from './icons';
import { rippleIconNames } from './icons';

@Component({
	selector: 'ripple-icon',
	templateUrl: './icons.component.html',
	styleUrls: ['./icons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class IconsComponent implements OnChanges {
	@Input() size: ICON_SIZE = ICON_SIZE.size24;
	@Input() color?: ICON_COLOR;
	@Input() hasClickEvent: boolean = false;

	private _isSkeletonLoader: boolean = false;
	@Input()
	set isSkeletonLoader(value: boolean) {
		this._isSkeletonLoader = value;
		this.applyStyles();
	}

	get isSkeletonLoader(): boolean {
		return this._isSkeletonLoader;
	}

	@Input()
	set name(iconName: rippleIconNames) {
		this.element.nativeElement.innerHTML = rippleIcons.default[iconName] || null;
	}

	constructor(private element: ElementRef) {
		this.applyStyles();
	}

	ngOnChanges() {
		this.applyStyles();
	}

	private applyStyles() {
		const { nativeElement } = this.element;
		nativeElement.className = '';
		nativeElement.classList.add(this.size);

		if (this.color) {
			nativeElement.classList.add(this.color);
		}
		if (this.hasClickEvent) {
			nativeElement.classList.add('clickable');
		}
		if (this.isSkeletonLoader) {
			nativeElement.classList.add('skeleton-loader');
		}
	}
}

export enum ICON_SIZE {
	'size16' = 'size-16',
	'size20' = 'size-20',
	'size24' = 'size-24',
	'size32' = 'size-32',
	'size40' = 'size-40',
}

export enum ICON_COLOR {
	'default' = 'default',
	'blue' = 'blue',
	'blue-60' = 'blue-60',
	'white' = 'white',
	'red' = 'red',
	'text-secondary' = 'text-secondary',
	'green' = 'green',
}
