import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from '../../../utils/store/store';
import { FacilitiesApiService } from '../../api/providers-api/facilities-api.service';
import { ProvidersApiService } from '../../api/providers-api/providers-api.service';
import { RxjsUtils } from '../../../utils/rxjs';
import { SearchResponse } from '../../api/providers-api/helpers/providers-api.interface';
import {
	SearchOptions,
	SearchEntity,
	SearchPage,
} from '../../../modules/main-layout/care-costs/helpers/providers-search.helper';
import { MixSearchService } from '../../providers-search/mix-search.service';

@Injectable({
	providedIn: 'root',
})
export class SearchResultsStoreService extends Store<SearchOptions, SearchResponse<any>> {
	constructor(
		private facilitiesApiService: FacilitiesApiService,
		private providersApiService: ProvidersApiService,
		private mixSearchService: MixSearchService,
	) {
		super();
	}

	protected retrieve(context: SearchOptions): Observable<SearchResponse<any>> {
		switch (context.entity) {
			case SearchEntity.Facility:
				return this.facilitiesApiService.facilitiesPaginated(context.params);
			case SearchEntity.Provider:
			case SearchEntity.Treatment:
			case SearchEntity.Condition:
			case SearchEntity.SearchByGroupName:
				return this.providersApiService.providersPaginated(context.params);
			case SearchEntity.SearchByNeed:
				return this.mixSearchService.getMixProvidersAndFacilities(context.params);
		}
	}

	getNormalized(context: SearchOptions, normalizeMethod: (data) => any): Observable<SearchPage<any>> {
		return this.get(context).pipe(
			RxjsUtils.isNotNil(),
			map(({ data, parameters, isInNetwork, networkName, networkStructure }) => {
				const records = data.map((record) => ({ ...normalizeMethod(record), isInNetwork }));
				return {
					records,
					page: parameters.page,
					pageSize: parameters.page_size,
					totalCount: parameters.total_count,
					isInNetwork,
					entity: context.entity,
					isLastPage: parameters.isLastPage,
					networkName,
					networkStructure
				};
			})
		);
	}
}
