import { Component, OnInit } from '@angular/core';
import { BannerCard } from '../../standalone/banner-card/banner-card.component';
import { TelehealthService } from '../../telehealth/services/telehealth.service';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { talonBannerCard, telehealthBannerCard } from './non-eligible-layout.config';
import { TrackingService } from 'src/app/services/tracking.service';


@Component({
	selector: 'app-non-eligible-layout',
	templateUrl: './non-eligible-layout.component.html',
	styleUrls: ['./non-eligible-layout.component.scss'],
})
export class NonEligibleLayoutComponent implements OnInit {
	public telehealthBannerCard: BannerCard = telehealthBannerCard;
	public talonBannerCard: BannerCard = talonBannerCard;
	public cards: BannerCard[] = [];

	constructor(
		private telehealthService: TelehealthService,
		private appManagerService: AppManagerService,
		private trackingService: TrackingService,
	) {}

	ngOnInit(): void {
		this.setDisplayCards();
	}

	setDisplayCards() {
		this.talonBannerCard.action = () => {
			this.appManagerService.openTalonModal();
			this.trackingService.trackClientEvent('Talon modal started', {Source: 'Talon teaser'});
		};
		this.telehealthBannerCard.action = () => {
			this.telehealthService.onOpenTelehealth();
			this.trackingService.trackClientEvent('Telehealth modal started', {Source: 'Telehealth teaser'});
		};

		this.cards.push(this.talonBannerCard);
		this.cards.push(this.telehealthBannerCard);
	}
}
