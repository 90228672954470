import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-toggle-menu-item',
	templateUrl: './toggle-menu-item.component.html',
	styleUrls: ['./toggle-menu-item.component.scss'],
})
export class ToggleMenuItemComponent implements OnInit {
	@Input() title: string = '';
	@Input() description: string = '';
	@Input() type: 'radio' = 'radio';
	@Input() disabled: boolean = false;
	@Input() state: boolean;

	@Output() itemClicked = new EventEmitter();
	@Output() toggleClicked = new EventEmitter();
	@Output() valueChanged = new EventEmitter<boolean>();

	constructor() {}

	ngOnInit(): void {}

	public onItemClicked() {
		this.itemClicked.emit();
	}
	public onToggleClicked() {
		this.toggleClicked.emit();
	}

	public onStateChange(event: Event) {
		const isChosen = event as unknown as boolean;
		this.valueChanged.emit(isChosen);
	}
}
