<div
	class="container"
	[ngClass]="{ 'full-height': data.fullHeight, 'no-padding': data.noPadding, 'full-width': data.fullWidth }"
>
	<span
		*ngIf="data.hasCloseButton"
		(click)="close()"
		class="close-btn material-icons-outlined cypress-testing-selector-close-dialog"
		mat-dialog-close
	>
		<img src="../../../assets/images/icons/x-close-dialog.svg" />
	</span>

	<div *ngIf="data.icon" class="icon-dialog">
		<img [src]="'/assets/images/icons/' + data.icon" alt="" />
	</div>
	<div class="header-wrapper" *ngIf="!data.componentOnly">
		<h1 class="title">{{ data.title }}</h1>
	</div>

	<mat-divider *ngIf="!data.componentOnly"></mat-divider>

	<div
		mat-dialog-content
		class="dialog-content"
		[ngClass]="{
			'full-height': data.fullHeight,
			'has-title': !data.componentOnly,
			'no-padding': data.noPadding,
			'has-action-btns': !data.componentOnly && (data?.actionsTemplateRef || data.cancelText || data.confirmText)
		}"
	>
		<ng-template dynamicCmp></ng-template>
		<ng-container [ngTemplateOutlet]="data?.templateRef"></ng-container>
		<p *ngIf="!data.componentOnly" class="dialog-message">{{ data.message }}</p>
	</div>

	<div mat-dialog-actions class="action-buttons" *ngIf="!data.componentOnly">
		<ng-container
			[ngTemplateOutlet]="data?.actionsTemplateRef"
			[ngTemplateOutletContext]="{ close: close }"
		></ng-container>
		<button
			*ngIf="data.cancelText"
			class="healthee-btn sm tertiary"
			style="margin-right: 10px"
			(click)="onCancel()"
		>
			{{ data.cancelText }}
		</button>
		<button
			*ngIf="data.confirmText"
			class="healthee-btn sm primary cypress-testing-selector-confirm"
			(click)="confirm()"
		>
			{{ data.confirmText }}
		</button>
	</div>
</div>
