<ng-container *ngIf="{ info: ionicAppInfo$ | async } as ionicApp">
	<span class="app-verion-container" (click)="countClicksOnVersionCaption()">
		<span *ngIf="ionicApp.info && showBundleId">{{ ionicApp.info.id }} </span>
		<span *ngIf="ionicApp.info">v{{ ionicApp.info.version }} ({{ ionicApp.info.build }}) - </span>
		<span *ngIf="!ionicApp.info">v</span><span>{{ webAppVersion }} </span>

		<ng-container *ngIf="isDevelopmentOrLocalEnv || forceProductionEnvInfo">
			<ion-badge color="{{ isDevelopmentOrLocalEnv ? 'primary' : 'danger' }}">
				{{ env.envName }}
			</ion-badge>

			<span>
				<br />
				host: {{ hostUrl }}
			</span>
		</ng-container>

		<span *ngIf="apiVersion">
			<br />
			API: {{ apiVersion }}
		</span>
	</span>

	<app-mobile-developer-menu
		[appInfo]="ionicApp.info"
		[environment]="env"
		[host]="hostUrl"
		[isOpen]="showDeveloperMenu"
		(dismissed)="dismissDeveloperMenu()"
		(dismissedAndDeactivated)="lockDeveloperMode()"
	></app-mobile-developer-menu>
</ng-container>
