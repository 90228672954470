import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import CFG from '../../../config/app-config.json';
import { ConversationsParams } from './helpers/conversations.params';
import { Maybe } from '../../../utils/types/maybe';
import { ConversationsResponse } from './helpers/conversations.response';

@Injectable({
	providedIn: 'root',
})
export class ConversationsApiService {
	constructor(private httpClient: HttpClient) {}

	conversations(params: Maybe<ConversationsParams> = {}) {
		return this.httpClient.get<ConversationsResponse[]>(CFG.apiEndpoints.conversationsAPIs.conversations, {
			params: { ...params },
		});
	}
}
