<div
	*ngIf="{ isLoading: isLoading$ | async } as asyncData"
	class="address-bar-dialog-container"
	[ngClass]="{ 'additional-top-padding': dataIsAvailable || asyncData.isLoading }"
>
	<span class="close-modal-btn" (click)="onCloseModalClicked()">
		<img src="../../../../../../../assets/images/icons/close-btn-round-black.svg" alt="close" />
	</span>

	<div class="google-places-wrapper">
		<app-google-places-autocomplete
			[enabled]="true"
			(addressChanged)="onAddressChanged($event)"
			(searchCleared)="onSearchCleared()"
		></app-google-places-autocomplete>
	</div>

	<mat-divider class="mat-divider"></mat-divider>

	<div *ngIf="asyncData.isLoading" class="loading-indicator-wrapper">
		<app-loader size="medium"></app-loader>
	</div>

	<div *ngIf="dataIsAvailable" class="providers-results">
		<div *ngIf="ribbonProviders">
			<UT
				str="There are <strong>{providers_length}</strong> providers in
			your area!"
				[vars]="{ providers_length: getProvidersLengthDisplay() }"
			></UT>
		</div>

		<div *ngIf="!ribbonProviders">
			{{ 'No providers found. Please try modifying the search.' | translate }}
		</div>

		<button class="result-btn bt-gradient active" (click)="onShowResultsClicked()" [disabled]="!ribbonProviders">
			<UT str="Show Providers" key="show_providers"></UT>
		</button>
	</div>
</div>
