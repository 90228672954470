import { Contract } from 'src/app/models/contract.model';
import { toTitleCase } from '../utils';
import { PlanType } from 'src/app/models/plan-type.model';
import { MpiPlan } from './mpi.constant';

export const getCategoryTitleFromContract = (item?: Contract): string => {
	if (!item) return '';
	const planType = item.employerSponsored ? 'Employer Sponsored' : item.planType;
	return getServiceWithPlanTypeOrEmployerSponsored(item.insuranceCompany?.name, planType);
};

export const getEmployerSponsoredCarrierName = (name?: string): string => {
	const planType = 'Employer Sponsored';
	return getServiceWithPlanTypeOrEmployerSponsored(name, planType);
};

export const getServiceWithPlanTypeOrEmployerSponsored = (
	carrierName: string | undefined,
	planType: PlanType | 'Employer Sponsored'
): string => {
	if (!carrierName) return '';
	const name = toTitleCase(carrierName);
	switch (name.toLowerCase()) {
		case 'cvs/caremark':
			return 'CVS Caremark - Pharmacy';
		case 'vsp':
			return 'VSP - Vision';
		case 'optum':
			return 'Optum - Behavioral Health';
		default:
			return `${name} - ${planType === 'Employer Sponsored' ? 'Employer Sponsored' : toTitleCase(planType)}`;
	}
};

export const getPlanTitleEmployerSponsoredForMyProfile = (carrierName: string | undefined): string => {
	switch (carrierName?.toLowerCase()) {
		case 'cvs/caremark':
			return 'My Pharmacy Plan Info';
		case 'optum':
			return 'My Behavioral Health Info';
		default:
			return `Employer Sponsored Plan Info`;
	}
};

export const getProviderSearchTitleByPlanName = (mpiPlan: MpiPlan): string => {
	let planName = '';
	switch (mpiPlan) {
		case MpiPlan.Anthem:
			planName = 'Anthem Blue Cross';
			break;
		case MpiPlan.HealthNet:
			planName = 'Health Net HMO';
			break;
		case MpiPlan.Kaiser:
			planName = 'Kaiser Permanente HMO';
			break;
		case MpiPlan.Oxford:
			planName = 'Oxford Health Plan POS';
			break;
	}

	return `${planName} Provider Search`;
};
