<form class="autocomplete-select-container">
	<img [src]="imgSrc" class="autocomplete-img" alt="" />
	<mat-form-field class="autocomplete-form-field" (click)="openAutocomplete()">
		<input
			matInput
			#autoTrigger="matAutocompleteTrigger"
			type="text"
			placeholder="{{ 'Specialist' | translate }}"
			class="fs-unmask"
			[matAutocomplete]="auto"
			[formControl]="searchCtrl"
			(keydown)="clearSelectedElement()"
		/>

		<mat-autocomplete [panelWidth]="'15rem'" #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
			<mat-option *ngFor="let option of filteredOptions$ | async" [value]="option"
				>{{ option | titlecase }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<img
		(click)="openAutocomplete()"
		class="select-arrow"
		src="../../../../assets/images/icons/ribbon/dropDown_arrow.svg"
		alt=""
	/>
</form>
