import { AppError } from '../../error-handlers/app-errors';

export class GeolocationError extends AppError {
	constructor(
		public message: string,
		public originalError?: Error
	) {
		super(message);
		Object.setPrototypeOf(this, GeolocationError.prototype);
	}
}

export class GeolocationPermissionsDeniedOnWebError extends GeolocationError {
	constructor(public message: string = 'geolocation permissions denied on web') {
		super(message);
		Object.setPrototypeOf(this, GeolocationPermissionsDeniedOnWebError.prototype);
	}
}

export class GeolocationPermissionsDeniedOnAndroidError extends GeolocationError {
	constructor(public message: string = 'geolocation permissions denied on Android') {
		super(message);
		Object.setPrototypeOf(this, GeolocationPermissionsDeniedOnAndroidError.prototype);
	}
}

export class GeolocationPermissionsDeniedOniOSError extends GeolocationError {
	constructor(public message: string = 'geolocation permissions denied on iOS') {
		super(message);
		Object.setPrototypeOf(this, GeolocationPermissionsDeniedOniOSError.prototype);
	}
}


export class GeolocationAccessDeniedTemporarilyError extends GeolocationError {
	constructor(public message: string = 'geolocation access temporarily denied on android') {
		super(message);
		Object.setPrototypeOf(this, GeolocationAccessDeniedTemporarilyError.prototype);
	}
}

export class GeolocationServiceNotAvailableError extends GeolocationError {
	constructor(public message: string = 'geolocation service not available') {
		super(message);
		Object.setPrototypeOf(this, GeolocationServiceNotAvailableError.prototype);
	}
}

export class GeolocationTimeoutError extends GeolocationError {
	constructor(public message: string = 'geolocation service timed out') {
		super(message);
		Object.setPrototypeOf(this, GeolocationTimeoutError.prototype);
	}
}

export class GeolocationUnknownError extends GeolocationError {
	constructor(
		public message: string = 'unknown geolocation error',
		public originalError?: Error
	) {
		super(message);
		Object.setPrototypeOf(this, GeolocationUnknownError.prototype);
	}
}
