import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { PlanClassType } from '../../../models/domain/plan-class-type';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';

@Pipe({
	name: 'planClassType',
})
export class PlanClassTypePipe implements PipeTransform {
	constructor(private userPlanDataStoreService: UserPlanDataStoreService) {}

	transform(planType: string): Observable<Maybe<PlanClassType>> {
		return this.userPlanDataStoreService.planClassType(planType);
	}
}
