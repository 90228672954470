import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import CFG from '../config/app-config.json';
import { resolveHost } from '../utils/utils';

import { BenefitSummaryModel } from '../models/benefit-summary.model';

const EMPTY_FILTER_TEXT = '';
@Injectable({ providedIn: 'root' })
export class BenefitsService {
	private _benefitSummary$: BehaviorSubject<BenefitSummaryModel[]> = new BehaviorSubject<BenefitSummaryModel[]>(null);
	private _selectedBenefit$: BehaviorSubject<BenefitSummaryModel> = new BehaviorSubject<BenefitSummaryModel>(null);
	private _benefitFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(EMPTY_FILTER_TEXT);

	private server = resolveHost();

	constructor(private http: HttpClient) {}

	get benefitSummary$() {
		return this._benefitSummary$.asObservable();
	}
	get selectedBenefit$() {
		return this._selectedBenefit$.asObservable();
	}
	get benefitFilter$() {
		return this._benefitFilter$.asObservable();
	}

	public fetchBenefitsSummary() {
		return this.http
			.get<BenefitSummaryModel[]>(CFG.apiEndpoints.benefitsSummary)
			.subscribe((benefits) => this.saveBenefitsArrayAndAddLinks(benefits));
	}

	private saveBenefitsArrayAndAddLinks(benefits: BenefitSummaryModel[]): BenefitSummaryModel[] {
		if (!benefits) return;

		benefits.map((benefit, indexInArray) => {
			benefit.indexInList = indexInArray;
			benefit.companyLink = this.server + '/' + benefit.companyLink;
			benefit.insuranceLink = this.server + '/' + benefit.insuranceLink;
			return benefit;
		});

		this.updateBenefitsSummary(benefits);
	}

	private updateBenefitsSummary(benefitsArray: BenefitSummaryModel[]) {
		this._benefitSummary$.next(benefitsArray);
	}

	public updateSelectedBenefit(benefit: BenefitSummaryModel) {
		this._selectedBenefit$.next(benefit);
	}

	public updateBenefitsFilter(partialTitle: string) {
		this._benefitFilter$.next(partialTitle);
	}

	public setMnBenefitsNetworkPriority(contractNetworks, benefitNetworks) {
		const indexMap = contractNetworks.reduce((acc, obj, index) => {
			acc[obj] = index;
			return acc;
		}, {});

		const sortedArrayB = [...benefitNetworks].sort((a, b) => {
			const aIndex = indexMap[a.networkLink];
			const bIndex = indexMap[b.networkLink];
			return aIndex - bIndex;
		});

		return sortedArrayB;
	}
}
