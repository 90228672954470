import { Injectable } from '@angular/core';
import { BaseRibbonEntitiesStoreService } from './base-ribbon-entities-store.service';
import { RibbonHealthService } from '../../ribbon.service';

@Injectable({
	providedIn: 'root',
})
export class RibbonEntitiesStoreService extends BaseRibbonEntitiesStoreService {
	constructor(ribbonHealthService: RibbonHealthService) {
		super(ribbonHealthService);
	}
}
