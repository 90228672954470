import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { ExternalApiService, MondayTaskType } from '../../external-api.service';

const AUTOCOMPLETE_API = '/api/search-providers/autocomplete';
const DEFAULT_SERVICES = [
	'Mental Health Disorders',
];

export interface AutocompleteData {
	providers: [];
	facilities: [];
}

@Injectable({
	providedIn: 'root',
})
export class AutocompleteApiService extends StoreApi {
	constructor(
		private httpClient: HttpClient,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private externalApiService: ExternalApiService,
	) {
		super(storeUpdateRegistryService);
	}

	search(name: string, pageSize: number, address?: string, providedServices: string[] = []): Observable<AutocompleteData> {
		let params = new HttpParams({
			fromObject: {
				name,
				pageSize
			}
		});

		if (address) {
			params = params.set('address', address);
		}

		const services = [...DEFAULT_SERVICES, ...providedServices];

		return this.httpClient.get<AutocompleteData>(AUTOCOMPLETE_API, { params }).pipe(
			tap(result => {
				const isLikelyServiceName = services.some(service => {
					const lcService = service.toLowerCase();
					const lcName = name.toLowerCase();

					return lcService.startsWith(lcName) || lcName.startsWith(lcService);
				});

				if (!isLikelyServiceName && result.providers.length === 0 && result.facilities.length === 0) {
					this.externalApiService.handleExternalApiForNotFoundSearch(
						address ? `${name} at ${address}` : name,
						MondayTaskType.ProvidersSearch,
						'User\'s provider couldn\'t be found',
					);
				}
			}),
		);
	}
}
