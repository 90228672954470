import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, map, Observable, Subject, tap, } from 'rxjs';
import { StorageService } from './storage.service';
import { UserOpenEnrollmentData } from '../models/open-enrollment.model';
import { RibbonHealthService } from './ribbon.service';
import { PlanSelectionStoreService, } from './stores/plan-selection-store/plan-selection-store.service';
import { PlanSelectionAPIService } from './api/plan-selection-api/plan-selection-api.service';
import { LoggerService } from './logger.service';
import { RxjsUtils } from '../utils/rxjs';

export interface PlansObject {
	medical: any[];
	dental: any[];
	vision: any[];
	pharmacy: any[];
}

export interface PlansIdsObject {
	medical?: string[];
	dental?: string[];
	vision?: string[];
	pharmacy?: string[];
}

export enum MedicalEvent {
	PCP = 'pcp',
	SPECIALIST = 'specialist',
	SURGERY = 'surgery',
	INPATIENT_SURGERY = 'inpatient-surgery',
	OUTPATIENT_SURGERY = 'outpatient-surgery',
	PREGNANCY = 'pregnancy',
	ER = 'er',
	OTHER = 'other',
	DENTIST = 'dentist',
	OPTOMETRIST = 'optometrist',
}

export interface UserEnrollmentData {
	status?: string;
	pinnedPlanIds?: PlansIdsObject;
}

export interface Incentive {
	title: string;
	description: string;
}

export interface OpenEnrollmentData {
	educationCenterLink: string;
	customText: {
		enrollmentCompletion: string;
		benAdminName: string;
	};
	isZipCodeLocked?: boolean;
	incentives?: Incentive[];
	plans: PlansObject;
}

export interface Step {
	currentIndex: number;
	lastIndex?: number;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionService {
	public userEnrollmentData: UserEnrollmentData = {};

	constructor(
		private logger: LoggerService,
		private http: HttpClient,
		private storageService: StorageService,
		private planSelectionStore: PlanSelectionStoreService,
		private ribbonHealthService: RibbonHealthService,
		private planSelectionAPIService: PlanSelectionAPIService,
	) {
	}

	private _pinnedPlans$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private _step$: BehaviorSubject<Step> = new BehaviorSubject<Step>(null);
	private _resetData$: Subject<void> = new Subject();

	get pinnedPlans$(): Observable<any> {
		return this._pinnedPlans$.asObservable();
	}

	get resetData$(): Observable<void> {
		return this._resetData$.asObservable();
	}

	public getMedicalEvents(medicalEventsStrings: string[]): { serviceType: MedicalEvent }[] {
		if (!medicalEventsStrings?.length) return [];
		const eventMapping: { [key: string]: MedicalEvent } = {
			Pregnancy: MedicalEvent.PREGNANCY,
			'ER Visit': MedicalEvent.ER,
			'Surgery requiring hospital stay (inpatient)': MedicalEvent.INPATIENT_SURGERY,
			'Surgery not requiring hospital stay (outpatient)': MedicalEvent.OUTPATIENT_SURGERY,
		};

		return medicalEventsStrings
			.filter((eventString) => !!eventMapping[eventString])
			.map((eventString) => {
				return {
					serviceType: eventMapping[eventString],
				};
			});
	}

	get userEnrollmentData$(): Observable<UserEnrollmentData> {
		return this.mapPlanSelectionToUserEnrollmentData();
	}

	public mapPlanSelectionToUserEnrollmentData(): Observable<UserEnrollmentData> {
		return this.planSelectionStore.get().pipe(
			RxjsUtils.isNotNil(),
			map((planSelectionData) => {
				const userData = planSelectionData?.userData?.userData;
				const status = userData.status;

				return {
					status,
					pinnedPlanIds: userData?.pinnedPlanIds ?? {},
				};
			}),
			tap((userEnrollmentData) => {
				this.userEnrollmentData = userEnrollmentData;
			})
		);
	}

	public setPinnedPlanIds(pinnedPlanIds: PlansIdsObject): void {
		this._pinnedPlans$.next(pinnedPlanIds);
	}

	get step$(): Observable<Step> {
		return this._step$.asObservable();
	}

	public updateUserOpenEnrollmentData$(status?: string) {
		const data = this.getSavedData() || {};
		const serverData: Partial<UserOpenEnrollmentData> = {
			userData: {
				status: status ?? data.status ?? 'started',
				pinnedPlanIds: data.pinnedPlanIds ?? {},
			},
		};

		return this.planSelectionAPIService.updateServer(serverData);
	}

	public updateUserOpenEnrollmentData(status?: string) {
		this.updateUserOpenEnrollmentData$(status).subscribe();
	}

	public updateLocalData(data: Partial<UserEnrollmentData>): void {
		let currentData = this.getSavedData();
		currentData = { ...currentData, ...data };

		this.userEnrollmentData = currentData;
	}

	public getSavedData(): UserEnrollmentData {
		return this.userEnrollmentData;
	}
}
