import { getPlatformId, useIonicFile } from '../../../utils/utils';
import { Component, ContentChildren, QueryList, Output, Input, EventEmitter, OnInit } from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Component({
	selector: 'app-tabs-container',
	templateUrl: './tabs-container.component.html',
	styleUrls: [useIonicFile('./tabs-container.component.scss', './tabs-container.component.ionic.scss')],
})
export class TabsContainerComponent implements OnInit {
	@ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
	@Input() activeTabLink: string;
	@Output() setActiveTabLink = new EventEmitter<string>();

	public platform = getPlatformId();

	constructor() {}

	ngOnInit() {
		setTimeout(() => {
			this.tabs.toArray().find((tab) => tab.link === this.activeTabLink).isActive = true;
		}, 0);
	}

	selectTabFromTemplate(event: Event, tab: TabComponent) {
		event.preventDefault();
		this.selectTab(tab);
	}

	private selectTab(tab: TabComponent) {
		this.deselectAllTabs();
		this.activateTab(tab);
	}

	private activateTab(tab: TabComponent) {
		this.setActiveTabLink.next(tab.link);
		tab.isActive = true;
	}

	private deselectAllTabs() {
		this.tabs.toArray().forEach((tab) => (tab.isActive = false));
	}
}
