import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-add-dependents-dialog',
	templateUrl: './add-dependents-dialog.component.html',
	styleUrls: ['./add-dependents-dialog.component.scss'],
})
export class AddDependentsDialogComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
