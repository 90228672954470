import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Maybe } from '../../../utils/types/maybe';
import { isIonic } from '../../../utils/utils';
import {
	BehaviorSubject,
	map,
	Observable,
	of,
	shareReplay,
	switchMap,
	combineLatest,
	take,
	tap,
	Subscription,
} from 'rxjs';
import { CommonComponent } from '../../../utils/components/common-component';
import { InputOf } from '../../../utils/input-reflector/input-of';
import { AllServicesStoreService } from '../../../services/stores/all-services-store/all-services-store.service';
import { ServicesResponse } from '../../../services/api/employee-api/helpers/services.response';
import { isNil } from '../../../utils/is/is-nil';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { Benefit, CountLimitation, Network, NewBenefit } from '../../../models/benefits.model';
import { BenefitStoreService } from '../../../services/stores/benefit-store/benefit-store.service';
import { NewBenefitStoreService } from '../../../services/stores/new-benefit-store/new-benefit-store.service';
import { AdditionalBenefitsStoreService } from '../../../services/stores/additional-benefits-store/additional-benefits-store.service';
import { TrackingService } from '../../../services/tracking.service';
import { RxjsUtils } from '../../../utils/rxjs';
import { RibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { HomePageService } from 'src/app/modules/home-page/services/home-page.service';
import { UnleashService } from 'src/app/services/unleash.service';
import { HealtheeDialogData } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { HealtheeDialogService } from '../../../services/healthee-dialog.service';
import { FeedbackDialogComponent } from 'src/app/modules/standalone/feedback/feedback-dialog/feedback-dialog.component';
import { LabelColorTheme } from 'ripple';
import { FfNewCoverageInfoRestructure } from 'src/app/config/feature-flags/ff-new-coverage-info-layout';
import { MedicalConditionsStoreService } from 'src/app/services/stores/medical-conditions-store/medical-conditions-store.service';
import { MedicalConditionsResponse } from 'src/app/services/api/employee-api/helpers/medical-conditions.response';
import { ExternalApiService } from 'src/app/services/external-api.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { FeedbackLocalize } from 'src/app/modules/standalone/feedback/feedback-localize';
import { BenefitsService } from 'src/app/services/benefits.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
	showAppointmentChecklist,
	showDisclaimerPanelCard,
	showMP3Disclaimer,
	showProviderSearchButton,
} from 'src/app/utils/mpi/mpi.constant';

export enum BenefitType {
	OLD = 'old',
	NEW = 'new',
}

@Component({
	selector: 'app-service-panel',
	templateUrl: './service-panel.component.html',
	styleUrls: ['./service-panel.component.scss'],
})
export class ServicePanelComponent extends CommonComponent implements OnInit {
	@HostBinding('class.is-ionic')
	isIonic = isIonic();

	@Input()
	serviceId: Maybe<string>;

	@Input()
	isAdditionalService: Maybe<boolean> = false;

	@Input()
	hideFindProvidersButton: boolean = false;

	@Input() feature: string;

	@Input() data: object;

	@Output()
	closeClick = new EventEmitter<void>();

	@Output()
	relatedBenefitClick = new EventEmitter<string>();

	@Output()
	findProviderClick = new EventEmitter<void>();

	contentScrollPos$ = new BehaviorSubject<number>(0);

	isHeaderShrinked$: Observable<boolean>;

	service$: Observable<Maybe<ServicesResponse>>;

	userBenefitsMap$: Observable<Map<string, any>>;

	benefit$: Observable<Maybe<Benefit & { brochureFilePath?: string }>>;

	newBenefit$: Observable<Maybe<NewBenefit | any>>;

	activeNetworkTabIndex = 0;
	activeNetworkTabName = '';

	activeBenefit: BenefitType;

	showFindProvidersButton$: Observable<boolean>;

	public readonly NetworkStructure = NetworkStructure;

	protected readonly LabelColorTheme = LabelColorTheme;

	protected readonly OON_MN_NAME = 'out of network';
	protected readonly OON_SIMPLE_NAME = 'out network';

	newSubscription: Subscription = new Subscription();

	isEnabledCoverageInfoRestructure: boolean = this.unleashService.isEnabled(FfNewCoverageInfoRestructure);

	BenefitType = BenefitType;

	isAnthemContract$: Observable<boolean>;
	isAdditionalService$: Observable<boolean>;

	constructor(
		private allServicesStoreService: AllServicesStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private benefitStoreService: BenefitStoreService,
		private newBenefitStoreService: NewBenefitStoreService,
		private additionalBenefitsStoreService: AdditionalBenefitsStoreService,
		private ribbonServices: RibbonEntitiesStoreService,
		private trackingService: TrackingService,
		private homePageService: HomePageService,
		private unleashService: UnleashService,
		private dialogService: HealtheeDialogService,
		private medicalConditionsStoreService: MedicalConditionsStoreService,
		private externalApiService: ExternalApiService,
		private benefitsService: BenefitsService,
		private _router: Router
	) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'serviceId', 'isAdditionalService'];
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.newSubscription.unsubscribe();
	}

	ngOnInit(): void {
		const serviceId$ = this.inputs.one('serviceId');
		this.isAdditionalService$ = this.inputs.one('isAdditionalService');

		this.userBenefitsMap$ = this.homePageService.userBenefitsMap$;

		this.isHeaderShrinked$ = this.contentScrollPos$.pipe(
			map((pos) => pos > 160),
			shareReplay(1)
		);

		this.service$ = combineLatest([serviceId$, this.isAdditionalService$]).pipe(
			switchMap(([serviceId, isAdditionalService]) => {
				return isNil(serviceId)
					? of(null)
					: isAdditionalService
					? this.additionalBenefitsStoreService.byId(serviceId).pipe(
							map((benefit) =>
								isNil(benefit)
									? null
									: {
											abbreviate: benefit.name,
											name: benefit.name,
											title: benefit.name,
											type: '',
											_id: benefit._id,
											synonyms: [],
											iconURL: benefit.iconURL,
									  }
							)
					  )
					: this.allServicesStoreService.byId(serviceId);
			}),
			shareReplay(1)
		);

		this.benefit$ = combineLatest([serviceId$, this.isAdditionalService$]).pipe(
			switchMap(([serviceId, isAdditionalService]) => {
				return isNil(serviceId)
					? of(null)
					: isAdditionalService
					? this.additionalBenefitsStoreService.byId(serviceId).pipe(
							map((benefit) =>
								isNil(benefit)
									? null
									: {
											_id: benefit._id,
											inNetwork: '',
											outNetwork: '',
											additionalNetworks: [],
											blueOptionsNetwork: '',
											subjectToDeductibleOut: false,
											subjectToDeductibleIn: false,
											subjectToDeductibleBlueOptions: false,
											iconURL: '',
											title: benefit.name,
											desc: benefit.description,
											notes: [benefit.notes],
											relatedServices: [],
											brochureFilePath: benefit.brochureFilePath,
									  }
							)
					  )
					: this.benefitStoreService.get(serviceId);
			}),
			shareReplay(1)
		);

		this.benefit$ = combineLatest([this.benefit$, this.userPlanDataStoreService.getMnNetworkSortArray()]).pipe(
			map(([benefit, contractNetworks]) => {
				if (benefit?.networkStructure !== NetworkStructure.MN) return benefit;
				return {
					...benefit,
					additionalNetworks: this.benefitsService.setMnBenefitsNetworkPriority(
						contractNetworks,
						benefit.additionalNetworks
					),
				};
			})
		);

		this.newBenefit$ = combineLatest([
			serviceId$.pipe(
				RxjsUtils.isNotNil(),
				map((serviceId) => (isNil(serviceId) ? null : serviceId))
			),
			this.userBenefitsMap$.pipe(
				RxjsUtils.isNotNil(),
				map((map) => (isNil(map) ? null : map))
			),
			this.benefit$.pipe(
				RxjsUtils.isNotNil(),
				map((benefit) => (isNil(benefit) ? null : benefit._id))
			),
			this.benefit$.pipe(
				RxjsUtils.isNotNil(),
				map((benefit) => benefit?.createdBy2p0)
			),
		]).pipe(
			switchMap(([serviceId, map, oldBenefitId, isNew]) => {
				this.trackingService.trackClientEvent('Benefit Panel', { type: this.getBenefitVersionType(isNew) });
				const contractId = map.get(serviceId)?.['contractId'] ?? '';
				return isNil(contractId) || isNil(oldBenefitId) || !isNew
					? of({})
					: this.newBenefitStoreService.get({
							contractId,
							oldBenefitId,
					  });
			}),
			shareReplay(1)
		);

		this.newSubscription = combineLatest([this.benefit$, this.newBenefit$])
			.pipe(
				RxjsUtils.isNotNil(),
				filter(([benefit, newBenefit]) => newBenefit !== null), // Filter out null or undefined
				take(1),
				tap(([benefit, newBenefit]) => {
					if (newBenefit?.oid) {
						this.activeBenefit = BenefitType.NEW;
					} else if (benefit?._id) {
						this.activeBenefit = BenefitType.OLD;
					}
				})
			)
			.subscribe();

		this.showFindProvidersButton$ = combineLatest([
			this.isAdditionalService$,
			this.externalApiService.isUserSupportedByTalon(),
			serviceId$,
			this.ribbonServices.getHealthServices(),
		]).pipe(
			map(([isAdditionalService, hasTalon, serviceId, ribbonServices]) => {
				if (isAdditionalService) return false;
				if (hasTalon) return true;
				return !!ribbonServices.find((service) => service._id === serviceId);
			})
		);

		this.subsBag.add = serviceId$.subscribe({
			next: () => this.contentScrollPos$.next(0),
		});

		this.isAnthemContract$ = this.userPlanDataStoreService.hasMedicalPlanByAnthem();
	}

	public setActiveTab(index: number, tabName: string) {
		this.activeNetworkTabName = tabName;
		this.activeNetworkTabIndex = index;
	}

	public getAppointmentChecklistCount(network: Network) {
		let counter = 0;
		if (network.appointmentChecklist) counter++;
		if (network.referralRequired) counter++;
		if (network.preAuth) counter++;
		return counter;
	}

	public getImportantToKnowCount(network: Network, gender: string) {
		let counter = 0;
		if (gender) counter++;
		if (network.ageRestriction) counter++;
		if (network.countLimitations) counter++;
		if (network.preExistConditions?.length) counter++;
		if (network.notes?.length) counter++;
		return counter;
	}

	public onFeedbackClick(rating) {
		this.trackingService.trackClientEvent('Feedback Benefit panel', { rating, feature: this.feature });
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			component: FeedbackDialogComponent,
			data: {
				rating,
				feature: this.feature,
				data: this.data,
				headline: FeedbackLocalize.headline,
				question: FeedbackLocalize.question,
			},
		};
		return this.dialogService.open(options);
	}

	public getMedicalConditionById(id: string): Observable<Maybe<MedicalConditionsResponse>> {
		return this.medicalConditionsStoreService.byId(id);
	}

	public getCountLimitationsText(countLimitations: CountLimitation) {
		if (countLimitations.label) {
			return countLimitations.label;
		}

		let returnString = '';
		if (countLimitations.serviceCount) {
			returnString += countLimitations.serviceCount + ' per ';
		}
		if (countLimitations.timeUnitCount) {
			returnString += countLimitations.timeUnitCount + ' ';
		}
		if (countLimitations.timeUnit) {
			returnString +=
				countLimitations.timeUnitCount === 1 && countLimitations.timeUnit.endsWith('s')
					? countLimitations.timeUnit.slice(0, -1)
					: countLimitations.timeUnit;
		}
		return returnString;
	}

	trackBrochureEvent() {
		this.benefit$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe({
			next: (benefit) =>
				this.trackingService.trackClientEvent('Coverage Info Employer Benefit link click', {
					'service name': benefit.title,
				}),
		});
	}

	onFindProvidersClick() {
		//this.findProviderClick.emit();

		this.benefit$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe({
			next: (benefit) =>
				this.trackingService.trackClientEvent('Benefit Card Find Providers Click', {
					'Service Name': benefit.title,
					'Benefit type': this.getBenefitVersionType(benefit.createdBy2p0),
				}),
		});

		let url;

		if (this.isMp3TabActive()) {
			url = '/care-and-costs/mp3-providers';
		} else {
			url = '/care-and-costs/providers';
		}

		return this._router.navigate([url], {});
	}

	getBenefitVersionType(createdBy2p0: boolean | undefined) {
		return createdBy2p0 ? 'New' : 'Old';
	}

	isMp3TabActive() {
		return this.activeNetworkTabName === 'mp3';
	}

	showMP3Disclaimer(): Observable<boolean> {
		return combineLatest([this.isAnthemContract$, this.userBenefitsMap$, this.newBenefit$]).pipe(
			map(([isAnthemContract, userBenefitsMap, newBenefit]) => {
				const networkTitle = newBenefit?.networks[this.activeNetworkTabIndex]?.title;

				const showMP3DisclaimerResult = showMP3Disclaimer(this.serviceId, networkTitle);
				if (showMP3DisclaimerResult !== undefined) {
					return showMP3DisclaimerResult;
				}

				if (this.isMp3TabActive()) {
					return false;
				}

				const userBenefit = userBenefitsMap?.get(this.serviceId);
				const isAnthemCarrier = userBenefit?.carrier?.name.toLowerCase().includes('anthem');
				return isAnthemContract && isAnthemCarrier;
			})
		);
	}

	showDisclaimerPanel(): Observable<boolean> {
		return this.newBenefit$.pipe(
			switchMap((newBenefit) => {
				const networkTitle = newBenefit?.networks[this.activeNetworkTabIndex]?.title;
				const showDisclaimerResult = showDisclaimerPanelCard(this.serviceId, networkTitle);

				if (showDisclaimerResult !== undefined) {
					return of(showDisclaimerResult);
				}

				return this.isAdditionalService$.pipe(map((isAdditionalService) => !isAdditionalService));
			})
		);
	}

	shouldShowProviderSearchButton(): Observable<boolean> {
		return this.newBenefit$.pipe(
			switchMap((newBenefit) => {
				const networkTitle = newBenefit?.networks[this.activeNetworkTabIndex]?.title;
				const showProviderSearchResult = showProviderSearchButton(this.serviceId, networkTitle);

				if (showProviderSearchResult !== undefined) {
					return of(showProviderSearchResult);
				}

				return this.showFindProvidersButton$.pipe(
					map(
						(showFindProvidersButton) =>
							showFindProvidersButton && !this.hideFindProvidersButton && !this.isOONTabActive()
					)
				);
			})
		);
	}

	showAppointmentChecklist(): Observable<boolean> {
		return this.newBenefit$.pipe(
			switchMap((newBenefit) => {
				const networkTitle = newBenefit?.networks[this.activeNetworkTabIndex]?.title;
				const showAppointmentChecklistResult = showAppointmentChecklist(this.serviceId, networkTitle);

				if (showAppointmentChecklistResult !== undefined) {
					return of(showAppointmentChecklistResult);
				}

				return of(!this.isOONTabActive());
			})
		);
	}

	public transformNetworkName(networkName: string): string {
		if (!networkName) return '';
		switch (networkName.toLowerCase()) {
			case this.OON_MN_NAME:
			case this.OON_SIMPLE_NAME:
				return 'Out-of-Network';
			default:
				return networkName;
		}
	}

	public isOONTabActive(): boolean {
		return (
			this.activeNetworkTabName === this.OON_MN_NAME ||
			this.activeNetworkTabName === this.OON_SIMPLE_NAME ||
			this.activeNetworkTabName === 'out-of-network'
		);
	}

	public isOneTabAnthemService(): Observable<boolean> {
		return (
			this.isAnthemContract$.pipe(map((isAnthem: boolean) => isAnthem)) &&
			this.newBenefit$.pipe(
				map((benefit: NewBenefit) =>
					benefit.networks.some(
						(network: Network) =>
							network.title.toLowerCase() === 'mp3' &&
							(network.copay?.label ?? '').toLowerCase() === 'hide'
					)
				)
			)
		);
	}
}
