import { Component, Input } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { LabelColorTheme } from 'ripple';

@Component({
	selector: 'app-service-panel-card',
	templateUrl: './service-panel-card.component.html',
	styleUrls: ['./service-panel-card.component.scss'],
})
export class ServicePanelCardComponent extends CommonComponent {
	@Input()
	iconURL: string;

	@Input()
	title: string;

	@Input()
	description: string;

	@Input()
	labelText: string;

	@Input()
	badgeSuccess: boolean;

	@Input()
	preformatted?: boolean;

	constructor() {
		super();
	}

	protected readonly LabelColorTheme = LabelColorTheme;
}
