import { ReferralsInfoResponse } from 'src/app/services/api/referrals-info-api/helpers/referrals-info-api.response';

export function sort(sortDirection: string, data?: ReferralsInfoResponse[]) {
	if (!data) {
		return;
	}

	if (sortDirection == 'newest') {
		return data?.sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime());
	} else {
		return data?.sort((a, b) => a.CreatedDate.getTime() - b.CreatedDate.getTime());
	}
}
