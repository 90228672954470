import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, map, take } from 'rxjs';

import { UserService } from '../services/user.service';
import CFG from '../config/app-config.json';
import { UIService } from '../services/ui.service';

@Injectable({
	providedIn: 'root',
})
export class EligibleUserGuard implements CanActivate {
	constructor(private userService: UserService, private uiService: UIService) {}

	canActivate(): Observable<boolean> {
		return this.userService.userData$.pipe(
			map((userData) => {
				if (userData.esiEligible) return true;
				this.uiService.navigate([CFG.defaultAppRoute]);
				return false;
			}),
			take(1)
		);
	}
}
