import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavController } from '@ionic/angular';
import { map, Observable, switchMap } from 'rxjs';
import { FacilityDetailsStoreService } from 'src/app/services/stores/facility-details-store/facility-details-store.service';
import { Facility } from '../helpers/providers.helpers';

@Component({
	selector: 'app-facility-details-page',
	templateUrl: './facility-details-page.component.html',
	styleUrls: ['./facility-details-page.component.scss'],
})
export class FacilityDetailsPageComponent {
	uuid$ = this.route.params.pipe(map((params: Params) => params.uuid));
	facilityData$: Observable<Facility> = this.uuid$.pipe(
		switchMap((uuid: string) => this.facilityDetailsStore.get(uuid))
	);

	constructor(
		private facilityDetailsStore: FacilityDetailsStoreService,
		private route: ActivatedRoute,
		private navCtrl: NavController
	) {}

	onClickBack() {
		this.navCtrl.back();
	}
}
