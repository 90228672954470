import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const EMPTY_SIGNAL = null;
const OPTIONS = {
	componentRestrictions: { country: 'us' },
};

@Component({
	selector: 'app-google-places-autocomplete',
	templateUrl: './google-places-autocomplete.component.html',
	styleUrls: ['./google-places-autocomplete.component.scss'],
})
export class GooglePlacesAutocompleteComponent implements OnInit {
	@Input() enabled: boolean = true;

	@Output() addressChanged: EventEmitter<string> = new EventEmitter<string>(null);
	@Output() invalidAddress: EventEmitter<null> = new EventEmitter<null>(null);

	public readonly options = OPTIONS;

	public searchTerm: string = '';
	public isViewDisabled: boolean = true;

	constructor() {}

	ngOnInit(): void {}

	public onAddressChanged(addressObject: any): void {
		const address = addressObject?.formatted_address;
		this.addressChanged.emit(address);
	}

	public showResultsPanel(): void {
		this.invalidAddress.emit(EMPTY_SIGNAL);
	}
}
