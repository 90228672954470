<div class="scroll-container">
	<div class="header-close">
		<span class="previous-btn">
			<span
				*ngIf="currentStep === flowSteps.confirm"
				class="icon material-icons-outlined"
				(click)="goToStep(flowSteps.availability)"
			>
				<img src="assets/images/icons/arrow-left.svg" />
			</span>
		</span>
		<span
			*ngIf="currentStep !== flowSteps.submitted"
			class="close-btn material-icons-outlined"
			(click)="onClose.emit()"
		>
			<img src="assets/images/icons/x-close-dialog.svg" />
		</span>
	</div>

	<div *ngIf="isProcessing" class="loader"><app-loader size="medium"></app-loader></div>

	<div *ngIf="!isProcessing">
		<app-appointment-availability-form
			[hidden]="currentStep !== flowSteps.availability"
			[zocdocData]="zocdocData"
			[providerData]="providerData"
			(setAvailabilitySelection)="setAvailabilitySelection($event)"
			(setSelectedSlot)="setSelectedSlot($event)"
		></app-appointment-availability-form>
		<app-appointment-confirmation
			[hidden]="currentStep !== flowSteps.confirm"
			(goToStep)="goToStep($event)"
			(cancelSubmit)="cancelSubmit()"
			[zocdocData]="zocdocData"
			[providerData]="providerData"
			(userFormValid)="onUserFormValid($event)"
			[availabilitySelection]="availabilitySelection"
			[selectedSlot]="selectedSlot"
		></app-appointment-confirmation>
		<app-appointment-reschedule
			[hidden]="currentStep !== flowSteps.reschedule"
			*ngIf="appointment && currentStep === flowSteps.reschedule"
			[appointment]="appointment"
			(setAvailabilitySelection)="setAvailabilitySelection($event)"
			(setSelectedSlot)="setSelectedSlot($event)"
			[providerData]="providerData"
		></app-appointment-reschedule>
		<app-appointment-submitted
			[hidden]="currentStep !== flowSteps.submitted"
			[isReschedule]="!!appointment"
			(onSubscriptionChanged)="onSubscriptionChanged($event)"
		></app-appointment-submitted>
	</div>
</div>

<div *ngIf="!!selectedSlot" class="footer">
	<provider-avatar [gender]="providerData.gender" size="medium"></provider-avatar>
	<div class="info">
		<div class="date">{{ selectedSlot.date | date : 'MMMM d' }}</div>
		<div>{{ selectedSlot.date | date : 'shortTime' }}</div>
	</div>
	<ng-container [ngSwitch]="currentStep">
		<ripple-button
			*ngSwitchCase="flowSteps.availability"
			(click)="submitSelectedSlot()"
			[label]="'Continue' | translate"
			size="md"
		>
		</ripple-button>
		<ripple-button
			*ngSwitchCase="flowSteps.confirm"
			(click)="submitAppointmentRequest()"
			[label]="'Request to book' | translate"
			[isLoading]="isSubmitting"
			size="md"
		>
		</ripple-button>
		<ripple-button
			*ngSwitchCase="flowSteps.reschedule"
			(click)="submitRescheduleRequest()"
			[label]="'Request to reschedule' | translate"
			[isLoading]="isSubmitting"
			size="md"
		>
		</ripple-button>
		<ripple-button
			*ngSwitchCase="flowSteps.submitted"
			(click)="onClickDone()"
			[label]="'Done' | translate"
			size="md"
		>
		</ripple-button>
	</ng-container>
</div>
