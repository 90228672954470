import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatestWith, map, Observable } from 'rxjs';
import { TrackingService } from 'src/app/services/tracking.service';
import { FavoriteProvidersStoreService } from '../../../../services/stores/favorite-providers-store/favorite-providers-store.service';
import { UIService } from '../../../../services/ui.service';
import { HealtheeContentSliderComponent } from '../../../app-shared/healthee-content-slider/healthee-content-slider.component';
import { createCards, deviceToMinCardsCount, ProviderCardType, ProviderCard } from '../../helpers/my-care-team.helpers';

@Component({
	selector: 'app-my-care-team-teaser',
	templateUrl: './my-care-team-teaser.component.html',
	styleUrls: ['./my-care-team-teaser.component.scss'],
})
export class MyCareTeamTeaserComponent implements OnInit {
	@ViewChild('slider') slider: HealtheeContentSliderComponent;

	ProviderCardType = ProviderCardType;

	enableScrollRight: boolean = true;
	enableScrollLeft: boolean = false;

	cards$: Observable<ProviderCard<ProviderCardType>[]> = this.favoriteProvidersStore
		.get()
		.pipe(combineLatestWith(this.uiService.isMobile$.pipe(map(deviceToMinCardsCount))), map(createCards));

	constructor(
		private favoriteProvidersStore: FavoriteProvidersStoreService,
		private uiService: UIService,
		private trackingService: TrackingService
	) {}

	ngOnInit(): void {}

	onScroll(direction: 'left' | 'right'): void {
		this.slider.scrollContent(direction);
	}
	public track(event, metaData = {}) {
		this.trackingService.trackClientEvent(event, metaData);
	}
}
