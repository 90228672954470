import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { ServicesResponse } from '../../api/employee-api/helpers/services.response';
import { map, Observable, share, shareReplay } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { EmployeeApiService } from '../../api/employee-api/employee-api.service';
import { isNil } from '../../../utils/is/is-nil';

@Injectable({
	providedIn: 'root',
})
export class AllServicesStoreService extends Store<void, ServicesResponse[]> {
	constructor(private employeeApiService: EmployeeApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<ServicesResponse[]>> {
		return this.employeeApiService.services();
	}

	byId(serviceId: string): Observable<Maybe<ServicesResponse>> {
		return this.get().pipe(
			map((services) => (isNil(services) ? null : services.find((s) => s._id === serviceId))),
			shareReplay(1)
		);
	}

	namesStr(): Observable<Maybe<Map<string, string>>> {
		return this.get().pipe(
			map((services) => {
				if (isNil(services)) {
					return null;
				}
				const res = new Map<string, string>();

				for (const service of services) {
					let concatedNameStr = service.abbreviate.toLowerCase();
					for (const synonym of service.synonyms) {
						concatedNameStr += ' ' + synonym.toLowerCase();
					}
					res.set(service._id, concatedNameStr);
				}

				return res;
			}),
			share()
		);
	}
}
