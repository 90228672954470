import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TrackingService } from '../../../../../services/tracking.service';
import { UIService } from '../../../../../services/ui.service';
import { invokePhoneDialer } from '../../../../../utils/utils';
import { ProviderDetails, ProviderGender } from '../../helpers/providers.helpers';
import { ScheduleAppointmentEvents } from '../../../../../models/tracking-events.model';
import { ProvidersSearchService } from '../../../../../services/providers-search/providers-search.service';
import { ICON_SIZE } from 'ripple';
import { Observable } from 'rxjs';
import { SearchBarService } from 'src/app/services/providers-search/search-bar.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';

const ASSETS_PATH = '/assets/images/provider-card';

@Component({
	selector: 'app-provider-card',
	templateUrl: './provider-card.component.html',
	styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent implements OnInit {
	ProviderGender = ProviderGender;

	@Input() appearance: 'standard' | 'outlined' = 'outlined';
	@Input() providerData: ProviderDetails;
	@Input() isFavorite: boolean = false;
	@Input() eventSource: string = null;
	@Output() isFavoriteChange = new EventEmitter<boolean>();
	@Output() openScheduleAppointment = new EventEmitter<boolean>();

	public readonly NetworkStructure = NetworkStructure;
	public isMobileScreen: boolean = UIService.isMobileDeviceScreen(window) || UIService.isTabletDeviceScreen(window);
	public assetsPath: string = ASSETS_PATH;
	public phoneNumber?: string;
	public rippleSize: typeof ICON_SIZE = ICON_SIZE;

	private destroyRef = inject(DestroyRef);
	public isRbpApproved$: Observable<boolean> = this.searchBarService._rbpNetworkSearch$;
	public networkStructure$: Observable<NetworkStructure> = this.userPlanDataStoreService.getPlansNetworkStructure();

	public get hasOnlineBookingOption() {
		return this.providerData.isZocdoc === true;
	}

	constructor(
		private trackingService: TrackingService,
		private providersSearchService: ProvidersSearchService,
		private searchBarService: SearchBarService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {}

	ngOnInit(): void {
		this.setPhoneNumber();
	}

	private setPhoneNumber(): void {
		if (!this.providerData?.locations?.length) return;

		const firstLocationWithPhoneNumber = this.providerData.locations.find(
			(location) => location?.phoneNumbers?.length
		);
		this.phoneNumber = firstLocationWithPhoneNumber?.phoneNumbers[0];
	}

	public callToProvider(event, numberPhone): void {
		event.stopPropagation();

		invokePhoneDialer(numberPhone);
		this.track('PS - Card: Call', { Source: 'Main card', cardType: 'Provider' });
	}

	public onOpenScheduleAppointment(event): void {
		event.stopPropagation();
		this.trackingService.trackClientEvent(ScheduleAppointmentEvents.SchedulingFormOpened, {
			source: this.eventSource,
		});

		this.openScheduleAppointment.emit();
	}

	public track(event, metaData): void {
		this.trackingService.trackClientEvent(event, metaData);
	}

	public showTooltip(tooltip: MatTooltip): void {
		if (this.isMobileScreen) {
			tooltip.disabled = true;
			return;
		}
		tooltip.disabled = false;
		tooltip.show();
	}
}
