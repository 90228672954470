import { Injectable } from '@angular/core';
import { DeductiblesStoreService } from './deductibles-store/deductibles-store.service';
import { Initializer } from '../../utils/initializer';
import { StoreUpdateRegistryService } from './store-update-registry.service';
import { DeductiblesStoreToken } from './deductibles-store/deductibles-store.token';
import { FavoriteProvidersStoreToken } from './favorite-providers-store/favorite-providers-store.token';
import { FavoriteProvidersStoreService } from './favorite-providers-store/favorite-providers-store.service';
import { MeUserStoreService } from './me-user-store/me-user-store.service';
import { MeUserStoreToken } from './me-user-store/me-user-store.token';
import { AppointmentsListStoreService } from './appointments-list-store/appointments-list-store.service';
import { AppointmentsListStoreToken } from './appointments-list-store/appointments-list-store.token';
import { PreventiveCareStoreToken } from './preventive-care-store/preventive-care-store.token';
import { PreventiveCareStoreService } from './preventive-care-store/preventive-care-store.service';

@Injectable({
	providedIn: 'root',
})
export class StoreUpdateHandlerService implements Initializer {
	constructor(
		private deductiblesStoreService: DeductiblesStoreService,
		private favoriteProvidersStoreService: FavoriteProvidersStoreService,
		private meUserStoreService: MeUserStoreService,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private appointmentsListStoreService: AppointmentsListStoreService,
		private _preventiveCareStoreService: PreventiveCareStoreService
	) {}

	init() {
		this.storeUpdateRegistryService.updatesFor(DeductiblesStoreToken).subscribe({
			next: () => this.deductiblesStoreService.reload(),
		});
		this.storeUpdateRegistryService.updatesFor(FavoriteProvidersStoreToken).subscribe({
			next: () => this.favoriteProvidersStoreService.reload(),
		});
		this.storeUpdateRegistryService.updatesFor(MeUserStoreToken).subscribe({
			next: () => this.meUserStoreService.reload(),
		});
		this.storeUpdateRegistryService.updatesFor(AppointmentsListStoreToken).subscribe({
			next: () => this.appointmentsListStoreService.reload(),
		});
		this.storeUpdateRegistryService.updatesFor(PreventiveCareStoreToken).subscribe({
			next: () => this._preventiveCareStoreService.reload(),
		});
	}
}
