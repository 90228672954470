import { T } from '@transifex/angular';

export class VersionUpdateLocalize {
	// Mandatory updates
	@T('App update is required')
	static txtAppUpdateIsRequired: string;

	@T('A mandatory app update is required. Please open App Store and update this app.')
	static txtMandatoryUpdateInstructionsIos: string;

	@T('A mandatory app update is required. Please open Google Play and update this app.')
	static txtMandatoryUpdateInstructionsAndroid: string;

	// Optional updates
	@T('App update is available')
	static txtAppUpdateIsAvailable: string;

	@T('An app update is available. Please open App Store and update this app.')
	static txtOptionalUpdateInstructionsIos: string;

	@T('An app update is available. Please open Google Play and update this app.')
	static txtOptionalUpdateInstructionsAndroid: string;

	@T('Open Google Play')
	static txtOpenGooglePlay: string;

	@T('Open App Store')
	static txtOpenAppStore: string;

	// Opening external browser
	@T('Opening App Store...')
	static txtOpeningAppStore: string;

	@T('Opening Google Play...')
	static txtOpeningGooglePlay: string;

	@T('Failed to open App Store. Please update the app manually.')
	static txtFailedToOpenAppStore: string;

	@T('Failed to open Google Play. Please update the app manually.')
	static txtFailedToOpenGooglePlay: string;

	// Update Process
	@T('Checking for updates.... Please wait.')
	static txtCheckingForUpdates: string;

	@T('Downloading update...')
	static txtDownloadingUpdate: string;

	@T('Installing...')
	static txtInstalling: string;

	@T('App is up to date.')
	static txtAppIsUpToDate: string;

	@T('Error updating app. Please try again later.')
	static txtErrorUpdatingApp: string;

	@T('Update app')
	static txtUpdateApp: string;

	@T('Skip update')
	static txtSkipUpdating: string;

	@T('Continue')
	static txtContinue: string;
}
