import { catchError, map, merge, Observable, of } from 'rxjs';

export enum HttpRequestProgressStatus {
	Pending = 'pending',
	Success = 'success',
	Error = 'error',
	Loading = 'loading',
}

export const createHttpProgress = <Data, Error = any>(request: Observable<Data>): HttpProgress<Data, Error> =>
	merge(
		of({ type: HttpRequestProgressStatus.Loading }),
		request.pipe(
			map((data: Data) => ({ type: HttpRequestProgressStatus.Success, response: data })),
			catchError((error: Error) => of({ type: HttpRequestProgressStatus.Error, response: error }))
		)
	);

export type HttpProgress<Data, Error> = Observable<HttpProgressStage<Data, Error>>;

interface HttpProgressStage<Data, Error = any> {
	type: HttpRequestProgressStatus;
	response?: Data | Error;
}
