import { MedicalConditionsResponse } from '../../../api/employee-api/helpers/medical-conditions.response';
import { isNil } from '../../../../utils/is/is-nil';

export function serviceConditions(medicalConditions: MedicalConditionsResponse[]) {
	const res = new Map<string, Map<string, MedicalConditionsResponse>>();
	if (isNil(medicalConditions)) {
		return res;
	}

	const checkService = (serviceId: string) => {
		if (res.has(serviceId)) {
			return;
		}
		res.set(serviceId, new Map<string, MedicalConditionsResponse>());
	};

	const processServices = (serviceIds: string[], condition: MedicalConditionsResponse) => {
		serviceIds.forEach((serviceId) => {
			checkService(serviceId);
			res.get(serviceId).set(condition._id, condition);
		});
	};

	medicalConditions.forEach((condition) => {
		processServices(condition.services.stage1, condition);
		processServices(condition.services.unDiagnosed, condition);
		processServices(condition.services.preventive, condition);
	});

	return res;
}
