import { Component, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-tooltip-bubble',
	templateUrl: './tooltip-bubble.component.html',
	styleUrls: ['./tooltip-bubble.component.scss'],
})
export class TooltipBubbleComponent {
	@Input() isDynamicWidth = false;

	tooltip: TemplateRef<any>;
}
