import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReferralsInfoResponse } from './helpers/referrals-info-api.response';

@Injectable({ providedIn: 'root' })
export class ReferraceInfoApiService {
	constructor(private http: HttpClient) {}

	public getReferrals(): Observable<ReferralsInfoResponse[]> {
		return this.http.get<ReferralsInfoResponse[]>('/mpi-referrals');
	}

	public downloadReferral(referralCode: string): Observable<ArrayBuffer> {
		return this.http.get(`/mpi-referral/${referralCode}/download`, { responseType: 'arraybuffer' });
	}
}
