import { Component } from '@angular/core';
import { ReferraceinfoStoreService } from 'src/app/services/stores/referrance-info-store/referrance-info-store.service';
import { UIService } from 'src/app/services/ui.service';

@Component({
	selector: 'app-referrals-info-page',
	templateUrl: './referrals-info-page.component.html',
	styleUrls: ['./referrals-info-page.component.scss'],
})
export class ReferralsInfoPageComponent {
	constructor(private referraceinfoStoreService: ReferraceinfoStoreService, public uiService: UIService) {}
}
