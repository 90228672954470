import { Injectable } from '@angular/core';
import { PreventiveCareApiService } from '../../preventive-care-api.service';
import { PreventiveCare, PreventiveCareService } from '../../preventive-care.service';
import { Observable, map, shareReplay, switchMap } from 'rxjs';
import { Store } from '../../../utils/store/store';
import { RxjsUtils } from '../../../utils/rxjs';
import { HomePageService } from '../../../modules/home-page/services/home-page.service';

@Injectable({
	providedIn: 'root',
})
export class PreventiveCareStoreService extends Store<void, PreventiveCare[]> {
	constructor(
		private preventiveCareApiService: PreventiveCareApiService,
		private preventiveCareService: PreventiveCareService,
		private homePageService: HomePageService
	) {
		super();
	}

	protected retrieve(): Observable<PreventiveCare[]> {
		return this.homePageService.userBenefitsMap$.pipe(
			RxjsUtils.isNotNil(),
			switchMap((benefits) =>
				this.preventiveCareApiService.getChecklist().pipe(
					RxjsUtils.isNotNil(),
					map((preventiveCares: PreventiveCare[]) =>
						this.preventiveCareService.normalizeData(preventiveCares, benefits)
					)
				)
			)
		);
	}

	getById(id: number): Observable<PreventiveCare> {
		id = Number(id);
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((preventiveCareList) => {
				const item = preventiveCareList.find((item: PreventiveCare) => item.id === id);
				return item;
			}, shareReplay(1))
		);
	}
}
