import { Injectable } from '@angular/core';
import { URL } from '../utils/url-service.interface';
import { SessionStorageService } from './sessionStorage.service';

const SESSION_STORAGE_PREVIOUS_URL = 'previousUrl';
const SESSION_STORAGE_PREVIOUS_URL_QUERY_PARAMS = 'previousUrlQueryParams';

@Injectable({ providedIn: 'root' })
export class UrlService {
	constructor(private sessionStorageService: SessionStorageService) {}

	public getPreviousUrl(): URL {
		const url = this.sessionStorageService.getItem(SESSION_STORAGE_PREVIOUS_URL);
		const queryParams = sessionStorage.getItem(SESSION_STORAGE_PREVIOUS_URL_QUERY_PARAMS);
		return { url, queryParams };
	}

	public setPreviousUrl(url: string = null, queryParams: object): void {
		this.sessionStorageService.setItem(SESSION_STORAGE_PREVIOUS_URL, url);
		this.sessionStorageService.setItem(SESSION_STORAGE_PREVIOUS_URL_QUERY_PARAMS, JSON.stringify(queryParams));
	}

	public clearPreviousUrl(): void {
		sessionStorage.removeItem(SESSION_STORAGE_PREVIOUS_URL);
		sessionStorage.removeItem(SESSION_STORAGE_PREVIOUS_URL_QUERY_PARAMS);
	}

	public getUrlWithoutParams(urlString: string): string {
		const url = new URL(urlString);
		return url.origin + url.pathname;
	}
}
