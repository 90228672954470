import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlag } from 'src/app/models/feature-flag.model';
import { ProvidersApiService } from '../../../../../services/api/providers-api/providers-api.service';
import { TrackingService } from '../../../../../services/tracking.service';
import { MPIProvidersSearchService } from '../../../../../services/providers-search/mpi-providers-search.service';
import { SearchEntity } from '../../helpers/providers-search.helper';
import { ProviderDetails } from '../../helpers/providers.helpers';
import { mapDefault, mapFallback } from './provider-map-style';
import { take } from 'rxjs';

const MARKER_ICONS = {
	ACTIVE: '/assets/images/provider-card/marker-active.svg',
	UNACTIVE: '/assets/images/provider-card/marker-unactive.svg',
};

const CONVERT_MILE_TO_METER = 1609.344; // 1 mile = 1609.344 meters

const mapPadding = { top: 100, right: 10, left: 10, bottom: 10 };

@Component({
	selector: 'app-mpi-providers-map',
	templateUrl: './providers-map.component.html',
	styleUrls: ['./providers-map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvidersMapComponent implements OnChanges {
	FeatureFlag = FeatureFlag;

	@ViewChildren(MapMarker) markerComponents: QueryList<MapMarker>;
	@Input() providerLocations = [];
	@Input() hasSearchThisAreaButton: boolean = false;
	@Input() activeLocationIndex: number;
	@Input() showActiveLocationCard: boolean = false;
	@Input() type: SearchEntity;
	@Input() isLoading: boolean;
	@Input() disablePanTo: boolean;
	@Output() openCreateAppointment = new EventEmitter<ProviderDetails>();

	@ViewChild(GoogleMap) map!: GoogleMap;

	public mapOptions: google.maps.MapOptions = mapDefault;

	public showSearchButton: boolean = false;
	public markers = [];
	private wasInitialized: boolean = false;
	private centerPointAddress: string = '';
	private visibleDistance: number = 0;
	private geoCoder: google.maps.Geocoder = new google.maps.Geocoder();

	constructor(
		private providersApiService: ProvidersApiService,
		private trackingService: TrackingService,
		private route: ActivatedRoute,
		private _changeDetectorRef: ChangeDetectorRef,
		private _providerSearchService: MPIProvidersSearchService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.activeLocationIndex && !changes.activeLocationIndex.firstChange) {
			const { previousValue, currentValue } = changes.activeLocationIndex;

			this.handleActiveLocationIndexChange(previousValue, currentValue);
		}

		if (changes.providerLocations?.currentValue !== changes.providerLocations?.previousValue) {
			this.setMarkers();
		}

		if (changes.providerLocations?.firstChange) {
			this.wasInitialized = true;
		}

		if (changes.isLoading && !!this.isLoading) {
			this.activeLocationIndex = null;
		}
	}

	handleActiveLocationIndexChange(prevIndex, currIndex): void {
		this.markerComponents.toArray()[prevIndex]?.marker.setIcon(MARKER_ICONS.UNACTIVE);
		this.markerComponents.toArray()[currIndex]?.marker.setIcon(MARKER_ICONS.ACTIVE);
		if (!this.disablePanTo) {
			this.centerMapSmoothyAccordingToMarker();
		}
	}

	public setZoomInLevel() {
		const currentZoom = this.map?.getZoom();
		this.map?.googleMap?.setZoom(currentZoom + 1);
	}
	public setZoomOutLevel() {
		const currentZoom = this.map?.getZoom();
		this.map?.googleMap?.setZoom(currentZoom - 1);
	}

	public goToCurrentLocation() {
		if (!navigator.geolocation) return;
		navigator.geolocation.getCurrentPosition((position) => {
			const center = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			this.map.panTo(center);
		});
	}

	private centerMap(): void {
		this.route.queryParams.pipe(take(1)).subscribe((params) => {
			const address = params['address'];
			this.activeLocationIndex !== null
				? this.centerMapOnMarker(this.activeLocationIndex)
				: this.centerMapOnAddress(address);
		});
	}

	private centerMapOnMarker(index): void {
		this.mapOptions.center = this.markers[index]?.position;
	}

	private async centerMapOnAddress(address): Promise<void> {
		await this.geoCoder.geocode({ address }, (results, status) => {
			if (status === 'OK') {
				const location = results[0].geometry.location;
				this.mapOptions.center = { lat: location.lat(), lng: location.lng() };
				this.map.panTo({ lat: location.lat(), lng: location.lng() });
			}
		});
		this.setMapZoomFromMarkers();
	}

	private async setMarkers(): Promise<void> {
		if (!this.isLoading) {
			this.markers = await this.providerLocations.map((location, index) => {
				const marker = {
					position: {
						lat: location?.latitude,
						lng: location?.longitude,
					},
					options: {
						icon: this.getIcon(index),
						clickable: true,
						animation: !this.wasInitialized ? google.maps.Animation.DROP : null,
					},
				};
				return marker;
			});
		}
		this.centerMap();
		this._changeDetectorRef.detectChanges();
	}

	private setMapZoomFromMarkers(): void {
		if (this.markers.length === 0) {
			this.map?.googleMap?.setZoom(mapFallback.zoom);
			return;
		}

		this.map?.googleMap?.setZoom(mapDefault.zoom);
		const bounds = new google.maps.LatLngBounds();
		bounds.extend(this.map.getCenter());

		this.markers.forEach((marker) => {
			bounds.extend(marker.position);
		});

		this.map?.googleMap?.fitBounds(bounds, mapPadding);
	}

	private centerMapSmoothyAccordingToMarker(): void {
		this.markers[this.activeLocationIndex] && this.map.panTo(this.markers[this.activeLocationIndex].position);
	}

	private getIcon(index): string {
		return index === this.activeLocationIndex ? MARKER_ICONS.ACTIVE : MARKER_ICONS.UNACTIVE;
	}

	public onMapChangeCenter(): void {
		this.onCenterChanged();
		this.resetMarkerClicked();
	}

	public handleOpenCreateAppointment(provider: ProviderDetails): void {
		this.openCreateAppointment.emit(provider);
	}

	public onMarkerClicked(markerIndex): void {
		this._providerSearchService.setActiveLocationIndex(markerIndex, { showCard: true });
	}
	public resetMarkerClicked(): void {
		this._providerSearchService.setActiveLocationIndex(null);
	}

	public onCenterChanged(): void {
		this.showSearchButton = true;

		const newCenterLat = this.map.getCenter().lat();
		const newCenterLng = this.map.getCenter().lng();
		this.setCenterAddress({ lat: newCenterLat, lng: newCenterLng });
		this.setVisibleDistance({ lat: newCenterLat, lng: newCenterLng });
	}

	public onAreaSearchClicked(): void {
		const queryParams = {
			address: this.centerPointAddress,
			distance: this.visibleDistance,
			page: 1,
			searchType: 'Options',
		};
		this.activeLocationIndex = null;
		this.providersApiService.searchByParams(queryParams, 'merge');
		this.trackingService.trackClientEvent('PS - Map: search this area');
		this.showSearchButton = false;
	}

	private setCenterAddress(latLngCenter: { lat: number; lng: number }): void {
		this.geoCoder.geocode({ location: latLngCenter }, (results) => {
			if (results?.length > 0) {
				this.centerPointAddress = results[0].formatted_address;
			}
		});
	}

	private setVisibleDistance(latLngCenter: { lat: number; lng: number }): void {
		if (this.map?.getBounds()) {
			const northEast = this.map.getBounds().getNorthEast();
			const latitudinalDistance = google.maps.geometry.spherical.computeDistanceBetween(
				latLngCenter,
				new google.maps.LatLng(northEast.lat(), latLngCenter.lng)
			);
			const longitudinalDistance = google.maps.geometry.spherical.computeDistanceBetween(
				latLngCenter,
				new google.maps.LatLng(latLngCenter.lat, northEast.lng())
			);

			const distanceMeter = Math.min(latitudinalDistance, longitudinalDistance);
			const distanceMiles = distanceMeter / CONVERT_MILE_TO_METER;

			this.visibleDistance = distanceMiles;
		}
	}
}
