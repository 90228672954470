import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DEFAULT_MPI_LOCATION, SearchEntity, SearchFields, SearchType } from '../../../helpers/providers-search.helper';
import { MPISearchBarService } from 'src/app/services/providers-search/mpi-search-bar.service';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
	selector: 'app-primary-care-specialties',
	templateUrl: './primary-care-specialties.component.html',
	styleUrls: ['./primary-care-specialties.component.scss'],
})
export class PrimaryCareSpecialtiesComponent implements OnInit {
	public specialties: any[] = [
		{
			name: 'Adult Primary Care',
			value: 'PRIMARY CARE PROVIDENCE-INCLUDES FAM/INT MEDICINE',
			id: 'AdultPrimaryCare',
		},
		{
			name: 'Obstetrics & Gynecology',
			value: 'OBSTETRICS & GYNECOLOGY',
			id: 'ObstetricsAndGynecology',
		},
		{
			name: 'Pediatrics, General',
			value: 'PEDIATRICS, GENERAL',
			id: 'PediatricsGeneral',
		},
	];

	@Input() defaultLocation: string;
	@Input() searchEvent: EventEmitter<SearchFields>;

	constructor(
		private searchBarService: MPISearchBarService,
		private trackingService: TrackingService,
		private _currentLocationService: CurrentLocationService
	) {}

	async ngOnInit(): Promise<void> {
		await this.checkCurrentLocation();
	}

	onCategoryClick(value: string) {
		const data = this.searchBarService.getSearchFormGroup();

		data.patchValue({
			address: this.defaultLocation,
			entity: SearchEntity.Provider,
			value: value.toUpperCase(),
			searchType: SearchType.Options,
		});

		this.trackingService.trackClientEvent('PS - Primary care specialties: Category clicked', {
			Source: 'MPI empty state',
			CategoryName: value.toUpperCase(),
		});

		this.searchEvent.emit(data.value);
	}

	private async checkCurrentLocation(): Promise<boolean> {
		const isApproved = await this._currentLocationService.checkPermission();
		if (isApproved) {
			await this.getCurrentLocation();
			return true;
		}

		this.defaultLocation = DEFAULT_MPI_LOCATION;
		return false;
	}

	private async getCurrentLocation(): Promise<void> {
		const place = await this._currentLocationService.getCurrentPlaceInfo();
		this.defaultLocation = place?.formatted_address;
	}
}
