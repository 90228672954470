import { Component, Input, OnInit } from '@angular/core';
import { FavoriteProvider, DEFAULT_FAVORITE_PROVIDER, PROVIDER_ICON_PATH, Gender } from '../helpers/providers.helpers';

@Component({
	selector: 'app-favorite-provider-card',
	templateUrl: './favorite-provider-card.component.html',
	styleUrls: ['./favorite-provider-card.component.scss'],
})
export class ProviderFavoriteCardComponent implements OnInit {
	@Input() provider: FavoriteProvider = DEFAULT_FAVORITE_PROVIDER;

	get providerIconPath() {
		return PROVIDER_ICON_PATH[this.provider.gender || Gender.Male];
	}
	constructor() {}

	ngOnInit(): void {}
}
