<div class="provider-about">
	<div class="information">
		<div class="title">{{ "Provider's information" | translate }}</div>
		<table>
			<tr *ngIf="providerData.specialties.length">
				<td class="sub-title">{{ 'Specialties' | translate }}</td>
				<td class="content">
					<div *ngFor="let specialty of providerData.specialties" class="item">
						{{ specialty | titlecase }}
					</div>
				</td>
			</tr>
			<tr *ngIf="providerData.languages?.length">
				<td class="sub-title languages">{{ 'Languages' | translate }}</td>
				<td class="content labels">
					<ng-container>
						<ripple-label
							*ngFor="let language of providerData.languages"
							[text]="language | titlecase"
						></ripple-label>
					</ng-container>
				</td>
			</tr>
			<tr *ngIf="providerData.educations.length">
				<td class="sub-title">{{ 'Education' | translate }}</td>
				<td class="content">
					<div *ngFor="let educationObj of providerData.educations" class="item">
						{{ educationObj.education.name | titlecase
						}}<span *ngIf="educationObj.year"> {{ educationObj.year }}</span>
					</div>
				</td>
			</tr>
			<tr *ngIf="providerData.gender">
				<td class="sub-title">{{ 'Gender' | translate }}</td>
				<td class="content">
					<div class="item">
						{{
							providerData.gender === ProviderGender.Male ? ('Male' | translate) : ('Female' | translate)
						}}
					</div>
				</td>
			</tr>
			<tr *ngIf="groups.length">
				<td class="sub-title" [ngPlural]="groups.length">
					<ng-template ngPluralCase="one">
						{{ 'Group' | translate }}
					</ng-template>
					<ng-template ngPluralCase="other">
						{{ 'Groups' | translate }}
					</ng-template>
				</td>
				<td class="content">
					<div *ngFor="let group of groups" class="item">
						{{ group }}
					</div>
				</td>
			</tr>
		</table>
	</div>

	<div
		*ngIf="providerData.panelDemographics.ages?.length > 0 || providerData.panelDemographics.sexes"
		class="demographics"
	>
		<div class="title">{{ 'Patient focus' | translate }}</div>

		<table>
			<tr *ngIf="providerData.panelDemographics.ages?.length > 0">
				<td class="sub-title">{{ 'Ages' | translate }}</td>
				<td class="content">
					<div *ngFor="let age of providerData.panelDemographics.ages" class="item">
						{{ age | titlecase }}
					</div>
				</td>
			</tr>
			<tr *ngIf="providerData.panelDemographics.sexes">
				<td class="sub-title">{{ 'Gender' | translate }}</td>
				<td class="content">
					<p class="item">{{ providerData.panelDemographics.sexes | titlecase }}</p>
				</td>
			</tr>
		</table>
	</div>

	<div *ngIf="providerData.clinical_areas.length" class="clinical-areas">
		<div class="title">{{ 'Clinical focus areas' | translate }}</div>

		<app-clinical-area *ngFor="let clinicalArea of providerData.clinical_areas" [data]="clinicalArea">
		</app-clinical-area>
	</div>
</div>
