import { Component, Input } from '@angular/core';
import { LoaderColor } from '../../../../../projects/ripple/src/public-api';

type LoaderSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
	@Input() size: LoaderSize = 'small';
	@Input() text: string = '';
	@Input() color: LoaderColor = LoaderColor.default;

}
