<div class="wrapper">
	<div class="grid">
		<div class="header-1">
			<div class="header-1-content" [ngClass]="{ 'three-icons': content.upperRightLogo === 'frontpath_right' }">
				<div class="image-container image-container-1">
					<img
						[ngSrc]="'https://assets.healthee.co/blackhawk_logos/' + content.upperLeftLogo + '.png'"
						alt=""
						fill
					/>
				</div>
				<div class="image-container image-container-2">
					<img
						*ngIf="content?.networkName !== 'RBP'"
						[ngSrc]="'https://assets.healthee.co/blackhawk_logos/' + content.upperRightLogo + '.png'"
						alt=""
						fill
					/>
				</div>
			</div>
		</div>
		<div class="header-2">
			<div class="header-2-content">
				<div class="item">
					<span class="item-bold">{{ 'Group ID' | translate }}</span>
					<span>{{ groupNo }}</span>
				</div>
				<div class="item">
					<span class="item-bold">{{ 'Employer' | translate }}</span>
					<span>{{ employer }}</span>
				</div>
			</div>
		</div>
		<div class="content content-1">
			<ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{ $implicit: userInfo }"></ng-container>
		</div>
		<div class="content content-2">
			<ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{ $implicit: benefits }"></ng-container>
		</div>
		<div class="content content-3">
			<div class="info">
				<div class="contact">
					<div class="image-container">
						<img ngSrc="https://assets.healthee.co/blackhawk_logos/A.png" alt="" fill />
					</div>
					<div class="contact-info">
						<div class="name">Telemedicine</div>
						<div class="number">{{ call }} (855) 355-2255</div>
						<a class="web" href="https://www.dailycare.com" target="_blank">www.dailycare.com</a>
					</div>
				</div>
				<div class="companies">
					<div class="image-container">
						<img ngSrc="https://assets.healthee.co/blackhawk_logos/blackhawk_special.png" alt="" fill />
					</div>
					<div class="image-container">
						<img ngSrc="https://assets.healthee.co/blackhawk_logos/welldyne_special.png" alt="" fill />
					</div>
				</div>
			</div>
		</div>
		<div class="content content-4">
			<ng-container
				[ngTemplateOutlet]="list"
				[ngTemplateOutletContext]="{ $implicit: insuranceInfo }"
			></ng-container>
		</div>
	</div>
</div>

<ng-template #list let-list>
	<div *ngFor="let item of list">
		<div class="list-item">
			<span class="property">{{ item.displayName | translate }}</span>
			<span class="value" *ngIf="!isArray(item.value); else arr">
				<span *ngIf="item.property === 'effectiveDate'; else default">
					{{ item.value | date : 'MM/dd/yyyy' }}
				</span>

				<ng-template #default>
					<span>{{ item.value }}</span>
				</ng-template></span
			>
			<ng-template #arr>
				<div class="sub-list">
					<span class="value" *ngFor="let val of item.value">{{ val }}</span>
				</div>
			</ng-template>
		</div>
	</div>
</ng-template>
