import { AfterViewInit, Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import CFG from '../../../config/app-config.json';
import { isIonic } from '../../../utils/utils';

import { TrackingService } from '../../../services/tracking.service';

const SCROLLED_PIXELS_PER_CLICK = CFG.ui.contentScrollerScrolledPixelsPerClick;
const SCROLL_SAFE_MARGIN_END = 10;
const SCROLL_START = 0;
const DEFAULT_APPEARANCE = 'large-chevrons';

type HealtheeContentSlideAppearance = 'standard' | 'large-chevrons' | 'small-round-chevrons';

@Component({
	selector: 'app-healthee-content-slider',
	templateUrl: './healthee-content-slider.component.html',
	styleUrls: ['./healthee-content-slider.component.scss'],
})
export class HealtheeContentSliderComponent implements AfterViewInit {
	@ViewChild('sliderContentWrapper', { static: false }) private sliderContentWrapper: ElementRef;
	@Input() customCssClassNameList: string = '';
	@Input() appearance: HealtheeContentSlideAppearance = DEFAULT_APPEARANCE;
	@Input() hideArrows: boolean = false;
	@Input() enableClickScroll = true;
	@Output() enableScrollLeftChange = new EventEmitter<boolean>();
	@Output() enableScrollRightChange = new EventEmitter<boolean>();

	public SCROLL_START = SCROLL_START;
	public isIonic = isIonic();

	private _sliderContentScroll: number = 0;
	private _enableScrollLeft: boolean = true;

	public get sliderContentScroll(): number {
		return this._sliderContentScroll;
	}

	public set sliderContentScroll(value: number) {
		this._sliderContentScroll = value;
		this.enableScrollLeftChange.emit(!(this._sliderContentScroll <= SCROLL_START));
	}

	public get enableScrollLeft(): boolean {
		return this._enableScrollLeft;
	}

	public set enableScrollLeft(isEnabled: boolean) {
		this._enableScrollLeft = isEnabled;
		this.enableScrollRightChange.emit(this._enableScrollLeft);
	}

	constructor(private trackingService: TrackingService) {}

	ngAfterViewInit() {
		const slider = this.sliderContentWrapper.nativeElement as HTMLElement;

		slider.childNodes.forEach((node) => {
			node.addEventListener('click', () => this.sliderChildItemClickedEvent(node));
		});
	}

	private sliderChildItemClickedEvent(element: ChildNode) {
		if (!HTMLElement || !this.enableClickScroll) {
			return;
		}

		const targetElement = element as HTMLElement;
		const supportsNativeSmoothScroll = 'scrollBehavior' in targetElement.style;

		supportsNativeSmoothScroll
			? targetElement.scrollIntoView({ behavior: 'smooth' })
			: targetElement.scrollIntoView(false);
	}

	public sliderContentScrolled($event: Event) {
		const element: HTMLElement = $event.target as HTMLElement;

		if (element.scrollLeft + element.offsetWidth >= element.scrollWidth - SCROLL_SAFE_MARGIN_END) {
			this.enableScrollLeft = false;
			return;
		}

		this.enableScrollLeft = true;
		this.sliderContentScroll = element.scrollLeft;
	}

	public scrollContent(direction: 'left' | 'right') {
		const sliderContentWrapper = this.sliderContentWrapper.nativeElement;

		const scrollOffset = SCROLLED_PIXELS_PER_CLICK;
		const scrollOffsetAndDirection = direction === 'left' ? -scrollOffset : scrollOffset;

		sliderContentWrapper.scrollTo({
			left: sliderContentWrapper.scrollLeft + scrollOffsetAndDirection,
			behavior: 'smooth',
		});
	}
}
