import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	tooltip: TemplateRef<any>;

	constructor() {}

	ngOnInit(): void {}
}
