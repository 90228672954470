import { T } from '@transifex/angular';

export class SelectedBenefitsLocalize {
	@T('Gym Membership')
	static id614f65388df89f3ba64ed686;
	@T('Mental Health Therapy & Counseling')
	static id602fc6a0f9b9675f5bf9a3e7;
	@T('Wellness Visit')
	static id5d9c3af263507c70ef688601;
	@T('Massage Therapy')
	static id614040ff21dcd573c9f3b8df;
	@T('Nutrition Counseling')
	static id616c26c886563262b0c2224d;
	@T('Anxiety Diagnosis & Management')
	static id619cbaad5fdc06090e9a2d4d;
	@T('Telehealth')
	static id5ddfaa5fcace7e30c3c90d6e;
	@T('Acupuncture')
	static id60fd0dfb0cca1e24477fed22;
	@T('Allergy Testing & Treatment')
	static id617904b686563262b0c24af9;
	@T('Flu Vaccine')
	static id60e3f51a54823252fe1b8fc9;
	@T('Pain Management')
	static id60e32f0554823252fe1b8f19;
	@T('Covid Tests')
	static id60250fdc2da2fb5b02e42638;
	@T('Physical Therapy')
	static id61fc1131d0a2ab18c9b110b1;
	@T('Blood Pressure Monitoring & Screening')
	static id6025581ef68744676d225490;
	@T('Covid Vaccine & Booster')
	static id604a75d304b9b313c9704524;
	@T('Chiropractor')
	static id604a72c104b9b313c97044f0;
	@T('Cold & Flu')
	static id5d9c3af263507c70ef68860f;
	@T('Diabetes Diagnosis & Management')
	static id5ea1614d63f59515f7bafa0a;
	@T('Depression Diagnosis & Management')
	static id5ea1531363f59515f7baf9fe;
	@T('Mammogram')
	static id611a47fcb7003f30ce5f2228;
	@T('Eye Exam')
	static id61ef1344d0a2ab18c9b07cbb;
	@T('Dental Cleaning')
	static id61e8055390f66c4ccfceb2b6;
	@T('Colonoscopy')
	static id60e2e24854823252fe1b8ea1;
	@T('Cavities & Fillings')
	static id61e8174c90f66c4ccfceb2cd;
	@T('Cancer Diagnosis & Treatment')
	static id5ef58dfc1f066b1ed215de16;
	@T('Pregnancy')
	static id62031b1a97f8f2507e89da39;
	@T('Mole Removal')
	static id60e323bc54823252fe1b8ef8;
	@T('Family Planning')
	static idError;
	@T('Substance Use')
	static id60e2e34854823252fe1b8ea2;
	@T('Weight Management')
	static id61dd0c784baee71f36a66885;
	@T('Emergency Room')
	static id5d9c3af263507c70ef68861b;
	@T('Contacts')
	static id61e9749d90f66c4ccfcec2ef;
	@T('Glasses')
	static id61e970d790f66c4ccfcec2e6;
	@T('Invisalign')
	static id63407d193a04d7681a93de8d;
	@T('Braces')
	static id6202c52197f8f2507e89cac8;
	@T('Mouth guards')
	static id603689aaa8877650c85817d2;
	@T('Gum Disease')
	static id61e8339590f66c4ccfceb2eb;
	@T('Prescription Sunglasses')
	static id61e9747390f66c4ccfcec2ed;
	@T('Bi-Focal / Tri-Focal Glasses')
	static id61e9740290f66c4ccfcec2eb;
	@T('Teeth Whitening')
	static id6303759918aaaf5d4398f793;
	@T('Root Canal & Crowns')
	static id61e8433890f66c4ccfceb2f6;
	@T('Dental Retainer')
	static id626988cec2a6ca4d48700f18;
	@T('Dentures & Implants')
	static id60368740a8877650c85817cb;
	@T('Tinted Glasses')
	static id62dee113e57e55dc435c37de;
	@T('Blue Light Glasses')
	static id61e9763c90f66c4ccfcec2f5;
	@T('Lasik Eye Surgery')
	static id61e9761390f66c4ccfcec2f3;
}
