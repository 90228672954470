import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackingService } from '../../../services/tracking.service';
import { invokePhoneDialer, isIonic } from '../../../utils/utils';
import { UserService } from '../../../services/user.service';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { Subscription, combineLatest, filter } from 'rxjs';
import { UserData } from '../../../models/user-data';
import { PlanData } from '../../../models/plan-data.model';

@Component({
  selector: 'app-quantum-support',
  templateUrl: './quantum-support.component.html',
  styleUrls: ['./quantum-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class QuantumSupportComponent implements OnInit, OnDestroy {
  private eventMetadata;
  private subscription: Subscription;

  public isMobileView: boolean;

  constructor(private domSanitizer: DomSanitizer,
    private trackingService: TrackingService,
    private userService: UserService,
    private planDataStoreService: UserPlanDataStoreService
  ) { }

  ngOnInit(): void {
    this.collectEventMetadata()
    this.isMobileView = isIonic();
  }

  call() {
    invokePhoneDialer('+833 346-1482');
    const callMetadata = { ...this.eventMetadata, eventName: 'Quantum Health Call', description: 'The user call to the call center' }
    this.track('Quantum Health Call', callMetadata)
  }

  goToWebsite() {
    const url = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.myqhcc.com/'))
    window.open(url, '_blank');

    const webMetadata = {
      ...this.eventMetadata, eventName: 'Quantum Health Website',
      description: 'The user navigate to the website'
    }
    this.track('Quantum Health Website', webMetadata)
  }

  collectEventMetadata() {
    this.subscription = combineLatest(this.userService.userData$?.pipe(filter((value) => !!value)),
      this.planDataStoreService.get()?.pipe(filter((value) => !!value)),
      (userData: UserData, planData: PlanData) => {
        this.eventMetadata = {
          category: 'Quantum support',
          eventName: '',
          description: '',
          userId: userData.uid,
          company: userData.company?.name,
          medical: planData.contract?.name,
          dental: planData.dental?.name,
          vision: planData.vision?.name,
          birthDate: userData.birthday,
          gender: userData.gender,
        }
      })
      .subscribe()
  }


  private track(event: string, metaData = {}) {
    this.trackingService.trackClientEvent(event, metaData);
  }


  ngOnDestroy() {
      this.subscription?.unsubscribe();
  }
}
