<div class="empty-dependent-container">
	<div class="headlines-container">
		<p class="main-headline">{{ 'You have no registered dependents yet!' | translate }}</p>
		<p class="secondery-headline">
			<UT
				str="If you’d like to add dependents or a spouse to your account, <br> you’ve come to the right place!"
			></UT>
		</p>
	</div>
	<img src="../../../../../assets/images/health-profile/empty_dependents.svg" alt="empty dependents" />
</div>
