import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PreventiveCare, PreventiveCareService } from '../../../services/preventive-care.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AllServicesStoreService } from '../../../services/stores/all-services-store/all-services-store.service';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { ProvidersSearchService } from '../../../services/providers-search/providers-search.service';
import { AppManagerService } from '../../../services/app-manager.service';
import { PreventiveCareStoreService } from '../../../services/stores/preventive-care-store/preventive-care-store.service';

@Component({
	selector: 'app-preventive-care-details-card',
	templateUrl: './preventive-care-details-card.component.html',
	styleUrls: ['./preventive-care-details-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PreventiveCareDetailsCardComponent extends HealtheeDialogContent implements OnInit {
	public preventiveCareData$: Observable<PreventiveCare>;
	public isFromModal: boolean = false;
	public buttonsLoadingStates = {
		markAsCompleted: false,
		pinned: false,
	};

	private _id: number;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _preventiveCareService: PreventiveCareService,
		private _preventiveCareStore: PreventiveCareStoreService,
		private _providersSearchService: ProvidersSearchService,
		private _appManagerService: AppManagerService,
		public allServicesStoreService: AllServicesStoreService,
	) {
		super();
	}

	ngOnInit(): void {
		this.isFromModal = !!this.data;
		this._initData();
	}

	private _initData(): void {
		if (this.isFromModal) {
			this._id = this.data.id;
			this.preventiveCareData$ = this._preventiveCareStore.getById(this._id);
		} else {
			this._route.params.subscribe((params) => {
				this._id = params.id;
				this.preventiveCareData$ = this._preventiveCareStore.getById(this._id);
			});
		}
	}

	public goBack(): void {
		this._router.navigate(['preventive-care']);
	}

	public onFindAProviderClick(serviceId: string): void {
		this.closeDialog.emit(null);
		const referrer = this._router.url;
		this._providersSearchService.goToProviderSearchWithServiceId(serviceId, referrer);
	}

	public onMarkAsCompleted(preventiveCareData: PreventiveCare): void {
		this._appManagerService.openPreventiveCareMarkAsCompleted({
			id: preventiveCareData.id,
			name: preventiveCareData.name,
			frequency: preventiveCareData.frequency,
		}).subscribe((selectedDate) => {
			if (selectedDate) {
				this.buttonsLoadingStates.markAsCompleted = true;
				this._preventiveCareService.saveCompletedDate(this._id, selectedDate).subscribe(() => {
					this.buttonsLoadingStates.markAsCompleted = false;
				});
			}
		});
	}

	public onPinClick(id: number, isPinned: boolean): void {
		this.buttonsLoadingStates.pinned = true;
		this._preventiveCareService.updatePinnedState(id, !isPinned).subscribe(() => {
			this.buttonsLoadingStates.pinned = false;
		});
	}
}
