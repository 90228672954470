import { Component, Input } from "@angular/core";
import { BaseGipitpotMessage, GipitpotIntent } from "../../../models/gipitpot/gipitpot-messages";

@Component({
	selector: "app-gipitpot-system-message",
	templateUrl: "./gipitpot-system-message.component.html",
	styleUrls: ["./gipitpot-system-message.component.scss"]
})
export class GipitpotSystemMessageComponent {
	@Input() message: BaseGipitpotMessage;

	public get suggestedIntent(): string {
		const intent = this.message?.intent;
		if ([GipitpotIntent.Coverage.valueOf()].includes(intent)) {
			return this.message?.intent;
		}

		return null;
	}

	protected readonly GipitpotIntent = GipitpotIntent;
}
