<ng-container *ngIf="hasBenefits$ | async">
	<nav mat-tab-nav-bar class="benefits-tab-desktop">
		<a mat-tab-link [active]="isMedicalBenefitsActive" routerLink="/gallery">
			{{ 'Medical Benefits' | translate }}
		</a>
		<a
			mat-tab-link
			[active]="!isMedicalBenefitsActive"
			routerLink="/gallery/additional-benefits"
			(click)="onTrack()"
		>
			{{ 'Employer Benefits' | translate }}
		</a>
	</nav>

	<nav mat-tab-nav-bar mat-stretch-tabs class="benefits-tab-small-screens">
		<a mat-tab-link [active]="isMedicalBenefitsActive" routerLink="/gallery">
			{{ 'Medical Benefits' | translate }}
		</a>
		<a
			mat-tab-link
			[active]="!isMedicalBenefitsActive"
			routerLink="/gallery/additional-benefits"
			(click)="onTrack()"
		>
			{{ 'Employer Benefits' | translate }}
		</a>
	</nav>
</ng-container>
