import { Injectable } from '@angular/core';

import { Observable, take, tap } from 'rxjs';
import { AppManagerService } from '../services/app-manager.service';
import { UserPlanDataStoreService } from '../services/stores/user-plan-data-store/user-plan-data-store.service';
import { RxjsUtils } from '../utils/rxjs';

@Injectable({
	providedIn: 'root',
})
export class ContractGuard {
	constructor(private userPlanDataStoreService: UserPlanDataStoreService, private appManager: AppManagerService) {}

	canActivate(): Observable<boolean> {
		return this.userPlanDataStoreService.hasContract().pipe(
			RxjsUtils.isNotNil(),
			take(1),
			tap((has) => (!has ? this.appManager.openBlockedAccountModal() : null))
		);
	}
}
