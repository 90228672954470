<div class="disclaimer-container">
	<div class="content">
		<h2 class="title">
			{{ 'Are you sure you that you want to reschedule this appointment?' | translate }}
		</h2>
		<div class="disclaimer">
			<p class="subtitle">{{ 'Please note!' | translate }}</p>
			<p>
				{{
					'Rescheduling within 24 hours of your appointment more than three times or missing an appointment more than two times may affect your ability to book appointments in the future.'
						| translate
				}}
			</p>
		</div>
	</div>
</div>
