<div class="providers-container">
	<div class="providers-strip">
		<button [disabled]="providerIndex === START_POSITION" class="to-start" (click)="changeProvider(false)"></button>

		<div class="providers-wrapper">
			<div #provider class="providers-container">
				<p class="location-number">
					{{ 'Location' | translate }} {{ providerIndex + 1 }} / {{ providersArr.length }}
				</p>
				<p class="location-address">{{ activeProvider?.address }}</p>
				<p *ngIf="providerPhone" class="location-phone-number">
					<strong>{{ 'Phone' | translate }} </strong>{{ providerPhone }}
				</p>
			</div>
		</div>
		<button
			[disabled]="providerIndex === providersArr.length - 1"
			class="to-end"
			(click)="changeProvider(true)"
		></button>
	</div>
	<div class="map-container">
		<google-map width="100%" height="100%" #googleMap></google-map>
	</div>
</div>
