import { Provider } from '../../main-layout/care-costs/helpers/providers.helpers';

export enum Gender {
	Male = 'male',
	Female = 'female',
}

export interface FavoriteProvider {
	npi: string;
	name: string;
	specialty: string;
	gender: Gender;
	addedToFavoriteAt?: string;
	isFavorite?: boolean;
}

export const DEFAULT_FAVORITE_PROVIDER: FavoriteProvider = {
	npi: '',
	name: '',
	specialty: '',
	gender: Gender.Male,
	addedToFavoriteAt: '',
	isFavorite: false,
};

export const PROVIDER_ICON_PATH = {
	[Gender.Male]: '/assets/images/provider-card/provider-card-male.svg',
	[Gender.Female]: '/assets/images/provider-card/provider-card-female.svg',
};

export const providerCardToFavoriteProvider = (provider: Provider): FavoriteProvider => ({
	npi: provider.npi,
	name: `${provider.firstName} ${provider.lastName}`,
	specialty: provider.primarySpecialty,
	gender: provider.gender === 'm' ? Gender.Male : Gender.Female,
});
