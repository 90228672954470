import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CFG from '../../../config/app-config.json';
import { MedicalConditionsResponse } from './helpers/medical-conditions.response';
import { ServicesResponse } from './helpers/services.response';
import { MedicalGroupsResponse } from './helpers/medical-groups.response';

@Injectable({
	providedIn: 'root',
})
export class EmployeeApiService {
	constructor(private httpClient: HttpClient) {}

	medicalConditions() {
		return this.httpClient.get<MedicalConditionsResponse[]>(CFG.apiEndpoints.employeeAPIs.medicalConditions);
	}

	services() {
		return this.httpClient.get<ServicesResponse[]>(CFG.apiEndpoints.employeeAPIs.services);
	}

	medicalGroups() {
		return this.httpClient.get<MedicalGroupsResponse[]>('/employee/medicalGroups');
	}
}
