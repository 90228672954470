import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

const PASSWORD_MINIMUM_CHARACTERS = 8;
const PASSWORD_MAXIMUM_CHARACTERS = 64;

export interface PasswordObj {
	password: string;
	isValid: boolean;
	isMatch?: boolean;
}
@Component({
	selector: 'app-set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss'],
})

/**
 * Component will use 100% of the width of the parent element
 * @param  useValidation (Input, boolean) - Signals if the component will display & use the validation marker on the password values.
 * @param  useConfirmPassword (Input, boolean) - Signals to display the 'confirm password input'
 * @param  showUsedPasswordErrorSignal (Input, boolean) - Signals to display "Password is already used" error
 * @param  passwordChanged (Output, EventEmitter, Password) - Emits the submitted password input value & isValid boolean on keyUp event, as a Password object.
 */
export class SetPasswordComponent implements OnInit {
	@Input() useValidation: boolean = false;
	@Input() useConfirmPassword: boolean = false;
	@Input() showUsedPasswordErrorSignal$: Observable<boolean>;
	@Input() passwordFromLogin: boolean = false;
	@Input() invalidField: boolean = false;

	@Output() passwordChanged = new EventEmitter<PasswordObj>();

	public password: string = '';
	public confirmPassword: string = '';

	public passwordStrength: number;
	public hidePassword: boolean = true;
	public passwordsMatch: boolean = false;
	public passwordIsValid: boolean = false;
	public hideConfirmPassword: boolean = true;
	public displayPasswordMatchValidation = false;

	public validationObj = { length: false, uppercase: false, lowercase: false, numbers: false, specialChar: false };

	constructor() {}

	ngOnInit(): void {
		this.subscribeToShowUsedPasswordErrorSignal();
	}

	private subscribeToShowUsedPasswordErrorSignal() {
		this.showUsedPasswordErrorSignal$?.subscribe();
	}

	public onPasswordInputChange() {
		this.checkPasswordStrength();
		this.checkIfBothPasswordsAreTouched();
	}

	private checkPasswordStrength() {
		const password = this.password;
		const numbersValid = /[0-9]/;
		const uppercaseValid = /[A-Z]/;
		const lowercaseValid = /[a-z]/;
		const specialCharValid = /[-@$!%*?&<>\/~+;:\.,|_`"=”’#^\\[\]{}()]/;
		const passwordLength = password.length || 0;

		this.validationObj.numbers = numbersValid.test(password);
		this.validationObj.uppercase = uppercaseValid.test(password);
		this.validationObj.lowercase = lowercaseValid.test(password);
		this.validationObj.specialChar = specialCharValid.test(password);
		this.validationObj.length =
			passwordLength >= PASSWORD_MINIMUM_CHARACTERS && passwordLength <= PASSWORD_MAXIMUM_CHARACTERS;

		this.passwordIsValid = Object.values(this.validationObj).every((field) => field === true);
		this.passwordChanged.emit({
			password,
			isValid: this.passwordIsValid,
			isMatch: this.passwordsMatch,
		});
	}

	public checkPasswordMatch() {
		this.passwordsMatch = this.password === this.confirmPassword;

		this.passwordChanged.emit({
			password: this.password,
			isValid: this.passwordIsValid,
			isMatch: this.passwordsMatch,
		});
	}

	public onPasswordBlur() {
		this.checkIfBothPasswordsAreTouched();
	}

	private checkIfBothPasswordsAreTouched() {
		if (this.password?.length <= 0 || this.confirmPassword?.length <= 0) {
			return;
		}

		this.displayPasswordMatchValidation = true;
		this.checkPasswordMatch();
	}
}
