import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
	Coverage,
	normalizeProvider,
	ProviderDetails,
	Reviews,
	RibbonProvider,
} from '../../../modules/main-layout/care-costs/helpers/providers.helpers';
import CFG from '../../../config/app-config.json';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { UIService } from '../../ui.service';
import { SearchResponse } from './helpers/providers-api.interface';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';

const PROVIDER_DETAILS_API = '/api/providers/';

@Injectable({
	providedIn: 'root',
})
export class ProvidersApiService extends StoreApi {
	constructor(
		private httpClient: HttpClient,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private uiService: UIService,
		private providersSearchUrlsService: ProvidersSearchUrlsService
	) {
		super(storeUpdateRegistryService);
	}

	providerByNpi(npi: string): Observable<ProviderDetails> {
		return this.httpClient.get<{ provider: RibbonProvider } & Coverage & Reviews>(PROVIDER_DETAILS_API + npi).pipe(
			map((response) => ({
				...normalizeProvider(response.provider),
				isInNetwork: response.isInNetwork,
				networkContract: response.networkContract,
				reviews: response.reviews,
			}))
		);
	}

	providersPaginated(formData: any): Observable<SearchResponse<RibbonProvider>> {
		const params = new HttpParams({
			fromObject: {
				...formData,
			},
		});
		return this.httpClient.get<SearchResponse<RibbonProvider>>(CFG.apiEndpoints.providersSearch, { params });
	}

	searchByParams(queryParams: any, mergeType: 'merge' | 'preserve') {
		this.uiService.navigate([this.providersSearchUrlsService.resultsUrl], {
			queryParams,
			queryParamsHandling: mergeType ? mergeType : 'preserve',
		});
	}
}
