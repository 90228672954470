import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerCardComponent } from './banner-card.component';
import { RippleModule } from 'ripple';


@NgModule({
  	declarations: [
		BannerCardComponent
  	],
  	imports: [
    	CommonModule,
		RippleModule
  	],
  	exports: [
		BannerCardComponent,
	]
})
export class BannerCardModule { }
