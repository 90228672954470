import { T } from '@transifex/angular';

export class TelehealthLocalize {
	@T('We lost connection :(')
	static iframeErrorMessageTitle: string;

	@T('This may be caused by a temporary connection issue. Please try again or report the issue if it persists')
	static iframeErrorMessageDescription: string;

	@T('Report the issue')
	static iframeErrorReportButton: string;
}
