import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	forwardRef,
	Injector,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { FormControlComponent } from '../../../../../../utils/components/form-control-component';
import { Maybe } from '../../../../../../utils/types/maybe';
import { isNil } from '../../../../../../utils/is/is-nil';
import { getPath } from '../../../../../../utils/get-path';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { InputOf } from '../../../../../../utils/input-reflector/input-of';
import { combineLatest } from 'rxjs';
import { isArray } from '../../../../../../utils/is/is-array';
import { StringUtils } from '../../../../../../utils/string';

@Component({
	selector: 'app-select-form-control',
	templateUrl: './select-form-control.component.html',
	styleUrls: ['./select-form-control.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectFormControlComponent),
			multi: true,
		},
	],
})
export class SelectFormControlComponent extends FormControlComponent implements OnInit {
	@ViewChild('searchInput')
	searchInput: ElementRef;

	@ContentChild('item')
	itemTemplate: TemplateRef<any>;

	@Input()
	label: Maybe<string>;

	@Input()
	searchLabel: Maybe<string>;

	@Input()
	items: Maybe<Array<unknown>>;

	@Input()
	valueField: Maybe<string> = 'value';

	@Input()
	displayField: Maybe<string> = 'display';

	@Input()
	multi: Maybe<boolean> = true;

	@Input()
	hasBottomSpace = true;

	@Input()
	hasSearch = false;

	@Input()
	placeholderText: Maybe<string>;

	// Props
	@Input()
	appearance: MatFormFieldAppearance = 'outline';

	@Input()
	floatLabel: string = 'auto';

	@Input()
	isFullWidth: Maybe<boolean> = false;

	@Input()
	markIfValid: Maybe<boolean> = false;

	@Input()
	expandHeightToContent: Maybe<boolean> = false;

	@Input()
	isNullable: Maybe<boolean> = false;

	search$ = new BehaviorSubject<string>('');

	filteredItems$: Observable<Array<unknown>>;

	constructor(private injector: Injector) {
		super(injector);
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'items', 'hasSearch'];
	}

	override ngOnInit(): void {
		super.ngOnInit();
		const items$ = this.inputs.one('items');
		const hasSearch$ = this.inputs.one('hasSearch');

		this.filteredItems$ = combineLatest([items$, hasSearch$, this.search$]).pipe(
			map(([items, hasSearch, search]) => {
				if (isNil(items) || !isArray(items)) {
					return [];
				}
				if (!hasSearch) {
					return items;
				}
				return items.filter((item) => StringUtils.similar(item[this.displayField], search));
			}),
			shareReplay(1)
		);
	}

	optionsPanelOpened() {
		if (!this.hasSearch) {
			return;
		}
		const nativeElement: any = getPath(this, 'searchInput.nativeElement');
		if (!isNil(nativeElement)) {
			nativeElement.focus();
		}
	}
}
